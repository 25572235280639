import React, { Fragment } from 'react';
import { Query, withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import _ from "lodash";
import $ from 'jquery';
import linkifyHtml from 'linkifyjs/html'
import CommentEditForm from './Edit';
import CommentBody from './CommentBody';
import { MY_COMMENT_LIST, MY_COMMENT_CNT, COMMENT_LIKE, COMMENT_REMOVE } from '../../../queries/prescript/comment';
import CommonFunc from '../../../common/CommonFunction';
import { confirmAlert } from 'react-confirm-alert';
import HtmlConfirm from '../../../common/HtmlConfirm';

class MyCommentFragment extends React.PureComponent {

    state = {
        comment: null,
        comment_tot_cnt: 0,
        comment_list_cnt: 0,
        sorter: 'DATE',
        row_unit_size: 99999,
        ip: null
    }

    sortComment = (sorter) => {
        this.setState({
            sorter: sorter
        })
    }

	commentEditForm = (comment, kcd_list, img_arr) => {
        //console.log('comment', comment);
        this.setState({
            comment: {
                ...comment,
                kcd_list: kcd_list,
                img_arr: img_arr
            }
		});
    }

    commentRemove = (comment_idx, refetch) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <HtmlConfirm
                        title={"의견 삭제"}
                        message={"해당 의견을 삭제하시겠습니까?"}
                        onClose={onClose}
                        confirmCallback={() => {
                            this.props.client.mutate({
                                mutation: COMMENT_REMOVE,
                                variables: {
                                    comment_idx
                                }
                            })
                            .then((res) => {
                                this.updateCommentCount(-1);
                                refetch();	 //update props & render
                            })
                            .catch((error) => {
                                //console.log(error.message);
                            });
                        }}
                    />
                )
            }
        })
    }

    updateCommentCount = (added) => {
        const comment_tot_cnt = this.state.comment_tot_cnt + added;
        this.setState({
            comment_tot_cnt: comment_tot_cnt
        })
        this.props.updateMyCommentCnt(comment_tot_cnt); //상위에 의견 개수 전달
    }

    closeEditForm = (comment_idx) => {
        this.setState({
            comment: null
		});
    }

    nextComment = () => {
        this.setState({
            row_unit_size: 999999
        })
    }

    /*
    getTotalCommentCnt = () => {
        this.props.client.query({
			query: MY_COMMENT_CNT,
			variables: { u_id: this.props.user.id }
		})
		.then((res) => {
            //console.log('The comment total count : ', res);
            this.setState({
                comment_tot_cnt: res.data.getCommentMyCnt.count
            })
		})
		.catch((error) => {
            //console.log('error', error.message);
		});
    }
    */

    componentDidMount = () => {
        //this.getTotalCommentCnt(); //댓글 개수 조회
    }

    callbackIPAddr = (ip) => {
        this.setState({
            ip: ip
        });
    }

    render() {
        const { user, reload_flag } = this.props;
        const { comment, sorter } = this.state;
        //console.log('MyCommentFragment props', this.props);
        //console.log('MyCommentFragment state', this.state);

        return (
            <div id="review" className="mycomment">
                { !_.isEmpty(user) &&
                <Query query={MY_COMMENT_LIST} variables={{ u_id: user.id, sorter: sorter, row_count: this.state.row_unit_size, reload_flag: reload_flag }}
                    fetchPolicy="cache-and-network"
                >
                    {({ data, loading, error, refetch, fetchMore }) => {
                        if (loading) return <p></p>
                        if (error) return <p>{error.message}</p>;
                        //console.log('MY_COMMENT_LIST', data);
                        //console.log('xxx', { u_id: user.id, sorter: sorter, row_count: this.state.row_unit_size, reload_flag: reload_flag })

                        this.setState({
                            comment_tot_cnt: data.getMyCommentList.length
                        })

                        return (
                            <Fragment>
                                <div className="review_cont">
                                    { data.getMyCommentList.length > 0 &&
                                        <div className="sort_option">
                                            <ul className="option_list">
                                                <li className={sorter === 'DATE' ? 'type_item on' : 'type_item'} onClick={()=>this.sortComment('DATE')}><span className="sort_label">최신순</span></li>
                                                <li className={sorter === 'LIKE' ? 'type_item on' : 'type_item'} onClick={()=>this.sortComment('LIKE')}><span className="sort_label">추천순</span></li>
                                            </ul>
                                        </div>
                                    }
                                    {
                                        data.getMyCommentList.map((item, index) => {
                                            return (
                                                <Fragment key={index}>
                                                {
                                                    //editable comment form
                                                    comment && comment.comment_idx === item.comment_idx ?
                                                        <div className="inputarea_wrap">
                                                            <div className="input_box">
                                                                <div className="input_box_wrap">
                                                                    {/* EDIT MODE */}
                                                                    <CommentEditForm
                                                                        comment={comment}
                                                                        closeEditForm={this.closeEditForm}
                                                                        refetch={refetch}
                                                                        handleSubmit={this.editComment}
                                                                        page="MY"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                    <CommentBody
                                                        item={item}
                                                        user={user}
                                                        refetch={refetch}
                                                        commentEditForm={this.commentEditForm}
                                                        commentRemove={this.commentRemove}
                                                        from="MY"
                                                    />
                                                }
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            </Fragment>
                        )
                    }}
                </Query>
                }
            </div>
        )
    }
}
export default withApollo(MyCommentFragment);