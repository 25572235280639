import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import _ from "lodash";
import striptags from "striptags";
import { isMobileOnly } from 'react-device-detect';
import TimeStamps from '../../../common/TimeStamps';
import Swiper from 'swiper';
import CommonFunc from '../../../common/CommonFunction';
import $ from 'jquery';

const Muzzima = (props) => {

    function onRendered(){
        if(props.content.length > 0){
            var swiper = new Swiper('#muzzima_swiper', {
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 0,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    // when window width is <= 320px
                    310: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                    },
                    // when window width is <= 480px
                    560: {
                        slidesPerView: 2,
                        slidesPerGroup: 2
                    },
                    // when window width is <= 640px
                    720: {
                        slidesPerView: 3,
                        slidesPerGroup: 3
                    }
                }
            });
            //console.log('swiper', swiper);
            $('#muzzima_swiper .swiper-wrapper').css('transform', 'translate3d(0px, 0px, 0px)');
        }
    }

    return (
        <div className="popchapter feed">
            {   props.user ?
                <div className="chaptit">
                    <p className="tittxt"><span className="diseasename">{props.ingred_name}</span> 관련 메디게이트 게시물(<span>{props.content.length}</span>)</p>
                    <p className="subtxt">이 게시물은 의사 사용자만 보입니다.</p>
                </div>
                :
                <div className="chaptit">
                    <p className="tittxt"><span className="diseasename">{props.ingred_name}</span> 관련 메디게이트 게시물</p>
                </div>
            }
            {
                props.user ?
                    <Fragment>
                    <div className="swiper-container" id="muzzima_swiper">
                        <div className="swiper-wrapper">
                        {
                            props.content.map((item, index) =>
                                <div className="swiper-slide"
                                        key={index}
                                        onClick={() => {
                                            CommonFunc.pageview('처방정보', '게시물', props.ingred_name); //Google Analytics
                                            let url = isMobileOnly ? `https://m.medigate.net/muzzima/${item.svc_cont_idx}` : `https://www.medigate.net/board/muzzima/detail?boardIdx=${item.svc_cont_idx}`;
                                            window.open(url, '_blank');
                                        }}
                                        style={{cursor: "pointer"}}
                                >
                                    <div className="article box">
                                        <p className="name">{ _.isEmpty(item.u_name) ? '익명' : item.u_name.replace('`', '')}</p>
                                        <p className="tittxt">{item.title}</p>
                                        <p className="subtit" dangerouslySetInnerHTML={{__html: striptags(CommonFunc.replaceAll(item.content, '&nbsp;', ' '), [], ' ')}}></p>
                                        <div class="subtxt txtinfo">
                                            <p class="icon icon_due">
                                                <span><time><TimeStamps date={item.reg_date} /></time></span>
                                            </p>
                                            <p class="icon icon_like">{item.like_cnt}</p>
                                            <p class="icon icon_dislike">{item.dislike_cnt}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        </div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                    <div ref={onRendered}></div>
                    </Fragment>
                :
                <div className="force_login">의사 회원 로그인이 필요합니다.</div>
            }

        </div>

)};

export default Muzzima;