import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMore from '@material-ui/icons/ExpandMore';

const options = [
    {text: '급여정보', key: 'payinfo'},
    {text: '근무시간', key: 'worktime'},
    {text: '근무환경', key: 'condition'},
    {text: '초빙수요', key: 'demand'},
];

class S_MobileMenu extends Component {
    state = {
        anchorEl: null,
        selectedIndex: 0
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.currentMenu !== prevState.selectedIndex) {
            this.setState({
                selectedIndex: this.props.currentMenu
            });
        }
    }

    handleClickListItem = event => {
        this.setState({
            anchorEl: event.currentTarget,
        })
    };

    handleMenuItemClick = (event, index, key) => {
        this.setState({
            anchorEl: null,
            selectedIndex: index
        })

        this.props.history.push(`/salary/${key}`);
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    };

    render(){
        const { anchorEl, selectedIndex } = this.state;

        return (
            <div>
                <List component="nav" aria-label="Device settings">
                    <ListItem
                        button
                        aria-haspopup="true"
                        aria-controls="lock-menu"
                        aria-label="when device is locked"
                        onClick={this.handleClickListItem}
                    >
                        <ListItemText primary={options[selectedIndex].text} />
                        <ExpandMore />
                    </ListItem>
                </List>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    {
                        options.map((option, index) => (
                        <MenuItem
                            key={option.key}
                            // /disabled={index === 0}
                            selected={index === selectedIndex}
                            onClick={event => this.handleMenuItemClick(event, index, option.key)}
                        >
                            {option.text}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    }
}

export default withRouter((S_MobileMenu))