import React, { Fragment } from 'react';
import CapacityChart from '../chart/CapacityChart';
import SpecialtyChart from '../chart/SpecialtyChart';
import IMSubChart from '../chart/IMSubChart';
import SexChart from '../chart/SexChart';
import AgeChart from '../chart/AgeChart';
import HospitalTypeChart from '../chart/HospitalTypeChart';
import AreaChart from '../chart/AreaChart';
import BedChart from '../chart/BedChart';

const HighChartFragment = (props) => {
    return (
        <Fragment>
            <div className="statics graph1">
                <CapacityChart
                    data={props.data}
                    ingred_eng={props.ingred_eng}
                    kcd_code_arr={props.kcd_code_arr}
                    ctg_code3={props.ctg_code3}
                    ingred_code_grp={props.ingred_code_grp}
                    dgsbjt_cd_arr={props.dgsbjt_cd_arr}
                    dgsbjt_dtl_cd_arr={props.dgsbjt_dtl_cd_arr}
                    agg_arr={props.agg_arr}
                    sex_tp_cd_arr={props.sex_tp_cd_arr}
                    cl_cd_arr={props.cl_cd_arr}
                    bed_arr={props.bed_arr}
                    sido_cd_arr={props.sido_cd_arr}
                    place={props.place}
                    atc_code={props.atc_code}
                    combi_flag={props.combi_flag}
                    ingred_combi_flag={props.ingred_combi_flag}
                    method={props.method}
                    stat_year={props.stat_year}
                />
            </div>
            <div className="statics graph2">
                <SpecialtyChart
                    data={props.data}
                />
            </div>
            <div className="statics graph3">
                <IMSubChart
                    data={props.data}
                />
            </div>
            <div className="statics graph4">
                <SexChart
                    data={props.data}
                />
            </div>
            <div className="statics graph5">
                <AgeChart
                    data={props.data}
                />
            </div>
            <div className="statics graph6">
                <HospitalTypeChart
                    data={props.data}
                />
            </div>
            <div className="statics graph7">
                <AreaChart
                    data={props.data}
                />
            </div>
            <div className="statics graph8">
                <BedChart
                    data={props.data}
                />
            </div>
        </Fragment>
    )
}

export default HighChartFragment;