import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import 'bootstrap';
import _ from 'lodash';

import { PRODUCT_LIST } from '../../../queries/prescript/prescription';

import ProductList from './ProductList';
import FavoriteFragment from './FavoriteFragment';
import ResponsiveDialog from '../layer/ResponsiveDialog';

class Combi3Fragment extends React.PureComponent {

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        const propsChanged = (newProps.liked_item !== null && oldProps.liked_item !== newProps.liked_item);
        //console.log('componentWillReceiveProps changed - 3rd', propsChanged);
        if(propsChanged) {
            this.props.refetch();
        }
    }

    render() {
        const { getPrescriptionList } = this.props.data;
        const { percent } = this.props;

        //console.log("parentPercent 3", percent)
        let prescript_use_percent = 0;

        return(
            <Fragment>
                {
                    getPrescriptionList.map((item, index) => {

                        if(this.props.order === "prescript"){
                            prescript_use_percent = Number.parseFloat((percent * item.prescript_share_per_round5 * 0.01)).toFixed(1)
                        }else{
                            prescript_use_percent = Number.parseFloat((percent * item.use_share_per_round5 * 0.01)).toFixed(1)
                        }

                        return (
                            <div class="contlist third" key={`3rd_${index}`}>
                                <div class="txtinfo td07"></div>
                                <div class="txtinfo td01"><span></span></div>
                                <div class="txtinfo td02">
                                    <span>
                                        {prescript_use_percent}
                                    </span>
                                    <span>%</span>
                                </div>
                                <div class="txtinfo td06">
                                    {
                                        item.method === "A" &&
                                        <span className="type1">{item.method_kor}</span>
                                    }
                                    {
                                        item.method === "B" &&
                                        <span className="type2">{item.method_kor}</span>
                                    }
                                    {
                                        item.method === "C" &&
                                        <span className="type3">{item.method_kor}</span>
                                    }
                                    {
                                        item.method === "D" &&
                                        <span className="type4">{item.method_kor}</span>
                                    }
                                </div>
                                <div class="txtinfo td03">
                                    <ResponsiveDialog
                                        item={item}
                                        kcd_code_arr={this.props.kcd_code_arr}
                                        ctg_code_grp={this.props.ctg_code_grp}
                                        ctg_name={this.props.ctg_name}
                                        ctg_code3={this.props.ctg_code3}
                                        user={this.props.user}
                                        kcd_list={this.props.kcd_list}
                                        dgsbjt_cd_arr={this.props.dgsbjt_cd_arr}
                                        dgsbjt_dtl_cd_arr={this.props.dgsbjt_dtl_cd_arr}
                                        agg_arr={this.props.agg_arr}
                                        sex_tp_cd_arr={this.props.sex_tp_cd_arr}
                                        cl_cd_arr={this.props.cl_cd_arr}
                                        bed_arr={this.props.bed_arr}
                                        sido_cd_arr={this.props.sido_cd_arr}
                                        userProfile={this.props.userProfile}
                                        onLike={(liked_item) => {
                                            // update 1st Depth
                                            this.props.onLike(liked_item);
                                        }}
                                        filter_place={this.props.filter_place}
                                        filter_category={this.props.filter_category}
                                        filter_combination={this.props.filter_combination}
                                        filter_method={this.props.filter_method}
                                        filter_stat_year={this.props.filter_stat_year}
                                    />
                                    {/**
                                    <button class="dialog-button" onClick={()=>this.props.displayIngredDetails(item)}>
                                        <p class="titxt">{item.combi_flag === "Y" && "(복합)"} {item.ingred_eng}</p>
                                        <p class="subtxt">{item.atc_name}</p>
                                    </button>
                                     */}
                                </div>
                                <div class="txtinfo td04">
                                {
                                    <Query query={PRODUCT_LIST} variables={{
                                        ingred_code_grp: item.ingred_code_grp,
                                        method: item.method,
                                        ingred_combi_flag: item.combi_flag,
                                        u_id: this.props.userProfile.u_id,
                                        u_spc_code: this.props.userProfile.u_spc_code,
                                        u_wtp_code: this.props.userProfile.u_wtp_code,
                                        u_hospital_group_code: this.props.userProfile.u_hospital_group_code,
                                        u_birthday: this.props.userProfile.u_birthday,
                                        u_sex: this.props.userProfile.u_sex,
                                        u_zip_zone: this.props.userProfile.u_zip_zone
                                    }}>
                                        {({ data, loading, error }) => {
                                            if (loading) return <p></p>
                                            if (error) return <p>{error.message}</p>;
                                            //console.log('2combi PRODUCT_LIST', data);
                                            const insurance_count = _.filter(data.getPdtList, ["insurance", "급여"]).length;

                                            return (
                                                <ProductList
                                                    data={data}
                                                    insurance_count={insurance_count}
                                                />
                                            )
                                        }}
                                    </Query>
                                }
                                </div>
                                <div class="txtinfo td05">
                                    <FavoriteFragment
                                        item={item}
                                        ctg_code_grp={this.props.ctg_code_grp}
                                        ctg_code3={this.props.ctg_code3}
                                        kcd_code_arr={this.props.kcd_code_arr}
                                        filter_combination={this.props.filter_combination}
                                        user={this.props.user}
                                        onLike={this.props.onLike}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            </Fragment>
        )
    }
}

export default Combi3Fragment;