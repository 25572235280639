import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";



const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function S_SalaryGuide() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const [dialogMaxWidth, setDialogMaxWidth] = React.useState("lg");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <span>
            <Button variant="outlined" color="primary" onClick={handleClickOpen} className="btnguide">
                이용가이드
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth={dialogMaxWidth} fullWidth={true} className="appguide">
                <DialogTitle id="max-width-dialog-title" >
                    <Grid xs={12} container>
                        <Grid xs={11} item className="txttit">
                            연봉 인덱스 이용가이드
                        </Grid>
                        <Grid item xs={1} style={{textAlign:"right",padding:"0"}}>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                              <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent >
                    {guideText()}
                </DialogContent>
            </Dialog>
        </span>
    );
}
const guideText = () => {
    return(
        <DialogContentText className="appguide-wrap">
            <Typography className="txtarea main">
                메디게이트 연봉 인덱스는 메디게이트 초빙구직 공고의 ‘봉직의’ 연봉 정보를 기반으로 구현됐습니다.
                일부 데이터의 경우 조건별 샘플수에 따라 체감 연봉과 차이를 보일 수 있습니다.
            </Typography>
            <Typography className="txtarea main">
                전문과별 집계 데이터 주의점: 메디게이트 초빙구직에 등록한 일부 공고의 경우, 구인 전문과를 ‘A전문과도 좋고 B전문과도 상관없다’는 의미에서 복수로 명시합니다. 전문과 관련 통계에선, 명시 전문과가 3개 이하일 때만 유효한 샘플로 정의해 구현했습니다.(아래 설명 참조)
            </Typography>
            <Typography className="txtarea sub1" style={{padding:"20px 20px 0"}}>
                - 공고A  ‘내과 or 가정의학과’ 명시 -> 전문과 종류가 3개 이하이므로, 전문과별 통계에서 내과와 가정의학과 케이스에 각각 샘플로 포함
            </Typography>
            <Typography className="txtarea sub1" style={{padding:"5px 20px"}}>
                - 공고B  ‘내과, 가정의학과, 재활의학과, 외과, 신경과’ 명시 -> 전문과 종류가 5개로, 전문과별 특성이 떨어지는 공고로 판단해 전문과별 통계에서 샘플 제외
            </Typography>
            <Typography className="txtarea sub" style={{padding:"0 20px 20px"}}>
                - 따라서, 전문과별 그래프에서 전문과별 샘플의 합이 전체 공고수와 차이를 보일 수 있습니다.
            </Typography>
            <Typography className="txtarea main">
                메디게이트 초빙구직 공고의 연봉 인덱스는 NET(월급)과 GROSS(연봉)중 하나만 입력되어 있습니다. 모든 샘플을 NET/GROSS 각각의 조건에 모두 포함하기 위해, NET-GROSS 간 실수령액 계산기를 활용했습니다. 전환 조건은 다음과 같이 일괄 처리했으니 참고하시길 바랍니다.
            </Typography>
            <Typography className="txtarea sub">
                - 2024년 근로소득 간이세액표 기준<br/>
                - 본인 : 1인 / 부양가족 : 없음 / 비과세 : 0원
            </Typography>
        </DialogContentText>)
}