import React, {Component, Fragment} from 'react';
import { withApollo } from 'react-apollo';
import {inject, observer} from "mobx-react";

import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Link from '@material-ui/core/Link';

import useStyles from './SalaryStyle';
import {withStyles} from "@material-ui/core/styles";
import {SERVICE_AD_LOG} from "../../queries/salary/salary";
import { isMobileOnly } from 'react-device-detect';
import CommonFunction from "../../common/CommonFunction";

@inject("salaryStore")
@observer
class S_RecjobADBox extends Component {
    recjobAdLog = (item) => {
        const salaryStore = this.props.salaryStore;
        let pathName = window.location.pathname;
        if(pathName === "/salary"){
            pathName = "/salary/payinfo";
        }

        let variables = {
            u_id: salaryStore.userProfile.u_id,
            svc_code: item.svc_code,
            svc_cont_idx: "" + item.svc_cont_idx,
            path_name: pathName
        }

        this.props.client.mutate({
            mutation: SERVICE_AD_LOG,
            variables: variables
        })
        .then((res) => {
            // //console.log('SERVICE_AD_LOG result ', res, res.data.createAdLog.result);
            //console.log("isMobileOnly ", isMobileOnly);
            if(res.data.createAdLog.result){
                let url = isMobileOnly ? `https://m.medigate.net/recruit/${item.svc_cont_idx}` : `https://www.medigate.net/recruit/${item.svc_cont_idx}`;
                window.open(url, '_blank')
            }
        })
        .catch((error) => {
            //console.log('error', error.message);
        });
    }

    render() {
        const {
            data=[],
        } = this.props;

        return (
            <Fragment>
                {
                    // Array.from(new Array(3)).map((item, index) => (

                    data.map((item) => {
                        return(
                            <Grid item xs={12} md={4} lg={4}>
                                <Paper className="fixedHeightPaper">
                                {
                                    item.svc_cont_idx === 0 ?
                                    <Typography component="div">
                                        <Typography component="p" style={{textAlign:"center"}} className="notice none">관련 초빙공고가 없습니다.</Typography>
                                    </Typography>
                                        :
                                    <Typography component="div">
                                        <Link
                                            component="span"
                                            style={{cursor: "pointer"}}
                                            onClick={() => {this.recjobAdLog(item); CommonFunction.pageview('연봉인덱스', '초빙광고 클릭', item.svc_cont_idx);}}
                                        >
                                            <Typography component="p" className="txtinfo1">
                                                <span>{item.org_name}</span>
                                            </Typography>
                                            <Typography component="p" className="txtinfo2">
                                                <span>{`${item.spc_name} / ${item.zon}`}</span>
                                            </Typography>
                                            <Typography component="p" className="txtinfo3">
                                                <div className="workingarea">
                                                    <p className="worktit"><span>{item.pay_type}</span></p>
                                                    <p className="workinfo"><span>{item.pay}</span></p>
                                                </div>
                                            </Typography>
                                            <Typography component="p" className="txtinfo4">
                                                <div className="workingarea">
                                                    <p className="worktit">근무시간</p>
                                                    <p className="workinfo">
                                                        <span>{item.work_hour}</span>
                                                        <span>{`${item.s_work_hour}, ${item.h_work_hour}`}</span>
                                                    </p>
                                                </div>
                                            </Typography>
                                        </Link>
                                    </Typography>
                                }
                                </Paper>
                            </Grid>
                        )
                    })

                    // ))
                }
            </Fragment>
        );
    }
}

export default withApollo(withStyles(useStyles)(S_RecjobADBox));