import React, {Component, Fragment} from 'react';
import _ from 'lodash';
import Chart from "react-google-charts";
import { isMobileOnly } from 'react-device-detect';

class ShareRateChart extends Component {
    render() {
        const { data } = this.props;

        //console.log("pre data", data.chart_data);

        let chartArr = [];
        let ingred_text1 = "";
        let ingred_text2 = "";
        let ingred_text3 = "";
        if(data.chart_data !== null && data.chart_data.length > 0) {
            data.chart_data.map((item, i) => {
                if (i === (data.chart_data.length - 1)) {
                    ingred_text1 = item.ingred_eng1;
                    ingred_text2 = item.ingred_eng2;
                    ingred_text3 = item.ingred_eng3;
                }
                let tmpArray = _.toArray(item);
                //tmpArray.unshift(i);
                chartArr.push(_.dropRight(tmpArray, 4));
            });
            chartArr.unshift(
                [
                    '',
                    ingred_text1,
                    {role: "annotation"},
                    ingred_text2,
                    {role: "annotation"},
                    ingred_text3,
                    {role: "annotation"}
                ]
            );
        }
        // //console.log("pre chartArr", chartArr)

        return (
            <Fragment>
                <p className="title-text">{data.max_year}년 기준 상위 3개 약물의 최근 {data.year_length}년간(데이터) 점유율 변화</p>
                <div className="App" style={{height: "300px"}}>
                    <Chart
                        width={'100%'}
                        height={'300px'}
                        chartType="LineChart"
                        loader={<div>Loading Chart</div>}
                        data={chartArr}
                        options={{
                            vAxis: {
                                format: '#\'%\'',
                                textStyle: {
                                    fontSize: 11,
                                    color: '#999'
                                }
                            },
                            legend: { position: 'top' },
                            title: ``,
                            titlePosition: "out",
                            annotations: {
                                textStyle: {
                                    fontSize: 13,
                                    bold: true,
                                    italic: true,
                                    color: '#1a1a1a',
                                    //auraColor: '#d799ae',
                                    opacity: 1
                                }
                            },
                            chartArea:{top:70, width:isMobileOnly ? '90%' : '93%', left: isMobileOnly ? 30 : 50},
                            hAxis: {
                                textStyle: {
                                    fontSize: 11,
                                    color: '#999'
                                }
                            },
                        }}
                        rootProps={{ 'data-testid': '3' }}
                    />
                </div>
            </Fragment>
        )
    }
}

export default ShareRateChart;