import React, { Fragment } from 'react';
import Select from 'react-select';
import { Query } from 'react-apollo';
import { FILTERATCCATEGORY_LIST } from '../../../queries/prescript/prescription';

class FilterATCCategory extends React.PureComponent {
    state = {
        selectedOption: null,
        placeholder: "ATC 카테고리"
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption: selectedOption });
        //console.log(`Option selected:`, selectedOption);

        this.props.onChangeFilterCategory(selectedOption);
    };

    render() {
        //console.log("CATEGORY STATE", this.state);
        return (
            <Fragment>
                <Query query={FILTERATCCATEGORY_LIST}
                    variables={{
                        kcd_code_arr: this.props.kcd_code_arr,
                        ctg_code3: this.props.ctg_code3,
                        dgsbjt_cd_arr: this.props.dgsbjt_cd_arr,
                        dgsbjt_dtl_cd_arr: this.props.dgsbjt_dtl_cd_arr,
                        agg_arr: this.props.agg_arr,
                        sex_tp_cd_arr: this.props.sex_tp_cd_arr,
                        cl_cd_arr: this.props.cl_cd_arr,
                        bed_arr: this.props.bed_arr,
                        sido_cd_arr: this.props.sido_cd_arr,
                        place: this.props.filter_place,
                        atc_code: this.props.filter_category,
                        combi_flag: this.props.filter_combination,
                        method: this.props.filter_method,
                        stat_year: this.props.filter_stat_year
                    }}
                >
                    {({ data, loading, error }) => {
                        if (loading) return <p></p>
                        if (error) return <p>{error.message}</p>;

                        const getAtcCodeList = [{ value: "", label: "전체" }].concat(data.getAtcCodeList)
                        //console.log('FILTERATCCATEGORY_LIST data', this.props.ctg_code3, data);

                        return (
                            <Select
                                placeholder={this.state.placeholder}
                                options={getAtcCodeList}
                                isSearchable={false}
                                onChange={this.handleChange}
                                value={this.props.filter_category_selectedOption}
                                styles={{
                                    option: base => ({
                                        ...base,
                                        borderBottom: `1px solid rgba(0,0,0,0.05)`,
                                        height: '100%',
                                        fontSize: '13px'
                                    }),
                                }}
                            />
                        )
                    }}
                </Query>
            </Fragment>
        )
    }
};

export default FilterATCCategory;