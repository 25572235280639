import React, { Fragment } from 'react';
import { withApollo } from 'react-apollo';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import $ from 'jquery';
import _ from 'lodash';
import { debounce } from 'throttle-debounce'
import Dexie from 'dexie';
import { isMobile } from 'react-device-detect';
import CommonFunc from '../../../common/CommonFunction';

const SEARCH_LOG_DB = 'SearchLog';
let delete_keyword_action = false;

class SearchKeywordInput extends React.PureComponent {

	state = {
		keyword: null,
		suggestions: [],
		filter_kcd_code_arr: this.props.filter_kcd_code_arr || [],
		enter_in_suggestions: false,
		scrollMoveTop: false,
	};

	addSearchLog = (keyword, suggestion) => {
		if(_.isEmpty(keyword)){
			return;
		}

		if(this.props.searchForm === 'HOME' || this.props.searchForm === 'PRESCRIPTION'){
			CommonFunc.pageview('처방정보', '검색', keyword); //Google Analytics
		}

		//INDEXED_DB
		var db = new Dexie(SEARCH_LOG_DB);
		db.version(1).stores({ search: "++id,keyword,kcd_code,kcd_eng" });
		db.transaction('rw', db.search, async() => {
			if ((await db.search.where('keyword').equals(keyword).count()) > 0) {
				let searchList = await db.search.where('keyword').equals(keyword).toArray();
				var idList = _.map(searchList, 'id')
				await db.search.bulkDelete(idList);
			}

			const insertData = { keyword: keyword, kcd_code: suggestion?suggestion.kcd_code:null, kcd_eng: suggestion?suggestion.kcd_eng: null };
			await db.search.add(insertData);

		}).catch(e => {
			//console.log(e.stack || e);
		});
	}

	deleteSearchLog = (keyword) => {
		delete_keyword_action = true;
		const thisObj = this;
		var db = new Dexie(SEARCH_LOG_DB);
		db.version(1).stores({ search: "++id,keyword,kcd_code,kcd_eng" });
		db.transaction('rw', db.search, async() => {
			// Make sure we have something in DB:
			//console.log('delete keyword count', await db.search.where('keyword').equals(keyword).count());
			if ((await db.search.where('keyword').equals(keyword).count()) > 0) {
				let searchList = (await db.search.where('keyword').equals(keyword).toArray());
				var idList = _.map(searchList, 'id')
				await db.search.bulkDelete(idList);
			}

			let searchList = await db.search.orderBy("id").reverse().toArray();

			const suggestions = searchList.map(item => {
				return { kcd_code: item.kcd_code, kcd_kor: item.keyword, kcd_eng: item.kcd_eng, previous_log: 'Y' }
			});
			thisObj.setState({
				keyword: null,
				suggestions: suggestions
			});

		}).catch(e => {
			//console.log(e.stack || e);
		});

		//console.log('deleteSearchLog delete_keyword_action', delete_keyword_action);
		setTimeout(function(){ thisObj.updateDeleteKeyowrdActionFlag(); }, 500);
	}

	updateDeleteKeyowrdActionFlag = () => {
		delete_keyword_action = false;
	}

	listSearchLog = (keyword) => {
		const thisObj = this;
		var db = new Dexie(SEARCH_LOG_DB);
		db.version(1).stores({ search: "++id,keyword,kcd_code,kcd_eng" });
		db.transaction('r', db.search, async() => {
			let searchList = await db.search.orderBy("id").reverse().toArray();
			let suggestions = searchList.map(item => {
				return { kcd_code: item.kcd_code, kcd_kor: item.keyword, kcd_eng: item.kcd_eng, previous_log: 'Y' }
			});

			if(!_.isEmpty(keyword) && suggestions.length > 0){
				//filtering 이전 검색어
				suggestions = suggestions.filter((item)=> item.kcd_kor.toLowerCase().indexOf(keyword.toLowerCase()) !== -1);
			}

			thisObj.setState({
				suggestions: suggestions
			});

		}).catch(e => {
			//console.log(e.stack || e);
		});
	}

	componentDidMount = () => {
		this.setState({
			keyword: null,
			suggestions: [],
			filter_kcd_code_arr: this.props.filter_kcd_code_arr || [],
			enter_in_suggestions: false,
			scrollMoveTop: false,
		});
	}

	doEnter = () => {
		//console.log('doEnter', this.state.keyword, this.state.enter_in_suggestions);
		if(_.isEmpty(this.state.keyword)){
			//clearInterval(this.searchEnterInterval);
			return;
		}

		//input box enter
		if(!this.state.enter_in_suggestions){
			const previous_suggest_list = _.filter(this.state.suggestions, {previous_log: 'Y'});
			//console.log('previous_suggest_list', previous_suggest_list.length, this.state.suggestions);
			if(this.state.suggestions.length > 0 && previous_suggest_list.length === 0){
				//console.log('case-1');
				this.doSelect(this.state.keyword, this.state.suggestions, 'ENTER_FROM_INPUT');
			}
			else if(this.state.suggestions.length === 0){
				//console.log('case-2');
				this.searchFromEngine(this.state.keyword, 'ENTER_FROM_INPUT');
			}
			else{
				//nothing
				//console.log('case-3', this.state.suggestions, previous_suggest_list.length););
			}
		}
	}

	doSelect = (keyword, suggestions, enter_type) => {
		let suggestions_match_keyword = null;
		if(suggestions.length <= 1){
			suggestions_match_keyword = suggestions;
		}
		else{
			//keyword match filtering
			const keywords = CommonFunc.replaceAll(CommonFunc.replaceAll(CommonFunc.replaceAll(keyword, '  ', ' '), '	', ' '), ',', ' ').split(' ');
			suggestions_match_keyword = [];
			suggestions.map((item, index) => {
				_.forEach(keywords, (k) => {
					const tmp_keyword = k.toLowerCase();
					if(!_.isEmpty(tmp_keyword) && (item.kcd_code.toLowerCase().indexOf(tmp_keyword) !== -1 || item.kcd_kor.toLowerCase().indexOf(tmp_keyword) !== -1 || item.kcd_eng.toLowerCase().indexOf(tmp_keyword) !== -1)){
						suggestions_match_keyword.push(item);
						return false;
					}
				})
			})
			suggestions_match_keyword = _.uniqWith(suggestions_match_keyword, _.isEqual); //remove duplicates
		}
		//console.log('suggestions_match_keyword', suggestions_match_keyword);

		this.props.onSearched(keyword, suggestions_match_keyword);
		if(enter_type === 'ENTER_FROM_INPUT'){
			this.addSearchLog(keyword, null);
		}
		else{
			this.addSearchLog(keyword, suggestions_match_keyword?suggestions_match_keyword[0]:null);
		}
		$('.react-autosuggest__suggestions-container').hide();
	}

	onKeyUp = (e) => {
		e.preventDefault();
		if(e.keyCode === 13 && this.props.searchForm !== 'KCD_ADD'){ //enter
			if(!_.isEmpty(this.state.keyword) && this.state.keyword.length >= 2){
				this.doEnter();
			}
			else{
				//nothing
			}
		}
	}

	searchByButton = (e) => {
		e.preventDefault();
		if(!_.isEmpty(this.state.keyword) && this.state.keyword.length >= 2){
			this.doEnter();
		}
	}

	onChange = (event, { newValue, method }) => {
		//console.log('onChange', newValue, event.keyCode, method);
		this.setState({
			keyword: newValue
		});

		if(!$('.react-autosuggest__suggestions-container').is(':visible')){
			$('.react-autosuggest__suggestions-container').show();
		}
	};

	searchFromEngine = (keyword, inputType = '') => {
		let isKcdSearch = false;
		if(keyword.length >= 2){
			const isAlpha = /^[a-zA-Z()]+$/.test(keyword.substring(0, 1));
			const isNumeric = !isNaN(keyword.substring(1));
			isKcdSearch = isAlpha && isNumeric;
			//console.log('isKcdSearch', keyword, isKcdSearch);
		}
		let searchEngineUrl = null;
		if(!isKcdSearch){
			searchEngineUrl = "https://search.medigate.net/search.jsp?query=" + encodeURIComponent(keyword) + "&collection=kcd&colldisplay=100&sort=kcd@order_cnt/DESC";
		}
		else{
			const kcd_keyword = keyword.replace(new RegExp('\\.', 'g'), '');
			searchEngineUrl = "https://search.medigate.net/search.jsp?query=" + encodeURIComponent(kcd_keyword) + "&collection=kcd&sfield=kcd@kcd_code&sort=kcd@RANK/DESC";
		}
		//console.log('searchEngineUrl', searchEngineUrl);
		//console.log('inputType', inputType);
		const filter_kcd_code_arr = this.state.filter_kcd_code_arr;

		axios.get(searchEngineUrl)
		.then((res) => {
			//console.log('search_result', res.data.TotalCount, res.data.Data[0].Result);
			if(res.data.ReturnMsg === "success"){
				if(res.data.TotalCount > 0){
					let kcd_list = res.data.Data[0].Result.map(item => {
						const kcd_kor = CommonFunc.getKcdNameConv(item.kcd_kor);
						const kcd_eng = CommonFunc.getKcdNameConv(item.kcd_eng);
						return { kcd_code: item.kcd_code, kcd_kor: kcd_kor, kcd_eng: kcd_eng, wikiKeyword: item.keyword, order_cnt: item.order_cnt }
					});

					//KCD CODE 검색 시 kcd_code 순으로 정렬
					if(isKcdSearch){
						kcd_list = _.orderBy(kcd_list, [item => item.kcd_code.toUpperCase()], ['ASC']);
					}
					//console.log('kcd_list', keyword, kcd_list);

					//상병 추가 시 동일한 상병코드는 제외
					if(filter_kcd_code_arr && filter_kcd_code_arr.length > 0 && kcd_list.length > 0){
						const kcd_list_without_duplicate = kcd_list.filter(function(item) {
							return !filter_kcd_code_arr.includes(item.kcd_code);
						})
						//console.log('skip_kcd_code kcd_list', kcd_list);
						this.setState({
							suggestions: kcd_list_without_duplicate
						});
					}
					else{
						this.setState({
							suggestions: kcd_list
						}, () => {
							if(inputType === 'ENTER_FROM_INPUT'){
								//console.log('enter ---> ', this.state.keyword, this.state.suggestions);
								this.doSelect(this.state.keyword, this.state.suggestions, 'ENTER_FROM_INPUT');
							}
						});
					}
				}
				else{
					this.setState({
						suggestions: []
					}, () => {
						if(inputType === 'ENTER_FROM_INPUT'){
							//console.log('enter ---> ', this.state.keyword, this.state.suggestions);
							this.doSelect(this.state.keyword, this.state.suggestions, 'ENTER_FROM_INPUT');
						}
					});
				}
			}
		})
		.catch((error) => {
			//console.log('error', error.message);
		});
	}

	onSuggestionsFetchRequested = ({ value, reason }) => {
		if((this.props.searchForm === 'HOME' || this.props.searchForm === 'PRESCRIPTION') && isMobile){
			this.scrollToSearchbox();
		}

		if(_.isEmpty(value) || value.length < 2){
			if(this.props.searchForm !== 'KCD_ADD'){
				this.listSearchLog(value);
			}
		}
		else if(value.length >= 2){
			this.searchFromEngine(value, 'nothing');
		}
		else{
			this.setState({
				suggestions: []
			});
		}
	};

	onSuggestionsClearRequested = () => {
		//초기화
		this.setState({
			enter_in_suggestions: false,
			//keyword: this.props.keyword
		})
	};

	onSuggestionSelected = (event, { suggestion, method }) => {
		//console.log('onSuggestionSelected delete_keyword_action', delete_keyword_action)
		//키워드 삭제 선택 시 진행하지 않음
		if(delete_keyword_action){
			return;
		}

		//console.log('onSuggestionSelected', suggestion, suggestion.previous_log);
		if(method === "click" || method === "enter"){
			if(suggestion.previous_log){ //이전 검색어를 통한 검색 -> 검색엔진을 통해 재검색 후 이동
				//this.searchFromLog(suggestion);
				const keyword = suggestion.kcd_kor;
				this.setState({
					keyword: keyword
				}, () => {
					this.searchFromEngine(keyword, 'ENTER_FROM_INPUT');
				})
			}
			else{
				const suggestions = [ {kcd_code: suggestion.kcd_code, kcd_kor: suggestion.kcd_kor, kcd_eng: suggestion.kcd_eng, wikiKeyword: suggestion.wikiKeyword, order_cnt: suggestion.order_cnt} ];
				let keyword = suggestion.kcd_kor;

				if(this.props.searchForm === 'KCD_ADD'){
					keyword = ''; //상병 추가 화면에서 선택 시 상병명을 공백 처리
				}

				this.setState({
					keyword: keyword,
					enter_in_suggestions: true,
					suggestions: suggestions
				})
				//console.log('onSuggestionSelected called', keyword, suggestions);
				this.doSelect(keyword, suggestions, 'ENTER_FROM_SUGGESTION');
			}
		}
	}

	renderSuggestion = suggestion => {
		return (
			<Fragment>
			{
				suggestion.previous_log ?
				<div className="search-list">
					<div className="txtinfo">
						<p className="maintxt">{suggestion.kcd_kor}</p>
						<p className="subtxt remove" onClick={()=>this.deleteSearchLog(suggestion.kcd_kor)}>삭제</p>
					</div>
				</div>
				:
				<div className="search-list">
					<div className="num">{suggestion.kcd_code}</div>
					<div className="txtinfo">
						<p className="maintxt">{suggestion.kcd_kor}</p>
						<p className="subtxt">{suggestion.kcd_eng}</p>
					</div>
				</div>
			}
			</Fragment>
		);
	}

	scrollToSearchbox = () => {
		this.setState({
			scrollMoveTop: true
		}, () => {
			const offset = $(".searcharea").offset().top - 10;
			$('html, body').animate({'scrollTop': offset}, 300);
        });
    }

	shouldRenderSuggestions = () => {
		return true;
	}

	componentWillMount = () => {
		this.onSuggestionsFetchRequested = debounce(
			100,
			this.onSuggestionsFetchRequested
		)
	}

	render() {
		const { suggestions } = this.state;
		//console.log('suggestions----------- ', suggestions);

		const keyword = (this.state.keyword !== null ? this.state.keyword : this.props.keyword) || '';
		let placeholder_text = "";
		if(!isMobile){
			if(this.props.searchForm !== 'KCD_ADD'){
				placeholder_text = "질환 관련 키워드(한/영)나 상병코드를 입력하세요";
			}else{
				placeholder_text = "추가할 질환 키워드나 코드를 입력하세요";
			}
		}else{
			placeholder_text = "질환 키워드(한/영)나 상병코드 입력";
		}
		const inputProps = {
			placeholder: placeholder_text,
			value: keyword,
			onChange: this.onChange,
			onKeyUp: this.onKeyUp,
			id: "app"
		};

		return (
			<Fragment>
				{
					this.props.searchForm === 'HOME' ?
						<div id="searcharea">
							<h1 className="schtitle">어떤 질환의 처방을 알고 싶나요?
                                <span>건강보험심사평가원의 데이터 공개 정책 변경에 따라 2019년 이후의 표본데이터를 제공 받을 수 없게 되어<br/>현재의 처방정보는 2018년 까지의 표본데이터 기준임을 알려 드립니다. (2021.8.5)</span>
                            </h1>
							<div className="searcharea">
								<div className="schwrap">
									<Autosuggest
										suggestions={suggestions}
										onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
										onSuggestionsClearRequested={this.onSuggestionsClearRequested}
										onSuggestionSelected={this.onSuggestionSelected}
										getSuggestionValue={suggestion => suggestion.kcd_kor}
										renderSuggestion={this.renderSuggestion}
										shouldRenderSuggestions={this.shouldRenderSuggestions}
										inputProps={inputProps}
										focusInputOnSuggestionClick={false}
									/>
									<div className="search_btn" onClick={(e)=>this.searchByButton(e)}>search</div>
								</div>
							</div>
						</div>
					: this.props.searchForm === 'PRESCRIPTION' ?
						<div className="header-field menu_schbar">
							<div className="searcharea">
								<div className="schwrap">
									<Autosuggest
										suggestions={suggestions}
										onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
										onSuggestionsClearRequested={this.onSuggestionsClearRequested}
										onSuggestionSelected={this.onSuggestionSelected}
										getSuggestionValue={suggestion => suggestion.kcd_kor}
										renderSuggestion={this.renderSuggestion}
										shouldRenderSuggestions={this.shouldRenderSuggestions}
										inputProps={inputProps}
										focusInputOnSuggestionClick={false}
									/>
									<div className="search_btn" onClick={(e)=>this.searchByButton(e)}>search</div>
								</div>
							</div>
						</div>
					: this.props.searchForm === 'KCD_ADD' ?
						<div className="header-field menu_schbar">
							<div className="searcharea">
								<div className="schwrap">
									<Autosuggest
										suggestions={suggestions}
										onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
										onSuggestionsClearRequested={this.onSuggestionsClearRequested}
										onSuggestionSelected={this.onSuggestionSelected}
										getSuggestionValue={suggestion => suggestion.kcd_kor}
										renderSuggestion={this.renderSuggestion}
										inputProps={inputProps}
									/>
								</div>
							</div>
						</div>
					: ''
				}

				{/* this.state.scrollMoveTop &&
					<div style={{height:'600px'}}></div>
				*/}
			</Fragment>
		);
	}
}

export default withApollo(SearchKeywordInput);
