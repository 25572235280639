import React, { Fragment } from 'react';

class Result_PlaceHolder extends React.Component {

	render() {
        return (
            <Fragment>
				<div className="wrap_druglist">
					<div className="schoption_wrap loading">
						<div className="schoption type1"><div className="css-2b097c-container"></div></div>
						<div className="schoption type2"><div className="css-2b097c-container"></div></div>
						<div className="schoption type3"><div className="css-2b097c-container"></div></div>
						<div className="schoption type4"><div className="css-2b097c-container"></div></div>
					</div>
				</div>
				<div className="loading">
					<ul className="druglist depth1">
						<li>
							<div className="contlist tit">
								<div className="txtinfo td01">&nbsp;</div>
								<div className="txtinfo td02">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td06">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td03">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td04">
									<div className="css-2b097c-container"></div>
								</div>
							</div>
						</li>
						<li>
							<div className="contlist">
								<div className="txtinfo td01">
									<div className="css-2b097c-container" style={{width: "100%", height: "38px"}}></div>
								</div>
								<div className="txtinfo td02">
									<div className="css-2b097c-container" style={{width: "100%", height: "38px"}}></div>
								</div>
								<div className="txtinfo td06">
									<div className="css-2b097c-container" style={{width: "100%", height: "38px"}}></div>
								</div>
								<div className="txtinfo td03">
									<div className="dialog-button">
										<div className="titxt">
											<div className="css-2b097c-container" style={{width: "100%", height: "25px"}}></div>
										</div>
										<div className="subtxt">
											<div className="css-2b097c-container" style={{width: "100%", height: "10px"}}></div>
										</div>
									</div>
								</div>
								<div className="txtinfo td04">
									<div className="css-2b097c-container" style={{width: "100%", height: "38px"}}></div>
								</div>
							</div>
						</li>
						<li>
							<div className="contlist">
								<div className="txtinfo td01">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td02">
									<div className="css-2b097c-container" ></div>
								</div>
								<div className="txtinfo td06">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td03">
									<div className="dialog-button">
										<div className="titxt">
											<div className="css-2b097c-container"></div>
										</div>
										<div className="subtxt">
											<div className="css-2b097c-container"></div>
										</div>
									</div>
								</div>
								<div className="txtinfo td04">
									<div className="css-2b097c-container"></div>
								</div>
							</div>
						</li>
						<li>
							<div className="contlist">
								<div className="txtinfo td01">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td02">
									<div className="css-2b097c-container" ></div>
								</div>
								<div className="txtinfo td06">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td03">
									<div className="dialog-button">
										<div className="titxt">
											<div className="css-2b097c-container"></div>
										</div>
										<div className="subtxt">
											<div className="css-2b097c-container"></div>
										</div>
									</div>
								</div>
								<div className="txtinfo td04">
									<div className="css-2b097c-container"></div>
								</div>
							</div>
						</li>
						<li>
							<div className="contlist">
								<div className="txtinfo td01">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td02">
									<div className="css-2b097c-container" ></div>
								</div>
								<div className="txtinfo td06">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td03">
									<div className="dialog-button">
										<div className="titxt">
											<div className="css-2b097c-container"></div>
										</div>
										<div className="subtxt">
											<div className="css-2b097c-container"></div>
										</div>
									</div>
								</div>
								<div className="txtinfo td04">
									<div className="css-2b097c-container"></div>
								</div>
							</div>
						</li>
						<li>
							<div className="contlist">
								<div className="txtinfo td01">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td02">
									<div className="css-2b097c-container" ></div>
								</div>
								<div className="txtinfo td06">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td03">
									<div className="dialog-button">
										<div className="titxt">
											<div className="css-2b097c-container"></div>
										</div>
										<div className="subtxt">
											<div className="css-2b097c-container"></div>
										</div>
									</div>
								</div>
								<div className="txtinfo td04">
									<div className="css-2b097c-container"></div>
								</div>
							</div>
						</li>
						<li>
							<div className="contlist">
								<div className="txtinfo td01">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td02">
									<div className="css-2b097c-container" ></div>
								</div>
								<div className="txtinfo td06">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td03">
									<div className="dialog-button">
										<div className="titxt">
											<div className="css-2b097c-container"></div>
										</div>
										<div className="subtxt">
											<div className="css-2b097c-container"></div>
										</div>
									</div>
								</div>
								<div className="txtinfo td04">
									<div className="css-2b097c-container"></div>
								</div>
							</div>
						</li>
						<li>
							<div className="contlist">
								<div className="txtinfo td01">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td02">
									<div className="css-2b097c-container" ></div>
								</div>
								<div className="txtinfo td06">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td03">
									<div className="dialog-button">
										<div className="titxt">
											<div className="css-2b097c-container"></div>
										</div>
										<div className="subtxt">
											<div className="css-2b097c-container"></div>
										</div>
									</div>
								</div>
								<div className="txtinfo td04">
									<div className="css-2b097c-container"></div>
								</div>
							</div>
						</li>
						<li>
							<div className="contlist">
								<div className="txtinfo td01">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td02">
									<div className="css-2b097c-container" ></div>
								</div>
								<div className="txtinfo td06">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td03">
									<div className="dialog-button">
										<div className="titxt">
											<div className="css-2b097c-container"></div>
										</div>
										<div className="subtxt">
											<div className="css-2b097c-container"></div>
										</div>
									</div>
								</div>
								<div className="txtinfo td04">
									<div className="css-2b097c-container"></div>
								</div>
							</div>
						</li>
						<li>
							<div className="contlist">
								<div className="txtinfo td01">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td02">
									<div className="css-2b097c-container" ></div>
								</div>
								<div className="txtinfo td06">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td03">
									<div className="dialog-button">
										<div className="titxt">
											<div className="css-2b097c-container"></div>
										</div>
										<div className="subtxt">
											<div className="css-2b097c-container"></div>
										</div>
									</div>
								</div>
								<div className="txtinfo td04">
									<div className="css-2b097c-container"></div>
								</div>
							</div>
						</li>
						<li>
							<div className="contlist">
								<div className="txtinfo td01">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td02">
									<div className="css-2b097c-container" ></div>
								</div>
								<div className="txtinfo td06">
									<div className="css-2b097c-container"></div>
								</div>
								<div className="txtinfo td03">
									<div className="dialog-button">
										<div className="titxt">
											<div className="css-2b097c-container"></div>
										</div>
										<div className="subtxt">
											<div className="css-2b097c-container"></div>
										</div>
									</div>
								</div>
								<div className="txtinfo td04">
									<div className="css-2b097c-container"></div>
								</div>
							</div>
						</li>
					</ul>
				</div>
            </Fragment>
        )
    }
}

export default Result_PlaceHolder;