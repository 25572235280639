import React, {Fragment} from 'react';
import { withCookies } from 'react-cookie';

import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import useLocalStorage from "react-use-localstorage";


const ServicePolicy = (props) => {

    const {handleFunction} = props;
    const {cookieName} = props;
    const {type="p"} = props; //s : 연봉 인덱스, p: 처방정보, l: 입지분석
    const { cookies } = props;

    React.useEffect(()=>{
        // console.log("ServicePolicy props??", props);

        /*
        Session Storage Date set
        * */
/*        sessionStorage.setItem("lPolicySession","n"); //location
        sessionStorage.setItem("pPolicySession","n"); //prescription
        sessionStorage.setItem("sPolicySession","n"); //salary*/
    },[])

    const salaryContent = () =>
        <Fragment>
            <Typography gutterBottom variant="subtitle1" className="subtitle">
                연봉인덱스
            </Typography>
            <Typography variant="body2" className="txtarea main">
                메디게이트 연봉 인덱스는 메디게이트 초빙구직 공고의 ‘봉직의’ 연봉 정보를 기반으로 구현됐습니다.
                일부 데이터의 경우 조건별 샘플수에 따라 체감 연봉과 차이를 보일 수 있습니다.
            </Typography>
            <Typography variant="body2" className="txtarea main">
                전문과별 집계 데이터 주의점: 메디게이트 초빙구직에 등록한 일부 공고의 경우, 구인 전문과를 ‘A전문과도 좋고 B전문과도 상관없다’는 의미에서 복수로 명시합니다. 전문과 관련 통계에선, 명시 전문과가 3개 이하일 때만 유효한 샘플로 정의해 구현했습니다.(아래 설명 참조)
            </Typography>
            <Typography variant="body2" className="txtarea sub1" style={{padding:"20px 20px 0"}}>
                - 공고A  ‘내과 or 가정의학과’ 명시 -> 전문과 종류가 3개 이하이므로, 전문과별 통계에서 내과와 가정의학과 케이스에 각각 샘플로 포함
            </Typography>
            <Typography variant="body2" className="txtarea sub1" style={{padding:"5px 20px"}}>
                - 공고B  ‘내과, 가정의학과, 재활의학과, 외과, 신경과’ 명시 -> 전문과 종류가 5개로, 전문과별 특성이 떨어지는 공고로 판단해 전문과별 통계에서 샘플 제외
            </Typography>
            <Typography variant="body2" className="txtarea sub" style={{padding:"0 20px 20px"}}>
                - 따라서, 전문과별 그래프에서 전문과별 샘플의 합이 전체 공고수와 차이를 보일 수 있습니다.
            </Typography>
            <Typography variant="body2" className="txtarea main">
                메디게이트 초빙구직 공고의 연봉 인덱스는 NET(월급)과 GROSS(연봉)중 하나만 입력되어 있습니다. 모든 샘플을 NET/GROSS 각각의 조건에 모두 포함하기 위해, NET-GROSS 간 실수령액 계산기를 활용했습니다. 전환 조건은 다음과 같이 일괄 처리했으니 참고하시길 바랍니다.
            </Typography>
            <Typography variant="body2" className="txtarea sub">
                - 2024년 근로소득 간이세액표 기준<br/>
                - 본인 : 1인 / 부양가족 : 없음 / 비과세 : 0원
            </Typography>
        </Fragment>;

    const locationContent = () =>
        <Fragment>
            <Typography gutterBottom variant="subtitle1" className="subtitle">
                입지분석
            </Typography>
            <Typography variant="body2" className="txtarea main">
                메디게이트 입지정보는 의료기관정보 및 진료청구통계(건강보험심사평가원), 인구/상권정보(통계지리정보서비스&소상공인진흥공단)와 임대분양 매물정보(메디게이트)를 기반으로 구현됐습니다.
            </Typography>
            <Typography variant="body2" className="txtarea main">
                일부 공공 데이터의 경우, 업데이트 반영이 늦어 실제와 다를 수 있습니다. 정보 주체자가 정보 오류를 확인 후 수정이 필요하다고 판단한 경우, 해당 데이터를 관리하는 기관에 직접 요청하셔야 합니다.
            </Typography>
            <Typography variant="body2" className="txtarea main">
                서비스 내에서 제공하는 지역별 질환 처방 정보는 보험심사평가원에서 수집한 ＇의원급＇ 급여 처방 데이터 중, 매월 처방액 기준으로 시군구별 & 전문과별 상위 50개 질환만을 추출해 구성했습니다.
            </Typography>
            <Typography variant="body2" className="txtarea main">
                지역별 질환 처방 정보의 경우, 완벽한 통계가 아닌, 지역별 주요 상위 질환을 비교하기 위한 용도입니다. 이 데이터는 특히 다음과 같은 상황에서 해석에 주의해야 합니다.
            </Typography>
            <Typography variant="body2" className="txtarea sub">
                - 일부 전문과에서만 처방하는 빈도가 아주 낮은 질환
            </Typography>
        </Fragment>;

    const prescriptionContent = () =>
        <Fragment>
            <Typography gutterBottom variant="subtitle1" className="subtitle">
                처방정보
            </Typography>
            <Typography variant="body2" className="txtarea main">
                메디게이트 처방정보는 보험심사평가원에서 제공하는 환자표본자료 중, 입원환자데이터셋(HIRA-NIS)과 전체환자 데이터셋(HIRA-NPS)을 기반으로 구현됐습니다.
            </Typography>
            <Typography variant="body2" className="txtarea main">
                환자표본자료는 최종 급여 인정된 연간 처방 샘플을 추출합니다. 보험심사 기간과 데이터 처리 기간으로 인해 이전 연도 데이터는 확인할 수 없고, 비급여 항목은 포함하지 않습니다.
            </Typography>
            <Typography variant="body2" className="txtarea main">
                외래환자의 경우, 전체환자데이터셋(HIRA-NPS)을 활용했고,  데이터 특성은 다음과 같습니다.
            </Typography>
            <Typography variant="body2" className="txtarea sub1" style={{padding:"20px 20px 0"}}>
                - 2009~2016년 데이터셋 :  전체환자 추출비율 3%(약 140만 명)의 해당연도 모든 원외 급여 의료활동
            </Typography>
            <Typography variant="body2" className="txtarea sub" style={{padding:"5px 20px 20px"}}>
                - 2017년 이후 데이터셋 : 전체환자 추출비율 3%(약 145만 명)의 해당연도 모든 원외 급여 의료활동
            </Typography>
            <Typography variant="body2" className="txtarea main">
                입원환자의 경우, 입원환자데이터셋(HIRA-NIS)을 활용했고,  데이터 특성은 다음과 같습니다.
            </Typography>
            <Typography variant="body2" className="txtarea sub1" style={{padding:"20px 20px 0"}}>
                - 2009~2016년 데이터셋 :  전체환자 추출비율 13%(약 100만 명)의 해당연도 모든 원내 급여 의료활동
            </Typography>
            <Typography variant="body2" className="txtarea sub" style={{padding:"5px 20px 20px"}}>
                - 2017년 이후 데이터셋 : 전체환자 추출비율 10%(약 75만 명)의 해당연도 모든 원내 급여 의료활동
            </Typography>
        </Fragment>;

    const [pPolicyChecked, setPpolicyChecked] = useLocalStorage('pPolicyChecked', 'n');
    const [sPolicyChecked, setSpolicyChecked] = useLocalStorage('sPolicyChecked', 'n');
    const [lPolicyChecked, setLpolicyChecked] = useLocalStorage('lPolicyChecked', 'n');
    const handleCheck = (event) => {
        const isChecked = event.target.checked;

        if(type === "p"){
            if(isChecked){
                setPpolicyChecked('y');
            } else {
                setPpolicyChecked('n');
            }
        } else if(type === "s"){
            if(isChecked){
                setSpolicyChecked('y');
            } else {
                setSpolicyChecked('n');
            }
        } else if(type === "l"){
            if(isChecked){
                setLpolicyChecked('y');
            } else {
                setLpolicyChecked('n');
            }
        }

    }

    return(
        <Box className="checkpolicy">
            <Grid item container direction="column" className="wraparea">
                <Grid item xs className="txtinfo">
                    { type === "p" && prescriptionContent()}
                    { type === "s" && salaryContent()}
                    { type === "l" && locationContent()}
                    <Grid className="checkwrap">
                        <FormControlLabel
                            className="checkarea"
                            control={
                                <Checkbox
                                    onChange={(event) => {
                                        //console.log("ServicePolicy checkbox onchange", (event.target.checked)?"Y":"N");
                                        // cookies.set(cookieName, (event.target.checked)?"Y":"N");
                                        handleCheck(event);
                                    }}
                                    name="checkeAgree"
                                />
                            }
                            label="상기 설명을 감안하고, 서비스를 이용합니다.​"
                            labelPlacement="right"
                        />
                        <Button className="button" onClick={()=>{handleFunction(true)}}>확인</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default withCookies(ServicePolicy);
