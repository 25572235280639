import React, { Fragment } from 'react';
import _ from 'lodash';
import $ from 'jquery';
import { isMobileOnly, isIE, isFirefox } from 'react-device-detect';
import { PowerSelect } from 'react-power-select';
import { confirmAlert } from 'react-confirm-alert';
import HtmlConfirm from '../../../common/HtmlConfirm';

import 'react-power-select/dist/react-power-select.css';
import CommonFunc from '../../../common/CommonFunction';

class ProductList extends React.PureComponent {

    constructor(props) {
        super();

        const bannerList = _.filter(props.data.getPdtList, {pdt_type: 'AD'}); //only first one
        let bannerInfo = null;
        if(bannerList && bannerList.length > 0){
            bannerInfo = bannerList[0];
        }

        let date = CommonFunc.getDateIntervalMonth(-1); // 배치를 고려해 현재월 -1
        this.state = {
            defaultBannerOption: bannerInfo,
            selectedOption: null,
            pdt_placeholder: `급여 제품을 확인하세요(기준: ${date})`
        }
    }

    selectProduct = selectedOption => {
        if(selectedOption.pdt_type === 'NORMAL'){
            if(selectedOption.pmt_code !== "" && selectedOption.insurance !== "비급여"){
                let url = "https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetail?itemSeq=" + selectedOption.pmt_code;
                window.open(url, '_blank');
            }else{
                confirmAlert({ customUI: ({ onClose }) => <HtmlConfirm message={"비급여 약물은 관련 정보를 제공하지 않습니다."} onClose={onClose} />});
                return false;
            }

            const ga_value = `${selectedOption.cpn_name},${selectedOption.pdt_name},${selectedOption.unit_amt},${selectedOption.insurance}`;
            CommonFunc.pageview('처방정보', '상품_GNL', ga_value); //Google Analytics
        }
        else{
            window.open(selectedOption.link, '_blank');

            const ga_value = `${selectedOption.pdt_name}`;
            CommonFunc.pageview('처방정보', '상품_AD', ga_value); //Google Analytics
        }
    }

    handleChange = ({ option }) => {
		//console.log('handleChange', option);
		this.setState({
			selectedOption: option
        })
        this.selectProduct(option);
    }

    componentDidMount = () => {
        if(this.props.from_type === "dialog"){
            setTimeout(() => {
                if(isMobileOnly){
                    $(".MuiDialog-container.MuiDialog-scrollPaper").removeAttr("tabindex");
                }else{
                    $(".MuiDialog-container.MuiDialog-scrollBody").removeAttr("tabindex");
                    if(isFirefox){
                        $(".MuiDialog-container").css("height", "auto");
                    }
                }
            }, 500);
        }
    }

    render() {
        const { defaultBannerOption, pdt_placeholder } = this.state;
        let searchEnabledFlag = true;
        if(isIE && this.props.from_type === "dialog"){
            searchEnabledFlag = false;
        }

        return (
            <Fragment>
                <PowerSelect
                    searchEnabled={searchEnabledFlag}
                    searchInputAutoFocus={isMobileOnly?false:true}
                    options={this.props.data.getPdtList}
                    selected={defaultBannerOption}
                    placeholder={`${this.props.insurance_count}개의 ${pdt_placeholder}`}
                    searchPlaceholder={'검색하려면 제품명을 입력하세요'}
                    optionLabelPath={['cpn_name','pdt_name','unit_amt','insurance']}
                    searchFields={['cpn_name','pdt_name','unit_amt','insurance']}
                    onChange={this.handleChange}
                    /* 목록 상단 */
                    /*
                    beforeOptionsComponent={() => {
                        return (
                            <div className="options-header">First Item Of List</div>
                        )
                    }}
                    */
                    /* 제품 목록 */
                    optionComponent={({ option }) => {
                        return (
                            <div className="product">
                                { option.pdt_type === 'NORMAL' ?
                                    <div className="info">
                                        <span className='cpn_name'>{option.cpn_name}</span>
                                        <span className='pdt_name'>{option.pdt_name}&nbsp;</span>
                                        {/*<div className='pdt_name' title={option.pdt_name} style={{maxWidth:'200px', maxHeight: '30px', overflowX:'hidden', overflowY:'hidden'}}>{option.pdt_name}</div>*/}
                                        <span className='unit_amt'>{!_.isEmpty(option.unit_amt) ? "- " : ""}{option.unit_amt}</span>
                                        <span className={option.insurance === '급여' ? 'insurance icon': 'insurance'}>{option.insurance}</span>
                                    </div>
                                    :
                                    <Fragment>
                                        <div className='sponsored'></div>
                                        <div className="info">
                                            <span className='pdt_name'>{option.pdt_name}</span>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        )
                    }}
                    /* 기본 배너 노출에서 활용 (from defaultBannerOption) */
                    selectedOptionComponent={({ option }) => {
                        return (
                            <div className="product">
                                { option.pdt_type === 'NORMAL' ?
                                    <div className="info">
                                        <span className='cpn_name'>{option.cpn_name}</span>
                                        <span className='pdt_name'>{option.pdt_name}&nbsp;</span>
                                        {/*<div className='pdt_name' title={option.pdt_name} style={{maxWidth:'200px', maxHeight: '30px', overflowX:'hidden', overflowY:'hidden'}}>{option.pdt_name}</div>*/}
                                        <span className='unit_amt'>{!_.isEmpty(option.unit_amt) ? "- " : ""}{option.unit_amt}</span>
                                        <span className='insurance'>{option.insurance}</span>
                                    </div>
                                    :
                                    <Fragment>
                                        <div className='sponsored'></div>
                                        <div className="info">
                                            <span className='pdt_name'>{option.pdt_name}</span>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        )
                    }}
                />
            </Fragment>
        )
    }
}

export default ProductList;