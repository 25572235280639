import React, {useState, useEffect, Fragment} from 'react';

import withPopper from "./withPopper";
import PopperSettings from "./PopperSettings";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import StarsIcon from "@material-ui/icons/Stars";
import IconButton from "@material-ui/core/IconButton";
import {Box} from "@material-ui/core";
import DetailLayout from "./DetailLayout";
import withCodes from "./withCodes";
import PopperSpecialty from "./PopperSpecialty";
import StarBorderIcon from '@material-ui/icons/StarBorder';

function MySettingsContainer(props){

    /* mySettins ksseo */
    const [mySettingAnchorEl, setMySettingAnchorEl] = useState(null);
    const [mySettingOpen, setMySettingOpen] = useState(false);
    const [myHistoryOpen, setMyHistoryOpen] = useState(false);            // 조회이력보기 dialog state

    //console.log("ks settingsOpen1", mySettingOpen)

    // 조회이력보기 dialog state
    const handleClickOpen = () => {
        setMyHistoryOpen(true);
    };

    const handleClose = () => {
        setMyHistoryOpen(false);
    };

    const handleMySettingClose = () => {
        setMySettingOpen(false);
    }

    const toggleSettingsPopper = (e) => {
        if(mySettingOpen){
            let isPopperContain = document.getElementById("popperSettings").contains(e.target);
            if((!isPopperContain || e.target.name == "closePopper") && !myHistoryOpen) {
                setMySettingOpen(false);
            }
        }
    };

    const handleMyMenu = event => {
        setMySettingAnchorEl(event.currentTarget);
        setMySettingOpen(true);
    };

    return (
        <Fragment>
            <ClickAwayListener onClickAway={toggleSettingsPopper}>
                <IconButton
                    aria-label="my historys"
                    aria-controls="menu-my"
                    aria-haspopup="true"
                    onClick={handleMyMenu}
                    color="inherit"
                    disableRipple="true"
                    //onClose={handleMyMenuClose}
                >
                    <StarBorderIcon />
                    <Box mt={5}>
                        {
                            withPopper(withCodes(PopperSettings))(
                                {
                                    anchorEl: mySettingAnchorEl,
                                    open: mySettingOpen,
                                    historyOpen: myHistoryOpen,
                                    handleDetail: handleClickOpen,
                                    handleMySettingClose: handleMySettingClose
                                }
                            )
                        }
                    </Box>
                </IconButton>
            </ClickAwayListener>
            <DetailLayout
                open={myHistoryOpen}
                handleOpen={handleClose}
                myHistory="true"
            />
        </Fragment>
    )
}

export default MySettingsContainer;