import React, {Component, Fragment} from 'react';
import {inject, observer} from "mobx-react";

import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import STitle from './S_Title';

import useStyles from './SalaryStyle';

@inject("salaryStore")
@observer
class S_NotLogin extends Component {
    openLogin = () => {
        const { salaryStore } = this.props;
        salaryStore.setLoginLayerShow(salaryStore.loginLayerShow);
    }

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid item xs={12} className="lognotice">
                        <Paper className={classes.notLoginPaper}>
                            <STitle>닥터게이트 연봉 인덱스는<br/>메디게이트 의사 로그인이 필요합니다.</STitle>
                            <div className={classes.textCenter}>
                                <Button variant="contained" color="primary" onClick={this.openLogin}>
                                    로그인
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Container>
            </Fragment>
        );
    }
}

export default withStyles(useStyles)(S_NotLogin)