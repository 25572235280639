import React, { Fragment } from 'react';
import BeatLoading from './BeatLoading';
import { isMobileOnly, isIE } from 'react-device-detect';

class Chart_PlaceHolder extends React.Component {
    render() {
        return (
            <Fragment>
                {
                    !(isIE || isMobileOnly) ?
                    <Fragment>
                        <div className="statics graph1"><BeatLoading/></div>
                        <div className="statics graph2"><BeatLoading/></div>
                        <div className="statics graph3"><BeatLoading/></div>
                        <div className="statics graph4"><BeatLoading/></div>
                        <div className="statics graph5"><BeatLoading/></div>
                        <div className="statics graph6"><BeatLoading/></div>
                        <div className="statics graph7"><BeatLoading/></div>
                        <div className="statics graph8"><BeatLoading/></div>
                    </Fragment>
                    :
                    <Fragment>
                        <div className="statics graph1" style={{position: "relative"}}><span style={{position: "absolute", top: "50%", left: "50%", margin: "-10px 0 0 -15px", fontSize: "12px", color: "#999", lineHeight: "1.0"}}>Loading...</span></div>
                        <div className="statics graph2" style={{position: "relative"}}><span style={{position: "absolute", top: "50%", left: "50%", margin: "-10px 0 0 -15px", fontSize: "12px", color: "#999", lineHeight: "1.0"}}>Loading...</span></div>
                        <div className="statics graph3" style={{position: "relative"}}><span style={{position: "absolute", top: "50%", left: "50%", margin: "-10px 0 0 -15px", fontSize: "12px", color: "#999", lineHeight: "1.0"}}>Loading...</span></div>
                        <div className="statics graph4" style={{position: "relative"}}><span style={{position: "absolute", top: "50%", left: "50%", margin: "-10px 0 0 -15px", fontSize: "12px", color: "#999", lineHeight: "1.0"}}>Loading...</span></div>
                        <div className="statics graph5" style={{position: "relative"}}><span style={{position: "absolute", top: "50%", left: "50%", margin: "-10px 0 0 -15px", fontSize: "12px", color: "#999", lineHeight: "1.0"}}>Loading...</span></div>
                        <div className="statics graph6" style={{position: "relative"}}><span style={{position: "absolute", top: "50%", left: "50%", margin: "-10px 0 0 -15px", fontSize: "12px", color: "#999", lineHeight: "1.0"}}>Loading...</span></div>
                        <div className="statics graph7" style={{position: "relative"}}><span style={{position: "absolute", top: "50%", left: "50%", margin: "-10px 0 0 -15px", fontSize: "12px", color: "#999", lineHeight: "1.0"}}>Loading...</span></div>
                        <div className="statics graph8" style={{position: "relative"}}><span style={{position: "absolute", top: "50%", left: "50%", margin: "-10px 0 0 -15px", fontSize: "12px", color: "#999", lineHeight: "1.0"}}>Loading...</span></div>
                    </Fragment>
                }
                
            </Fragment>
        )
    }
}

export default Chart_PlaceHolder;