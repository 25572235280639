import React, {Component, Fragment} from 'react';
import clsx from 'clsx';
import {Query, withApollo} from "react-apollo";
import {inject, observer} from "mobx-react";
import Moment from "react-moment";
import $ from "jquery";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import _ from "lodash";

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import withWidth from "@material-ui/core/withWidth";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import SNotLogin from './S_NotLogin';
import SFormSelectBox from "./S_FormSelectBox";
import SWorktimeOverviewBox from "./S_WorktimeOverviewBox";
import SLongBarGraph from "./S_LongBarGraph";
import SLongLineGraph from "./S_LongLineGraph";
import SHlinkADBox from "./S_HlinkADBox";
import withTab from "./withTab";

import CommonFunction from "../../common/CommonFunction";
import { ORG_TYPE, SPC_CODE, LOC_CODE, HTP_CODE } from "./UserConstants";
import {
    WORKTIME_OVERVIEW,
    PAYINFO_COMPARE_PAY,
    HLINK_AD, PAYINFO_SALARY_RESULT
} from '../../queries/salary/salary';

import useStyles from './SalaryStyle';
import SSnackbar from "./S_Snackbar";

function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            //prefix="$"
            suffix={props.id === "pay_net" ? " 만원" : " 시간"}
        />
    );
}

@inject("salaryStore")
@observer
class S_Worktime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: true,
            value: 0,   //탭에 사용되는 state
            filter_worktime_overview_term: props.salaryStore.filterOverviewTerm ? props.salaryStore.filterOverviewTerm : "year",
            filter_worktime_overview_pay: "net",
            filter_worktime_week_term: "year",
            filter_worktime_week_pay: "net",
            filter_worktime_time_term: "year",
            filter_worktime_time_pay: "net",
            //filter_worktime_weektrend_pay: "",  // 사용안함
            filter_worktime_timetrend_pay: "net",
            input_net_pay: 0,
            input_worktime: 0,
            input_edit_flag: false,
            snackbar_message: "",
            snackbar_type: "error"
        }
    }

    componentDidMount = () => {
        CommonFunction.pageview('연봉인덱스', '근무시간'); //Google Analytics

        $("legend").css("position", "unset").css("width", "auto");

        this.props.handleCurrentMenu(1);
    }

    // 스낵바 오픈
    openSnackbar = (msg) => {
        this.setState({
            snackbar_open: true,
            snackbar_message: msg
        })
    };

    // 스낵바 클로즈
    closeSnackbar = () => {
        this.setState({
            snackbar_open: false,
            snackbar_message: ""
        })
    };

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        })
    };

    handleChangeFilter = (event, id) => {
        //console.log("event", event.target.value, id);

        if(id === "worktime_overview_term"){
            this.setState({
                filter_worktime_overview_term: event.target.value
            })
        }else if(id === "worktime_overview_pay"){
            this.setState({
                filter_worktime_overview_pay: event.target.value
            })
        }else if(id === "worktime_week_term"){
            this.setState({
                filter_worktime_week_term: event.target.value
            })
        }else if(id === "worktime_week_pay"){
            this.setState({
                filter_worktime_week_pay: event.target.value
            })
        }else if(id === "worktime_time_term"){
            this.setState({
                filter_worktime_time_term: event.target.value
            })
        }else if(id === "worktime_time_pay"){
            this.setState({
                filter_worktime_time_pay: event.target.value
            })
        }else if(id === "worktime_timetrend_pay"){
            this.setState({
                filter_worktime_timetrend_pay: event.target.value
            })
        }
    }

    handleChangeReInput = () => {
        this.setState({
            input_edit_flag: true
        }, () => {
            $("legend").css("position", "unset").css("width", "auto");
        });

    }

    // net, 근무시간 입력처리
    validateInputValue = () => {
        const {input_net_pay, input_worktime} = this.state;
        const {width} = this.props;

        //console.log("input", this.state.input_net_pay, this.state.input_worktime)
        if (!input_net_pay) {
            let msg = "NET 월급을 입력하세요";
            this.openSnackbar(msg);
            return;
        }
        if (!input_worktime) {
            let msg = "주간 근무시간을 입력하세요";
            this.openSnackbar(msg);
            return;
        }

        if (input_net_pay < 100 || input_net_pay > 10000) {
            let msg = "NET 월급의 입력값은 100 ~ 10000 단위(만원) 입니다";
            this.openSnackbar(msg);
            return;
        }

        if (input_worktime < 20 || input_worktime > 100) {
            let msg = "주간근무시간 입력값은 20 ~ 100 단위(시간) 입니다";
            this.openSnackbar(msg);
            return;
        }

        if (width === "xs" || width === "sm") {
            document.querySelector("main").scrollTo(0, 0);
        }

        // 입력값 스토어에 저장
        const salaryStore = this.props.salaryStore;
        salaryStore.setMyPayInfo(input_net_pay, input_worktime);
        // 등록날짜 스토어에 저장
        let date = CommonFunction.getDateIntervalMonth();
        salaryStore.setSalaryLogRegDate(date);

        let variables = {
            spc_code: salaryStore.userProfile.u_spc_code,
            hop_loc_code: salaryStore.userProfile.u_zip_zone,
            org_type: CommonFunction.getCodeName(salaryStore.userProfile.u_hospital_group_code, ORG_TYPE),
            u_id: salaryStore.userProfile.u_id,
            period_type: this.state.filter_worktime_overview_term,
            salary: input_net_pay.toString(),
            time: input_worktime.toString()
        }

        this.props.client.mutate({
            mutation: PAYINFO_SALARY_RESULT,
            variables: variables
        })
            .then((res) => {
                //console.log('PAYINFO_SALARY_RESULT result ', res.data)
                salaryStore.setMySalaryResult(res.data.createSalaryInsertLog, this.state.filter_worktime_overview_term);
                this.setState({
                    input_edit_flag: false
                })
            })
            .catch((error) => {
                //console.log('error', error.message);
            });
    }

    render() {
        const { classes, LinkTab, TabPanel, a11yProps } = this.props;
        const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
        const fixedMediumHeightPaper = clsx(classes.paperMedium, classes.fixedMediumHeight);

        const { salaryStore } = this.props;
        const { userProfile } = this.props.salaryStore;
        const {
            filter_worktime_overview_term, filter_worktime_overview_pay,
            filter_worktime_week_term, filter_worktime_week_pay,
            filter_worktime_time_term, filter_worktime_time_pay,
            //filter_worktime_weektrend_pay,
            filter_worktime_timetrend_pay,
            input_edit_flag
        } = this.state;

        const org_type = CommonFunction.getCodeName(userProfile.u_hospital_group_code, ORG_TYPE);
        let salary_mod_flag = 'Y';
//        //console.log(salaryStore.salaryLogRegDate);
        if(!_.isEmpty(salaryStore.salaryLogRegDate)) {
//            //console.log("salaryStore ", salaryStore.salaryLogRegDate);
            let salary_log_reg_date = salaryStore.salaryLogRegDate;
            let year = salary_log_reg_date.substring(0, 4);
            let month = (salary_log_reg_date.substring(5, 7) - 1);
            let day = salary_log_reg_date.substring(8, 10);

            let nowDate = new Date();
            let regDate = new Date();
            regDate.setFullYear(year, month, day);
            regDate.setDate(regDate.getDate() + 90);
            if(regDate >= nowDate){
                salary_mod_flag = 'N';
            }
            if(salaryStore.myPay > 0 && this.state.input_net_pay === 0)
            {
                this.setState({
                    input_net_pay: salaryStore.myPay,
                    input_worktime: salaryStore.myWorkTime
                }); // 로그 정보 존재시 기존 pay, worktime set state
            }
        }

        return (
            <Fragment>
                <SSnackbar
                    snackbar_open={this.state.snackbar_open}
                    closeSnackbar={this.closeSnackbar}
                    snackbar_message={this.state.snackbar_message}
                    snackbar_type={this.state.snackbar_type}
                />
                {
                    !salaryStore.isLogin ?
                    <SNotLogin />
                    :
                    <Container maxWidth="lg" className={classes.container}>
                        <div>
                            <Typography component="div" style={{float: "left"}} gutterBottom>
                                <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                    근무시간(시급) 개요
                                </Typography>
                                <Typography component="span" variant="caption">
                                    주간 근무시간으로, 전체 이외 3가지 조건은 선생님 회원정보를 기반으로 호출합니다
                                </Typography>
                            </Typography>
                            <Typography component="div" className="combo_1" style={{marginTop:"0"}} gutterBottom>
                                <SFormSelectBox
                                    id="worktime_overview_pay"
                                    labelId="worktime_overview_pay_label"
                                    labelText="급여"
                                    selectedValue={filter_worktime_overview_pay}
                                    type="pay_type"
                                    change={this.handleChangeFilter}
                                />
                                <SFormSelectBox
                                    id="worktime_overview_term"
                                    labelId="worktime_overview_term_label"
                                    labelText="기간"
                                    selectedValue={filter_worktime_overview_term}
                                    type="term"
                                    change={this.handleChangeFilter}
                                />
                            </Typography>
                            <Paper>
                                <div className="Overview_time">
                                    <Grid container spacing={3}>
                                        <Query
                                            query={WORKTIME_OVERVIEW}
                                            variables={{
                                                unit_type: filter_worktime_overview_pay,
                                                spc_code: userProfile.u_spc_code,
                                                hop_loc_code: userProfile.u_zip_zone,
                                                org_type: org_type,
                                                period_type: filter_worktime_overview_term
                                            }}
                                            fetchPolicy="network-only"
                                        >
                                            {({ data, loading, error }) => {
                                                if (error) return <p>{error.message}</p>;

                                                return (
                                                    <SWorktimeOverviewBox
                                                        data={data ? data.getAvgHour : []}
                                                        fixedHeightPaper={fixedHeightPaper}
                                                        fixedMediumHeightPaper={fixedMediumHeightPaper}
                                                        spc_code={userProfile.u_spc_code}
                                                        hop_loc_code={userProfile.u_zip_zone}
                                                        org_type={org_type}
                                                        state_period_type={filter_worktime_overview_term}
                                                        state_uint_type={filter_worktime_overview_pay}
                                                        loading={loading}
                                                    />
                                                )
                                            }}
                                        </Query>
                                        <div className="input_pay">
                                            {/* input pay 연봉 인덱스 입력전 출력 */}
                                            {
                                                salaryStore.isLogin &&
                                                (!salaryStore.myPay > 0 && !salaryStore.myWorkTime > 0) ?
                                                    <Grid item xs={12}>
                                                        <Paper className={fixedHeightPaper}>
                                                            <div className={classes.payTextCenter}>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color="primary"
                                                                    gutterBottom
                                                                >
                                                                    나의 '세후 월급여'와 '주간 총 근무 시간'을 입력하면 각 조건별 상대적 크기나, 순위 퍼센타일을 쉽게 확인하실 수 있습니다.
                                                                </Typography>
                                                                <div className="inputwrap">
                                                                    <div className="inputarea">
                                                                        <div>
                                                                            <TextField
                                                                                id="pay_net"
                                                                                name="input_net_pay"
                                                                                label="NET 월급"
                                                                                style={{
                                                                                    margin: '8px 0',
                                                                                    width: '100%',
                                                                                    minWidth: 240
                                                                                }}
                                                                                placeholder="반드시 세후 월급여로 입력해주세요"
                                                                                margin="normal"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                InputProps={{
                                                                                    inputComponent: NumberFormatCustom,
                                                                                    inputProps:{
                                                                                        maxLength: 9
                                                                                    }
                                                                                }}
                                                                                variant="outlined"
                                                                                size="small"
                                                                                onChange={e => this.setState({input_net_pay: e.target.value})}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <TextField
                                                                                id="worktime_week"
                                                                                name="input_worktime"
                                                                                label="주간 근무 시간"
                                                                                style={{
                                                                                    margin: '8px 0',
                                                                                    width: '100%',
                                                                                    minWidth: 240
                                                                                }}
                                                                                placeholder="일주일 동안의 평일근무 + 주말근무 + 당직근무의 총 합한 시간"
                                                                                margin="normal"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                InputProps={{
                                                                                    inputComponent: NumberFormatCustom,
                                                                                    inputProps:{
                                                                                        maxLength: 6
                                                                                    }
                                                                                }}
                                                                                variant="outlined"
                                                                                size="small"
                                                                                onChange={e => this.setState({input_worktime: e.target.value})}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="inputarea button">
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            size="large"
                                                                            onClick={this.validateInputValue}
                                                                        >
                                                                            확인
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                <Typography className="txtnotice">* 입력한 정보는 90일이후 수정가능합니다.</Typography>
                                                            </div>
                                                        </Paper>
                                                    </Grid>
                                                    :

                                                    (!input_edit_flag && salary_mod_flag === 'Y') ?
                                                    <Grid item xs={12}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            fullWidth={true}
                                                            size="large"
                                                            onClick={this.handleChangeReInput}
                                                        >
                                                            급여 & 근무시간 수정
                                                        </Button>
                                                    </Grid>

                                                : (!input_edit_flag && salary_mod_flag === 'N') &&
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        fullWidth={true}
                                                        size="large"
                                                        style={{boxShadow:"none"}}
                                                    >
                                                        등록하신 급여/근무시간 정보는&ensp;<Moment add={{ day: 90}} format="YYYY.MM.DD">{salaryStore.salaryLogRegDate}</Moment>&ensp;이후 수정하실 수 있습니다.
                                                    </Button>
                                                </Grid>
                                            }

                                            {
                                                input_edit_flag &&
                                                <Grid item xs={12}>
                                                    <Paper className={fixedHeightPaper}>
                                                        <div className={classes.payTextCenter}>
                                                            <Typography
                                                                variant="subtitle1"
                                                                color="primary"
                                                                gutterBottom
                                                            >
                                                                나의 '세후 월급여'와 '주간 총 근무 시간'을 입력하면 각 조건별 상대적 크기나, 순위 퍼센타일을 쉽게 확인하실 수 있습니다.
                                                            </Typography>
                                                            <div>
                                                                <TextField
                                                                    id="pay_net"
                                                                    name="input_net_pay"
                                                                    label="NET 월급"
                                                                    style={{
                                                                        margin: 8,
                                                                        width: '40%',
                                                                        minWidth: 240
                                                                    }}
                                                                    placeholder="반드시 세후 월급여로 입력해주세요"
                                                                    margin="normal"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom,
                                                                        inputProps:{
                                                                            maxLength: 9
                                                                        }
                                                                    }}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    defaultValue={salaryStore.myPay}
                                                                    onChange={e => this.setState({input_net_pay: e.target.value})}
                                                                />
                                                            </div>
                                                            <div>
                                                                <TextField
                                                                    id="worktime_week"
                                                                    name="input_worktime"
                                                                    label="주간 근무 시간"
                                                                    style={{
                                                                        margin: 8,
                                                                        width: '40%',
                                                                        minWidth: 240
                                                                    }}
                                                                    placeholder="일주일 동안의 평일근무 + 주말근무 + 당직근무의 총 합한 시간"
                                                                    margin="normal"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom,
                                                                        inputProps:{
                                                                            maxLength: 6
                                                                        }
                                                                    }}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    defaultValue={salaryStore.myWorkTime}
                                                                    onChange={e => this.setState(
                                                                        {input_worktime: e.target.value})}
                                                                />
                                                            </div>
                                                            <div>
                                                                <Typography
                                                                    className="txtnotice"
                                                                    style={{
                                                                        width: '40%',
                                                                        minWidth: 240
                                                                    }}
                                                                >
                                                                    * 입력한 정보는 90일이후 수정가능합니다.
                                                                </Typography>
                                                            </div>
                                                            <div className="btnarea">
                                                                <Button
                                                                    className="btn_cancel"
                                                                    variant="contained"
                                                                    color="default"
                                                                    size="large"
                                                                    style={{
                                                                        marginTop: '1vh',
                                                                        marginBottom: '2vh',
                                                                        marginRight: '5px'
                                                                    }}
                                                                    onClick={() => this.setState({input_edit_flag: false})}
                                                                >
                                                                    취소
                                                                </Button>
                                                                <Button
                                                                    className="btn_confirm"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="large"
                                                                    style={{
                                                                        marginTop: '1vh',
                                                                        marginBottom: '2vh',
                                                                        marginLeft: '5px'
                                                                    }}
                                                                    onClick={this.validateInputValue}
                                                                >
                                                                    수정
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                            }
                                        </div>
                                        <div className="hlink_ad" style={{margin:'20px 0 40px',width:'100%'}}>
                                            <Query
                                                query={HLINK_AD}
                                                variables={{
                                                    spc_code: userProfile.u_spc_code,
                                                    limit_cnt: 1
                                                }}
                                                fetchPolicy="network-only"
                                            >
                                                {({ data, loading, error }) => {
                                                    if (loading) return <p></p>;
                                                    if (error) return <p>{error.message}</p>;
                                                    return (
                                                        data.getHlinkAd && data.getHlinkAd.length > 0 && data.getHlinkAd[0].svc_cont_idx !== 0 &&
                                                        <SHlinkADBox
                                                            data={data.getHlinkAd}
                                                            fixedHeightPaper={fixedHeightPaper}
                                                        />
                                                    )
                                                }}
                                            </Query>
                                        </div>
                                    </Grid>
                                </div>
                            </Paper>
                        </div>

                        <div className="tabswrap" style={{paddingTop:'60px',marginTop:'30px',borderTop:'1px solid rgba(0,0,0,0.1)'}}>
                            <AppBar position="static" style={{zIndex: 500}}>
                                <div className="tabs">
                                    <Tabs
                                        variant="fullWidth"
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                        aria-label="nav tabs example"
                                    >
                                        <LinkTab className="left" label="주간 근무 시간" href="/week" {...a11yProps(0)} />
                                        <LinkTab className="right" label="시간당 급여" href="/time" {...a11yProps(1)} />
                                    </Tabs>
                                </div>
                            </AppBar>
                            <TabPanel value={this.state.value} index={0}>
                                <div className="pagegraph">
                                    <Typography component="div" style={{float: "left"}} gutterBottom>
                                        <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                            근무시간 비교
                                        </Typography>
                                        <Typography component="span" variant="caption">
                                            전문과, 지역, 병원 타입에 따른 주간 근무 시간 비교입니다
                                        </Typography>
                                    </Typography>
                                    <Typography component="div" className="combo_1" style={{margin:"10px 0 0"}} gutterBottom>
                                        <SFormSelectBox
                                            id="worktime_week_term"
                                            labelId="worktime_week_term_label"
                                            selectedValue={filter_worktime_week_term}
                                            type="term"
                                            change={this.handleChangeFilter}
                                        />
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Query
                                            query={PAYINFO_COMPARE_PAY}
                                            variables={{
                                                spc_code: userProfile.u_spc_code,
                                                hop_loc_code: userProfile.u_zip_zone,
                                                org_type: org_type,
                                                pay_type: filter_worktime_week_pay,
                                                period_type: filter_worktime_week_term,
                                                chart_menu: "avg_hour"
                                            }}
                                            fetchPolicy="network-only"
                                        >
                                            {({ data, loading, error }) => {
                                                if (error) return <p>{error.message}</p>;

                                                return (
                                                    <SLongBarGraph
                                                        data={data ? data.getChartData : []}
                                                        fixedHeightPaper={fixedHeightPaper}
                                                        loopCount={3}
                                                        loading={loading}
                                                        type={1}
                                                        unit="(시간)"
                                                    />
                                                )
                                            }}
                                        </Query>
                                    </Grid>
                                </div>
                                <div style={{paddingTop:'60px'}}>
                                    <Typography component="div" style={{float: "left"}} gutterBottom>
                                        <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                            근무시간 추이
                                        </Typography>
                                        <Typography component="span" variant="caption">
                                            최근 1년간 주간 근무시간 추이로 전체 이외 3가지 조건은 선생님 회원정보를 기반으로 호출합니다
                                        </Typography>
                                    </Typography>
                                    <Typography component="div" style={{float: "right"}} gutterBottom>
                                    </Typography>
                                    <div className="pagegraph type2">
                                        <Grid container spacing={3}>
                                            <Query
                                                query={PAYINFO_COMPARE_PAY}
                                                variables={{
                                                    spc_code: userProfile.u_spc_code,
                                                    hop_loc_code: userProfile.u_zip_zone,
                                                    org_type: org_type,
                                                    chart_menu: "trend_hour",
                                                }}
                                                fetchPolicy="network-only"
                                            >
                                                {({ data, loading, error }) => {
                                                    if (error) return <p>{error.message}</p>;

                                                    return (
                                                        <SLongLineGraph
                                                            data={data ? data.getChartData : []}
                                                            fixedHeightPaper={fixedHeightPaper}
                                                            loopCount={4}
                                                            arrName={[
                                                                "전체",
                                                                CommonFunction.getCodeName(userProfile.u_spc_code, SPC_CODE),
                                                                CommonFunction.getCodeName(userProfile.u_zip_zone, LOC_CODE),
                                                                CommonFunction.getCodeName(org_type, HTP_CODE)
                                                            ]}
                                                            loading={loading}
                                                            unit="(시간)"
                                                        />
                                                    )
                                                }}
                                            </Query>
                                        </Grid>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>
                                <div className="pagegraph">
                                    <Typography component="div" style={{float: "left"}} gutterBottom>
                                        <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                            시급 비교
                                        </Typography>
                                        <Typography component="span" variant="caption">
                                            전문과, 지역, 병원 타입에 따른 시급 비교입니다. 세전/세후 조건을 확인하세요
                                        </Typography>
                                    </Typography>
                                    <Typography component="div" className="combo_1" style={{margin:"10px 0 0"}} gutterBottom>
                                        <SFormSelectBox
                                            id="worktime_time_pay"
                                            labelId="worktime_time_pay_label"
                                            selectedValue={filter_worktime_time_pay}
                                            type="pay_two"
                                            change={this.handleChangeFilter}
                                        />
                                        <SFormSelectBox
                                            id="worktime_time_term"
                                            labelId="worktime_time_term_label"
                                            selectedValue={filter_worktime_time_term}
                                            type="term"
                                            change={this.handleChangeFilter}
                                        />
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Query
                                            query={PAYINFO_COMPARE_PAY}
                                            variables={{
                                                spc_code: userProfile.u_spc_code,
                                                hop_loc_code: userProfile.u_zip_zone,
                                                org_type: org_type,
                                                pay_type: filter_worktime_time_pay,
                                                period_type: filter_worktime_time_term,
                                                chart_menu: "avg_hour_pay",
                                            }}
                                            fetchPolicy="network-only"
                                        >
                                            {({ data, loading, error }) => {
                                                if (error) return <p>{error.message}</p>;

                                                return (
                                                    <SLongBarGraph
                                                        data={data ? data.getChartData : []}
                                                        fixedHeightPaper={fixedHeightPaper}
                                                        loopCount={3}
                                                        loading={loading}
                                                        type={1}
                                                        unit="(만원)"
                                                    />
                                                )
                                            }}
                                        </Query>
                                    </Grid>
                                </div>
                                <div className="pagegraph" style={{paddingTop:"60px"}}>
                                    <Typography component="div" style={{float: "left"}} gutterBottom>
                                        <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                            시급 추이
                                       </Typography>
                                        <Typography component="span" variant="caption">
                                            최근 1년간 시급 추이로 전체 이외 3가지 조건은 선생님 회원정보를 기반으로 호출합니다
                                        </Typography>
                                    </Typography>
                                    <Typography component="div" className="combo_1" style={{margin:"10px 0 0"}} gutterBottom>
                                        <SFormSelectBox
                                            id="worktime_timetrend_pay"
                                            labelId="worktime_timetrend_pay_label"
                                            selectedValue={filter_worktime_timetrend_pay}
                                            type="pay_two"
                                            change={this.handleChangeFilter}
                                        />
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Query
                                            query={PAYINFO_COMPARE_PAY}
                                            variables={{
                                                spc_code: userProfile.u_spc_code,
                                                hop_loc_code: userProfile.u_zip_zone,
                                                org_type: org_type,
                                                pay_type: filter_worktime_timetrend_pay,
                                                chart_menu: "trend_hour_pay",
                                            }}
                                            fetchPolicy="network-only"
                                        >
                                            {({ data, loading, error }) => {
                                                if (error) return <p>{error.message}</p>;

                                                return (
                                                    <SLongLineGraph
                                                        data={data ? data.getChartData : []}
                                                        fixedHeightPaper={fixedHeightPaper}
                                                        loopCount={4}
                                                        arrName={[
                                                            "전체",
                                                            CommonFunction.getCodeName(userProfile.u_spc_code, SPC_CODE),
                                                            CommonFunction.getCodeName(userProfile.u_zip_zone, LOC_CODE),
                                                            CommonFunction.getCodeName(org_type, HTP_CODE)
                                                        ]}
                                                        loading={loading}
                                                        unit="(만원)"
                                                    />
                                                )
                                            }}
                                        </Query>
                                    </Grid>
                                </div>
                            </TabPanel>
                        </div>
                    </Container>
                }
            </Fragment>
        );
    }
};

S_Worktime.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(withApollo(withStyles(useStyles)(withTab(S_Worktime))));
