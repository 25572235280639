import React from 'react';

const ZeroResult_KCD = (props) => {
    return (
        <div className="not_found_wrap">
            <div className="not_found">
                <div className="icon" style={{background: `url(http://image.medigate.net/test/190522/images/icon_nofound1.png) center / cover no-repeat`}}></div>
                <p className="txt">죄송합니다.</p>
                <p className="txt">'<span className="keytxt">{props.keyword}</span>' 과 관련한 상병명을 찾을 수 없습니다.</p>
            </div>
        </div>

    )
}

export default ZeroResult_KCD;