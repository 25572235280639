import React, { Fragment } from 'react';
import axios from 'axios';
import _ from 'lodash';
import CommonFunc from '../../../common/CommonFunction';

class SearchRouter extends React.PureComponent {

	state = {
		keyword: null,
		suggestions: []
	};

	componentDidMount = () => {
		const keyword = this.props.match.params.keyword;
		this.setState({
			keyword: keyword
		})
		this.searchFromEngine(keyword);
	}

	searchFromEngine = (keyword) => {
		let isKcdSearch = false;
		if(keyword.length >= 2){
			const isAlpha = /^[a-zA-Z()]+$/.test(keyword.substring(0, 1));
			const isNumeric = !isNaN(keyword.substring(1));
			isKcdSearch = isAlpha && isNumeric;
			//console.log('isKcdSearch', keyword, isKcdSearch);
		}
		let searchEngineUrl = null;
		if(!isKcdSearch){
			searchEngineUrl = "https://search.medigate.net/search.jsp?query=" + encodeURIComponent(keyword) + "&collection=kcd&colldisplay=100&sort=kcd@order_cnt/DESC";
		}
		else{
			const kcd_keyword = keyword.replace(new RegExp('\\.', 'g'), '');
			searchEngineUrl = "https://search.medigate.net/search.jsp?query=" + encodeURIComponent(kcd_keyword) + "&collection=kcd&sfield=kcd@kcd_code&sort=kcd@RANK/DESC";
		}
		//console.log('searchEngineUrl', searchEngineUrl);
		
		axios.get(searchEngineUrl)
		.then((res) => {
			//console.log('search_result', res.data.TotalCount, res.data.Data[0].Result);
			if(res.data.ReturnMsg === "success"){
				if(res.data.TotalCount > 0){
					let kcd_list = res.data.Data[0].Result.map(item => {
						const kcd_kor = CommonFunc.getKcdNameConv(item.kcd_kor);
						const kcd_eng = CommonFunc.getKcdNameConv(item.kcd_eng);
						return { kcd_code: item.kcd_code, kcd_kor: kcd_kor, kcd_eng: kcd_eng, wikiKeyword: item.keyword, order_cnt: item.order_cnt }
					});
					
					//KCD CODE 검색 시 kcd_code 순으로 정렬
					if(isKcdSearch){
						kcd_list = _.orderBy(kcd_list, [item => item.kcd_code.toUpperCase()], ['ASC']);
					}
					//console.log('kcd_list', keyword, kcd_list);

					kcd_list = _.filter(kcd_list, function(o) {
						if (o.order_cnt !== '0') return o;
					});
					//console.log('kcd_list filter', kcd_list);

					this.setState({
						suggestions: kcd_list
					}, () => {
						this.doSelect(this.state.keyword, this.state.suggestions);
					});
				}
				else{
					this.setState({
						suggestions: []
					}, () => {
						this.doSelect(this.state.keyword, this.state.suggestions);
					});
				}
			}
		})
		.catch((error) => {
			//console.log('error', error.message);
			this.doSelect(this.state.keyword, []);
		});
	}

	doSelect = (keyword, suggestions) => {
		this.addSearchLog(keyword);
		
		//처방 결과 화면으로 이동
		const params = {
			'keyword': keyword,
			'kcd_list': suggestions
		}
		this.props.history.push("/prescription", params)
	}

	addSearchLog = (keyword) => {
		if(keyword){
			CommonFunc.pageview('처방정보', '검색-MG', keyword); //Google Analytics
		}
	}

	render() {
		return (
			<Fragment></Fragment>
		);
	}
}

export default SearchRouter;