import React from 'react';
import $ from 'jquery';

const Footer = ({data, onSearchCtg3List}) => {

    function controlFooterLayer(){
        $('[data-popup-open]').on('click', function(e)  {
            // //console.log('data-popup-open clicked', e);
            var targeted_popup_class = $(this).attr('data-popup-open');
            $('[data-popup="' + targeted_popup_class + '"]').fadeIn(0);
            e.preventDefault();
        });
        //----- LAYER POPIMAGE CLOSE
        $('[data-popup-close]').on('click', function(e)  {
            var targeted_popup_class = $(this).attr('data-popup-close');
            $('[data-popup="' + targeted_popup_class + '"]').fadeOut(0);
            e.preventDefault();
        });
    }

    return (
        <div id="footer">
            <div className="footer_wrap">
                <div className="footer_logo">
                    <img src="http://image.medigate.net/drgate/images/footer_logo.png" alt="logo" />
                </div>
                <div className="footer_address">
                    <p className="txtinfo">서울특별시 강남구 영동대로 702 화천회관빌딩 6층 (06075) (지번주소 : 청담동 133-3)</p>
                    <p className="txtinfo">
                        <a href="mailto:master@medigate.net"><span>master@medigate.net</span></a>
                        <a href="http://www.medigate.net/help/customer/policy_use_20190715.jsp?menuGroupCode=FOOTER&menuCode=POLICY" target="_blank"><span className="line_space">이용약관</span></a>
					    <a href="http://www.medigate.net/help/customer/policy_person_20190715.jsp?menuGroupCode=FOOTER&menuCode=POLICY" target="_blank"><span className="line_space">개인정보취급방침</span></a>
                        <a href="javascript:void(0);" onClick={() => window.open('https://www.medigate.net/common/claim/addForm?service=drgate', 'claim', 'width=505, height=620')}><span className="line_space">의견보내기</span></a>
                    </p>
                    <p className="txtinfo copyright">Copyright © MEDIC&amp;C Co.,Ltd. All Rights Reserved.</p>
                </div>
                <div className="footer_combobox">
                    <div className="familySite">
                        <a data-popup-open="popup" className="family_tit" href="#">
                            <span>패밀리</span>
                        </a>
                    </div>
                    <div className="Layerpopup_more" data-popup="popup">
                        <ul className="popup_list">
                            <li className="list_area">
                                <a href="http://www.medigate.net" target="_blank" title="메디게이트">MEDI:GATE</a>
                            </li>
                            <li className="list_area">
                                <a href="http://www.medigatenews.com" target="_blank" title="메디게이트뉴스">MEDI:GATE NEWS</a>
                            </li>
                            <li className="list_area">
                                <a href="http://www.medigate.net/hlink/job/jobinfo" target="_blank" title="H-LINK">H-LINK</a>
                            </li>
                            <li className="list_area">
                                <a href="http://www.medicaldepot.co.kr" target="_blank" title="메디칼데포">MEDICAL DEPOT</a>
                            </li>
                            <li className="list_area">
                                <a href="http://medicnc.co.kr/kr/aboutcnc.html" target="_blank" title="메디씨앤씨">MEDICNC</a>
                            </li>
                        </ul>
                        <p data-popup-close="popup">
                            <a href="#" className="family_tit"><span></span></a>
                        </p>
                    </div>
                </div>
            </div>
            <div ref={controlFooterLayer}/>
        </div>
    )
}

export default Footer;
