import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Skeleton from "@material-ui/lab/Skeleton";

import SLineChart from './S_LineChart';
import SBoxNoLongContent from "./S_BoxNoLongContent";

class S_LongLineGraph extends Component {
    render() {
        const {
            data=[],
            fixedHeightPaper,
            // spc_code,
            // hop_loc_code,
            org_type,
            loopCount,
            arrName,
            loading,
            isAnnotation,
            data_standard = "medi",
            legendDisabled=false,
            unit=""
        } = this.props;

        // //console.log("line data", data);
        // console.log("this.props ", this.props)
        let chartArr = [];
        let allArr = [];
        let spcArr = [];
        let locArr = [];
        let orgArr = [];

        if(loopCount === 4){
            data.forEach((item, i) => {
                item.forEach((innerItem) => {
                    let tmpArray = _.toArray(innerItem)
                    if(i === 0){
                        allArr.push(_.dropRight(tmpArray));
                    }else if(i === 1){
                        spcArr.push(_.dropRight(tmpArray));
                    }else if(i === 2){
                        locArr.push(_.dropRight(tmpArray));
                    }else if(i === 3){
                        orgArr.push(_.dropRight(tmpArray));
                    }
                });
            });

            allArr.unshift(["", `전체${unit}`, { role: "annotation" }]);
            spcArr.unshift(["", `${arrName[1]}${unit}`, { role: "annotation" }]);
            locArr.unshift(["", `${arrName[2]}${unit}`, { role: "annotation" }]);
            orgArr.unshift(["", `${arrName[3]}${unit}`, { role: "annotation" }]);

            // //console.log("allArr", allArr);
            // //console.log("spcArr", spcArr);
            // //console.log("locArr", locArr);
            // //console.log("orgArr", orgArr);
        }else if(loopCount === 3){
            data.forEach((item, i) => {
                item.forEach((innerItem) => {
                    let tmpArray = _.toArray(innerItem)
                    if(i === 0){
                        spcArr.push(_.dropRight(tmpArray, isAnnotation !== "Y" ? 2 : 1));
                    }else if(i === 1){
                        locArr.push(_.dropRight(tmpArray, isAnnotation !== "Y" ? 2 : 1));
                    }else if(i === 2){
                        orgArr.push(_.dropRight(tmpArray, isAnnotation !== "Y" ? 2 : 1));
                    }
                });
            });

            if(isAnnotation !== "N"){
                spcArr.unshift(["", `${arrName[0]}${unit}`, { role: "annotation" }]);
                locArr.unshift(["", `${arrName[1]}${unit}`, { role: "annotation" }]);
                orgArr.unshift(["", `${arrName[2]}${unit}`, { role: "annotation" }]);
            }else{
                spcArr.unshift(["", `${arrName[0]}${unit}`]);
                locArr.unshift(["", `${arrName[1]}${unit}`]);
                orgArr.unshift(["", `${arrName[2]}${unit}`]);
            }

            // kosis data일 때 기관 타입이 기타일시 그래프 미노출
            if((org_type === 'ORG_TYPE5' || !org_type) && data_standard === 'kosis' ) orgArr = [];
            // //console.log("spcArr", spcArr);
            // //console.log("locArr", locArr);
            // //console.log("orgArr", orgArr);
        }else if(loopCount === 1){
            data.forEach((item) => {
                let tmpArray = _.toArray(item);
                chartArr.push(_.dropRight(tmpArray, isAnnotation !== "Y" ? 2 : 1));
            });

            if(isAnnotation !== "N"){
                chartArr.unshift(["", "전체", { role: "annotation" }]);
            }else{
                chartArr.unshift(["", "전체"]);
            }

            // //console.log("chartArr", chartArr);
        }

        return (
            <Fragment>
                {
                    Array.from(new Array(loopCount)).map((item, index) => (
                        <Grid item xs={12}>
                            <Paper className={fixedHeightPaper}>
                                {
                                    !data.length > 0 || loading ?
                                    <Fragment>
                                        <Skeleton variant="rect" width="100%" height={240} />
                                    </Fragment>
                                    :
                                        loopCount === 3 ?
                                            index === 0 ?
                                            <SLineChart
                                                data={spcArr}
                                                fill="#666666"
                                                name={arrName[index]}
                                            />
                                            :
                                            index === 1 ?
                                                data[index].length !== 1 ?
                                                <SLineChart
                                                    data={locArr}
                                                    fill="#1e88e5"
                                                    name={arrName[index]}
                                                />
                                                :
                                                <SBoxNoLongContent
                                                    dataType="지역"
                                                />
                                            :
                                                orgArr.length === 0 ?
                                                    <Fragment></Fragment>
                                                :
                                                data[index].length !== 1 ?
                                                <SLineChart
                                                    data={orgArr}
                                                    fill="#43a047"
                                                    name={arrName[index]}
                                                />
                                                :
                                                <SBoxNoLongContent
                                                    dataType="병원타입"
                                                />
                                        :
                                        loopCount === 4 ?
                                            index === 0 ?
                                            <SLineChart
                                                data={allArr}
                                                fill="#666666"
                                                name={arrName[index]}
                                            />
                                            :
                                            index === 1 ?
                                            <SLineChart
                                                data={spcArr}
                                                fill="#1e88e5"
                                                name={arrName[index]}
                                            />
                                            :
                                            index === 2 ?
                                                data[index].length !== 1 ?
                                                <SLineChart
                                                    data={locArr}
                                                    fill="#43a047"
                                                    name={arrName[index]}
                                                />
                                                :
                                                <SBoxNoLongContent
                                                    dataType="지역"
                                                />
                                            :
                                                data[index].length !== 1 ?
                                                <SLineChart
                                                    data={orgArr}
                                                    fill="#666666"
                                                    name={arrName[index]}
                                                />
                                                :
                                                <SBoxNoLongContent
                                                    dataType="병원타입"
                                                />
                                        :
                                        <SLineChart
                                            data={chartArr}
                                            fill="#666666"
                                            name="전체"
                                            legendDisabled={legendDisabled}
                                        />

                                }

                            </Paper>
                        </Grid>
                    ))
                }
            </Fragment>
        )
    }
}

export default S_LongLineGraph;