import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import $ from 'jquery';
import 'bootstrap';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';

class Combi2Indicator extends React.PureComponent {
    state = {

    }

    changeCombiIndicator = (index, ingred_code_grp_arr, prescript_use_percent) => {
        let active_flag = false;
        if(this.props.depth === "2"){
            active_flag = $(`.contlist button.c${this.props.depth}btn${this.props.index}.active`).length === 0 ? true : false ;
        }else{
            active_flag = $(`.contlist.second button.c${this.props.depth}btn${this.props.index}.active`).length === 0 ? true : false ;
        }

        this.props.changeCombiValue(active_flag, index, ingred_code_grp_arr, prescript_use_percent);
    }

    render() {
        return(
            <Fragment>
            {
                this.props.depth === "2" ?
                    <div className="txtinfo td07">
                    <button
                        className={`btn_openclose c${this.props.depth}btn${this.props.index}`}
                        onClick={() => this.changeCombiIndicator(this.props.index, this.props.ingred_code_grp_arr, this.props.prescript_use_percent)}
                    >
                        View similar bookings
                    </button>
                </div>
                :
                <button
                    className={`btn_openclose c${this.props.depth}btn${this.props.index}`}
                    onClick={() => this.changeCombiIndicator(this.props.index, this.props.ingred_code_grp_arr, this.props.prescript_use_percent)}
                >
                    View similar bookings
                </button>
            }
            </Fragment>
        )
    }
}

export default Combi2Indicator;