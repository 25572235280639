import React, {Component, Fragment} from 'react';

import Typography from "@material-ui/core/Typography";
import CommonFunction from "../../common/CommonFunction";
import {HTP_CODE, LOC_CODE, SPC_CODE} from "./UserConstants";
import SBoxNoContent from "./S_BoxNoContent";

class S_BoxPaySubContent extends Component {
    render() {
        const { data, spc_code, hop_loc_code, org_type } = this.props;
        const dataType = data.type.split(",");

        return (
            <Fragment>
                {
                    (dataType[1] && dataType[1] === "null") ?
                    <SBoxNoContent
                        dataType={dataType}
                    />
                    :
                    <Typography component="span" variant="subtitle1" style={{marginBottom:'15px'}}>
                        선생님의 급여는
                        <Typography component="p" variant="subtitle1" style={{margin:'10px 0'}}>
                            <p className="txtinfo">
                                <Typography component="span" variant="subtitle1" color="primary">
                                    {
                                        data.type === "ALL" &&
                                        <Fragment>전체</Fragment>
                                    }
                                    {
                                        data.type === "SPC" &&
                                        CommonFunction.getCodeName(spc_code, SPC_CODE)
                                    }
                                    {
                                        data.type === "ZON" &&
                                        CommonFunction.getCodeName(hop_loc_code, LOC_CODE)
                                    }
                                    {
                                        data.type === "HTP" &&
                                        CommonFunction.getCodeName(org_type, HTP_CODE)
                                    }
                                </Typography> 기준
                            </p>
                            <p className="txtinfo">평균의
                                <Typography component="span" variant="subtitle1" color="secondary"> {data.pay_percent}%</Typography> 수준이고
                            </p>
                        </Typography>
                        <p className="txtinfo">
                            <Typography component="span" variant="subtitle1" color="secondary">{data.pay_percentile_rank}퍼센타일</Typography>에 해당합니다.
                        </p>
                    </Typography>
                }
            </Fragment>
        )
    }
}

export default S_BoxPaySubContent;