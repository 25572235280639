import React, { Component, Fragment } from 'react';

import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ArrowDropUpRoundedIcon from "@material-ui/icons/ArrowDropUpRounded";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import Table from "@material-ui/core/Table";

import CommonFunction from "../../common/CommonFunction";
import {HTP_CODE, LOC_CODE, SPC_CODE} from "./UserConstants";
import STitle from "./S_Title";
import SBoxNoContent from "./S_BoxNoContent";

class S_BoxWorktimeContent extends Component {
    render() {
        const { data={}, state_period_type, state_uint_type, spc_code, hop_loc_code, org_type } = this.props;

        const dataType = data.type.split(",");

        return (
            <Fragment>
                <STitle align="center">
                    {
                        dataType[0] === "ALL" &&
                        <Fragment>전체</Fragment>
                    }
                    {
                        dataType[0] === "SPC" &&
                        CommonFunction.getCodeName(spc_code, SPC_CODE)
                    }
                    {
                        dataType[0] === "ZON" &&
                        CommonFunction.getCodeName(hop_loc_code, LOC_CODE)
                    }
                    {
                        dataType[0] === "HTP" &&
                        CommonFunction.getCodeName(org_type, HTP_CODE)
                    }
                </STitle>
                {
                    (dataType[1] && dataType[1] === "null") ?
                    <SBoxNoContent
                        dataType={dataType[0]}
                    />
                    :
                    <Table size="small" style={{marginTop: "15px"}}>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan="2" align="center" padding="none" className="tittxt_time">주간근무 / {state_uint_type === "net" ? "평균" : "중앙"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan="2" align="center" padding="none" className="subtxt1_time" style={{paddingBottom: "20px"}}>
                                <span className="growth">
                                    {
                                        state_period_type === "month" &&
                                        <Fragment>
                                            {
                                                data.avg_weekly_hour_growth !== 0 ?
                                                    data.avg_weekly_hour_growth > 0 ?
                                                        <Fragment>
                                                            <ArrowDropUpRoundedIcon color="primary"/>
                                                            {data.avg_weekly_hour_growth}%
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                            <ArrowDropDownRoundedIcon color="secondary"/>
                                                            {data.avg_weekly_hour_growth}%
                                                        </Fragment>
                                                    :
                                                    '-'
                                            }
                                        </Fragment>
                                    }
                                </span>
                                    <span className="data_time">{data.avg_weekly_hour && data.avg_weekly_hour.toLocaleString()}</span>
                                    <span className="text">시간</span>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" padding="none" style={{padding:'15px 0 5px 60px',borderBottom:'none',borderTop:'1px solid #7c7bde',color:'#c9c9ec'}}>
                                    세후 / {state_uint_type === "net" ? "평균" : "중앙"}
                                </TableCell>
                                <TableCell align="center" padding="none" style={{padding:'15px 60px 5px 0',borderBottom:'none',borderTop:'1px solid #7c7bde',color:'#c9c9ec'}}>
                                    세전 / {state_uint_type === "net" ? "평균" : "중앙"}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" padding="none" className="subtxt">
                                <span className="gth_net">
                                    {
                                        state_period_type === "month" &&
                                        <Fragment>
                                            {
                                                data.avg_net_hour_growth !== 0 ?
                                                    data.avg_net_hour_growth > 0 ?
                                                        <Fragment>
                                                            <ArrowDropUpRoundedIcon color="primary"/>
                                                            {data.avg_net_hour_growth}%
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                            <ArrowDropDownRoundedIcon color="secondary"/>
                                                            {data.avg_net_hour_growth}%
                                                        </Fragment>
                                                    :
                                                    '-'
                                            }
                                        </Fragment>
                                    }
                                </span>
                                    <span>{data.avg_net_hour && data.avg_net_hour.toLocaleString()}</span>
                                    <span>만원</span>
                                </TableCell>

                                <TableCell align="center" padding="none" className="subtxt">
                                    <span>{data.avg_gross_hour && data.avg_gross_hour.toLocaleString()}</span>
                                    <span>만원</span>
                                    <span className="gth_gross">
                                        {
                                            state_period_type === "month" &&
                                            <Fragment>
                                                {
                                                    data.avg_gross_hour_growth !== 0 ?
                                                        data.avg_gross_hour_growth > 0 ?
                                                            <Fragment>
                                                                <ArrowDropUpRoundedIcon color="primary"/>
                                                                {data.avg_gross_hour_growth}%
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                <ArrowDropDownRoundedIcon color="secondary"/>
                                                                {data.avg_gross_hour_growth}%
                                                            </Fragment>
                                                        :
                                                        '-'
                                                }
                                            </Fragment>
                                        }
                                    </span>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                }
            </Fragment>
        )
    }
}

export default S_BoxWorktimeContent;