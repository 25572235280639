import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";

import SPayInfo from "./S_PayInfo";
import SWorktime from "./S_Worktime";
import SCondition from "./S_Condition";
import SDemand from "./S_Demand";

class S_SalaryRouter extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/salary">
                    <SPayInfo handleCurrentMenu={this.props.handleCurrentMenu}/>
                </Route>
                <Route exact path="/salary/payinfo">
                    <SPayInfo handleCurrentMenu={this.props.handleCurrentMenu}/>
                </Route>
                <Route exact path="/salary/worktime">
                    <SWorktime handleCurrentMenu={this.props.handleCurrentMenu}/>
                </Route>
                <Route exact path="/salary/condition">
                    <SCondition handleCurrentMenu={this.props.handleCurrentMenu}/>
                </Route>
                <Route exact path="/salary/demand">
                    <SDemand handleCurrentMenu={this.props.handleCurrentMenu}/>
                </Route>
            </Switch>
        )
    }
}

export default S_SalaryRouter