import React, { Fragment } from 'react';
import $ from "jquery";
/*
function openGuide(evt, guideName) {
    var i, tabcontent, tablinks;
    tabcontent = $(".tabcontent");

    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = $(".tablinks");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    $("#" + guideName).show();
    evt.currentTarget.className += " active";
}

//document.getElementById("defaultOpen").click();
*/

const Tutorial = () => {
    return (
        <Fragment>
            <div className="Layer_wrap" data-popup="popup_tutorial">
                <div className="input_wrap">
                    <div className="tab">
                        <button className="tablinks active" id="tutorial_tab_1" onClick={()=>{ $('.tabcontent').hide(); $('.tablinks').removeClass('active'); $('#tutorial_tab_1').addClass('active'); $('#guide01').show(); }}>간단사용법</button>
                        <button className="tablinks" id="tutorial_tab_2" onClick={()=>{ $('.tabcontent').hide(); $('.tablinks').removeClass('active'); $('#tutorial_tab_2').addClass('active'); $('#guide02').show(); }}>표본데이터</button>
                    </div>
                    <div id="guide01" className="tabcontent">
                        <div className="contwrap">
                            <p className="tittxt">처방정보 서비스는?</p>
                            <p className="subtxt">처방정보는 보험심사평가원에서 제공하는 표본 데이터를 기반으로 만든 처방 정보 서비스입니다. <a href="http://www.hira.or.kr/download.do?src=%2Fshare%2Finternet%2Fpt%2Fbbs%2F479%2F2018%2F06%2FBZ201806263901243.pdf&fnm=2012+HIRA+6%EA%B6%8C+5%ED%98%B8_HIRA%EC%97%B0%EA%B5%AC_%EA%B1%B4%EA%B0%95%EB%B3%B4%ED%97%98%EC%8B%AC%EC%82%AC%ED%8F%89%EA%B0%80%EC%9B%90+%ED%99%98%EC%9E%90%ED%91%9C%EB%B3%B8%EC%9E%90%EB%A3%8C%28HIRA-NPS%29%EC%9D%98+%EC%86%8C%EA%B0%9C_%EA%B9%80%EB%A1%9D%EC%98%81.pdf" className="txtlinks">표본 데이터란?</a><br/>처방정보 사용자는 표본 데이터에 담긴 질환별 처방 약물(성분명)을 파악해, 대한민국 의사들이 임상에서 어떤 약물을 다빈도로 처방하는지 가늠해볼 수 있습니다. </p>

                            <p className="tittxt">추천하는 처방정보 사용예</p>
                            <p className="subtxt">1.의사 본인이 자주 처방하는 질환이지만, 해당 전문과의 처방 패턴을 참고하고 싶을 때<br/>(예: 당뇨약을 처방하는 의사가 상급종합병원의 내분비내과 처방을 확인)</p>
                            <p className="subtxt">2.약물의 급여 가능성이 확실치 않은 상황에서, 다빈도 약물임을 확인해 삭감 가능성을 배제할 때.</p>
                            <p className="subtxt">3.평소 처방 빈도가 낮아, 잘 기억나지 않던 약물을 처방해야만 할 때 </p>

                            <p className="tittxt">처방정보 사용 방법</p>
                            <p className="subtxt">이 페이지에선 처방정보의 사용 방법을 간단하게 설명합니다. 자세한 설명은 이후 상단 탭을 통해 추가할 예정입니다.</p>

                            <p className="subtit">1)검색어 입력</p>
                            <p className="subtxt">사용자는 검색 바에 질환 관련 키워드(영문/한글)나 상병코드를 입력할 수 있습니다. 기계가 인식할 수 있는 최소 단어나 코드를 입력하면 검색 바 하단엔 관련 질환이 제시됩니다.<br/>사용자는 제시한 질환 중 하나를 선택하거나, 키워드만 입력해(단일 키워드 입력 후 엔터키 혹은 검색 버튼) 키워드 관련 질환을 모두 불러올 수도 있습니다.</p>

                            <p className="subtit">2)결과 화면 설명</p>
                            <p className="subtxt">검색어를 입력하거나 제시한 질환을 선택하면, 결과 화면이 나타납니다. 결과 화면엔 관련 질환과 약물이 처방빈도순으로 표시됩니다. 아래는 결과 화면에 대한 설명입니다.</p>

                            <p className="subimg"><img src="http://image.medigate.net/drgate/images/sample_1.jpg" alt="가이드이미지"/></p>
                            <p className="subtit">2-1) 검색 및 검색옵션 영역</p>
                            <p className="subtxt">검색어, 상세 검색 버튼, METRICS 선택 버튼이 위치합니다.<br/>상세 검색을 누르면 전문과 / 세부내과 / 환자나이 / 환자성별 / 병원타 / 병상규모 / 지역의 7개 세부 조건 검색이 나옵니다. METRICS 버튼은 약물별 점유율을 산정하는 기준으로 처방 건수나 처방된 전체 약물수 중 택1 할 수 있습니다.</p>

                            <p className="subtit">2-2) 관련 상병 영역</p>
                            <p className="subtxt">약물 처방 결과 데이터 기준이 되는 상병을 모두 표시합니다. 전체보기를 누르면 전체 상병이 모두 표시되고, 수정하기 버튼을 통해 원하지 않는 상병을 빼고, 원하는 질환을 추가할 수도 있습니다.<br/>
                            <br/>이렇게 다중질환이 선택하게 만든 이유는 실제 처방 때 입력되는 상병의 특성 때문에 그렇습니다. 가령 합병증이 없는 고혈압 환자에게 A의사는 ‘기타 및 상세불명의 원발성 고혈압(I109)’라고 상병명을 입력하고 약물을 처방하지만,  B의사는 ‘본태성 고혈압(I10)’이라고 등록하기도 합니다. 이런 사례를 고려해, 사용자는 그룹 지어 보길 원하는 상병을 최대한 입맛에 맞게 넣거나 뺄 수 있습니다.</p>

                            <p className="subtit">2-3) 약물 카테고리 영역</p>
                            <p className="subtxt">해당 질환이 포함된 처방전의 모든 약물을 식약처에서 정한 효능 분류에 맞게 그룹 지어 보여줍니다. 하나의 처방전엔 둘 이상 상병이 입력된 케이스가 많고, 상병별로 따져도 약물이 수개씩 처방되기 때문입니다.(자세한 설명은 상단 탭 ‘심평원 표본데이터’ 확인)<br/>
                            <br/>가령 폐렴이란 질환을 검색했을 때  A의사는 진해거담제를 B의사는 항생제를 보고 싶을 것입니다. 이런 의도를 기계는 파악할 수 없어서 일단 많이 처방되는  효능 분류 그룹을 상단에 보여줍니다.<br/>
                            사용자는 처음 제시된 효능 그룹이 본인이 찾는 게 아닐 경우, 본인이 의도한 다른 그룹을 선택해 확인할 수 있습니다. </p>

                            <p className="subtit">2-4) 성분 리스트 영역</p>
                            <p className="subtxt">이 영역엔 관련 상병(2-2)과 약물 카테고리(2-3) 조건에 맞는 약물 성분이 점유율 순서대로 보입니다. 리스트 우측 상단엔 [외래/입원],  [ATC코드], [약물요법], [투여방법]에 대한 필터링 옵션이 있고, 사용자는 원하는 필터링 조건을 선택해 결과는 세부적으로 들여다볼 수 있습니다.<br/>
                            <br/>성분 리스트 하단엔 검색한 질환 관련, 간단한 주제(위키피디아)와 더불어 의사들의 코멘트가 노출되고, 이중 코멘트는 메디게이트 의사 계정을 통해 로그인한 의사 사용자만 노출됩니다.<br/>
                            성분 리스트 화면 내에서 성분명이 명시된 영역 우측엔 해당하는 상품을 확인할 수 있는 박스가 있고, 박스를 열어 해당 제품을 확인할 수 있습니다. 제품 중 링크 연결 아이콘이 있는 경우, 클릭 후 식약처 제공 제품 상세 페이지로 이동합니다.<br/>
                            <br/>사용자는 성분별 리스트의 성분명을 클릭해 성분 상세화면으로 접근할 수 있습니다. 성분별 상세화면엔 성분에 관한 자세한 통계, 메디게이트뉴스, 메디게이트 커뮤니티 게시물에서 제공하는 관련 콘텐츠가 노출됩니다 이중 메디게이트 커뮤니티 게시물은 메디게이트 의사 계정으로 로그인한 사용자만 확인할 수 있습니다. <br/>
                            *참고로 성분별 점유율은 결과 리스트에서 적용한 필터링 옵션과, 상세조건을 상속받아 표현합니다.</p>

                            <p className="subtit">2-5) 결과 화면: 마이페이지 영역(의사 로그인  사용자)</p>
                            <p className="subtxt">가장 상단 우측엔 마이페이지 버튼(별 아이콘)이 있고, 해당 버튼을 누르면 ‘내가 좋아요를 누른 약물’과 ‘게시한 코멘트’가 노출됩니다.<br/>
                            ‘좋아요를 누른 약물’은 관련 질환을 변경하고 약물 자체를 삭제할 수 있으며, 코멘트 역시 수정하거나 삭제할 수 있습니다.</p>
                        </div>
                    </div>

                    <div id="guide02" className="tabcontent" style={{display:'none'}}>
                        <div className="contwrap">
                            <p className="tittxt">건강보험심사평가원 표본데이터?</p>
                            <p className="subtxt">심평원 표본데이터는 1년간 의료이용을 한 모든 대한민국 환자 대상으로 성별, 연령구간(5세 단위)에 따른 환자 단위 층화 계층 추출 데이터</p>
                            <p className="subtxt">심평원의 데이터 공개 정책 변경에 따라 2019년이후의 표본데이터를 제공 받을 수 없게 되어 현재의 처방정보는 2018년 까지의 표본데이터 기준임을 알려 드립니다. (2021.8.05)</p>

                            <p className="tittxt">심평원 표본 데이터의 추출 방법</p>
                            <p className="subtit">입원환자</p>
                            <p className="subtxt">해당 연도의 전체 환자 중 13% 추출(모집단 1년간 가지는 입원 환자 1인당 평균 진료금액 표준 편차 0.5%에 해당하는 금액을 표본의 허용 오차 범위로 정함)</p>

                            <p className="subtit">외래환자</p>
                            <p className="subtxt">해당 연도의 전체 환자 중 1% 추출(입원과 동일한 오차 범위에서 필요 표본 인원 계산하여  0.05%에 산출했으나 1%로 확대 제공)<br/>매년 이런 로직으로 110만명 이상의 의료이용자를 표본 추출해, 이들의 1년간 입원, 외래 모든 진료 내역과 처방 내역을 집계합니다.</p>

                            <p className="subtxt2" style={{paddingTop: "10px"}}>*2017년 이후 데이터부터 특정 상병에 한해 비식별 조치를 강화했습니다.</p>
                            <p className="subtxt2">1)법정전염병, 희귀질환은 전부 마스킹<br/>2)F상병코드(정신 및 행동 장애)는 3단코드로 범주화하며, T∙U∙W∙X∙Y상병코드는 1단코드로 범주화 → 예시) F200(편집조현병, 네자리코드)은 F20(조현병,세자리코드)과 통합되어 호출되고, F200에 대한 통계 결과는 표시되지 않습니다. </p>


                            <p className="tittxt">다음은 표본 데이터 한계를 설명한 것입니다. 반드시 꼭 읽어보시길 바랍니다.</p>
                            <p className="subtxt">1.급여가 인정된 진료 내역만 포함되어 비급여 항목은 확인할 수 없습니다. </p>
                            <p className="subtxt">2.위 1의 이유로 데이터 생성이 다소 늦습니다. 일반적으로 보험 심사 완료엔 최대 6개월이 걸립니다. 2019년 표본 데이터의 경우 해당 년도 완료 시점인 2019년 말 기준, 6개월이 지난 2020년 6월 이후에 가능합니다.</p>
                            <p className="subtxt">3.표본 자료 내의 관측치는 확률에 의해 추출되는 자료이기 때문에 적정수준 이상의 표본 수를 확보해야 대표성, 유의성을 보장받을 수 있습니다.<br/>예를 들어 처방정보 환자표본자료에서 특정 연령에 한정한 희귀 질환의 경우 표본추출 빈도가 너무 적어 대표성이 떨어질 수 있다.</p>


                            <p className="subtxt" style={{color: "#ff6666", paddingTop: "10px"}}>4.처방 약물과 검색 질환이 정확하게 매칭되어 있지 않은 문제</p>
                            <p className="subtxt" style={{color: "#ff6666"}}>처방정보 서비스에서 질환별 처방 약물의 결괏값은 ‘해당 질환이 포함된 처방전의 모든 약물’을 재구성하여 보여줍니다.<br/>
                            여기서 사용자는 ‘해당 질환이 포함된 처방전의 모든 약물’이란 말을 잘 음미해 볼 필요가 있습니다.<br/>
                            만약 어떤 사용자가 ‘당뇨’라는 질환을 검색하면, 검색 결과의 약효별 카테고리엔 ‘당뇨병용제’ 뿐만 아니라 ‘혈압강하제’도 상위에 노출됩니다.<br/>
                            이것은 많은 당뇨 환자가 고혈압을 동반하고 있어, 그럴 경우 당뇨약을 처방 받은 처방전에 혈압강하제도 포함되기 때문입니다.<br/>
                            사용자는 처방 결과를 해석할 때 ‘혈압강하제가 당뇨라는 질환을 위해 처방된 것도 아니고, 당뇨라는 질환의 급여약물도 아니다’는 정도의 사전 지식이 필요합니다.<br/>
                            대신 이런 데이터의 특성이 다른 가능성을 제시하기도 합니다.<br/>
                            당뇨라는 질환을 검색하고 나온 혈압강하제 결과는 ‘당뇨 환자(혹은 당뇨가 동반된 고혈압 환자)가 처방 받은 다빈도 고혈압 약물’이라고 해석할 수 있기 때문입니다. </p>
                        </div>
                    </div>
                </div>
                <p data-popup-close="popup_tutorial">
                    <a href="#" className="btn-closeApply"></a>
                </p>
            </div>
            <div className="tutorial">
                <a data-popup-open="popup_tutorial" href="#">
                    <p className="tutorial_tit">처방정보?</p>
                </a>
            </div>
        </Fragment>
    )
}

export default Tutorial;