import React, {Fragment, useState} from 'react';
import LocationMain from './LocationMain';
import ServicePolicy from "../common/ServicePolicy";
import SServicePolicy from "../common/ServicePolicy";
import {useStore} from "../../stores/StoreProvider";
import {observer} from "mobx-react";
import {withCookies} from "react-cookie";
import useLocalStorage from "react-use-localstorage";
import { isMobileOnly } from 'react-device-detect';

// class Location extends React.PureComponent {
// const LocationMobileNotice = observer((props) => {
const LocationMobileNotice = () => {
    const _bgImgUrl = "http://image.medigate.net/drgate/images/refused/background.png";

    const buttonClass = {
        position:"fixed",
        display: "block",
        bottom: "40px",
        left: "40px",
        right: "40px",
        zIndex: "1",
        lineHeight: "0",
        // boxShadow: "0px 8px 13px -3px",
        boxShadow: "0px 8px 13px -3px rgba(0, 6, 29, 0.3)",
    }
    return (
        <div style={{minWidth:"100%",minHeight:"100%",position: "fixed", backgroundImage:`url(${_bgImgUrl})`, backgroundPosition:"center", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
            {/*MOBILE*/}
            <a href="/" className="button" style={buttonClass}>
                <img src="http://image.medigate.net/drgate/images/refused/button.png" className="image" style={{width:"100%"}}
                     alt="닥터게이트 메인으로 이동" />
            </a>
        </div>
    );
};

export default withCookies(LocationMobileNotice);