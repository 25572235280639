import React, {Fragment} from 'react';
import Category from './Category';

//menu
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

const drawerWidth = 240;
const menuStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
}));

const CategoryFragment = (props) => {

    const { current_ctg_code, ctg_name2, ctg_name3, force_rerender_category, data } = props;
    const { container } = props;
    const [ menuOpen, setMenuOpen ] = React.useState(false);
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const [ moreAnchorEl, setMoreAnchorEl ] = React.useState(false);
    
    return (
        <Fragment>
            <div className="ctg_icon_area">
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    edge="start"
                    onClick={()=> setMenuOpen(!menuOpen)}
                    className={menuStyles.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <div className="ctg_name">
                    <p className="ctg2" onClick={()=> setMenuOpen(!menuOpen)}>{ctg_name2}</p>
                    <p className="ctg3">{ctg_name3}</p>
                </div>
            </div>
            <nav className={menuStyles.drawer} aria-label="Mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={'left'}
                        open={menuOpen}
                        onClose={()=> setMenuOpen(!menuOpen)}
                        classes={{
                            paper: menuStyles.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <div>
                            <div className={menuStyles.toolbar} />
                            <Category
                                data={data}
                                current_ctg_code={current_ctg_code}
                                force_rerender_category={force_rerender_category}
                                onSearchPrescriptList={(ctg_code_grp, ctg_code, ctg_name2, ctg_name3)=>{props.onSearchPrescriptList(ctg_code_grp, ctg_code, ctg_name2, ctg_name3); setMenuOpen(false);}}
                            />
                        </div>
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: menuStyles.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <div>
                            <div className={menuStyles.toolbar} />
                            <Category
                                data={data}
                                current_ctg_code={current_ctg_code}
                                force_rerender_category={force_rerender_category}
                                onSearchPrescriptList={props.onSearchPrescriptList}
                            />
                        </div>
                    </Drawer>
                </Hidden>
            </nav>
        </Fragment>
    );
}

export default CategoryFragment;