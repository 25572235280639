import gql from 'graphql-tag';

//for search-engine
export const KCD_LIST = gql`
	query select($kcd_code_arr: [String], $order: String) {
		getKcdList(kcd_code_arr: $kcd_code_arr, order: $order) {
			kcd_code
			kcd_kor
			kcd_eng
		}
	}
`;


export const USER_INFO = gql`
    query select($u_id: String!) {
        getUserInfo(u_id: $u_id) {
            u_id
            u_kind
            u_name
            u_email
            u_mobile
            u_spc_code
            u_wtp_code
            addr
            u_zip_zone
        }
    }
`;

export const GEOCODE = gql`
    query select($addr: String!){
        getGeocode( addr: $addr ) {
            pos_x
            pos_y
            addr
        }
    }
`;

export const DONG_INFO = gql`
    query select($pos_x: String!, $pos_y: String!){
        getDongInfo(pos_x: $pos_x, pos_y: $pos_y) {
            sido_code
            sgg_code
            emd_code
            sido_name
            sgg_name
            emd_name
            addr
        }
    }
`;

export const LEASE_LIST = gql`
    query select($sgg_code: String!){
        getLeaseList(sgg_code: $sgg_code) {
            lease_idx
            title
            lease_type
            lease_type_code
            sido_name
            sgg_name
            addr
            supply_area
            private_area
            rent_layer
            tot_over_layer
            tot_under_layer
            pos_x
            pos_y
        }
    }
`;

export const HOSPITAL_LIST = gql`
    query select ($polygon: String!, $spc_code_arr: [String]!, $hos_type_code_arr: [String], $spc_type: String!){
        getHospitalList(polygon: $polygon, spc_code_arr: $spc_code_arr, hos_type_code_arr: $hos_type_code_arr, spc_type: $spc_type) {
            hos_code
            hos_name
            hos_type_code
            addr
            pos_x
            pos_y
            estb_date
            close_date
            homepage
            tel
            bed
        }
    }
`;

export const HOSPITAL_CLUSTER_SGG = gql`
    query select ($polygon: String!, $spc_code_arr: [String]!, $hos_type_code_arr: [String], $spc_type: String!){
        getHospitalClusterListBySgg(polygon: $polygon, spc_code_arr: $spc_code_arr, hos_type_code_arr: $hos_type_code_arr, spc_type: $spc_type) {
            region_name
            pos_x
            pos_y
            hospital_count
        }
    }
`;

export const HOSPITAL_CLUSTER_EMD = gql`
    query select ($polygon: String!, $spc_code_arr: [String]!, $hos_type_code_arr: [String], $spc_type: String!){
        getHospitalClusterListByEmd(polygon: $polygon, spc_code_arr: $spc_code_arr, hos_type_code_arr: $hos_type_code_arr, spc_type: $spc_type) {
            region_name
            pos_x
            pos_y
            hospital_count
        }
    }
`;

export const POPULAR_LOCATION_LIST = gql`
    query select ($sido_code: String, $spc_code_arr: [String]){
        getPopularLocationList(sido_code: $sido_code, spc_code_arr: $spc_code_arr) {
            addr
            search_cnt
        }
    }
`;

export const SEARCH_REGION = gql`
    query select ($u_id: String!){
        getSearchRegion(u_id: $u_id) {
            sido_code
            sgg_code
            emd_code
            addr
        }
    }
`;

export const REGION_LIST = gql`
    query select ($region_code: String!){
        getRegionList(region_code: $region_code) {
            region_code
            region_name
        }
    }
`;

export const MY_SEARCH_LOG_CNT = gql`
    query select ($u_id: String!){
        getSearchLogCnt(u_id: $u_id) {
            count
        }
    }
`;

export const MY_SEARCH_LOG_LIST = gql`
    query select ($u_id: String!, $start_index: Int, $row_count: Int){
        getSearchLogList(u_id: $u_id, start_index: $start_index, row_count: $row_count) {
            spc_code
            spc_type
            hos_type_code
            search_condition{
                sido_code
                sgg_code
                emd_code
                addr
                shape
                circle_info{
                    center_x
                    center_y
                    radius
                }
                rect_info{
                    min_x
                    min_y
                    max_x
                    max_y
                }
                polygon_info{
                    pos_x
                    pos_y
                }
            }
            reg_ymd
            reg_his
        }
    }
`;

export const EDIT_SEARCH_REGION = gql`
    mutation insert ($u_id: String!, $sido_code: String!, $sgg_code: String!, $emd_code: String!){
        editSearchRegion(u_id: $u_id, sido_code: $sido_code, sgg_code: $sgg_code, emd_code: $emd_code) {
            result
        }
    }
    
`;

export const REMOVE_SEARCH_REGION = gql`
    mutation delete ($u_id: String!){
        removeSearchRegion(u_id: $u_id) {
            result
        }
    }
    
`;

export const STAT_HOSPITAL = gql`
    query select ($hos_code_arr: [String]!){
        getStatHospital(hos_code_arr: $hos_code_arr) {
            code
            label
            value
        }
    }
`;

export const STAT_CLINIC_OPEN_CLOSE = gql`
    query select ($polygon: String!, $spc_code_arr: [String]!){
        getStatClinicOpenClose(polygon: $polygon, spc_code_arr: $spc_code_arr) {
            stat_12_months_prev_count
            stat_10_years_prev_count
            stat_12_months{
                date
                value_open
                value_close
            }
            stat_10_years{
                date
                value_open
                value_close
            }
        }
    }
`;

export const STAT_CLINIC_BY_SPC = gql`
    query select ($hos_code_arr: [String]!, $spc_code_arr: [String]!){
        getStatClinicBySpc(hos_code_arr: $hos_code_arr, spc_code_arr: $spc_code_arr) {
            code
            label
            value_specialty
            value_subject
        }
    }
`;

export const DONG_LIST = gql`
query select ($polygon: String!){
    getDongList(polygon: $polygon ) {
          sido_code
          sgg_code
          emd_code
          sido_name
          sgg_name
          emd_name
          polygon
    }
}
`;

export const STAT_SGIS_SUMMARY = gql`
    query select ($emd_code_arr: [String]!){
        getStatSgisSummary(emd_code_arr: $emd_code_arr ) {
            sgis_year
            emd_code
            tot_ppltn
            jobpeople
            avg_age
            ppltn_dnsty
            tot_family
            corp_cnt
            sex_m
            sex_f
            age_pp {
                teenage_less_than_cnt
                teenage_cnt
                twenty_cnt
                thirty_cnt
                forty_cnt
                fifty_cnt
                sixty_cnt
                seventy_more_than_cnt
            }
            house_pp {
                apart_cnt
                row_house_cnt
                dom_soc_fac_cnt
                detach_house_cnt
                officetel_cnt
                etc_cnt
            }
            region_total{
                adm_cd
                adm_nm
                one_person_family_per
                resid_ppltn_per
                twenty_ppltn_per
                sixty_five_more_ppltn_per
                apart_per
                job_ppltn_per
            }
            biz_theme_ratio {
                adm_cd
                adm_nm
                theme_list{
                    theme_cd
                    s_theme_cd_nm
                    dist_per
                }
            }
            biz_theme_increase {
                adm_cd
                year
                theme_list{
                    theme_cd
                    theme_nm
                    corp_cnt
                }
            }
        }
    }
`;

export const STAT_HIRA = gql`
    query select ($sgg_code_arr: [String]!, $spc_code_arr: [String]!){
        getStatHira(sgg_code_arr: $sgg_code_arr, spc_code_arr: $spc_code_arr ) {
            date
            summary{
                patient_cnt
                treat_cnt
                amount
            }
            summary_trend_list{
                date
                patient_cnt
                treat_cnt
                amount
            }
            disease_list{
                kcd_kor
                kcd_eng
                patient_cnt
                treat_cnt
                amount
            }
            disease_trend_list{
                date
                kcd_kor
                patient_cnt
                treat_cnt
                amount
            }
        }
    }
`;

export const STAT_HIRA_SGG = gql`
    query select ($sido_code: String!){
        getHiraStatBySgg(sido_code: $sido_code) {
            sgg_code
            sgg_name
            patient_cnt
            treat_cnt
            amount
        }
    }
`;

export const BIZAREA_LIST = gql`
    query select ($shape: String!, $circle_info: CIRCLE, $rect_info: RECT){
        getBizareaList(shape: $shape, circle_info: $circle_info, rect_info: $rect_info ) {
            area_name
            area_code
            polygon
        }
    }
`;

export const MY_SEARCH_LOG_INSERT = gql`
    mutation insert ($u_id: String!, $u_spc_code: String, $u_wtp_code: String, $u_loc_code: String, $spc_code_arr: [String], $spc_type: String, $hos_type_code_arr: [String], $search_condition_arr: [INPUT_SEARCH_CONDITION]! ){
        addSearchLog(u_id: $u_id, u_spc_code: $u_spc_code, u_wtp_code: $u_wtp_code, u_loc_code: $u_loc_code, spc_code_arr: $spc_code_arr, spc_type: $spc_type, hos_type_code_arr: $hos_type_code_arr, search_condition_arr: $search_condition_arr ) {
            result
        }
    }
`
/*
export const BIZAREA_LIST = gql`

`;
*/
