import React, { Fragment } from 'react';
import { withApollo } from 'react-apollo';
import _ from 'lodash';
import axios from 'axios';
import { isMobileOnly } from 'react-device-detect';
import My from './MyLayer';
import CommonFunc from '../../../common/CommonFunction';
import { confirmAlert } from 'react-confirm-alert';
import HtmlConfirm from '../../../common/HtmlConfirm';

class UserProfileLayer extends React.PureComponent {
	constructor(props){
		super(props);
		this.state = {
			userSession: this.props.user,
			fetchedUserData: false,
			userProfile: null,
			username: '',
			password: '',
			rememberme: true,
			message: null,
			signinBtnLabel: '로그인'
		}
	}

	componentWillMount = async () => {
		const userSession = await CommonFunc.getSessionUserInfo();
		//console.log('userSession', userSession);
		if(userSession){
			this.getUserProfile(userSession.id, CommonFunc.getToken());
		}
		else{
			//console.log('로그인 되지 않은 유저~!!');
			this.setState({
				fetchedUserData: true
			});
		}
	}

	getUserProfile = async (u_id, token) => {
		const userProfile = await CommonFunc.getUserProfile(u_id, token);
		//console.log('userProfile result : ', userProfile);
		this.setState({
			fetchedUserData: true,
			userProfile: userProfile
		});
	}

	signin = (e) => {
		e.preventDefault();

		if(_.isEmpty(this.state.username)){
			this.setState({
				message: '아이디를 입력하세요.'
			})
			return;
		}
		if(_.isEmpty(this.state.password)){
			this.setState({
				message: '비밀번호를 입력하세요.'
			})
			return;
		}

		this.setState({
			signinBtnLabel: '로그인 중...'
		})

		const endpoint = `https://apis.medigate.net/signin?username=${this.state.username}&password=${this.state.password}`;
		//console.log('singin_url', endpoint)

		const thisProps = this.props;
		//console.log('thisProps', thisProps)
		axios.post(endpoint, {headers: {'Content-Type': 'application/json;charset=UTF-8'}})
		.then((response) => {
			const responseData = response.data;
			const userInfo = responseData.user;
			//console.log("userInfo: ", userInfo)

			var isAllowedRoleForDrgate = userInfo.uKind === 'UKD000' || userInfo.uKind === 'UKD001';
			if(isAllowedRoleForDrgate){
				//console.log('responseData', responseData);
				CommonFunc.saveAuthInfoToStorage(this.state.rememberme, responseData);
				CommonFunc.pageview('공통', '로그인'); //Google Analytics
				window.location.reload();
			}
			else{
				//로그인 권한 없음
				this.setState({
					message: '메디게이트 의사 선생님만 로그인 가능합니다.',
					signinBtnLabel: '로그인'
				})
			}
		})
		.catch(error => {
			//console.log("singin error: ", error);
			this.setState({
				message: '아이디 또는 비밀번호가 일치하지 않습니다.',
				signinBtnLabel: '로그인'
			})
		});
	}

	handleOnChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	profileImageNoti = () => {
		confirmAlert({ customUI: ({ onClose }) => <HtmlConfirm title={"이미지 수정 불가"} message={"현재 이미지 등록/수정은 지원하지 않습니다.<br/>꼭 필요한 경우 메디게이트 모바일을 이용해<br/>프로필 이미지를 등록/수정하세요."} onClose={onClose} />});
		return false;
	}

	render() {

		const { fetchedUserData, userProfile } = this.state;
		//console.log('userProfile : ', this.state);

		return (
			<Fragment>
			{
			fetchedUserData &&
				<Fragment>
				{ userProfile ?
					<Fragment>
						<div className="header-field menu_userinfo">
							<div id="popup_userinfo" className="modal fade mdc-dialog">
								<div className="modal-dialog">
									<div className="contwrap modal-content">
										<p className="mdl-button close" data-dismiss="modal">
											<span className="btn-closeApply"></span>
										</p>
										<div className="profilearea">
											{/*
											<div className="btn_imgchange">
												<button className="change_thumb"><span>변경</span></button>
											</div>
											*/}
											<div className="info_img">
												{ !_.isEmpty(userProfile.u_pic) ?
													<p className="profile_thumb" onClick={this.profileImageNoti} style={{backgroundImage: `url(${CommonFunc.getImageDomain()}${userProfile.u_pic})`, cursor: "pointer" }}></p>
													:
													<p className="profile_thumb" onClick={this.profileImageNoti} style={{backgroundImage: `url(//image.medigate.net/static/mobile/ver_3/images/icon_profilethumb.gif)`, cursor: "pointer" }}></p>
												}
											</div>
											<div className="info_txt">
												<p className="tittxt">{userProfile.u_name}</p>
												<div>
													{/*<p className="subtxt">{userProfile.u_nick}</p>*/}
													{/*<p className="subtxt txtlink">필명생성</p>*/}
												</div>
												<p className="subtxt mail">{userProfile.u_email}</p>
											</div>
										</div>
										<div className="contarea">
											<div className="info_txt specialty">
												<span className="line_right">{userProfile.u_profile_spc_name}</span>
												<span>{userProfile.u_wtp_name}</span>
												{/*
												<p className="txt_edit txtlink">
													<span onClick={() => {
														let url = isMobileOnly ? `https://www.medigate.net/user/edit/basic`;
														window.open(url, '_blank');
													}}>정보수정</span>
												</p>
												*/}
											</div>
											<div className="info_txt location">
												<span className="loc01">{userProfile.u_office}</span>
												<span className="loc02">{userProfile.u_office_addr}</span>
											</div>
										</div>
										<div className="btn_type3 border-top-1px">
											<button className="mdc-button mdc-button--raised select left"
												onClick={() => {
													let url = `https://www.medigate.net/user/edit/basic`;
													window.open(url, '_blank');
												}}
											>정보수정</button>
											<button className="mdc-button mdc-button--outlined cancel right" onClick={()=>CommonFunc.signout()}>로그아웃</button>
										</div>
									</div>
								</div>
							</div>
							{/*<!-- 마이 -->*/}
							<My/>
							<div className="dialog-button userinfo" data-toggle="modal" data-target="#popup_userinfo">
								<div className="info_profile">
									{ !_.isEmpty(userProfile.u_pic) ?
										<p className="profile_thumb" style={{backgroundImage: `url(${CommonFunc.getImageDomain()}${userProfile.u_pic})` }}></p>
										:
										<p className="profile_thumb" style={{backgroundImage: `url(//image.medigate.net/static/mobile/ver_3/images/icon_profilethumb.gif)` }}></p>
									}
									{/*<p className="txtinfo"><span>{userProfile.u_name}</span> 님</p>*/}
									{/*<div className="bg_line"></div>*/}
								</div>
							</div>
						</div>
					</Fragment>
				:
					<Fragment>
						<div className="header-field menu_userinfo">
							<div id="popup_login" className="modal mdc-dialog">
								<div className="modal-dialog">
									<div className="contwrap modal-content">
										<p className="mdl-button close" data-dismiss="modal">
											<span className="btn-closeApply"></span>
										</p>
										<form onSubmit={(e)=>this.signin(e)}>
											<div className="profilearea login">
												<p className="input_row txt">메디게이트 의사회원만<br/>로그인할 수 있습니다.</p>
												<div className="input_row">
													<input
														type="text"
														name="username"
														value={this.state.username}
														placeholder="메디게이트 아이디"
														onChange={this.handleOnChange}
														className="form-control" />
												</div>
												<div className="input_row">
													<input
														type="password"
														name="password"
														value={this.state.password}
														placeholder="메디게이트 비밀번호"
														onChange={this.handleOnChange}
														className="form-control" />
												</div>
												<button type="submit" className="mdc-button mdc-button--raised select left">{this.state.signinBtnLabel}</button>
												{
													this.state.message &&
													<div className="error"><p className="error_txt"  dangerouslySetInnerHTML={{__html: this.state.message}}/></div>
												}
												<div className="gridcontainer signin_options">
													<div className="input_row padding-10px">
														<div className="checkbox">
															<label className="label_txt">
																<span className={this.state.rememberme?'ckbox on':'ckbox'}>
																	<input type="checkbox" name="rememberme" defaultChecked={this.state.rememberme} onChange={()=>{ this.setState({rememberme: !this.state.rememberme}) }}/>
																</span>
																로그인 상태 유지
															</label>
														</div>
													</div>
													<div className="input_row pc padding-10px border-top-1px">
														<a className="forgotid" href="https://www.medigate.net/user/member/findForm?findType=U_ID" target="_blank">아이디 찾기</a>
														<a className="forgotpassword" href="https://www.medigate.net/user/member/findForm?findType=U_PASSWD" target="_blank">비밀번호 찾기</a>
														<a className="join" href="https://www.medigate.net/user/member/addStep01" target="_blank">의사회원 가입</a>
													</div>
													<div className="input_row mobile padding-10px border-top-1px">
														<p className="forgot">아이디, 비밀번호 찾기는 PC에서 이용할 수 있습니다.</p>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div className="dialog-button" data-toggle="modal" data-target="#popup_login">
								<div className="info_profile">
									<p className="profile_thumb" style={{backgroundImage: 'url(//image.medigate.net/static/mobile/ver_3/images/icon_profilethumb.gif)' }}></p>
									<p className="txtinfo"><span>로그인</span></p>
									<div className="bg_line"></div>
								</div>
							</div>
						</div>
					</Fragment>
				}
				</Fragment>
			}
			</Fragment>
		);
	}
}
export default withApollo(UserProfileLayer);
