import React, { Fragment } from 'react';
import SearchKeywordInput from './SearchKeywordInput';

class SearchFragment extends React.PureComponent {

    onSearched = (keyword, kcd_list) => {
        //console.log('onSearched', keyword, kcd_list);
    }

    render() {
        //console.log('SearchFragment props', this.props);
        return (
            <Fragment>
                <SearchKeywordInput
                    keyword={this.props.keyword}
                    filter_kcd_code_arr={this.props.filter_kcd_code_arr}
                    onSearched={this.props.onSearched}
                    searchForm={this.props.searchForm}
                />
            </Fragment>
        )
    }
}

export default SearchFragment;