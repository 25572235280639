import {withStyles} from "@material-ui/core/styles";

const SalaryGlobalCss = withStyles({
    // @global is handled by jss-plugin-global.
    '@global': {
        // You should target [class*="MuiButton-root"] instead if you nest themes.
        'body':{backgroundColor:'#f4f5f9 !important'},
        'header.MuiAppBar-colorPrimary':{backgroundColor:'#fff',boxShadow:'none',borderBottom:'1px solid rgba(0,0,0,0.05)'},
        'header.MuiAppBar-colorPrimary h1':{color:'#000',fontSize:'16px',fontWeight:'400',fontFamily:'Noto Sans KR,Nanum Gothic,나눔고딕,Dotum,돋움,verdana'},
        'header.MuiAppBar-colorPrimary h1 a':{fontWeight:'400'},
        'header.MuiAppBar-colorPrimary a':{color:'#000'},
        '.MuiTypography-gutterBottom':{marginBottom:'1rem'},
        '.headermenu':{position:'absolute',top:'0',left:'50%',marginLeft:'-160px',letterSpacing:'0'},
        '.headermenu a':{marginRight:'50px',fontSize:'1rem',lineHeight:'64px',fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana'},
        '.headermenu a:last-child':{paddingRight:'0'},
        '.MuiSvgIcon-root':{color:'#000'},
        '.MuiSvgIcon-root.MuiSvgIcon-colorPrimary':{color:'#1d40ff'},
        '.MuiSvgIcon-root.MuiSvgIcon-colorSecondary':{color:'#bf1c10'},
        '.MuiTypography-h5':{fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'2.0rem',color:'#31415f',fontWeight:'bold',letterSpacing:'-1px'},
        '.MuiTypography-h6': {fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana'},
        '.MuiTypography-caption':{fontSize:'12px',letterSpacing:'-1px',color:'rgba(0,0,0,0.5)'},
        '.MuiGrid-spacing-xs-3': {padding:'0', margin:'0',width:'calc(100% - -12px)',marginLeft:'-6px'},
        '.MuiGrid-spacing-xs-3 > .MuiGrid-item': {padding:'12px 6px'},
        '.MuiGrid-root .MuiTypography-root': {},
        '.MuiPaper-elevation1': {boxShadow:'none',background:'none',padding:'0'},
        //Overview테이블높이제한해제
        '.Overview':{width:'100%',display:'flex',paddingTop:'10px'},
        '.Overview .MuiPaper-elevation1':{height:'auto !important',padding:'30px 0 !important',boxShadow:'0px 26px 35px -25px rgba(68, 52, 206, 0.5)',background:'#7d69d4'},
        '.Overview .myinfo':{marginTop:'-5px'},
        '.Overview .myinfo .MuiPaper-elevation1':{background:'#6a51cd',borderTopLeftRadius:'0',borderTopRightRadius:'0',borderTop:'1px dashed rgba(0,0,0,0.05)'},
        '.Overview .myinfo .MuiTypography-subtitle1':{fontSize:'1.25rem',color:'#fff',fontWeight:'400',marginBottom:'20px',lineHeight:'1.4',letterSpacing:'-1px'},
        '.Overview .MuiTypography-h6': {color:'#80DEEA',fontWeight:'700',marginBottom:'0'},
        '.Overview .MuiTypography-subtitle1':{fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'1.25rem',color:'#31415f',fontWeight:'bold',marginBottom:'20px',lineHeight:'1.4'},
        '.Overview_time':{width:'100%',display:'flex',fontFamily:'Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana'},
        '.Overview_time .MuiPaper-elevation1':{height:'auto !important',padding:'30px 0 !important',boxShadow:'0px 26px 35px -25px rgba(14, 31, 115, 0.8)',background:'#5755d9'},
        '.Overview_time .MuiTypography-h6': {color:'#fff176',fontWeight:'400',marginBottom:'0'},
        '.Overview_time .MuiTypography-subtitle1':{fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'1.25rem',color:'#000',marginBottom:'20px',lineHeight:'1.4'},
        '.Overview_time .MuiTableCell-root':{textAlign:'center',fontSize:'12px',color:'#fff'},
        '.Overview_time .MuiTableCell-root.tittxt_time':{borderBottom:'none',padding:'0'},
        '.Overview_time .MuiTableCell-root.subtxt1_time':{borderBottom:'none',padding:'0',lineHeight:'1.0'},
        '.Overview_time .MuiTableCell-root.subtxt1_time .data_time':{fontSize:'3.5rem',fontFamily:'Ubuntu,sans-serif',fontWeight:'700'},
        '.Overview_time .MuiTableCell-root.subtxt1_time .growth':{display:'inline-block',width:'60px',paddingRight:'5px',textAlign:'right',letterSpacing:'-1px'},
        '.Overview_time .MuiTableCell-root.subtxt1_time .text':{display:'inline-block',width:'60px',paddingLeft:'5px',textAlign:'left',letterSpacing:'-1px'},
        '.Overview_time .MuiTableCell-root.subtxt2_time':{borderBottom:'none',padding:'10px 0 20px',color:'#b2faff'},
        '.Overview_time .subtxt':{padding:'0 0 15px',borderBottom:'none',fontSize:'1.25rem',color:'#c9c9ec'},
        '.Overview_time .subtxt .gth_net':{display:'inline-block',width:'60px',paddingLeft:'5px',fontSize:'0.875rem',letterSpacing:'-1px'},
        '.Overview_time .subtxt .gth_gross':{display:'inline-block',width:'60px',paddingRight:'5px',fontSize:'0.875rem',letterSpacing:'-1px'},
        '.Overview_time .myinfo':{marginTop:'-5px'},
        '.Overview_time .myinfo .MuiPaper-elevation1':{background:'#4a49c5',borderTopLeftRadius:'0',borderTopRightRadius:'0',borderTop:'1px dashed rgba(0,0,0,0.05)'},
        '.Overview_time .myinfo .MuiTypography-subtitle1':{fontSize:'1.25rem',color:'#fff',fontWeight:'400',marginBottom:'20px',lineHeight:'1.4'},
        '.Overview_time .MuiTableCell-paddingNone:last-child':{padding:'0 0 15px'},

        '.myinfo .txtinfo':{fontSize:'1rem',color:'#fff',lineHeight:'1.2'},
        '.myinfo .txtinfo .MuiTypography-subtitle1':{color:'#ffeb3b',margin:'10px 0'},
        //그래프배경삭제- jss190
        '.pagegraph .MuiGrid-container':{padding:'0 20px',width:'100%',margin:'0',background:'rgba(255,255,255,0.5)',borderRadius:'4px'},
        '.pagegraph .MuiTypography-body1':{marginBottom:'1rem'},
        '.pagegraph .MuiPaper-elevation1':{boxShadow:'none',background:'none',height:'auto !important'},
        '.pagegraph .MuiGrid-spacing-xs-3 > .MuiGrid-item': {padding:'10px 0 30px',borderBottom:'1px dashed rgba(0,0,0,0.1)'},
        '.pagegraph .MuiGrid-spacing-xs-3 > .MuiGrid-item:last-child': {borderBottom:'none'},
        '.pagegraph .MuiGrid-spacing-xs-4 > .MuiGrid-item': {padding:'20px 0 30px',borderBottom:'1px dashed rgba(0,0,0,0.1)'},
        '.pagegraph .MuiGrid-spacing-xs-4 > .MuiGrid-item:last-child': {borderBottom:'none'},
        '.pagegraph.type2 .MuiGrid-spacing-xs-3 > .MuiGrid-item':{padding:'40px 0 30px'},
        '.pagegraph.type2 .MuiPaper-root':{fontSize:'0.875rem'},
        '.pagegraph.type1 .MuiPaper-root':{fontSize:'0.875rem'},
        '.pagegraph .App':{fontFamily:'Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',letterSpacing:'-1px'},

        '.MuiTypography-root.MuiPaper-elevation1':{},
        '.MuiTableCell-root': {color:"#445891",textAlign:'center',fontSize:'12px'},
        '.recharts-yAxis.yAxis':{stroke:'rgba(0,0,0,0)'},
        '.MuiTableCell-root.tittxt_net':{borderBottom:'none',padding:'0',color:'#fff',textAlign:'center'},
        '.MuiTableCell-root.subtxt1_net':{borderBottom:'none',padding:'0',lineHeight:'1.0',color:'#fff',textAlign:'center'},
        '.MuiTableCell-root.subtxt1_net .data_net':{fontSize:'3.5rem',fontFamily:'Ubuntu,sans-serif',fontWeight:'700',color:'#fff'},
        '.MuiTableCell-root.subtxt1_net .growth':{display:'inline-block',width:'60px',paddingRight:'5px',textAlign:'right',letterSpacing:'-1px'},
        '.MuiTableCell-root.subtxt1_net .text':{display:'inline-block',width:'60px',paddingLeft:'5px',textAlign:'left',letterSpacing:'-1px'},
        '.MuiTableCell-root.subtxt2_net':{borderBottom:'1px dashed rgba(255,255,255,0.2)',padding:'10px 0 20px',color:'rgba(255,255,255,0.6)',textAlign:'center'},
        '.MuiTableCell-root.tittxt_grs':{borderBottom:'none',padding:'20px 0 0',color:'#0f0633',textAlign:'center'},
        '.MuiTableCell-root.subtxt1_grs':{borderBottom:'none',padding:'0',lineHeight:'1.0',color:'#0f0633',textAlign:'center'},
        '.MuiTableCell-root.subtxt1_grs .data_grs':{fontSize:'3rem',fontFamily:'Ubuntu,sans-serif',fontWeight:'700',color:'#0f0633'},
        '.MuiTableCell-root.subtxt1_grs .growth':{display:'inline-block',width:'60px',paddingRight:'5px',textAlign:'right',letterSpacing:'-1px'},
        '.MuiTableCell-root.subtxt1_grs .text':{display:'inline-block',width:'60px',paddingLeft:'5px',textAlign:'left',letterSpacing:'-1px'},
        '.MuiTableCell-root.subtxt2_grs':{borderBottom:'0',padding:'10px 0',color:'#0f0633',textAlign:'center'},
        '.MuiAppBar-colorPrimary':{backgroundColor:'#1976d2'},
        //hlink_ad
        '.hlink_ad':{position:'relative'},
        '.hlink_ad .MuiPaper-elevation1': {display:'block',boxShadow:'none',background:'#fff',padding:'0 !important',height:'180px !important'},
        '.hlink_ad .hr_infoimg':{float:'left',display:'block',width:'50%',height:'180px'},
        '.hlink_ad .hr_infotxt':{float:'left',display:'inline-block',width:'49%',padding:'20px 0 0'},
        '.hlink_ad .txtinfo1':{paddingBottom:'5px'},
        '.hlink_ad .txtinfo1 .title':{display:'inline-block',minWidth:'300px',textAlign:'left',
            fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'1.25rem',fontWeight:'700',color:'#1a1a1a',letterSpacing:'-1px'},
        '.hlink_ad .txtinfo2':{fontFamily:'Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',letterSpacing:'-1px',color:'rgba(0,0,0,0.5)'},
        '.hlink_ad .txtinfo2:first-child':{paddingTop:'5px'},
        '.hlink_ad .hlink_logo':{position:'absolute',width:'143px',height:'15px',
            background:'url("http://image.medigate.net/drgate/images/salary/hlink-logo2.png") no-repeat',backgroundSize:'143px'},
        '.txtinfo2 .txttit':{paddingRight:'15px',color:'rgba(0,0,0,0.5)',fontSize:'0.875rem'},
        '.txtinfo2 .txtsub':{color:'#1a1a1a',letterSpacing:'0'},
        '.hlink_ad .txtarea':{display:'inline-block',margin:'0 auto',minWidth:'290px'},
        '.hlink_ad .txtarea .txtinfo2':{position:'relative'},
        '.hlink_ad .txtarea .txtinfo2 .txttit':{position:'absolute',left:'0',textAlign:'left'},
        '.hlink_ad .txtarea .txtinfo2 .txtsub':{paddingLeft:'90px',textAlign:'left',display:'block',width:'100%'},
        '.hlink_ad .txtarea .txtinfo2 .txtsub:last:child':{paddingLeft:'0'},

        //연봉입력
        '.input_pay':{width:'100%',display:'flex',padding:'10px 6px 20px'},
        //'.input_pay .MuiPaper-elevation1':{background:'#fff',boxShadow:'0px 18px 15px -9px rgba(0,0,0,0.1)'},
        '.input_pay .MuiPaper-elevation1':{background:'#fff',boxShadow:'none',border:'10px solid #512da8'},
        //'.input_pay .MuiGrid-item':{padding:'20px',border:'1px dashed rgba(0,0,0,0.1)',borderRadius:'5px',background:'#e7eaf2'},
        '.input_pay .MuiButton-contained':{background:'#512da8',boxShadow:'0px 24px 20px -20px rgba(84, 115, 232, 0.5)',padding:'15px 20px',fontSize:'1rem',letterSpacing:'-1px'},
        '.input_pay .MuiButton-contained:hover':{background:'#1e1d6b'},
        '.input_pay .txtnotice':{maxWidth:'680px',margin:'0 auto',fontSize:'12px',color:'#ff6666',textAlign:'left',fontFamily:'Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',letterSpacing:'0'},
        '.inputwrap':{display:'flex',maxWidth:'680px',margin:'0 auto'},
        '.inputwrap .inputarea':{flexBasis:'100px'},
        '.inputarea.button .MuiButton-containedSizeLarge':{
            marginTop:'8px',padding:'0 40px',background:'#6a51cd',
            lineHeight:'92px',fontSize:'1.25rem',fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana'},
        '.inputarea.button .MuiButton-containedSizeLarge:hover':{background:'#512da8'},
        '.inputwrap .inputarea:nth-child(1)':{flexGrow:'7'},
        '.inputwrap .inputarea:nth-child(2)':{flexGrow:'1'},
        //광고3개
        '.Recjob_ad':{width:'100%',display:'flex',padding:'0 6px'},
        '.Recjob_ad .MuiGrid-grid-lg-4':{padding:'12px 6px'},
        '.Recjob_ad .MuiGrid-grid-lg-4:first-child':{padding:'12px 6px 12px 0'},
        '.Recjob_ad .MuiGrid-grid-lg-4:last-child':{padding:'12px 0 12px 6px'},
        '.Recjob_ad .MuiPaper-elevation1':{boxShadow:'none',background:'rgba(225, 230, 239, 0.7)',padding:'0 !important'},
        '.fixedHeightPaper .MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary':{padding:'20px',display:'block'},
        '.fixedHeightPaper .MuiTypography-body1':{fontFamily:'Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',letterSpacing:'-1px',color:'rgba(0,0,0,0.5)'},
        '.fixedHeightPaper .txtinfo1':{fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'1.25rem',fontWeight:'700',color:'rgba(0,0,0,1)',paddingBottom:'5px'},
        '.fixedHeightPaper .txtinfo2':{},
        '.fixedHeightPaper .txtinfo3':{},
        '.fixedHeightPaper .txtinfo4':{},
        '.workingarea':{display:'flex',padding:'6px 0 0'},
        '.workingarea .worktit':{flexBasis:'93px',fontSize:'0.875rem',lineHeight:'1.4'},
        '.workingarea .workinfo span':{display:'block',fontSize:'0.875rem',lineHeight:'1.4'},
        //탭관련
        '.tabswrap header.MuiAppBar-colorPrimary':{background:'none'},
        '.tabswrap .MuiBox-root':{padding:'25px 0'},
        '.tabs .MuiTabs-scroller':{padding:'0 10px 25px'},
        '.tabs .MuiTab-root':{
            margin:'0 5px',border:'1px dashed rgba(0,0,0,0.1)',borderRadius:'24px',background:'rgba(0,0,0,0.03)',
            letterSpacing:'-1px',fontSize:'1rem',fontWeight:'700',color:'rgba(0,0,0,0.5)'},
        '.tabs .MuiTab-root.Mui-selected':{border:'1px solid #512da8',background:'#6a51cd',boxShadow:'0px 25px 15px -20px rgba(84, 115, 232, 0.5)',color:'#fff'},
                                        //{border:'1px solid #69c9d6',background:'#69c9d6',boxShadow:'0px 25px 15px -20px rgba(84, 115, 232, 0.5)',color:'#fff'},
        '.tabs .MuiTabs-indicator':{display:'none'},
        '.tabs.type2 .MuiTab-root.Mui-selected':{},
        //근무환경
        '.workcond':{width:'100%'},
        '.workcond .MuiPaper-elevation1':{background:'rgba(255,255,255,0.5)',borderRadius:'5px',padding:'20px 5px 10px',height:'300px'},
        '.workcond .MuiTypography-alignLeft':{textAlign:'center',paddingBottom:'10px'},
        '.workcond .MuiTypography-subtitle1':{fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'1.25rem',color:'#1a1a1a',letterSpacing:'-1px'},
        '.workcond .payoption_table':{marginTop:'5px'},
        '.workcond .payoption_table .MuiTableCell-root':{borderBottom:'0'},
        '.workcond .payoption_table .title':{display:'block',paddingTop:'15px',fontSize:'0.875rem',letterSpacing:'-1px',color:'rgba(0,0,0,0.5)'},
        '.workcond .payoption_table .data':{display:'block',fontSize:'1.75rem',fontFamily:'Ubuntu,sans-serif',color:'#6a51cd'},

        '.nodata':{position:'relative'},
        '.Overview .nodata':{height:'273px'},
        '.Overview_time .nodata':{height:'222px'},
        '.nodata .MuiTypography-subtitle1':{margin:'-25px 30px 0',position:'absolute',top:'50%'},
        '.myinfo .nodata':{height:'0'},

        '.txtnotice':{fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',letterSpacing:'-1px'},
        '.txtnotice.type1':{fontSize:'1.25rem',color:'#fff',padding:'0 20px',position:'absolute',top:'50%',marginTop:'-35px',width:'100%'},
        '.txtnotice.type1 span':{display:'block'},
        '.txtnotice.type2':{fontSize:'1.75rem',color:'#b3b3b3'},

        //초빙수요
        '.u_spec':{width:'100%',display:'flex',padding:'10px 0 5px'},
        '.u_spec .MuiGrid-spacing-xs-3':{width:'100%',marginLeft:'0'},
        '.u_spec .MuiPaper-elevation1':{position:'relative',display:'block',background:'#fff',borderRadius:'5px',padding:'12px 15px',height:'280px'},
        '.u_spec .graph-tit':{paddingTop:'12px',marginBottom:'0',borderTopLeftRadius:'5px',borderTopRightRadius:'5px',background:'#fff'},
        //콤보박스1
        '.combo_1':{float:'right',margin:'10px 0 0'},
        '.combo_1 .MuiFormControl-root':{minWidth:'150px'},
        '.combo_1 .MuiFormControl-root:last-child':{margin:'8px 0 8px 8px'},
        '.combo_1 .MuiInputBase-root':{padding:'5px 15px',background:'#fff',borderRadius:'4px',boxShadow:'0px 13px 8px -8px rgba(84, 115, 232, 0.15), 0px 1px 1px -1px rgba(84, 115, 232, 0.5)'},
        '.combo_1 .MuiInput-underline:before':{display:'none'},
        '.combo_1 .MuiInput-underline:after':{display:'none'},
        '.combo_1 .MuiInputBase-input':{background:'#fff',color:'#31415f'},
        '.combo_1 .MuiFormLabel-root':{zIndex:'1',padding:'0 0 0 7px',letterSpacing:'-1px',lineHeight:'26px'},
        '.combo_2':{float:'right',textAlign:'right'},
        '.combo_2 .MuiInputBase-root':{padding:'5px 15px',background:'#fff',borderRadius:'4px',boxShadow:'0px 13px 8px -8px rgba(84, 115, 232, 0.15), 0px 1px 1px -1px rgba(84, 115, 232, 0.5)'},
        '.combo_2 .MuiInput-underline:before':{display:'none'},
        '.combo_2 .MuiInput-underline:after':{display:'none'},
        '.combo_2 .MuiInputBase-input':{background:'#fff',color:'#31415f'},
        '.combo_2 .MuiFormControl-root':{minWidth:'150px'},
        '.combo_2 .MuiFormLabel-root':{zIndex:'1',padding:'0 0 0 7px',letterSpacing:'-1px',lineHeight:'26px'},
        '.combo_1.single':{float:'none',margin:'0'},
        '.combo_1.single .MuiFormControl-root':{margin:'8px 0'},
        '.combo_1.single.notitle .MuiInputBase-root':{marginTop:'16px'},
        '.combo_3':{},
        '.combo_3 .MuiFormControl-root':{minWidth:'150px'},
        '.combo_3 .MuiInputBase-root':{padding:'5px 15px',background:'none',borderRadius:'4px',border:'1px solid rgba(0,0,0,0.1)'},
        '.combo_3 .MuiInput-underline:before':{display:'none'},
        '.combo_3 .MuiInput-underline:after':{display:'none'},
        '.combo_3 .MuiInputBase-input':{background:'none',color:'#31415f',textAlign:'left'},
        '.combo_4 .MuiInputBase-root':{fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'1.25rem',color:'#31415f',fontWeight:'bold',letterSpacing:'-1px'},
        '.combo_4 .MuiFormControl-root':{margin:'8px 0'},
        '.combo_4 .MuiSelect-select.MuiSelect-select':{paddingRight:'40px'},
        //버튼스타일
        '.btnarea .btn_cancel':{background:'rgba(0,0,0,0.1)',boxShadow:'none',color:'rgba(0,0,0,0.6)'},
        '.btnarea .btn_cancel:hover':{background:'rgba(0,0,0,0.2)'},
        '.btnarea .btn_confirm':{background:'#5f80f5'},
        '.btnarea .btn_confirm:hover':{background:'#193dbd'},

        '.tablewrap_1':{padding:'20px 0 0',margin:'0 auto'},
        '.tablewrap_1 .title_all':{fontFamily:'Noto Sans KR,Dotum,돋움,verdana',fontSize:'1.75rem',fontWeight:'800',textAlign:'center',color:'#31415f',borderBottom:'1px solid #1a1a1a',padding:'20px 0'},
        '.tablewrap_1 .count_all':{fontFamily:'Noto Sans KR,Dotum,돋움,verdana',fontSize:'1.75rem',fontWeight:'800',textAlign:'right',color:'#31415f',borderBottom:'1px solid #1a1a1a',padding:'20px 10px 20px 0 !important'},
        '.tablewrap_1 .title_sub':{fontSize:'1.15rem',textAlign:'center',color:'#31415f',borderBottom:'0',padding:'20px 0 0',letterSpacing:'-1px'},
        '.tablewrap_1 .count_sub':{fontSize:'1.15rem',textAlign:'right',color:'#31415f',borderBottom:'0',padding:'20px 10px 0 0 !important'},

        '.btn_more':{
            float:'right',display:'block',border:'1px dashed rgba(0,0,0,0.1)',borderRadius:'20px',background:'rgba(0,0,0,0.03)',
            letterSpacing:'-1px',fontSize:'1rem',fontWeight:'700',color:'rgba(0,0,0,0.5)',lineHeight:'40px',textAlign:'center'},
        '.btn_more:hover':{background:'rgba(0,0,0,0.07)',border:'1px dashed rgba(0,0,0,0.15)',transition:'all .2s'},

        'header .profile':{height:'40px !important',background:'rgba(0,0,0,0) !important'},

        '#footer':{position:'relative',bottom:'initial',left:'initial',marginTop:'60px'},

        '.blockwrap':{position:'relative',display:'table',width:'100%',height:'100vh',background:'linear-gradient(90deg,#2e3742,#44525d)'},
        '.blockwrap .wraparea':{height:'100%',overflowY:'auto',overflowX:'hidden',display:'table-cell',verticalAlign:'middle',background:'none'},
        '.blockwrap .wraparea .blockarea':{display:'flex',maxWidth:'800px',height:'auto',margin:'0 auto'},
        '.blockwrap .wraparea .blockarea .blockarea-cont':{flexBasis:'auto'},
        '.blockarea-cont':{flexBasis:'auto',fontFamily:'Noto Sans KR,Dotum,돋움,verdana',textAlign:'center'},
        '.blockarea-cont .subtitle':{fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'1.25rem',color:'#fff',letterSpacing:'-1px',lineHeight:'1.4',paddingTop:'180px',
            background:'url("http://image.medigate.net/drgate/images/main/title_explorer.png") center top / 160px no-repeat'},
        '.blockarea-cont .linkcont':{background:'#fff',padding:'40px 20px',margin:'20px 0 20px'},
        '.blockarea-cont .linkcont .title':{fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'1.75rem',padding:'20px 0'},
        '.blockarea-cont .linkcont .linkarea':{display:'flex',justifyContent:'center'},
        '.blockarea-cont .linkcont .linkarea .imageicon':{paddingTop:'140px',display:'block',padding:'20px 0',margin:'0 10px',textAlign:'center',width:'140px',
            border:'1px solid rgba(0,0,0,0.0)',borderRadius:'5px'},
        '.blockarea-cont .linkcont .linkarea .link_chrome':{background:'url("http://image.medigate.net/drgate/images/main/icon_chrome.png") center 20px / 100px no-repeat'},
        '.blockarea-cont .linkcont .linkarea .link_edge':{background:'url("http://image.medigate.net/drgate/images/main/icon_edge.png") center 20px / 100px no-repeat'},
        '.blockarea-cont .linkcont .linkarea .link_whale':{background:'url("http://image.medigate.net/drgate/images/main/icon_whale.png") center 20px / 100px no-repeat'},
        '.blockarea-cont .linkcont .linkarea .link_firefox':{background:'url("http://image.medigate.net/drgate/images/main/icon_firefox.png") center 20px / 100px no-repeat'},
        '.blockarea-cont .linkcont .linkarea .imageicon:hover':{border:'1px solid rgba(0,0,0,0.1)',transition:'all .2s',textDecoration:'none'},
        '.blockarea-cont .btnarea':{color:'rgba(255,255,255,0.6)',fontSize:'13px',textAlign:'center',lineHeight:'30px',height:'30px',borderRadius:'15px'},
        '.blockarea-cont .btnarea:hover':{background:'rgba(0,0,0,0.2)',transition:'all .2s',padding:'0 10px'},
        '.lognotice .MuiTypography-h6.MuiTypography-gutterBottom':{fontSize:'2rem',color:'#1a1a1a',letterSpacing:'-1px',marginTop:'20%'},
        '.lognotice .MuiPaper-elevation1':{justifyContent:'unset'},
        '.lognotice .MuiButton-containedPrimary':{backgroundColor:'#6a51cd'},

        '.btnguide':{border:'1px solid #7d69d4',background:'#fff',borderRadius:'15px',padding:'0 15px',marginRight:'40px'},
        '.btnguide .MuiButton-label':{lineHeight:'26px',fontSize:'13px',color:'#6a51cd',letterSpacing:'-1px'},
        '.btnguide:hover':{border:'1px solid #7d69d4',background:'#7d69d4'},
        '.btnguide:hover .MuiButton-label':{color:'#3b1c84'},

        '.appguide':{padding:'40px 20px'},
        '.appguide .txttit':{fontFamily:'Noto Sans KR,Nanum Gothic,나눔고딕,Malgun Gothic,맑은 고딕,Dotum,돋움,verdana,arial,sans-serif',fontSize:'26px',fontWeight:'600',letterSpacing:'-1px',padding:'40px 40px 0'},
        '.appguide-wrap':{padding:'20px'},
        '.appguide-wrap .txtarea.main':{fontFamily:'Nanum Gothic,나눔고딕,Malgun Gothic,맑은 고딕,Dotum,돋움,verdana,arial,sans-serif',fontSize:'15px',lineHeight:'1.5',margin:'10px 0',color:'#1a1a1a'},
        '.appguide-wrap .txtarea.sub':{fontSize:'13px',color:'rgba(0,0,0,0.6)',background:'rgba(0,0,0,0.05)',padding:'20px',margin:'0 0 20px'},
        '.appguide-wrap .txtarea.sub1':{fontSize:'13px',color:'rgba(0,0,0,0.6)',background:'rgba(0,0,0,0.05)',padding:'20px',fontWeight:'400'},
        '.appguide-wrap .txtarea.sub2':{fontSize:'13px',color:'rgba(0,0,0,0.6)',background:'rgba(0,0,0,0.05)',padding:'5px 20px 20px 20px'},

        '@media (max-width:599px)':{
            '.MuiTypography-h5':{fontSize:'1.75rem'},
            '.inputwrap':{display:'block'},
            '.inputarea.button .MuiButton-containedSizeLarge':{marginTop:'8px',padding:'10px 40px',lineHeight:'1.4',fontSize:'1.25rem'},
            '.Recjob_ad':{display:'block'},
            '.Recjob_ad .MuiGrid-grid-lg-4':{padding:'6px 0'},
            '.Recjob_ad .MuiGrid-grid-lg-4:first-child':{padding:'6px 0'},
            '.Recjob_ad .MuiGrid-grid-lg-4:last-child':{padding:'6px 0'},
            '.fixedHeightPaper .MuiTypography-body1':{textAlign:'center'},
            '.workingarea .worktit':{textAlign:'right'},
            '.workingarea .workinfo span':{textAlign:'left',paddingLeft:'10px'},
            '.Overview_time':{display:'block'},
            '.input_pay':{padding:'10px 0 20px'},
            '.input_pay .MuiPaper-elevation1':{padding:'30px 15px !important'},
            '.hlink_ad .MuiPaper-elevation1':{height:'auto !important'},
            '.hlink_ad .hr_infoimg':{float:'none',width:'100%',height:'120px'},
            '.hlink_ad .hr_infotxt':{float:'none',width:'100%',padding:'20px 20px 50px'},
            '.graph_trend .MuiPaper-elevation1':{height:'auto'},
            '.u_spec .combo_3.type2':{width:'100%',background:'#fff'},
            '.u_spec .combo_3.type2 .MuiTypography-body1':{textAlign:'center'},
            '.u_spec .graph-tit':{padding:'25px 0 5px !important'},
            '.tablewrap_1':{width:'100% !important'},
            '.nodata':{height:'auto',minHeight:'60px'},
            '.myinfo .nodata':{height:'0',minHeight:'0'},
            '.combo_4':{width:'100%'},
            '.combo_4 .MuiInputBase-root':{width:'100%'},
            '.combo_4 .MuiFormControl-root':{width:'100% !important'},
            '.pagegraph .MuiGrid-container':{padding:'0'},
            '.MuiContainer-root':{padding:'32px 15px !important'},
            '.MuiGrid-spacing-xs-3 > .MuiGrid-item':{padding:'12px 0 !important'},
            '.txtnotice.type2':{fontSize:'1rem',color:'#999'},
            '.MuiGrid-spacing-xs-3':{width:'100% !important',margin:'0 !important'},
            '.btn_more':{width:'100%'},
            '.input_pay .MuiButton-contained .MuiButton-label':{display:'block'},
            '.pagegraph .MuiGrid-item':{position:'relative'},
            '.pagegraph .ico_scroll':{position:'absolute',top:'30px',right:'10px',width:'40px',height:'40px',
                background:'url("http://image.medigate.net/drgate/images/graph/ico_scroll.png") center center / 40px no-repeat'},
            '.pagegraph .MuiPaper-elevation1':{overflowX:'scroll'},
            'header .MuiToolbar-root.MuiToolbar-regular .MuiIconButton-edgeStart':{marginRight:'0'},
            'nav .MuiListItem-root .MuiListItemText-root .MuiTypography-body1':{fontFamily:'Noto Sans KR,Dotum,돋움,verdana',fontSize:'1rem',color:'#31415f'},
            '.u_spec .MuiPaper-elevation1':{padding:'12px 15px 30px',height:'auto'},
            '.hlink_ad .hlink_logo':{bottom:'10px',left:'50%',marginLeft:'-72px'},
            '.hlink_ad .txtinfo1 .title':{display:'inline-block',minWidth:'100%',textAlign:'center'},
            '.btnguide':{display:'none'},
            '.fixedHeightPaper .MuiTypography-body1 .notice.none':{padding:'30px 0'},
            '.fixedHeightPaper .MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary':{padding:'20px 10px'},
        },
        '@media (min-width:600px)':{
            '.u_spec .MuiGrid-spacing-xs-3 > .MuiGrid-item':{padding:'12px 5px 12px 0'},
            '.u_spec .MuiGrid-spacing-xs-3 > .MuiGrid-item:last-child':{padding:'12px 0 12px 5px'},
            '.u_spec .MuiGrid-spacing-xs-3 > .MuiGrid-item:first-child .MuiPaper-elevation1':{height:'100%',justifyContent:'unset'},
            '.u_spec .MuiTypography-h6':{textAlign:'left',padding:'15px 0 15px 10px'},
            '.u_spec .combo_3':{position:'absolute',marginTop:'0'},
            '.u_spec .combo_3.type1':{top:'0',right:'15px'},
            '.u_spec .combo_3.type2':{top:'15px',right:'15px'},
            '.MuiSnackbar-anchorOriginBottomCenter':{top:'0'},
            '.MuiAlert-filledError':{backgroundColor:'#512da8'},
            '.tablewrap_1':{width:'80% !important'},
            '.btn_more':{width:'33%',margin:'0 5px'},
            '.Overview .myinfo .MuiPaper-elevation1':{height:'197px !important'},
            '.Overview_time .myinfo .MuiPaper-elevation1':{/*height:'207px !important'*/},
            '.hlink_ad .hlink_logo':{bottom:'15px',right:'15px'},
            '.Recjob_ad .MuiPaper-elevation1':{height:'155px !important'},
            '.fixedHeightPaper .MuiTypography-body1 .notice.none':{padding:'60px 0 0'},
            '.hlink_ad .txtarea .txtinfo2':{width:'330px'},
        },


    },
})(() => null);

export default SalaryGlobalCss;
