import React, {useEffect, useState, useContext} from 'react';
import {MapContext} from "./MapContainer";
import MuiSelect from "./MuiSelect";

import CssBaseline from "@material-ui/core/CssBaseline";

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CheckIcon from '@material-ui/icons/Check';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const withPopper = (WrappedComponent) => (props,handleAction=(()=>{}), closeFunction=(()=>{}),) => {
    // //console.log("with popper. props??", props,"handleClickAway func??",handleClickAway);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const [open, setOpen] = React.useState(false);
    // const [placement, setPlacement] = React.useState();

    const { anchorEl, open, placement } = props;
    const { content, styles } = props;
    //--//console.log("@with popper handleAction??", handleAction);
    // //console.log("@with popper style??", styles);
/*

    const handleClick = newPlacement => event => {
        //console.log("handleSearchClick",placement ,newPlacement ,event,open);
        setAnchorEl(event.currentTarget);
        setOpen(prev => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    const togglePopper = newPlacement => event => {
        //console.log("handleSearchClick",placement ,newPlacement ,event,open);
        setAnchorEl(event.currentTarget);
        setOpen(prev => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
*/

    return(
        // <ClickAwayListener onClickAway={handleClickAway}>
            <Popper style={{ zIndex:'3'}} open={ open } anchorEl={anchorEl} placement={placement}>
                    {/*<WrappedComponent content={ content } styles={ styles } onAction={ handleAction } close={closeFunction}/>*/}
                    <WrappedComponent onAction={ handleAction } close={closeFunction} {...props} />
            </Popper>
        // </ClickAwayListener>
    );
};

export default withPopper;