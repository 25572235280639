import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Skeleton from "@material-ui/lab/Skeleton";

import SStackedBarChart from './S_StackedBarChart';
import SStackedBarChart3 from './S_StackedBarChart3';

class S_LongStackedBarGraph extends Component {
    render() {
        const { data=[], fixedHeightPaper, loopCount, legendName, filterType, loading } = this.props;
        let spcArr = [];
        let locArr = [];
        let orgArr = [];

        let deleteIndex = 1;
        if(filterType === "red_work"){
            deleteIndex = 4;
        }else{
            deleteIndex = 5;
        }

        data.forEach((item, i) => {
            item.forEach((innerItem) => {
                let tmpArray = _.toArray(innerItem)
                if(i === 0){
                    spcArr.push(_.dropRight(tmpArray, deleteIndex));
                }else if(i === 1){
                    locArr.push(_.dropRight(tmpArray, deleteIndex));
                }else if(i === 2){
                    orgArr.push(_.dropRight(tmpArray, deleteIndex));
                }
            });
        });

        if(filterType !== "red_work"){
            spcArr.unshift(["", "예", { role: "annotation" }, "아니오"]);
            locArr.unshift(["", "예", { role: "annotation" }, "아니오"]);
            orgArr.unshift(["", "예", { role: "annotation" }, "아니오"]);
        }else{
            spcArr.unshift(["", "휴무", { role: "annotation" }, "순번근무", "근무"]);
            locArr.unshift(["", "휴무", { role: "annotation" }, "순번근무", "근무"]);
            orgArr.unshift(["", "휴무", { role: "annotation" }, "순번근무", "근무"]);
        }
         // //console.log("spcArr", spcArr);
         // //console.log("locArr", locArr);
         // //console.log("orgArr", orgArr);

        return (
            <Fragment>
                {
                    Array.from(new Array(loopCount)).map((item, index) => (
                        <Grid item xs={12}>
                            <div className="ico_scroll"></div>
                            <Paper className={fixedHeightPaper}>
                                {
                                    !data.length > 0 || loading ?
                                    <Fragment>
                                        <Skeleton variant="rect" width="100%" height={240} />
                                    </Fragment>
                                    :
                                    loopCount !== 1 ?
                                        index === 0 ?
                                            filterType !== "red_work" ?
                                            <SStackedBarChart
                                                data={spcArr}
                                                fill="#1e88e5"
                                                //name="전문과별"
                                                custom={true}
                                            />
                                            :
                                            <SStackedBarChart3
                                                data={spcArr}
                                                fill="#1e88e5"
                                                //name="전문과별"
                                                custom={true}
                                            />
                                        :
                                        index === 1 ?
                                            filterType !== "red_work" ?
                                            <SStackedBarChart
                                                data={locArr}
                                                fill="#43a047"
                                                //name="지역별"
                                            />
                                            :
                                            <SStackedBarChart3
                                                data={locArr}
                                                fill="#43a047"
                                                //name="지역별"
                                            />
                                        :
                                            filterType !== "red_work" ?
                                            <SStackedBarChart
                                                data={orgArr}
                                                //name="병원타입별"
                                                fill="#3f51b5"
                                            />
                                            :
                                            <SStackedBarChart3
                                                data={orgArr}
                                                //name="병원타입별"
                                                fill="#3f51b5"
                                            />
                                    :
                                    filterType !== "red_work" ?
                                    <SStackedBarChart
                                        data={data}
                                        name={legendName}
                                        fill="#434343"
                                    />
                                    :
                                    <SStackedBarChart3
                                        data={data}
                                        name={legendName}
                                        fill="#434343"
                                    />
                                }

                            </Paper>
                        </Grid>
                    ))
                }
            </Fragment>
        )
    }
}

export default S_LongStackedBarGraph;