import React, {useState, useEffect, Fragment} from 'react';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import useStyles from "../style/common";
import Draggable from "react-draggable";
import DraggableCore from "react-draggable";
import InfoIcon from "@material-ui/icons/Info";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Drawer from '@material-ui/core/Drawer';
import StatClinicOpenClose from './charts/StatClinicOpenClose';
import DetailHospitalInfoMap from './DetailHospitalInfoMap';
import StatHosSpc from './charts/StatHosSpc';
import {useStore} from "../../stores/StoreProvider";
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import StatSgis from './charts/StatSgis';
import StatHira from "./charts/StatHira";
import {observer} from "mobx-react";
import Typography from "@material-ui/core/Typography";

const hospitalInfo = (props) => {
// const hospitalInfo = observer( (props) => {
    return (
        <Grid container direction={"column"}>
            <DetailHospitalInfoMap mapObj = {props.includedMap}/>
            <Grid item>
                <StatClinicOpenClose hosCodeArr={props.hosCodeArr} polygon={props.polygon}/>
            </Grid>
            <Grid item>
                <StatHosSpc hosCodeArr={props.hosCodeArr}/>
            </Grid>
        </Grid>
    )
// });
};

const sgisInfo = (props) => {
    return (
        <StatSgis nearDongList={props.nearDongList}/>
    )
};

const hiraInfo = (props) => {
    return (
        <StatHira locationInfo={props.locationInfo} setDate={props.setDate}/>
    )
};

const DetailTabContents = observer((props) => {
    const classes = useStyles();
    const store = useStore().LocationStore;
    const {tabName} = props;
    const [hiraDate, setHiraDate] = React.useState('');     //심평원 기준일

    const callbackHiraDate = (date) => {
        setHiraDate(date);
    }
    const dataCompare = store.drawFigure1 != null && store.drawFigure2 != null; //다중분석입지 플래그

    return (
        <Grid container xs={12}>
            { (dataCompare && tabName === "hiraInfo") &&
            <Grid item xs={12} className="datawrap">
                <Grid container direction={"column"} className="hiraInfo" style={{margin:'0px'}}>
                    <Grid item xs={12} className="caution">
                        <Typography className="txtinfo1">
                            주의!
                        </Typography>
                        <Typography className="txtinfo2">
                            이 페이지는 건강보험심사평가원에서 수집한 ＇의원급＇ 급여 처방 데이터 중, 매월 처방액 기준으로 시군구별 & 진료과별 상위 50개 질환만을 추출해 구성했습니다.<br/>
                            이 데이터는 완벽한 통계가 아닌, 지역별 주요 상위 질환을 비교하기 위한 용도입니다.  일부 진료과에서만 처방하는 빈도가 아주 낮은 질환의 경우, 집계 때 포함되지 않거나 적게 포함될 가능성이 있어 해석에 주의해야 합니다.<br/>
                            (기준년월 : {hiraDate} / 심평원 제공 최신 데이터)
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            }
            <Grid item xs={dataCompare ? 6 : 9} className="datawrap">
                {tabName === "hospitalInfo" &&
                    hospitalInfo({
                        hosCodeArr: _.map(store.hospitalMarkerList, 'hos_code'),
                        polygon: store.figureBound,
                        includedMap: store.figureMapContainer1,
                    })
                }
                {tabName === "sgisInfo" &&
                    sgisInfo({
                        nearDongList: store.drawFigure1 ? store.figureNearDongList : store.nearDongList
                    })
                }
                {tabName === "hiraInfo" &&
                    hiraInfo({
                        locationInfo: store.drawFigure1 ? store.figureLocationInfo : store.locationInfo,
                        tabNo: 1
                    })
                }
            </Grid>

            { dataCompare &&
            <Grid item xs={6} className="datawrap">
                {tabName === "hospitalInfo" &&
                    hospitalInfo({
                        hosCodeArr: _.map(store.hospitalMarkerList2, 'hos_code'),
                        polygon: store.figureBound2,
                        includedMap: store.figureMapContainer2,
                    })
                }
                {tabName === "sgisInfo" &&
                    sgisInfo({
                        nearDongList: store.figureNearDongList2
                    })
                }
                { tabName === "hiraInfo" &&
                    hiraInfo({
                        locationInfo: store.figureLocationInfo2,
                        setDate: callbackHiraDate
                    })
                }
            </Grid>
            }
        </Grid>
    );
})

export default DetailTabContents;