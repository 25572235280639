import React from 'react';
import mom from 'moment';
import Moment from 'react-moment';

export const TimeStamps = ({date}) => {
    return (
		<span>
			{
				(mom(new Date()).diff(mom(date), 'year')) > 0 ?
					<Moment locale="ko" format="YYYY년 MM월 DD일">{date}</Moment>
				:
					(mom(new Date()).diff(mom(date), 'days')) > 0 ?
						(mom(new Date()).diff(mom(date), 'days')) < 7 ?
							(mom(new Date()).diff(mom(date), 'days') + 1)+'일전'
						:
							<Moment locale="ko" format="MM월 DD일">{date}</Moment>
						:
					(mom(new Date()).diff(mom(date))) > 300000 ?
						<Moment locale="ko" fromNow>{date}</Moment>
				:
					"방금"
			}
		</span>
    );
};

export default TimeStamps;
