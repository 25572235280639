import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import _ from 'lodash';

const secretKey = 'to_the_best'
//console.log('host', window.location.hostname);

const fullHost = window.location.hostname;
var cookieDomain = null;
if(fullHost.indexOf('medigate.net') !== -1){
	cookieDomain = '.medigate.net'
}
else{
	var hostArr = fullHost.split('.');
	if (hostArr.length > 2) {
		cookieDomain = `.${hostArr[hostArr.length-2]}.${hostArr[hostArr.length-1]}`;
	}
	else{
		cookieDomain = fullHost
	}
}
//console.log('cookieDomain', cookieDomain);

export default {
//module.exports = {
	USER_ROLES: ['DOCTOR', 'STAFF', 'ADMIN'],
	getSecretKey: function () {
		return secretKey;
	},
	getToken: function () {
		//return localStorage.getItem('rememerme') === 'true' ? localStorage.getItem('token') : sessionStorage.getItem('token');
		return localStorage.getItem('token') || sessionStorage.getItem('token');
	},
	getUserInfo: function () {
		//let user_encrypted = localStorage.getItem('rememerme') === 'true' ? localStorage.getItem('user') : sessionStorage.getItem('user');
		let user_encrypted = localStorage.getItem('user') || sessionStorage.getItem('user')
		if (!user_encrypted) {
			return null;
		}
		let user_decrypted = this.decryptJson(user_encrypted);
		//{email:"good@test.com", userSno:161947, enabled:true, id:"hsjung", name:"정현성", nick:"청담의", pic:"/upload/2018/06/1529382735862_72467.jpg", profileDate:"2018-06-18", profileStep:9, roles:["DOCTOR"], sex:"M", userKind:"UKD001", userType:"DOCTOR"}
		//console.log("UserInfo", user_decrypted);

		return user_decrypted
	},
	setUserInfo: function (key, value) {
		let user = this.getUserInfo();
		user[key] = value;
		let user_encrypted = this.encryptJson(user);

		localStorage.setItem('user', user_encrypted);
		//console.log("setUserInfo", key, value, this.getUserInfo())
	},
	getUserRoles: function () {
		return this.getUserInfo().u_roles;
	},
	hasRole: function (roleName) {
		let roles = this.getUserRoles();
		if (_.isEmpty(roles)) {
			return false;
		}
		let rtn = false;
		roles.map(function (role) {
			if (role === roleName) {
				rtn = true;
				return false; //break
			}
			return true
		})
		return rtn;
	},
	setCookie: function (cookieName, cookieValue) {
		this.setCookieDays(cookieName, cookieValue, 999999);
	},
	setCookieDays: function (cookieName, cookieValue, days) {
		Cookies.set(cookieName, cookieValue, { domain: cookieDomain, expires: days })
	},
	getCookieValue: function (cookieName) {
		let cookieValue = Cookies.get(cookieName);
		if (cookieValue && cookieValue.indexOf('=') !== -1) {
			cookieValue = cookieValue.replace(new RegExp('=', 'g'), '%3D')
		}
		//console.log('cookie: ', cookieValue)
		return cookieValue;
	},
	encryptJson: function (jsonObj) {
		var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(jsonObj), secretKey);
		return ciphertext.toString()
	},
	decryptJson: function (ciphertext) {
		try {
			if (ciphertext) {
				var bytes = CryptoJS.AES.decrypt(ciphertext.toString(), secretKey);
				var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
				//console.log('decryptedData', decryptedData);
				return decryptedData
			}
			else {
				return null
			}
		}
		catch (e) {
			//console.log("decryptJson error: ", e)
		}
	},
	signout: function (history) {
		this.initAuthStorage();
		window.location.reload();
	},
  getConvLoginUser: function (user) {
    if(!user) return null;
    const convUser = {
      admin: user.uKind === 'UKD000',
      age: user.uAge,
      email: user.uEmail,
      enabled: true,
      hospitalType: user.uHtpName,
      id: user.uId,
      loginDate: null,
      name: user.uName,
      nick: user.uNick,
      pic: user.uPic,
      profileDate: null,
      profileStep: 9,
      sex: user.uSex,
      sno: user.uSno,
      spc: user.uSpcName,
      spcCode: user.uSpcCode,
      spcIm: user.uSpcDtlName,
      spcImCode: user.uSpcDtlCode,
      u_roles: user.roles,
      userKind: user.uKind,
      userType: user.uKind === 'UKD000' ? 'ADMIN' : 'DOCTOR',
      wtp: user.uWtpName,
    };
    //console.log('convUser', convUser);
    return convUser;
  },
  getConvProfileUser: function (user) {
    if(!user) return null;
    const convUser = {
      u_id: user.uId,
      u_sno: user.uSno,
      u_kind: user.uKind,
      u_name: user.uName,
      u_pic: user.uPic,
      u_sex: user.uSex,
      u_age: user.uAge,
      u_birthday: user.uBirthday,
      u_email: user.uEmail,
      u_hospital_group_code: user.uHtpCode,
      u_spc_code: user.uSpcCode,
      u_profile_spc_name: user.uSpcName,
      u_wtp_code: user.uWtpCode,
      u_wtp_name: user.uWtpName,
      u_zip_zone: user.uLocCode,
      u_office: user.uOffice,
      u_office_addr: user.uOfficeAddr,
      u_spc_dtl_name: user.uSpcDtlName,
      u_spc_dtl_code: user.uSpcDtlCode,
    };
    //console.log('convUser', convUser);
    return convUser;
  },
	saveAuthInfoToStorage: function (rememberme, authInfo) {
		this.initAuthStorage();
    const { accessToken: token, refreshToken, user: orgUser, pkg } = authInfo;
    const user = this.getConvLoginUser(orgUser);
		let user_encrypted = this.encryptJson(user);

		//console.log("saveAuthInfoToStorage user_encrypted", user_encrypted);
		localStorage.setItem('rememerme', rememberme);
		if (rememberme) {
			localStorage.setItem('token', token);
			localStorage.setItem('user', user_encrypted);
		}
		else {
			sessionStorage.setItem('token', token);
			sessionStorage.setItem('user', user_encrypted);
		}

		//save token to cookie
		Cookies.remove('token', { domain: cookieDomain });
		Cookies.remove('MeDiPkg', { domain: cookieDomain });
		Cookies.remove('MeDiUserNick', { domain: cookieDomain });
		Cookies.set('token', token, { domain: cookieDomain, expires: 999999 })
		Cookies.withConverter({
			write: function (value) {
				return value
			}
		}).set('MeDiPkg', pkg, { domain: cookieDomain, expires: 999999 });

		return {
			token: token,
			user: user
		}
	},
	initAuthStorage: function () {
		localStorage.removeItem('user');
		localStorage.removeItem('token');
		sessionStorage.removeItem('user');
		sessionStorage.removeItem('token');

		Cookies.remove('token', { domain: cookieDomain });
		Cookies.remove('MeDiPkg', { domain: cookieDomain });
		Cookies.remove('MeDiUserNick', { domain: cookieDomain });
	}
}
