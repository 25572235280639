import React, {useState, useEffect, Fragment} from 'react';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import useStyles from "../style/common";
import Draggable from "react-draggable";
import DraggableCore from "react-draggable";
import InfoIcon from "@material-ui/icons/Info";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Drawer from '@material-ui/core/Drawer';
import StatClinicOpenClose from './charts/StatClinicOpenClose';
import StatHosSpc from './charts/StatHosSpc';
import {useStore} from "../../stores/StoreProvider";
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import StatSgis from './charts/StatSgis';
import StatHira from "./charts/StatHira";
import {observer} from "mobx-react";


const DetailHospitalInfoMap = observer((props) => {
    const classes = useStyles();
    const store = useStore().LocationStore;
    //const isDisplayMap = store.drawFigure1 != null ; //맵 출력 플래그
    const isDisplayMap = true;
    const {mapObj} = props;
    const {id} = mapObj.props;
    const kakao = store.kakao;
    let localKakao = null;
    let mapBox = null;
    const [localKakaoMap, setLocalKakaoMap] = useState(null);
    const [localDrawingManager, setLocalDrawingManager] = useState(null);
    const [mapMarkerList, setMapMarkerList] = useState([]);
    const [mapFigure, setMapFigure] = useState(null);

    useEffect(()=>{
        if(isDisplayMap) {
            window.kakao.maps.load(() => {
                localKakao = window.kakao;
                let isDefaultMap = store.drawFigure1 === null;
                let mapOption = {
                    center: (isDefaultMap ? new kakao.maps.LatLng(store.centerPosArr[0], store.centerPosArr[1]) : new kakao.maps.LatLng(37.566431, 126.978042)),
                    level: (isDefaultMap ? store.zoomLevel : 1),
                    draggable: false,
                    scrollWheel: false,
                    disableDoubleClick: false,
                    disableDoubleClickZoom: false,

                }
                let mapConatiner = document.getElementById(id);
                setLocalKakaoMap( new kakao.maps.Map(mapConatiner, mapOption) )
            });
        }
    }, isDisplayMap)

    useEffect(()=>{
        if(localKakaoMap != null){
            if(store.drawFigure1 === null) { //default
                putMarkerList([...store.hospitalMarkerList])
            }
            else{
                let drawManagerOption = {
                    map: localKakaoMap, // drawing manager로 그리기 요소를 그릴 map 객체
                    drawingMode: [kakao.maps.drawing.OverlayType.RECTANGLE, kakao.maps.drawing.OverlayType.CIRCLE, kakao.maps.drawing.OverlayType.POLYGON],
                    rectangleOptions: {strokeColor: '#21a82d', /* 외곽선 색*/ fillColor: '#21a82d', /* 채우기 색*/ fillOpacity: 0.5 /* 채우기색 투명도*/},
                    circleOptions: {strokeColor: '#39f', fillColor: '#3399ff', fillOpacity: 0.5},
                    polygonOptions: {/* draggable: true,*/ removable: false,/* editable: true,*/ strokeColor: '#c88e1a', fillColor: '#c88e1a', fillOpacity: 0.5, hintStrokeStyle: 'dash', hintStrokeOpacity: 0.5}
                };
                setLocalDrawingManager(new kakao.maps.drawing.DrawingManager(drawManagerOption));

                let isSecondMap = (id == "fMapContainer2") ? true : false;
                if(!isSecondMap){
                    putMarkerList([...store.hospitalMarkerList]);
                } else {
                    putMarkerList([...store.hospitalMarkerList2]);
                }
            }
        }
    },[localKakaoMap, store.hospitalMarkerList, store.hospitalMarkerList2])

    useEffect(()=>{
        if(localDrawingManager != null){
            let isSecondMap = (id == "fMapContainer2") ? true : false;
            if(!isSecondMap){
                putFigure(store.drawFigure1 );
            } else {
                putFigure(store.drawFigure2);
            }
        }
    },[localDrawingManager])

    const putMarkerList = (markerList) => {
        const _normalMarkerObj = {
            url:'http://image.medigate.net/drgate/images/location/marker2.png',
            width:26,
            height:33,
        };

        for (let i = 0; i < markerList.length; i++) {
            let {hos_name: title, pos_x: lng, pos_y: lat} = markerList[i]; //마커리스트 항목에서 필요한 요소들 추림
            let markerImg = new kakao.maps.MarkerImage(_normalMarkerObj.url, new kakao.maps.Size(_normalMarkerObj.width,_normalMarkerObj.height));

            let marker = new kakao.maps.Marker({
                map: localKakaoMap, //마커달 카카오맵
                position: new kakao.maps.LatLng(lat, lng),//마커 위경도
                image: markerImg,
                title: title, //마커이름
            });
            setMapMarkerList(oldArray => [...oldArray, marker]);
        }
    }

    const putFigure = (figure) => {
        let figureObj = figure[0];
        let figureType = figure[1];
        let boundList = new kakao.maps.LatLngBounds();
        if(figureType == "rectangle"){
            // console.log("detailhosmap rect", figureObj.getBounds());
            let boundsKeyArr = Object.keys( figureObj.getBounds()); //바운드 object에서 키 리스트 가져옴

            let sw = new kakao.maps.LatLng(figureObj.getBounds()[boundsKeyArr[1]], figureObj.getBounds()[boundsKeyArr[0]]);// 남서 southwest 위도,경도순. bounds[1] bounds[0]
            let ne = new kakao.maps.LatLng(figureObj.getBounds()[boundsKeyArr[3]], figureObj.getBounds()[boundsKeyArr[2]]);// 북동 northeast 위도,경도순. bounds[3] bounds[2]
            boundList.extend(sw) // 남서 southwest 위도,경도순. bounds[1] bounds[0]
            boundList.extend(ne) // 북동 northeast 위도,경도순. bounds[3] bounds[2]
            localDrawingManager.put(kakao.maps.drawing.OverlayType.RECTANGLE, boundList);
        } else if(figureType == "polygon"){
            figureObj.getPath().map((coord, index)=>{
                let boundsKeyArr = Object.keys( coord.toLatLng() ); //바운드 object에서 키 리스트 가져옴
                // console.log("detailhosmap polygon", coord.toLatLng(),boundsKeyArr);
                // console.log("detailhosmap polygon2", coord.toLatLng()[boundsKeyArr[0]], coord.toLatLng()[boundsKeyArr[1]]);
                boundList.extend(new kakao.maps.LatLng(coord.toLatLng()[boundsKeyArr[1]], coord.toLatLng()[boundsKeyArr[0]]));//Ha,Ja: lat-위도, Ga,Ia: lng-경도
            });
            localDrawingManager.put(kakao.maps.drawing.OverlayType.POLYGON, figureObj.getPath());
        } else {
            // console.log("detailhosmap circle", figureObj.getBounds());
            let boundsKeyArr = Object.keys( figureObj.getBounds()); //바운드 object에서 키 리스트 가져옴

            let sw = new kakao.maps.LatLng(figureObj.getBounds()[boundsKeyArr[1]], figureObj.getBounds()[boundsKeyArr[0]]);// 남서 southwest 위도,경도순. bounds[1] bounds[0]
            let ne = new kakao.maps.LatLng(figureObj.getBounds()[boundsKeyArr[3]], figureObj.getBounds()[boundsKeyArr[2]]);// 북동 northeast 위도,경도순. bounds[3] bounds[2]
            boundList.extend(sw) // 남서 southwest 위도,경도순. bounds[1] bounds[0]
            boundList.extend(ne) // 북동 northeast 위도,경도순. bounds[3] bounds[2]
            localDrawingManager.put(kakao.maps.drawing.OverlayType.CIRCLE, figureObj.getPosition(), figureObj.getRadius());
        }
        localKakaoMap.setBounds(boundList);
    }

    return (
        <Grid item xs={12}>
            {isDisplayMap &&
            <div id={id} style={{height: "35vh"}}></div>
            }
        </Grid>
    );
})

export default DetailHospitalInfoMap;