import React, {Component, Fragment} from 'react';

import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import CommonFunction from "../../common/CommonFunction";
import { HTP_CODE } from "./UserConstants";
import STitle from "./S_Title";

class S_BoxDemandContent extends Component {
    render() {
        const { data={}, orgName, locName, spcName, title, data_standard } = this.props;
        return (
            <Fragment>
                <STitle align="center">{title}</STitle>
                <Table size="small" className="tablewrap_1">
                    <TableBody>
                        <TableRow>
                            <TableCell padding="none" className="title_all">전체</TableCell>
                            <TableCell padding="none" className="count_all">
                                {data.total_cnt && data.total_cnt.toLocaleString()} {data_standard === 'kosis' ? '명' : '개'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding="none" className="title_sub">{spcName}</TableCell>
                            <TableCell padding="none" className="count_sub">
                                {data.spc_cnt && data.spc_cnt.toLocaleString()} {data_standard === 'kosis' ? '명' : '개'}
                            </TableCell>
                        </TableRow>
                        { locName &&
                            <TableRow>
                                <TableCell padding="none" className="title_sub">{locName}</TableCell>
                                <TableCell padding="none" className="count_sub">
                                    {data.loc_cnt && data.loc_cnt.toLocaleString()} {data_standard === 'kosis' ? '명' : '개'}
                                </TableCell>
                            </TableRow>
                        }
                        { orgName && !(data_standard === 'kosis' && orgName === 'ORG_TYPE5') &&
                            <TableRow>
                                <TableCell padding="none"
                                           className="title_sub">{CommonFunction.getCodeName(orgName, HTP_CODE)}</TableCell>
                                <TableCell padding="none" className="count_sub">
                                    {data.org_cnt && data.org_cnt.toLocaleString()} {data_standard === 'kosis' ? '명' : '개'}
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </Fragment>
        )
    }
}

export default S_BoxDemandContent;