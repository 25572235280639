import React, {Component, Fragment} from 'react';
import { Link } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PaymentIcon from '@material-ui/icons/Payment';
import SSnackbar from "./S_Snackbar";
import { isMobileOnly } from 'react-device-detect';

import useStyles from './SalaryStyle';

class S_CollapseLeft extends Component {
    state = {
        snackbar_open: false,
        snackbar_message: "",
        snackbar_type: "error"
    }
    /*state = {
        open: false
    }

    handleClick = () => {
        this.setState({
            open: !this.state.open
        })
    };*/

    closeDrawer = () => {
        this.props.closeDrawer();
    }

    // 스낵바 오픈
    openSnackbar = (msg) => {
        this.setState({
            snackbar_open: true,
            snackbar_message: msg
        })
    };

    // 스낵바 클로즈
    closeSnackbar = () => {
        this.setState({
            snackbar_open: false,
            snackbar_message: ""
        })
    };

    locationLink = () => {
        window.location.href = `/location`;
    };

    render() {
        const { classes } = this.props;
        // const { open } = this.state;

        return (
            <Fragment>
                <SSnackbar
                    snackbar_open={this.state.snackbar_open}
                    closeSnackbar={this.closeSnackbar}
                    snackbar_message={this.state.snackbar_message}
                    snackbar_type={this.state.snackbar_type}
                />
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader" style={{fontSize: "20px"}} className="title drgate">
                            <Link to="#" onClick={()=>window.location.href='/'}>
                                DOCTOR:GATE
                            </Link>
                        </ListSubheader>
                    }
                    className={classes.leftMenuRoot}
                >
                    <ListItem button component={Link} to="#" onClick={(e) => { this.locationLink(); e.preventDefault() }} className="subtit location">
                        <ListItemIcon>
                            {/*<AssessmentIcon />*/}
                            <span className="icon"></span>
                        </ListItemIcon>
                        <ListItemText primary="개원입지" />
                    </ListItem>
                    {/*<ListItem button onClick={this.handleClick} style={{borderBottom: "1px solid black"}}>*/}
                    <ListItem button component={Link} to="#" onClick={()=>window.location.href='/salary'} className="subtit salary">
                        <ListItemIcon>
                            {/*<PaymentIcon />*/}
                            <span className="icon"></span>
                        </ListItemIcon>
                        <ListItemText primary="연봉 인덱스" />
                        {/*{open ? <ExpandLess /> : <ExpandMore />}*/}
                    </ListItem>
                    <ListItem button component={Link} to="#" onClick={()=>window.location.href='/search'} className="subtit search">
                        <ListItemIcon>
                            {/*<LocalHospitalIcon />*/}
                            <span className="icon"></span>
                        </ListItemIcon>
                        <ListItemText primary="처방정보" />
                    </ListItem>
                    <span className="linespace"></span>
                    <ListItem button onClick={()=>window.open('http://www.medigate.net', '_blank')} className="subtit link">
                        <ListItemIcon>
                            <span className="icon medigate"></span>
                        </ListItemIcon>
                        <ListItemText primary="메디게이트" />
                    </ListItem>
                    <ListItem button onClick={()=>window.open('http://www.medigatenews.com', '_blank')} className="subtit link">
                        <ListItemIcon>
                            <span className="icon news"></span>
                        </ListItemIcon>
                        <ListItemText primary="메디게이트뉴스" />
                    </ListItem>
                    <ListItem button onClick={()=>window.open('http://www.h-link.co.kr', '_blank')} className="subtit link">
                        <ListItemIcon>
                            <span className="icon hlink"></span>
                        </ListItemIcon>
                        <ListItemText primary="H-LINK" />
                    </ListItem>
                    {/*<Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested} component={Link} to="/salary/payinfo" onClick={this.closeDrawer}>
                                <ListItemIcon>
                                    <MoneyIcon />
                                </ListItemIcon>
                                <ListItemText primary="급여정보" />
                            </ListItem>
                        </List>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested} component={Link} to="/salary/worktime" onClick={this.closeDrawer}>
                                <ListItemIcon>
                                    <TimerIcon />
                                </ListItemIcon>
                                <ListItemText primary="근무시간" />
                            </ListItem>
                        </List>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested} component={Link} to="/salary/condition" onClick={this.closeDrawer}>
                                <ListItemIcon>
                                    <SurroundSoundIcon />
                                </ListItemIcon>
                                <ListItemText primary="근무환경" />
                            </ListItem>
                        </List>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested} component={Link} to="/salary/demand" onClick={this.closeDrawer}>
                                <ListItemIcon>
                                    <WorkIcon />
                                </ListItemIcon>
                                <ListItemText primary="초빙수요" />
                            </ListItem>
                        </List>
                    </Collapse>*/}
                </List>
            </Fragment>
        );
    }
}

export default withStyles(useStyles)(S_CollapseLeft)

