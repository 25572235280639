import gql from 'graphql-tag';

export const COMMENT_CNT = gql`
	query select($ctg_code: String!, $kcd_code_arr: [String]!) {
		getCommentCnt(ctg_code: $ctg_code, kcd_code_arr: $kcd_code_arr) {
			count
		}
	}
`;

export const COMMENT_LIST = gql`
	query select($ctg_code: String!, $kcd_code_arr: [String]!, $sorter: String!, $u_id: String, $page_num: Int, $row_count: Int, $tranx: Int) {
		getCommentList(ctg_code: $ctg_code, kcd_code_arr: $kcd_code_arr, sorter: $sorter, u_id: $u_id, page_num: $page_num, row_count: $row_count, tranx: $tranx) {
			comment_idx
			ctg_code
			u_id
			u_type
			u_name
			u_pic
			u_spc_name
			u_wtp_name
			content
			u_ip
			like_cnt
			liked_flag
			reg_date
			kcd_list
			img_list			
		}
	}
`;

export const MY_COMMENT_CNT = gql`
	query select($u_id: String!, $reload_flag: Int) {
		getMyCommentCnt(u_id: $u_id, reload_flag: $reload_flag) {
			count
		}
	}
`;

export const MY_COMMENT_LIST = gql`
	query select($u_id: String!, $sorter: String!, $row_count: Int, $reload_flag: Int) {
		getMyCommentList(u_id: $u_id, sorter: $sorter, row_count: $row_count, reload_flag: $reload_flag) {
			comment_idx
			ctg_code
			u_id
			u_type
			u_name
			u_pic
			u_spc_name
			u_wtp_name
			content
			u_ip
			like_cnt
			liked_flag
			reg_date
			kcd_list
			img_list			
		}
	}
`;

export const COMMENT_CREATE = gql`
	mutation create($comment: COMMENT_INUPT!) {
		createComment(comment: $comment) {
			comment_idx
			ctg_code
			u_id
			u_type
			u_name
			u_pic
			u_spc_name
			u_wtp_name
			content
			u_ip
			like_cnt
			liked_flag
			reg_date
			kcd_list
			img_list
		}
	}
`;

export const COMMENT_UPDATE = gql`
	mutation update($comment: COMMENT_INUPT!) {
		editComment(comment: $comment) {
			comment_idx
			ctg_code
			u_id
			u_type
			u_name
			u_pic
			u_spc_name
			u_wtp_name
			content
			u_ip
			like_cnt
			liked_flag
			reg_date
			kcd_list
			img_list
		}
	}
`;

export const COMMENT_REMOVE = gql`
	mutation remove($comment_idx: Int!) {
		removeComment(comment_idx: $comment_idx) {
			result
		}
	}
`;

export const COMMENT_LIKE = gql`
	mutation update($comment_idx: Int!, $u_id: String!) {
		likeComment(comment_idx: $comment_idx, u_id: $u_id) {
			result
		}
	}
`;

export const COMMENT_DISLIKE = gql`
	mutation update($comment_idx: Int!, $u_id: String!) {
		dislikeComment(comment_idx: $comment_idx, u_id: $u_id) {
			result
		}
	}
`;