import React from 'react';
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'

const SubCapacityTimeChart = (props) => {
    // const categories = _.map(props.data.agg_stat, "name");
    // const chartData = _.map(props.data.agg_stat, "data").filter(zero => zero !== 0);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    type: 'pie',
                    height: 280
                },
                title: {
                    text: "회당 투여약물수",
                    margin: 0
                },
                tooltip: {
                    pointFormat: '<b>{point.percentage:.1f}%</b>'
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            distance: -30,
                            filter: {
                                property: 'percentage',
                                operator: '>',
                                value: 4
                            }
                        },
                        showInLegend: false,
                        animation: {
                            duration: 0
                        }
                    }
                },
                series: [{
                    data: props.data.fq1_mdct_qty
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal'
                            },
                            yAxis: {
                                labels: {
                                    align: 'left'
                                }
                            },
                            credits: {
                                enabled: false
                            }
                        }
                    }]
                }
            }}
        />
    )
}

export default SubCapacityTimeChart;