import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import StarsIcon from '@material-ui/icons/Stars';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withTheme } from '@material-ui/core/styles';
import { borders } from '@material-ui/system';
import useStyles from '../style/common'
import Grid from "@material-ui/core/Grid";
import withPopper from "./withPopper";
import PopperSettings from "./PopperSettings";
import UserProfileLayer from "../common/UserProfileLayer";
import MySettingsContainer from "./MySettingsContainer";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from '@material-ui/core/Divider';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import S_CollapseLeft from "../salery/S_CollapseLeft"

import { useStore } from "../../stores/StoreProvider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Popper from "@material-ui/core/Popper";
import withCodes from "./withCodes";
import PopperPopular from "./PopperPopular";
import LocationGuide from "./LocationGuide";

// class Header extends React.PureComponent {
function Header(props) {
    const { title, className } = props;

    const classes = useStyles();
    const store = useStore().LocationStore;
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const open = Boolean(anchorEl);

    //console.log("ksseo isLogin", store.isLogin)

    const handleChange = event => {
        setAuth(event.target.checked);
    };

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const MySettingsContainerByLogin = () => {
        let component = null;
        if(store.isLogin) {
            component = <MySettingsContainer/>;
        }
        return component;
    }


    /*for favoriteRegions*/
    const [popularOpen, setPopularOpen] = React.useState(false);
    const [popularAnchorEl, setPopularAnchorEl] = React.useState(null);
    const handlePupularClick = event => {
        // handleAllPopperClose();
        setPopularAnchorEl(event.currentTarget);
        setPopularOpen(!popularOpen);
        //setPopularOpen(Boolean(popularAnchorEl));
    };

    const togglePopularPopper = (e) => {
        if(popularOpen){
            if(e.target.nodeName && e.target.nodeName.toUpperCase() === 'BODY'){ return; } // SELECT BOX 선택 - onClick 시 SKIP
            const isPopperContain = document.getElementById("popperPopular").contains(e.target);
            const isSidoContain = document.getElementById("popular_sido") ? document.getElementById("popular_sido").parentNode.contains(e.target) : false;    // SELECT BOX - 시도 onChange 시
            const isSpcContain = document.getElementById("popular_spc") ? document.getElementById("popular_spc").parentNode.contains(e.target) : false;       // SELECT BOX - 진료과목 onChange 시
            //console.log("isPopperContain",isPopperContain);
            //console.log('isSidoContain', isSidoContain);
            //console.log('isSpcContain', isSpcContain);

            if(!(isPopperContain || isSidoContain || isSpcContain) || e.target.name == "closePopper"){
                setPopularOpen(false);
            }
        }
    };

    const updateAddrByPopular = (popularAddr) => {
        store.setSearchAddress(popularAddr);
    }
    /*for favoriteRegions*/

    const favoriteRegions = () => {
        return (<Box display="flex" pt={2} pl={2} style={{alignContent:"center", justifyContent:"flex-end"}} className="opt3_combo">
            <Box onClick={handlePupularClick}>
                <Grid container item direction={"row"} wrap="nowrap">
                    <Grid item style={{flexGrow:1}} zeroMinWidth>
                        인기 조회 지역
                    </Grid>
                    <Grid item style={{flexGrow:0}}>
                        <ExpandMoreIcon/>
                    </Grid>
                </Grid>
            </Box>
            { popularOpen &&
            <ClickAwayListener onClickAway={ togglePopularPopper }>
                <Popper open={popularOpen} anchorEl={popularAnchorEl} style={{zIndex:"3"}}>
                    { withCodes(PopperPopular)({addr: updateAddrByPopular, open: setPopularOpen} ) }
                </Popper>
            </ClickAwayListener>
            }
        </Box>)
    }


    return (
        <Box className={className}  border={1} borderTop={0} borderLeft={0} borderRight={0} borderColor="grey.500">
{/*
        <div className={classes.root}>
            <FormGroup>
                <FormControlLabel
                    control={<Switch checked={auth} onChange={handleChange} aria-label="login switch" />}
                    label={auth ? 'Logout' : 'Login'}
                />
            </FormGroup>*/}


            {/* Appbar start */}
            <AppBar position="static" color="inherit" elevation={0}  spacing={2}  >
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box className={classes.title}>
                        <Typography display={"inline"}>
                            {title}
                        </Typography>   
                    </Box>                     
                        <Typography display={"inline"}>
                            <LocationGuide />
                        </Typography>
                    <div style={{display:"flex"}}>
                        {/*여기에 인기조회지역 삽입요청*/}
                        {favoriteRegions()}

                        {/*회원일시 나오는 개인별 설정*/}
                        {MySettingsContainerByLogin()}

                        {/* 로그인아웃 */}
                        <UserProfileLayer loginLayerShow={store.loginLayerShow}/>


                        {/*<IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose}>Profile</MenuItem>
                            <MenuItem onClick={handleClose}>My account</MenuItem>
                        </Menu>*/}
                    </div>
                </Toolbar>
            </AppBar>
            {/* Appbar end */}

            {/* Hamburger menu start*/}
            <SwipeableDrawer
                open={drawerOpen}
                onClose={handleDrawerClose}
                onOpen={handleDrawerOpen}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />

                {/** Left navigation */}
                <S_CollapseLeft
                    closeDrawer={handleDrawerClose}
                />
            </SwipeableDrawer>
            {/* Hamburger menu end*/}


        </Box>
    );
}

export default withTheme(Header);
// export default withTheme(Header);