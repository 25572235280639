import React, {Fragment} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { withApollo, Query } from 'react-apollo';
import _ from 'lodash';
import ToggleIcon from 'material-ui-toggle-icon';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { confirmAlert } from 'react-confirm-alert';
import Tooltip from '@material-ui/core/Tooltip';

import HtmlConfirm from '../../../common/HtmlConfirm';
import ProductList from '../prescription/ProductList';

import { PRODUCT_LIST, LIKE_BOOKMARK, UNLIKE_BOOKMARK } from '../../../queries/prescript/prescription';
import { STAT_LIST, STAT_CNT } from '../../../queries/prescript/stat';

import ContentFragment from '../content/ContentFragment';
import HighChartFragment from '../prescription/HighChartFragment';
import KCDDetailMoreLess from '../comment/KCDDetailMoreLess';
import Chart_PlaceHolder from '../loading/Chart_PlaceHolder';
import CommonFunc from '../../../common/CommonFunction';
import { isMobileOnly } from 'react-device-detect';
import FilterStatYear from '../filter/FilterStatYear';

class ResponsiveDialog extends React.PureComponent {
    constructor(props){
        super(props);

        let date = CommonFunc.getDateIntervalMonth(-1); // 배치를 고려해 현재월 -1

        this.state = {
            like_flag: props.item.like_flag,
            total_like_cnt: props.item.total_like_cnt,
            kcd_list: props.kcd_list,
            selectedOption: null,
            pdt_placeholder: `해당 제품을 확인하세요(갱신:${date})`,
            more_less: 'LESS',
            open: false,
            scroll: "body",
            filter_stat_year: 0,
            filter_stat_year_selectedOption: null
        }
    }

    handleClickOpen = (scrollType) => {
        this.setState({
            open: true,
            scroll: scrollType
        }, () => {
            CommonFunc.pageview('처방정보', '성분상세', this.props.item.ingred_eng); //Google Analytics
        })
    };

    handleClose = () => {
        let date = CommonFunc.getDateIntervalMonth(-1); // 배치를 고려해 현재월 -1
        this.setState({
            open: false,
            selectedOption: null,
            pdt_placeholder: `해당 제품을 확인하세요(갱신:${date})`,
            filter_stat_year: 0,
            filter_stat_year_selectedOption: null
        })
    }

    componentWillReceiveProps = (nextProps) => {
        //console.log("componentWillReceiveProps item", nextProps.item, this.props.item)
        if (nextProps.item !== this.props.item) {
            //console.log('componentWillReceiveProps item1', nextProps.item);
            const { like_flag, total_like_cnt } = nextProps.item;
			this.setState({
                like_flag: like_flag,
                total_like_cnt: total_like_cnt,
                more_less: 'LESS'
            })

            //this.scrollToTop();
        }
	}

    changeFavoriteFlag = (afterFavoriteFlag, user) => {
        //console.log("changeFavoriteFlag", afterFavoriteFlag, user)

        let total_like_cnt = this.state.total_like_cnt + (afterFavoriteFlag === "Y" ? 1 : -1);

        if(!_.isEmpty(user)){
            let liked_item = {
                ...this.props.item,
                like_flag: afterFavoriteFlag,
                total_like_cnt: total_like_cnt,
            }

            this.setState({
                like_flag: afterFavoriteFlag,
                total_like_cnt: total_like_cnt
            })

            if(afterFavoriteFlag === "Y"){
                this.props.client.mutate({
                    mutation: LIKE_BOOKMARK,
                    variables: {
                        u_id: user.id,
                        ingred_code_grp: this.props.item.ingred_code_grp,
                        ingred_combi_flag: this.props.item.combi_flag,
                        method: this.props.item.method,
                        kcd_code_arr: this.props.kcd_code_arr,
                        ctg_code_grp: this.props.ctg_code_grp,
                        ctg_code3: this.props.ctg_code3
                    }
                })
                .then((res) => {
                    //console.log('SUCCESS LIKE', res);
                    this.props.onLike(liked_item);
                })
                .catch((error) => {
                    //console.log('error', error.message);
                });
            }else{
                let values = [{
                    u_id: user.id,
                    ingred_code_grp: this.props.item.ingred_code_grp,
                    ingred_combi_flag: this.props.item.combi_flag,
                    method: this.props.item.method
                }]
                this.props.client.mutate({
                    mutation: UNLIKE_BOOKMARK,
                    variables: {
                        likeBookmark: values
                    }
                })
                .then((res) => {
                    this.props.onLike(liked_item);
                    //console.log('SUCCESS UNLIKE', res);
                })
                .catch((error) => {
                    //console.log('error', error.message);
                });
            }
        }else{
            confirmAlert({ customUI: ({ onClose }) => <HtmlConfirm message={"로그인 후 이용하실 수 있습니다"} onClose={onClose} />});
            return false;
        }
    }

    onMenuOpen = () => {
        this.setState({
            pdt_placeholder: "검색하려면 제품명을 입력하세요"
        })
    }

    onMenuClose = () => {
        this.setState({
            pdt_placeholder: "해당 제품을 확인하세요(갱신:2019.07)"
        })
    }

    selectProduct = selectedOption => {
        //this.setState({ selectedOption: selectedOption });

        if(selectedOption.pmt_code !== "" && selectedOption.insurance !== "비급여"){
            let url = "https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetail?itemSeq=" + selectedOption.pmt_code;
            window.open(url, '_blank');
        }else{
            confirmAlert({ customUI: ({ onClose }) => <HtmlConfirm message={"해당 제품은 비급여 약물로 관련 정보를 제공하지 않습니다."} onClose={onClose} />});
            return false;
        }
    }

    onChangeFilterStatYear = (stat_year) => {
        this.setState({
            filter_stat_year: stat_year.value,
            filter_stat_year_selectedOption: stat_year
        })
    }
/*
    closeLayerOnLayer = () => {
        this.setState({
            selectedOption: null,
            pdt_placeholder: "해당 제품을 확인하세요(갱신:2019.07)"
        })
        // $("popup_kcd").hide();
        //$("#druginfo_layer").hide();
    }
*/
    render(){
        const {
            item,
            ctg_code3,
            ctg_name,
            user,
            dgsbjt_cd_arr,
            dgsbjt_dtl_cd_arr,
            agg_arr,
            sex_tp_cd_arr,
            cl_cd_arr,
            bed_arr,
            sido_cd_arr,
            filter_place,
            filter_category,
            filter_combination,
            filter_method,
            filter_stat_year
        } = this.props;
        const { kcd_list, selectedOption } = this.state;
        const kcd_code_arr = _.map(kcd_list, "kcd_code");
        if(this.state.filter_stat_year === 0){
            this.setState({
                filter_stat_year: filter_stat_year,
                filter_stat_year_selectedOption: {value: filter_stat_year, label: filter_stat_year}
            })
        }

        return (
            <div>
                <button className="dialog-button" onClick={() => this.handleClickOpen(!isMobileOnly ? "body" : "paper")}>
                    <p className="titxt">{this.props.item.combi_flag === "Y" && "(복합)"} {this.props.item.ingred_eng}</p>
                    <p className="subtxt">{this.props.item.atc_name}</p>
                </button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    scroll={this.state.scroll}
                    fullScreen={isMobileOnly ? true : false}
                    aria-labelledby="scroll-dialog-title"
                    style={{"-webkitOverflowScrolling": "touch", overflow: "hidden"}}
                >
                        <div className="modal-dialog">
                            <div className="contwrap modal-content">
                                { (!_.isEmpty(item) && this.state.filter_stat_year > 0) &&
                                    <Fragment>
                                        <p className="mdl-button close" data-dismiss="modal" onClick={this.handleClose}>
                                            <span className="btn-closeApply"></span>
                                        </p>
                                        <div className="top">
                                            <div className="tit">{item.ingred_eng}</div>
                                            <div className="titlike">
                                                <div>
                                                    <IconButton
                                                        onClick={() => this.changeFavoriteFlag(this.state.like_flag === "Y" ? "N" : "Y", user)}
                                                    >
                                                        <ToggleIcon
                                                            on={this.state.like_flag === "Y"}
                                                            onIcon={<FavoriteIcon />}
                                                            offIcon={<FavoriteBorderIcon />}
                                                        />
                                                    </IconButton>
                                                    <span>{this.state.total_like_cnt}</span>
                                                </div>
                                            </div>
                                            <div className="titcode">
                                                <p className="code">#{ctg_name}</p>
                                                <p className="code">#{item.ingred_kor}</p>
                                            </div>
                                        </div>
                                        <div className="txtinfo">
                                            {
                                                <Query query={PRODUCT_LIST} variables={{
                                                    ingred_code_grp: item.ingred_code_grp,
                                                    method: item.method,
                                                    ingred_combi_flag:
                                                    item.combi_flag,
                                                    u_id: this.props.userProfile.u_id,
                                                    u_spc_code: this.props.userProfile.u_spc_code,
                                                    u_wtp_code: this.props.userProfile.u_wtp_code,
                                                    u_hospital_group_code: this.props.userProfile.u_hospital_group_code,
                                                    u_birthday: this.props.userProfile.u_birthday,
                                                    u_sex: this.props.userProfile.u_sex,
                                                    u_zip_zone: this.props.userProfile.u_zip_zone
                                                }}>
                                                    {({ data, loading, error }) => {
                                                        if (loading) return <p></p>
                                                        if (error) return <p>{error.message}</p>;
                                                        //console.log('PRODUCT_LIST', data);
                                                        const insurance_count = _.filter(data.getPdtList, ["insurance", "급여"]).length;

                                                        return (
                                                            <Fragment>
                                                                <ProductList
                                                                    data={data}
                                                                    insurance_count={insurance_count}
                                                                    from_type="dialog"
                                                                />
                                                            </Fragment>
                                                        )
                                                    }}
                                                </Query>
                                            }
                                        </div>
                                        <div className="popchapter">
                                            <div className="chaptit">
                                                {
                                                    <Query query={STAT_CNT} variables={{
                                                        kcd_code_arr: kcd_code_arr,
                                                        ctg_code3: ctg_code3,
                                                        ingred_code_grp: item.ingred_code_grp,
                                                        combi_flag: filter_combination,
                                                        method: item.method,
                                                        dgsbjt_cd_arr: dgsbjt_cd_arr,
                                                        dgsbjt_dtl_cd_arr: dgsbjt_dtl_cd_arr,
                                                        agg_arr: agg_arr,
                                                        sex_tp_cd_arr: sex_tp_cd_arr,
                                                        cl_cd_arr: cl_cd_arr,
                                                        bed_arr: bed_arr,
                                                        sido_cd_arr: sido_cd_arr,
                                                        place: filter_place,
                                                        atc_code: filter_category,
                                                        ingred_code_grp_arr: [],
                                                        ingred_combi_flag: item.combi_flag,
                                                        stat_year: this.state.filter_stat_year
                                                    }}>
                                                        {({ data, loading, error }) => {
                                                            if (loading) return null;
                                                            if (error) return <p>{error.message}</p>;
                                                            // //console.log('STAT_CNT', data);

                                                            return (
                                                                <Fragment>
                                                                    {
                                                                        data.getStatTotalCnt.count >= 100 &&
                                                                        /*<Tooltip title={`처방전 샘플수가 충분합니다 (${ filter_stat_year }년 표본데이터)`} placement="bottom-end">*/
                                                                            <p className="schstatus type3">샘플수 양호({
                                                                                <FilterStatYear
                                                                                    filter_stat_year={this.state.filter_stat_year}
                                                                                    filter_stat_year_selectedOption={this.state.filter_stat_year_selectedOption}
                                                                                    onChangeFilterStatYear={this.onChangeFilterStatYear}
                                                                                />
                                                                            }년)</p>
                                                                        /*</Tooltip>*/
                                                                    }
                                                                    {
                                                                        (data.getStatTotalCnt.count >= 50 && data.getStatTotalCnt.count < 100) &&
                                                                        /*<Tooltip title="처방전 샘플수가 조금 부족해, 추가 상세검색을 추천하지 않습니다." placement="bottom-end">*/
                                                                            <p className="schstatus type2">샘플수 주의({
                                                                                <FilterStatYear
                                                                                    filter_stat_year={this.state.filter_stat_year}
                                                                                    filter_stat_year_selectedOption={this.state.filter_stat_year_selectedOption}
                                                                                    onChangeFilterStatYear={this.onChangeFilterStatYear}
                                                                                />
                                                                            }년)</p>
                                                                        /*</Tooltip>*/
                                                                    }
                                                                    {
                                                                        data.getStatTotalCnt.count < 50 &&
                                                                        /*<Tooltip title="처방전 샘플수가 부족해, 결과 해석에 주의가 필요합니다." placement="bottom-end">*/
                                                                            <p className="schstatus type1">샘플수 부족({
                                                                                <FilterStatYear
                                                                                    filter_stat_year={this.state.filter_stat_year}
                                                                                    filter_stat_year_selectedOption={this.state.filter_stat_year_selectedOption}
                                                                                    onChangeFilterStatYear={this.onChangeFilterStatYear}
                                                                                />
                                                                            }년)</p>
                                                                        /*</Tooltip>*/
                                                                    }
                                                                </Fragment>
                                                            )
                                                        }}
                                                    </Query>
                                                }
                                                <p className="tittxt">선택한 조건으로 처방된 <span className="diseasename">{item.ingred_eng}</span>의 통계</p>

                                                <KCDDetailMoreLess
                                                    kcd_list_of_comment={this.state.kcd_list}
                                                    more_less={this.state.more_less}
                                                />

                                            </div>
                                            <div className="cont base">
                                                {
                                                    <Query query={STAT_LIST} variables={{
                                                        kcd_code_arr: kcd_code_arr,
                                                        ctg_code3: ctg_code3,
                                                        ingred_code_grp: item.ingred_code_grp,
                                                        combi_flag: filter_combination,
                                                        method: item.method,
                                                        dgsbjt_cd_arr: dgsbjt_cd_arr,
                                                        dgsbjt_dtl_cd_arr: dgsbjt_dtl_cd_arr,
                                                        agg_arr: agg_arr,
                                                        sex_tp_cd_arr: sex_tp_cd_arr,
                                                        cl_cd_arr: cl_cd_arr,
                                                        bed_arr: bed_arr,
                                                        sido_cd_arr: sido_cd_arr,
                                                        place: filter_place,
                                                        atc_code: filter_category,
                                                        ingred_code_grp_arr: [],
                                                        ingred_combi_flag: item.combi_flag,
                                                        stat_year: this.state.filter_stat_year
                                                    }}
                                                    fetchPolicy="cache-and-network"
                                                    >
                                                        {({ data, loading, error }) => {
                                                            if (loading) return <Chart_PlaceHolder/>;
                                                            if (error) return <p>{error.message}</p>;
                                                            //console.log('STAT_LIST', data);

                                                            return (
                                                                <HighChartFragment
                                                                    data={data}
                                                                    ingred_eng={item.ingred_eng}
                                                                    kcd_code_arr={kcd_code_arr}
                                                                    ctg_code3={ctg_code3}
                                                                    ingred_code_grp={item.ingred_code_grp}
                                                                    ingred_combi_flag={item.combi_flag}
                                                                    dgsbjt_cd_arr={dgsbjt_cd_arr}
                                                                    dgsbjt_dtl_cd_arr={dgsbjt_dtl_cd_arr}
                                                                    agg_arr={agg_arr}
                                                                    sex_tp_cd_arr={sex_tp_cd_arr}
                                                                    cl_cd_arr={cl_cd_arr}
                                                                    bed_arr={bed_arr}
                                                                    sido_cd_arr={sido_cd_arr}
                                                                    place={filter_place}
                                                                    atc_code={filter_category}
                                                                    combi_flag={filter_combination}
                                                                    method={item.method}
                                                                    stat_year= {this.state.filter_stat_year}
                                                                />
                                                            )
                                                        }}
                                                    </Query>
                                                }
                                            </div>
                                        </div>
                                        <ContentFragment
                                            user={ this.props.user }
                                            ingred_kor={item.ingred_kor}
                                            ingred_eng={item.ingred_eng}
                                        />
                                    </Fragment>
                                }
                            </div>
                            {
                                isMobileOnly &&
                                <DialogActions>
                                    <Button onClick={this.handleClose} color="primary">
                                        닫기
                                    </Button>
                                </DialogActions>
                            }
                        </div>
                </Dialog>
            </div>
        );
    }
}

export default withApollo(ResponsiveDialog);