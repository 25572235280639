import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import $ from 'jquery';
import 'bootstrap';
import _ from 'lodash';

import { PRODUCT_LIST, PRESCRIPTION_LIST } from '../../../queries/prescript/prescription';

import ProductList from './ProductList';
import FavoriteFragment from './FavoriteFragment';
import CombiIndicator from './CombiIndicator';
import Combi3Fragment from './Combi3Fragment';
import Combi3_PlaceHolder from '../loading/Combi3_PlaceHolder';
import ResponsiveDialog from '../layer/ResponsiveDialog';

class Combi2Fragment extends React.PureComponent {
    state = {
        combi2_flag: "",
        combi3_flag: "",
        selected_combo3_index: 0,
        ingred_code_grp_arr: [],
        liked_item: null,
        prescript_use_percent: 0
    }

    changeCombiValue = (active_flag, index, ingred_code_grp_arr, prescript_use_percent) => {
        const old_index = this.state.selected_combo3_index;
        const new_index = index;
        //console.log('changeCombiValue', active_flag, old_index, new_index);

        if(active_flag){
            let complexArr = [this.props.ingred_code_grp_arr];
            complexArr = complexArr.concat(ingred_code_grp_arr);
            //console.log("complexArr", complexArr);
            this.setState({
                selected_combo3_index: index,
                ingred_code_grp_arr: complexArr,
                prescript_use_percent: prescript_use_percent
            }, () => {
                $(".contlist.second button.c3btn"+old_index).removeClass("active");
                $(".contlist.second button.c3btn"+new_index).addClass("active");
            })
        }
        else{
            this.setState({
                selected_combo3_index: 0,
                ingred_code_grp_arr: this.props.ingred_code_grp_arr,
                prescript_use_percent: 0
            }, () => {
                $(".contlist.second button.c3btn"+new_index).removeClass("active");
            })
        }
    }

    updateLike3rd = (liked_item, fetchMore) => {
        //console.log('liked_item_result - 3rd', liked_item);
        fetchMore({
            updateQuery: (prev, { fetchMoreResult }) => {
                let list = [];
                //console.log('prev.getPrescriptionList', prev.getPrescriptionList)
                prev.getPrescriptionList.map(item => {
                    //console.log('item', item)
                    if(liked_item.ingred_code_grp === item.ingred_code_grp && liked_item.combi_flag === item.combi_flag && liked_item.method === item.method){
                        //console.log('liked_item_update - 3rd', liked_item, item);
                        list.push({...item, like_flag: liked_item.like_flag, total_like_cnt: liked_item.total_like_cnt});
                    }
                    else{
                        list.push(item);
                    }
                })
                return Object.assign({}, prev, {
                    getPrescriptionList: list
                });
            }
        })
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        const propsChanged = (newProps.liked_item !== null && oldProps.liked_item !== newProps.liked_item);
        //console.log('componentWillReceiveProps changed - 2nd', propsChanged);
        if(propsChanged) {
            this.setState({
                liked_item: newProps.liked_item
            })
            this.props.refetch();
        }
    }

    render() {
        const { getPrescriptionList  } = this.props.data;
        const { percent } = this.props;
        //console.log("percent", percent);
        // const parentPercent = _.split(percent, "%", 1)[0];

        let prescript_use_percent = 0;

        return(
            <div className="listdepth">
                {
                    getPrescriptionList.map((item, index) => {
                        if(this.props.order === "prescript"){
                            prescript_use_percent = Number.parseFloat((percent * item.prescript_share_per_round5) * 0.01).toFixed(1)
                        }else{
                            prescript_use_percent = Number.parseFloat((percent * item.use_share_per_round5 * 0.01)).toFixed(1)
                        }

                        return (
                            <Fragment>
                                <div className="contlist second" key={`2nd_${index}`}>
                                    <div className="txtinfo td07">
                                        {
                                            (this.props.filter_combination === "3combi") &&
                                            <CombiIndicator
                                                index={index + 1}
                                                depth={3}
                                                prescript_use_percent={prescript_use_percent}
                                                ingred_code_grp_arr={item.ingred_code_grp}
                                                changeCombiValue={this.changeCombiValue}
                                            />
                                        }
                                    </div>
                                    <div className="txtinfo td01"><span></span></div>
                                    <div className="txtinfo td02">
                                        <span>
                                            {prescript_use_percent}
                                        </span>
                                        <span>%</span>
                                    </div>
                                    <div className="txtinfo td06">
                                        {
                                            item.method === "A" &&
                                            <span className="type1">{item.method_kor}</span>
                                        }
                                        {
                                            item.method === "B" &&
                                            <span className="type2">{item.method_kor}</span>
                                        }
                                        {
                                            item.method === "C" &&
                                            <span className="type3">{item.method_kor}</span>
                                        }
                                        {
                                            item.method === "D" &&
                                            <span className="type4">{item.method_kor}</span>
                                        }
                                    </div>
                                    <div className="txtinfo td03">
                                        <ResponsiveDialog
                                            item={item}
                                            kcd_code_arr={this.props.kcd_code_arr}
                                            ctg_code_grp={this.props.ctg_code_grp}
                                            ctg_name={this.props.ctg_name}
                                            ctg_code3={this.props.ctg_code3}
                                            user={this.props.user}
                                            kcd_list={this.props.kcd_list}
                                            dgsbjt_cd_arr={this.props.dgsbjt_cd_arr}
                                            dgsbjt_dtl_cd_arr={this.props.dgsbjt_dtl_cd_arr}
                                            agg_arr={this.props.agg_arr}
                                            sex_tp_cd_arr={this.props.sex_tp_cd_arr}
                                            cl_cd_arr={this.props.cl_cd_arr}
                                            bed_arr={this.props.bed_arr}
                                            sido_cd_arr={this.props.sido_cd_arr}
                                            userProfile={this.props.userProfile}
                                            onLike={(liked_item) => {
                                                // update 1st/2nd Depth
                                                this.props.onLike2nd(liked_item);
                                                // update 3rd depth like
                                                this.setState({
                                                    liked_item: liked_item
                                                })
                                            }}
                                            filter_place={this.props.filter_place}
                                            filter_category={this.props.filter_category}
                                            filter_combination={this.props.filter_combination}
                                            filter_method={this.props.filter_method}
                                            filter_stat_year={this.props.filter_stat_year}
                                        />
                                        {/**
                                        <button className="dialog-button" onClick={()=>this.props.displayIngredDetails(item)}>
                                            <p className="titxt">{item.combi_flag === "Y" && "(복합)"} {item.ingred_eng}</p>
                                            <p className="subtxt">{item.atc_name}</p>
                                        </button>
                                         */}
                                    </div>
                                    <div className="txtinfo td04">
                                        {
                                            <Query query={PRODUCT_LIST} variables={{
                                                ingred_code_grp: item.ingred_code_grp,
                                                method: item.method,
                                                ingred_combi_flag: item.combi_flag,
                                                u_id: this.props.userProfile.u_id,
                                                u_spc_code: this.props.userProfile.u_spc_code,
                                                u_wtp_code: this.props.userProfile.u_wtp_code,
                                                u_hospital_group_code: this.props.userProfile.u_hospital_group_code,
                                                u_birthday: this.props.userProfile.u_birthday,
                                                u_sex: this.props.userProfile.u_sex,
                                                u_zip_zone: this.props.userProfile.u_zip_zone
                                            }}>
                                                {({ data, loading, error }) => {
                                                    if (loading) return null;
                                                    if (error) return <p>{error.message}</p>;
                                                    //console.log('2combi PRODUCT_LIST', data);
                                                    const insurance_count = _.filter(data.getPdtList, ["insurance", "급여"]).length;

                                                    return (
                                                        <ProductList
                                                            data={data}
                                                            insurance_count={insurance_count}
                                                        />
                                                    )
                                                }}
                                            </Query>
                                        }
                                    </div>
                                    <div className="txtinfo td05">
                                        <FavoriteFragment
                                            item={item}
                                            ctg_code_grp={this.props.ctg_code_grp}
                                            ctg_code3={this.props.ctg_code3}
                                            kcd_code_arr={this.props.kcd_code_arr}
                                            filter_combination={this.props.filter_combination}
                                            user={this.props.user}
                                            onLike={(liked_item) => {
                                                // update 1st/2nd depth like
                                                this.props.onLike2nd(liked_item);
                                                // update 3nd depth like
                                                this.setState({
                                                    liked_item: liked_item
                                                })
                                            }}
                                        />
                                    </div>
                                </div>

                                {
                                    this.props.filter_combination === "3combi" &&
                                    (this.state.selected_combo3_index === (index + 1)) &&
                                    <Query
                                        query={PRESCRIPTION_LIST}
                                        variables={{
                                            kcd_code_arr: this.props.kcd_code_arr,
                                            ctg_code3: this.props.ctg_code3,
                                            dgsbjt_cd_arr: this.props.dgsbjt_cd_arr,
                                            dgsbjt_dtl_cd_arr: this.props.dgsbjt_dtl_cd_arr,
                                            agg_arr: this.props.agg_arr,
                                            sex_tp_cd_arr: this.props.sex_tp_cd_arr,
                                            cl_cd_arr: this.props.cl_cd_arr,
                                            bed_arr: this.props.bed_arr,
                                            sido_cd_arr: this.props.sido_cd_arr,
                                            atc_code: this.props.filter_category,
                                            method: item.method,
                                            u_id: this.props.u_id,
                                            order: this.props.order,
                                            combi_flag: this.props.filter_combination,
                                            ingred_code_grp_arr: this.state.ingred_code_grp_arr,
                                            ingred_combi_flag: item.combi_flag,
                                            stat_year: item.stat_year
                                        }}
                                        // fetchPolicy={"cache-and-network"}
                                    >
                                        {({ data, loading, error, fetchMore, refetch }) => {
                                            if (loading) return <Combi3_PlaceHolder/>;
                                            if (error) return <p>{error.message}</p>;

                                            //console.log('3combi PRESCRIPTION_LIST', this.props.page_num, data.getPrescriptionList);

                                            return (
                                                <Combi3Fragment
                                                    data={data}
                                                    liked_item={this.state.liked_item}
                                                    refetch={refetch}
                                                    kcd_code_arr={this.props.kcd_code_arr}
                                                    ctg_code_grp={this.props.ctg_code_grp}
                                                    ctg_code3={this.props.ctg_code3}
                                                    ctg_name={this.props.ctg_name}
                                                    kcd_list={this.props.kcd_list}
                                                    dgsbjt_cd_arr={this.props.dgsbjt_cd_arr}
                                                    dgsbjt_dtl_cd_arr={this.props.dgsbjt_dtl_cd_arr}
                                                    agg_arr={this.props.agg_arr}
                                                    sex_tp_cd_arr={this.props.sex_tp_cd_arr}
                                                    cl_cd_arr={this.props.cl_cd_arr}
                                                    bed_arr={this.props.bed_arr}
                                                    sido_cd_arr={this.props.sido_cd_arr}
                                                    order={this.props.order}
                                                    user={this.props.user}
                                                    userProfile={this.props.userProfile}
                                                    percent={this.state.prescript_use_percent}
                                                    filter_place={this.props.filter_place}
                                                    filter_category={this.props.filter_category}
                                                    filter_method={this.props.filter_method}
                                                    filter_combination={this.props.filter_combination}
                                                    filter_stat_year={this.props.filter_stat_year}
                                                    displayIngredDetails={this.props.displayIngredDetails}
                                                    onLike={(liked_item) => {
                                                        // update 3rd depth like
                                                        this.updateLike3rd(liked_item, fetchMore);
                                                        // update 1st/2nd depth like
                                                        this.props.onLike2nd(liked_item);
                                                    }}
                                                    /*
                                                    onLikeFrom1st={(liked_item) => {
                                                        //console.log('onLikeFrom1st -- xxx', liked_item);
                                                        // update 3nd depth like
                                                        this.updateLike3rd(liked_item, fetchMore);

                                                        // reset liked_item
                                                        this.setState({
                                                            liked_item: null
                                                        })
                                                    }}
                                                    */
                                                />
                                            )
                                        }}
                                    </Query>
                                }
                            </Fragment>
                        )
                    })
                }
            </div>
        )
    }
}

export default Combi2Fragment;