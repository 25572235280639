import React, {Component, Fragment} from 'react';
import clsx from 'clsx';
import {Query} from "react-apollo";
import {inject, observer} from "mobx-react";

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Skeleton from "@material-ui/lab/Skeleton";

import SNotLogin from './S_NotLogin';
import SFormSelectBox from "./S_FormSelectBox";
import SPieChart from "./S_PieChart";
import SNonPieContent from "./S_NonPieContent";
import SLongStackedBarGraph from "./S_LongStackedBarGraph";
import SLongPercentAreaGraph from "./S_LongPercentAreaGraph";

import {CONDITION_PIE, CONDITION_STACKED, CONDITION_MONTH_CHART, HLINK_AD} from "../../queries/salary/salary";
import { ORG_TYPE, SPC_CODE, HTP_CODE, LOC_CODE} from "./UserConstants";
import CommonFunction from "../../common/CommonFunction";
import SHlinkADBox from "./S_HlinkADBox";
import useStyles from './SalaryStyle';

@inject("salaryStore")
@observer
class S_Condition extends Component {
    state = {
        login: true,
        value: 0,
        filter_condition_spc: "",
        filter_condition_region: "",
        filter_condition_instType: "",
        filter_condition_rate_term: "year",
        filter_condition_type_kind: "nego",
        filter_condition_type_term: "year",
    }

    componentDidMount = () => {
        CommonFunction.pageview('연봉인덱스', '근무환경'); //Google Analytics

        this.props.handleCurrentMenu(2);
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        })
    };

    handleChangeFilter = (event, id) => {
        //console.log("event", event.target.value, id);

        if(id === "condition_rate_spc"){
            this.setState({
                filter_condition_spc: event.target.value
            })
        }else if(id === "condition_rate_region"){
            this.setState({
                filter_condition_region: event.target.value
            })
        }else if(id === "condition_rate_instType"){
            this.setState({
                filter_condition_instType: event.target.value
            })
        }else if(id === "condition_rate_term"){
            this.setState({
                filter_condition_rate_term: event.target.value
            })
        }else if(id === "condition_type_kind"){
            this.setState({
                filter_condition_type_kind: event.target.value
            })
        }else if(id === "condition_type_term"){
            this.setState({
                filter_condition_type_term: event.target.value
            })
        }
    }

    render() {
        const { classes } = this.props;
        const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
        const { salaryStore } = this.props;
        const { userProfile } = this.props.salaryStore;
        const org_type = CommonFunction.getCodeName(userProfile.u_hospital_group_code, ORG_TYPE);
        const {
            filter_condition_spc,
            filter_condition_region,
            filter_condition_instType,
            filter_condition_rate_term,
            filter_condition_type_kind,
            filter_condition_type_term
        } = this.state;

        return (
            <Fragment>
                {
                    !salaryStore.isLogin ?
                    <SNotLogin />
                    :
                    <Container maxWidth="lg" className={classes.container}>
                        <div>
                            <Typography component="div" style={{float: "left"}} gutterBottom>
                                <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                    추가옵션 비율
                                </Typography>
                                <Typography component="span" variant="caption">
                                    근무환경 중 부대옵션에 관한 조건의 해당 비율 정보입니다. 필터를 통해 조건별로 확인할 수 있습니다
                                </Typography>
                            </Typography>
                            <Typography component="div" gutterBottom className="combo_1">
                                <SFormSelectBox
                                    id="condition_rate_spc"
                                    labelId="condition_rate_spc_label"
                                    defaultValue="전문과 전체"
                                    empty="true"
                                    selectedValue={filter_condition_spc}
                                    type="custom"
                                    optionsData={SPC_CODE}
                                    change={this.handleChangeFilter}
                                />
                                <SFormSelectBox
                                    id="condition_rate_region"
                                    labelId="condition_rate_region_label"
                                    defaultValue="지역 전체"
                                    empty="true"
                                    selectedValue={filter_condition_region}
                                    type="custom"
                                    optionsData={LOC_CODE}
                                    change={this.handleChangeFilter}
                                />
                                <SFormSelectBox
                                    id="condition_rate_instType"
                                    labelId="condition_rate_instType_label"
                                    defaultValue="병원타입 전체"
                                    empty="true"
                                    selectedValue={filter_condition_instType}
                                    type="custom"
                                    optionsData={HTP_CODE}
                                    change={this.handleChangeFilter}
                                />
                                <SFormSelectBox
                                    id="condition_rate_term"
                                    labelId="condition_rate_term_label"
                                    labelText="기간"
                                    selectedValue={filter_condition_rate_term}
                                    type="term"
                                    change={this.handleChangeFilter}
                                />
                            </Typography>
                            <Paper>
                                <div className="workcond">
                                    <Grid container spacing={3}>
                                        <Query
                                            query={CONDITION_PIE}
                                            variables={{
                                                spc_code: filter_condition_spc,
                                                hop_loc_code: filter_condition_region,
                                                org_type: filter_condition_instType,
                                                period_type: filter_condition_rate_term
                                            }}
                                            fetchPolicy="network-only"
                                        >
                                            {({ data, loading, error }) => {
                                                //if (loading) return <p></p>;
                                                if (error) return <p>{error.message}</p>;

                                                return (
                                                    <Fragment>
                                                        <Grid item xs={12} md={3} lg={3}>
                                                            <Paper className={fixedHeightPaper}>
                                                                {
                                                                    (data && data.getWorkCondPieChartData) ?
                                                                    <Fragment>
                                                                        <Typography component="h6" variant="subtitle1" align="left">
                                                                            술기에 따른 인상여부
                                                                        </Typography>
                                                                        <SPieChart
                                                                            data={[
                                                                                ['',''],
                                                                                ['예', data.getWorkCondPieChartData.cnt_nego_flag_y],
                                                                                ['아니오', data.getWorkCondPieChartData.cnt_nego_flag_n]
                                                                            ]}
                                                                            //loading={loading}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <Skeleton variant="rect" width="100%" height={240} />
                                                                    </Fragment>
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={3}>
                                                            <Paper className={fixedHeightPaper}>
                                                                {
                                                                    (data && data.getWorkCondPieChartData) ?
                                                                    <Fragment>
                                                                        <Typography component="h6" variant="subtitle1" align="left">
                                                                            인센티브
                                                                        </Typography>
                                                                        <SPieChart
                                                                            data={[
                                                                                ['',''],
                                                                                ['예', data.getWorkCondPieChartData.cnt_incentive_flag_y],
                                                                                ['아니오', data.getWorkCondPieChartData.cnt_incentive_flag_n]
                                                                            ]}
                                                                            loading={loading}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <Skeleton variant="rect" width="100%" height={240} />
                                                                    </Fragment>
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={3}>
                                                            <Paper className={fixedHeightPaper}>
                                                                {
                                                                    (data && data.getWorkCondPieChartData) ?
                                                                    <Fragment>
                                                                        <Typography component="h6" variant="subtitle1" align="left">
                                                                            연차를 근로기준법에 준함
                                                                        </Typography>
                                                                        <SPieChart
                                                                            data={[
                                                                                ['',''],
                                                                                ['예', data.getWorkCondPieChartData.cnt_labor_law_flag_y],
                                                                                ['아니오', data.getWorkCondPieChartData.cnt_labor_law_flag_n]
                                                                            ]}
                                                                            loading={loading}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <Skeleton variant="rect" width="100%" height={240} />
                                                                    </Fragment>
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={3}>
                                                            <Paper className={fixedHeightPaper} style={{justifyContent:'unset'}}>
                                                                {
                                                                    (data && data.getWorkCondPieChartData) ?
                                                                    <Fragment>
                                                                        <Typography component="h6" variant="subtitle1" align="left">
                                                                            NET 월급 계약 옵션
                                                                        </Typography>
                                                                        <SNonPieContent
                                                                            data={
                                                                                {
                                                                                    option1: data.getWorkCondPieChartData.pay_option1,
                                                                                    option2: data.getWorkCondPieChartData.pay_option2,
                                                                                    option3: data.getWorkCondPieChartData.pay_option3
                                                                                }
                                                                            }
                                                                            loading={loading}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <Skeleton variant="rect" width="100%" height={240} />
                                                                    </Fragment>
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={3}>
                                                            <Paper className={fixedHeightPaper}>
                                                                {
                                                                    (data && data.getWorkCondPieChartData) ?
                                                                    <Fragment>
                                                                        <Typography component="h6" variant="subtitle1" align="left">
                                                                            주택 제공
                                                                        </Typography>
                                                                        <SPieChart
                                                                            data={[
                                                                                ['',''],
                                                                                ['예', data.getWorkCondPieChartData.cnt_house_flag_y],
                                                                                ['아니오', data.getWorkCondPieChartData.cnt_house_flag_n]
                                                                            ]}
                                                                            loading={loading}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <Skeleton variant="rect" width="100%" height={240} />
                                                                    </Fragment>
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={3}>
                                                            <Paper className={fixedHeightPaper}>
                                                                {
                                                                    (data && data.getWorkCondPieChartData) ?
                                                                    <Fragment>
                                                                        <Typography component="h6" variant="subtitle1" align="left">
                                                                            식사비 별도 제공
                                                                        </Typography>
                                                                        <SPieChart
                                                                            data={[
                                                                                ['',''],
                                                                                ['예', data.getWorkCondPieChartData.cnt_meal_flag_y],
                                                                                ['아니오', data.getWorkCondPieChartData.cnt_meal_flag_n]
                                                                            ]}
                                                                            loading={loading}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <Skeleton variant="rect" width="100%" height={240} />
                                                                    </Fragment>
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={3}>
                                                            <Paper className={fixedHeightPaper}>
                                                                {
                                                                    (data && data.getWorkCondPieChartData) ?
                                                                    <Fragment>
                                                                        <Typography component="h6" variant="subtitle1" align="left">
                                                                            배상책임보험 병원가입
                                                                        </Typography>
                                                                        <SPieChart
                                                                            data={[
                                                                                ['',''],
                                                                                ['예', data.getWorkCondPieChartData.cnt_insu_flag_y],
                                                                                ['아니오', data.getWorkCondPieChartData.cnt_insu_flag_n]
                                                                            ]}
                                                                            loading={loading}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <Skeleton variant="rect" width="100%" height={240} />
                                                                    </Fragment>
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                    </Fragment>
                                                )
                                            }}
                                        </Query>
                                    </Grid>
                                </div>
                            </Paper>
                        </div>

                        <div style={{margin:"60px 0"}}>
                            <Typography component="div" style={{float: "left"}} gutterBottom>
                                <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                    공휴일/당직 비율
                                </Typography>
                                <Typography component="span" variant="caption">
                                    근무환경 중 공휴일/ 당직에 관한 조건의 해당 비율 정보입니다. 필터를 통해 조건별로 확인할 수 있습니다
                                </Typography>
                            </Typography>
                            <Paper>
                                <div className="workcond">
                                    <Grid container spacing={3}>
                                        <Query
                                            query={CONDITION_PIE}
                                            variables={{
                                                spc_code: filter_condition_spc,
                                                hop_loc_code: filter_condition_region,
                                                org_type: filter_condition_instType,
                                                period_type: filter_condition_rate_term
                                            }}
                                            fetchPolicy="network-only"
                                        >
                                            {({ data, loading, error }) => {
                                                //if (loading) return <p></p>;
                                                if (error) return <p>{error.message}</p>;

                                                return (
                                                    <Fragment>
                                                        <Grid item xs={12} md={3} lg={3}>
                                                            <Paper className={fixedHeightPaper}>
                                                                {
                                                                    (data && data.getWorkCondPieChartData) ?
                                                                    <Fragment>
                                                                        <Typography component="h6" variant="subtitle1" align="left">
                                                                            토요일 휴무
                                                                        </Typography>
                                                                        <SPieChart
                                                                            data={[
                                                                                ['',''],
                                                                                ['예', data.getWorkCondPieChartData.cnt_s_work_flag_y],
                                                                                ['아니오', data.getWorkCondPieChartData.cnt_s_work_flag_y]
                                                                            ]}
                                                                            loading={loading}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <Skeleton variant="rect" width="100%" height={240} />
                                                                    </Fragment>
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={3}>
                                                            <Paper className={fixedHeightPaper}>
                                                                {
                                                                    (data && data.getWorkCondPieChartData) ?
                                                                    <Fragment>
                                                                        <Typography component="h6" variant="subtitle1" align="left">
                                                                            일요일 휴무
                                                                        </Typography>
                                                                        <SPieChart
                                                                            data={[
                                                                                ['',''],
                                                                                ['예', data.getWorkCondPieChartData.cnt_h_work_flag_y],
                                                                                ['아니오', data.getWorkCondPieChartData.cnt_h_work_flag_n]
                                                                            ]}
                                                                            loading={loading}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <Skeleton variant="rect" width="100%" height={240} />
                                                                    </Fragment>
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={3}>
                                                            <Paper className={fixedHeightPaper}>
                                                                {
                                                                    (data && data.getWorkCondPieChartData) ?
                                                                    <Fragment>
                                                                        <Typography component="h6" variant="subtitle1" align="left">
                                                                            공휴일 휴무
                                                                        </Typography>
                                                                        <SPieChart
                                                                            data={[
                                                                                ['',''],
                                                                                ['휴무', data.getWorkCondPieChartData.cnt_red_work_1],
                                                                                ['순번근무', data.getWorkCondPieChartData.cnt_red_work_2],
                                                                                ['근무', data.getWorkCondPieChartData.cnt_red_work_3]
                                                                            ]}
                                                                            loading={loading}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <Skeleton variant="rect" width="100%" height={240} />
                                                                    </Fragment>
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={3}>
                                                            <Paper className={fixedHeightPaper}>
                                                                {
                                                                    (data && data.getWorkCondPieChartData) ?
                                                                    <Fragment>
                                                                        <Typography component="h6" variant="subtitle1" align="left">
                                                                            평일야간 당직
                                                                        </Typography>
                                                                        <SPieChart
                                                                            data={[
                                                                                ['',''],
                                                                                ['예', data.getWorkCondPieChartData.cnt_night_work_flag_y],
                                                                                ['아니오', data.getWorkCondPieChartData.cnt_night_work_flag_n]
                                                                            ]}
                                                                            loading={loading}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <Skeleton variant="rect" width="100%" height={240} />
                                                                    </Fragment>
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={3}>
                                                            <Paper className={fixedHeightPaper}>
                                                                {
                                                                    (data && data.getWorkCondPieChartData) ?
                                                                    <Fragment>
                                                                        <Typography component="h6" variant="subtitle1" align="left">
                                                                            주말 당직
                                                                        </Typography>
                                                                        <SPieChart
                                                                            data={[
                                                                                ['',''],
                                                                                ['예', data.getWorkCondPieChartData.cnt_weekend_work_flag_y],
                                                                                ['아니오', data.getWorkCondPieChartData.cnt_weekend_work_flag_n]
                                                                            ]}
                                                                            loading={loading}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <Skeleton variant="rect" width="100%" height={240} />
                                                                    </Fragment>
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={3}>
                                                            <Paper className={fixedHeightPaper}>
                                                                {
                                                                    (data && data.getWorkCondPieChartData) ?
                                                                    <Fragment>
                                                                        <Typography component="h6" variant="subtitle1" align="left">
                                                                            평일야간 당직비 별도지급
                                                                        </Typography>
                                                                        <SPieChart
                                                                            data={[
                                                                                ['',''],
                                                                                ['예', data.getWorkCondPieChartData.cnt_night_pay_flag_y],
                                                                                ['아니오', data.getWorkCondPieChartData.cnt_night_pay_flag_n]
                                                                            ]}
                                                                            loading={loading}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <Skeleton variant="rect" width="100%" height={240} />
                                                                    </Fragment>
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={3}>
                                                            <Paper className={fixedHeightPaper}>
                                                                {
                                                                    (data && data.getWorkCondPieChartData) ?
                                                                    <Fragment>
                                                                        <Typography component="h6" variant="subtitle1" align="left">
                                                                            주말 당직비 별도지급
                                                                        </Typography>
                                                                        <SPieChart
                                                                            data={[
                                                                                ['',''],
                                                                                ['예', data.getWorkCondPieChartData.cnt_weekend_pay_flag_y],
                                                                                ['아니오', data.getWorkCondPieChartData.cnt_weekend_pay_flag_n]
                                                                            ]}
                                                                            loading={loading}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <Skeleton variant="rect" width="100%" height={240} />
                                                                    </Fragment>
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                    </Fragment>
                                                )
                                            }}
                                        </Query>
                                    </Grid>
                                </div>
                            </Paper>
                        </div>

                        <div>
                            <Paper>
                                <div className="hlink_ad" style={{paddingTop:'20px'}}>
                                    <Query
                                        query={HLINK_AD}
                                        variables={{
                                            spc_code: userProfile.u_spc_code,
                                            limit_cnt: 1
                                        }}
                                        fetchPolicy="network-only"
                                    >
                                        {({ data, loading, error }) => {
                                            if (loading) return <p></p>;
                                            if (error) return <p>{error.message}</p>;
                                            return (
                                                data.getHlinkAd && data.getHlinkAd.length > 0 && data.getHlinkAd[0].svc_cont_idx !== 0 &&
                                                <SHlinkADBox
                                                    data={data.getHlinkAd}
                                                    fixedHeightPaper={fixedHeightPaper}
                                                />
                                            )
                                        }}
                                    </Query>
                                </div>
                            </Paper>
                        </div>

                        <div style={{marginTop: "70px"}}>
                            <Typography component="div" gutterBottom style={{marginBottom:"10px"}}>
                                <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                    조건별 비교
                                </Typography>
                                <Typography component="span" variant="caption">
                                    전문과, 지역, 병원 타입에 따른 조건별 비교입니다
                                </Typography>
                            </Typography>
                            <Typography component="div" className="combo_4" style={{float:"left",marginTop:"0"}} gutterBottom>
                                <Typography component="h2" variant="h5" color="primary" style={{margin:"0"}}>
                                    <SFormSelectBox
                                        id="condition_type_kind"
                                        labelId="condition_type_kind_label"
                                        //labelText="술기에 따른 인상여부"
                                        selectedValue={filter_condition_type_kind}
                                        type="type_condition"
                                        width="150"
                                        change={this.handleChangeFilter}
                                    />
                                </Typography>
                            </Typography>
                            <Typography component="div" className="combo_1" style={{float: "right",marginTop:"-0"}} gutterBottom>
                                <SFormSelectBox
                                    id="condition_type_term"
                                    labelId="condition_type_term_label"
                                    labelText="기간"
                                    selectedValue={filter_condition_type_term}
                                    type="term"
                                    change={this.handleChangeFilter}
                                />
                            </Typography>
                            <div className="pagegraph type1">
                                <Grid container spacing={3}>
                                    <Query
                                        query={CONDITION_STACKED}
                                        variables={{
                                            spc_code: userProfile.u_spc_code,
                                            hop_loc_code: userProfile.u_zip_zone,
                                            org_type: org_type,
                                            period_type: filter_condition_type_term,
                                            cond_type: filter_condition_type_kind
                                        }}
                                        fetchPolicy="network-only"
                                    >
                                        {({ data, loading, error }) => {
                                            if (error) return <p>{error.message}</p>;

                                            return (
                                                <SLongStackedBarGraph
                                                    data={data ? data.getWorkCondStackedBarChartData : []}
                                                    fixedHeightPaper={fixedHeightPaper}
                                                    loopCount={3}
                                                    filterType={filter_condition_type_kind}
                                                    loading={loading}
                                                />
                                            )
                                        }}
                                    </Query>
                                </Grid>
                            </div>
                        </div>
                        <div style={{margin:"60px 0"}}>
                            <Typography component="div" style={{float: "left"}} gutterBottom>
                                <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                    조건별 추이
                                </Typography>
                                <Typography component="span" variant="caption">
                                    선생님이 해당하는 전문과, 지역, 병원 타입의  근무환경별 추이입니다
                                </Typography>
                            </Typography>
                            <div className="pagegraph type2">
                            <Grid container spacing={3}>
                                <Query
                                    query={CONDITION_MONTH_CHART}
                                    variables={{
                                        spc_code: userProfile.u_spc_code,
                                        hop_loc_code: userProfile.u_zip_zone,
                                        org_type: org_type,
                                        cond_type: filter_condition_type_kind
                                    }}
                                    fetchPolicy="network-only"
                                >
                                    {({ data, loading, error }) => {
                                        //if (loading) return null;
                                        if (error) return <p>{error.message}</p>;

                                        // //console.log("CONDITION_MONTH_CHART chart", data.getWorkCondMonthlyChartData)

                                        return (
                                            <SLongPercentAreaGraph
                                                data={data ? data.getWorkCondMonthlyChartData : []}
                                                fixedHeightPaper={fixedHeightPaper}
                                                loopCount={4}
                                                spc_code={userProfile.u_spc_code}
                                                hop_loc_code={userProfile.u_zip_zone}
                                                org_type={org_type}
                                                loading={loading}
                                                state_filter_value={filter_condition_type_kind}
                                            />
                                        )
                                    }}
                                </Query>
                            </Grid>
                        </div>
                        </div>
                    </Container>
                }
            </Fragment>
        );
    }
}

export default withStyles(useStyles)(S_Condition)