import React, {useState, useEffect, Fragment} from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {useStore} from "../../stores/StoreProvider";
import {useQuery} from "@apollo/react-hooks";
import {HOSPITAL_LIST} from "../../queries/location";
import _ from 'lodash';
import {observer} from "mobx-react";
import CommonFunction from "../../common/CommonFunction";

// function HospitalContent(props){
const HospitalContent = observer ((props) => {
    const store = useStore().LocationStore;
    const dataHospitalList = _.sortBy(store.hospitalMarkerList.concat(store.hospitalMarkerList2), ['hos_name']);
    //console.log('dataHospitalList', dataHospitalList);
    const {handleCenterMarker} = props;

    const emptyMsg = () => {
        let msg = "의료기관 정보가 없습니다.";
        if(store.zoomLevel > 4){
            msg = "의료기관 정보를 보려면 지도를 더 확대해 주세요.";
        }
        return msg;
    }

    return (
        <Fragment>

            <Grid container alignItems="center">
                <Grid item xs>
                    <Typography gutterBottom variant="h6">
                        의료기관 정보
                    </Typography>
                </Grid>
            </Grid>
            <Divider component="li"/>
            <List>
                {
                    // !(store.zoomLevel < 5 && store.drawFigure1 != null) ||
                    (dataHospitalList && dataHospitalList.length > 0 && store.zoomLevel < 5) ?
                    dataHospitalList.map((item) => {
                        return(
                            <div className="hsp-cont">
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Fragment>
                                                <Typography
                                                    component="p"
                                                    variant="h6"
                                                    color="textPrimary"
                                                    onClick={()=> { handleCenterMarker([item.pos_y, item.pos_x], item.hos_name); CommonFunction.pageview('입지분석', '의료기관 클릭');}}
                                                >
                                                    {item.hos_name}
                                                </Typography>
                                            </Fragment>
                                        }
                                        secondary={
                                            <Fragment>
                                                <Typography
                                                    className="hsp-moreinfo"
                                                    component="p"
                                                    variant="body2"
                                                    color="textPrimary"
                                                    onClick={()=> { handleCenterMarker([item.pos_y, item.pos_x], item.hos_name); CommonFunction.pageview('입지분석', '의료기관 클릭');}}
                                                >
                                                    <span className="subtxt addr">{item.addr}</span>
                                                </Typography>
                                                <Typography
                                                    className="hsp-moreinfo"
                                                    component="p"
                                                    variant="body2"
                                                    color="textPrimary"
                                                >
                                                    {
                                                        item.homepage &&
                                                        <p onClick={() => window.open(`${item.homepage}`, '_blank')}>
                                                            <span className="titxt">홈페이지</span>
                                                            <span className="subtxt homepage">{`${item.homepage}`}</span>
                                                        </p>
                                                    }
                                                </Typography>
                                                <Typography
                                                    className="hsp-moreinfo"
                                                    component="p"
                                                    variant="body2"
                                                    color="textPrimary"
                                                    onClick={()=> handleCenterMarker([item.pos_y, item.pos_x], item.hos_name)}
                                                >
                                                    <span className="titxt">개설일</span>
                                                    <span className="subtxt spaceline">{`${item.estb_date.substring(0, 4)}.${item.estb_date.substring(4, 6)}.${item.estb_date.substring(6, 8)}`}</span>
                                                    <span className="titxt">병상수</span>                                                    
                                                    <span className="subtxt">{`${item.bed}`}</span>
                                                </Typography>
                                                <Typography
                                                    className="hsp-moreinfo"
                                                    component="p"
                                                    variant="body2"
                                                    color="textPrimary"
                                                    onClick={()=> { handleCenterMarker([item.pos_y, item.pos_x], item.hos_name); CommonFunction.pageview('입지분석', '의료기관 클릭');}}
                                                >
                                                    <span className="subtxt">{item.tel}</span>
                                                </Typography>
                                            </Fragment>
                                        }
                                    />
                                </ListItem>
                            </div>
                        )
                    })
                    :
                    <ListItem>
                        <ListItemText>
                            <Typography
                                component="p"
                                variant="body2"
                                color="textPrimary"
                            >
                                {emptyMsg()}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                }
            </List>
        </Fragment>
    )
})

export default HospitalContent;