import React, { Fragment } from 'react';
import _ from "lodash";
import Swiper from 'swiper';
import { isMobileOnly } from 'react-device-detect';
import $ from 'jquery';
import CommonFunc from '../../../common/CommonFunction';

const News = (props) => {

    function onRendered(){
        if(props.content.length > 0){
            var swiper = new Swiper('#news_swiper', {
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 0,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    // when window width is <= 320px
                    310: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                    },
                    // when window width is <= 480px
                    560: {
                        slidesPerView: 2,
                        slidesPerGroup: 2
                    },
                    // when window width is <= 640px
                    720: {
                        slidesPerView: 3,
                        slidesPerGroup: 3
                    }
                }
            });
            //console.log('swiper', swiper);
            $('#news_swiper .swiper-wrapper').css('transform', 'translate3d(0px, 0px, 0px)');
        }
    }

    return (
        <div className="popchapter news">
            <div className="chaptit">
                <p className="tittxt">
                    <span className="diseasename">{props.ingred_name}</span> 관련 뉴스(<span>{props.content.length}</span>)
                </p>
            </div>
            <div className="swiper-container" id="news_swiper">
                <div className="swiper-wrapper">
                {
                    props.content.map((item, index) => {
                        return (
                            <div className="swiper-slide"
                                    key={index}
                                    onClick={() => {
                                        CommonFunc.pageview('처방정보', '뉴스', props.ingred_name); //Google Analytics
                                        let url = isMobileOnly ? `http://m.medigatenews.com/news/${item.publicId}` : `http://www.medigatenews.com/news/${item.publicId}`;
                                        window.open(url, '_blank');
                                    }}
                                    style={{cursor: "pointer"}}
                                >
                                <div className="article">
                                    <div className="photo" style={{background: `url(${item.displayPhotoId}) center center / cover no-repeat`}}></div>
                                    <p className="tittxt">{item.title}</p>
                                    <p className="subtit">{item.subtitle}</p>
                                    <p className="subtxt">{item.displayModifiedDate}</p>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
                <div class="swiper-button-next"></div>
				<div class="swiper-button-prev"></div>
            </div>
            <div ref={onRendered}></div>
        </div>
    )
}

export default News;