import React from 'react';
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert';
import HtmlConfirm from '../../../common/HtmlConfirm';

const options = [
    { value: '', label: '전체' },
    { value: 'N', label: '단일제' },
    { value: 'Y', label: '복합제' },
    { value: '2combi', label: '2제 병합요법' },
    { value: '3combi', label: '3제 병합요법' }
];

class FilterCombination extends React.PureComponent {
    state = {
        selectedOption: null,
        placeholder: "단일 / 병용 선택"
    };

    handleChange = selectedOption => {
        this.setState({ selectedOption: selectedOption });
        // //console.log(`Option selected:`, selectedOption, this.props.filter_place);

        if((selectedOption.value === "2combi" || selectedOption.value === "3combi") && this.props.filter_place === "IN"){
            confirmAlert({ customUI: ({ onClose }) => <HtmlConfirm title="처방옵션 변경" message={"'입원' 처방 옵션에서 병합 요법을 제공하지 않아<br/>외래 처방 옵션으로 자동 전환합니다."} onClose={onClose} />});
        }
        this.props.onChangeFilterCombination(selectedOption);
    };

    render() {
        const { selectedOption } = this.state;

        return (
            <Select
                value={selectedOption}
                onChange={this.handleChange}
                placeholder={this.state.placeholder}
                options={options}
                isSearchable={false}
                value={this.props.filter_combination_selectedOption}
                styles={{
                    option: base => ({
                        ...base,
                        borderBottom: `1px solid rgba(0,0,0,0.05)`,
                        height: '100%'
                    }),
                }}
            />
        )
    }
};

export default FilterCombination;