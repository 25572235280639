import gql from 'graphql-tag';

//for search-engine
export const KCD_LIST = gql`
	query select($kcd_code_arr: [String], $order: String) {
		getKcdList(kcd_code_arr: $kcd_code_arr, order: $order) {
			kcd_code
			kcd_kor
			kcd_eng
		}
	}
`;

export const CTG2_LIST = gql`
	query select($kcd_code_arr: [String], $order: String) {
		getCtg2List(kcd_code_arr: $kcd_code_arr, order: $order) {
			ctg_code_grp
			tot_prescript
			tot_use
			ctg_name
		},
		getCtg3List(kcd_code_arr: $kcd_code_arr, order: $order) {
			ctg_code_grp
			ctg_code
			tot_prescript
			tot_use
			ctg_name
			place_in_cnt
			place_out_cnt
		}
	}
`;

export const CTG3_LIST = gql`
	query select($kcd_code_arr: [String], $dgsbjt_cd_arr: [String], $dgsbjt_dtl_cd_arr: [String], $agg_arr: [String], $sex_tp_cd_arr: [String], $cl_cd_arr: [String], $bed_arr: [String], $sido_cd_arr: [String], $order: String) {
		getCtg3List(kcd_code_arr: $kcd_code_arr, dgsbjt_cd_arr: $dgsbjt_cd_arr, dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr, agg_arr: $agg_arr, sex_tp_cd_arr: $sex_tp_cd_arr, cl_cd_arr: $cl_cd_arr, bed_arr: $bed_arr, sido_cd_arr: $sido_cd_arr, order: $order) {
			ctg_code_grp
			ctg_code
			tot_prescript
			tot_use
			ctg_name
		}
	}
`;

export const PRESCRIPTION_LIST = gql`
	query select(
		$kcd_code_arr: [String],
		$ctg_code3: String,
		$dgsbjt_cd_arr: [String],
		$dgsbjt_dtl_cd_arr: [String],
		$agg_arr: [String],
		$sex_tp_cd_arr: [String],
		$cl_cd_arr: [String],
		$bed_arr: [String],
		$sido_cd_arr: [String],
		$atc_code: String,
		$method: String,
		$u_id: String,
		$page_num: Int,
		$row_count: Int,
		$order: String,
		$place: String,
		$combi_flag: String,
		$stat_year: Int,
		$ingred_code_grp_arr: [String],
        $ingred_combi_flag: String
	) {
		getPrescriptionList(
			kcd_code_arr: $kcd_code_arr,
			ctg_code3: $ctg_code3,
			dgsbjt_cd_arr: $dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
			agg_arr: $agg_arr,
			sex_tp_cd_arr: $sex_tp_cd_arr,
			cl_cd_arr: $cl_cd_arr,
			bed_arr: $bed_arr,
			sido_cd_arr: $sido_cd_arr,
			atc_code: $atc_code,
			method: $method
			u_id: $u_id,
			page_num: $page_num,
			row_count: $row_count,
			order: $order,
			place: $place,
			combi_flag: $combi_flag,
			stat_year: $stat_year,
			ingred_code_grp_arr: $ingred_code_grp_arr,
			ingred_combi_flag: $ingred_combi_flag
		) {
			ingred_code_grp
			combi_flag
			ingred_kor
			ingred_eng
			atc_name
			method
			method_kor
			tot_prescript
			tot_use
			f_tot_prescript
			f_tot_use
			prescript_share_per
			use_share_per
			prescript_share_per_round5
			use_share_per_round5
			use_prescript_avg
			total_like_cnt
			like_flag
			tot_cnt
		}
	}
`;

export const PRODUCT_LIST = gql`
	query select($ingred_code_grp: String, $method: String, $ingred_combi_flag: String, $u_id: String, $u_spc_code: String, $u_wtp_code: String, $u_hospital_group_code: String, $u_birthday: String, $u_sex: String, $u_zip_zone: String) {
		getPdtList(ingred_code_grp: $ingred_code_grp, method: $method, ingred_combi_flag: $ingred_combi_flag, u_id: $u_id, u_spc_code: $u_spc_code, u_wtp_code: $u_wtp_code, u_hospital_group_code: $u_hospital_group_code, u_birthday: $u_birthday, u_sex: $u_sex, u_zip_zone: $u_zip_zone) {
			pdt_type
			pdt_code
			ingred_code_grp
			unit_amt_code
			method
			combi_flag
			cpn_name
			pdt_name
			insurance
			unit_amt
			pmt_code
			template_type
			link
			banner_info_idx
			banner_pdt_idx
		}
	}
`;

export const FILTERATCCATEGORY_LIST = gql`
	query select($kcd_code_arr: [String], $ctg_code3: String, $dgsbjt_cd_arr: [String], $dgsbjt_dtl_cd_arr: [String], $agg_arr: [String], $sex_tp_cd_arr: [String], $cl_cd_arr: [String], $bed_arr: [String], $sido_cd_arr: [String], $place: String, $atc_code: String, $combi_flag: String, $method: String, $stat_year: Int) {
		getAtcCodeList(
			kcd_code_arr: $kcd_code_arr,
			ctg_code3: $ctg_code3,
			dgsbjt_cd_arr: $dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
			agg_arr: $agg_arr,
			sex_tp_cd_arr: $sex_tp_cd_arr,
			cl_cd_arr: $cl_cd_arr,
			bed_arr: $bed_arr,
			sido_cd_arr: $sido_cd_arr,
			place: $place,
			atc_code: $atc_code,
			combi_flag: $combi_flag,
			method: $method,
			stat_year: $stat_year
		) {
			value: atc_code
			label: atc_name
		}
	}
`;

export const FILTERMETHOD_LIST = gql`
	query select($kcd_code_arr: [String], $ctg_code3: String, $dgsbjt_cd_arr: [String], $dgsbjt_dtl_cd_arr: [String], $agg_arr: [String], $sex_tp_cd_arr: [String], $cl_cd_arr: [String], $bed_arr: [String], $sido_cd_arr: [String], $place: String, $atc_code: String, $combi_flag: String, $method: String, $stat_year: Int) {
		getMethodList(
			kcd_code_arr: $kcd_code_arr,
			ctg_code3: $ctg_code3,
			dgsbjt_cd_arr: $dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
			agg_arr: $agg_arr,
			sex_tp_cd_arr: $sex_tp_cd_arr,
			cl_cd_arr: $cl_cd_arr,
			bed_arr: $bed_arr,
			sido_cd_arr: $sido_cd_arr,
			place: $place,
			atc_code: $atc_code,
			combi_flag: $combi_flag,
			method: $method,
			stat_year: $stat_year
		) {
			value: method
			label: method_kor
		}
	}
`;

export const FILTERSTATYEAR_LIST = gql`
	query select {
		getStatYearList {
			value: stat_year
			label: stat_year
		}
	}
`;

export const MY_FAVORITE_LIST = gql`
	query select($u_id: String, $order: String, $reload_flag: Int) {
		getMypageLB(u_id: $u_id, order: $order, reload_flag: $reload_flag) {
			ingred_code_grp
			method
			combi_flag
			ctg_code_grp
			ctg_code
			ingred_kor
			ingred_eng
			kcd_code_arr
			kcd_kor_arr
			kcd_eng_arr
		}
	}
`;

export const STAT_YEAR_MAX = gql`
	query select {
		getStatYearOne {
			stat_year
		}
	}	
`;

export const INGRED_GM_NEW_LIST = gql`
	query select($ctg_code3: String, $page_num: Int, $atc_code: String, $method: String, $combi_flag: String, $kcd_code_arr: [String], $place: String, $stat_year: Int) {
		getIngredGmNewList(ctg_code3: $ctg_code3, page_num: $page_num, atc_code: $atc_code, method: $method, combi_flag: $combi_flag, kcd_code_arr: $kcd_code_arr, place: $place, stat_year: $stat_year) {
			ingred_code_grp
			combi_flag
			method
			ingred_kor
			ingred_eng
			insu_date
			atc_name
			tot_cnt
			method_kor
		}
	}
`;

export const SHARE_RATE_CHART = gql`
	query select($kcd_code_arr: [String], $ctg_code3: String, $place: String) {
		getShareRateOfChange(kcd_code_arr: $kcd_code_arr, ctg_code3: $ctg_code3, place: $place){
			max_year
			year_length
			chart_data{
				year
				prescript_share_per1
				annotation_1: prescript_share_per1
				prescript_share_per2
				annotation_2: prescript_share_per2
				prescript_share_per3
				annotation_3: prescript_share_per3
				ingred_eng1
				ingred_eng2
				ingred_eng3
			}
		}
	}

`;
//==============================//
// MUTATE
//==============================//

export const LIKE_BOOKMARK = gql`
	mutation insert($u_id: String, $ingred_code_grp: String, $ingred_combi_flag: String, $method: String, $kcd_code_arr: [String], $ctg_code_grp: String, $ctg_code3: String) {
		createLikeBookmark(
			u_id: $u_id,
			ingred_code_grp: $ingred_code_grp,
			ingred_combi_flag: $ingred_combi_flag,
			method: $method,
			kcd_code_arr: $kcd_code_arr,
			ctg_code_grp: $ctg_code_grp,
			ctg_code3: $ctg_code3
		) {
			result
		}
	}
`;

export const UNLIKE_BOOKMARK = gql`
	mutation delete($likeBookmark: [LIKEBOOKMARK_INUPT]) {
		removeLikeBookmark(
			likeBookmark: $likeBookmark
		) {
			result
		}
	}
`;