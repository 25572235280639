import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const LocationTheme = createMuiTheme({
    palette: {
        primary: {
            light: "#ffc000",
            main: "#e6a33f",
            dark: "#916c00",
            text: "#3432ff",
            gray: "#f2f2f2",
        },
        red:"#ff1f19",
        green:"#33ff3b",
        blue:"#3432ff",
    },
    spacing: 4,
});

const GlobalThemeProvider = ({ children }) => (
    <ThemeProvider theme={LocationTheme}>{children}</ThemeProvider>
);

export default GlobalThemeProvider;