import React, {Fragment} from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import $ from 'jquery';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import { UNLIKE_BOOKMARK } from '../../../queries/prescript/prescription';
import CommonFunc from '../../../common/CommonFunction';
import HtmlConfirm from '../../../common/HtmlConfirm';

const FavoriteIngred = ({data, favorite_edit_mode, keyword, refetch, client, onUpdateIngredEditMode}) => {
    //console.log('favorite_edit_mode', favorite_edit_mode);

    const [expanded, setExpanded] = React.useState('');
    const [ingred_code_list, setIngred_code_list] = React.useState([]);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function removeKcd(kcd_element_id, ingred_code_grp, combi_flag, method, kcd_code){
        const u_id = CommonFunc.getUserId();
        const values = [{
            u_id: u_id,
            ingred_code_grp: ingred_code_grp,
            ingred_combi_flag: combi_flag,
            method: method,
            kcd_code: kcd_code
        }]
        //console.log('values', values, client);

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <HtmlConfirm
                        title={'매칭 상병 삭제'}
                        message={`해당 상병을 삭제하시겠습니까?`}
                        onClose={onClose}
                        confirmCallback={() => {
                            client.mutate({
                                mutation: UNLIKE_BOOKMARK,
                                variables: {
                                    likeBookmark: values
                                }
                            })
                            .then((res) => {
                                $(`#${kcd_element_id}`).fadeOut();
                                //refetch();
                            })
                            .catch((error) => {
                                //console.log(error.message);
                            });
                        }}
                    />
                )
            }
        })
    }

    function removeIngred(){
        const u_id = CommonFunc.getUserId();
        //console.log('ingred_code_list', ingred_code_list)
        if(ingred_code_list.length === 0){
            onUpdateIngredEditMode('EDIT');
            confirmAlert({ customUI: ({ onClose }) => <HtmlConfirm title="삭제성분 선택" message={"삭제할 성분을 선택하세요."} onClose={onClose} />});
            return;
        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <HtmlConfirm
                        title={'저장한 성분 삭제'}
                        message={`선택하신 성분을 모두 삭제하시겠습니까?`}
                        onClose={onClose}
                        confirmCallback={() => {
                            client.mutate({
                                mutation: UNLIKE_BOOKMARK,
                                variables: {
                                    likeBookmark: ingred_code_list
                                }
                            })
                            .then((res) => {
                                onUpdateIngredEditMode('REMOVE_COMPLETED');
                                refetch();
                            })
                            .catch((error) => {
                                //console.log(error.message);
                            });
                        }}
                    />
                )
            }
        })

    }

    function handleIngredSelect(e, item){
        //const value = e.target.value;
        let values = ingred_code_list || [];
        //console.log('values', values);
        const u_id = CommonFunc.getUserId();

        const item_value = {
            u_id: u_id,
            ingred_code_grp: item.ingred_code_grp,
            ingred_combi_flag: item.combi_flag,
            method: item.method,
            kcd_code: null
        }

        const item_key = `#ingred_chk_${item.ingred_code_grp}_${item.combi_flag}_${item.method}`;
        //console.log('handleIngredSelect', item_key);
        if(e.target.checked) {
            values = [...values, item_value];
            $(item_key).find('.ckbox').addClass('on');
        }
        else {
            values = values.filter(ingred => !(ingred.ingred_code_grp === item_value.ingred_code_grp && ingred.combi_flag === item_value.combi_flag && ingred.method === item_value.method));
            $(item_key).find('.ckbox').removeClass('on');
        }
        setIngred_code_list(values);
    }

    /*
    function displayRightBtn(){
        if(favorite_edit_mode){
            $('.MuiIconButton-root').hide()
        }
        else{
            $('.MuiIconButton-root').show()
        }
    }
    */

    return (
        <Fragment>
        {
            (data.getMypageLB && data.getMypageLB.length > 0) &&
            data.getMypageLB.map((item, index) => {
                const ingred_code_grp = item.ingred_code_grp;
                const kcd_kor_arr = item.kcd_kor_arr.split('#');
                const kcd_code_arr = item.kcd_code_arr.split('#');
                const ingred_element_id = `panel${(index + 1)}bh-header`;

                if(favorite_edit_mode === 'REMOVE'){
                    //console.log('INGRED REMOVE called')
                    removeIngred();
                }

                //키워드 검색
                var ingred_display_flag = true;
                if(!_.isEmpty(keyword)){
                    keyword = keyword.toLowerCase()
                    ingred_display_flag = (item.ingred_eng.toLowerCase().indexOf(keyword) === -1 && item.ingred_kor.toLowerCase().indexOf(keyword) === -1 && item.kcd_code_arr.toLowerCase().indexOf(keyword) === -1 && item.kcd_kor_arr.toLowerCase().indexOf(keyword) === -1 && item.kcd_eng_arr.toLowerCase().indexOf(keyword) === -1) ? false : true;
                    //console.log('ingred_display_flag', ingred_display_flag);
                }

                return (
                    <li className="druglist">
                        {
                            !_.isEmpty(favorite_edit_mode) &&
                            <div className="drugtit td01">{item.ingred_eng}
                                <div className="checkbox" id={`ingred_chk_${item.ingred_code_grp}_${item.combi_flag}_${item.method}`}>
                                    <label className="label_txt">
                                        <span className='ckbox'>
                                            <input type="checkbox" onChange={(e)=>handleIngredSelect(e, item)}/>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        }

                        {
                            _.isEmpty(favorite_edit_mode) && ingred_display_flag &&
                            <ExpansionPanel key={index} expanded={expanded === `panel${(index + 1)}`} onChange={handleChange(`panel${(index + 1)}`)}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${(index + 1)}bh-content`}
                                    id={ingred_element_id}
                                    //style={{padding: '0 24px 0 0'}}
                                >
                                    <Typography>
                                        <div className="drugtit td01">{item.ingred_eng}</div>
                                    </Typography>
                                </ExpansionPanelSummary>
                                {
                                    <ExpansionPanelDetails style={{display:'block', padding: '0px'}}>
                                    {
                                        kcd_kor_arr.map((kcd_kor, subidx) => {
                                            const kcd_code = kcd_code_arr[subidx];
                                            const kcd_element_id = `kcd_${ingred_code_grp}_${kcd_code}`;

                                            return (
                                                <Typography
                                                    key={subidx}
                                                    className="diseasetit"
                                                    id={kcd_element_id}
                                                    //onClick={()=> { //console.log('select sub ctg');}}
                                                >
                                                    <div className="detail td02">
                                                        <p className="value td01">{kcd_code}</p>
                                                        <p className="value td02">{CommonFunc.getKcdNameConv(kcd_kor)}</p>
                                                    </div>
                                                    <button className="mdc-fab mdc-fab--extended" onClick={()=>removeKcd(kcd_element_id, ingred_code_grp, item.combi_flag, item.method, kcd_code)}>
                                                        <i className="material-icons mdc-icon-button__icon mdc-icon-button__icon">delete</i>
                                                    </button>
                                                </Typography>
                                            )
                                        })
                                    }
                                    </ExpansionPanelDetails>
                                }
                            </ExpansionPanel>
                        }
                    </li>
                )
            })
        }
        </Fragment>
    );
}

export default FavoriteIngred;