import React from 'react';
import Select from 'react-select';
import { Query } from 'react-apollo';

import { FILTERMETHOD_LIST } from '../../../queries/prescript/prescription';

class FilterMethod extends React.PureComponent {
    state = {
        selectedOption: null,
        placeholder: "투여 방법"
    };

    handleChange = selectedOption => {
        this.setState({ selectedOption: selectedOption });
        //console.log(`Option selected:`, selectedOption);

        this.props.onChangeFilterMethod(selectedOption);
    };

    render() {
        const { selectedOption } = this.state;

        return (
            <Query query={FILTERMETHOD_LIST}
                variables={{
                    kcd_code_arr: this.props.kcd_code_arr,
                    ctg_code3: this.props.ctg_code3,
                    dgsbjt_cd_arr: this.props.dgsbjt_cd_arr,
                    dgsbjt_dtl_cd_arr: this.props.dgsbjt_dtl_cd_arr,
                    agg_arr: this.props.agg_arr,
                    sex_tp_cd_arr: this.props.sex_tp_cd_arr,
                    cl_cd_arr: this.props.cl_cd_arr,
                    bed_arr: this.props.bed_arr,
                    sido_cd_arr: this.props.sido_cd_arr,
                    place: this.props.filter_place,
                    atc_code: this.props.filter_category,
                    combi_flag: this.props.filter_combination,
                    method: this.props.filter_method,
                    stat_year: this.props.filter_stat_year
                }}
                // fetchPolicy={"cache-and-network"}
            >
                {({ data, loading, error }) => {
                    if (loading) return <p></p>
                    if (error) return <p>{error.message}</p>;

                    const getMethodList = [{ value: "", label: "전체" }].concat(data.getMethodList)
                    //console.log('FILTERMETHOD_LIST data', data);

                    return (
                        <Select
                            placeholder={this.state.placeholder}
                            options={getMethodList}
                            isSearchable={false}
                            onChange={this.handleChange}
                            value={this.props.filter_method_selectedOption}
                            styles={{
                                option: base => ({
                                    ...base,
                                    borderBottom: `1px solid rgba(0,0,0,0.05)`,
                                    height: '100%'
                                }),
                            }}
                        />
                    )
                }}
            </Query>
        )
    }
};

export default FilterMethod;