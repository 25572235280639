import React, {useEffect, useState, useContext} from 'react';
import {observer, useObserver} from "mobx-react";
import {useLazyQuery} from "@apollo/react-hooks";
import {STAT_CLINIC_OPEN_CLOSE, STAT_HOSPITAL} from "../../../queries/location";
import {Chart} from "react-google-charts";
import CommonFunc from '../../../common/CommonFunction';
import {useStore} from "../../../stores/StoreProvider";
import _ from 'lodash';
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "../../style/common";
import {SIDO_CODE, SPC_CODE, SBJ_CODE, CHART } from '../../../common/constants'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    //disablePortal: true,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        },
    },
};

const dateTypes = [
    { code: 'MONTHLY', name: '최근 1년 누적 (의원급)'},
    { code: 'YEARLY', name: '최근 10년 누적 (의원급)'},
];

const accumulateFlags = [
    { code: false, name: '개업/폐업'},
    { code: true, name: '증감누적'},
];

const StatClinicOpenClose = observer((props) => {
    const store = useStore().LocationStore;
    const classes = useStyles();

    const [hosData, setHosData] = React.useState(null);
    const [getHosData, {loading:hosDataLoading, error:hosDataError, data:hosEndpointData}] = useLazyQuery(STAT_HOSPITAL);

    const [getOpenCloseData, {loading:openCloseDataLoading, error:openCloseDataError, data:openCloseEndpointData}] = useLazyQuery(STAT_CLINIC_OPEN_CLOSE);
    const [dateType, setDateType] = React.useState('MONTHLY');          //{월간, 연간}
    const [accumulateFlag, setAccumulateFlag] = React.useState(false);  //{개업/폐업, 증감누적}

    //의원 누적
    const [monthlyPeriodAllData, setMonthlyPeriodAllData] = React.useState(null);    //monthly 개폐업 총합 데이터
    const [yearlyPeriodAllData, setYearlyPeriodAllData] = React.useState(null);      //yearly 개폐업 총합 데이터
    //개폐업
    const [monthlyPeriodData, setMonthlyPeriodData] = React.useState(null);          //monthly 개폐업 데이터
    const [monthlyAccumulateData, setMonthlyAccumulateData] = React.useState(null);  //monthly 개폐업 누적 데이터

    //검색 과목
    const [spcNames, setSpcNames] = React.useState(null);

    const getSpcLabel = (spcCodeArr) => {
        let label = '';
        spcCodeArr.map((code, index) => {
            // if(index > 0) label += '&#160;&#160;&#160;';
            if(index > 0) label += '&nbsp;&nbsp;&nbsp;';
            let filteredCode = _.filter(SPC_CODE, {code: code});
            label += filteredCode.length > 0 ? filteredCode[0].label : '';
        })
        return label;
    }

    useEffect(() => {
        CommonFunc.pageview('입지분석', '통계-의료기관');
    }, []);

    useEffect(() => {
        const spcNames = getSpcLabel(store.searchSpcArr);
        setSpcNames(spcNames);
    }, [store.searchSpcArr]);

    useEffect(() => {
        getHosData({ variables: { hos_code_arr: props.hosCodeArr }});
    }, [props.hosCodeArr]);

    useEffect(() => {
        //console.log('hosDataLoading', hosDataLoading, hosEndpointData);
        if (!hosDataLoading && hosEndpointData && monthlyPeriodAllData) {
            const title = [['', '', {role: 'style'}, {role: 'annotation'}]];
            let values = [];
            let totalCount = 0;
            hosEndpointData.getStatHospital.map((item, index) => {
                const selectedOrg = store.searchOrgArr.length === 0 || item.code === 'TOTAL' ||  _.indexOf(store.searchOrgArr, item.code) !== -1;
                if(selectedOrg){
                    let count = 0;
                    if(item.code === '31'){
                        count = item.value;
                        values.push([item.label, count, (index === 0 ? 'color: #505050' : ''), count]);
                        /*
                        if(store.searchOrgArr.length === 0 || _.indexOf(store.searchOrgArr, '31') !== -1){
                            count = monthlyPeriodAllData[monthlyPeriodAllData.length-1][1]; //의원은 가장 최근의 월간누적데이터로 적용
                            values.push([item.label, count, (index === 0 ? 'color: #505050' : ''), count]);
                        }
                        else{
                            count = 0;
                            values.push([item.label, count, (index === 0 ? 'color: #505050' : ''), count]);
                        }
                        */
                    }
                    else{
                        if(index > 0) {
                            count = item.value;
                        }
                        values.push([item.label, count, (index === 0 ? 'color: #505050' : ''), count]);
                    }
                    totalCount += count;
                }
            })
            //console.log('values', values)

            //Set Total Count
            values[0][1] = totalCount;
            values[0][3] = totalCount;

            const chartData = title.concat(values);
            setHosData(chartData);
        }
    },[hosEndpointData, hosDataLoading, monthlyPeriodAllData]);

    useEffect(() => {
        getOpenCloseData({ variables: { polygon: props.polygon, spc_code_arr: store.searchSpcArr }});
    }, [props.polygon, store.searchSpcArr]);

    useEffect(() => {
        if(!openCloseDataLoading && openCloseEndpointData){
            const stat12MonthsPrevCount = openCloseEndpointData.getStatClinicOpenClose.stat_12_months_prev_count;
            const stat12YearsPrevCount = openCloseEndpointData.getStatClinicOpenClose.stat_10_years_prev_count;

            let monthlyPeriodValues = [];      //monthly 개폐업 데이터
            let monthlyAccumulateValues = [];  //monthly 개폐업 누적 데이터
            let monthlyPeriodAllValues = [];   //monthly 개폐업 총합 누적 데이터
            let sum = 0;
            openCloseEndpointData.getStatClinicOpenClose.stat_12_months.map((item, index) => {
                sum += Number(item.value_open) + Number(item.value_close) * -1;
                monthlyPeriodValues.push([item.date, Number(item.value_open), item.value_open, Number(item.value_close) * -1, item.value_close]);
                monthlyAccumulateValues.push([item.date, sum, sum]);
                monthlyPeriodAllValues.push([item.date, sum + stat12MonthsPrevCount, sum + stat12MonthsPrevCount]);
            })
            setMonthlyPeriodData([['', '개업', { role: 'annotation' }, '폐업', { role: 'annotation' }]].concat(monthlyPeriodValues));
            setMonthlyAccumulateData([['', '', { role: 'annotation' }]].concat(monthlyAccumulateValues));
            setMonthlyPeriodAllData([['', '', { role: 'annotation' }]].concat(monthlyPeriodAllValues));

            let yearlyPeriodAllValues = [];    //yearly 개폐업 총합 누적 데이터
            sum = 0;
            openCloseEndpointData.getStatClinicOpenClose.stat_10_years.map((item, index) => {
                sum += Number(item.value_open) + Number(item.value_close) * -1;
                yearlyPeriodAllValues.push([item.date, sum + stat12YearsPrevCount, sum + stat12YearsPrevCount]);
            })
            setYearlyPeriodAllData([['', '', { role: 'annotation' }]].concat(yearlyPeriodAllValues));
        }
    },[openCloseEndpointData, openCloseDataLoading]);

    const handleAccumulateFlagChange = (event) => {
        setAccumulateFlag(event.target.value);
    }

    const handleDateTypeChange = (event) => {
        setDateType(event.target.value);
    }

    const filterDate = (dateType, MenuProps) => {
        return (
            <FormControl>
                <Select
                    value={dateType}
                    onChange={handleDateTypeChange}
                    MenuProps={MenuProps}
                >
                    {
                        dateTypes.map((item, index)=>
                            <MenuItem value={item.code}>{item.name}</MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        )
    }

    const filterOpenClose = (accumulateFlag, handleAccumulateFlagChange, MenuProps) => {
        return (
            <FormControl>
                <Select
                    value={accumulateFlag}
                    onChange={handleAccumulateFlagChange}
                    MenuProps={MenuProps}
                >
                    {
                        accumulateFlags.map((item, index)=>
                            <MenuItem value={item.code}>{item.name}</MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        )
    }

    return(
        <Grid>
            { (hosDataLoading || openCloseDataLoading) &&
                <Backdrop className={classes.backdrop} open={openCloseDataLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }

            <Grid item>
                <paper className="notice specialty">
                    <Typography className="txtinfo">
                        <span className="desc">선택된 전문과&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;</span>
                        <span dangerouslySetInnerHTML={{__html: spcNames}} />
                    </Typography>
                </paper>
            </Grid>
            {/* 의원 누적 */}
            {
                hosData &&
                <Paper className="paper-chart">
                    <Paper className="titxt">
                        <Typography>의료 기관 현황</Typography>
                    </Paper>
                    <Chart
                        chartType='ColumnChart'
                        width='100%'
                        height='200px'
                        data={hosData}
                        options={{
                            bar: {groupWidth: CHART.barWidth},
                            legend: { position: 'none' }
                        }}
                        loader={<div>Loading Chart</div>}
                    />
                </Paper>
            }

            <Grid container xs={12}>
                <Grid item xs={6} style={{position:"relative",paddingRight:"10px"}}>

                    <Paper>
                        {/* 월별 */}
                        {
                            dateType === 'MONTHLY' && monthlyPeriodAllData &&
                            <Grid item className="paper-chart">
                                <Paper className="titxt">
                                    <Typography>의원 누적(월간)</Typography>
                                </Paper>
                                <Paper className="combobox type1">
                                    {
                                        filterDate(dateType, MenuProps)
                                    }
                                </Paper>
                                <Chart
                                    chartType="LineChart"
                                    width="100%"
                                    height="200px"
                                    data={monthlyPeriodAllData}
                                    options={{
                                        //title: '의원 누적(월간)',
                                        legend: { position: 'none' },
                                    }}
                                    loader={<div>Loading Chart</div>}
                                />
                            </Grid>
                        }

                        {/* 연도별 */}
                        {
                            dateType === 'YEARLY' && yearlyPeriodAllData &&
                            <Grid item className="paper-chart">
                                <Paper className="titxt">
                                    <Typography>의원 누적(연간)</Typography>
                                </Paper>
                                <Paper className="combobox type1">
                                    {
                                        filterDate(dateType, MenuProps)
                                    }
                                </Paper>
                                <Chart
                                    chartType="LineChart"
                                    width="100%"
                                    height="200px"
                                    data={yearlyPeriodAllData}
                                    options={{
                                        //title: '의원 누적(연간)',
                                        legend: { position: 'none' },
                                    }}
                                    loader={<div>Loading Chart</div>}
                                />
                            </Grid>
                        }
                    </Paper>
                </Grid>
                {/* 개폐업 의원 */}
                <Grid item xs={6} style={{position:"relative",paddingLeft:"10px"}}>
                    <Paper>
                        {/* 개업/폐업 */}
                        {
                            accumulateFlag === false && monthlyPeriodData &&
                            <Grid item className="paper-chart">
                                <Paper className="titxt">
                                    <Typography>의원 월별 개폐업</Typography>
                                </Paper>
                                <Paper className="combobox type1">
                                    {
                                        filterOpenClose(accumulateFlag, handleAccumulateFlagChange, MenuProps)
                                    }
                                </Paper>
                                <Chart
                                    chartType="LineChart"
                                    width="100%"
                                    height="200px"
                                    data={monthlyPeriodData}
                                    options={{
                                        //title: '월별 개폐업',
                                        legend: { position: 'none' },
                                    }}
                                    loader={<div>Loading Chart</div>}
                                />
                            </Grid>
                        }

                        {/* 증감누적 */}
                        {
                            accumulateFlag === true && monthlyAccumulateData &&
                            <Grid item className="paper-chart">
                                <Paper className="titxt">
                                    <Typography>월별 증감누적</Typography>
                                </Paper>
                                <Paper className="combobox type1">
                                    {
                                        filterOpenClose(accumulateFlag, handleAccumulateFlagChange, MenuProps)
                                    }
                                </Paper>
                                <Chart
                                    chartType="LineChart"
                                    width="100%"
                                    height="200px"
                                    data={monthlyAccumulateData}
                                    options={{
                                        //title: '월별 증감누적',
                                        legend: { position: 'none' },
                                    }}
                                    loader={<div>Loading Chart</div>}
                                />
                            </Grid>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
})

export default StatClinicOpenClose;
