import React, {useEffect} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import useStyles from "../../style/common";
import {useStore} from "../../../stores/StoreProvider";
import {useLazyQuery} from "@apollo/react-hooks";
import {
    REGION_LIST,
    STAT_HIRA,
    STAT_HIRA_SGG,
    STAT_SGIS_SUMMARY
} from "../../../queries/location";
import _ from 'lodash';
import {Chart} from "react-google-charts";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CommonFunc from '../../../common/CommonFunction';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {SIDO_CODE, SPC_CODE, SBJ_CODE, CHART } from '../../../common/constants'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    //disablePortal: true,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        },
    },
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        //fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const STAT_TYPES = [
    { code: 'patient_cnt', label: '환자수' },
    { code: 'treat_cnt', label: '처방건수' },
    { code: 'amount', label: '처방액' }
]

const STAT_PORTIONS = [
    { code: 'amount_per_treat', label: '건당 처방액' },
    { code: 'treat_per_patient', label: '환자당 처방건수' },
    { code: 'amount_per_patient', label: '환자당 처방액' }
]

const defaultStatType = 'patient_cnt';
const defaultStatPortion = 'amount_per_treat';

const StatHira = (props) => {
    const store = useStore().LocationStore;
    const classes = useStyles();

    // 질환별 처방정보
    const [trendData, setTrendData] = React.useState(null);
    const [diseaseList, setDiseaseList] = React.useState(null);
    const [sortedDiseaseList, setSortedDiseaseList] = React.useState(null);
    const [diseaseSorter, setDiseaseSorter] = React.useState(defaultStatType);
    const [getTrendData, {loading:endpointTrendLoading, error:endpointTrendError, data:endpointTrendData}] = useLazyQuery(STAT_HIRA);

    // 시군구별 주요지표
    const [sggData, setSggData] = React.useState(null);
    const [getSggData, {loading:endpointSggLoading, error:endpointSggError, data:endpointSggData}] = useLazyQuery(STAT_HIRA_SGG);

    // 시군구 조회
    const [regionList, setRegionList] = React.useState(null);
    const [getRegionList, { loading: loadingRegionList, error: errorRegionList, data: endpointRegionList }] = useLazyQuery(REGION_LIST);

    //-- 상단의 검색 조건
    const [sidoCode, setSidoCode] = React.useState(props.locationInfo.sido_code); //시도
    const [sggCode, setSggCode] = React.useState(props.locationInfo.sgg_code);    //시군구 코드
    const [sggName, setSggName] = React.useState(props.locationInfo.sgg_name);    //시군구명
    const [sggMsg, setSggMsg] = React.useState(null);    //시군구 입력 메시지
    const [spcCodeArr, setSpcCodeArr] = React.useState(_.map(store.searchSpcArr));       //진료과
    const [spcLabel, setSpcLabel] = React.useState('');       //전문과목
    const [page, setPage] = React.useState(1);              //더보기 페이지 번호

    const [statType, setStatType] = React.useState(defaultStatType);            //선택한 통계 항목
    const [kcdKorArr, setKcdKorArr] = React.useState(null);           //상병 목록
    const [kcdKor, setKcdKor] = React.useState(null);                 //선택한 상병명

    //-- 하단의 검색 조건
    const [sido2Code, setSido2Code] = React.useState(props.locationInfo.sido_code); //시도
    const [stat2Type, setStat2Type] = React.useState(defaultStatType);              //선택한 통계 항목
    const [stat2Portion, setStat2Portion] = React.useState(defaultStatPortion);     //선택한 통계 항목
    const [dataCompare, setDataCompare] = React.useState(false);     //2개 영역 지정 여부
    const [hiraDate, setHiraDate] = React.useState('');     //심평원 기준일

    //---------------- 상단의 검색 조건 처리 ----------------//
    const handleSido = (event) => {
        //console.log('selSidoCode', event.target.value);
        setSidoCode(event.target.value);
        setSggMsg('시군구를 선택해 주세요.')
    }

    const handleSgg = (event) => {
        setSggCode(event.target.value);
        setSggMsg(null)
        //시군구명
        const sggInfos = _.filter(regionList, {region_code: event.target.value});
        if(sggInfos && sggInfos.length > 0){
            setSggName(sggInfos[0].region_name);
        }
    }

    const handleSpc = (event) => {
        //console.log('spcCodeArr', event.target.value);
        if(event.target.value.length === 0){
            alert('진료과 1개 이상 선택해 주세요.')
        }
        else{
            setSpcCodeArr(event.target.value);
        }
    }

    const getSpcLabel = (spcCodeArr) => {
        let label = '';
        spcCodeArr.map((code, index) => {
            if(index > 0) label += ', ';
            let filteredCode = _.filter(SBJ_CODE, {code: code});
            label += filteredCode.length > 0 ? filteredCode[0].label : '';
        })
        return label;
    }

    const handleStatTypeChange = (event) => {
        //console.log('handleStatTypeChange', event.target.value)
        setStatType(event.target.value);
    }

    const handleKcdKorChange = (event) => {
        //console.log('setKcdKor', event.target.value)
        setKcdKor(event.target.value);
    }

    //---------------- 하단의 검색 조건 처리 ----------------//
    const handleSido2 = (event) => {
        //console.log('handleSido2', event.target.value);
        setSido2Code(event.target.value);
    }

    const handleStat2TypeChange = (event) => {
        //console.log('handleStat2TypeChange', event.target.value)
        setStat2Type(event.target.value);
    }

    const handleStat2PortionChange = (event) => {
        //console.log('handleStat2PortionChange', event.target.value)
        setStat2Portion(event.target.value);
    }

    useEffect(() => {
        const isDataCompare = store.drawFigure1 != null && store.drawFigure2 != null; //다중분석입지 플래그
        setDataCompare(isDataCompare);
        CommonFunc.pageview('입지분석', '통계-진료청구');
    }, []);

    useEffect(() => {
        //console.log('sidoCode', sidoCode);
        getRegionList({ variables: { region_code: sidoCode }});
    }, [sidoCode]);

    useEffect(() => {
        if(!loadingRegionList && endpointRegionList){
            //console.log('endpointRegionList', endpointRegionList);
            setRegionList(endpointRegionList.getRegionList);
        }
    }, [endpointRegionList]);
    
    useEffect(() => {
        //console.log('sidoCode', sidoCode);
        //console.log('sggCode', sggCode);
        //console.log('spcCodeArr', _.map(spcCodeArr));
        if(sidoCode && spcCodeArr.length > 0){
            getTrendData({ variables: { sgg_code_arr: [sggCode], spc_code_arr: spcCodeArr }});
        }
    }, [sggCode, spcCodeArr]);

    useEffect(() => {
        const label = getSpcLabel(spcCodeArr)
        //console.log('spcLabel', label);
        setSpcLabel(label);
    }, [spcCodeArr]);

    useEffect(() => {
        //console.log('sido2Code', sido2Code);
        getSggData({ variables: { sido_code: sido2Code }});
    }, [sido2Code]);

    /*
    useEffect(() => {
        if(!endpointTrendLoading && endpointTrendData){
            //console.log('endpointTrendData', endpointTrendData);
        }
    }, [endpointTrendData]);
    */

    const sortDesc = (orgArr, key) => {
        let values = orgArr;
        return values.sort((a,b) => b[key] - a[key]);
    }

    const getKcdList = (disease_list) => {
        //최신달의 가장 인기순(statType) 제품 목록
        return  _.map(_.reverse(_.sortBy(disease_list, [statType])), 'kcd_kor'); //patient_cnt 역순으로 kcd_kor 추출
    }

    useEffect(() => {
        if(!endpointTrendLoading && endpointTrendData){
            //console.log('endpointTrendData-getStatHira', endpointTrendData.getStatHira);
            setTrendData(endpointTrendData.getStatHira);
            setDiseaseList(endpointTrendData.getStatHira.disease_list);
            setSortedDiseaseList(endpointTrendData.getStatHira.disease_list);
            /*
            //만원 단위
            let diseaseList = [];
            endpointTrendData.getStatHira.disease_list.map(item => {
                diseaseList.push({...item, amount: _.round(item.amount / 10000, 0)})
            })
            console.log('diseaseList', diseaseList);
            setDiseaseList(diseaseList);
            setSortedDiseaseList(diseaseList);
            */
            const hiraDate = endpointTrendData.getStatHira.date.substring(0, 4) + '년 ' + parseInt(endpointTrendData.getStatHira.date.substring(4, 6), 10) + '월';
            setHiraDate(hiraDate);

            //최신달의 가장 인기있는 제품 목록
            const kcdKorList = getKcdList(endpointTrendData.getStatHira.disease_list);
            //console.log('kcdKorList => ', kcdKorList);
            setKcdKorArr(kcdKorList); //상병 목록
            setKcdKor(kcdKorList[0]);
        }
    }, [endpointTrendData]);

    useEffect(() => {
        if(!endpointSggLoading && endpointSggData){
            //console.log('endpointSggData', endpointSggData);
            setSggData(endpointSggData.getHiraStatBySgg);
        }
    }, [endpointSggData]);

    useEffect(() => {
        if(hiraDate && props.setDate){
            props.setDate(hiraDate);
        }
    }, [hiraDate]);

    const getTrendChartData = (data) => {
        let chartData = [];
        chartData.push(['YEAR'].concat(kcdKor).concat({role: 'annotation'}));
        const dates = _.sortedUniq(_.map(data.disease_trend_list, 'date'));
        //console.log('dates', dates);
        //console.log('kcdKor : ', kcdKor);
        //console.log('kcdKorArr : ', kcdKorArr);
        dates.map(date => {
            let chartValues = [];
            const item = _.filter(data.disease_trend_list, {date: date, kcd_kor: kcdKor}); //해당 날짜의 KCD 목록 추출
            if(item && item.length > 0){
                chartValues.push(item[0][statType]);
            }
            else{
                chartValues.push(0);
            }
            //console.log('item', item);
            chartData.push([date].concat(chartValues).concat(chartValues));
        })
        //console.log('getTrendChartData', chartData);
        return chartData;
    }

    const sortDiseaseList = (sorter) => {
        let newSortedDiseaseList = null;
        if(sorter.startsWith('unit_')){
            if(sorter === 'unit_amount_per_treat'){
                newSortedDiseaseList = sortedDiseaseList.sort((a,b) => (b['treat_cnt'] > 0 ? b['amount']/b['treat_cnt'] : 0) - (a['treat_cnt'] > 0 ? a['amount']/a['treat_cnt'] : 0));
            }
            else if(sorter === 'unit_treat_per_patient'){
                newSortedDiseaseList = sortedDiseaseList.sort((a,b) => (b['patient_cnt'] > 0 ? b['treat_cnt']/b['patient_cnt'] : 0) - (a['patient_cnt'] > 0 ? a['treat_cnt']/a['patient_cnt'] : 0));
            }
            else if(sorter === 'unit_amount_per_patient'){
                newSortedDiseaseList = sortedDiseaseList.sort((a,b) => (b['patient_cnt'] > 0 ? b['amount']/b['patient_cnt'] : 0) - (a['patient_cnt'] > 0 ? a['amount']/a['patient_cnt'] : 0));
            }
        }
        else{
            newSortedDiseaseList = sortedDiseaseList.sort((a,b) => b[sorter] - a[sorter]);
            setDiseaseList(sortedDiseaseList);
            setStatType(sorter);
        }
        setSortedDiseaseList([...newSortedDiseaseList]);
        setDiseaseSorter(sorter);
    }

    const totSum = { patient_cnt: 0, treat_cnt: 0, amount: 0 };
    let trendChartData = [];

    if(trendData){
        trendChartData = getTrendChartData(trendData);
    }
    let sggChartData = [];
    let sidoPortionChartData = [];
    let sggPortionChartData = [];
    let sggMaxVal = 0;
    if(sggData){
        sidoPortionChartData =
            stat2Portion === 'amount_per_treat' ? _.round(_.sumBy(sggData, 'amount') / _.sumBy(sggData, 'treat_cnt'), 0) :
            stat2Portion === 'treat_per_patient' ? _.round(_.sumBy(sggData, 'treat_cnt') / _.sumBy(sggData, 'patient_cnt'), 2) :
            stat2Portion === 'amount_per_patient' ? _.round(_.sumBy(sggData, 'amount') / _.sumBy(sggData, 'patient_cnt'), 0) : 0;

        sggPortionChartData =
            stat2Portion === 'amount_per_treat' ? sggData.map(item => { return [item.sgg_name, _.round(item.amount / item.treat_cnt, 0), _.round(item.amount / item.treat_cnt, 0)] }) :
            stat2Portion === 'treat_per_patient' ? sggData.map(item => { return [item.sgg_name, _.round(item.treat_cnt / item.patient_cnt, 2), _.round(item.treat_cnt / item.patient_cnt, 2)] }) :
            stat2Portion === 'amount_per_patient' ? sggData.map(item => { return [item.sgg_name, _.round(item.amount / item.patient_cnt, 0), _.round(item.amount / item.patient_cnt, 0)] }) : 0;

        sggPortionChartData.map(item => {
            sggMaxVal = item[1] > sggMaxVal ? item[1] : sggMaxVal;
        })
    }

    return <>
        { (endpointTrendLoading || endpointSggLoading) &&
            <Backdrop className={classes.backdrop} open={endpointTrendLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        }
        { !endpointTrendLoading && trendData &&
            <Grid container direction={"column"} className="hiraInfo">
                { !dataCompare &&
                <Grid item className="caution">
                    <Typography className="txtinfo1">
                        주의!
                    </Typography>
                    <Typography className="txtinfo2">
                        이 페이지는 건강보험심사평가원에서 수집한 ＇의원급＇ 급여 처방 데이터 중, 매월 처방액 기준으로 시군구별 & 진료과별 상위 50개 질환만을 추출해 구성했습니다.<br/>
                        이 데이터는 완벽한 통계가 아닌, 지역별 주요 상위 질환을 비교하기 위한 용도입니다.  일부 진료과에서만 처방하는 빈도가 아주 낮은 질환의 경우, 집계 때 포함되지 않거나 적게 포함될 가능성이 있어 해석에 주의해야 합니다.<br/>
                        (기준년월 : {hiraDate} / 심평원 제공 최신 데이터)
                    </Typography>
                </Grid>
                }
                <Grid container direction={"row"} xs={12} className="infowrap">
                    <Grid item xs={3} className="combobox type2">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="sido-select-label">시도 선택</InputLabel>
                            <Select
                                labelId="sido-select-label"
                                value={sidoCode}
                                onChange={handleSido}
                                MenuProps={MenuProps}
                            >
                                {
                                    SIDO_CODE.map((item, index) =>
                                        <MenuItem value={item.code}>{item.label}</MenuItem>
                                    )
                                }
                            </Select>
                            {
                                sggMsg &&
                                <div className="sggWarningMsg">시군구 항목까지 선택 하셔야 해당 지역의 진료청구통계를 확인하실 수 있습니다. * 미선택시 기존 조회지역 통계가 보여집니다.</div>
                            }

                        </FormControl>
                    </Grid>
                    <Grid item xs={3} className="combobox type2">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="sgg-select-label">시군구 선택</InputLabel>
                            <Select
                                labelId="sgg-select-label"
                                value={sggCode}
                                onChange={handleSgg}
                                MenuProps={MenuProps}
                            >
                                {
                                    regionList && regionList.length > 0 &&
                                    regionList.map((item, index) =>
                                        <MenuItem value={item.region_code}>{item.region_name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} className="combobox type2">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="spc-select-label">진료과 선택</InputLabel>
                            <Select
                                multiple
                                labelId="spc-select-label"
                                value={spcCodeArr}
                                input={<Input />}
                                renderValue={(selected) => spcLabel}
                                onChange={handleSpc}
                                MenuProps={MenuProps}
                            >
                                {
                                    SBJ_CODE.map((item, index)=>
                                        <MenuItem key={item.code} value={item.code}>
                                            <Checkbox checked={spcCodeArr.join().indexOf(item.code) > -1} />
                                            <ListItemText primary={item.label} />
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid item className="infowrap title">
                    <span className="txtinfo"><span className="MuiIconButton-colorSecondary">{sggName}</span> 의원의 처방 개요</span>
                    <span className="txtinfo sub">선택된 진료과에 해당하는 결과만 보여줍니다.</span>
                    {/*<span className="txtinfo sub">기본값은 검색 전 선택된 전문과에 해당하는 결과입니다.</span>*/}
                </Grid>
                <Grid container xs={12} className="infowrap" style={{flexWrap:"nowrap",padding:"0 40px 40px"}}>
                    <Grid item xs={4} className="LineChart">
                        <Paper className="titxt">
                            <span className="title">환자수</span>
                            <span className="data">{trendData.summary.patient_cnt.toLocaleString()}</span>
                        </Paper>
                        {trendData.summary_trend_list.length > 0 &&
                        <Paper>
                            <Chart
                                chartType='LineChart'
                                width='100%'
                                height='130px'
                                chartArea={{
                                    width: '100%',
                                    height: '100%'
                                }}
                                data={
                                    [['', '']].concat(CommonFunc.pivotArray(
                                        trendData.summary_trend_list,
                                        ['date', 'patient_cnt']))
                                }
                                options={{
                                    legend: {position: 'none'},
                                    hAxis: {
                                        textPosition: 'none',
                                        gridlines: {count: 0},
                                        baselineColor: '#FFFFFF'
                                    },
                                    vAxis: {
                                        textPosition: 'none',
                                        gridlines: {count: 0},
                                        baselineColor: '#FFFFFF'
                                    },
                                }}
                                loader={<div>Loading Chart</div>}
                            />
                        </Paper>
                        }
                    </Grid>
                    <Grid item xs={4} className="LineChart">
                        <Paper className="titxt">
                            <span className="title">처방건수</span>
                            <span className="data">{trendData.summary.treat_cnt.toLocaleString()}</span>
                        </Paper>
                        {trendData.summary_trend_list.length > 0 &&
                        <Paper>
                            <Chart
                                chartType='LineChart'
                                width='100%'
                                height='130px'
                                data={
                                    [['', '']].concat(CommonFunc.pivotArray(
                                        trendData.summary_trend_list,
                                        ['date', 'treat_cnt']))
                                }
                                options={{
                                    legend: {position: 'none'},
                                    hAxis: {
                                        textPosition: 'none',
                                        gridlines: {count: 0},
                                        baselineColor: '#FFFFFF'
                                    },
                                    vAxis: {
                                        textPosition: 'none',
                                        gridlines: {count: 0},
                                        baselineColor: '#FFFFFF'
                                    },
                                }}
                                loader={<div>Loading Chart</div>}
                            />
                        </Paper>
                        }
                    </Grid>
                    <Grid item xs={4} className="LineChart">
                        <Paper className="titxt">
                            <span className="title">처방액</span>
                            <span className="data">{trendData.summary.amount.toLocaleString()}</span>
                        </Paper>
                        {trendData.summary_trend_list.length > 0 &&
                        <Paper>
                            <Chart
                                chartType='LineChart'
                                width='100%'
                                height='130px'
                                chartArea={{
                                    width: '100%',
                                    height: '100%'
                                }}
                                data={
                                    [['', '']].concat(CommonFunc.pivotArray(
                                        trendData.summary_trend_list,
                                        ['date', 'amount']))
                                }
                                options={{
                                    legend: {position: 'none'},
                                    hAxis: {
                                        textPosition: 'none',
                                        gridlines: {count: 0},
                                        baselineColor: '#FFFFFF'
                                    },
                                    vAxis: {
                                        textPosition: 'none',
                                        gridlines: {count: 0},
                                        baselineColor: '#FFFFFF'
                                    },
                                }}
                                loader={<div>Loading Chart</div>}
                            />
                        </Paper>
                        }
                    </Grid>
                </Grid>
                <Grid item className="infowrap title">                    
                    <span className="txtinfo"><span className="MuiIconButton-colorSecondary">{sggName}</span> 의원의 질환별 처방 개요</span>
                    <span className="txtinfo sub">선택된 진료과에 해당하는 결과만 보여줍니다.</span>
                </Grid>
                <Grid item className="infowrap table">
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className="title-tr">
                                    <StyledTableCell>KCD KOR</StyledTableCell>
                                    <StyledTableCell align="right" className={diseaseSorter === 'patient_cnt' ? 'on' : ''} onClick={()=>sortDiseaseList('patient_cnt')}>환자수</StyledTableCell>
                                    <StyledTableCell align="right" className={diseaseSorter === 'treat_cnt' ? 'on' : ''}  onClick={()=>sortDiseaseList('treat_cnt')}>처방건수</StyledTableCell>
                                    <StyledTableCell align="right" className={diseaseSorter === 'amount' ? 'on' : ''}  onClick={()=>sortDiseaseList('amount')}>처방액<br/>(만원)</StyledTableCell>
                                    <StyledTableCell align="right" className={diseaseSorter === 'unit_amount_per_treat' ? 'on' : ''}  onClick={()=>sortDiseaseList('unit_amount_per_treat')}>건당{dataCompare?<br/>:' '}처방액<br/>(평균/원)</StyledTableCell>
                                    <StyledTableCell align="right" className={diseaseSorter === 'unit_treat_per_patient' ? 'on' : ''}  onClick={()=>sortDiseaseList('unit_treat_per_patient')}>환자당{dataCompare?<br/>:' '}처방건수<br/>(평균)</StyledTableCell>
                                    <StyledTableCell align="right" className={diseaseSorter === 'unit_amount_per_patient' ? 'on' : ''}  onClick={()=>sortDiseaseList('unit_amount_per_patient')}>환자당{dataCompare?<br/>:' '}처방액<br/>(평균/원)</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedDiseaseList.map((item, index) => {
                                        if(index > page * 10){
                                            return;
                                        }
                                        if (item.patient_cnt !== 0) {
                                            totSum.patient_cnt += item.patient_cnt;
                                            totSum.treat_cnt += item.treat_cnt;
                                            totSum.amount += item.amount;
                                        }
                                        return <>
                                                {
                                                    item.patient_cnt > 0 ?
                                                        <StyledTableRow key={index} className="list-tr">
                                                            <StyledTableCell component="th" scope="row">{item.kcd_kor}</StyledTableCell>
                                                            <StyledTableCell className={diseaseSorter === 'patient_cnt' ? 'on' : ''} align="right">{item.patient_cnt.toLocaleString()}</StyledTableCell>
                                                            <StyledTableCell className={diseaseSorter === 'treat_cnt' ? 'on' : ''} align="right">{item.treat_cnt.toLocaleString()}</StyledTableCell>
                                                            <StyledTableCell className={diseaseSorter === 'amount' ? 'on' : ''} align="right">{_.round(item.amount / 10000, 0).toLocaleString()}</StyledTableCell>
                                                            <StyledTableCell className={diseaseSorter === 'unit_amount_per_treat' ? 'on' : ''} align="right">{item.treat_cnt === 0 ? '-' : _.round( item.amount / item.treat_cnt).toLocaleString()}</StyledTableCell>
                                                            <StyledTableCell className={diseaseSorter === 'unit_treat_per_patient' ? 'on' : ''} align="right">{_.round(item.treat_cnt / item.patient_cnt, 1).toLocaleString()}</StyledTableCell>
                                                            <StyledTableCell className={diseaseSorter === 'unit_amount_per_patient' ? 'on' : ''} align="right">{_.round(item.amount/ item.patient_cnt).toLocaleString()}</StyledTableCell>
                                                        </StyledTableRow>
                                                        :
                                                        <StyledTableRow key={index} className="list-tr">
                                                            <StyledTableCell component="th" scope="row">{item.kcd_kor}</StyledTableCell>
                                                            <StyledTableCell className={diseaseSorter === 'patient_cnt' ? 'on' : ''} align="right">N/A</StyledTableCell>
                                                            <StyledTableCell className={diseaseSorter === 'treat_cnt' ? 'on' : ''} align="right">N/A</StyledTableCell>
                                                            <StyledTableCell className={diseaseSorter === 'amount' ? 'on' : ''} align="right">N/A</StyledTableCell>
                                                            <StyledTableCell className={diseaseSorter === 'unit_amount_per_treat' ? 'on' : ''} align="right">-</StyledTableCell>
                                                            <StyledTableCell className={diseaseSorter === 'unit_treat_per_patient' ? 'on' : ''} align="right">-</StyledTableCell>
                                                            <StyledTableCell className={diseaseSorter === 'unit_amount_per_patient' ? 'on' : ''} align="right">-</StyledTableCell>
                                                        </StyledTableRow>
                                                }
                                            </>
                                    }
                                )}
                                <StyledTableRow>
                                    <StyledTableCell component="td" colSpan="7" scope="row" style={{padding:"0 0 10px"}}>
                                        {
                                            (sortedDiseaseList.length > (page  * 10)) &&
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                onClick={() => setPage(page+1)}
                                            >
                                                더보기
                                            </Button>
                                        }
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key={'total'}>
                                    <StyledTableCell component="th" scope="row" className="total-th"><b>총 합계</b></StyledTableCell>
                                    <StyledTableCell align="right">{totSum.patient_cnt.toLocaleString()}</StyledTableCell>
                                    <StyledTableCell align="right">{totSum.treat_cnt.toLocaleString()}</StyledTableCell>
                                    <StyledTableCell align="right">{_.round(totSum.amount / 10000, 0).toLocaleString()}</StyledTableCell>
                                    <StyledTableCell align="right">{totSum.treat_cnt === 0 ? '-' : _.round(totSum.amount / totSum.treat_cnt).toLocaleString()}</StyledTableCell>
                                    <StyledTableCell align="right">{totSum.patient_cnt === 0 ? '-' : _.round(totSum.treat_cnt / totSum.patient_cnt, 1).toLocaleString()}</StyledTableCell>
                                    <StyledTableCell align="right">{totSum.patient_cnt === 0 ? '-' : _.round(totSum.amount / totSum.patient_cnt).toLocaleString()}</StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                { diseaseList.length > 0 &&
                    <Grid item container direction={"row"} className="infowrap">
                        <Grid item style={{width:"25%",marginBottom:"20px"}}>
                            <FormControl className="combobox type2" style={{textAlign:"center"}}>
                                <Select
                                    value={statType}
                                    onChange={handleStatTypeChange}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        STAT_TYPES.map((item, index) =>
                                            <MenuItem value={item.code}>{item.label}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item container direction={"row"} xs={12} className="LineChart" style={{flexWrap:"nowrap"}}>
                            <Grid item xs={5}>
                                <Paper>
                                    {diseaseList.length > 0 &&
                                        <Chart
                                            chartType='PieChart'
                                            width='100%'
                                            height='300px'
                                            data={
                                                [['', '']]
                                                .concat(CommonFunc.pivotArray(diseaseList,['kcd_kor', statType]))
                                            }
                                            options={{
                                                //title: _.filter(STAT_TYPES, {code: statType})[0],
                                                pieHole: 0.4,
                                                legend: {
                                                    position: "right",
                                                    alignment: "center",
                                                },
                                                tooltip: {
                                                    textStyle: {fontSize: 12},
                                                    showColorCode: true
                                                }
                                            }}
                                            legend_toggle
                                        />
                                    }
                                </Paper>
                            </Grid>
                            <Grid item xs={7}>
                                {diseaseList.length > 0 &&
                                <FormControl className="combobox type1">
                                    <Select
                                        value={kcdKor}
                                        onChange={handleKcdKorChange}
                                        MenuProps={MenuProps}
                                    >
                                        {
                                            kcdKorArr.map((item, index) =>
                                                <MenuItem value={item}>{item}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                                }
                                {diseaseList.length > 0 &&
                                <Paper>
                                    <Chart
                                        chartType='LineChart'
                                        width='100%'
                                        height='300px'
                                        data={
                                            trendChartData
                                        }
                                        options={{
                                            title: _.filter(STAT_TYPES, {code: statType})[0],
                                            legend: {position: 'none'}
                                        }}
                                        loader={<div>Loading Chart</div>}
                                    />
                                </Paper>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        }

        { !endpointTrendLoading && trendData && !endpointSggLoading && sggData &&
            <Grid container direction={"column"} className="hiraInfo">
                <Grid item className="infowrap title" style={{padding:"40px 40px 0"}}>
                    <Grid item>
                        <span className="txtinfo">시군구별 주요지표 비교(의원)</span>
                        <Paper className="combobox type2" style={{textAlign:"center"}}>
                            <FormControl>
                                <Select
                                    value={sido2Code}
                                    onChange={handleSido2}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        SIDO_CODE.map((item, index) =>
                                            <MenuItem value={item.code}>{item.label}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={12} className="infowrap type2">
                        <Paper>
                            <FormControl className="combobox type1">
                                <Select
                                    value={stat2Type}
                                    onChange={handleStat2TypeChange}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        STAT_TYPES.map((item, index) =>
                                            <MenuItem value={item.code}>{item.label}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>

                            <Typography>
                                총{_.filter(STAT_TYPES, {code: stat2Type})[0].label.replace('처방액', '액')} :&nbsp;
                                { _.sumBy(sggData, stat2Type).toLocaleString() }&nbsp;
                                {stat2Type === 'patient_cnt' ? '명': stat2Type === 'treat_cnt' ? '건' : '원'}
                            </Typography>
                        </Paper>
                        <Paper>
                            <Chart
                                chartType='ColumnChart'
                                width='100%'
                                height='300px'
                                data={
                                    [['', '', { role: 'annotation' }]]
                                    .concat(CommonFunc.pivotArray(sggData,['sgg_name', stat2Type, stat2Type]))
                                }
                                options={{
                                    //title: _.filter(STAT_TYPES, {code: statType})[0],
                                    bar: {groupWidth: CHART.barWidth},
                                    legend: {position: 'none'}
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid container xs={12} className="infowrap type2">
                        <Grid item xs={12}>
                            <FormControl className="combobox type1">
                                <Select
                                    value={stat2Portion}
                                    onChange={handleStat2PortionChange}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        STAT_PORTIONS.map((item, index) =>
                                            <MenuItem value={item.code}>{item.label}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <Paper>
                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='300px'
                                    data={
                                        [
                                            [ '', '', { role: 'annotation' } ],
                                            [ _.filter(SIDO_CODE, {code : sido2Code })[0].label ]
                                            .concat(
                                                sidoPortionChartData
                                            )
                                            .concat(
                                                sidoPortionChartData
                                            ),
                                        ]
                                    }
                                    options={{
                                        bar: {groupWidth: CHART.barWidth},
                                        legend: {position: 'none'},
                                        hAxis: { textStyle: { fontSize: 14 } },
                                        vAxis: { minValue: 0, maxValue: sggMaxVal, textStyle: { fontSize: 14 } },
                                        annotations: { textStyle: { fontSize: 14 } },
                                    }}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={10}>
                            <Paper>
                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='300px'
                                    data={
                                        [['', '', { role: 'annotation' }]]
                                        .concat(
                                            sggPortionChartData
                                        )
                                    }
                                    options={{
                                        //title: _.filter(STAT_TYPES, {code: statType})[0],
                                        bar: {groupWidth: CHART.barWidth},
                                        legend: {position: 'none'},
                                        hAxis: { textStyle: { fontSize: 14 } },
                                        vAxis: { minValue: 0, maxValue: sggMaxVal, textStyle: { fontSize: 14 } },
                                        annotations: { textStyle: { fontSize: 14 } },
                                    }}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        }
    </>
};

export default StatHira;
