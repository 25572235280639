import React from 'react';

export default class HtmlConfirm extends React.PureComponent {
	render() {
		const { param, title="", message, onClose, confirmCallback, okText='확인', cancelText='취소', useCancel=true } = this.props;
		console.log("useCancel", useCancel)
		return (
            <div className="mdc-dialog alert"
                role="alertdialog"
                aria-modal="true"
                aria-labelledby="my-dialog-title"
                aria-describedby="my-dialog-content"
                style={{display:'block'}}
            >
                <div className="mdc-dialog__container custom_dialog" style={{opacity: 1}}>
                    <div className="mdc-dialog__surface">
                    {
                        title &&
                        <h2 className="mdc-dialog__title" id="my-dialog-title">{title}</h2>
                    }
                    <div className="mdc-dialog__content" id="my-dialog-content" dangerouslySetInnerHTML={{ __html: message }}/>
                    <footer className="mdc-dialog__actions">
                    {
                        confirmCallback ?
                        <>
                            {
                                useCancel &&
                                <button type="button" className="mdc-button mdc-dialog__button mdc-button--outlined cancel" data-mdc-dialog-action="no" onClick={onClose}>
                                    <span className="mdc-button__label">{cancelText}</span>
                                </button>
                            }
                            <button type="button" className="mdc-button mdc-dialog__button" data-mdc-dialog-action="yes" onClick={() => {
                                confirmCallback({param})
                                onClose()
                            }}>
                                <span className="mdc-button__label">{okText}</span>
                            </button>
                        </>
                        :
                        <>
                            <button type="button" className="mdc-button mdc-dialog__button" data-mdc-dialog-action="no" onClick={onClose}>
                                <span className="mdc-button__label">확인</span>
                            </button>
                        </>
                    }
                    </footer>
                    </div>
                </div>
                <div className="mdc-dialog__scrim"></div>
            </div>
        )
    }
}
