import React from 'react';
import Select from 'react-select';
import { Query } from 'react-apollo';

import { FILTERSTATYEAR_LIST } from '../../../queries/prescript/prescription';

class FilterStatYear extends React.PureComponent {
    state = {
        selectedOption: null,
        placeholder: "처방정보 기준년도"
    };

    handleChange = selectedOption => {
        this.setState({ selectedOption: selectedOption });

        this.props.onChangeFilterStatYear(selectedOption);
    };

    render() {
        const { selectedOption } = this.state;

        return (
            <Query query={FILTERSTATYEAR_LIST} >
                {({ data, loading, error }) => {
                    if (loading) return <p></p>
                    if (error) return <p>{error.message}</p>;
                    //초기 값 set
                    if(!this.props.filter_stat_year_selectedOption){
                        this.handleChange(data.getStatYearList[0]);
                    }
                    return (
                        <Select
                            placeholder={this.state.placeholder}
                            options={data.getStatYearList}
                            isSearchable={false}
                            onChange={this.handleChange}
                            value={this.props.filter_stat_year_selectedOption}
                            styles={{
                                option: base => ({
                                    ...base,
                                    borderBottom: `1px solid rgba(0,0,0,0.05)`,
                                    height: '100%'
                                })
                            }}
                        />
                    )
                }}
            </Query>
        )
    }
};

export default FilterStatYear;