export const ORG_TYPE = [
    {code: "UH", name: "ORG_TYPE1"},
    {code: "HC", name: "ORG_TYPE1"},
    {code: "EX", name: "ORG_TYPE2"},
    {code: "GH", name: "ORG_TYPE2"},
    {code: "HB", name: "ORG_TYPE2"},
    {code: "HO", name: "ORG_TYPE5"},
    {code: "CH", name: "ORG_TYPE6"},
    {code: "MP", name: "ORG_TYPE3"},
    {code: "ETC", name: "ORG_TYPE5"},
];

export const HTP_CODE = [
    {
        "kbn": "HTP",
        "code": "ORG_TYPE1",
        "name": "종합병원"
    },{
        "kbn": "HTP",
        "code": "ORG_TYPE6",
        "name": "요양병원"
    },{
        "kbn": "HTP",
        "code": "ORG_TYPE2",
        "name": "병원"
    },{
        "kbn": "HTP",
        "code": "ORG_TYPE7",
        "name": "건강검진센터"
    },{
        "kbn": "HTP",
        "code": "ORG_TYPE8",
        "name": "네트워크의원"
    },{
        "kbn": "HTP",
        "code": "ORG_TYPE3",
        "name": "의원"
    },{
        "kbn": "HTP",
        "code": "ORG_TYPE4",
        "name": "보건소"
    },{
        "kbn": "HTP",
        "code": "ORG_TYPE5",
        "name": "기타"
    }
];

export const SPC_CODE = [
    {
        "kbn": "SPC",
        "code": "SPC116",
        "name": "일반의"
    },{
        "kbn": "SPC",
        "code": "SPC101",
        "name": "가정의학과"
    },{
        "kbn": "SPC",
        "code": "SPC102",
        "name": "결핵과"
    },{
        "kbn": "SPC",
        "code": "SPC103",
        "name": "내과"
    },{
        "kbn": "SPC",
        "code": "SPC104",
        "name": "마취통증의학과"
    },{
        "kbn": "SPC",
        "code": "SPC105",
        "name": "비뇨의학과"
    },{
        "kbn": "SPC",
        "code": "SPC106",
        "name": "산부인과"
    },{
        "kbn": "SPC",
        "code": "SPC107",
        "name": "직업환경의학과"
    },{
        "kbn": "SPC",
        "code": "SPC108",
        "name": "성형외과"
    },{
        "kbn": "SPC",
        "code": "SPC109",
        "name": "소아청소년과"
    },{
        "kbn": "SPC",
        "code": "SPC110",
        "name": "신경과"
    },{
        "kbn": "SPC",
        "code": "SPC111",
        "name": "신경외과"
    },{
        "kbn": "SPC",
        "code": "SPC113",
        "name": "안과"
    },{
        "kbn": "SPC",
        "code": "SPC114",
        "name": "응급의학과"
    },{
        "kbn": "SPC",
        "code": "SPC115",
        "name": "이비인후과"
    },{
        "kbn": "SPC",
        "code": "SPC117",
        "name": "외과"
    },{
        "kbn": "SPC",
        "code": "SPC118",
        "name": "진단검사의학과"
    },{
        "kbn": "SPC",
        "code": "SPC119",
        "name": "재활의학과"
    },{
        "kbn": "SPC",
        "code": "SPC120",
        "name": "정신건강의학과"
    },{
        "kbn": "SPC",
        "code": "SPC121",
        "name": "정형외과"
    },{
        "kbn": "SPC",
        "code": "SPC122",
        "name": "영상의학과"
    },{
        "kbn": "SPC",
        "code": "SPC123",
        "name": "방사선종양학과"
    },{
        "kbn": "SPC",
        "code": "SPC124",
        "name": "피부과"
    },{
        "kbn": "SPC",
        "code": "SPC125",
        "name": "핵의학과"
    },{
        "kbn": "SPC",
        "code": "SPC126",
        "name": "흉부외과"
    },{
        "kbn": "SPC",
        "code": "SPC129",
        "name": "예방의학과"
    },{
        "kbn": "SPC",
        "code": "SPC130",
        "name": "병리과"
    }
];

export const LOC_CODE = [
    {
        "kbn": "ZON",
        "code": "01",
        "name": "강원"
    },{
        "kbn": "ZON",
        "code": "02",
        "name": "경기"
    },{
        "kbn": "ZON",
        "code": "03",
        "name": "경남"
    },{
        "kbn": "ZON",
        "code": "04",
        "name": "경북"
    },{
        "kbn": "ZON",
        "code": "05",
        "name": "광주"
    },{
        "kbn": "ZON",
        "code": "06",
        "name": "대구"
    },{
        "kbn": "ZON",
        "code": "07",
        "name": "대전"
    },{
        "kbn": "ZON",
        "code": "08",
        "name": "부산"
    },{
        "kbn": "ZON",
        "code": "09",
        "name": "서울"
    },{
        "kbn": "ZON",
        "code": "10",
        "name": "울산"
    },{
        "kbn": "ZON",
        "code": "11",
        "name": "인천"
    },{
        "kbn": "ZON",
        "code": "12",
        "name": "전남"
    },{
        "kbn": "ZON",
        "code": "13",
        "name": "전북"
    },{
        "kbn": "ZON",
        "code": "14",
        "name": "제주"
    },{
        "kbn": "ZON",
        "code": "15",
        "name": "충남"
    },{
        "kbn": "ZON",
        "code": "16",
        "name": "충북"
    },{
        "kbn": "ZON",
        "code": "17",
        "name": "세종"
    }
];

export const HLINK_CONSULTANT_TEL = [
    {code: 1, name: "02-718-9433"},
    {code: 2, name: "02-718-9443"},
    {code: 3, name: "02-542-9433"}
];

export const HLINK_CONSULTANT_MOBILE = [
    {code: 1, name: "010-3168-9433"},
    {code: 2, name: "010-9483-9443"},
    {code: 3, name: "010-2102-9443"}
];

export const CONV_SPC_CODE_ENG = [
    {code: "SPC101", name: "FM"},
    {code: "SPC102", name: "TB"},
    {code: "SPC104", name: "ANE"},
    {code: "SPC105", name: "URO"},
    {code: "SPC106", name: "OBGY"},
    {code: "SPC107", name: "OEM"},
    {code: "SPC108", name: "PS"},
    {code: "SPC109", name: "PED"},
    {code: "SPC110", name: "NR"},
    {code: "SPC111", name: "NS"},
    {code: "SPC113", name: "EYE"},
    {code: "SPC114", name: "EM"},
    {code: "SPC115", name: "ENT"},
    {code: "SPC116", name: "GP"},
    {code: "SPC117", name: "GS"},
    {code: "SPC118", name: "LM"},
    {code: "SPC119", name: "RM"},
    {code: "SPC120", name: "NP"},
    {code: "SPC121", name: "OS"},
    {code: "SPC122", name: "DR"},
    {code: "SPC123", name: "RO"},
    {code: "SPC124", name: "DER"},
    {code: "SPC125", name: "NM"},
    {code: "SPC126", name: "CS"},
    {code: "SPC129", name: "PM"},
    {code: "SPC130", name: "AP"},
    {code: "SPC103", name: "IM"}
]