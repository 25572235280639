import React, { Component, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ArrowDropUpRoundedIcon from "@material-ui/icons/ArrowDropUpRounded";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import Table from "@material-ui/core/Table";

import useStyles from './SalaryStyle';
import CommonFunction from "../../common/CommonFunction";
import {HTP_CODE, LOC_CODE, SPC_CODE} from "./UserConstants";
import STitle from "./S_Title";
import SBoxNoContent from "./S_BoxNoContent";

class S_BoxPayContent extends Component {
    render() {
        const { data={}, state_period_type, spc_code, hop_loc_code, org_type } = this.props;
        // const { classes } = this.props;

        const dataType = data.type.split(",");

        return (
            <Fragment>
                <STitle align="center">
                    {
                        dataType[0] === "ALL" &&
                        <Fragment>전체</Fragment>
                    }
                    {
                        dataType[0] === "SPC" &&
                        CommonFunction.getCodeName(spc_code, SPC_CODE)
                    }
                    {
                        dataType[0] === "ZON" &&
                        CommonFunction.getCodeName(hop_loc_code, LOC_CODE)
                    }
                    {
                        dataType[0] === "HTP" &&
                        CommonFunction.getCodeName(org_type, HTP_CODE)
                    }
                </STitle>
                {
                    (dataType[1] && dataType[1] === "null") ?
                    <SBoxNoContent
                        dataType={dataType[0]}
                    />
                    :
                    <Table size="small" style={{marginTop: "15px"}}>
                        {/*<CssBaseline />*/}

                        <TableBody>
                            <TableRow>
                                <TableCell className="tittxt_net">월급(NET) / 평균</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="subtxt1_net">
                            <span className="growth">
                                {
                                    state_period_type === "month" &&
                                    <Fragment>
                                        {
                                            data.avg_net_growth !== 0 ?
                                                data.avg_net_growth > 0 ?
                                                    <Fragment>
                                                        <ArrowDropUpRoundedIcon color="primary"/>
                                                        {data.avg_net_growth}%
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <ArrowDropDownRoundedIcon color="secondary"/>
                                                        {data.avg_net_growth}%
                                                    </Fragment>
                                                :
                                                '-'
                                        }
                                    </Fragment>
                                }
                            </span>
                                    <span className="data_net">
                                {data.avg_net_pay && data.avg_net_pay.toLocaleString()}
                            </span>
                                    <span className="text">만원</span>
                                </TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell className="subtxt2_net">중앙(MEDIAN) {data.median_net_pay && data.median_net_pay.toLocaleString()} 만원</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="tittxt_grs">연봉(GROSS) / 평균</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="subtxt1_grs">
                            <span className="growth">
                                {
                                    state_period_type === "month" &&
                                    <Fragment>
                                        {
                                            data.avg_gross_growth !== 0 ?
                                                data.avg_gross_growth > 0 ?
                                                    <Fragment>
                                                        <ArrowDropUpRoundedIcon color="primary"/>
                                                        {data.avg_gross_growth}%
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <ArrowDropDownRoundedIcon color="secondary"/>
                                                        {data.avg_gross_growth}%
                                                    </Fragment>
                                                :
                                                '-'
                                        }
                                    </Fragment>
                                }
                            </span>
                                    <span className="data_grs">
                                {data.avg_gross_pay && data.avg_gross_pay.toLocaleString()}
                            </span>
                                    <span className="text">만원</span>
                                </TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell className="subtxt2_grs">중앙(MEDIAN) {data.median_gross_pay && data.median_gross_pay.toLocaleString()} 만원</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                }
            </Fragment>
        )
    }
}

export default withStyles(useStyles)(S_BoxPayContent);