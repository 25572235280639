import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';

import { createBrowserHistory } from 'history';

import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';

//console.log('process.env.NODE_ENV ', process.env.NODE_ENV);
//console.log('process.env.REACT_APP_ENV_TYPE ', process.env.REACT_APP_ENV_TYPE);

let END_POINT_GRAPHQL = null;
if(process.env.REACT_APP_ENV_TYPE === 'production'){
    END_POINT_GRAPHQL = 'https://data-api.medigate.net';
}
else if(process.env.REACT_APP_ENV_TYPE === 'test'){
    END_POINT_GRAPHQL = '//t-data-api.medigate.net';
}
else{
    END_POINT_GRAPHQL = `http://${window.location.hostname}:4000`;
  //END_POINT_GRAPHQL = 'https://data-api.medigate.net';
}
END_POINT_GRAPHQL = 'https://data-api.medigate.net';
//console.log('GRAPHQL_END_POINT : ', END_POINT_GRAPHQL);

const httpLink = new HttpLink({
    uri: END_POINT_GRAPHQL
})

const wsLink = new WebSocketLink({
    // uri: 'wss://subscriptions.graph.cool/v1/cjdgbw4h34l7j0185k94go7w3',
    uri: 'wss://subscriptions.graph.cool/v1/cjdgbw4h34l7j0185k94go7w3_1',
    options: {
        reconnect: true
    }
});

const link = ApolloLink.split(
    // split based on operation type
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
);

const errorLink = onError(({graphQLErrors, networkError}) => {
    //console.log('graphQLErrors', graphQLErrors);
    //console.log('networkError', networkError);
    var errorMsg = ((graphQLErrors ? graphQLErrors.message : '') || '') + (networkError || '')
    //console.log('errorMsg : ', errorMsg);
    if((errorMsg && errorMsg.indexOf('read ECONNRESET') !== -1)){
        window.location.reload();
    }
})

const client = new ApolloClient({
    link: errorLink.concat(link),
    cache: new InMemoryCache()
});
const history = createBrowserHistory();

ReactDOM.render(
    <BrowserRouter history={history}>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </BrowserRouter>
    , document.getElementById('root')
);

//registerServiceWorker();
unregisterServiceWorker();
