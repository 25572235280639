import React, {Component, Fragment} from 'react';
import {inject, observer} from "mobx-react";

import Skeleton from '@material-ui/lab/Skeleton';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import SBoxPayContent from "./S_BoxPayContent";
import SBoxPaySubContent from "./S_BoxPaySubContent";

@inject("salaryStore")
@observer
class S_PayinfoOverviewBox extends Component {
    render() {
        const {
            fixedHeightPaper,
            fixedMediumHeightPaper,
            data=[],
            spc_code,
            hop_loc_code,
            org_type,
            state_period_type,
            loading
        } = this.props;
        const { salaryStore } = this.props;
        // const { userProfile } = this.props.salaryStore;

        return (
            <Fragment>
                {
                    Array.from(new Array(4)).map((item, index) => (
                        <Grid item xs={12} md={3} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                {
                                    !data.length > 0 || loading ?
                                    <Fragment>
                                        <Skeleton variant="rect" width="100%" height={240} />
                                    </Fragment>
                                    :
                                    <SBoxPayContent
                                        data={data[index]}
                                        state_period_type={state_period_type}
                                        spc_code={spc_code}
                                        hop_loc_code={hop_loc_code}
                                        org_type={org_type}
                                    />
                                }
                            </Paper>
                            {
                                (salaryStore.isLogin && salaryStore.myPay > 0 && salaryStore.myWorkTime > 0) &&
                                <div className="myinfo">
                                    <Paper className={fixedMediumHeightPaper}>
                                        {
                                            salaryStore.mySalaryResult.length > 0 ?
                                            <SBoxPaySubContent
                                                data={salaryStore.mySalaryResult[index]}
                                                spc_code={spc_code}
                                                hop_loc_code={hop_loc_code}
                                                org_type={org_type}
                                            />
                                            :
                                            <Fragment>
                                                <Skeleton variant="rect" width="100%" height={240} />
                                            </Fragment>
                                        }
                                    </Paper>
                                </div>
                            }
                        </Grid>
                    ))
                }
            </Fragment>
        );
    }
}

export default S_PayinfoOverviewBox;
