import React, { Fragment } from 'react';
import { withApollo } from 'react-apollo';
import CommonFunc from '../../../common/CommonFunction';
import { COMMENT_LIKE, COMMENT_DISLIKE } from '../../../queries/prescript/comment';

class Helpful extends React.PureComponent {
    constructor(props){
        super(props);

        this.state = {
            liked_flag: props.item.liked_flag,
            like_cnt: props.item.like_cnt
        }
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        const propsChanged = (oldProps.item !== newProps.item);
        if(propsChanged) {
            this.setState({
                liked_flag: newProps.item.liked_flag,
                like_cnt: newProps.item.like_cnt
            })
        }
    }

    changeCommentLike = (comment_idx, like_flag) => {
        const u_id = CommonFunc.getUserId();
        if(u_id){
            if(like_flag === 'Y'){
                this.commentLike(comment_idx, u_id);
            }
            else{
                this.commentDislike(comment_idx, u_id);
            }
        }
        else{
            //console.log('로그인이 필요합니다.');
        }
    }

    commentLike = (comment_idx, u_id) => {
        //console.log('comment_idx', comment_idx);
        this.props.client.mutate({
            mutation: COMMENT_LIKE,
            variables: {
                comment_idx,
                u_id
            }
        })
        .then((res) => {
            //console.log('like success!', res);

            this.setState({
                liked_flag: 'Y',
                like_cnt: this.state.like_cnt + 1
            })
        })
        .catch((error) => {
            //console.log(error.message);
        });
    }

    commentDislike = (comment_idx, u_id) => {
        this.props.client.mutate({
            mutation: COMMENT_DISLIKE,
            variables: {
                comment_idx,
                u_id
            }
        })
        .then((res) => {
            //console.log('dislike success!', res);
            this.setState({
                liked_flag: 'N',
                like_cnt: this.state.like_cnt - 1
            })
        })
        .catch((error) => {
            //console.log(error.message);
        });
    }

    render() {
        //console.log('Helpful Render ====================================', this.state)
		return (
			<Fragment>
                <div className="etc inp_check">
                    <button class="mdc-button mdl-js-button" onClick={() => this.changeCommentLike(this.props.item.comment_idx, this.state.liked_flag === 'Y'? 'N' : 'Y')}>
                        {
                            this.state.liked_flag === 'Y'?
                            <Fragment>
                                <i class="material-icons green">done</i>
                                <span class="mdc-button__label green">Helpful</span>
                            </Fragment>
                            :
                            <Fragment>
                                <i class="material-icons gray">add</i>
                                <span class="mdc-button__label gray">Helpful</span>
                            </Fragment>
                        }
                    </button>
                </div>
                <div className="etc count"><span>{this.state.like_cnt}</span>명의 의사가 도움된다고 했습니다.</div>
			</Fragment>
		);
	}
}

export default withApollo(Helpful);
