import React from 'react';
import _ from "lodash";
import $ from 'jquery';
import { isMobileOnly } from 'react-device-detect';

class SearchDetailLayer extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            dgsbjt_cd_arr: [],
            dgsbjt_dtl_cd_arr: [],
            agg_arr: [],
            sex_tp_cd_arr: [],
            cl_cd_arr: [],
            bed_arr: [],
            sido_cd_arr: []
        }
    }

    scrollToTop = () => {
        //$('#accordian-list').scrollTop(0);
    }

    changeSearchCondition = (key, e) => {
		//console.log("key", key);
		//console.log("value", e.target, e.target.type === 'checkbox' ? e.target.checked : e.target.value, e.target.name);

        const value = e.target.value;
        const checked = e.target.checked;
        if(key === "dgsbjt_cd_arr"){
            if(checked){
                this.setState({dgsbjt_cd_arr: [...this.state.dgsbjt_cd_arr, value]});
                if(value === "01"){
                    this.setState({dgsbjt_dtl_cd_arr: []});
                    $("#im_spc_area input").attr("disabled", true);
                    $("#im_spc_area span").show();
                }
            }
            else{
                this.setState({dgsbjt_cd_arr: this.state.dgsbjt_cd_arr.filter(val => val !== value)});
                if(value === "01"){
                    $("#im_spc_area input").attr("disabled", false);
                    $("#im_spc_area span").hide();
                }
            }
		}
		if(key === "dgsbjt_dtl_cd_arr"){
			if(checked){
                this.setState({dgsbjt_dtl_cd_arr: [...this.state.dgsbjt_dtl_cd_arr, value]});
            }
            else{
                this.setState({dgsbjt_dtl_cd_arr: this.state.dgsbjt_dtl_cd_arr.filter(val => val !== value)});
            }
		}
		if(key === "agg_arr"){
			if(checked){
                this.setState({agg_arr: [...this.state.agg_arr, value]});
            }
            else{
                this.setState({agg_arr: this.state.agg_arr.filter(val => val !== value)});
            }
		}
		if(key === "sex_tp_cd_arr"){
			if(checked){
                this.setState({sex_tp_cd_arr: [...this.state.sex_tp_cd_arr, value]});
            }
            else{
                this.setState({sex_tp_cd_arr: this.state.sex_tp_cd_arr.filter(val => val !== value)});
            }
		}
		if(key === "cl_cd_arr"){
			if(checked){
                this.setState({cl_cd_arr: [...this.state.cl_cd_arr, value]});
            }
            else{
                this.setState({cl_cd_arr: this.state.cl_cd_arr.filter(val => val !== value)});
            }
		}
		if(key === "bed_arr"){
			if(checked){
                this.setState({bed_arr: [...this.state.bed_arr, value]});
            }
            else{
                this.setState({bed_arr: this.state.bed_arr.filter(val => val !== value)});
            }
		}
		if(key === "sido_cd_arr"){
			if(checked){
                this.setState({sido_cd_arr: [...this.state.sido_cd_arr, value]});
            }
            else{
                this.setState({sido_cd_arr: this.state.sido_cd_arr.filter(val => val !== value)});
            }
		}
    }

    submitCondition = () => {
        const { dgsbjt_cd_arr, dgsbjt_dtl_cd_arr, agg_arr, sex_tp_cd_arr, cl_cd_arr, bed_arr, sido_cd_arr } = this.state;
        this.props.onChangeSearchCondition(dgsbjt_cd_arr, dgsbjt_dtl_cd_arr, agg_arr, sex_tp_cd_arr, cl_cd_arr, bed_arr, sido_cd_arr);
    }

    checked = (code_list, code) => {
        //console.log('checked', code_list, code)
        if(!code_list || code_list.length === 0) return false;
        const check_flag = code_list.filter(val => val === code).length > 0;
        //console.log('checked', check_flag);
        return check_flag;
    }

    resetSearchForm = () => {
        this.setState({
            dgsbjt_cd_arr: [],
            dgsbjt_dtl_cd_arr: [],
            agg_arr: [],
            sex_tp_cd_arr: [],
            cl_cd_arr: [],
            bed_arr: [],
            sido_cd_arr: []
        })

        $("#im_spc_area input").attr("disabled", false);
        $("#im_spc_area span").hide();
    }

    restoreSearchForm = () => {
        this.setState({
            dgsbjt_cd_arr: this.props.dgsbjt_cd_arr,
            dgsbjt_dtl_cd_arr: this.props.dgsbjt_dtl_cd_arr,
            agg_arr: this.props.agg_arr,
            sex_tp_cd_arr: this.props.sex_tp_cd_arr,
            cl_cd_arr: this.props.cl_cd_arr,
            bed_arr: this.props.bed_arr,
            sido_cd_arr: this.props.sido_cd_arr
        });
    }

    componentWillReceiveProps(nextProps) {
		if (nextProps.detail_search_form_init_flag !== this.props.detail_search_form_init_flag) {
            //console.log('componentWillReceiveProps', nextProps.detail_search_form_init_flag, this.props.detail_search_form_init_flag)
            //this.scrollToTop();
			this.resetSearchForm();
		}
	}

    render() {
        const { data } = this.props;

        return (
            <div id="schoption" className="modal fade mdc-dialog" style={{"-webkitOverflowScrolling": "touch"}}>
                <div className="modal-dialog">
                    <div className="contwrap modal-content">
                        {
                            !isMobileOnly &&
                            <p className="mdl-button close" data-dismiss="modal" onClick={() => this.restoreSearchForm()}>
                                <span className="btn-closeApply"></span>
                            </p>
                        }
                        <div class="modal-body" style={{maxHeight: "calc(100vh - 180px)", overflowY: "auto"}}>
                            <div id="accordian-list">
                                <ul>
                                    <li className="category">
                                        <h3 className="title">전문과</h3>
                                        <div className="b-description_readmore option1_readmore">
                                            <ul className="checkbox_area">
                                                {
                                                    _.filter(data.getCommonCodeList, {"group_code": "DGSBJT_CD"}).map((item, index) =>
                                                        <li key={index}>
                                                            <input type="checkbox" checked={this.checked(this.state.dgsbjt_cd_arr, item.code)} name="dgsbjt_cd" id={`dgsbjt_cd_${item.code}`} value={item.code} className="SPC" onChange={(e)=>this.changeSearchCondition("dgsbjt_cd_arr", e)} />
                                                            <label htmlFor={`dgsbjt_cd_${item.code}`}>{item.code_name}</label>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="category" id="im_spc_area">
                                        <h3 className="title">세부내과</h3>
                                        <div className="b-description_readmore option1_readmore">
                                            <ul className="checkbox_area">
                                                {
                                                    _.filter(data.getCommonCodeList, {"group_code": "DGSBJT_DTL_CD"}).map((item, index) =>
                                                        <li key={index}>
                                                            <input type="checkbox" checked={this.checked(this.state.dgsbjt_dtl_cd_arr, item.code)} name="dgsbjt_dtl_cd" id={`dgsbjt_dtl_cd_${item.code}`} value={item.code} className="SPC" onChange={(e)=>this.changeSearchCondition("dgsbjt_dtl_cd_arr", e)} />
                                                            <label htmlFor={`dgsbjt_dtl_cd_${item.code}`}>{item.code_name}</label>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            <span className="dtl_noti" style={{display: "none"}}>세부내과를 선택하려면 내과 전체를 해제하세요</span>
                                        </div>
                                    </li>
                                    <li className="category">
                                        <h3 className="title">환자나이</h3>
                                        <div className="b-description_readmore option2_readmore">
                                            <ul className="checkbox_area">
                                                {
                                                    _.filter(data.getCommonCodeList, {"group_code": "AGG"}).map((item, index) =>
                                                        <li key={index}>
                                                            <input type="checkbox" checked={this.checked(this.state.agg_arr, item.code)} name="agg" id={`agg_${item.code}`} value={item.code} className="SPC" onChange={(e)=>this.changeSearchCondition("agg_arr", e)} />
                                                            <label htmlFor={`agg_${item.code}`}>{item.code_name}</label>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="category">
                                        <h3 className="title">환자성별</h3>
                                        <div className="b-description_readmore option3_readmore">
                                            <ul className="checkbox_area">
                                                {
                                                    _.filter(data.getCommonCodeList, {"group_code": "SEX_TP_CD"}).map((item, index) =>
                                                        <li key={index}>
                                                            <input type="checkbox" checked={this.checked(this.state.sex_tp_cd_arr, item.code)} name="sex_tp_cd" id={`sex_tp_cd_${item.code}`} value={item.code} className="SPC" onChange={(e)=>this.changeSearchCondition("sex_tp_cd_arr", e)} />
                                                            <label htmlFor={`sex_tp_cd_${item.code}`}>{item.code_name}</label>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="category">
                                        <h3 className="title">병원타입</h3>
                                        <div className="b-description_readmore option4_readmore">
                                            <ul className="checkbox_area">
                                                {
                                                    _.filter(data.getCommonCodeList, {"group_code": "CL_CD"}).map((item, index) =>
                                                        <li key={index}>
                                                            <input type="checkbox" checked={this.checked(this.state.cl_cd_arr, item.code)} name="cl_cd" id={`cl_cd_${item.code}`} value={item.code} className="SPC" onChange={(e)=>this.changeSearchCondition("cl_cd_arr", e)} />
                                                            <label htmlFor={`cl_cd_${item.code}`}>{item.code_name}</label>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="category">
                                        <h3 className="title">병상규모</h3>
                                        <div className="b-description_readmore option5_readmore">
                                            <ul className="checkbox_area">
                                                {
                                                    _.filter(data.getCommonCodeList, {"group_code": "BED"}).map((item, index) =>
                                                        <li key={index}>
                                                            <input type="checkbox" checked={this.checked(this.state.bed_arr, item.code)} name="bed" id={`bed_${item.code}`} value={item.code} className="SPC" onChange={(e)=>this.changeSearchCondition("bed_arr", e)} />
                                                            <label htmlFor={`bed_${item.code}`}>{item.code_name}</label>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="category">
                                        <h3 className="title">지역 (시/도)</h3>
                                        <div className="b-description_readmore option6_readmore">
                                            <ul className="checkbox_area">
                                                {
                                                    _.filter(data.getCommonCodeList, {"group_code": "SIDO_CD"}).map((item, index) =>
                                                        <li key={index}>
                                                            <input type="checkbox" checked={this.checked(this.state.sido_cd_arr, item.code)} name="sido_cd" id={`sido_cd_${item.code}`} value={item.code} className="SPC" onChange={(e)=>this.changeSearchCondition("sido_cd_arr", e)} />
                                                            <label htmlFor={`sido_cd_${item.code}`}>{item.code_name}</label>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div className="btn_type2 border-top-1px">
                            <button className="mdc-button mdc-button--outlined reset" onClick={() => this.resetSearchForm() }>초기화</button>
                            <button className="mdc-button mdc-button--outlined cancel close" data-dismiss="modal" onClick={() => this.restoreSearchForm()}>취소</button>
                            <button id="btn_detail_search" className="mdc-button mdc-button--raised select" data-dismiss="modal" onClick={()=> this.submitCondition()}>적용</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SearchDetailLayer;