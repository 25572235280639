import gql from 'graphql-tag';

export const STAT_CNT = gql`
query select($kcd_code_arr: [String], $ctg_code3: String, $ingred_code_grp: String, $combi_flag: String, $method: String, $dgsbjt_cd_arr: [String], $dgsbjt_dtl_cd_arr: [String], $agg_arr: [String], $sex_tp_cd_arr: [String], $cl_cd_arr: [String], $bed_arr: [String], $sido_cd_arr: [String], $place: String, $atc_code: String, $ingred_code_grp_arr: [String], $ingred_combi_flag: String, $stat_year: Int) {
	getStatTotalCnt(
		kcd_code_arr: $kcd_code_arr,
		ctg_code3: $ctg_code3,
		ingred_code_grp: $ingred_code_grp,
		combi_flag: $combi_flag,
		method: $method,
		dgsbjt_cd_arr: $dgsbjt_cd_arr,
		dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
		agg_arr: $agg_arr,
		sex_tp_cd_arr: $sex_tp_cd_arr,
		cl_cd_arr: $cl_cd_arr,
		bed_arr: $bed_arr,
		sido_cd_arr: $sido_cd_arr,
		place: $place,
		atc_code: $atc_code,
		ingred_code_grp_arr: $ingred_code_grp_arr,
		ingred_combi_flag: $ingred_combi_flag,
		stat_year: $stat_year
		
	) {
		count
	}
}
`;

export const STAT_LIST = gql`
	query select($kcd_code_arr: [String], $ctg_code3: String, $ingred_code_grp: String, $combi_flag: String, $method: String, $dgsbjt_cd_arr: [String], $dgsbjt_dtl_cd_arr: [String], $agg_arr: [String], $sex_tp_cd_arr: [String], $cl_cd_arr: [String], $bed_arr: [String], $sido_cd_arr: [String], $place: String, $atc_code: String, $ingred_code_grp_arr: [String], $ingred_combi_flag: String, $stat_year: Int) {
		getAmtStat(
			kcd_code_arr: $kcd_code_arr,
			ctg_code3: $ctg_code3,
			ingred_code_grp: $ingred_code_grp,
			combi_flag: $combi_flag,
			method: $method,
			dgsbjt_cd_arr: $dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
			agg_arr: $agg_arr,
			sex_tp_cd_arr: $sex_tp_cd_arr,
			cl_cd_arr: $cl_cd_arr,
			bed_arr: $bed_arr,
			sido_cd_arr: $sido_cd_arr,
			place: $place,
			atc_code: $atc_code,
			ingred_code_grp_arr: $ingred_code_grp_arr,
			ingred_combi_flag: $ingred_combi_flag,
			stat_year: $stat_year
		) {
			code
			name: code_name
			y: cnt
			avg_unit_cost
		},
		sex_tp_cd_stat: getStatByTypeVariable(
			stat_type: "sex_tp_cd",
			kcd_code_arr: $kcd_code_arr,
			ctg_code3: $ctg_code3,
			ingred_code_grp: $ingred_code_grp,
			combi_flag: $combi_flag,
			method: $method,
			dgsbjt_cd_arr: $dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
			agg_arr: $agg_arr,
			sex_tp_cd_arr: $sex_tp_cd_arr,
			cl_cd_arr: $cl_cd_arr,
			bed_arr: $bed_arr,
			sido_cd_arr: $sido_cd_arr,
			place: $place,
			atc_code: $atc_code,
			ingred_code_grp_arr: $ingred_code_grp_arr,
			ingred_combi_flag: $ingred_combi_flag,
			stat_year: $stat_year
		) {
			code
			name: code_name
			y: cnt
		},
		agg_stat: getStatByTypeVariable(
			stat_type: "agg",
			kcd_code_arr: $kcd_code_arr,
			ctg_code3: $ctg_code3,
			ingred_code_grp: $ingred_code_grp,
			combi_flag: $combi_flag,
			method: $method,
			dgsbjt_cd_arr: $dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
			agg_arr: $agg_arr,
			sex_tp_cd_arr: $sex_tp_cd_arr,
			cl_cd_arr: $cl_cd_arr,
			bed_arr: $bed_arr,
			sido_cd_arr: $sido_cd_arr,
			place: $place,
			atc_code: $atc_code,
			ingred_code_grp_arr: $ingred_code_grp_arr,
			ingred_combi_flag: $ingred_combi_flag,
			stat_year: $stat_year
		) {
			code
			name: code_name
			cnt
			total_cnt
    		data: share_per
		},
		cl_cd_stat: getStatByTypeVariable(
			stat_type: "cl_cd",
			kcd_code_arr: $kcd_code_arr,
			ctg_code3: $ctg_code3,
			ingred_code_grp: $ingred_code_grp,
			combi_flag: $combi_flag,
			method: $method,
			dgsbjt_cd_arr: $dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
			agg_arr: $agg_arr,
			sex_tp_cd_arr: $sex_tp_cd_arr,
			cl_cd_arr: $cl_cd_arr,
			bed_arr: $bed_arr,
			sido_cd_arr: $sido_cd_arr,
			place: $place,
			atc_code: $atc_code,
			ingred_code_grp_arr: $ingred_code_grp_arr,
			ingred_combi_flag: $ingred_combi_flag,
			stat_year: $stat_year
		) {
			code
			name: code_name
			y: cnt
		},
		dgsbjt_cd_stat: getStatByTypeVariable(
			stat_type: "dgsbjt_cd",
			kcd_code_arr: $kcd_code_arr,
			ctg_code3: $ctg_code3,
			ingred_code_grp: $ingred_code_grp,
			combi_flag: $combi_flag,
			method: $method,
			dgsbjt_cd_arr: $dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
			agg_arr: $agg_arr,
			sex_tp_cd_arr: $sex_tp_cd_arr,
			cl_cd_arr: $cl_cd_arr,
			bed_arr: $bed_arr,
			sido_cd_arr: $sido_cd_arr,
			place: $place,
			atc_code: $atc_code,
			ingred_code_grp_arr: $ingred_code_grp_arr,
			ingred_combi_flag: $ingred_combi_flag,
			stat_year: $stat_year
		) {
			code
			name: code_name
			cnt
			total_cnt
    		data: share_per
		},
		dgsbjt_dtl_cd_stat: getStatByTypeVariable(
			stat_type: "dgsbjt_dtl_cd",
			kcd_code_arr: $kcd_code_arr,
			ctg_code3: $ctg_code3,
			ingred_code_grp: $ingred_code_grp,
			combi_flag: $combi_flag,
			method: $method,
			dgsbjt_cd_arr: $dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
			agg_arr: $agg_arr,
			sex_tp_cd_arr: $sex_tp_cd_arr,
			cl_cd_arr: $cl_cd_arr,
			bed_arr: $bed_arr,
			sido_cd_arr: $sido_cd_arr,
			place: $place,
			atc_code: $atc_code,
			ingred_code_grp_arr: $ingred_code_grp_arr,
			ingred_combi_flag: $ingred_combi_flag,
			stat_year: $stat_year
		) {
			code
			name: code_name
			cnt
			total_cnt
    		data: share_per
		},
		sido_cd_stat: getStatByTypeVariable(
			stat_type: "sido_cd",
			kcd_code_arr: $kcd_code_arr,
			ctg_code3: $ctg_code3,
			ingred_code_grp: $ingred_code_grp,
			combi_flag: $combi_flag,
			method: $method,
			dgsbjt_cd_arr: $dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
			agg_arr: $agg_arr,
			sex_tp_cd_arr: $sex_tp_cd_arr,
			cl_cd_arr: $cl_cd_arr,
			bed_arr: $bed_arr,
			sido_cd_arr: $sido_cd_arr,
			place: $place,
			atc_code: $atc_code,
			ingred_code_grp_arr: $ingred_code_grp_arr,
			ingred_combi_flag: $ingred_combi_flag,
			stat_year: $stat_year
		) {
			code
			name: code_name
			cnt
			total_cnt
    		data: share_per
		},
		bed_stat: getStatByTypeVariable(
			stat_type: "bed",
			kcd_code_arr: $kcd_code_arr,
			ctg_code3: $ctg_code3,
			ingred_code_grp: $ingred_code_grp,
			combi_flag: $combi_flag,
			method: $method,
			dgsbjt_cd_arr: $dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
			agg_arr: $agg_arr,
			sex_tp_cd_arr: $sex_tp_cd_arr,
			cl_cd_arr: $cl_cd_arr,
			bed_arr: $bed_arr,
			sido_cd_arr: $sido_cd_arr,
			place: $place,
			atc_code: $atc_code,
			ingred_code_grp_arr: $ingred_code_grp_arr,
			ingred_combi_flag: $ingred_combi_flag,
			stat_year: $stat_year
		) {
			code
			name: code_name
			cnt
			total_cnt
    		data: share_per
		}
	}
`;

export const SUB_CAPACITY_STAT_LIST = gql`
	query select($kcd_code_arr: [String], $ctg_code3: String, $ingred_code_grp: String, $combi_flag: String, $method: String, $dgsbjt_cd_arr: [String], $dgsbjt_dtl_cd_arr: [String], $agg_arr: [String], $sex_tp_cd_arr: [String], $cl_cd_arr: [String], $bed_arr: [String], $sido_cd_arr: [String], $place: String, $atc_code: String, $unit_amt_code: String, $ingred_code_grp_arr: [String], $ingred_combi_flag: String, $stat_year: Int) {
		tot_injc_ddcnt_exec_fq: getPatternStat(
			stat_type: "tot_injc_ddcnt_exec_fq",
			kcd_code_arr: $kcd_code_arr,
			ctg_code3: $ctg_code3,
			ingred_code_grp: $ingred_code_grp,
			combi_flag: $combi_flag,
			method: $method,
			dgsbjt_cd_arr: $dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
			agg_arr: $agg_arr,
			sex_tp_cd_arr: $sex_tp_cd_arr,
			cl_cd_arr: $cl_cd_arr,
			bed_arr: $bed_arr,
			sido_cd_arr: $sido_cd_arr,
			place: $place,
			atc_code: $atc_code,
			unit_amt_code: $unit_amt_code,
			ingred_code_grp_arr: $ingred_code_grp_arr,
			ingred_combi_flag: $ingred_combi_flag,
			stat_year: $stat_year
		) {
			code
			name: code_name
			y: cnt
		},
		dy1_mdct_qty: getPatternStat(
			stat_type: "dy1_mdct_qty",
			kcd_code_arr: $kcd_code_arr,
			ctg_code3: $ctg_code3,
			ingred_code_grp: $ingred_code_grp,
			combi_flag: $combi_flag,
			method: $method,
			dgsbjt_cd_arr: $dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
			agg_arr: $agg_arr,
			sex_tp_cd_arr: $sex_tp_cd_arr,
			cl_cd_arr: $cl_cd_arr,
			bed_arr: $bed_arr,
			sido_cd_arr: $sido_cd_arr,
			place: $place,
			atc_code: $atc_code,
			unit_amt_code: $unit_amt_code,
			ingred_code_grp_arr: $ingred_code_grp_arr,
			ingred_combi_flag: $ingred_combi_flag,
			stat_year: $stat_year
		) {
			code
			name: code_name
			y: cnt
		},
		fq1_mdct_qty: getPatternStat(
			stat_type: "fq1_mdct_qty",
			kcd_code_arr: $kcd_code_arr,
			ctg_code3: $ctg_code3,
			ingred_code_grp: $ingred_code_grp,
			combi_flag: $combi_flag,
			method: $method,
			dgsbjt_cd_arr: $dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: $dgsbjt_dtl_cd_arr,
			agg_arr: $agg_arr,
			sex_tp_cd_arr: $sex_tp_cd_arr,
			cl_cd_arr: $cl_cd_arr,
			bed_arr: $bed_arr,
			sido_cd_arr: $sido_cd_arr,
			place: $place,
			atc_code: $atc_code,
			unit_amt_code: $unit_amt_code,
			ingred_code_grp_arr: $ingred_code_grp_arr,
			ingred_combi_flag: $ingred_combi_flag,
			stat_year: $stat_year
		) {
			code
			name: code_name
			y: cnt
		}
	}
`;