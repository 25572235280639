import React, {Component, Fragment} from 'react';
import clsx from 'clsx';
import {Query} from "react-apollo";
import {inject, observer} from "mobx-react";

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import STitle from './S_Title';
import SNotLogin from './S_NotLogin';
import SFormSelectBox from "./S_FormSelectBox";
import SBoxDemandContent from "./S_BoxDemandContent";
import withTab from "./withTab";

import CommonFunction from "../../common/CommonFunction";
import { ORG_TYPE, SPC_CODE, HTP_CODE, LOC_CODE} from "./UserConstants";

import {
    DEMAND_MEDI,
    DEMAND_MEDI_TREND,
    DEMAND_KOSIS,
    DEMAND_KOSIS_TREND,
    DEMAND_MEDI_COMPARE,
    DEMAND_MEDI_TREND_COMPARE,
    DEMAND_KOSIS_COMPARE,
    DEMAND_KOSIS_TREND_COMPARE, HLINK_AD
} from "../../queries/salary/salary";
import Skeleton from "@material-ui/lab/Skeleton";
import SLongBarGraph from "./S_LongBarGraph";
import SLongLineGraph from "./S_LongLineGraph";
import SHlinkADBox from "./S_HlinkADBox";
import useStyles from './SalaryStyle';

@inject("salaryStore")
@observer
class S_Demand extends Component {
    state = {
        login: true,
        value: 0,
        filter_demand_overview_term: "year",
        filter_demand_overview_kosis: "소계",
        filter_demand_work_term: "year",
    }

    componentDidMount = () => {
        CommonFunction.pageview('연봉인덱스', '초빙수요'); //Google Analytics

        this.props.handleCurrentMenu(3)
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        })
    };

    handleChangeFilter = (event, id) => {
        //console.log("event", event.target.value, id);

        if(id === "demand_overview_term"){
            this.setState({
                filter_demand_overview_term: event.target.value
            })
        }else if(id === "demand_overview_kosis"){
            this.setState({
                filter_demand_overview_kosis: event.target.value
            })
        }else if(id === "demand_work_term"){
            this.setState({
                filter_demand_work_term: event.target.value
            })
        }
    }

    render() {
        const { classes, LinkTab, TabPanel, a11yProps } = this.props;
        const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
        const { filter_demand_overview_term, filter_demand_overview_kosis, filter_demand_work_term } = this.state;
        const { salaryStore } = this.props;
        const { userProfile } = this.props.salaryStore;
        const org_type = CommonFunction.getCodeName(userProfile.u_hospital_group_code, ORG_TYPE);

        return (
            <Fragment>
                {
                    !salaryStore.isLogin ?
                    <SNotLogin />
                    :
                    <Container maxWidth="lg" className={classes.container}>
                        <div>
                            <Typography component="div" style={{float: "left"}} gutterBottom>
                                <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                    초빙수요/국내의사 개요
                                </Typography>
                                <Typography component="span" variant="caption">
                                    초빙수요는 메디게이트 공고 게시물, 국내의사는 통계청 데이터를 기반으로 작성되었습니다
                                </Typography>
                            </Typography>
                            <Paper className="u_spec">
                                <Grid container spacing={3}>
                                    <Query
                                        query={DEMAND_MEDI}
                                        variables={{
                                            spc_code: userProfile.u_spc_code,
                                            hop_loc_code: userProfile.u_zip_zone,
                                            org_type: org_type,
                                            period_type: filter_demand_overview_term,
                                        }}
                                        fetchPolicy="network-only"
                                    >
                                        {({ data, loading, error }) => {
                                            //if (loading) return <p></p>;
                                            if (error) return <p>{error.message}</p>;

                                            return (
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <Paper className={fixedHeightPaper}>
                                                        <div className="combo_3 type2">
                                                            <Typography component="div" gutterBottom>
                                                                <SFormSelectBox
                                                                    id="demand_overview_term"
                                                                    labelId="demand_overview_term_label"
                                                                    labelText="기간"
                                                                    selectedValue={filter_demand_overview_term}
                                                                    type="term"
                                                                    change={this.handleChangeFilter}
                                                                />
                                                            </Typography>
                                                        </div>
                                                        {
                                                            // data.getMediDemandData && !loading ?
                                                            data && data.getMediDemandData ?
                                                            <SBoxDemandContent
                                                                data={data.getMediDemandData}
                                                                orgName={org_type}
                                                                locName={CommonFunction.getCodeName(userProfile.u_zip_zone, LOC_CODE)}
                                                                spcName={CommonFunction.getCodeName(userProfile.u_spc_code, SPC_CODE)}
                                                                title="메디게이트 초빙수요"
                                                                data_standard="medigate"
                                                            />
                                                            :
                                                            <Skeleton variant="rect" width="100%" height={240} />
                                                        }
                                                    </Paper>
                                                </Grid>
                                            )
                                        }}
                                    </Query>                                    
                                    <Query
                                        query={DEMAND_MEDI_TREND}
                                        fetchPolicy="network-only"
                                    >
                                        {({ data, loading, error }) => {
                                            //if (loading) return <p></p>;
                                            if (error) return <p>{error.message}</p>;

                                            return (
                                                <Grid item xs={12} md={6} lg={8}>
                                                    <div className="graph_trend">
                                                        <STitle align="center" className="graph-tit" style={{padding:'27px 0 15px 22px'}}>연간 메디게이트 초빙 추이</STitle>
                                                        {
                                                            // data.getAllMediDemandTrendChartData && !loading ?
                                                            data && data.getAllMediDemandTrendChartData ?
                                                            <SLongLineGraph
                                                                data={data.getAllMediDemandTrendChartData}
                                                                fixedHeightPaper={fixedHeightPaper}
                                                                loopCount={1}
                                                                isAnnotation="Y"
                                                                legendDisabled={true}
                                                            />
                                                            :
                                                            <Skeleton variant="rect" width="100%" height={240} />
                                                        }
                                                    </div>
                                                </Grid>
                                            )
                                        }}
                                    </Query>
                                </Grid>
                            </Paper>

                            <Paper className="u_spec">
                                <Grid container spacing={3}>
                                    <Query
                                        query={DEMAND_KOSIS}
                                        variables={{
                                            spc_name: CommonFunction.getCodeName(userProfile.u_spc_code, SPC_CODE),
                                            hop_loc_name: CommonFunction.getCodeName(userProfile.u_zip_zone, LOC_CODE),
                                            org_name: CommonFunction.getCodeName(org_type, HTP_CODE),
                                        }}
                                        fetchPolicy="network-only"
                                    >
                                        {({ data, loading, error }) => {
                                            //if (loading) return <p></p>;
                                            if (error) return <p>{error.message}</p>;

                                            return (
                                                <Grid item xs={12} md={6} lg={4}>
                                                    {/* <STitle align="center" style={{marginBottom: "16px"}}>국내의사</STitle> */}
                                                    <Paper className={fixedHeightPaper}>
                                                        {
                                                            data && data.getKosisDemandData ?
                                                                <SBoxDemandContent
                                                                    data={data.getKosisDemandData}
                                                                    orgName={org_type}
                                                                    locName={CommonFunction.getCodeName(userProfile.u_zip_zone, LOC_CODE)}
                                                                    spcName={CommonFunction.getCodeName(userProfile.u_spc_code, SPC_CODE)}
                                                                    title="국내의사"
                                                                    data_standard="kosis"
                                                                />
                                                                :
                                                                <Skeleton variant="rect" width="100%" height={240} />
                                                        }
                                                    </Paper>
                                                </Grid>
                                            )
                                        }}
                                    </Query>
                                    <Grid item xs={12} md={6} lg={8}>
                                        <Grid item container direction={"row"} style={{position:'relative'}}>
                                            <Grid item style={{flexGrow:2}}>
                                                <STitle align="center" className="graph-tit" style={{padding:'27px 0 15px 22px'}}>
                                                    <span className="tittxt">2010년 이후 의사수 추이</span>
                                                </STitle>
                                            </Grid>
                                            <div className="combo_3 type2">
                                                <Grid item style={{flexGrow:0}}>
                                                    <Typography component="div">
                                                        <SFormSelectBox
                                                            id="demand_overview_kosis"
                                                            labelId="demand_overview_kosis_label"
                                                            labelText=""
                                                            selectedValue={filter_demand_overview_kosis}
                                                            type="demand"
                                                            change={this.handleChangeFilter}
                                                        />
                                                    </Typography>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <div className="graph_trend">
                                            <Query
                                                query={DEMAND_KOSIS_TREND}
                                                variables={{
                                                    prof_type2: filter_demand_overview_kosis,
                                                    greater_than_year: '2010'
                                                }}
                                                fetchPolicy="network-only"
                                            >
                                                {({ data, loading, error }) => {
                                                    //if (loading) return <p></p>;
                                                    if (error) return <p>{error.message}</p>;

                                                    return (
                                                        <Fragment>
                                                            {
                                                                data && data.getAllKosisDemandTrendChartData ?
                                                                    <SLongLineGraph
                                                                        data={data.getAllKosisDemandTrendChartData}
                                                                        fixedHeightPaper={fixedHeightPaper}
                                                                        loopCount={1}
                                                                        isAnnotation="N"
                                                                        legendDisabled={true}
                                                                    />
                                                                    :
                                                                    <Skeleton variant="rect" width="100%" height={240} />
                                                            }
                                                        </Fragment>
                                                    )
                                                }}
                                            </Query>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        {/* 20200514 요청 삭제
                        <div className="hlink_ad" style={{paddingTop: '60px'}}>
                            <Query
                                query={HLINK_AD}
                                variables={{
                                    spc_code: userProfile.u_spc_code,
                                    limit_cnt: 1
                                }}
                            >
                                {({ data, loading, error }) => {
                                    if (loading) return <p></p>;
                                    if (error) return <p>{error.message}</p>;

                                    return (
                                        data.getHlinkAd && data.getHlinkAd.length > 0 && data.getHlinkAd[0].svc_cont_idx !== 0 &&
                                        <SHlinkADBox
                                            data={data.getHlinkAd}
                                            fixedHeightPaper={fixedHeightPaper}
                                        />
                                    )
                                }}
                            </Query>
                        </div>
                        */}
                        <div className="tabswrap" style={{paddingTop:'60px',marginTop:'30px',borderTop:'1px solid rgba(0,0,0,0.1)'}}>
                            <AppBar position="static" style={{zIndex: 500}}>
                                <div className="tabs type2">
                                    <Tabs
                                        variant="fullWidth"
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                        aria-label="nav tabs example"
                                    >
                                        <LinkTab label="초빙 수요" href="/recjob" {...a11yProps(0)} />
                                        <LinkTab label="대한민국 의사" href="/doctor" {...a11yProps(1)} />
                                    </Tabs>
                                </div>
                            </AppBar>
                            <TabPanel value={this.state.value} index={0}>
                                <div>
                                    <Typography component="div" style={{float: "left"}} gutterBottom>
                                        <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                            초빙수요 비교
                                        </Typography>
                                        <Typography component="span" variant="caption">
                                            메디게이트 공고 게시물의 초빙수요의 조건별 비교입니다
                                        </Typography>
                                    </Typography>
                                    <Typography component="div" className="combo_1" gutterBottom>
                                        <SFormSelectBox
                                            id="demand_work_term"
                                            labelId="demand_work_term_label"
                                            labelText="기간"
                                            selectedValue={filter_demand_work_term}
                                            type="term"
                                            change={this.handleChangeFilter}
                                        />
                                    </Typography>
                                    <div className="pagegraph">
                                        <Grid container spacing={3}>
                                            <Query
                                                query={DEMAND_MEDI_COMPARE}
                                                variables={{
                                                    spc_code: userProfile.u_spc_code,
                                                    hop_loc_code: userProfile.u_zip_zone,
                                                    org_type: org_type,
                                                    period_type: filter_demand_work_term,
                                                }}
                                                fetchPolicy="network-only"
                                            >
                                                {({ data, loading, error }) => {
                                                    if (error) return <p>{error.message}</p>;

                                                    return (
                                                        <SLongBarGraph
                                                            data={data ? data.getMediDemandCompareChartData : []}
                                                            fixedHeightPaper={fixedHeightPaper}
                                                            loopCount={3}
                                                            loading={loading}
                                                            type={1}
                                                        />
                                                    )
                                                }}
                                            </Query>
                                        </Grid>
                                    </div>
                                        <div className="hlink_ad" style={{paddingTop: '60px'}}>                        
                                            <Query
                                                query={HLINK_AD}
                                                variables={{
                                                    spc_code: userProfile.u_spc_code,
                                                    limit_cnt: 1
                                                }}
                                                fetchPolicy="network-only"
                                            >
                                                {({ data, loading, error }) => {
                                                    if (loading) return <p></p>;
                                                    if (error) return <p>{error.message}</p>;

                                                    return (
                                                        data.getHlinkAd && data.getHlinkAd.length > 0 && data.getHlinkAd[0].svc_cont_idx !== 0 &&
                                                        <SHlinkADBox
                                                            data={data.getHlinkAd}
                                                            fixedHeightPaper={fixedHeightPaper}
                                                        />
                                                    )
                                                }}
                                            </Query>
                                        </div>
                                </div>
                                <div style={{marginTop: "70px"}}>
                                    <Typography component="div" style={{float: "left"}} gutterBottom>
                                        <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                            초빙수요 추이
                                        </Typography>
                                        <Typography component="span" variant="caption">
                                            선생님이 해당하는 전문과, 지역, 병원 타입의 메디게이트 초빙 게재 수요 트렌드입니다
                                        </Typography>
                                    </Typography>
                                    <div className="pagegraph">
                                        <Grid container spacing={3}>
                                            <Query
                                                query={DEMAND_MEDI_TREND_COMPARE}
                                                variables={{
                                                    spc_code: userProfile.u_spc_code,
                                                    hop_loc_code: userProfile.u_zip_zone,
                                                    org_type: org_type
                                                }}
                                                fetchPolicy="network-only"
                                            >
                                                {({ data, loading, error }) => {
                                                    if (error) return <p>{error.message}</p>;
                                                    return (
                                                        <SLongLineGraph
                                                            data={data ? data.getMediDemandTrendChartData : []}
                                                            fixedHeightPaper={fixedHeightPaper}
                                                            loopCount={3}
                                                            isAnnotation="Y"
                                                            arrName={[
                                                                CommonFunction.getCodeName(userProfile.u_spc_code, SPC_CODE),
                                                                CommonFunction.getCodeName(userProfile.u_zip_zone, LOC_CODE),
                                                                CommonFunction.getCodeName(org_type, HTP_CODE)]
                                                            }
                                                            loading={loading}
                                                        />
                                                    )
                                                }}
                                            </Query>
                                        </Grid>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>
                                <div>
                                    <Typography component="div" style={{float: "left"}} gutterBottom>
                                        <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                            대한민국 의사 비교
                                        </Typography>
                                        <Typography component="span" variant="caption">
                                            전문과, 지역, 병원 타입에 따른 국내 의사 비교입니다. 해당 데이터의 출처는 통계청입니다
                                        </Typography>
                                    </Typography>
                                    <div className="pagegraph">
                                        <Grid container spacing={3}>
                                            <Query
                                                query={DEMAND_KOSIS_COMPARE}
                                                fetchPolicy="network-only"
                                            >
                                                {({ data, loading, error }) => {
                                                    if (error) return <p>{error.message}</p>;

                                                    return (
                                                        <SLongBarGraph
                                                            data={data ? data.getKosisDemandCompareChartData : []}
                                                            fixedHeightPaper={fixedHeightPaper}
                                                            loopCount={3}
                                                            loading={loading}
                                                            type={1}
                                                        />
                                                    )
                                                }}
                                            </Query>
                                        </Grid>
                                    </div>
                                </div>
                                <div>
                                    <Paper>
                                        <div className="hlink_ad" style={{paddingTop:'60px'}}>
                                            <Query
                                                query={HLINK_AD}
                                                variables={{
                                                    spc_code: userProfile.u_spc_code,
                                                    limit_cnt: 1
                                                }}
                                                fetchPolicy="network-only"
                                            >
                                                {({ data, loading, error }) => {
                                                    if (loading) return <p></p>;
                                                    if (error) return <p>{error.message}</p>;

                                                    return (
                                                        data.getHlinkAd && data.getHlinkAd.length > 0 && data.getHlinkAd[0].svc_cont_idx !== 0 &&
                                                        <SHlinkADBox
                                                            data={data.getHlinkAd}
                                                            fixedHeightPaper={fixedHeightPaper}
                                                        />
                                                    )
                                                }}
                                            </Query>
                                        </div>
                                    </Paper>
                                </div>
                                <div style={{marginTop: "70px"}}>
                                    <Typography component="div" style={{float: "left"}} gutterBottom>
                                        <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                            대한민국 의사 추이
                                       </Typography>
                                        <Typography component="span" variant="caption">
                                            최근 1년 국내 의사 추이로 데이터의 출처는 통계청입니다
                                        </Typography>
                                    </Typography>
                                    <div className="pagegraph">
                                        <Grid container spacing={3}>
                                            <Query
                                                query={DEMAND_KOSIS_TREND_COMPARE}
                                                variables={{
                                                    spc_name: CommonFunction.getCodeName(userProfile.u_spc_code, SPC_CODE),
                                                    hop_loc_name: CommonFunction.getCodeName(userProfile.u_zip_zone, LOC_CODE),
                                                    org_name: CommonFunction.getCodeName(org_type, HTP_CODE),
                                                    greater_than_year: '2010'
                                                }}
                                                fetchPolicy="network-only"
                                            >
                                                {({ data, loading, error }) => {
                                                    if (error) return <p>{error.message}</p>;

                                                    return (
                                                        <SLongLineGraph
                                                            data={data ? data.getKosisDemandTrendChartData : []}
                                                            fixedHeightPaper={fixedHeightPaper}
                                                            loopCount={3}
                                                            isAnnotation="N"
                                                            arrName={[
                                                                CommonFunction.getCodeName(userProfile.u_spc_code, SPC_CODE) + '(인턴,전공의 제외)',
                                                                CommonFunction.getCodeName(userProfile.u_zip_zone, LOC_CODE),
                                                                CommonFunction.getCodeName(org_type, HTP_CODE)]
                                                            }
                                                            loading={loading}
                                                            data_standard="kosis"
                                                            org_type={org_type}
                                                        />
                                                    )
                                                }}
                                            </Query>
                                        </Grid>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </Container>
                }
            </Fragment>
        );
    }
}

export default withStyles(useStyles)(withTab(S_Demand))