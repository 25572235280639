import React, {button, useEffect, useState} from 'react';
// import { importMapScripts } from './Kakaomap';
import CssBaseline from "@material-ui/core/CssBaseline";
import _ from "lodash";

// function withMapUtil(WrappedComponent) {
const withMarkerUtils = (WrappedComponent) => (props) => {
    const convPos = (dupCount, posX, posY) => {
        const operations = [
            ['+', '+'],
            ['+', '-'],
            ['-', '+'],
            ['-', '-'],
        ]
        const num = dupCount - 1;
        const group = operations.length;
        const gap = 0.00005;
        const seq = parseInt(num/group) + 1;
        const operation1 = operations[num%group][0];
        const operation2 = operations[num%group][1];
        const convX = eval(parseFloat(posX) + operation1 +  seq * gap).toString();
        const convY = eval(parseFloat(posY) + operation2 +  seq * gap).toString();

        return {
            posX: convX,
            posY: convY
        }
    }

    //병의원 좌표 중첩 방지
    const getConvHospitalList = (hospitalList) => {
        let convHospitalList = [];
        let dupList = [];
        for (let i = 0; i < hospitalList.length; i++) {
            //console.log('convHospitalList', convHospitalList);
            const dupHosPosList = _.filter(convHospitalList,
                {
                    pos_x: hospitalList[i].pos_x,
                    pos_y: hospitalList[i].pos_y
                }
            );
            //console.log('dupHosPosList', dupHosPosList.length, dupHosPosList);

            if(dupHosPosList.length === 0){
                convHospitalList.push({...hospitalList[i]});
            }
            else{
                let dupPos = {
                    pos_x: hospitalList[i].pos_x,
                    pos_y: hospitalList[i].pos_y
                };
                dupList.push(dupPos);

                var dupCount = _.filter(dupList, dupPos).length;
                const pos = convPos(dupCount, hospitalList[i].pos_x, hospitalList[i].pos_y);
                convHospitalList.push({...hospitalList[i], pos_x: pos.posX, pos_y: pos.posY});
            }
        }
        //console.log('convHospitalList', convHospitalList);
        return convHospitalList;
    }


    return (
        <WrappedComponent convertHosList={getConvHospitalList} {...props}/>
    )
}

export default withMarkerUtils;