import gql from 'graphql-tag';

export const COMMON_CODE_LIST = gql`
	query select {
		getCommonCodeList {
			group_code
            code
            code_name
		}
	}
`;