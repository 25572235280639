import React, { Fragment } from 'react';
import _ from "lodash";
import ReactPlayer from 'react-player'
import Swiper from 'swiper';
import $ from 'jquery';
import CommonFunc from '../../../common/CommonFunction';
import striptags from "striptags";

/*
 { id: 'SWeyE3sIVAA',
    link: 'https://www.youtube.com/watch?v=SWeyE3sIVAA',
    kind: 'youtube#video',
    publishedAt: '2013-12-03T06:38:54.000Z',
    channelId: 'UCaJ9hjSrT5NnIhzSoGpd3Yg',
    channelTitle: '배성조',
    title: '진동식지방흡입1- 4- 2 지흡달인 배성조원장 닥터 교육동영상',
    description:
     '2000년 개원 ,경험이 풍부한, 세심한, 지흡달인,지방흡입의사교육자, 비만연구의사회 학술이사, 대한통합의료연구회 창설자. 멀츠필러 교육의사,...',
    thumbnails: { default: [Object], medium: [Object], high: [Object] }
*/

const Youtube = (props) => {

    function onRendered(){
        if(props.content.length > 0){
            var swiper = new Swiper('#youtube_swiper', {
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 0,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    // when window width is <= 320px
                    310: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                    },
                    // when window width is <= 480px
                    560: {
                        slidesPerView: 2,
                        slidesPerGroup: 2
                    },
                    // when window width is <= 640px
                    720: {
                        slidesPerView: 3,
                        slidesPerGroup: 3
                    }
                }
            });
            //console.log('swiper', swiper);
            $('#youtube_swiper .swiper-wrapper').css('transform', 'translate3d(0px, 0px, 0px)');
        }
    }

    return (
        <div className="popchapter youtube">
            <div className="chaptit">
                <p className="tittxt">
                    <span className="diseasename">{props.ingred_name}</span> 관련 YOUTUBE 동영상(<span>{props.content.length}</span>)
                </p>
            </div>
            <div className="swiper-container" id="youtube_swiper">
                <div className="swiper-wrapper">
                {
                    props.content.map((item, index) =>
                        <div className="swiper-slide"
                            key={index}
                            onClick={() => {
                                CommonFunc.pageview('처방정보', 'YOUTUBE', props.ingred_name); //Google Analytics
                                window.open(item.link, '_blank');
                            }}
                            style={{cursor: "pointer"}}
                        >
                            <div className="article">
                                <div className="photo" style={{background: `url(${item.thumbnails.high.url}) center center / cover no-repeat`}}></div>
                                <p className="tittxt" dangerouslySetInnerHTML={{__html: striptags(CommonFunc.replaceAll(item.title, '&nbsp;', ' '), [], ' ')}}></p>
                                <p className="subtit">{item.channelTitle}</p>
                                <p className="subtxt">{item.publishedAt.substring(0, 16).replace('T', ' ')}</p>
                            </div>
                        </div>
                    )
                }
                </div>
                <div class="swiper-button-next"></div>
				<div class="swiper-button-prev"></div>                
            </div>
            <div ref={onRendered}></div>
        </div>
    )
}

export default Youtube;