import React, { Fragment } from 'react';

class WikiPlaceHolder extends React.Component {
    render() {
        return (
            <Fragment>
				<div className="loading wiki">
					<div clas="wikiload">
						<div className="contlist">
							<div className="txtinfo td00">
								<div className="css-2b097c-container"></div>
							</div>
						</div>
						<ul>
							<li>
								<div className="contlist txt01">
									<div className="txtinfo td01">
										<div className="css-2b097c-container"></div>
									</div>
									<div className="txtinfo td01">
										<div className="css-2b097c-container" ></div>
									</div>
									<div className="txtinfo td01">
										<div className="css-2b097c-container"></div>
									</div>
									<div className="txtinfo td01">
										<div className="css-2b097c-container"></div>
									</div>
									<div className="txtinfo td01">
										<div className="css-2b097c-container"></div>
									</div>
								</div>
							</li>
							<li>
								<div className="contlist txt02">
									<div className="txtinfo td02">
										<div className="css-2b097c-container"></div>
									</div>
									<div className="txtinfo td02">
										<div className="css-2b097c-container" ></div>
									</div>
									<div className="txtinfo td02">
										<div className="css-2b097c-container"></div>
									</div>
									<div className="txtinfo td02">
										<div className="css-2b097c-container"></div>
									</div>
									<div className="txtinfo td02">
										<div className="css-2b097c-container"></div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
            </Fragment>
        )
    }
}

export default WikiPlaceHolder;