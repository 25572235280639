import React, {Component, Fragment} from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import $ from 'jquery';
import _ from "lodash";
import { inject, observer } from 'mobx-react';
import { withCookies } from 'react-cookie';

import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import withWidth from '@material-ui/core/withWidth';

import SSalaryRouter from './S_SalaryRouter';
import SCollapseLeft from './S_CollapseLeft';
import SMainMenu from './S_MainMenu';
import UserProfile from '../common/UserProfileLayer.js'
import CommonFunc from "../../common/CommonFunction";

import useStyles from './SalaryStyle';
import SalaryGlobalCss from "./SalaryGlobalStyle";
import { PAYINFO_TERM_PERCENTILE, SALARY_INSERT_LOG_ONE } from '../../queries/salary/salary';
import {withApollo} from "react-apollo";
import CommonFunction from "../../common/CommonFunction";
import {ORG_TYPE} from "./UserConstants";
import Footer from "../prescript/prescription/Footer";
import SServicePolicy from "../common/ServicePolicy";

@inject("salaryStore")
@observer
class S_OutsideFrame extends Component {
    state = {
        open: false,
        value: 0,
        currentMenu: 0,
        policyAgreed: 'n',
        policySessionAgreed: sessionStorage.getItem("sPolicySession"),
    }

    handleDrawerOpen = () => {
        this.setState({
            open: true
        })
    };
    handleDrawerClose = () => {
        this.setState({
            open: false
        })
    };

    changePolicyCookie = () => {
        const { salaryStore } = this.props;
        salaryStore.setSalaryServiceAgreeFlag(true)

        this.setState({policySessionAgreed:'y',});
        sessionStorage.setItem("sPolicySession", 'y');
    }

    componentWillMount = async () => {
        const salaryStore = this.props.salaryStore;
        let isLogin = false;
        if(_.isEmpty(salaryStore.userProfile)){
            const user = await CommonFunc.getRealtimeUserInfo();
            //console.log('user', user);
            if(user){
                isLogin = true;
            }
            else{
                const storageUser = await CommonFunc.getUserInfo();
                //console.log('storageUser', storageUser);
                if(storageUser && CommonFunc.getUserId() && CommonFunc.getToken()){
                    isLogin = true;
                }
            }
        }
        else{
            isLogin = true;
        }
        //console.log('getUserId', CommonFunc.getUserId());
        //console.log('getToken', CommonFunc.getToken());

        let userProfile = null;
        if(isLogin){
            salaryStore.setIsLogin(true);
            if(_.isEmpty(salaryStore.userProfile)){
                userProfile = await CommonFunc.getUserProfile(CommonFunc.getUserId(), CommonFunc.getToken());
                salaryStore.setUserProfile(userProfile);
            }
        }

        if(userProfile){
            this.props.client.query({
                query: SALARY_INSERT_LOG_ONE,
                variables: { u_id: userProfile.u_id }
            })
            .then((res) => {
                //console.log("salary_insert_log_one ", res.data)
                if (!_.isEmpty(res.data.getSalaryInsertLogByOne)) {
                    salaryStore.setMyPayInfo(res.data.getSalaryInsertLogByOne.salary_val, res.data.getSalaryInsertLogByOne.time_val);
                    salaryStore.setSalaryLogRegDate(res.data.getSalaryInsertLogByOne.reg_date);
                    if (salaryStore.myPay > 0 && salaryStore.myWorkTime > 0) {
                        let variables = {
                            spc_code: userProfile.u_spc_code,
                            hop_loc_code: userProfile.u_zip_zone,
                            org_type: CommonFunction.getCodeName(userProfile.u_hospital_group_code, ORG_TYPE),
                            u_id: userProfile.u_id,
                            period_type: this.state.currentMenu === 0 ? "payinfo" : "worktime",
                            salary: salaryStore.myPay.toString(),
                            time: salaryStore.myWorkTime.toString()
                        }

                        this.props.client.query({
                            query: PAYINFO_TERM_PERCENTILE,
                            variables: variables
                        })
                        .then((res) => {
                            salaryStore.setMySalaryResult(res.data.getPercentileData, this.state.filter_payinfo_overview_term);
                            this.setState({
                                input_edit_flag: false
                            })
                        })
                        .catch((error) => {
                            //console.log('error', error.message);
                        });
                    }
                }
            })
            .catch((error) => {
                //console.log('error', error.message);
            });
        }
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        })
    };

    componentDidMount = () => {
        const { cookies } = this.props;

        // 이중스크롤 제거
        $("legend").css("position", "unset").css("width", "auto");

        // 이용동의 쿠키체크
        // let policyCookie = CommonFunction.getCookieValue("mSpcV");
/*        let policyCookie = cookies.get("mSpcV");
        if(policyCookie === "Y"){
            this.changePolicyCookie();
        }*/

        this.setState({
            policyAgreed:localStorage.getItem('sPolicyChecked'),
        })
    }

    componentDidUpdate(prevProps) {
        // 전형적인 사용 사례 (props 비교를 잊지 마세요)
        if (this.state.currentMenu !== prevProps.currentMenu) {
            let anchor = document.querySelector("main div[class^='jss']");;

            if (anchor) {
                anchor.scrollIntoView({block: 'center'});
            }
        }
    }

    handleCurrentMenu = (menu) => {
        this.setState({
            currentMenu: menu
        })
    }

    changeLoginLayerShow = (loginLayerShow) => {
        this.props.salaryStore.setLoginLayerShow(loginLayerShow);
    }

    render() {
        const { classes, width } = this.props;
        const { salaryStore } = this.props;
        const { open, currentMenu,policyAgreed,policySessionAgreed } = this.state;
        const systemOs = window.navigator.appVersion;

        return (
            <div className={classes.root}>
                {/*<CssBaseline />*/}
                <SalaryGlobalCss />
                {
                    policySessionAgreed == 'y' || policyAgreed == 'y' ?
                        <Fragment>
                            <AppBar position="fixed" className={clsx(classes.appBar, open && classes.appBarShift)}>
                                <Toolbar className={classes.toolbar}>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={this.handleDrawerOpen}
                                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                                    >
                                        <MenuIcon />
                                    </IconButton>

                                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                                        <Link to="/salary/payinfo">
                                            연봉 인덱스
                                        </Link>
                                    </Typography>

                                    <div className={classes.hiddenMenuContainer}>
                                        <SMainMenu
                                            width={width}
                                            currentMenu={currentMenu}
                                        />
                                    </div>

                                    {/** 아바타 */}
                                    <UserProfile
                                        loginLayerShow={salaryStore.loginLayerShow} changeLoginLayerShow={this.changeLoginLayerShow}
                                    />
                                </Toolbar>
                            </AppBar>

                            <SwipeableDrawer
                                open={open}
                                onClose={this.handleDrawerClose}
                                onOpen={this.handleDrawerOpen}
                            >
                                <div className={classes.toolbarIcon}>
                                    <IconButton onClick={this.handleDrawerClose}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </div>
                                <Divider />

                                {/** Left navigation */}
                                <SCollapseLeft
                                    closeDrawer={this.handleDrawerClose}
                                />
                            </SwipeableDrawer>

                            <main
                                className={
                                    (width !== "xs" && width !== "sm" ) ?
                                        classes.content
                                        :
                                        (systemOs.indexOf('Win') !== -1) ?
                                            classes.content
                                            :
                                            classes.content_m
                                }

                            >
                                <div className={classes.appBarSpacer} />

                                <SSalaryRouter handleCurrentMenu={this.handleCurrentMenu}/>

                            </main>
                            <Footer />

                        </Fragment>
                        :
                        <SServicePolicy cookieName={"mSpcV"} handleFunction={this.changePolicyCookie} type={"s"}/>
                }
            </div>
        );
    }
}

S_OutsideFrame.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(withCookies(withStyles(useStyles)(withApollo(S_OutsideFrame))))