import React, {Component, Fragment} from 'react';
import clsx from 'clsx';
import {inject, observer} from "mobx-react";
import {Query, withApollo} from 'react-apollo';
import NumberFormat from 'react-number-format';
import $ from "jquery";
import _ from "lodash";
import PropTypes from "prop-types";
import Moment from 'react-moment';

import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import withWidth from "@material-ui/core/withWidth";
import CssBaseline from '@material-ui/core/CssBaseline';

import SNotLogin from './S_NotLogin';
import SFormSelectBox from './S_FormSelectBox';
import SPayinfoOverviewBox from './S_PayinfoOverviewBox';
import SLongBarGraph from './S_LongBarGraph';
import SLongLineGraph from "./S_LongLineGraph";
import SSnackbar from "./S_Snackbar";
import SRecjobADBox from "./S_RecjobADBox";

import CommonFunction from "../../common/CommonFunction";
import {ORG_TYPE, SPC_CODE, LOC_CODE, HTP_CODE} from "./UserConstants"
import {
    PAYINFO_OVERVIEW,
    PAYINFO_COMPARE_PAY,
    PAYINFO_NET_DISTRIBUTION,
    PAYINFO_SALARY_RESULT,
    PAYINFO_TERM_PERCENTILE,
    RECJOB_AD
} from '../../queries/salary/salary';

import useStyles from './SalaryStyle';
import { isMobileOnly } from 'react-device-detect';

function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            //prefix="$"
            suffix={props.id === "pay_net" ? " 만원" : " 시간"}
        />
    );
}

@inject("salaryStore")
@observer
class S_PayInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: false,
            filter_payinfo_overview_term: props.salaryStore.filterOverviewTerm ? props.salaryStore.filterOverviewTerm : "year",
            filter_payinfo_avg_pay: "net",
            filter_payinfo_avg_term: "year",
            filter_payinfo_trend_pay: "net",
            filter_net_pay_spc: "",
            filter_net_pay_loc: "",
            filter_net_pay_org: "",
            loading: true,
            snackbar_open: false,
            input_net_pay: 0,
            input_worktime: 0,
            input_edit_flag: false,
            snackbar_message: "",
            snackbar_type: "error"
        }
    }

    componentDidMount = () => {
        CommonFunction.pageview('연봉인덱스', '급여정보'); //Google Analytics
        // 이중스크롤 제거
        //$("html").css("overflow-y", "hidden");
        // legend reset.css와 material 충돌제거
        $("legend").css("position", "unset").css("width", "auto");

        this.props.handleCurrentMenu(0);
    }

    componentWillMount = async () => {
        const salaryStore = this.props.salaryStore;
        if(!salaryStore.isLogin){
            const user = await CommonFunction.getRealtimeUserInfo();
            if(user !== null){
                /*
                this.setState({
                    login: true
                })
                salaryStore.setUserProfile(user);
                salaryStore.setIsLogin(true);
                */
                window.location.reload();
            }
        }
        //console.log('componentWillMount end', salaryStore.isLogin);
    }

    handleChange = event => {
        this.setState({
            age: event.target.value
        })
    };

    handleChangeFilter = (event, id) => {
        const salaryStore = this.props.salaryStore;
        //console.log("event", event.target.value, id);

        if (id === "payinfo_overview_term") {
            this.setState({
                filter_payinfo_overview_term: event.target.value
            })

            // filter 조건 : 기간이  month <--> year 변경시 퍼센트, 퍼센타일 값 재조회
            if (salaryStore.myPay > 0 && salaryStore.myWorkTime > 0) {
                let variables = {
                    spc_code: salaryStore.userProfile.u_spc_code,
                    hop_loc_code: salaryStore.userProfile.u_zip_zone,
                    org_type: CommonFunction.getCodeName(salaryStore.userProfile.u_hospital_group_code, ORG_TYPE),
                    u_id: salaryStore.userProfile.u_id,
                    period_type: event.target.value,
                    salary: salaryStore.myPay.toString(),
                    time: salaryStore.myWorkTime.toString()
                }
                //console.log("variables", variables)
                this.props.client.query({
                    query: PAYINFO_TERM_PERCENTILE,
                    variables: variables
                })
                .then((res) => {
                    //console.log("result", res.data.getPercentileData)
                    // 입력값 스토어에 저장
                    salaryStore.setMySalaryResult(res.data.getPercentileData, event.target.value);
                })
                .catch((error) => {
                    //console.log('error', error.message);
                });
            }
        } else if (id === "payinfo_avg_pay") {
            this.setState({
                filter_payinfo_avg_pay: event.target.value
            })
        } else if (id === "payinfo_avg_term") {
            this.setState({
                filter_payinfo_avg_term: event.target.value
            })
        } else if (id === "payinfo_trend_pay") {
            this.setState({
                filter_payinfo_trend_pay: event.target.value
            })
        } else if (id === "payinfo_net_pay_spc") {
            this.setState({
                filter_net_pay_spc: event.target.value
            })
        } else if (id === "payinfo_net_pay_loc") {
            this.setState({
                filter_net_pay_loc: event.target.value
            })
        } else if (id === "payinfo_net_pay_org") {
            this.setState({
                filter_net_pay_org: event.target.value
            })
        }
    }

    handleChangeReInput = () => {
        this.setState({
            input_edit_flag: true
        }, () => {
            $("legend").css("position", "unset").css("width", "auto");
        });

    }

    // 스낵바 오픈
    openSnackbar = (msg) => {
        this.setState({
            snackbar_open: true,
            snackbar_message: msg
        })
    };

    // 스낵바 클로즈
    closeSnackbar = () => {
        this.setState({
            snackbar_open: false,
            snackbar_message: ""
        })
    };

    // net, 근무시간 입력처리
    validateInputValue = () => {
        const {input_net_pay, input_worktime} = this.state;
        const {width} = this.props;

        //console.log("input", this.state.input_net_pay, this.state.input_worktime)
        if (!input_net_pay) {
            let msg = "NET 월급을 입력하세요";
            this.openSnackbar(msg);
            return;
        }
        if (!input_worktime) {
            let msg = "주간 근무시간을 입력하세요";
            this.openSnackbar(msg);
            return;
        }

        if (input_net_pay < 100 || input_net_pay > 10000) {
            let msg = "NET 월급의 입력값은 100 ~ 10000 단위(만원) 입니다";
            this.openSnackbar(msg);
            return;
        }

        if (input_worktime < 20 || input_worktime > 100) {
            let msg = "주간근무시간 입력값은 20 ~ 100 단위(시간) 입니다";
            this.openSnackbar(msg);
            return;
        }

        if (width === "xs" || width === "sm") {
            document.querySelector("main").scrollTo(0, 0);
        }

        // 입력값 스토어에 저장
        const salaryStore = this.props.salaryStore;
        salaryStore.setMyPayInfo(input_net_pay, input_worktime);
        // 등록날짜 스토어에 저장
        let date = CommonFunction.getDateIntervalMonth();
        salaryStore.setSalaryLogRegDate(date);

        let variables = {
            spc_code: salaryStore.userProfile.u_spc_code,
            hop_loc_code: salaryStore.userProfile.u_zip_zone,
            org_type: CommonFunction.getCodeName(salaryStore.userProfile.u_hospital_group_code, ORG_TYPE),
            u_id: salaryStore.userProfile.u_id,
            period_type: this.state.filter_payinfo_overview_term,
            salary: input_net_pay.toString(),
            time: input_worktime.toString()
        }

        this.props.client.mutate({
            mutation: PAYINFO_SALARY_RESULT,
            variables: variables
        })
        .then((res) => {
            //console.log('PAYINFO_SALARY_RESULT result ', res.data)
            salaryStore.setMySalaryResult(res.data.createSalaryInsertLog, this.state.filter_payinfo_overview_term);
            this.setState({
                input_edit_flag: false
            })
        })
        .catch((error) => {
            //console.log('error', error.message);
        });
    }

    render() {
        const {classes} = this.props;
        const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
        const fixedMediumHeightPaper = clsx(classes.paperMedium, classes.fixedMediumHeight);

        const {salaryStore} = this.props;
        const {userProfile} = this.props.salaryStore;

        const {
            filter_payinfo_overview_term,
            filter_payinfo_avg_pay,
            filter_payinfo_avg_term,
            filter_payinfo_trend_pay,
            filter_net_pay_spc,
            filter_net_pay_loc,
            filter_net_pay_org,
            input_edit_flag
        } = this.state;

        const org_type = CommonFunction.getCodeName(userProfile.u_hospital_group_code, ORG_TYPE);
        let salary_mod_flag = 'Y';
        //console.log(salaryStore.salaryLogRegDate);
        if(!_.isEmpty(salaryStore.salaryLogRegDate)) {
            //console.log("salaryStore ", salaryStore.salaryLogRegDate);
            let salary_log_reg_date = salaryStore.salaryLogRegDate;
            let year = salary_log_reg_date.substring(0, 4);
            let month = (salary_log_reg_date.substring(5, 7) - 1);
            let day = salary_log_reg_date.substring(8, 10);

            let nowDate = new Date();
            let regDate = new Date();
            regDate.setFullYear(year, month, day);
            regDate.setDate(regDate.getDate() + 90);
            if(regDate >= nowDate){
                salary_mod_flag = 'N';
            }
            if(salaryStore.myPay > 0 && this.state.input_net_pay === 0)
            {
                this.setState({
                    input_net_pay: salaryStore.myPay,
                    input_worktime: salaryStore.myWorkTime
                }); // 로그 정보 존재시 기존 pay, worktime set state
            }
        }

        return (
            <Fragment>
                <CssBaseline/>
                <SSnackbar
                    snackbar_open={this.state.snackbar_open}
                    closeSnackbar={this.closeSnackbar}
                    snackbar_message={this.state.snackbar_message}
                    snackbar_type={this.state.snackbar_type}
                />
                {
                    !salaryStore.isLogin ?
                    <SNotLogin/>
                    :
                    <Container maxWidth="lg" className={classes.container}>
                        <div className="payinfo_wrap">
                            <Typography component="div" style={{float: "left"}} gutterBottom>
                                <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                    급여 개요
                                </Typography>
                                <Typography component="span" variant="caption">
                                    전체 우측 3가지 조건은 선생님 회원정보를 기반으로 호출합니다
                                </Typography>
                            </Typography>
                            <Typography component="div" className="combo_1" gutterBottom>
                                <SFormSelectBox
                                    id="payinfo_overview_term"
                                    labelId="payinfo_overview_term_label"
                                    labelText="기간"
                                    selectedValue={filter_payinfo_overview_term}
                                    type="term"
                                    change={this.handleChangeFilter}
                                />
                            </Typography>
                            <Paper>
                                <div className="Overview">
                                    <Grid container spacing={3}>
                                        <Query
                                            query={PAYINFO_OVERVIEW}
                                            variables={{
                                                spc_code: userProfile.u_spc_code,
                                                hop_loc_code: userProfile.u_zip_zone,
                                                org_type: org_type,
                                                period_type: filter_payinfo_overview_term
                                            }}
                                            fetchPolicy="network-only"
                                        >
                                            {({data, loading, error}) => {
                                                if (error) {
                                                    return <p>{error.message}</p>;
                                                }

                                                return (
                                                    <SPayinfoOverviewBox
                                                        data={data ? data.getAvgPay : []}
                                                        fixedHeightPaper={fixedHeightPaper}
                                                        fixedMediumHeightPaper={fixedMediumHeightPaper}
                                                        spc_code={userProfile.u_spc_code}
                                                        hop_loc_code={userProfile.u_zip_zone}
                                                        org_type={org_type}
                                                        state_period_type={filter_payinfo_overview_term}
                                                        loading={loading}
                                                    />
                                                )
                                            }}
                                        </Query>
                                        <div className="input_pay">
                                            {/* input pay 연봉 인덱스 입력전 출력 */}
                                            {
                                                salaryStore.isLogin &&
                                                (!salaryStore.myPay > 0 && !salaryStore.myWorkTime > 0) ?
                                                    <Grid item xs={12}>
                                                        <Paper className={fixedHeightPaper}>
                                                            <div className={classes.payTextCenter}>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color="primary"
                                                                    gutterBottom
                                                                >
                                                                    나의 '세후 월급여'와 '주간 총 근무 시간'을 입력하면 각 조건별 상대적 크기나, 순위 퍼센타일을 쉽게 확인하실 수 있습니다.
                                                                </Typography>
                                                                <div className="inputwrap">
                                                                    <div className="inputarea">
                                                                        <div>
                                                                            <TextField
                                                                                id="pay_net"
                                                                                name="input_net_pay"
                                                                                label="NET 월급"
                                                                                style={{
                                                                                    margin: '8px 0',
                                                                                    width: '100%',
                                                                                    minWidth: 240
                                                                                }}
                                                                                placeholder="반드시 세후 월급여로 입력해주세요"
                                                                                margin="normal"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                InputProps={{
                                                                                    inputComponent: NumberFormatCustom,
                                                                                    inputProps:{
                                                                                        maxLength: 9
                                                                                    }
                                                                                }}
                                                                                variant="outlined"
                                                                                size="small"
                                                                                onChange={e => this.setState({input_net_pay: e.target.value})}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <TextField
                                                                                id="worktime_week"
                                                                                name="input_worktime"
                                                                                label="주간 근무 시간"
                                                                                style={{
                                                                                    margin: '8px 0',
                                                                                    width: '100%',
                                                                                    minWidth: 240
                                                                                }}
                                                                                placeholder="일주일 동안의 평일근무 + 주말근무 + 당직근무의 총 합한 시간"
                                                                                margin="normal"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                InputProps={{
                                                                                    inputComponent: NumberFormatCustom,
                                                                                    inputProps:{
                                                                                        maxLength: 6
                                                                                    }
                                                                                }}
                                                                                variant="outlined"
                                                                                size="small"
                                                                                onChange={e => this.setState({input_worktime: e.target.value})}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="inputarea button">
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            size="large"
                                                                            onClick={this.validateInputValue}
                                                                        >
                                                                            확인
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                <Typography className="txtnotice">* 입력한 정보는 90일이후 수정가능합니다.</Typography>
                                                            </div>
                                                        </Paper>
                                                    </Grid>
                                                    :

                                                    (!input_edit_flag && salary_mod_flag === 'Y') ?
                                                    <Grid item xs={12}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            fullWidth={true}
                                                            size="large"
                                                            onClick={this.handleChangeReInput}
                                                        >
                                                            급여 & 근무시간 수정
                                                        </Button>
                                                    </Grid>
                                                    : (!input_edit_flag && salary_mod_flag === 'N') &&
                                                    <Grid item xs={12}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            fullWidth={true}
                                                            size="large"
                                                            style={{boxShadow:"none"}}
                                                        >
                                                            등록하신 급여/근무시간 정보는&ensp;<Moment add={{ day: 90}} format="YYYY.MM.DD">{salaryStore.salaryLogRegDate}</Moment>&ensp;이후 수정하실 수 있습니다.
                                                        </Button>
                                                    </Grid>
                                            }

                                            {
                                                input_edit_flag &&
                                                <Grid item xs={12}>
                                                    <Paper className={fixedHeightPaper}>
                                                        <div className={classes.payTextCenter}>
                                                            <Typography
                                                                variant="subtitle1"
                                                                color="primary"
                                                                gutterBottom
                                                            >
                                                                나의 '세후 월급여'와 '주간 총 근무 시간'을 입력하면 각 조건별 상대적 크기나, 순위 퍼센타일을 쉽게 확인하실 수 있습니다.
                                                            </Typography>
                                                            <div>
                                                                <TextField
                                                                    id="pay_net"
                                                                    name="input_net_pay"
                                                                    label="NET 월급"
                                                                    style={{
                                                                        margin: 8,
                                                                        width: '40%',
                                                                        minWidth: 240
                                                                    }}
                                                                    placeholder="반드시 세후 월급여로 입력해주세요"
                                                                    margin="normal"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom,
                                                                        inputProps:{
                                                                            maxLength: 9
                                                                        }
                                                                    }}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    defaultValue={salaryStore.myPay}
                                                                    onChange={e => this.setState({input_net_pay: e.target.value})}
                                                                />
                                                            </div>
                                                            <div>
                                                                <TextField
                                                                    id="worktime_week"
                                                                    name="input_worktime"
                                                                    label="주간 근무 시간"
                                                                    style={{
                                                                        margin: 8,
                                                                        width: '40%',
                                                                        minWidth: 240
                                                                    }}
                                                                    placeholder="일주일 동안의 평일근무 + 주말근무 + 당직근무의 총 합한 시간"
                                                                    margin="normal"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom,
                                                                        inputProps:{
                                                                            maxLength: 6
                                                                        }
                                                                    }}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    defaultValue={salaryStore.myWorkTime}
                                                                    onChange={e => this.setState(
                                                                        {input_worktime: e.target.value})}
                                                                />
                                                            </div>
                                                            <div>
                                                                <Typography
                                                                    className="txtnotice"
                                                                    style={{
                                                                        width: '40%',
                                                                        minWidth: 240
                                                                    }}
                                                                >
                                                                    * 입력한 정보는 90일이후 수정가능합니다.
                                                                </Typography>
                                                            </div>
                                                            <div className="btnarea">
                                                                <Button
                                                                    className="btn_cancel"
                                                                    variant="contained"
                                                                    color="default"
                                                                    size="large"
                                                                    style={{
                                                                        marginTop: '1vh',
                                                                        marginBottom: '2vh',
                                                                        marginRight: '5px'
                                                                    }}
                                                                    onClick={() => this.setState({input_edit_flag: false})}
                                                                >
                                                                    취소
                                                                </Button>
                                                                <Button
                                                                    className="btn_confirm"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="large"
                                                                    style={{
                                                                        marginTop: '1vh',
                                                                        marginBottom: '2vh',
                                                                        marginLeft: '5px'
                                                                    }}
                                                                    onClick={this.validateInputValue}
                                                                >
                                                                    수정
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                            }
                                        </div>
                                        <div className="Recjob_ad">
                                            <Query
                                                query={RECJOB_AD}
                                                variables={{
                                                    spc_code: userProfile.u_spc_code,
                                                }}
                                            >
                                                {({data, loading, error}) => {
                                                    if (loading) {
                                                        return <p></p>;
                                                    }
                                                    if (error) {
                                                        return <p>{error.message}</p>;
                                                    }
                                                    return (
                                                        //(data.getRecjobAd[0].svc_cont_idx !== 0 || data.getRecjobAd[1].svc_cont_idx !== 0 || data.getRecjobAd[2].svc_cont_idx !== 0) &&
                                                        <SRecjobADBox
                                                            data={data.getRecjobAd}
                                                            fixedHeightPaper={fixedHeightPaper}
                                                        />
                                                    )
                                                }}
                                            </Query>
                                        </div>
                                        <p style={{display:"block",width:"100%",height:"48px",margin:"10px 0 60px"}}>
                                            <a className="btn_more" href={isMobileOnly ? `https://m.medigate.net/home/recruit` : `https://www.medigate.net/recruit`} rel="noopener noreferrer" target="_blank" title="메디씨앤씨">
                                                <span>메디게이트 초빙구직 바로가기 ></span>
                                            </a>
                                        </p>
                                    </Grid>
                                </div>
                            </Paper>
                        </div>

                        <div style={{
                            paddingTop: '60px',
                            marginTop: '30px',
                            borderTop: '1px solid rgba(0,0,0,0.1)'
                        }}>
                            <Typography component="div" style={{float: "left"}} gutterBottom>
                                <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                    급여 비교
                                </Typography>
                                <Typography component="span" variant="caption">
                                    전문과, 지역, 병원 타입에 따른 평균 급여 비교입니다
                                </Typography>
                            </Typography>
                            <Typography component="div" className="combo_1" gutterBottom>
                                <SFormSelectBox
                                    id="payinfo_avg_pay"
                                    labelId="payinfo_avg_pay_label"
                                    labelText="급여"
                                    selectedValue={filter_payinfo_avg_pay}
                                    type="pay_four"
                                    change={this.handleChangeFilter}
                                />
                                <SFormSelectBox
                                    id="payinfo_avg_term"
                                    labelId="payinfo_avg_term_label"
                                    labelText="기간"
                                    selectedValue={filter_payinfo_avg_term}
                                    type="term"
                                    change={this.handleChangeFilter}
                                />
                            </Typography>
                            <div className="pagegraph">
                                <Grid container spacing={3}>
                                    <Query
                                        query={PAYINFO_COMPARE_PAY}
                                        variables={{
                                            spc_code: userProfile.u_spc_code,
                                            hop_loc_code: userProfile.u_zip_zone,
                                            org_type: org_type,
                                            pay_type: filter_payinfo_avg_pay,
                                            period_type: filter_payinfo_avg_term,
                                            chart_menu: "avg_pay"
                                        }}
                                        fetchPolicy="network-only"
                                    >
                                        {({data, loading, error}) => {
                                            if (error) {
                                                return <p>{error.message}</p>;
                                            }

                                            // //console.log("data chart", data)

                                            return (
                                                <SLongBarGraph
                                                    data={data ? data.getChartData : []}
                                                    fixedHeightPaper={fixedHeightPaper}
                                                    loopCount={3}
                                                    loading={loading}
                                                    type={1}
                                                    unit="(만원)"
                                                />
                                            )
                                        }}
                                    </Query>
                                </Grid>
                            </div>
                        </div>
                        {/* 20200514 요청 삭제
                        <div className="hlink_ad" style={{paddingTop: '60px'}}>
                            <Grid container spacing={3}>
                                <Query
                                    query={HLINK_AD}
                                    variables={{
                                        spc_code: userProfile.u_spc_code,
                                        limit_cnt: 1
                                    }}
                                    fetchPolicy="network-only"
                                >
                                    {({data, loading, error}) => {
                                        if (loading) return <p></p>;
                                        if (error) {
                                            return <p>{error.message}</p>;
                                        }

                                        return (
                                            data.getHlinkAd && data.getHlinkAd.length > 0 && data.getHlinkAd[0].svc_cont_idx !== 0 &&
                                            <SHlinkADBox
                                                data={data.getHlinkAd}
                                                fixedHeightPaper={fixedHeightPaper}
                                            />
                                        )
                                    }}
                                </Query>
                            </Grid>
                        </div>
                        */}
                        <div style={{paddingTop: '60px'}}>
                            <Typography component="div" style={{float: "left"}} gutterBottom>
                                <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                    급여 추이
                                </Typography>
                                <Typography component="span" variant="caption">
                                    최근 1년간 평균 급여 추이로 전체 이외 3가지 조건은 선생님 회원정보를 기반으로 호출합니다
                                </Typography>
                            </Typography>
                            <Typography component="div" className="combo_1" gutterBottom>
                                <SFormSelectBox
                                    id="payinfo_trend_pay"
                                    labelId="payinfo_trend_pay_label"
                                    labelText="급여"
                                    selectedValue={filter_payinfo_trend_pay}
                                    type="payTrend_two"
                                    change={this.handleChangeFilter}
                                />
                            </Typography>
                            <div className="pagegraph">
                                <Grid container spacing={4}>
                                    <Query
                                        query={PAYINFO_COMPARE_PAY}
                                        variables={{
                                            spc_code: userProfile.u_spc_code,
                                            hop_loc_code: userProfile.u_zip_zone,
                                            org_type: org_type,
                                            pay_type: filter_payinfo_trend_pay,
                                            period_type: "",
                                            chart_menu: "trend_pay"
                                        }}
                                        fetchPolicy="network-only"
                                    >
                                        {({data, loading, error}) => {
                                            if (error) {
                                                return <p>{error.message}</p>;
                                            }

                                            //console.log("data trend chart", data)
                                            return (
                                                <SLongLineGraph
                                                    data={data ? data.getChartData : []}
                                                    fixedHeightPaper={fixedHeightPaper}
                                                    state_pay_type={filter_payinfo_trend_pay}
                                                    loopCount={4}
                                                    arrName={[
                                                        "전체",
                                                        CommonFunction.getCodeName(userProfile.u_spc_code, SPC_CODE),
                                                        CommonFunction.getCodeName(userProfile.u_zip_zone, LOC_CODE),
                                                        CommonFunction.getCodeName(org_type, HTP_CODE)
                                                    ]}
                                                    loading={loading}
                                                    unit="(만원)"
                                                />
                                            )
                                        }}
                                    </Query>
                                </Grid>
                            </div>
                        </div>

                        <div style={{paddingTop: '60px'}}>
                            <Typography component="div" style={{float: "left"}} gutterBottom>
                                <Typography component="h2" variant="h5" color="primary" style={{marginRight: "15px"}}>
                                    급여 분포
                                </Typography>
                                <Typography component="span" variant="caption">
                                    최근 1년 평균 급여의 액수별 분포입니다. (참고: '800미만' = 700만원 이상 ~ 800만원 미만)
                                </Typography>
                            </Typography>
                            <Typography component="div" className="combo_1" gutterBottom>
                                <SFormSelectBox
                                    id="payinfo_net_pay_spc"
                                    labelId="payinfo_net_pay_spc_label"
                                    defaultValue="전문과 전체"
                                    empty="true"
                                    selectedValue={filter_net_pay_spc}
                                    type="custom"
                                    optionsData={SPC_CODE}
                                    change={this.handleChangeFilter}
                                />
                                <SFormSelectBox
                                    id="payinfo_net_pay_loc"
                                    labelId="payinfo_net_pay_loc_label"
                                    defaultValue="지역 전체"
                                    empty="true"
                                    selectedValue={filter_net_pay_loc}
                                    type="custom"
                                    optionsData={LOC_CODE}
                                    change={this.handleChangeFilter}
                                />
                                <SFormSelectBox
                                    id="payinfo_net_pay_org"
                                    labelId="payinfo_net_pay_org_label"
                                    defaultValue="병원타입 전체"
                                    empty="true"
                                    selectedValue={filter_net_pay_org}
                                    type="custom"
                                    optionsData={HTP_CODE}
                                    change={this.handleChangeFilter}
                                />
                            </Typography>
                            <div className="pagegraph">
                                <Grid container spacing={4}>
                                    <Query
                                        query={PAYINFO_NET_DISTRIBUTION}
                                        variables={{
                                            spc_code: filter_net_pay_spc,
                                            hop_loc_code: filter_net_pay_loc,
                                            org_type: filter_net_pay_org
                                        }}
                                        fetchPolicy="network-only"
                                    >
                                        {({data, loading, error}) => {
                                            if (error) {
                                                return <p>{error.message}</p>;
                                            }

                                            //console.log("data DIS multi chart", data)
                                            return (
                                                <SLongBarGraph
                                                    data={data ? data.getNetSpreadChartData : []}
                                                    fixedHeightPaper={fixedHeightPaper}
                                                    loopCount={1}
                                                    legendName={['', '액수별 분포도 (%)', { role: "annotation" }]}
                                                    loading={loading}
                                                    type={2}
                                                />
                                            )
                                        }}
                                    </Query>
                                </Grid>
                            </div>
                        </div>
                    </Container>
                }
            </Fragment>
        );
    }
}

S_PayInfo.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(withApollo(withStyles(useStyles)(S_PayInfo)));
