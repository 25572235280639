import React, {useState, useEffect, Fragment} from 'react';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import useStyles from "../style/common";
import Draggable from "react-draggable";
import DraggableCore from "react-draggable";
import InfoIcon from "@material-ui/icons/Info";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import DetailTabContents from "./DetailTabContents";
import {useStore} from "../../stores/StoreProvider";
import _ from "lodash";

function DetailContents(props) {
    const classes = useStyles();
    const store = useStore().LocationStore;

    const tabNameArr = [
      "hospitalInfo",
      "sgisInfo",
      "hiraInfo",
    ];
    const [tabName, setTabName] = React.useState(tabNameArr[0]);

    const handleTab = (tabIndex) => {
        setTabName( tabNameArr[tabIndex] );
    };

    //console.log('store.locationInfo', store.locationInfo);
    //console.log('store.figureLocationInfo', store.figureLocationInfo);
    //console.log('store.hospitalMarkerList', [...store.hospitalMarkerList]);
    //console.log('store.figureNearDongList', [...store.figureNearDongList]);
    //hospitalInfo,sgisInfo,hiraInfo
    return (
        <>
            {
                ((_.isEmpty(store.locationInfo) && _.isEmpty(store.figureLocationInfo)) || (store.nearDongList.length === 0 && store.figureNearDongList.length === 0)) ?
                    <Grid container>
                        지역, 과목을 먼저 선택해 주세요.
                    </Grid>
                    :
                    <Grid container>
                        <Grid item container direction={"row"} spacing={2} className="tabinfowrap">
                            <Grid item className="tabcont">
                                <button onClick={ ()=>{handleTab(0)} } className={tabName === 'hospitalInfo' ? 'on' : ''}>
                                    검색 지역 의료기관 정보 
                                </button>
                            </Grid>
                            <Grid item className="tabcont">
                                <button onClick={ ()=>{handleTab(1)} } className={tabName === 'sgisInfo' ? 'on' : ''}>
                                    인구/상권 정보 
                                </button>
                            </Grid>
                            <Grid item className="tabcont">
                                <button onClick={ ()=>{handleTab(2)} } className={tabName === 'hiraInfo' ? 'on' : ''}>
                                    시군구 진료 청구 통계 
                                </button>
                            </Grid>
                        </Grid>
                        <Grid item container className="datainfowrap">
                            <DetailTabContents tabName={tabName} />
                        </Grid>
                    </Grid>
            }
        </>
    );
}

export default DetailContents;
