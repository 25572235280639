import React from 'react';
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'

const BedChart = (props) => {
    const categories = _.map(props.data.bed_stat, "name");
    const chartData = _.map(props.data.bed_stat, "data").filter(zero => zero !== 0);
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    type: 'column',
                    height: 200
                },
                title: {
                    text: "병원 병상수별"
                },
                tooltip: {
                    pointFormat: '<b>{point.y:.1f}%</b>'
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    categories: categories,
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ' '
                    }
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: [{
                    showInLegend: false,
                    color: "#1E88E5",
                    data: chartData,
                    animation: {
                        duration: 0
                    }
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal'
                            },
                            yAxis: {
                                labels: {
                                    align: 'left'
                                }
                            },
                            credits: {
                                enabled: false
                            }
                        }
                    }]
                }
            }}
        />
    )
}

export default BedChart;