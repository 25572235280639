import React, {useEffect, useState, useContext} from 'react';
import {MapContext} from "./MapContainer";
import withPopper from "./withPopper";
import MuiSelect from "./MuiSelect";

import CssBaseline from "@material-ui/core/CssBaseline";

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CheckIcon from '@material-ui/icons/Check';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';


import Box from '@material-ui/core/Box';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {useStore} from "../../stores/StoreProvider";

function PopperAddress(props) {
    const { content, close, removeAddrHist } = props
    const store = useStore().LocationStore;

    const _maxCount = 50;

    const historyItems = () => {
        //console.log("popperaddress content", content);
        let firstItemEqual = (content.length == 1 && ( content[0] === store.searchAddress || (store.userInfo && content[0] === store.userInfo.addr)) ); //최초 접속시
        if(  content.length == 0 || firstItemEqual  ) {
            return <Typography paragraph>최근 검색 이력이 없습니다.</Typography>
        } else {
            return content.map((item, index) => {
                if ((item === store.searchAddress || (store.userInfo && item === store.userInfo.addr)) || index > _maxCount) { //현재 검색중인 주소 또는 default address는 제외
                    return false;
                }
                return (<Grid container item xs={12}>
                    <Grid item xs={10}>
                        <Typography display={"inline"} onClick={(e) => {
                            store.setSearchAddress(item);
                            e.target.name = 'closePopper';
                            close(e);
                        }} style={{cursor: 'pointer'}}>{item}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton aria-label="delete" size={"small"} onClick={(e) => {
                            removeAddrHist(item);
                        }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Grid>
                    <Divider/>
                </Grid>)
            })
        }
    }


    return(
        <Paper className="popperAdr" style={{width:'20vw', height:'55vh', overflowY:'scroll'}} id={"popperAddress"}>
            <Grid container spacing={4}>
                <Grid className="conttit" container item spacing={2} xs={12}>
                    <Grid item xs={10}>
                        <Typography paragraph>최근검색</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton aria-label="delete" name="closePopper" onClick={(e) => { e.target.name='closePopper'; close(e)}} size={"small"}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid className="contlst" item container spacing={2} alignItems={"baseline"} direction={"row"} xs={12}>
                {
                    historyItems()
                }
                </Grid>
                {/*
                    content.length === 0 &&
                        <Grid item xs={12}>
                            <Typography paragraph>최근 검색 이력이 없습니다.</Typography>
                        </Grid>
                */}
            </Grid>
        </Paper>
    );
}

export default PopperAddress;
// export default withPopper(PopperAddress);