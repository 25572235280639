import React, { Fragment } from 'react';
import { Query, withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import $ from "jquery";
import _ from "lodash";
import CommentAddForm from './Add';
import CommentEditForm from './Edit';
import CommentBody from './CommentBody';
import { COMMENT_LIST, COMMENT_CNT, COMMENT_REMOVE } from '../../../queries/prescript/comment';
import { confirmAlert } from 'react-confirm-alert';
import HtmlConfirm from '../../../common/HtmlConfirm';

//var page_num = 1;

class CommentFragment extends React.PureComponent {

    state = {
        comment_list: [],
        selected_comment: null,
        comment_tot_cnt: 0,
        sorter: 'DATE',
        row_count: 10,
        page_num: 1,
        ip: null,
        tranx: 0,
    }

    sortComment = (sorter) => {
        this.setState({
            page_num: 1,
            sorter: sorter,
            comment_list: [],
            tranx: this.state.tranx + 1
        }, () => {
            this.getCommentList(this.props);
        })
    }

    commentAddForm = () => {
        const user = this.props.user;
        this.setState({
            selected_comment: {
                comment_idx: 0,
                ctg_code: this.props.ctg_code,
                u_id: user.id,
                u_type: null,
                u_name: user.name,
                u_pic: user.pic,
                u_nick: user.nick,
                u_spc_name: user.spc,
                u_wtp_name: user.wtp,
                u_ip: this.state.ip,
                content: null,
                kcd_list: this.props.kcd_list,
                img_arr: []
            }
        });
    }

    onCommentAdd = (comment) => {
        //console.log('onCommentAdd', comment)
        this.updateCommentCount(1);
        this.closeInputForm();
        this.setState({
            comment_list: _.concat([comment], this.state.comment_list)
        })
    }

	commentEditForm = (comment, kcd_list, img_arr) => {
        this.setState({
            selected_comment: {
                ...comment,
                kcd_list: kcd_list,
                img_arr: img_arr
            }
		});
    }

    onCommentEdit = (comment) => {
        this.closeEditForm();
        let comment_list = []
        this.state.comment_list.map(item =>{
            if(item.comment_idx === comment.comment_idx){
                comment_list.push(comment);
            }
            else{
                comment_list.push(item);
            }
        })
        //console.log('onCommentEdit', comment_list);
        this.setState({
            comment_list: comment_list
        })
    }

    commentRemove = (comment_idx) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <HtmlConfirm
                        title={"의견 삭제"}
                        message={"해당 의견을 삭제하시겠습니까?"}
                        onClose={onClose}
                        confirmCallback={() => {
                            this.props.client.mutate({
                                mutation: COMMENT_REMOVE,
                                variables: {
                                    comment_idx
                                }
                            })
                            .then((res) => {
                                this.updateCommentCount(-1);
                                const comment_list = this.state.comment_list.filter(item => item.comment_idx !== comment_idx);
                                //console.log('comment_list', comment_list);
                                this.setState({
                                    comment_list: comment_list
                                })
                            })
                            .catch((error) => {
                                //console.log(error.message);
                            });
                        }}
                    />
                )
            }
        })
    }

    updateCommentCount = (added) => {
        this.setState({
            comment_tot_cnt: this.state.comment_tot_cnt + added
        })
    }

	closeInputForm = () => {
        this.setState({
            selected_comment: null
		});
    }

    closeEditForm = (comment_idx) => {
        this.setState({
            selected_comment: null
		});
    }

    nextComment = (next_page_num) => {
        this.setState({
            page_num: next_page_num
        }, () => {
            this.getCommentList(this.props);
        })
    }

    getTotalCommentCnt = (ctg_code, kcd_code_arr) => {
        this.props.client.query({
			query: COMMENT_CNT,
			variables: { ctg_code: ctg_code, kcd_code_arr:kcd_code_arr },
		})
		.then((res) => {
            this.setState({
                comment_tot_cnt: res.data.getCommentCnt.count
            })
		})
		.catch((error) => {
            //console.log('error', error.message);
		});
    }

    getCommentList = (props) => {
        const { page_num, row_count } = this.state;
        const { ctg_code, kcd_list, user } = props;
        const u_id = user ? user.id : null;
        const kcd_code_arr = _.map(kcd_list, 'kcd_code');
        const variables  = { ctg_code: ctg_code, kcd_code_arr: kcd_code_arr, u_id: u_id, sorter: this.state.sorter, page_num: page_num, row_count: row_count, tranx: this.state.tranx };
        
        this.props.client.query({
            query: COMMENT_LIST,
            variables: variables
        })
        .then((res) => {
            //console.log('getCommentList result ', res.data.getCommentList)
            this.setState({
                comment_list: _.concat(this.state.comment_list, res.data.getCommentList)
            })       
        })
        .catch((error) => {
            //console.log('error', error.message);
        });	
    }

    componentDidMount = () => {
        this.getTotalCommentCnt(this.props.ctg_code,  _.map(this.props.kcd_list, 'kcd_code')); //댓글 개수 조회
        if(this.props.user){
            this.setState({
                comment_list: [],
                selected_comment: null,
                comment_tot_cnt: 0,
                sorter: 'DATE',
                page_num: 1,
                tranx: this.state.tranx + 1,
            }, () => {
                this.getCommentList(this.props);
            })
        }
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        const propsChanged = (oldProps.ctg_code !== newProps.ctg_code || oldProps.kcd_list !== newProps.kcd_list || oldProps.user !== newProps.user);
        if(propsChanged) {
            this.getTotalCommentCnt(newProps.ctg_code,  _.map(newProps.kcd_list, 'kcd_code')); //댓글 개수 조회
            if(newProps.user){
                this.setState({
                    comment_list: [],
                    selected_comment: null,
                    comment_tot_cnt: 0,
                    sorter: 'DATE',
                    page_num: 1,
                    tranx: this.state.tranx + 1,
                }, () => {
                    this.getCommentList(newProps);
                })
            }
        }
    }

    callbackIPAddr = (ip) => {
        this.setState({
            ip: ip
        });
    }

    render() {
        const { ctg_name, ctg_name2, ctg_code, kcd_list, user } = this.props;
        //const u_id = user ? user.id : null;
        const { selected_comment, sorter } = this.state;
        const kcd_code_arr = _.map(kcd_list, 'kcd_code');
        //console.log('comment_list', this.props.ctg_code, user);
        
        return (
            <div id="review">
                <div className="heading">
                    <div className="title">
                        <p className="title_txt"><span>{ctg_name2}</span>에 관한 의사 의견(<span>{this.state.comment_tot_cnt})</span></p>
                    </div>
                    {
                    _.isEmpty(this.props.user) &&
                        <div className="force_login" data-toggle="modal" data-target="#popup_login">의사 회원 로그인이 필요합니다.</div>
                    }
                </div>
                {
                    !_.isEmpty(this.props.user) && !_.isEmpty(ctg_code) && !_.isEmpty(kcd_code_arr) &&
                    <Fragment>
                        <div className="inputarea_wrap">
                            <button
                                type="button"
                                className="inputbtn"
                                onClick={()=>this.commentAddForm()}>의견 작성
                            </button>

                            <div className="input_box">
                                <div className="input_box_wrap">
                                    {
                                        selected_comment && selected_comment.comment_idx === 0 &&
                                        <CommentAddForm
                                            ctg_code={ctg_code}
                                            kcd_code_arr={kcd_code_arr}
                                            comment={selected_comment}
                                            closeInputForm={this.closeInputForm}
                                            onCommentAdd={this.onCommentAdd}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="review_cont">
                            {
                                this.state.comment_tot_cnt > 0 &&
                                <div className="sort_option">
                                    <ul className="option_list">
                                        <li className={sorter === 'DATE' ? 'type_item on' : 'type_item'} onClick={(e)=>{e.preventDefault(); this.sortComment('DATE'); }}><span className="sort_label">최신순</span></li>
                                        <li className={sorter === 'LIKE' ? 'type_item on' : 'type_item'} onClick={(e)=>{e.preventDefault(); this.sortComment('LIKE'); }}><span className="sort_label">추천순</span></li>
                                    </ul>
                                </div>
                            }
                            {
                                this.state.comment_list.map((item, index) => {
                                    return (
                                        <Fragment key={index}>
                                        {
                                            //editable comment form
                                            selected_comment && selected_comment.comment_idx === item.comment_idx ?
                                                <div className="inputarea_wrap">
                                                    <div className="input_box">
                                                        <div className="input_box_wrap">
                                                            <CommentEditForm
                                                                comment={selected_comment}
                                                                closeEditForm={this.closeEditForm}
                                                                onCommentEdit={this.onCommentEdit}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                            <CommentBody
                                                item={item}
                                                user={user}
                                                commentEditForm={this.commentEditForm}
                                                commentRemove={this.commentRemove}
                                                from="LIST"
                                            />
                                        }
                                        </Fragment>
                                    )
                                })
                            }
                            {
                            this.state.comment_tot_cnt > this.state.comment_list.length &&
                                <div className="more_area">
                                    <button
                                        type="button"
                                        className="btn_more"
                                        onClick={()=> this.nextComment(this.state.page_num + 1) }>
                                        의견 더보기 ({ (this.state.page_num * this.state.row_count) > this.state.comment_list.length ? this.state.comment_list.length : (this.state.page_num * this.state.row_count) }/{this.state.comment_tot_cnt})
                                    </button>
                                </div>
                            }
                        </div>
                    </Fragment>
                }
            </div>

        )
    }
}
export default withApollo(CommentFragment);