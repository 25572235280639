import React from 'react';
import {observable} from "mobx";
import CommonFunction from "../common/CommonFunction";
import SessionManager from "../common/SessionManager";

const commonUserInfo = SessionManager.getUserInfo() ? SessionManager.getUserInfo() : null;
const defaultCenterPos = [37.56660340925655, 126.97838066527392];///맵 기본 좌표 - 서울시청

export default observable (
    {
        userInfo: null,
        setUserInfo(user){
            this.userInfo = user.getUserInfo;
        },
        userId: commonUserInfo ? commonUserInfo.id : null,
        isLogin: (CommonFunction.getUserId() != null) ? true : false,
        // ksseo update s
        zoomLevel: 3,
        setZoomLevel(param){
            this.zoomLevel = param
        },
        loginLayerShow: false,
        setLoginLayerShow(param){
            this.loginLayerShow = !param
        },
        // ksseo update e

        kakao: null,
        setKakao(kakao){this.kakao=kakao;},
        // searchAddress: useLocalStorage('addr', ''),
        searchAddress: "", //검색된 주소 텍스트
        setSearchAddress(addr){this.searchAddress=addr;},
        searchSpcArr:[], //검색할 specialty 종류 리스트
        setSearchSpcArr(arr){this.searchSpcArr=arr;},
        addSearchSpcArr(item){this.searchSpcArr = [...this.searchSpcArr, item];},
        searchSpcType: "SUBJECT", // SUBJECT || SPECIALTY
        setSearchSpcType(str){ this.searchSpcType=str; },
        searchOrgArr:[], //검색할 병원 종류 리스트
        setSearchOrgArr(arr){this.searchOrgArr=arr;},
        addSearchOrgArr(item){this.searchOrgArr = [...this.searchOrgArr, item];},
        centerPosArr: defaultCenterPos, //맵 중앙 좌표
        //centerPosArr: [], //맵 중앙 좌표
        setCenterPosArr(centerPos) { this.centerPosArr = [...centerPos];},
        figureBoundArr: [],//도형 bound array
        setFigureBoundArr(arr) { this.figureBoundArr = [...arr];},
        figureBound: "",//도형 bound text (array to text)
        setFigureBound(str) { this.figureBound = str;},
        figureBound2: "",//도형 bound text (array to text)
        setFigureBound2(str) { this.figureBound2 = str;},
        locationInfo: {}, //맵 중앙 좌표 기반 정보
        setLocationInfo(obj) { this.locationInfo = obj; },
        figureLocationInfo: null, //도형 중앙 좌표 기반 정보
        setfigureLocationInfo(obj) { this.figureLocationInfo = obj; },
        figureLocationInfo2: null, //도형 중앙 좌표 기반 정보
        setfigureLocationInfo2(obj) { this.figureLocationInfo2 = obj; },
        nearDongList: [], //인접동리스트(맵 센터 기준)
        setNearDongList(arr) { this.nearDongList = arr; },
        figureNearDongList: [], //인접동리스트(도형)
        setFigureNearDongList(arr) { this.figureNearDongList = arr; },
        figureNearDongList2: [], //인접동리스트(도형)
        setFigureNearDongList2(arr) { this.figureNearDongList2 = arr; },
        lsAddrSetted : false, //local storage 주소 세팅여부
        setLsAddrSetted(boolean) {this.lsAddrSetted = boolean;},
        hasLeaseAddr : false, // 임대분양에서 넘어왔는지 여부
        setHasLeaseAddr(boolean) {this.hasLeaseAddr = boolean;},
        leaseAddr : "", // 임대분양에서 넘어온 주소
        setLeaseAddr(str) {this.leaseAddr = str;},

        hospitalMarkerList: [], //맵에 나타날 마커 리스트. (도형 없음, 1번째 도형) 병원
        setHospitalMarkerList(arr) { this.hospitalMarkerList = arr; },
        hospitalMarkerList2: [], //맵에 나타날 마커 리스트. (2번째 도형)병원
        setHospitalMarkerList2(arr) { this.hospitalMarkerList2 = arr; },
        drawType: null, //도형그리기 종류 circle/rectangle/polygon/clear/roadview, 기본: circle, clear시는 모든 도형 지움 210804 roadview 추가
        setDrawType(string) {this.drawType = string},
        drawFigure1: null, // 1번 도형. format: [도형obj, "도형종류", {도형중앙좌표(위경도)}]
        setDrawFigure1(objArr) {this.drawFigure1 = objArr},
        drawFigure2: null,// 2번 도형. format: [도형obj, "도형종류", {도형중앙좌표(위경도)}]
        setDrawFigure2(objArr) {this.drawFigure2 = objArr},
        isMultiMap: false,
        setIsMultiMap(boolean) {this.isMultiMap = boolean},
        figureMapContainer1: <div id="fMapContainer1" style={{width:'100%', height:'100%'}}>TEST1</div>, // 1번 도형에 대한 이미지맵 컨테이너
        setFigureMapContainer1(component) {this.figureMapContainer1 = component},
        figureMapContainer2: <div id={"fMapContainer2"} style={{width:'100%', height:'100%'}}>TEST12</div>,// 2번 도형에 대한 이미지맵 컨테이너
        setFigureMapContainer2(component) {this.figureMapContainer2 = component},
        historyLoading: false,
        setHistoryLoading(boolean) { this.historyLoading = boolean },
        drawHistoryFigure1: null, // 검색내역에서 불러온 1번 도형. format: [도형 data, "도형종류"]
        setDrawHistoryFigure1(objArr) {this.drawHistoryFigure1 = objArr},
        drawHistoryFigure2: null,// 검색내역에서 불러온 2번 도형. format: [도형 data, "도형종류"]
        setDrawHistoryFigure2(objArr) {this.drawHistoryFigure2 = objArr},

        //임대몰 매물 목록
        leaseMarkerList: [], //맵에 나타날 마커 리스트. 병원
        setLeaseMarkerList(arr) { this.leaseMarkerList = arr; },

        //인기지역 조회
        popularSidoCode: null,
        setPopularSidoCode(sidoCode){ this.popularSidoCode = sidoCode; },
        popularSpcCodeArr: [],
        setPopularSpcCodeArr(spcCodeArr){ this.popularSpcCodeArr = spcCodeArr; },
        popularSpcLabel: '',
        setPopularSpcLabel(label){ this.popularSpcLabel = label; },
        //인기지역 선택시 나타날 중앙 마커 정보
        centerMarkerInfo: null,
        setCenterMarker(obj){ this.centerMarkerInfo = obj },



        //이용약관동의
        locationServiceAgreeFlag: false,
        setLocationServiceAgreeFlag(boolean) { this.locationServiceAgreeFlag = boolean; },

        // 사용자 액션 상태
        actionStatus: 0, //0: 기본, 1: 도형 한개 그렸을때, 2: 도형 두개 그릴 수 있을때(도형 한개 있고 다중 체크 된 상태) , 3: 도형 두개 그렸을때, 4: (도형x)클러스터 상태일때
        setActionStatus(int) { this.actionStatus = int; },
        /*
         0: 기본
         1: 도형 한개 그렸을때
         2: 도형 두개 그릴 수 있을때(도형 한개 있고 다중 체크 된 상태)
         3: 도형 두개 그렸을때
         4: (도형x)클러스터 상태일때
         */

    }
)
