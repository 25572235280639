import React, { Component } from 'react';
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";

const withTab = (WrappedComponent) => {
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                {value === index && <Box p={3}>{children}</Box>}
            </Typography>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `nav-tab-${index}`,
            'aria-controls': `nav-tabpanel-${index}`,
        };
    }

    function LinkTab(props) {
        return (
            <Tab
                component="a"
                onClick={event => {
                    event.preventDefault();
                }}
                {...props}
            />
        );
    }
    return class extends Component {
        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    LinkTab={LinkTab}
                    a11yProps={a11yProps}
                    TabPanel={TabPanel}
                />
            )
        }
    }
}

export default withTab;
