import React, {useEffect, useState, Fragment} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import {
    REGION_LIST,
    SEARCH_REGION,
    EDIT_SEARCH_REGION,
    REMOVE_SEARCH_REGION,
    USER_INFO
} from '../../queries/location';
import HtmlConfirm from "../../common/HtmlConfirm";
import {useStore} from "../../stores/StoreProvider";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    detailLayout:{
        overflowX:'hidden',
        width: 'auto',
        height: '86vh',
    },
}));

function PopperSettings(props) {
    const classes = useStyles();
    const [value, setValue] = useState("myOffice");     // radio button
    const [open, setOpen] = useState(false);            // 조회이력보기 dialog state
    const [type, setType] = useState("read");           // 지역설정 읽기모드 && 수정모드 state
    const [depth1, setDepth1] = useState("");           // 지역설정 시/도 state
    const [depth1Text, setDepth1Text] = useState("");   // 지역설정 시/도 text state
    const [depth2, setDepth2] = useState("");           // 지역설정 시/군/구 state
    const [depth2Text, setDepth2Text] = useState("");   // 지역설정 시/군/구 text state
    const [depth3, setDepth3] = useState("");           // 지역설정 읍/면/동 state
    const [depth3Text, setDepth3Text] = useState("");   // 지역설정 읍/면/동 text state

    const [oldSidoCode, setOldSidoCode] = useState("");
    const [oldSggCode, setOldSggCode] = useState("");
    const [oldEmdCode, setOldEmdCode] = useState("");


    const store = useStore().LocationStore;
    const SIDO_LIST = props.sidoCode;
    const {handleMySettingClose} = props;

    let isClinic = true;
    if(store.userInfo.u_wtp_code === "WTP001" || store.userInfo.u_wtp_code === "WTP018" || store.userInfo.u_wtp_code === "WTP019" || store.userInfo.u_wtp_code === "WTP023"){
        isClinic = false;
    }

    const handleChange = (event) => {

        // console.log("kkk handleChange", event.target.value)

        setValue(event.target.value);

        if(event.target.value === "myOffice"){
            if(isClinic){
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <HtmlConfirm
                                title={"검색지역 설정"}
                                message={`근무처 주소지로 변경시 설정하신 지역설정 정보가 초기화 됩니다`}
                                onClose={onClose}
                                useCancel={false}
                                confirmCallback={() => {
                                    removeMyRegion();
                                    store.setDrawType("clear");

                                    // 설정레이어 닫기
                                    props.handleMySettingClose();
                                }}
                            />
                        )
                    }
                })
            }else{
                // 휴직, 은퇴, 해외 일때
                if(store.userInfo.u_wtp_code === "WTP018" || store.userInfo.u_wtp_code === "WTP019" || store.userInfo.u_wtp_code === "WTP023"){
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <HtmlConfirm
                                    title={"검색지역 설정"}
                                    message={`근무처 주소지 정보가 없습니다. 검색지역을 설정해 주세요`}
                                    onClose={onClose}
                                    useCancel={false}
                                    confirmCallback={() => {
                                        setValue("setArea");
                                    }}
                                />
                            )
                        }
                    })
                }
                else if(store.userInfo.u_wtp_code === "WTP001"){    // 기타일때
                    if(store.userInfo.addr){    // 주소가 있을때
                        confirmAlert({
                            customUI: ({ onClose }) => {
                                return (
                                    <HtmlConfirm
                                        title={"검색지역 설정"}
                                        message={`근무처 주소지로 변경시 설정하신 지역설정 정보가 초기화 됩니다`}
                                        onClose={onClose}
                                        useCancel={false}
                                        confirmCallback={() => {
                                            removeMyRegion();
                                            store.setDrawType("clear");

                                            // 설정레이어 닫기
                                            props.handleMySettingClose();
                                        }}
                                    />
                                )
                            }
                        })
                    }else{      // 주소가 없을때
                        confirmAlert({
                            customUI: ({ onClose }) => {
                                return (
                                    <HtmlConfirm
                                        title={"검색지역 설정"}
                                        message={`근무처 주소지 정보가 없습니다. 검색지역을 설정해 주세요`}
                                        onClose={onClose}
                                        useCancel={false}
                                        confirmCallback={() => {
                                            setValue("setArea");
                                        }}
                                    />
                                )
                            }
                        })
                    }
                }
            }
        }
    };

    const handleTypeChange = (type) => {
        setType(type);
        regionList();
        regionEndList();
    }

    const handleChangeDepth1 = (e) => {
        // //console.log("handleChangeDepth1", e.target.value, e.target.options[e.target.selectedIndex].innerHTML);
        setDepth1(e.target.value);
        setDepth2("");
        setDepth3("");
        setDepth1Text(e.target.value === "" ? "" : e.target.options[e.target.selectedIndex].innerHTML)
    }
    const handleChangeDepth2 = (e) => {
        // console.log("handleChangeDepth2", e.target.value);
        setDepth2(e.target.value);
        setDepth3("");
        setDepth2Text(e.target.value === "" ? "" : e.target.options[e.target.selectedIndex].innerHTML)
    }
    const handleChangeDepth3 = (e) => {
        // //console.log("handleChangeDepth3", e.target.value);
        setDepth3(e.target.value);
        setDepth3Text(e.target.value === "" ? "" : e.target.options[e.target.selectedIndex].innerHTML)
    }

    // 저장버튼 클릭시 얼럿
    const handleSaveSettings = (type) => {
        if(
            depth1 === oldSidoCode &&
            depth2 === oldSggCode &&
            depth3 === oldEmdCode
        ){
            confirmAlert({ customUI: ({ onClose }) => <HtmlConfirm title={"검색지역 설정"} message={"현재 적용된 지역과 같습니다."} onClose={onClose} />});
            return;
        }else{
            let selectedText1 = document.querySelector("#loc1").selectedOptions[0].text === "시도" ? "" : document.querySelector("#loc1").selectedOptions[0].text;
            let selectedText2 = document.querySelector("#loc2").selectedOptions[0].text === "시군구" ? "" : document.querySelector("#loc2").selectedOptions[0].text;
            let selectedText3 = document.querySelector("#loc3").selectedOptions[0].text === "동면읍" ? "" : document.querySelector("#loc3").selectedOptions[0].text;

            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <HtmlConfirm
                            title={"검색지역 설정"}
                            message={`선택하신 “${selectedText1} ${selectedText2} ${selectedText3}”으로 지도 중심이 이동됩니다.`}
                            onClose={onClose}
                            useCancel={false}
                            confirmCallback={() => {
                                editMyRegion();
                                setDepth1Text(selectedText1);
                                setDepth2Text(selectedText2);
                                setDepth3Text(selectedText3);

                                // 설정레이어 닫기
                                props.handleMySettingClose();
                            }}
                        />
                    )
                }
            })
        }
    }

    const [editMyRegion, { loading, error }] = useMutation(
        EDIT_SEARCH_REGION,
        {
            variables: {
                u_id: store.userInfo.u_id,
                sido_code: depth1,
                sgg_code: depth2,
                emd_code: depth3
            },
            onCompleted(){
                setType("read");
                setOldSidoCode(depth1);
                setOldSggCode(depth2);
                setOldEmdCode(depth3);

                let address = depth1Text + " " + depth2Text + " " + depth3Text;
                store.setSearchAddress(address)
            }
        }
    );

    const [removeMyRegion, { loadingRemove, errorRemove }] = useMutation(
        REMOVE_SEARCH_REGION,
        {
            variables: {
                u_id: store.userInfo.u_id,
            },
            onCompleted(){
                setType("read");

                // 설정한 주소값 초기화
                setDepth1("");
                setDepth2("");
                setDepth3("");
                setDepth1Text("");
                setDepth2Text("");
                setDepth3Text("");
                setOldSidoCode("");
                setOldSggCode("");
                setOldEmdCode("");

                const addr = store.userInfo ? store.userInfo.addr: null;
                store.setSearchAddress(addr);
            }
        }
    );

    const [searchRegion, {
        loading: loadingSearchRegion, error: errorSearchRegion, data: dataSearchRegion
    }] = useLazyQuery(SEARCH_REGION, {
        variables: {
            u_id: store.userInfo.u_id
        },fetchPolicy: "network-only"
    });

    const [regionList, {
        loading: loadingRegionList, error: errorRegionList, data: dataRegionList
    }] = useLazyQuery(REGION_LIST, {
        variables: {
            region_code: depth1
        }
    });

    const [regionEndList, {
        loading: loadingRegionEndList, error: errorRegionEndList, data: dataRegionEndList
    }] = useLazyQuery(REGION_LIST, {
        variables: {
            region_code: depth2
        }
    });

    useEffect(()=>{
        // console.log("kkk value", value, type, isClinic, store.userInfo.addr)
        // console.log("ksseo init state", depth1, depth2, depth3, oldSidoCode, oldSggCode, oldEmdCode);
    });

    useEffect(()=>{
        // console.log("kkk dataSearchRegion 1", dataSearchRegion, loadingSearchRegion);

        if(!loadingSearchRegion && dataSearchRegion){
            if(dataSearchRegion.getSearchRegion){ //이미 지역 설정
                setDepth1(dataSearchRegion.getSearchRegion && dataSearchRegion.getSearchRegion.sido_code);
                setOldSidoCode(dataSearchRegion.getSearchRegion && dataSearchRegion.getSearchRegion.sido_code);
            }
        }

    }, [dataSearchRegion, loadingSearchRegion]);

    useEffect(()=>{
        // console.log("kkk zzzzzzz", depth1, oldSidoCode)
        if(depth1 && oldSidoCode){
            setValue("setArea");
        }

    }, [depth1, oldSidoCode])

    useEffect(()=>{
        if(!loadingRegionList){
            if(dataRegionList){
                setDepth2(dataSearchRegion.getSearchRegion ? dataSearchRegion.getSearchRegion.sgg_code : "");
                setOldSggCode(dataSearchRegion.getSearchRegion ? dataSearchRegion.getSearchRegion.sgg_code : "");

            }
        }
    }, [dataRegionList, loadingRegionList]);

    useEffect(()=>{
        if(!loadingRegionEndList){
            if(dataRegionEndList){
                setDepth3(dataSearchRegion.getSearchRegion ? dataSearchRegion.getSearchRegion.emd_code : "");
                setOldEmdCode(dataSearchRegion.getSearchRegion ? dataSearchRegion.getSearchRegion.emd_code : "");
            }
        }
    }, [dataRegionEndList, loadingRegionEndList]);

    useEffect(()=>{
        //console.log("PopperSettings value", value);
        searchRegion()
    }, [value]);

    useEffect(() => {
        if(!isClinic){
            setValue("setArea");
        }
    }, [isClinic])

    return(
        // <Paper style={{width:'23vw', height:'23vh', marginTop: "7px", marginRight: "5px"}} id={"popperSettings"} className="popperSettings">
        <Paper style={{width:'23vw', marginTop: "7px", marginRight: "5px"}} id={"popperSettings"} className="popperSettings">
            <Grid container spacing={2}>
                <Grid item container className="titxt">
{/*
                    <Typography paragraph>검색지역 설정</Typography>
                    <Divider />
*/}

                    <Grid item xs={10}>
                        <Typography paragraph>검색지역 설정</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton aria-label="delete" name="closePopper" onClick={(e) => { e.target.name='closePopper'; handleMySettingClose(e)}} size={"small"}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item container spacing={2} alignItems={"baseline"} xs={12}>
                    <Grid item xs={10} className="radiobtn type1 f-reset">
                        <FormControl component="fieldset">
                            <FormLabel component="legend">지역설정</FormLabel>
                            <RadioGroup aria-label="area" name="addrArea" value={value} onChange={handleChange}>
                                <FormControlLabel value="myOffice" control={<Radio />} label="근무처 주소지" />
                                <FormControlLabel value="setArea" control={<Radio />} label="지역 설정" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {
                        type === "read" &&
                        <Grid item xs={10} className="setArea f-reset">
                            {
                                value === "setArea" ?
                                <>
                                    {
                                        depth1Text ?
                                        <Typography paragraph>{`${depth1Text} ${depth2Text} ${depth3Text}`}</Typography>
                                        :
                                        <Typography paragraph>
                                            {(dataSearchRegion && dataSearchRegion.getSearchRegion) ? dataSearchRegion.getSearchRegion.addr : "설정지역이 없습니다"}
                                        </Typography>
                                    }
                                    <Button variant="outlined" className="btn-type3" onClick={() => handleTypeChange("update")}>
                                        { depth1 ? '수정하기' : '등록하기'  }
                                    </Button>
                                </>
                                :
                                <Typography paragraph>{isClinic ? store.userInfo.addr : ""}</Typography>
                            }
                        </Grid>
                    }
                    {
                        type === "update" &&
                        <Fragment>
                            <Grid item xs={10} className="codeArea">
                                <select id="loc1" onChange={handleChangeDepth1}>
                                    <option value="">시도</option>
                                    {
                                        SIDO_LIST.map((item) => {
                                            // const useRegion = dataSearchRegion.getSearchRegion;
                                            // let isSelected = useRegion.sido_code === item.code ? "seleted" : ""03.
                                            let isSelected = depth1 === item.code ? "seleted" : ""
                                            return (
                                                <option value={item.code} selected={isSelected}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <select id="loc2" onChange={handleChangeDepth2}>
                                    <option value="">시군구</option>
                                    {
                                        dataRegionList &&
                                        dataRegionList.getRegionList.map((item) => {
                                            // const useRegion = dataSearchRegion.getSearchRegion;
                                            // let isSelected = useRegion.sgg_code === item.region_code ? "seleted" : ""
                                            let isSelected = depth2 === item.region_code ? "seleted" : ""
                                            return (
                                                <option value={item.region_code} selected={isSelected}>{item.region_name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <select id="loc3" onChange={handleChangeDepth3}>
                                    <option value="">동면읍</option>
                                    {
                                        dataRegionEndList &&
                                        dataRegionEndList.getRegionList.map((item) => {
                                            // const useRegion = dataSearchRegion.getSearchRegion;
                                            // let isSelected = useRegion.emd_code === item.region_code ? "seleted" : ""
                                            let isSelected = depth3 === item.region_code ? "seleted" : ""
                                            return (
                                                <option value={item.region_code} selected={isSelected}>{item.region_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </Grid>
                            {
                                (depth1 && !(depth1 === oldSidoCode && depth2 === oldSggCode && depth3 === oldEmdCode)) &&
                                <Grid item xs={10} style={{padding:"0 20px 20px"}}>
                                    <Button variant="contained" className="btn-type3" fullWidth onClick={() => handleSaveSettings("read")}>
                                        저장
                                    </Button>
                                </Grid>
                            }
                        </Fragment>
                    }

                    <Divider />
                    <Grid item xs={8} className="setArea-btn f-reset">
                        <Button onClick={props.handleDetail}>조회이력 보기></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PopperSettings;