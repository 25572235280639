import React, { Component } from 'react';
import Chart from "react-google-charts";

class S_LineChart extends Component {
    render() {
        const { data, legendDisabled } = this.props;
        return (
            <div className="App">
                <Chart
                    chartType="LineChart"
                    width="100%"
                    height="240px"
                    data={data}
                    //options={options}
                    options={{
                        legend: { position: legendDisabled ? 'none' : 'top' },
                        annotations: {
                            textStyle: {
                                fontName:'verdana',
                                fontSize: 11,
                                bold: true,
                                color: '#000',
                                //auraColor: '#d799ae',
                                opacity: 0.8,
                                datum: { stem : {color: '#000', length: '12', style: 'point'}}
                            }
                        },
                        backgroundColor:'none',
                        fontName:'Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',letterSpacing:'-1px',fontSize:'12px',
                        hAxis: {textStyle: {fontSize: 12}},
                        vAxis: {
                            textStyle: {
                                fontName:'verdana,Arial,Noto Sans KR,Dotum',
                                fontSize: 10,color:'#999'
                                }
                            },
                        chartArea: {width:'93%', left:45},
                        colors: ['#0986ff'],
                        pointSize: 5,
                    }}
                />
            </div>
        )
    }
}

export default S_LineChart
