import React, {useEffect, useState, useContext} from 'react';
import { throttle, debounce } from 'throttle-debounce';
import {MapContext} from "./MapContainer";
import withPopper from "./withPopper";
import MuiSelect from "./MuiSelect";
import CssBaseline from "@material-ui/core/CssBaseline";

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CheckIcon from '@material-ui/icons/Check';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import { spacing } from '@material-ui/system';

import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import {useLazyQuery} from "@apollo/react-hooks";
import {POPULAR_LOCATION_LIST} from "../../queries/location";
import {observer, useObserver} from "mobx-react";
import {useStore} from "../../stores/StoreProvider";
import CommonFunction from "../../common/CommonFunction";
//import {SIDO_CODE, SBJ_CODE} from "../../common/constants";

const useStyles = makeStyles((theme) => ({
    formControl: {
        //margin: theme.spacing(1),
        minWidth: 120,
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    //disablePortal: true,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        },
    },
};

const PopperPopular = observer( (props) => {
    const classes = useStyles();
    const store = useStore().LocationStore;

    const [getPopularLocationList, {loading:loading, error:error, data:popularData}] = useLazyQuery(POPULAR_LOCATION_LIST, {onCompleted: (data)=> {  }}); //인기지역조회

    //delay function call
    const delayedPopularLocationList = debounce(0, () => {
        getPopularLocationList({variables: {sido_code: store.popularSidoCode, spc_code_arr: store.popularSpcCodeArr }});
    })

    useEffect(()=>{
        //console.log('popularSidoCode : ', store.popularSidoCode, _.isEmpty(store.popularSidoCode));
        let label = '';
        store.popularSpcCodeArr.map((code, index) => {
            if(index > 0) label += ', ';
            label += (_.filter(props.spcCode, {code: code}))[0].name;
        })
        store.setPopularSpcLabel(label);

        getPopularLocationList({variables: {sido_code: store.popularSidoCode, spc_code_arr: store.popularSpcCodeArr }});
        //delayedPopularLocationList();
    },[store.popularSidoCode, store.popularSpcCodeArr]);

    /*
    useEffect(()=>{
        if(!loading && popularData != null){
            //console.log('popularData', popularData);
        }
    },[popularData, loading]);
    */

    const handleChangeSido = (event) => {
        store.setPopularSidoCode(event.target.value);
    };

    const handleChangeSpc = (event) => {
        store.setPopularSpcCodeArr(event.target.value);
    };

    const setAddr = (e, addr) => {
        props.addr(addr);
        props.open(false);
    }

    return(
        <Paper id={"popperPopular"} className="popperPopular">
            <Grid style={{padding: '40px 0 20px'}}>
                <Grid className="sidosort">
                    <FormControl className={classes.formControl} className="combobox type2">
                        <InputLabel id="sido-select-label">시도 선택</InputLabel>
                        <Select
                            labelId="sido-select-label"
                            value={store.popularSidoCode}
                            onChange={handleChangeSido}
                            MenuProps={MenuProps}
                        >
                            {
                                !_.isEmpty(store.popularSidoCode) &&
                                <MenuItem value={''}>전체</MenuItem>
                            }
                            {
                                props.sidoCode.map((item, index)=>
                                    <MenuItem value={item.code} id={`popular_sido`}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid className="sidosort">
                    <FormControl className={classes.formControl} className="combobox type2">
                        <InputLabel id="spc-select-label">진료과목 선택</InputLabel>
                        <Select
                            multiple
                            labelId="spc-select-label"
                            value={[...store.popularSpcCodeArr]}
                            input={<Input />}
                            renderValue={(selected) => store.popularSpcLabel}
                            onChange={handleChangeSpc}
                            MenuProps={MenuProps}
                        >
                            {
                                props.spcCode.map((item, index)=>
                                    <MenuItem key={item.code} value={item.code} id={`popular_spc`}>
                                        <Checkbox checked={store.popularSpcCodeArr.join().indexOf(item.code) > -1} />
                                        <ListItemText primary={item.name} />
                                    </MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid className="sidolst">
                    {
                        popularData &&
                        popularData.getPopularLocationList.map((item, index)=>{
                            return (
                                <Grid item container spacing={2} alignItems={"baseline"} direction={"row"} xs={12} className="datalst">
                                    <Grid item xs={10}>
                                        <Typography display={"inline"} onClick={(e)=> {setAddr(e, item.addr); CommonFunction.pageview('입지분석', '인기지역 클릭', item.addr); }} name="closePopper">{index+1}. {item.addr}</Typography>
                                    </Grid>
                                    <Divider />
                                </Grid>
                            )
                        })
                    }
                    {
                        popularData && popularData.getPopularLocationList.length === 0 &&
                        <Grid item container spacing={2} alignItems={"baseline"} direction={"row"} xs={12}>
                            <Grid item xs={10}>
                                <Typography display={"inline"}>검색 결과가 없습니다.</Typography>
                            </Grid>
                            <Divider />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Paper>
    );
})

export default PopperPopular;