import React, { Fragment } from 'react';
import { withApollo } from 'react-apollo';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';
import CommonFunc from '../../../common/CommonFunction';
import { COMMENT_UPDATE } from '../../../queries/prescript/comment';
import { confirmAlert } from 'react-confirm-alert';
import HtmlConfirm from '../../../common/HtmlConfirm';
import ShowMoreText from 'react-show-more-text';

const AWS = require('aws-sdk');
const s3 = new AWS.S3(CommonFunc.awsConfig());

class Edit extends React.PureComponent {

	constructor(props){
		super(props);
		this.state = {
			content: props.comment.content,
			img_arr: props.comment.img_arr
		};
	}

	getCommentValues = () => {
		const { comment } = this.props;

		//set the edited values
		let values = {
			comment_idx: comment.comment_idx,
			ctg_code: comment.ctg_code,
			u_id: comment.u_id,
			u_type: comment.u_type,
			u_name: comment.u_name,
			u_pic: comment.u_pic,
			u_spc_name: comment.u_spc_name,
			u_wtp_name: comment.u_wtp_name,
			u_ip: comment.u_ip,
			content: this.state.content,
			kcd_code_arr: _.map(comment.kcd_list, 'kcd_code'),
			img_arr: this.state.img_arr
		}
		return values;
	}

	handleSubmit = (e) => {
		e.preventDefault();

		if(_.isEmpty(this.state.content)){
			return confirmAlert({ customUI: ({ onClose }) => <HtmlConfirm message={"의견을 입력하세요."} onClose={onClose} />});
		}

		const values = this.getCommentValues();
		//console.log('comment values', values);

		this.props.client.mutate({
			mutation: COMMENT_UPDATE,
			variables: { comment: values }
		})
		.then((res) => {
			if(this.props.page === 'MY'){
				this.props.closeEditForm();
				this.props.refetch();	 //update props & render
			}
			else{
				this.props.onCommentEdit(res.data.editComment);
			}			
		})
		.catch((error) => {
			//console.log('error', error.message);
		});
	}

	handleOnChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
		//console.log(e.target.name, e.target.value)
	}

	addImg = (img) => {
		this.setState({
			img_arr: [...this.state.img_arr, img]
		});
	}

	removeImg = (e, imgUrl) => {
		e.preventDefault();
		let filtered_img_arr = _.filter(this.state.img_arr, function(o) {
			if (o !== imgUrl) return o;
		});
		//console.log('filtered_img_arr', filtered_img_arr);
		this.setState({
			img_arr: filtered_img_arr
		});
	}

	handleFileUpload = (files) => {
		const file = files[0];
		const dirPath = moment().format('YYYY') + '/' + moment().format('MM') + '/';
		const hms = moment().format('hhmmss');
		const fileName = file.name.replace('.', '_' + hms + '.');

        var params = {
			Bucket: 'drgate',
			Key: 'image/' + dirPath + fileName,
			ACL:'public-read',
			Expires: 60,
			ContentType: file.type
		};

		const thisObj = this;
		s3.getSignedUrl('putObject', params, function(err, signedUrl) {
			if (err) {
				//console.log('error', err);
				return err;
			}
			else {
				//console.log('signedUrl', signedUrl);
				var instance = axios.create();
				instance.put(signedUrl, file, {headers: {'Content-Type': file.type}})
				.then(function (result) {
					//console.log('put result', result);
					const img = signedUrl.substring(0, signedUrl.lastIndexOf('?'));
					//console.log('image url : ', img);
					thisObj.addImg(img);
				})
				.catch(function (err) {
					//console.log(err);
				});
				//return signedUrl;
			}
		});
	}

	render() {
		const { closeEditForm, comment } = this.props;
		const { u_type, u_name, u_pic, u_spc_name, u_wtp_name, kcd_list } = comment;
		//console.log('comment_edit-props', this.props);
		//console.log('comment_edit-state', this.state);

		return (
			<div className="accordion" id="write_review">
				<form onSubmit={this.handleSubmit}>
				<div className="rbox_profile">
					{
					u_type === '1' ?
						<div className="radio-card">
							<label for="producer" id="radio-label1">
								{
									!_.isEmpty(u_pic) ?
									<div className="thumbinfo" style={{background: `url(${CommonFunc.getImageDomain()}${u_pic}) center center / cover no-repeat`}}/>
									:
									<div className="thumbinfo none"/>
								}
								<div className="txtinfo">
									<p className="name">{u_name}</p>
									<p className="specialty"><span>{u_spc_name}</span></p>
									<p className="invite"><span>{u_wtp_name}</span></p>
								</div>
							</label>
						</div>
					: u_type === '2' ?
						<div className="radio-card type2">
							<label for="consumer2" id="radio-label3">
								<div className="thumbinfo">{u_name}</div>
								<div className="txtinfo">
									<p className="name">{u_name}</p>
								</div>
							</label>
						</div>
					: u_type === '3' ?
						<div className="radio-card type1">
							<label for="consumer" id="radio-label2">
								<div className="thumbinfo"></div>
								<div className="txtinfo">
									<p className="name">익명</p>
								</div>
							</label>
						</div>
					: ''
					}
				</div>
				<div className="review_textfield">
					<p className="input_area">
						<textarea className="textEntry" name="content" placeholder="의견을 입력합니다." onChange={this.handleOnChange} value={this.state.content}></textarea>
					</p>
				</div>

				<div className="contwrap b-description_readmore js-description_readmore">
					<p className="extxt">약물의 의견이 다음 질환 검색 때 나타납니다.</p>
					<ShowMoreText
						lines={1}
						more='MORE(+)'
						less='LESS(-)'
						anchorclassName='see_more_link_inner'>
						{
							kcd_list.map((kcd, index) =>
								<span className="tag_code">{kcd.kcd_code}: {kcd.kcd_kor}</span>
							)
						}
					</ShowMoreText>
				</div>

				<div className="btn_type3">
					<Dropzone
						multiple={false}
						accept="image/jpg,image/jpeg,image/gif,image/png"
						onDrop={ (acceptedFiles) => {
							//console.log(acceptedFiles)
							if(acceptedFiles && _.size(acceptedFiles) > 0){
								let fileType = acceptedFiles[0].type;
								if(fileType.indexOf("image") === 0){
									this.handleFileUpload(acceptedFiles);
								}
							}

						}}>
						{({getRootProps, getInputProps}) => (
							<span {...getRootProps()}>
								<input {...getInputProps()} />
								<button className="mdl-button mdl-js-button mdl-button--fab">
									<i className="material-icons more_vert">add</i>
								</button>
							</span>
						)}
					</Dropzone>
					{
						this.state.img_arr.length > 0 &&
						<div className="thumb_wrap">
							<ul>
							{
								this.state.img_arr.map((imgUrl, index) =>
									<li key={index} className="rep"><div className="attach_thumb"><img src={imgUrl} alt="" /><div className="close" onClick={(e)=>this.removeImg(e, imgUrl)}><i className="material-icons md-13">close</i></div></div></li>
								)
							}
							</ul>
						</div>
					}
					<button className="mdc-button mdc-button--outlined cancel close" onClick={()=>closeEditForm()}>취소</button>
					<button className="mdc-button mdc-button--raised select" type="submit">수정</button>
				</div>
				</form>
			</div>
		);
	}
}
export default withApollo(Edit);