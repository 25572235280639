import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

class ImagePreview extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  showImage = (photoIndex) => {
    //console.log('photoIndex', photoIndex)
    this.setState({
        isOpen: true,
        photoIndex: photoIndex
    })
  }

  render() {
    const { images } = this.props;
    const { photoIndex, isOpen } = this.state;

    return (
      <div>
        <div className="thumb_post">
        {
            images.map((imgUrl, index) =>
                <div key={index} className="thumb_img" style={{cursor: 'pointer'}}><img className="thumb" src={imgUrl} width="65px" height="65px" alt="" onClick={() => this.showImage(index)}/></div>
            )
        }
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default ImagePreview;