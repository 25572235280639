import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import $ from 'jquery';
import 'bootstrap';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { PRODUCT_LIST, PRESCRIPTION_LIST, SHARE_RATE_CHART } from '../../../queries/prescript/prescription';
import FavoriteFragment from './FavoriteFragment';
import CombiIndicator from './CombiIndicator';
import Combi2Fragment from './Combi2Fragment';
import ProductList from './ProductList';
import FilterPlace from '../filter/FilterPlace';
import FilterATCCategory from '../filter/FilterATCCategory';
import FilterCombination from '../filter/FilterCombination';
import FilterMethod from '../filter/FilterMethod';
import FilterStatYear from '../filter/FilterStatYear';
import ShareRateChart from './ShareRateChart';
import ZeroResult_Sample2 from '../common/ZeroResult_Sample2';
import Combi2_PlaceHolder from '../loading/Combi2_PlaceHolder';
import BeatLoading_prescriptList from '../loading/BeatLoading_prescriptList';
import ResponsiveDialog from '../layer/ResponsiveDialog';
import MobileFilterOption from '../layer/MobileFilterOption';
import CommonFunc from '../../../common/CommonFunction';
import { isMobileOnly } from 'react-device-detect';

function selectProduct(value, state, props) {
    let url = "https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetail?itemSeq=" + value.pmt_code;
    window.open(url, '_blank');
}

class PrescriptList extends React.PureComponent {

    state = {
        item: {},
        userProfile: {},
        pdt_placeholder: "해당 제품을 확인하세요(갱신:2019.07)",
        selected_combo2_index: 0,
        ingred_code_grp_arr: [],
        liked_item: null,
        load_pagination: false
    }

    componentWillMount = async () => {
        if(!_.isEmpty(CommonFunc.getUserId())){
            const userProfile = await CommonFunc.getUserProfile(CommonFunc.getUserId(), CommonFunc.getToken());
            this.setState({
                userProfile: userProfile || {}
            })
        }
    }

    displayIngredDetails = (item) => {
        //console.log("item displayIngredDetails", item)
        this.setState({
            item: item
        }, () => {
            $('#druginfo_layer').modal('show');
        });

        CommonFunc.pageview('처방정보', '성분상세', item.ingred_eng); //Google Analytics
    }

    onMenuOpen = () => {
        this.setState({
            pdt_placeholder: "검색하려면 제품명을 입력하세요"
        })
    }

    changeCombiValue = (active_flag, index, ingred_code_grp_arr) => {
        const old_index = this.state.selected_combo2_index;
        const new_index = index;
        //console.log('changeCombiValue', active_flag, old_index, new_index);

        if(active_flag){
            this.setState({
                selected_combo2_index: index,
                ingred_code_grp_arr: ingred_code_grp_arr
            }, () => {
                $(".contlist button.c2btn" + old_index).removeClass("active");
                $(".contlist button.c2btn" + new_index).addClass("active");

                //focus to item
                $('.section_wrap').scrollTop(0);
                const offset = $("#item_" + new_index).position().top;
                $('.section_wrap').scrollTop(offset);
            })
        }
        else{
            this.setState({
                selected_combo2_index: 0,
                ingred_code_grp_arr: []
            }, () => {
                $(".contlist button.c2btn"+new_index).removeClass("active");
            })
        }
    }

    updateLike2nd = (liked_item, fetchMore) => {
        // //console.log('liked_item_result', liked_item);
        fetchMore({
            updateQuery: (prev, { fetchMoreResult }) => {
                let list = [];
                prev.getPrescriptionList.map(item => {
                    if(liked_item.ingred_code_grp === item.ingred_code_grp && liked_item.combi_flag === item.combi_flag && liked_item.method === item.method){
                        //console.log('liked_item_update - 2nd', liked_item, item);
                        list.push({...item, like_flag: liked_item.like_flag, total_like_cnt: liked_item.total_like_cnt});
                    }
                    else{
                        list.push(item);
                    }
                })
                return Object.assign({}, prev, {
                    getPrescriptionList: list
                });
            }
        })
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        const propsChanged = (oldProps.page_num !== newProps.page_num);
        if(propsChanged) {
            this.setState({
                load_pagination: false
            })
        }
    }

    render() {
        //console.log("state", this.state)
        //console.log("prescriptionList.length", prescriptionList.length, this.state.liked_item);
        //console.log('item-------------------------', prescriptionList);

        const {
            data,
            user, u_id,
            kcd_list, kcd_code_arr,
            ctg_code_grp, ctg_code3, ctg_name,
            filter_place, filter_place_change, filter_category, filter_combination, filter_method, filter_category_selectedOption, filter_combination_selectedOption, filter_method_selectedOption,
            filter_stat_year, filter_stat_year_selectedOption,
            order, dgsbjt_cd_arr, dgsbjt_dtl_cd_arr, agg_arr, sex_tp_cd_arr, cl_cd_arr, bed_arr, sido_cd_arr ,
        } = this.props;

        const {
            item,
            userProfile = {},
            selected_combo2_index,
            ingred_code_grp_arr,
            liked_item
        } = this.state;

        const { getPrescriptionList = [] } = data;
        const prescriptionList = _.take(getPrescriptionList, this.props.page_num * this.props.row_count);
        const prescriptionCount = prescriptionList ? prescriptionList.length : 0;
        const page_num = Math.ceil(prescriptionCount / this.props.row_count);

        return (
            <div className="wrap_druglist">
                <div id="three_line_chart">
                    <Query query={SHARE_RATE_CHART} variables={{
                        kcd_code_arr: kcd_code_arr,
                        ctg_code3: ctg_code3,
                        place: filter_place
                    }}>
                        {({ data, loading, error }) => {
                            if (loading) return <p></p>
                            if (error) return <p>{error.message}</p>;

                            // //console.log('SHARE_RATE_CHART', data.getShareRateOfChange);

                            return (
                                (data.getShareRateOfChange.chart_data !== null && data.getShareRateOfChange.chart_data.length > 0) &&
                                <ShareRateChart
                                    data={data.getShareRateOfChange}
                                />
                            )
                        }}
                    </Query>
                </div>
                <div className="schoption_wrap">
                    {
                        !isMobileOnly ?
                        <Fragment>
                            <div class="schstatus_wrap">
                                {
                                    prescriptionList[0] && prescriptionList[0].f_tot_prescript >= 500 &&
                                    /*<Tooltip title={`처방전 샘플수가 충분합니다 (${ filter_stat_year }년 표본데이터)`} placement="top">*/
                                        <p className="schstatus type3">샘플수 양호({
                                            <FilterStatYear
                                                filter_stat_year={filter_stat_year}
                                                filter_stat_year_selectedOption={filter_stat_year_selectedOption}
                                                onChangeFilterStatYear={this.props.onChangeFilterStatYear}
                                            />
                                            }년)
                                        </p>
                                    /*</Tooltip>*/
                                }
                                {
                                    (prescriptionList[0] &&
                                    prescriptionList[0].f_tot_prescript >= 100 &&
                                    prescriptionList[0].f_tot_prescript < 500) &&
                                    /*<Tooltip title="처방전 샘플수가 다소 부족해, 추가 상세검색을 추천하지 않습니다" placement="top">*/
                                        <p className="schstatus type2">샘플수 주의({
                                            <FilterStatYear
                                                filter_stat_year={filter_stat_year}
                                                filter_stat_year_selectedOption={filter_stat_year_selectedOption}
                                                onChangeFilterStatYear={this.props.onChangeFilterStatYear}
                                            />
                                            }년)
                                        </p>
                                    /*</Tooltip>*/
                                }
                                {
                                    prescriptionList[0] && prescriptionList[0].f_tot_prescript < 100 &&
                                    /*<Tooltip title="처방전 샘플수가 부족해, 결과 해석에 주의가 필요합니다" placement="top">*/
                                        <p className="schstatus type1">샘플수 부족({
                                            <FilterStatYear
                                                filter_stat_year={filter_stat_year}
                                                filter_stat_year_selectedOption={filter_stat_year_selectedOption}
                                                onChangeFilterStatYear={this.props.onChangeFilterStatYear}
                                            />
                                            }년)
                                        </p>
                                    /*</Tooltip>*/
                                }
                            </div>
                            <div className="schoption type1">
                                <FilterPlace
                                    filter_combination={filter_combination}
                                    filter_place={filter_place}
                                    onChangeFilterPlace={this.props.onChangeFilterPlace}
                                />
                            </div>
                            <div className="schoption type2">
                                <FilterATCCategory
                                    kcd_code_arr={kcd_code_arr}
                                    ctg_code3={ctg_code3}
                                    dgsbjt_cd_arr={dgsbjt_cd_arr}
                                    dgsbjt_dtl_cd_arr={dgsbjt_dtl_cd_arr}
                                    agg_arr={agg_arr}
                                    sex_tp_cd_arr={sex_tp_cd_arr}
                                    cl_cd_arr={cl_cd_arr}
                                    bed_arr={bed_arr}
                                    sido_cd_arr={sido_cd_arr}
                                    filter_place={filter_place}
                                    filter_category={filter_category}
                                    filter_combination={filter_combination}
                                    filter_method={filter_method}
                                    filter_stat_year={filter_stat_year}
                                    filter_category_selectedOption={filter_category_selectedOption}
                                    onChangeFilterCategory={this.props.onChangeFilterCategory}
                                />
                            </div>
                            <div className="schoption type3">
                                <FilterCombination
                                    filter_combination={filter_combination}
                                    filter_place={filter_place}
                                    filter_combination_selectedOption={filter_combination_selectedOption}
                                    onChangeFilterCombination={this.props.onChangeFilterCombination}
                                />
                            </div>
                            <div className="schoption type4">
                                <FilterMethod
                                    kcd_code_arr={kcd_code_arr}
                                    ctg_code3={ctg_code3}
                                    dgsbjt_cd_arr={dgsbjt_cd_arr}
                                    dgsbjt_dtl_cd_arr={dgsbjt_dtl_cd_arr}
                                    agg_arr={agg_arr}
                                    sex_tp_cd_arr={sex_tp_cd_arr}
                                    cl_cd_arr={cl_cd_arr}
                                    bed_arr={bed_arr}
                                    sido_cd_arr={sido_cd_arr}
                                    filter_place={filter_place}
                                    filter_category={filter_category}
                                    filter_combination={filter_combination}
                                    filter_method={filter_method}
                                    filter_stat_year={filter_stat_year}
                                    filter_method_selectedOption={filter_method_selectedOption}
                                    onChangeFilterMethod={this.props.onChangeFilterMethod}
                                />
                            </div>
                        </Fragment>
                        :
                        <MobileFilterOption
                            prescriptionList={prescriptionList}
                            kcd_code_arr={kcd_code_arr}
                            ctg_code3={ctg_code3}
                            filter_place={filter_place}
                            filter_place_change={filter_place_change}
                            filter_category={filter_category}
                            filter_method={filter_method}
                            filter_combination={filter_combination}
                            filter_stat_year={filter_stat_year}
                            dgsbjt_cd_arr={dgsbjt_cd_arr}
                            dgsbjt_dtl_cd_arr={dgsbjt_dtl_cd_arr}
                            agg_arr={agg_arr}
                            sex_tp_cd_arr={sex_tp_cd_arr}
                            cl_cd_arr={cl_cd_arr}
                            bed_arr={bed_arr}
                            sido_cd_arr={sido_cd_arr}
                            filter_category_selectedOption={filter_category_selectedOption}
                            filter_combination_selectedOption={filter_combination_selectedOption}
                            filter_method_selectedOption={filter_method_selectedOption}
                            filter_stat_year_selectedOption={filter_stat_year_selectedOption}
                            onChangeFilterPlace={this.props.onChangeFilterPlace}
                            onChangeFilterCategory={this.props.onChangeFilterCategory}
                            onChangeFilterCombination={this.props.onChangeFilterCombination}
                            onChangeFilterMethod={this.props.onChangeFilterMethod}
                            onChangeFilterStatYear={this.props.onChangeFilterStatYear}
                        />
                    }
                </div>
                {
                    (prescriptionList && prescriptionList.length > 0) ?
                        <Fragment>
                            <ul className="druglist">
                                <li>
                                    <div className="contlist title">
                                        {
                                            (filter_combination === "2combi" || filter_combination === "3combi") &&
                                            <div className="txtinfo td07">&nbsp;</div>
                                        }
                                        <div className="txtinfo td01">&nbsp;</div>
                                        <Tooltip title="검색질환, 상세검색의 조건에 따라 전체(100%)를 구성합니다">
                                            <div className="txtinfo td02">점유율</div>
                                        </Tooltip>
                                        <Tooltip title="복용 방법으로 내복/주사/외용/기타가 있습니다">
                                            <div className="txtinfo td02">투여</div>
                                        </Tooltip>
                                        <div className="txtinfo td03">성분명</div>
                                        <div className="txtinfo td04">해당 제품</div>
                                        <div className="txtinfo td05">&nbsp;</div>
                                    </div>
                                </li>
                                {
                                    prescriptionList.map((item, index) => {
                                        return (
                                            <li key={index} id={`item_${index+1}`}>
                                                <div className="contlist">
                                                    {
                                                        (filter_combination === "2combi" || filter_combination === "3combi") &&
                                                        <CombiIndicator
                                                            index={index + 1}
                                                            depth="2"
                                                            ingred_code_grp_arr={item.ingred_code_grp}
                                                            changeCombiValue={this.changeCombiValue}
                                                        />
                                                    }
                                                    <div className="txtinfo td01"><span>{index + 1}</span></div>
                                                    <div className="txtinfo td02"><span>{ order === "prescript" ? item.prescript_share_per : item.use_share_per}</span></div>
                                                    <div className="txtinfo td06">
                                                        {
                                                            item.method === "A" &&
                                                            <span className="type1">{item.method_kor}</span>
                                                        }
                                                        {
                                                            item.method === "B" &&
                                                            <span className="type2">{item.method_kor}</span>
                                                        }
                                                        {
                                                            item.method === "C" &&
                                                            <span className="type3">{item.method_kor}</span>
                                                        }
                                                        {
                                                            item.method === "D" &&
                                                            <span className="type4">{item.method_kor}</span>
                                                        }
                                                    </div>
                                                    <div className="txtinfo td03">
                                                        <ResponsiveDialog
                                                            item={item}
                                                            kcd_code_arr={kcd_code_arr}
                                                            ctg_code_grp={ctg_code_grp}
                                                            ctg_name={ctg_name}
                                                            ctg_code3={ctg_code3}
                                                            user={user}
                                                            kcd_list={kcd_list}
                                                            dgsbjt_cd_arr={dgsbjt_cd_arr}
                                                            dgsbjt_dtl_cd_arr={dgsbjt_dtl_cd_arr}
                                                            agg_arr={agg_arr}
                                                            sex_tp_cd_arr={sex_tp_cd_arr}
                                                            cl_cd_arr={cl_cd_arr}
                                                            bed_arr={bed_arr}
                                                            sido_cd_arr={sido_cd_arr}
                                                            userProfile={userProfile}
                                                            onLike={(liked_item) => {
                                                                // update 1st Depth
                                                                this.props.onLike(liked_item);
                                                                // update 2nd/3rd depth like
                                                                this.setState({
                                                                    liked_item: liked_item
                                                                })
                                                            }}
                                                            filter_place={filter_place}
                                                            filter_category={filter_category}
                                                            filter_combination={filter_combination}
                                                            filter_method={filter_method}
                                                            filter_stat_year={filter_stat_year}
                                                        />
                                                        {/**
                                                        <button className="dialog-button" onClick={()=>this.displayIngredDetails(item)}>
                                                            <p className="titxt">{item.combi_flag === "Y" && "(복합)"} {item.ingred_eng}</p>
                                                            <p className="subtxt">{item.atc_name}</p>
                                                        </button>
                                                         */}
                                                    </div>
                                                    <div className="txtinfo td04">
                                                        {
                                                            <Query query={PRODUCT_LIST} variables={{
                                                                ingred_code_grp: item.ingred_code_grp,
                                                                method: item.method,
                                                                ingred_combi_flag: item.combi_flag,
                                                                u_id: userProfile.u_id,
                                                                u_spc_code: userProfile.u_spc_code,
                                                                u_wtp_code: userProfile.u_wtp_code,
                                                                u_hospital_group_code: userProfile.u_hospital_group_code,
                                                                u_birthday: userProfile.u_birthday,
                                                                u_sex: userProfile.u_sex,
                                                                u_zip_zone: userProfile.u_zip_zone
                                                            }}>
                                                                {({ data, loading, error }) => {
                                                                    if (loading) return <p></p>
                                                                    if (error) return <p>{error.message}</p>;
                                                                    // //console.log('PRODUCT_LIST', data);
                                                                    const insurance_count = _.filter(data.getPdtList, ["insurance", "급여"]).length;

                                                                    return (
                                                                        <ProductList
                                                                            data={data}
                                                                            insurance_count={insurance_count}
                                                                            from_type="list"
                                                                        />
                                                                    )
                                                                }}
                                                            </Query>
                                                        }
                                                    </div>
                                                    <div className="txtinfo td05">
                                                        <FavoriteFragment
                                                            item={item}
                                                            ctg_code_grp={ctg_code_grp}
                                                            ctg_code3={ctg_code3}
                                                            kcd_code_arr={kcd_code_arr}
                                                            user={user}
                                                            onLike={(liked_item) => {
                                                                //update 1st Depth
                                                                this.props.onLike(liked_item);
                                                                //for 2nd/3rd depth like
                                                                this.setState({
                                                                    liked_item: liked_item
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                {
                                                    (filter_combination === "2combi" || filter_combination === "3combi") &&
                                                    (selected_combo2_index === (index + 1)) &&
                                                    <Query
                                                        query={PRESCRIPTION_LIST}
                                                        variables={{
                                                            kcd_code_arr: kcd_code_arr,
                                                            ctg_code3: ctg_code3,
                                                            dgsbjt_cd_arr: dgsbjt_cd_arr,
                                                            dgsbjt_dtl_cd_arr: dgsbjt_dtl_cd_arr,
                                                            agg_arr: agg_arr,
                                                            sex_tp_cd_arr: sex_tp_cd_arr,
                                                            cl_cd_arr: cl_cd_arr,
                                                            bed_arr: bed_arr,
                                                            sido_cd_arr: sido_cd_arr,
                                                            atc_code: filter_category,
                                                            //method: item.method,
                                                            method: filter_method,
                                                            u_id: u_id,
                                                            order: order,
                                                            combi_flag: filter_combination,
                                                            stat_year: filter_stat_year,
                                                            ingred_code_grp_arr: ingred_code_grp_arr,
                                                            ingred_combi_flag: item.combi_flag
                                                        }}
                                                        // fetchPolicy={"cache-and-network"}
                                                    >
                                                        {({ data, loading, error, fetchMore, refetch }) => {
                                                            if (loading) return <Combi2_PlaceHolder/>;
                                                            if (error) return <p>{error.message}</p>;
                                                            //console.log('2combi PRESCRIPTION_LIST', data.prescriptionList);

                                                            return (
                                                                <Combi2Fragment
                                                                    data={data}
                                                                    liked_item={liked_item}
                                                                    refetch={refetch}
                                                                    kcd_code_arr={kcd_code_arr}
                                                                    ctg_code_grp={ctg_code_grp}
                                                                    ctg_code3={ctg_code3}
                                                                    ctg_name={ctg_name}
                                                                    kcd_list={kcd_list}
                                                                    dgsbjt_cd_arr={dgsbjt_cd_arr}
                                                                    dgsbjt_dtl_cd_arr={dgsbjt_dtl_cd_arr}
                                                                    agg_arr={agg_arr}
                                                                    sex_tp_cd_arr={sex_tp_cd_arr}
                                                                    cl_cd_arr={cl_cd_arr}
                                                                    bed_arr={bed_arr}
                                                                    sido_cd_arr={sido_cd_arr}
                                                                    order={order}
                                                                    user={user}
                                                                    u_id={u_id}
                                                                    userProfile={userProfile}
                                                                    percent={order === "prescript" ? item.prescript_share_per_round5 : item.use_share_per_round5}
                                                                    ingred_code_grp_arr={ingred_code_grp_arr}
                                                                    filter_place={filter_place}
                                                                    filter_category={filter_category}
                                                                    filter_combination={filter_combination}
                                                                    filter_method={filter_method}
                                                                    filter_stat_year={filter_stat_year}
                                                                    displayIngredDetails={this.displayIngredDetails}
                                                                    onLike2nd={(liked_item) => {
                                                                        // update 2nd depth like
                                                                        this.updateLike2nd(liked_item, fetchMore);
                                                                        // update 1st depth like
                                                                        this.props.onLike(liked_item);
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    </Query>
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </Fragment>
                    :
                        <ZeroResult_Sample2/>
                }
                {
                    prescriptionList &&
                    prescriptionList.length > 0 &&
                    prescriptionList[0].tot_cnt > prescriptionCount &&
                    <div className="more_area" onClick={() => { this.setState({load_pagination: true}); this.props.onLoadMore(page_num + 1)} }>
                        <div style={{display:'inline-block'}}>
                        {
                            this.state.load_pagination &&
                            <BeatLoading_prescriptList/>
                        }
                        </div>
                        <div className="btn_more">
                            결과약물 더보기
                            <span>({page_num * this.props.row_count} / {prescriptionList[0].tot_cnt})</span>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default PrescriptList;