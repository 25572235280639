import React, {button, useEffect, useState} from 'react';
// import { importMapScripts } from './Kakaomap';
import CssBaseline from "@material-ui/core/CssBaseline";

// function withMapUtil(WrappedComponent) {
const withCodes = (WrappedComponent) => (props) => {
    const spcCode = [
        {code:"00", clicked: false, name: "일반의"},
        {code:"23", clicked: false, name: "가정의학과"},
        {code:"20", clicked: false, name: "결핵과"},
        {code:"01", clicked: false, name: "내과"},
        {code:"09", clicked: false, name: "마취통증의학과"},
        {code:"17", clicked: false, name: "방사선종양학과"},
        {code:"18", clicked: false, name: "병리과"},
        {code:"15", clicked: false, name: "비뇨의학과"},
        {code:"10", clicked: false, name: "산부인과"},
        {code:"08", clicked: false, name: "성형외과"},
        {code:"11", clicked: false, name: "소아청소년과"},
        {code:"02", clicked: false, name: "신경과"},
        {code:"06", clicked: false, name: "신경외과"},
        {code:"24", clicked: false, name: "응급의학과"},
        {code:"12", clicked: false, name: "안과"},
        {code:"16", clicked: false, name: "영상의학과"},
        {code:"26", clicked: false, name: "예방의학과"},
        {code:"04", clicked: false, name: "외과"},
        {code:"13", clicked: false, name: "이비인후과"},
        {code:"19", clicked: false, name: "진단검사의학과"},
        {code:"03", clicked: false, name: "정신건강의학과"},
        {code:"05", clicked: false, name: "정형외과"},
        {code:"21", clicked: false, name: "재활의학과"},
        {code:"25", clicked: false, name: "직업환경의학과"},
        {code:"14", clicked: false, name: "피부과"},
        {code:"22", clicked: false, name: "핵의학과"},
        {code:"07", clicked: false, name: "흉부외과"},
    ];
    const orgCode = [
        {code:"01", clicked: false, name:"상급종합병원"},
        {code:"11", clicked: false, name:"종합병원"},
        {code:"05", clicked: false, name:"전문병원"},
        {code:"21", clicked: false, name:"병원"},
        {code:"28", clicked: false, name:"요양병원"},
        {code:"31", clicked: false, name:"의원"},
        {code:"71", clicked: false, name:"보건소"},
    ];

    //시군구 코드
    const sidoCode = [
        {code: '11', name: '서울특별시'},
        {code: '21', name: '부산광역시'},
        {code: '22', name: '대구광역시'},
        {code: '23', name: '인천광역시'},
        {code: '24', name: '광주광역시'},
        {code: '25', name: '대전광역시'},
        {code: '26', name: '울산광역시'},
        {code: '29', name: '세종특별자치시'},
        {code: '31', name: '경기도'},
        {code: '32', name: '강원도'},
        {code: '33', name: '충청북도'},
        {code: '34', name: '충청남도'},
        {code: '35', name: '전라북도'},
        {code: '36', name: '전라남도'},
        {code: '37', name: '경상북도'},
        {code: '38', name: '경상남도'},
        {code: '39', name: '제주특별자치도'}
    ];

    return (
        <WrappedComponent spcCode={spcCode} orgCode={orgCode} sidoCode={sidoCode} {...props}/>
    )
}

export default withCodes;