import React, { Fragment } from 'react';
import { Query, withApollo } from 'react-apollo';
import $ from "jquery";
import _ from "lodash";
import Swiper from 'swiper';
import { isMobileOnly } from 'react-device-detect';
import Tooltip from '@material-ui/core/Tooltip';

import { COMMON_CODE_LIST } from '../../../queries/prescript/common';
import { CTG2_LIST, PRESCRIPTION_LIST, STAT_YEAR_MAX, INGRED_GM_NEW_LIST } from '../../../queries/prescript/prescription';

import CommonFunc from '../../../common/CommonFunction';
import Result_PlaceHolder from '../loading/Result_PlaceHolder';
import SearchFragment from './SearchFragment';
import CommentFragment from '../comment/CommentFragment';
import CategoryFragment from './CategoryFragment';
import PrescriptList from './PrescriptList';
import TitleMenu from '../common/TitleMenu';
import SearchDetailLayer from '../layer/SearchDetailLayer';
import KcdEditLayer from '../layer/KcdEditLayer';
import UserProfile from '../layer/UserProfileLayer';
import MobileSelectedPreUseOption from '../layer/MobileSelectedPreUseOption';
import KcdContentFragment from './KcdContentFragment';
import ZeroResult_KCD from '../common/ZeroResult_KCD';
import ZeroResult_Sample1 from '../common/ZeroResult_Sample1';
import Combi3_PlaceHolder from '../loading/Combi3_PlaceHolder';
import IngredGmNewList from './IngredGmNewList';
import LeftMenuBar from "./LeftMenuBar";

const row_count = 7;
const ingred_row_count = 5;
let page_num = 1;
let ingred_page_num = 1;
var swiper = null;

class Prescription extends React.PureComponent {

	constructor(props){
		super(props);

		//params from search home
		const { keyword, kcd_list } = this.props.history.location.state ? this.props.history.location.state : { keyword: '', kcd_list: [] };
		//console.log('SearchResult : ', keyword, kcd_list);

		this.state = {
			user: null,
			keyword: keyword,
			kcd_list_all: null,
			kcd_list: kcd_list,	//선택된 상병 목록 (노출용)
			kcd_select_flag: false,	//키워드 입력 후 상병 레이어 노출 여부
			force_rerender_category: false,
			detail_search_form_init_flag: false,
			ctg_code_grp: null,
			ctg_code2: null,
			ctg_code3: null,
			ctg_name2: null,
			ctg_name3: null,
			filter_place: "OUT",
			filter_place_change: false,
			filter_category: null,
			filter_combination: null,
			filter_method: null,
			filter_stat_year: 0,
			filter_category_selectedOption: null,
			filter_combination_selectedOption: null,
			filter_method_selectedOption: null,
			filter_stat_year_selectedOption: null,
			order: "prescript",
			dgsbjt_cd_arr: [],
			dgsbjt_dtl_cd_arr: [],
			agg_arr: [],
			sex_tp_cd_arr: [],
			cl_cd_arr: [],
			bed_arr: [],
			sido_cd_arr: [],
			tutorial01: false,
			tutorial02: false,

			stat_year_max: 0,
            no_sample_data: false
		}
	}

	closeTutorial = (id) => {
		//console.log('closeTutoorial', id);
		CommonFunc.setCookieDays(id, 'Y', 9999999);
		if(id === 'tutorial01'){
			this.setState({
				tutorial01: false
			})
		}
		else if(id === 'tutorial02'){
			this.setState({
				tutorial02: false
			})
		}
	}

	scrollToTop = () => {
		//window.scrollTo(0, 0);
		$('html, body').animate({ scrollTop: 0 }, 0);
	}

	componentWillMount = async () => {
		const user = await CommonFunc.getRealtimeUserInfo();
        //console.log('user', user)
		if(user){
			this.setState({
                user: user
            });
		}
		else{
			//console.log('로그인 되지 않은 유저~!!');
            const storageUser = await CommonFunc.getUserInfo();
            //console.log('storageUser', storageUser)
            if(storageUser){
                this.setState({
                    user: storageUser
                });
            }
		}

		await this.props.client.query({
			query: STAT_YEAR_MAX
		})
		.then((res) => {
			// filter_stat_year 초기화
			this.setState({
				filter_stat_year: res.data.getStatYearOne.stat_year,
				stat_year_max: res.data.getStatYearOne.stat_year
			});
		})
		.catch((error) => {
			//console.log('error', error.message);
		});
	}

	componentDidMount = () => {
		CommonFunc.pageview('처방정보', '성분리스트'); //Google Analytics
		//const user = CommonFunc.getSessionUserInfo();				//from storage
        if(this.state.keyword){
		    if(this.state.kcd_list.length > 0){
                this.searchPrescription(_.map(this.state.kcd_list, 'kcd_code'), this.state.kcd_list);
            }
		    else{
                this.setState({
                    no_sample_data: true
                })
            }
		}
	}

	onSearched = (keyword, kcd_list) => {
	    this.setState({
			keyword: keyword, //키워드 재입력 시 변경 안되는 문제 발생
			kcd_list: kcd_list,
			ctg_code_grp: null,
			ctg_code2: null,
			ctg_code3: null,
			ctg_name2: null,
			ctg_name3: null,
		})

		this.searchPrescription(_.map(kcd_list, 'kcd_code'), kcd_list);
	}

	searchPrescription = (selected_kcd_code_arr, kcd_list_all) => {
		this.resetPageNum();

		//console.log("onSearched kcd_code_arr", selected_kcd_code_arr);
		//console.log("onSearched kcd_list_all", kcd_list_all);
		//filter selected kcd list
		let kcd_list = kcd_list_all.filter(function(item) {
			return selected_kcd_code_arr.includes(item.order_cnt > 0 && item.kcd_code);
		})
		// //console.log('selected_kcd_list', kcd_list, kcd_list_all);

		this.setState({
			kcd_select_flag: true,
			kcd_list_all: kcd_list_all,
			kcd_list: kcd_list,
			dgsbjt_cd_arr: [],
			dgsbjt_dtl_cd_arr: [],
			agg_arr: [],
			sex_tp_cd_arr: [],
			cl_cd_arr: [],
			bed_arr: [],
			sido_cd_arr: [],
			detail_search_form_init_flag: !this.state.detail_search_form_init_flag,
			force_rerender_category: !this.state.force_rerender_category,
			ctg_code_grp: null,
			ctg_code2: null,
			ctg_code3: null,
            no_sample_data: false
		}, () => {
			this.calculateSwiper();
		})

		//history update
		const params = {
			'keyword': this.state.keyword,
			'kcd_list': kcd_list
		}
		this.props.history.push("/prescription", params);
	}

	filterKcdList = (kcd_code, kcd_list_all) => {
		//const value = e.target.value;
		let kcd_code_arr = _.map(this.state.kcd_list, 'kcd_code').filter(val => val !== kcd_code);
		//console.log('kcd_code_arr', kcd_code_arr);
		this.searchPrescription(kcd_code_arr, kcd_list_all);
	}

	onSearchPrescriptList = (ctg_code_grp, ctg_code, ctg_name2, ctg_name3) => {
	    this.resetPageNum();
		//console.log("onSearchPrescriptList ctg_code", ctg_code_grp, ctg_code, place)
		//console.log("onSearchPrescriptList state", this.state)
		this.setState({
			ctg_code_grp: ctg_code_grp,
			ctg_code3: ctg_code,
			ctg_name2: ctg_name2,
			ctg_name3: ctg_name3,
			//filter_place: place,
			filter_place_change: false,
			filter_category: null,
			filter_combination: null,
			filter_method: null,
			filter_category_selectedOption: null,
			filter_combination_selectedOption: null,
			filter_method_selectedOption: null,
			filter_stat_year: this.state.stat_year_max,
			filter_stat_year_selectedOption: {value: this.state.stat_year_max, label: this.state.stat_year_max},
            no_sample_data: false
		})
		$('.section_wrap').scrollTop(0);

		CommonFunc.pageview('처방정보', '카테고리', ctg_name3); //Google Analytics
	}

	sortPrescript = (afterOrder) => {
		this.resetPageNum();

		//console.log("afterOrder", afterOrder)
		this.setState({
			order: afterOrder
		})

		CommonFunc.pageview('처방정보', '처방기준', afterOrder === 'prescript' ? '처방건': '약물수'); //Google Analytics
	}

	onKcdRendered = () => {
		swiper = new Swiper('.swiper-container', {
			slidesPerView: 'auto',
			spaceBetween: 0,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			}
		});
	}

	onChangeDetailSearchCondition = (dgsbjt_cd_arr, dgsbjt_dtl_cd_arr, agg_arr, sex_tp_cd_arr, cl_cd_arr, bed_arr, sido_cd_arr) => {
		//console.log("dgsbjt_cd_arr", dgsbjt_cd_arr.length);
		//console.log("dgsbjt_dtl_cd_arr", dgsbjt_dtl_cd_arr.length);
		//console.log("agg_arr", agg_arr.length);
		//console.log("sex_tp_cd_arr", sex_tp_cd_arr.length);
		//console.log("cl_cd_arr", cl_cd_arr.length);
		//console.log("bed_arr", bed_arr.length);
		//console.log("sido_cd_arr", sido_cd_arr.length);

		this.resetPageNum();
		this.setState({
			dgsbjt_cd_arr: dgsbjt_cd_arr,
			dgsbjt_dtl_cd_arr: dgsbjt_dtl_cd_arr,
			agg_arr: agg_arr,
			sex_tp_cd_arr: sex_tp_cd_arr,
			cl_cd_arr: cl_cd_arr,
			bed_arr: bed_arr,
			sido_cd_arr: sido_cd_arr
		})
	}

	onChangeKcdSearchCondition = (kcd_list) => {
		this.resetPageNum();
		this.resetIngredPageNum();
		this.setState({
			kcd_list: kcd_list,
			force_rerender_category: !this.state.force_rerender_category,
			ctg_code2: null,
			ctg_code3: null,
		}, () => {
			this.calculateSwiper();
		})
	}

	resetPageNum = () => {
		page_num = 1;
	}

	resetIngredPageNum = () => {
		ingred_page_num = 1;
	}

	calculateSwiper = () => {
		if(swiper && !isMobileOnly){
			let realTotalKcdWidth = 0;
			$('.swiper-container .swiper-slide.slider__item').each(function(index){
				//console.log('itemsize', $(this).width())
				realTotalKcdWidth += $(this).width() + 10 //padding;
			})
			//console.log('swiper', realTotalKcdWidth, swiper.size, realTotalKcdWidth >= swiper.size ? "slidable->true" : "slidable->false")
			if(realTotalKcdWidth >= swiper.size){
				swiper.allowSlidePrev = true;
				swiper.allowSlideNext = true;
			}
			else{
				swiper.allowSlidePrev = false;
				swiper.allowSlideNext = false;
			}
			$('.swiper-container .swiper-wrapper').css('transform', 'translate3d(0px, 0px, 0px)');
		}
	}

	onChangeFilterPlace = (place) => {
		this.resetPageNum();
		this.setState({
			filter_place: place,
			filter_place_change: true,
		})

		CommonFunc.pageview('처방정보', '필터_처방장소', place==='OUT'?'외래':'입원'); //Google Analytics
	}

	onChangeFilterCategory = (category) => {
		//console.log("onChangeFilterCategory", category)
		this.resetPageNum();
		this.resetIngredPageNum();
		this.setState({
			filter_category: category.value,
			filter_category_selectedOption: category
		})

		CommonFunc.pageview('처방정보', '필터_ATC', category.label); //Google Analytics
	}

	onChangeFilterCombination = (combi) => {
		this.resetPageNum();
		this.resetIngredPageNum();
		let forcePlaceFlag = this.state.filter_place;
		if(combi.value === "2combi" || combi.value === "3combi"){
			forcePlaceFlag = "OUT";
		}
		this.setState({
			filter_place: forcePlaceFlag,
			filter_place_change: true,
			filter_combination: combi.value,
			filter_combination_selectedOption: combi
		})

		CommonFunc.pageview('처방정보', '필터_병용', combi.label); //Google Analytics
	}

	onChangeFilterMethod = (method) => {
		this.resetPageNum();
		this.resetIngredPageNum();
		this.setState({
			filter_method: method.value,
			filter_method_selectedOption: method
		})

		CommonFunc.pageview('처방정보', '필터_투여방법', method.label); //Google Analytics
	}

	onChangeFilterStatYear = (stat_year) => {
		this.resetPageNum();
		this.setState({
			filter_stat_year: stat_year.value,
			filter_stat_year_selectedOption: stat_year
		})

		CommonFunc.pageview('처방정보', '처방정보 기준 년도', stat_year.label); //Google Analytics
	}

	render() {

		const kcd_code_arr = _.map(this.state.kcd_list, 'kcd_code');
		const { dgsbjt_cd_arr, dgsbjt_dtl_cd_arr, agg_arr, sex_tp_cd_arr, cl_cd_arr, bed_arr, sido_cd_arr } = this.state;
		const detail_arr_count = dgsbjt_cd_arr.length + dgsbjt_dtl_cd_arr.length + agg_arr.length + sex_tp_cd_arr.length + cl_cd_arr.length + bed_arr.length + sido_cd_arr.length;
		//console.log('Prescription Render ====================================');

		//console.log('Prescription Render ====================================', this.state.ctg_code3);

		return (
			<Fragment>
				{/* tutorial */}
				{
					this.state.ctg_code3 !== null ?
						isMobileOnly ?
							<Fragment>
								{
									this.state.tutorial01 &&
									<div class="tutorial01">
										<p class="image txt"><img src="http://image.medigate.net/drgate/images/mobile01.png" width="320" height="119"/></p>
										<p class="image next" onClick={() => this.closeTutorial('tutorial01') }>
											<img src="http://image.medigate.net/drgate/images/mobile02.png" width="85" height="31"/>
										</p>
										<p class="cover"></p>
									</div>
								}
								{
									!this.state.tutorial01 && this.state.tutorial02 &&
									<div class="tutorial02">
										<p class="image txt"><img src="http://image.medigate.net/drgate/images/mobile03.png" width="305" height="209"/></p>
										<p class="image btn" onClick={() => this.closeTutorial('tutorial02') }>
											<img src="http://image.medigate.net/drgate/images/mobile04.png" width="228" height="54"/>
										</p>
										<p class="cover"></p>
									</div>
								}
							</Fragment>
						:
							<Fragment>
								{
									this.state.tutorial01 &&
										<div class="tutorial01" onClick={() => this.closeTutorial('tutorial01') }>
										<p class="image txt"><img src="http://image.medigate.net/drgate/images/pc01.png"/></p>
										<p class="image gradient"><img src="http://image.medigate.net/drgate/images/pc03.png"/></p>
									</div>
								}
								{
									!this.state.tutorial01 && this.state.tutorial02 &&
									<div class="tutorial02" onClick={() => this.closeTutorial('tutorial02') }>
										<p class="image txt"><img src="http://image.medigate.net/drgate/images/pc02.png" /></p>
										<p class="image gradient"><img src="http://image.medigate.net/drgate/images/pc04.png" /></p>
									</div>
								}
							</Fragment>
					:
					<Fragment></Fragment>
				}

				<div id="header" className="header">
					<LeftMenuBar />
					<TitleMenu/>

					<SearchFragment
						keyword={this.state.keyword}
						onSearched={this.onSearched}
						searchForm="PRESCRIPTION"
					/>

					{/*<!-- 상세검색 Layer -->*/}
					<div className="header-field menu_schoption">
						{
							<Query query={COMMON_CODE_LIST} variables={{
									detail_search_form_init_flag: this.state.detail_search_form_init_flag,
									dgsbjt_cd_arr: this.state.dgsbjt_cd_arr,
									dgsbjt_dtl_cd_arr: this.state.dgsbjt_dtl_cd_arr,
									agg_arr: this.state.agg_arr,
									sex_tp_cd_arr: this.state.sex_tp_cd_arr,
									cl_cd_arr: this.state.cl_cd_arr,
									bed_arr: this.state.bed_arr,
									sido_cd_arr: this.state.sido_cd_arr,

								}}>
								{({ data, loading, error }) => {
									if (loading) return null;
									if (error) return <p>{error.message}</p>;
									//console.log('COMMON_CODE_LIST', data);

									return (
										<SearchDetailLayer
											data={data}
											onChangeSearchCondition={this.onChangeDetailSearchCondition}
											detail_search_form_init_flag={this.state.detail_search_form_init_flag}
											dgsbjt_cd_arr={this.state.dgsbjt_cd_arr}
											dgsbjt_dtl_cd_arr={this.state.dgsbjt_dtl_cd_arr}
											agg_arr={this.state.agg_arr}
											sex_tp_cd_arr={this.state.sex_tp_cd_arr}
											cl_cd_arr={this.state.cl_cd_arr}
											bed_arr={this.state.bed_arr}
											sido_cd_arr={this.state.sido_cd_arr}
										/>
									)
								}}
							</Query>
						}

						<button className="dialog-button" data-toggle="modal" data-target="#schoption" onClick={()=>CommonFunc.pageview('처방정보', '상세검색')}>
							<p className="txtinfo">상세검색</p>
							{
								detail_arr_count > 0 &&
								<p className="sch-count">
									{detail_arr_count}
								</p>
							}
						</button>
					</div>

					{/*<!-- 처방건,약물선택 -->*/}
					{
						!isMobileOnly ?
						<div className="header-field menu_schtab">
							<div className="stv-radio-buttons-wrap">
								<input
									type="radio"
									className="stv-radio-button"
									name="radioButtonTest"
									value="1"
									id="button1"
									defaultChecked={this.state.order === "prescript"}
									onClick={() => this.sortPrescript("prescript")}
								/>
								<Tooltip title="처방전 건수 기준으로 점유율을 계산합니다">
								<label htmlFor="button1">처방건 기준</label>
								</Tooltip>
								<input
									type="radio"
									className="stv-radio-button"
									name="radioButtonTest"
									value="2"
									id="button2"
									defaultChecked={this.state.order === "use"}
									onClick={() => this.sortPrescript("use")}
								/>
								<Tooltip title="처방한 약물수 전체 기준으로 점유율을 계산합니다">
								<label htmlFor="button2">약물수 기준</label>
								</Tooltip>
							</div>
						</div>
						:
						<>
						<MobileSelectedPreUseOption
							order={this.state.order}
							sortPrescript={this.sortPrescript}
						/>
						{/**
						<div className="header-field menu_schtab">
							<div className="stv-radio-buttons-wrap">
								<Button variant="contained" color="primary">처방/약물</Button>
							</div>
						</div>
						 */}
						</>
					}

					{/*<!-- 사용자 정보 -->*/}
                    <UserProfile />
				</div>
				{ (this.state.kcd_list && this.state.kcd_list.length > 0 && this.state.kcd_select_flag) ?
					<Fragment>
						<div id="disease" className="disease">
							{ (this.state.kcd_list && this.state.kcd_list.length > 0 && this.state.kcd_select_flag) &&
								<Fragment>
									{/* 상병 Edit Layer */}
									<div className="openlayer-disease">
										<KcdEditLayer
											kcd_list={this.state.kcd_list}
											onChangeKcdSearchCondition={this.onChangeKcdSearchCondition}
										/>
										<button className="dialog-button" data-toggle="modal" data-target="#disease-option">
										<Tooltip title="검색 키워드 관련 모든 질환을 보여줍니다">
											<p className="txtinfo" onClick={()=>$('#disease-option .modal-dialog').animate({ scrollTop: 0 }, 'fast')}>상병 수정</p>
										</Tooltip>
										</button>
									</div>

									{/* 검색된 상병 목록 */}
									<div className="slider-outer-shell">
										<div className="swiper-container slider-wrapper" id="slider-wrapper">
										<div class="slider__shadowleft"></div>
										<div class="slider__shadowright"></div>
											<div className="swiper-wrapper slider" id="slider" style={{transform: 'translateX(0px)'}}>
											{
												this.state.kcd_list.map((item, index) =>
													<div key={index} className="swiper-slide slider__item">
														<span className="mdl-chip mdl-chip--deletable">
															<span className="mdl-chip__text">{item.kcd_code} : {item.kcd_kor}</span>
															<button type="button" className="mdl-chip__action" onClick={()=>this.filterKcdList(item.kcd_code, this.state.kcd_list) }><i className="icons-slide_del"></i></button>
														</span>
													</div>
												)
											}
											</div>
										</div>
										<div className="swiper-button-prev slider__nav-button previous" id="slider-previous"><svg enableBackground="new 0 0 404.258 404.258" version="1.1" viewBox="0 0 404.258 404.258" xmlns="http://www.w3.org/2000/svg"><polygon points="289.93 18 265.93 0 114.33 202.13 265.93 404.26 289.93 386.26 151.83 202.13"></polygon></svg></div>
										<div className="swiper-button-next slider__nav-button next" id="slider-next"><svg enableBackground="new 0 0 404.258 404.258" version="1.1" viewBox="0 0 404.258 404.258" xmlns="http://www.w3.org/2000/svg"><polygon points="138.33 0 114.33 18 252.43 202.13 114.33 386.26 138.33 404.26 289.93 202.13"></polygon></svg></div>
										<div ref={this.onKcdRendered}></div>
									</div>
								</Fragment>
							}
						</div>

						<div id="container" className="container" onScroll={() => { if($('.react-contexify').length > 0){ $('.react-contexify').hide(); }} }>
							{ (this.state.filter_stat_year > 0) &&
								<Query query={CTG2_LIST} variables={{
										kcd_code_arr: kcd_code_arr
									}}
								>
									{({ data, loading, error }) => {
										if (loading) return null;
										if (error) return <p>{error.message}</p>;
										//console.log('CTG2_LIST ctg_code3', this.state.ctg_code3, data);
										if(this.state.ctg_code3 === null){
										    this.setState({
                                                no_sample_data: true
                                            })
                                        }
										return (
											<CategoryFragment
												data={data}
												current_ctg_code={this.state.ctg_code3}
												ctg_name2={this.state.ctg_name2}
												ctg_name3={this.state.ctg_name3}
												force_rerender_category={this.state.force_rerender_category}
												onSearchPrescriptList={this.onSearchPrescriptList}
											/>
										)
									}}
								</Query>
							}
							{
								!_.isEmpty(this.state.kcd_list) && !_.isEmpty(this.state.ctg_code3) && (this.state.filter_stat_year > 0) ?
								<div className="section_wrap">
									<Query
										query={PRESCRIPTION_LIST}
										variables={{
											kcd_code_arr: kcd_code_arr,
											ctg_code3: this.state.ctg_code3,
											dgsbjt_cd_arr: this.state.dgsbjt_cd_arr,
											dgsbjt_dtl_cd_arr: this.state.dgsbjt_dtl_cd_arr,
											agg_arr: this.state.agg_arr,
											sex_tp_cd_arr: this.state.sex_tp_cd_arr,
											cl_cd_arr: this.state.cl_cd_arr,
											bed_arr: this.state.bed_arr,
											sido_cd_arr: this.state.sido_cd_arr,
											order: this.state.order,
											page_num: page_num,
											row_count: row_count,
											u_id: this.state.user ? this.state.user.id : "",
											place: this.state.filter_place,
											atc_code: this.state.filter_category,
											combi_flag: this.state.filter_combination,
											method: this.state.filter_method,
											stat_year: this.state.filter_stat_year
										}}
										// fetchPolicy={"network-only"}
										// fetchPolicy={"cache-and-network"}
									>
										{({ data, loading, error, fetchMore, refetch }) => {
											if (loading) return !isMobileOnly ? <Result_PlaceHolder/> : <Combi3_PlaceHolder/>;
											if (error) return <p>{error.message}</p>;

											//console.log('PRESCRIPTION_LIST ----------- ', data.getPrescriptionList);

											return (
												<PrescriptList
													user={ this.state.user }
													data={data}
													kcd_code_arr={kcd_code_arr}
													order={this.state.order}
													ctg_code_grp={this.state.ctg_code_grp}
													ctg_code3={this.state.ctg_code3}
													ctg_name={this.state.ctg_name3}
													filter_place={this.state.filter_place}
													filter_place_change={this.state.filter_place_change}
													filter_category={this.state.filter_category}
													filter_combination={this.state.filter_combination}
													filter_method={this.state.filter_method}
													filter_stat_year={this.state.filter_stat_year}
													filter_category_selectedOption={this.state.filter_category_selectedOption}
													filter_combination_selectedOption={this.state.filter_combination_selectedOption}
													filter_method_selectedOption={this.state.filter_method_selectedOption}
													filter_stat_year_selectedOption={this.state.filter_stat_year_selectedOption}
													kcd_list={this.state.kcd_list}
													u_id={this.state.user ? this.state.user.id : ""}
													page_num={page_num}
													row_count={row_count}
													favoriteFlag={this.state.favoriteFlag}
													dgsbjt_cd_arr={this.state.dgsbjt_cd_arr}
													dgsbjt_dtl_cd_arr={this.state.dgsbjt_dtl_cd_arr}
													agg_arr={this.state.agg_arr}
													sex_tp_cd_arr={this.state.sex_tp_cd_arr}
													cl_cd_arr={this.state.cl_cd_arr}
													bed_arr={this.state.bed_arr}
													sido_cd_arr={this.state.sido_cd_arr}
													onChangeFilterPlace={this.onChangeFilterPlace}
													onChangeFilterCategory={this.onChangeFilterCategory}
													onChangeFilterCombination={this.onChangeFilterCombination}
													onChangeFilterMethod={this.onChangeFilterMethod}
													onChangeFilterStatYear={this.onChangeFilterStatYear}
													onLike={(liked_item) => {
														//console.log('liked_item_result cmcmcmcm', liked_item);
														fetchMore({
															updateQuery: (prev, { fetchMoreResult }) => {
																let list = [];
																prev.getPrescriptionList.map(item => {
																	if(liked_item.ingred_code_grp === item.ingred_code_grp && liked_item.combi_flag === item.combi_flag && liked_item.method === item.method){
																		//console.log('liked_item_update', liked_item, item);
																		list.push({...item, like_flag: liked_item.like_flag, total_like_cnt: liked_item.total_like_cnt});
																	}
																	else{
																		list.push(item);
																	}
																})
																return Object.assign({}, prev, {
																	getPrescriptionList: list
																});
															}
														})
													}}
													onLoadMore={(next_page_num) => {
														page_num = next_page_num;
														fetchMore({
															variables: {
																page_num: page_num
															},
															updateQuery: (prev, { fetchMoreResult }) => {
																//console.log("prev", prev.getPrescriptionList);
																//console.log("fetchMoreResult", fetchMoreResult.getPrescriptionList);
																if (!fetchMoreResult) return prev;
																// return fetchMoreResult.getPrescriptionList;
																return Object.assign({}, prev, {
																	getPrescriptionList: [...prev.getPrescriptionList, ...fetchMoreResult.getPrescriptionList]
																});
															}
														})
													}}
												/>
											)
										}}
									</Query>


								{/** INGRED GM NEW */}
								{
									!_.isEmpty(this.state.kcd_list) && !_.isEmpty(this.state.ctg_code3) && (this.state.filter_stat_year > 0) &&
									<Query
										query={INGRED_GM_NEW_LIST}
										variables={{
											ctg_code3: this.state.ctg_code3,
											atc_code: this.state.filter_category,
											combi_flag: this.state.filter_combination,
											method: this.state.filter_method,
											page_num: ingred_page_num,
											kcd_code_arr: kcd_code_arr,
											place: this.state.filter_place,
											stat_year: this.state.filter_stat_year
										}}
										// fetchPolicy={"network-only"}
										// fetchPolicy={"cache-and-network"}
									>
										{({ data, loading, error, fetchMore, refetch }) => {
											if (loading) return !isMobileOnly ? <Result_PlaceHolder/> : <Combi3_PlaceHolder/>;
											if (error) return <p>{error.message}</p>;

											//console.log('PRESCRIPTION_LIST ----------- ', data.getPrescriptionList);

											return (
												<IngredGmNewList
													data={data}
													ctg_code3={this.state.ctg_code3}
													row_count={ingred_row_count}
													page_num={ingred_page_num}
													onLoadMore={(next_page_num) => {
														ingred_page_num = next_page_num;
														//console.log("this.state.pagenum", this.state, ingred_page_num, ingred_row_count);

														fetchMore({
															variables: {
																page_num: ingred_page_num
															},
															updateQuery: (prev, { fetchMoreResult }) => {
																//console.log("prev", prev.getPrescriptionList);
																//console.log("fetchMoreResult", fetchMoreResult.getPrescriptionList);
																if (!fetchMoreResult) return prev;
																// return fetchMoreResult.getPrescriptionList;
																return Object.assign({}, prev, {
																	getIngredGmNewList: [...prev.getIngredGmNewList, ...fetchMoreResult.getIngredGmNewList]
																});
															}
														})
													}}
												/>
											)
										}}
									</Query>
								}

								{/** WIKIPEDIA CONTENT */}
								{
									!_.isEmpty(this.state.kcd_list) && !_.isEmpty(this.state.ctg_code3) && this.state.kcd_select_flag &&
									<KcdContentFragment
										wikiKeyword={this.state.kcd_list[0].wikiKeyword}
										ctg_code={ this.state.ctg_code3 }
										kcd_list={ this.state.kcd_list }
									/>
								}

								{/** KCD YOUTUBE CONTENT */}
								{/**  */}

								{/* 댓글 */}
								{ !_.isEmpty(this.state.kcd_list) && !_.isEmpty(this.state.ctg_code3) && this.state.kcd_select_flag &&
									<CommentFragment
										ctg_name={ this.state.ctg_name3 }
										ctg_name2={ this.state.ctg_name2 }
										ctg_code={ this.state.ctg_code3 }
										kcd_list={ this.state.kcd_list }
										user={ this.state.user }
									/>
								}
							</div>
							:
							<>{this.state.no_sample_data?<ZeroResult_Sample1/>:''}</>
							}
						</div>
					</Fragment>
				:
					<Fragment>
						{
							_.isEmpty(this.state.kcd_list) && !_.isEmpty(this.state.kcd_list_all) ?
							<ZeroResult_Sample1/>
							:
							<ZeroResult_KCD
								keyword={this.state.keyword}
							/>
						}
					</Fragment>
				}
			</Fragment>
		);
	}
}

export default withApollo(Prescription);

