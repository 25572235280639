import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';

import SMobileMenu from "./S_MobileMenu";
import SSalaryGuide from "./S_SalaryGuide";

function S_MainMenu(props){
    const { width, currentMenu } = props;
    const menuStyle = (menu) => {
        let styleObject = null;
        if(menu === currentMenu){
            styleObject = {display:'inline-block',color:"#7d69d4",borderBottom:'5px solid #6a51cd',lineHeight:'55px'}
        }
        return styleObject;
    }
    return (
        <Fragment>
            <SSalaryGuide/>
            {
            width === "xs" || width === "sm" ?
                <SMobileMenu currentMenu={currentMenu}/>
                :
                <div className="headermenu">
                    <Link to="/salary/payinfo" style={menuStyle(0)}>
                        급여정보
                    </Link>
                    <Link to="/salary/worktime" style={menuStyle(1)}>
                        근무시간
                    </Link>
                    <Link to="/salary/condition" style={menuStyle(2)}>
                        근무환경
                    </Link>
                    <Link to="/salary/demand" style={menuStyle(3)}>
                        초빙수요
                    </Link>
                </div>
        }
        </Fragment>
    )
}

export default S_MainMenu;