import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={event => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: "20px"
    },
}));

export default function LocationGuideContent() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root} style={{background:"#f4f5f9"}}>
            <AppBar className="guidecont-tab">
                <Tabs
                    className="tabcont"
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs guideLoc"                >
                    <LinkTab className="tabs" label="서비스 이용 안내" href="/guideloc" {...a11yProps(0)} />
                    <LinkTab className="tabs" label="입지분석은?" href="/wharloc" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={1} className="guidecont-info">
                <Typography variant="h6" gutterBottom className="title">
                    입지분석 서비스는 검색지역의 인구정보, 생활업종 및 주요 편의시설 정보부터, 진료청구통계 등<br/>다양한 지표를 통하여 병의원 입지에 대한 인사이트를 드리고자 기획되었습니다.
                </Typography>
            </TabPanel>
            <TabPanel value={value} index={1} className="guidecont-info type2">
                <Typography gutterBottom className="title" style={{margin:"0 0 20px 30px"}}>
                    인용된 데이터 지표는 다음과 같습니다.
                </Typography>
                <div className="infoarea">
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type2">
                        <span className="info01">통계지리정보서비스 API</span>
                        총인구수 / 인구밀도 / 총가구수 / 총사업체수 / 연령대별인구수 / 성별인구수 / 주거유형(%) / 거주인구유형 / 생활업종&편의시설수 통계
                    </Typography>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type2">
                        <span className="info03">건강정보심사평가원</span>
                        의료기관수 / 의료기관개폐업현황 / 전문과목-진료과목별 의원수현황 / 질환별 처방건수&처방액수 관련 통계
                    </Typography>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type2">
                        <span className="info02">소상공인시장진흥공단</span>
                        주요 상권경계
                    </Typography>
                </div>
                <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type2" style={{padding:"10px 0 0",color:"rgba(255,255,255,0.7)"}}>
                모든 데이터는 각 기관이 제공하는 최신 데이터를 반영하였습니다. <br/>
                    각 지표와 관련된 설명은 서비스 이용안내를 참고 해주시기 바랍니다.
                </Typography>
                <Typography gutterBottom className="title" style={{margin:"60px 0 20px 30px"}}>
                    서비스 주요 특징
                </Typography>
                <div className="infoarea">
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type2">
                        <span className="info04">지도를 기반으로 한 서비스</span>
                        입지분석은 지도 기반 서비스 입니다. 지도 이동을 통해서 해당 지역의 의료기관 수 및 위치 확인, 해당 지역의 상권정보, 편의시설, 임대매물 정보까지 한번에 확인하실 수 있습니다.
                    </Typography>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type2">
                        <span className="info05">트렌드 분석</span>
                        개원 예정자가 관심있는 과목 및 지역을 선정하여 의료기관의 증감, 개/폐업추이, 질환별 처방건수, 처방액수, 환자수 추이 등을 통해 전반적인 트렌드를 파악해 볼 수 있습니다.
                    </Typography>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type2">
                        <span className="info06">상권 배후 정보 지원</span>
                        연령대 및 성별에 따른 인구분포, 해당지역 상가점포수와 점포 증감 추이, 공공기관 등 주위 편의시설수 및 증감 추이를 통해 상권 배후 정보를 파악해 볼 수 있습니다.
                    </Typography>
                </div>
            </TabPanel>
            <TabPanel value={value} index={0} className="guidecont-info type2">
                <div>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt" style={{textAlign:"center",dlsplay:"block"}}>
                        검색 주소지, 진료과/전문과, 의료기관 유형을 선택하시고 결과보기 버튼을 누르시면,<br/>해당지역의 의료기관 통계, 인구및상권정보, 시군구 진료청구통계를 확인하실 수 있습니다.
                    </Typography>
                </div>  
            </TabPanel>
            <TabPanel value={value} index={0} className="guidecont-info type2"> 
                <div className="infoarea" style={{paddingBottom:"40px"}}>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type3">
                        <span className="tab bgimg21"></span>
                        <span className="title">주소지검색</span>
                        사용자의 근무처 주소지 또는 설정 검색지역 주소지가 기본지역으로 활용되며, 주소 검색, 지도 이동 등을 통해서 변경할 수 있습니다.<br/>
                        입지분석 결과 데이터를 보기 위해서는 행정구역을 읍면동 단위까지 입력하셔야 합니다. (ex. 서울시 강남구 청담동)
                    </Typography>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type3">
                        <span className="tab bgimg22"></span>
                        <span className="title">진료과/전문과 선택</span>
                        입지분석 결과 데이터를 보기 위한 검색 필수 요건으로, 진료과 또는 전문과를 설정하시면, 해당 진료과 또는 전문과에 해당하는 의료기관의 위치가 지도 위에 표시 됩니다.<br/>
                        최대 5개 전문과 까지 선택 하실 수 있습니다. 
                        <span className="subtxt" style={{paddingTop:"10px"}}>진료과 선택 : 진료과를 선택하시면 해당 전문과를 진료과목으로 설정한 의료기관 목록을 확인하실 수 있습니다. </span>
                        <span className="subtxt">전문과 선택 : 전문과를 선택하시면 해당 과 전문의가 근무하고 있는 의료기관 목록을 확인하실 수 있습니다. </span>
                    </Typography>
                </div>
                <div className="infoarea" style={{paddingBottom:"40px"}}>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type3">
                        <span className="tab bgimg23"></span>
                        <span className="title">의료기관 유형 선택</span>
                        검색하고자 하는 의료기관 유형을 선택하실 수 있습니다.<br/>
                        선택하지 않으시는 경우 해당 검색지역의 모든 의료기관이 지도상에 표시되며, 항목 선택시 선택하신 기관타입만 지도에 표시됩니다. 
                    </Typography>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type3">
                        <span className="tab bgimg24"></span>
                        <span className="title">영역 선택</span>
                        지도위에 원형, 다각형, 사각형으로 분석영역을 설정 할 수 있습니다.<br/>
                        마우스 클릭으로 그리기를 진행하면 해당 영역내 의료기관을 확인하실 수 있으며, 해당 영역에 대한 입지분석 결과도 확인하실 수 있습니다. 
                        <span className="subtxt" style={{paddingTop:"10px"}}>영역그리기 시행시 해당 영역 내 위치한 상권 및 행정동 경계가 지도에 표현됩니다. (다각형은 제외) </span>
                    </Typography>
                </div>
                <div className="infoarea" style={{paddingBottom:"40px"}}>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type3">
                        <span className="tab bgimg25"></span>
                        <span className="title">다중 입지분석</span>
                        2개의 영역(반경/사각/다각) 그리기를 실행하여 해당 지역의 입지분석 통계를 비교해서 보실 수 있습니다. 
                    </Typography>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type3">
                        <span className="tab bgimg26"></span>
                        <span className="title">인기 조회 지역</span>
                        사용자가 많이 조회하는 지역을 시도단위, 조회전문과 단위로 확인하실 수 있습니다. 
                    </Typography>
                </div>
                <div className="infoarea" style={{paddingBottom:"40px"}}>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type3">
                        <span className="tab bgimg27"></span>
                        <span className="title">지도 컨트롤 </span>
                        - 지도보기, 스카이뷰 : 지도 보기 옵션을 조정하실 수 있습니다.<br/>
                        - 확대/축소 : 보고있는 지도의 축적을 조율합니다.<br/>
                        - 주변 시설 보기 아이콘 : 각 해당하는 아이콘을 클릭하면, 해당 시설의 위치가 지도상에 표시됩니다.
                    </Typography>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type3">
                        <span className="tab bgimg28"></span>
                        <span className="title">선택지역 통계 보기</span>
                        버튼 클릭시 해당 지역 의료기관/진료청구통계/인구&상권분석 등 해당 지역의 입지분석 결과를 확인하실 수 있습니다.
                    </Typography>
                </div>
                <div className="infoarea" style={{paddingBottom:"40px"}}>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type3">
                        <span className="tab bgimg29"></span>
                        <span className="title">즐겨찾기 메뉴</span>
                        - 검색지역 설정 : 지도 중심을 설정합니다.<br/>
                        - 근무처 주소지로 설정 : 지도 중심은 근무처 주소지로 설정합니다. (기본값)<br/>
                        - 조회이력 : 기존 조회내역을 확인하실 수 있습니다. 조회하셨던 지역, 진료/전문과, 기관유형이 저장 됩니다. 
                    </Typography>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type3">
                        <span className="tab bgimg30"></span>
                        <span className="title">시군구 매물정보</span>
                        메디게이트 임대분양서비스에 등록된 게시글 중 검색하신 행정구역(시군구 기준)내 매물 정보를 바로 확인 하실 수 있습니다. 
                    </Typography>
                </div>
                <div className="infoarea">
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type3">
                        <span className="tab bgimg31"></span>
                        <span className="title">의료기관정보</span>
                        검색하신 지역의 의료기관정보를 제공합니다. (건강보험심사평가원 등록 의료기관)<br/>
                        의료기관명, 주소, (홈페이지), 개설일, 병상수, 전화번호 정보를 확인 하실 수 있습니다. 
                    </Typography>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type3"></Typography>
                </div>
            </TabPanel>
            <TabPanel value={value} index={0} className="guidecont-info type3"> 
                <div className="infoarea" style={{paddingBottom:"40px"}}>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type4">
                        <span className="tab bgimg41"></span>
                        <div className="txtarea">
                            <span className="title">검색영역 의료기관정보</span>
                            <span className="subtxt2">(2020년 기준 데이터)</span>
                            검색한 지역에 있는 전체 의료기관수, 의원급 증감 및 개폐원추이, 진료과/전문의별 의료기관수를 보실 수 있습니다. 
                            <span className="subtxt" style={{paddingTop:"10px"}}>- 전체의료기관 : 해당지역 의료기관의 전체 및 유형별 의료기관 수</span>
                            <span className="subtxt">- 의원수 증감 추이(최근 1년/최근10년) : 해당 지역의 의원수 증감 추이</span>
                            <span className="subtxt">- 의원 개폐원 추이(증감누적/개업-폐업) : 최근 12개월간 해당 지역 의원수 증감 & 개폐원 추이</span>
                            <span className="subtxt">- 전문의&진료과목별 의원수 현황 : 검색 전문의&진료과목별 의원수 현황</span>
                        </div>
                    </Typography>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type4">
                        <span className="tab bgimg42"></span>
                        <div className="txtarea">
                            <span className="title">인구/상권 정보</span>
                            <span className="subtxt2">(2019년 기준 데이터/통계청 제공 최신 데이터)</span>
                            검색한 지역의 총 인구수, 인구밀도, 총 가구수, 총 사업체수, 연령별인구수, 성별 인구수, 주거유형비율, 거주인구특성, 생활업종 수&증감추이, 주요편의시설 수&증감추이를 확인하실 수 있습니다. 
                            <span className="subtxt" style={{paddingTop:"10px"}}>- 총 인구수 : 검색 행정구역의 전체 인구수</span>
                            <span className="subtxt">- 인구밀도 : 각 행정구역 단위별 인구밀도가 표현됩니다.</span>
                            <span className="subtxt">- 총 가구수 : 검색 행정구역의 가구수 총계 입니다.</span>
                            <span className="subtxt">- 총 사업체수 : 검색 행정구역의 사업체수 입니다.</span>
                            <span className="subtxt">- 연령별 인구수 : 검색 행정구역의 연령대별 인구수</span>
                            <span className="subtxt">- 성별 인구수 : 검색 행정구역의 남녀 성별 인구수</span>
                            <span className="subtxt">- 주거유형비율 : 검색 행정구역의 주거 유형별 비중을 %로 나타낸 그래프 입니다.</span>
                            <span className="subtxt">- 거주인구특성 : 검색 행정구역의 거주인구 특성화 그래프 입니다.</span>
                            <span className="subtxt">- 생활업종수 & 증감추이 : 검색 행정구역의 생활업종(서비스업, 도소매, 숙박시설, 음식점) 수와 증감 추이를 보실 수 있습니다.</span>
                            <span className="subtxt">- 주요편의시설수 & 증감추이 : 검색 행정구역의 주요편의시설(공공기관, 교육시설, 기업, 편의/문화시설, 교통, 기타) 수와 증감 추이를 보실 수 있습니다.</span>
                        </div>
                    </Typography>
                    <Typography component="p" variant="subtitle1" gutterBottom className="subtxt type4">
                        <span className="tab bgimg43"></span>
                        <div className="txtarea">
                            <span className="title">시군구 진료청구 통계</span>
                            <span className="subtxt2">(2020년 10월 기준 데이터/심평원 제공 최신 데이터)</span>
                            건강보험심사평가원에서 수집한 '의원급' 급여 처방 데이터 중, 매월 처방액 기준으로 시군구별 & 전문과별 상위 50개 질환을 추출해 구성되었습니다.<br/>
                            제한된 과에서만 처방하는 빈도가 낮은 질환의 경우,  2개 이상의 전문과를 선택할 때 일부 전문과의 데이터가 누락된 결과니 해석에 주의하세요.
                            <span className="subtxt" style={{paddingTop:"10px"}}>- 해당 시군구 처방개요(환자수) : 조회전문과별 해당시군구의 처방환자수를 의미합니다. (2020년 10월 기준/최근 12개월간 추이 포함)</span>
                            <span className="subtxt">- 해당 시군구 처방개요(처방건수) : 조회전문과별 해당시군구의 처방건수를 의미합니다. (2020년 10월 기준/최근 12개월간 추이 포함)</span>
                            <span className="subtxt">- 해당 시군구 처방개요(처방액수) : 조회전문과별 해당시군구의 처방액수를 의미합니다. (2020년 10월 기준/최근 12개월간 추이 포함)</span>
                            <span className="subtxt">- 해당 시군구 질환별 처방 개요 : 해당 시군구의 선택 전문과별(진료과 외) 질환별 처방 개요 입니다. 각 전문과별로 상위 20위까지의 질환순위 세부정보를 확인하실 수 있습니다. (상병명, 환자수, 처방건수, 처방액수, 건당 평균 처방액, 환자당 평균 처방건수, 환자당 평균 처방액 순)</span>
                            <span className="subtxt">- 해당 시도단위 시군구별 주요지표 비교(의원) : 조회 전문과별 해당시도단위의 전체 행정구역별 처방액, 처방건수, 환자수건당 처방액, 환자당 처방건수, 환자당 처방액을 비교해보실 수 있습니다.</span>
                        </div>
                    </Typography>
                </div>
            </TabPanel>
        </div>
    );
}