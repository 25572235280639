import React, { Component, Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Skeleton from "@material-ui/lab/Skeleton";

import SPercentAreaChart from './S_PercentAreaChart';
import CommonFunction from "../../common/CommonFunction";
import {HTP_CODE, LOC_CODE, SPC_CODE} from "./UserConstants";
import STitle from "./S_Title";
import SBoxNoLongContent from "./S_BoxNoLongContent";
import _ from "lodash";

class S_LongPercentAreaGraph extends Component {
    render() {
        const {
            data=[],
            fixedHeightPaper,
            loopCount,
            spc_code,
            hop_loc_code,
            org_type,
            loading,
            state_filter_value
        } = this.props;

        let allArr = [];
        let spcArr = [];
        let locArr = [];
        let orgArr = [];
        let allMinVal = 1;
        let spcMinVal = 1;
        let locMinVal = 1;
        let orgMinVal = 1;
        let allMaxVal = 0;
        let spcMaxVal = 0;
        let locMaxVal = 0;
        let orgMaxVal = 0;
        let minCorrectionVal = -0.025;
        let maxCorrectionVal = 0.025;

        let deleteIndex = 1;
        if(state_filter_value === "red_work"){
            deleteIndex = 4;
        }else{
            deleteIndex = 5;
        }

        data.forEach((item, i) => {
            item.forEach((innerItem) => {
                let tmpArray = _.toArray(innerItem)
                let tmpVal = tmpArray[2] * 0.01;
                if(i === 0){
                    allArr.push(_.dropRight(tmpArray, deleteIndex));
                    if(allMinVal > tmpVal) allMinVal = tmpVal;
                    if(allMaxVal < tmpVal) allMaxVal = tmpVal;
                }else if(i === 1){
                    spcArr.push(_.dropRight(tmpArray, deleteIndex));
                    if(spcMinVal > tmpVal) spcMinVal = tmpVal;
                    if(spcMaxVal < tmpVal) spcMaxVal = tmpVal;
                }else if(i === 2){
                    locArr.push(_.dropRight(tmpArray, deleteIndex));
                    if(locMinVal > tmpVal) locMinVal = tmpVal;
                    if(locMaxVal < tmpVal) locMaxVal = tmpVal;
                }else if(i === 3){
                    orgArr.push(_.dropRight(tmpArray, deleteIndex));
                    if(orgMinVal > tmpVal) orgMinVal = tmpVal;
                    if(orgMaxVal < tmpVal) orgMaxVal = tmpVal;
                }
            });
        });


        allMinVal = _.round(allMinVal + minCorrectionVal, 2);
        spcMinVal = _.round(spcMinVal + minCorrectionVal, 2);
        locMinVal = _.round(locMinVal + minCorrectionVal, 2);
        orgMinVal = _.round(orgMinVal + minCorrectionVal, 2);
        if(state_filter_value !== "red_work"){
            allArr.unshift(["", "예", { role: "annotation" }, "아니오"]);
            spcArr.unshift(["", "예", { role: "annotation" }, "아니오"]);
            locArr.unshift(["", "예", { role: "annotation" }, "아니오"]);
            orgArr.unshift(["", "예", { role: "annotation" }, "아니오"]);
            allMaxVal = _.round(allMaxVal + maxCorrectionVal, 2);
            spcMaxVal = _.round(spcMaxVal + maxCorrectionVal, 2);
            locMaxVal = _.round(locMaxVal + maxCorrectionVal, 2);
            orgMaxVal = _.round(orgMaxVal + maxCorrectionVal, 2);
        }else{
            allArr.unshift(["", "휴무", { role: "annotation" }, "순번근무", "근무"]);
            spcArr.unshift(["", "휴무", { role: "annotation" }, "순번근무", "근무"]);
            locArr.unshift(["", "휴무", { role: "annotation" }, "순번근무", "근무"]);
            orgArr.unshift(["", "휴무", { role: "annotation" }, "순번근무", "근무"]);
            // red_work 일 경우 최대값 1로 고정
            allMaxVal = 1;
            spcMaxVal = 1;
            locMaxVal = 1;
            orgMaxVal = 1;
        }
        //console.log("allArr", allArr);
        //console.log("spcArr", spcArr);
        //console.log("locArr", locArr);
        //console.log("orgArr", orgArr);

        return (
            <Fragment>
                {
                    Array.from(new Array(loopCount)).map((item, index) => (
                        <Grid item xs={12}>
                            <Paper className={fixedHeightPaper}>
                                <STitle align="left">
                                    {
                                        data.length > 0 && index === 0 &&
                                        <Fragment>전체</Fragment>
                                    }
                                    {
                                        data.length > 0 && index === 1 &&
                                        CommonFunction.getCodeName(spc_code, SPC_CODE)
                                    }
                                    {
                                        data.length > 0 && index === 2 &&
                                        CommonFunction.getCodeName(hop_loc_code, LOC_CODE)
                                    }
                                    {
                                        data.length > 0 && index === 3 &&
                                        CommonFunction.getCodeName(org_type, HTP_CODE)
                                    }
                                </STitle>
                                {
                                    !data.length > 0 || loading ?
                                    <Fragment>
                                        <Skeleton variant="rect" width="100%" height={240} />
                                    </Fragment>
                                    :
                                        loopCount === 3 ?
                                            index === 0 ?
                                            <SPercentAreaChart
                                                data={spcArr}
                                                fill="#666666"
                                                state_filter_value={state_filter_value}
                                                min={spcMinVal}
                                                max={spcMaxVal}
                                            />
                                            :
                                            index === 1 ?
                                                <SPercentAreaChart
                                                    data={locArr}
                                                    fill="#1e88e5"
                                                    state_filter_value={state_filter_value}
                                                    min={locMinVal}
                                                    max={locMaxVal}
                                                />
                                            :
                                            <SPercentAreaChart
                                                data={orgArr}
                                                fill="#43a047"
                                                state_filter_value={state_filter_value}
                                                min={orgMinVal}
                                                max={orgMaxVal}
                                            />
                                        :
                                        loopCount === 4 ?
                                            index === 0 ?
                                            <SPercentAreaChart
                                                data={allArr}
                                                fill="#666666"
                                                state_filter_value={state_filter_value}
                                                min={allMinVal}
                                                max={allMaxVal}
                                            />
                                            :
                                            index === 1 ?
                                            <SPercentAreaChart
                                                data={spcArr}
                                                fill="#1e88e5"
                                                state_filter_value={state_filter_value}
                                                min={spcMinVal}
                                                max={spcMaxVal}
                                            />
                                            :
                                            index === 2 ?
                                                data[index].length !== 1 ?
                                                <SPercentAreaChart
                                                    data={locArr}
                                                    fill="#43a047"
                                                    state_filter_value={state_filter_value}
                                                    min={locMinVal}
                                                    max={locMaxVal}
                                                />
                                                :
                                                <SBoxNoLongContent
                                                    dataType="지역"
                                                />
                                            :
                                                data[index].length !== 1 ?
                                                <SPercentAreaChart
                                                    data={orgArr}
                                                    fill="#666666"
                                                    state_filter_value={state_filter_value}
                                                    min={orgMinVal}
                                                    max={orgMaxVal}
                                                />
                                                :
                                                <SBoxNoLongContent
                                                    dataType="병원타입"
                                                />
                                        :
                                        <SPercentAreaChart
                                            data={data}
                                            fill="#666666"
                                            name="전체"
                                        />
                                }

                            </Paper>
                        </Grid>
                    ))
                }
            </Fragment>
        )
    }
}

export default S_LongPercentAreaGraph;