import React, { Component } from 'react';
import Chart from "react-google-charts";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";

class S_BarChart extends Component {
    render() {
        const { data, format } = this.props;
        const { width } = this.props;


        return (
            <div className="App">
                <Chart
                    chartType="ColumnChart"
                    width={(width === "sm" || width === "xs") ? "700px" : "100%"}
                    //모바일 width="170%" 
                    height="240px"                  
                    data={data}
                    options={{
                        legend: { position: 'top' },
                        explorer: { axis: "horizontal", keepInBounds: true },
                        annotations: {
                            allValuesSuffix: format,
                            textStyle: {
                                fontName:'verdana',
                                fontSize: (width === "sm" || width === "xs") ? 10 : 11,
                                bold: true,
                                color: '#000',
                                opacity: 0.8,
                            },
                        },
                        backgroundColor:'none',
                        fontName:'Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',letterSpacing:'-1px',fontSize:'12px',
                        hAxis: {
                            textStyle: {
                                fontSize: (width === "sm" || width === "xs") ? 9 : 12
                            }
                        },
                        vAxis: {
                            textStyle: {
                                fontName:'verdana,Arial,Noto Sans KR,Dotum',
                                fontSize: 10,color:'#999'
                                }
                            },
                        chartArea: {width:'93%'},
                        colors: ['#7d69d4']
                    }}
                />
            </div>
        )
    }
}

S_BarChart.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(S_BarChart);