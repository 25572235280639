import React, {useState, useEffect, Fragment} from 'react';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import useStyles from "../style/common";
import Draggable from "react-draggable";
import DraggableCore from "react-draggable";
import InfoIcon from "@material-ui/icons/Info";
import Box from "@material-ui/core/Box";
import Drawer from '@material-ui/core/Drawer';

import DetailContents from "./DetailContents";
import DetailMyHistoryContents from "./DetailMyHistoryContents";

function DetailLayout(props) {
    const classes = useStyles();

    const { anchor, open, handleOpen, myHistory } = props;
    const [isOpen, setIsOpen] = React.useState(false);

    // const toggleDrawer = (anchor, open) => event => {
    const toggleDrawer = (event) => {

        if (event.type === 'keydown' && (event.key === 'Esc')) {
            // handleOpen();
            // setIsOpen(false);
        }

        // setState({ ...state, [anchor]: open });
    };

    return (
        <Drawer anchor={"bottom"} open={open} onClose={toggleDrawer}>
            {/*<Box style={{width:'auto'}}>*/}
            <Box className={classes.detailLayout}>
                <button className="layerClose" onClick={handleOpen}><span>닫기</span></button>
                {
                    !myHistory ?
                    <DetailContents />
                    :
                    <DetailMyHistoryContents handleOpen={handleOpen}/>
                }

            </Box>
        </Drawer>
    );
}

export default DetailLayout;