import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'

const HospitalTypeChart = (props) => {
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    type: 'pie'
                },
                title: {
                    text: "병원 타입별"
                },
                tooltip: {
                    pointFormat: '<b>{point.percentage:.1f}%</b>'
                },
                legend: {
                    align: 'right',
                    layout: 'vertical',
                    verticalAlign: 'middle',
                    x: 0,
                    y: 0
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true,
                        animation: {
                            duration: 0
                        }
                    }
                },
                series: [{
                    data: props.data.cl_cd_stat
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal'
                            },
                            yAxis: {
                                labels: {
                                    align: 'left'
                                }
                            },
                            credits: {
                                enabled: false
                            }
                        }
                    }]
                }
            }}
        />
    )
}

export default HospitalTypeChart;