import React from 'react';
import { Link } from 'react-router-dom';

const TitleMenu = ( props ) => (
	<div className="header-field menu_title">
		<h1><Link to="/search">처방정보</Link></h1>
	</div>
);

export default TitleMenu;
