
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';


const drawerWidth = 240;

const useStyles = makeStyles(theme=>
    ({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        bgcolor:{
            backgroundColor: theme.text,
        },
        textColor:{
            grey:{color: theme.gray,},
        },
        space: {
            "1": theme.spacing(1),
            "2": theme.spacing(2),
            "3": theme.spacing(3),
            "4": theme.spacing(4),
            "5": theme.spacing(5),
        },
        fab: {
            position: 'absolute',
            zIndex: 33,
            // top: theme.spacing(2),
            bottom: theme.spacing(4),
            left: theme.spacing(2),
        },
        detailLayout:{
            overflowX:'hidden',
            width: 'auto',
            height: '86vh',
        },
        backdrop: {
            zIndex: theme.zIndex.appBar - 1,
            // zIndex: 1300,
            color: '#fff',
        },
        /* GLOBAL STYLE SHEET */
        '@global':{
            'body':{minHeight:'auto',minWidth:'900px'},
            '.MuiToolbar-gutters':{padding:'0'},
            '.MuiAppBar-positionStatic':{},
            '.loc-cont':{
                position:'absolute',top:'72px',right:'10px',bottom:'0',width:'350px',overflowY:'auto',
                margin:'20px 0 20px 15px',padding:'20px',background:'#fff',boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 5px 7px 3px rgba(0, 0, 0, 0.05)',
                zIndex:'2'},
            '.loc-cont .MuiTypography-h6':{fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',fontSize:'13px',letterSpacing:'-1px',color:'#26c6da'},
            '.loc-cont .MuiDivider-root':{display:'none'},
            '.loc-cont .MuiList-padding':{paddingTop:'0'},
            
            '.leasecont .MuiTypography-h6':{
                fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'18px',letterSpacing:'-1px',fontWeight:'600',color:'#1a1a1a',lineHeight:'1.4',
                cursor:'pointer',padding:'5px 0'},
            '.leasecont .MuiTypography-h6:hover':{color:'#26c6da'},
            '.leasecont .MuiListItem-gutters':{padding:'15px 0 !important',borderBottom:'1px dashed rgba(0,0,0,0.1)'},
            '.leasecont:last-child .MuiListItem-gutters':{borderBottom:'0'},
            '.leasecont .MuiListItemText-root':{margin:'0 !important'},
            '.leasecont .MuiTypography-body2':{lineHeight:'17px'},
            '.leasecont .sort':{display:'inline-block',fontSize:'11px',padding:'0 4px',lineHeight:'17px',color:'#fff',marginRight:'5px',letterSpacing:'-1px'},
            '.leasecont .sort.type1':{background:'#ff8133'}, //임대
            '.leasecont .sort.type2':{background:'#3779ba'}, //양도
            '.leasecont .sort.type3':{background:'#ca191e'}, //매매
            '.leasecont .sort.type4':{background:'#607d8b'}, //양수
            '.leasecont .sort.type5':{background:'#39b54a'}, //상권분석
            '.leasecont .sort.type6':{background:'#7e57c2'}, //분양
            '.leasecont .txtlink':{display:'inline-block',color:'#1a1a1a',letterSpacing:'-1px',lineHeight:'17px'},
            '.leasecont .txtlink:hover':{textDecoration:'underline'},
            '.lease-moreinfo .titxt':{display:'inline-block',fontSize:'12px',color:'#808080',lineHeight:'16px',paddingRight:'6px'},
            '.lease-moreinfo .subtxt':{display:'inline-block',fontSize:'12px',color:'#808080',lineHeight:'16px'},
            '.lease-moreinfo .subtxt.spaceline':{paddingRight:'10px',marginRight:'6px',background:'url("http://image.medigate.net/location/images/linespace_1.png") right center no-repeat'},
            
            '.hsp-cont .MuiTypography-h6':{fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'17px',letterSpacing:'-1px',fontWeight:'600',cursor:'pointer',padding:'5px 0',color:'#1a1a1a'},
            '.hsp-cont .MuiTypography-h6:hover':{color:'#26c6da'},
            '.hsp-cont .MuiListItem-gutters':{padding:'15px 0 !important',borderBottom:'1px dashed rgba(0,0,0,0.1)'},
            '.hsp-cont:last-child .MuiListItem-gutters':{borderBottom:'0'},
            '.hsp-cont .MuiListItemText-root':{margin:'0 !important'},
            '.hsp-cont .MuiTypography-body2':{lineHeight:'15px'},
            '.hsp-moreinfo .titxt':{display:'inline-block',fontSize:'12px',color:'#808080',lineHeight:'16px',paddingRight:'6px'},
            '.hsp-moreinfo .subtxt':{display:'inline-block',fontSize:'12px',color:'#808080',lineHeight:'16px',verticalAlign:'text-bottom'},
            '.hsp-moreinfo .subtxt.addr':{letterSpacing:'-1px'},
            '.hsp-moreinfo .subtxt.homepage':{color:'#00acc1',cursor:'pointer',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',maxWidth:'230px',},
            '.hsp-moreinfo .subtxt.homepage:hover':{textDecoration:'underline'},
            '.hsp-moreinfo .subtxt.spaceline':{paddingRight:'10px',marginRight:'6px',background:'url("http://image.medigate.net/location/images/linespace_1.png") right center no-repeat'},

            '.headercont':{width:'100%',height:'64px',padding:'0 24px',borderBottom:'0',background:'#fff',zIndex:'20'},//borderBottom:'1px solid #f2f2f2',
            '.headercont .MuiAppBar-root':{height:'64px',display:'initial'},
            '.headercont .MuiAppBar-root .MuiIconButton-edgeStart':{marginRight:'35px'},
            '.headercont .MuiAppBar-root .MuiBox-root .MuiTypography-body1':{fontFamily:'Noto Sans KR,Nanum Gothic,나눔고딕,Dotum,돋움,verdana',fontSize:'16px',fontWeight:'400'},
            '.headercont .profile':{background:'rgba(0,0,0,0) !important',height:'40px !important'},
            '.profile .modal-dialog':{top:'10px !important',right:'10px !important'},
            '.viewcont':{position:'absolute',top:'65px',left:'0',right:'0',bottom:'0',zIndex:'0'},
            '.viewcont .sortwrap':{boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 5px 7px 3px rgba(0, 0, 0, 0.05)',zIndex:'2'},
            '.viewcont .mapwrap':{position:'absolute',top:'0',left:'0',right:'0',bottom:'0',width:'100%',height:'100%',zIndex:'0'},
            '.viewcont .MuiBackdrop-root':{top:'65px'},
            '.input_adr':{cursor:'text',padding:'4px 10px',borderRight:'1px solid rgba(0,0,0,0.1)',height:'54px'},
            '.input_adr .inputarea':{paddingLeft:'10px'},
            '.input_adr .inputarea input':{padding:'0',height:'24px',lineHeight:'24px'},

            '.popperAdr':{margin:'15px 0 0 -10px',borderRadius:'0 0 4px 0',borderTop:'1px solid rgba(0,0,0,0.1)',paddingTop:'0',boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 5px 7px 3px rgba(0, 0, 0, 0.05)'},
            '.popperAdr .MuiGrid-container':{width:'100%',margin:'0'},
            '.popperAdr .conttit':{position:'relative',borderBottom:'1px solid rgba(0,0,0,0.05)',width:'100%',margin:'0 0 10px',padding:'0 20px',background:'rgba(0,0,0,0.05)'},
            '.popperAdr .conttit .MuiGrid-grid-xs-10':{padding:'5px 35px 5px 0',flexGrow:'initial',maxWidth:'100%',flexBasis:'auto'},
            '.popperAdr .conttit .MuiTypography-paragraph':{fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',fontSize:'12px',color:'#1a1a1a',margin:'0',lineHeight:'20px'},
            '.popperAdr .conttit .MuiGrid-grid-xs-2':{position:'absolute',top:'2px',right:'20px',flexGrow:'initial',maxWidth:'none',flexBasis:'auto',padding:'0'},
            '.popperAdr .contlst':{position:'relative',borderBottom:'1px dashed rgba(0,0,0,0.1)',width:'100%',margin:'0',padding:'0'},
            '.popperAdr .contlst:first-child':{paddingTop:'10px'},
            '.popperAdr .contlst:last-child':{borderBottom:'0',padding:'0 0 10px'},
            '.popperAdr .contlst .MuiGrid-item':{position:'relative',padding:'0 20px'},
            '.popperAdr .contlst .MuiGrid-item:hover':{background:'#f7f7f7',transition:'all .1s'},
            '.popperAdr .contlst .MuiTypography-body1':{display:'block',margin:'0',letterSpacing:'-1px',lineHeight:'40px',color:'#808080'},
            '.popperAdr .contlst .MuiTypography-body1:hover':{color:'#000',transition:'all .1s'},
            '.popperAdr .contlst .MuiGrid-grid-xs-10':{padding:'0 35px 0 0',flexGrow:'initial',maxWidth:'100%',width:'100%',flexBasis:'auto'},
            '.popperAdr .contlst .MuiGrid-grid-xs-2':{position:'absolute',top:'7px',right:'20px',flexGrow:'initial',maxWidth:'none',flexBasis:'auto',padding:'0'},
            //combobox-option1
            '.MuiBox-root.opt_combo':{padding:'4px 15px',height:'54px',borderRight:'1px solid rgba(0,0,0,0.1)'},
            '.MuiBox-root.opt_combo .MuiPaper-elevation1':{boxShadow:'none',height:'54px',padding:'12px 5px !important'},
            '.MuiBox-root.opt_combo .MuiPaper-elevation1 .MuiTypography-body1':{lineHeight:'26px'},
            '.opt3_combo':{padding:'3px 0 0 8px'},
            '.opt3_combo .MuiBox-root':{padding:'5px 8px',width:'120px',cursor:'pointer'},            
            '.popperwrap':{borderTop:'1px solid rgba(0,0,0,0.1)',borderRadius:'0 0 4px 4px',boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 5px 7px 3px rgba(0, 0, 0, 0.05)',padding:'0 !important'},
            '.popperwrap .conttit':{position:'relative',borderBottom:'1px solid rgba(0,0,0,0.05)',width:'100%',margin:'0',padding:'0 20px',background:'rgba(0,0,0,0.05)'},
            '.popperwrap .conttit .MuiGrid-grid-xs-10':{padding:'5px 35px 5px 0',flexGrow:'initial',maxWidth:'100%',flexBasis:'auto'},
            '.popperwrap .conttit .MuiGrid-grid-xs-11':{padding:'5px 35px 5px 0',flexGrow:'initial',maxWidth:'100%',flexBasis:'auto'},
            '.popperwrap .conttit .MuiTypography-paragraph':{fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',fontSize:'12px',color:'#1a1a1a',margin:'0',lineHeight:'20px'},
            '.popperwrap .conttit .MuiGrid-grid-xs-1':{position:'absolute',top:'2px',right:'10px',flexGrow:'initial',maxWidth:'none',flexBasis:'auto',padding:'0'},
            '.popperwrap .conttit .MuiGrid-grid-xs-2':{position:'absolute',top:'2px',right:'10px',flexGrow:'initial',maxWidth:'none',flexBasis:'auto',padding:'0'},
            '.popperwrap .radiobtn.type1':{position:'relative',borderBottom:'1px solid rgba(0,0,0,0.05)',width:'100%',margin:'0',padding:'10px 20px',},
            '.popperwrap .btn-type2 .MuiButton-root':{width:'100%',borderRadius:'0 0 4px 4px',lineHeight:'30px',height:'30px'},
            '.popperwrap .popper-opt2':{padding:'20px',margin:'0',width:'100%'},
            '.popperwrap .popper-opt2 .MuiChip-root':{
                fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',
                fontSize:'14px',letterSpacing:'-1px',lineHeight:'36px',color:'#fff',
                height:'36px',padding:'0',background:'#26c6da'},
            '.popperwrap .popper-opt2 .MuiChip-outlined':{border:'1px solid rgba(0, 0, 0, 0.1)',background:'transparent',color:'#808080'},
            '.opt2_switch':{height:'54px',padding:'6px 0 6px 18px'},
            '.opt2_switch .MuiToggleButtonGroup-root':{marginTop:'5px'},
            '.opt2_switch .MuiToggleButton-root':{
                fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',
                fontSize:'13px',color:'#808080',lineHeight:'30px',letterSpacing:'-1px',
                minWidth:'60px',height:'30px',padding:'0 7px 0 8px'},
            '.opt2_switch .MuiToggleButton-root.MuiToggleButton-root.Mui-selected':{background:'#26c6da',color:'#fff'},
            '.opt2_switch .MuiFormControlLabel-root':{margin:'-3px 0 0 0'},
            '.opt2_switch .multimap':{marginLeft:'10px'},
            '.opt2_switch .multimap .MuiCheckbox-root':{padding:'5px'},
            '.opt2_switch .btnreset':{background:'rgba(0,0,0,0.8)',borderRadius:'5px',height:'28px',padding:'0 8px',marginLeft:'15px'},
            '.opt2_switch .btnreset:hover':{background:'rgba(0,0,0,1)'},
            '.opt2_switch .MuiToggleButton-root.MuiToggleButton-root.Mui-selected:hover':{background:'#16acbf'},
            '.opt2_switch .btnreset .MuiButton-label':{fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',fontSize:'12px',letterSpacing:'-1px',color:'#fff',
                lineHeight:'28px',height:'28px',padding:'0 5px'},
            '.MuiToggleButton-sizeSmall':{padding:'0px 7px 0px 8px'},
            '.txtnotice':{background:'rgba(55, 71, 79, 0.9)',height:'26px'},
            '.txtnotice .MuiTypography-body1':{textAlign:'center',color:'#ffeb3b',lineHeight:'26px',fontSize:'12px'},
            
            //통계결과보기
            'button.btn_stats':{
                position:'absolute',top:'92px',left:'38%',zIndex:'2',padding:'0 20px !important',width:'400px !important',height:'46px !important',marginLeft:'-200px',
                background:'rgba(0, 188, 212, 0.95)',borderRadius:'4px !important',boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.3), 0px 5px 7px 3px rgba(0, 0, 0, 0.2)',border:'1px solid #fff',
                fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'15px',color:'#fff',fontWeight:'600',lineHeight:'46px !important',letterSpacing:'-1px'},
            'button.btn_stats:hover':{background:'rgba(2, 159, 179, 1)'},
            'button.btn_stats .MuiFab-label':{fontSize:'18px',lineHeight:'46px'},
            //통계보기탭
            '.layerClose':{position:'absolute',top:'0',right:'0',background:'rgba(0,0,0,0.7)',cursor:'pointer'},
            '.layerClose:hover':{background:'rgba(0,0,0,0.9)',transition:'all .1s'},
            '.layerClose span':{display:'block',width:'60px',height:'60px',color:'rgba(0,0,0,0)',background:'url("http://image.medigate.net/drgate/images/location/img_close.png") center center / 40px no-repeat'},
            '.tabinfowrap':{width:'85%',display:'flex',justifyContent:'center',paddingBottom:'40px',margin:'0 auto'},
            '.tabinfowrap .tabcont':{flex:'1',padding:'0',borderRight:'1px solid #f4f5f9'},
            '.tabinfowrap .tabcont:last-child':{borderRight:'0'},
            '.tabinfowrap .tabcont button':{
                padding:'0 20px',height:'60px',width:'100%',cursor:'pointer',background:'rgba(0,0,0,0.1)',
                fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'18px',color:'#1a1a1a',letterSpacing:'-1px',lineHeight:'60px'},
            '.tabinfowrap .tabcont button:hover':{background:'rgba(0,0,0,0.17)',transition:'all .1s'},
            '.tabinfowrap .tabcont button.on':{background:'#26c6da',color:'#fff'},
            '.tabinfowrap .tabcont button.on:hover':{background:'#16acbf',color:'#fff'},
            '.datawrap.MuiGrid-grid-xs-9':{width:'85%',maxWidth:'85%',flexBasis:'initial',padding:'0',margin:'0 auto'},
            '.datawrap.MuiGrid-grid-xs-6':{width:'50%',maxWidth:'50%',padding:'0 10px 0 40px'},
            '.datawrap.MuiGrid-grid-xs-6:last-child':{padding:'0 40px 0 10px'},
            '.datawrap .MuiPaper-elevation1':{boxShadow:'none'},
            '.datawrap .MuiPaper-elevation1 .MuiTypography-body1':{fontSize:'18px',fontFamily:'Noto Sans KR,Nanum Square,나눔스퀘어,Dotum,돋움,verdana',marginTop:'10px',textAlign:'center'},
            '.datawrap':{width:'85%',maxWidth:'85%',flexBasis:'initial',padding:'0',margin:'0 auto'},
            '.datawrap .notice.specialty':{display:'block',padding:'20px 0',background:'#fff',margin:'20px 0',minHeight:'calc(100% - 20px)'},
            '.datawrap .notice.specialty .txtinfo':{overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',textAlign:'center'},
            '.datawrap .notice.specialty .txtinfo span':{
                fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',
                fontSize:'18px',letterSpacing:'-1px',color:'#26c6da',paddingRight:'10px'},
            '.datawrap .notice.specialty .txtinfo span:last-child':{paddingRight:'0'},
            '.mdc-dialog__actions .mdc-button.mdc-dialog__button':{backgroundColor:'#26c6da !important',padding:'0 !important',boxShadow:'none !important'},
            '.mdc-dialog__actions .mdc-button.mdc-dialog__button.cancel':{backgroundColor:'#fff !important'},
            //sgisInfo
            '.sgisInfo .sort-tab':{position:'fixed',width:'85%',padding:'20px 0',textAlign:'center',background:'#f4f5f9',zIndex:'10'},
            '.datawrap.MuiGrid-grid-xs-9 .sgisInfo .sort-tab':{width:'87%'},
            '.datawrap.MuiGrid-grid-xs-6 .sgisInfo .sort-tab':{width:'50%'},
            '.sgisInfo .sort-tab .MuiPaper-root':{background:'none'},
            '.sgisInfo .sort-tab .MuiButton-root':{
                fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',
                fontSize:'13px',color:'rgba(0,0,0,0.5)',textAlign:'center',lineHeight:'30px',letterSpacing:'-1px',
                padding:'0 15px',boxShadow:'none',border:'1px solid rgba(0,0,0,0.1)',background:'none',marginRight:'5px'},
            '.sgisInfo .info-total':{border:'5px solid rgba(0,0,0,0.9)',display:'flex',borderRadius:'4px',margin:'72px 0 40px'},
            '.sgisInfo .info-total .MuiGrid-item':{flex:'1',maxWidth:'none',flexBasis:'auto',padding:'0',borderRight:'1px solid rgba(0,0,0,0.1)'},
            '.sgisInfo .info-total .MuiGrid-item:last-child':{borderRight:'0'},
            '.sgisInfo .info-total .MuiPaper-root':{
                fontFamily:'Ubuntu,sans-serif',
                fontSize:'3rem',color:'#1a1a1a',textAlign:'center',lineHeight:'1.4',letterSpacing:'-1px',fontWeight:'700',padding:'40px 0'},
            '.sgisInfo .info-total .MuiPaper-root .MuiTypography-body1':{
                fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',
                fontSize:'12px',lineHeight:'24px',letterSpacing:'-1px'},
            '.hiraInfo':{background:'#fff',margin:'0 0 60px',
                fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',letterSpacing:'-1px'},
            '.hiraInfo .caution':{padding:'20px 0',background:'#f4f5f9',display:'block'},
            '.hiraInfo .caution .txtinfo1':{fontSize:'18px',fontWeight:'bold'},
            '.hiraInfo .caution .txtinfo2':{fontSize:'13px'},
            '.hiraInfo .infowrap':{padding:'40px',justifyContent:'center'},
            '.hiraInfo .infowrap.type2':{margin:'0 40px 40px',padding:'20px',border:'1px solid rgba(0,0,0,0.1)',borderRadius:'4px'},
            '.hiraInfo .infowrap.type2:first-child':{margin:'40px'},
            '.hiraInfo .infowrap .combobox .MuiFormControl-root':{width:'95%'},
            '.hiraInfo .infowrap.title .combobox .MuiFormControl-root':{width:'25%',minWidth:'200px'},
            '.hiraInfo .infowrap.title .combobox .MuiInputBase-input':{textAlign:'center'},
            '.hiraInfo .infowrap.title':{padding:'20px 40px'},
            '.hiraInfo .infowrap.title .txtinfo':{display:'block',
                fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',
                fontSize:'18px',letterSpacing:'-1px',textAlign:'center'},
            '.hiraInfo .infowrap.title .txtinfo.sub':{fontSize:'13px',fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',color:'#808080'},
            '.hiraInfo .LineChart':{border:'1px solid rgba(0,0,0,0.1)',borderRadius:'4px',marginRight:'10px',padding:'20px'},
            '.hiraInfo .LineChart:last-child':{marginRight:'0'},
            '.hiraInfo .LineChart .titxt':{textAlign:'center'},
            '.hiraInfo .LineChart .titxt .title':{display:'block'},
            '.hiraInfo .LineChart .titxt .data':{display:'block',fontSize:'30px',fontFamily:'Ubuntu,sans-serif',fontWeight:'700',lineHeight:'34px'},
            '.infowrap.table':{padding:'0 40px 40px'},
            '.infowrap.table button':{border:'0',borderRadius:'0',background:'rgba(0,0,0,0.7) !important',color:'#fff'},
            '.infowrap.table button:hover':{border:'0',background:'rgba(0,0,0,0.9) !important'},
            '.infowrap.table .title-tr .on':{background:'#00838f',borderBottom:'1px solid rgba(0,0,0,0.1)'},
            '.infowrap.table .title-tr .MuiTableCell-head:hover':{background:'#006064',transition:'all .1s'},
            '.infowrap.table .title-tr .MuiTableCell-head':{cursor:'pointer'},
            '.infowrap.table .title-tr .MuiTableCell-head:first-child:hover':{background:'#000'},
            '.infowrap.table .title-tr .MuiTableCell-head:first-child':{cursor:'auto'},
            '.infowrap.table .list-tr .on':{background:'#b2ebf2',borderBottom:'1px solid rgba(0,0,0,0.1)'},
            '.infowrap.table .list-tr:nth-of-type(odd) .on':{background:'#a1d7de',borderBottom:'1px solid rgba(0,0,0,0.15)'},
            '.infowrap.table .total-th':{fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',fontSize:'15px',fontWeight:'bold',padding:'10px 24px 10px 16px'},
            '.infowrap.table .total-td':{fontSize:'15px',fontWeight:'bold',padding:'10px 24px 10px 16px'},
            '.hiraInfo .infowrap .combobox.type2 .MuiInputBase-root':{border:'1px solid rgba(0,0,0,0.9)'},
            '.hiraInfo .infowrap .combobox.type2 .MuiInputBase-root .MuiInputBase-input':{textAlign:'center'},
            '.hiraInfo .infowrap .combobox .MuiFormControl-root .MuiFormLabel-root':{width:'100px',textAlign:'center',left:'50%',marginLeft:'-40px'},
            

            '.infowrap.table.type2 .MuiTableCell-root':{padding:'6px 16px'},
            '.infowrap.table.type2 .MuiPaper-rounded':{borderRadius:'4px 4px 0 0',boxShadow:'none'},
            '.infowrap.table.type2 .MuiTableCell-root span.paddingR':{padding:'0 14px 0 0'},
            '.infowrap.table.type2 .title':{paddingBottom:'20px'},
            '.infowrap.table.type2 .title .MuiTypography-body1':{
                display:'block',width:'100%',
                fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',
                fontSize:'18px',letterSpacing:'-1px',textAlign:'center'},
            '.infowrap.table.type2 .title .MuiTypography-body1 span':{
                display:'block',width:'100%',
                fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',
                fontSize:'13px',color:'#808080'},

            //popperSettings
            '.popperSettings':{boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 5px 7px 3px rgba(0, 0, 0, 0.05)'},
            '.popperSettings .MuiGrid-spacing-xs-2':{width:'100%',margin:'0',padding:'0'},
            '.popperSettings .titxt':{borderBottom:'1px solid rgba(0,0,0,0.05)',width:'100%',padding:'0',background:'rgba(0,0,0,0.05)'},
            '.popperSettings .titxt .MuiTypography-body1':{paddingLeft:'20px',fontSize:'12px',color:'#808080',lineHeight:'30px',marginBottom:'0'},
            '.popperSettings .radiobtn.type1':{borderBottom:'1px solid rgba(0,0,0,0.05)',width:'100%',margin:'0',padding:'10px 20px'},
            '.popperSettings .radiobtn.type1 .MuiFormGroup-root':{display:'block'},
            '.popperSettings .titxt button.MuiIconButton-root':{float:'right',marginRight:'5px'},
            '.popperSettings .MuiFormControl-root':{display:'block'},
            '.popperSettings .setArea':{display:'block',padding:'20px',width:'100%'},
            '.popperSettings .setArea .MuiTypography-body1':{fontSize:'15px',wordBreak:'keep-all',marginBottom:'10px'},
            '.popperSettings .setArea-btn':{display:'block',padding:'20px 0',width:'100%',borderTop:'1px solid rgba(0,0,0,0.05)'},
            '.popperSettings .setArea-btn button':{fontSize:'13px',background:'#26c6da',color:'#fff',padding:'0 20px',marginLeft:'20px',lineHeight:'30px'},
            '.popperSettings .codeArea':{display:'block',padding:'20px 20px 10px'},
            '.popperSettings .codeArea select':{height:'30px',lineHeight:'30px',border:'1px solid rgba(0,0,0,0.1)',marginRight:'5px',fontSize:'13px'},
            '.popperPopular':{marginTop:'6px',boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 5px 7px 3px rgba(0, 0, 0, 0.05)',width:'355px',borderRadius:'0 0 4px 4px',borderTop:'1px solid rgba(0,0,0,0.05)'},
            '.popperPopular .MuiGrid-container':{padding:'40px 0 20px'},
            '.popperPopular .sidosort':{padding:'10px 20px 0'},
            '.popperPopular .sidosort .combobox.type2 .MuiInputBase-root':{border:'1px solid rgba(0,0,0,0.9)'},
            '.popperPopular .sidolst':{borderTop:'1px solid rgba(0,0,0,0.1)',padding:'20px 0 0',marginTop:'10px'},
            '.popperPopular .sidolst .MuiGrid-container':{padding:'8px 20px',cursor:'pointer',margin:'0',borderBottom:'1px dashed rgba(0,0,0,0.1)'},
            '.popperPopular .sidolst .MuiGrid-container:hover':{color:'#26c6da'},
            '.popperPopular .sidolst .MuiGrid-container .MuiTypography-body1':{fontSize:'15px',letterSpacing:'-1px'},

            //이용가이드
            '.btnguide':{border:'1px solid #26c6da',background:'#fff',borderRadius:'15px',padding:'0 15px',marginRight:'20px'},
            '.btnguide .MuiButton-label':{lineHeight:'26px',fontSize:'13px',color:'#1ba9bb',letterSpacing:'-1px'},
            '.btnguide:hover':{border:'1px solid #26c6da',background:'#26c6da'},
            '.btnguide:hover .MuiButton-label':{color:'#064c54'},
            '.appguide-wrap':{top:'65px !important'},
            '.appguide-wrap .MuiDialog-paperFullScreen':{background:'#f4f5f9'},
            '.appguide-wrap .MuiAppBar-colorPrimary':{boxShadow:'none',background:'none'},
            '.appguide-wrap .MuiAppBar-colorPrimary .MuiTypography-h6':{
                height:'60px',width:'85%',textAlign:'center',background:'none',margin:'0 auto',flex:'none',
                fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'18px',color:'#1a1a1a',letterSpacing:'-1px',lineHeight:'60px'},
            '.appguide-wrap .guidelayerClose':{position:'fixed',top:'65px',right:'0',background:'rgba(0,0,0,0.7)',cursor:'pointer',
                padding:'0',borderRadius:'0'},
            '.appguide-wrap .guidelayerClose:hover':{background:'rgba(0,0,0,0.9)',transition:'all .1s'},
            '.appguide-wrap .guidelayerClose span.MuiIconButton-label':{display:'block',width:'60px',height:'60px',color:'rgba(0,0,0,0)',background:'url("http://image.medigate.net/drgate/images/location/img_close.png") center center / 40px no-repeat'},
            '.guidecont-tab':{position:'relative'},
            '.guidecont-tab .tabcont':{width:'85%',display:'flex',justifyContent:'center',paddingBottom:'0',margin:'0 auto'},
            '.guidecont-tab .tabcont .tabs':{flex:'1',padding:'0',borderRight:'1px solid #f4f5f9'},
            '.guidecont-tab .tabcont .tabs.MuiTab-root':{maxWidth:'inherit',background:'rgba(0,0,0,0.1)'},
            '.guidecont-tab .tabcont .tabs.MuiTab-root.Mui-selected':{background:'#e6a33f'},
            '.guidecont-tab .tabcont .tabs.MuiTab-root.Mui-selected .MuiTab-wrapper':{color:'#fff'},
            '.guidecont-tab .tabcont .tabs.MuiTab-root .MuiTab-wrapper':{height:'60px',lineHeight:'60px',
                fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'18px',color:'#1a1a1a',letterSpacing:'-1px'},
            '.guidecont-tab .tabcont .MuiTabs-indicator':{display:'none'},
            '.guidecont-tab .tabcont .tabs:last-child':{borderRight:'0'},
            '.tabinfowrap .tabcont button':{
                padding:'0 20px',height:'60px',width:'100%',cursor:'pointer',background:'rgba(0,0,0,0.1)',
                fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'18px',color:'#1a1a1a',letterSpacing:'-1px',lineHeight:'60px'},
            '.tabinfowrap .tabcont button.on':{background:'#26c6da',color:'#fff'},
            '.guidecont-info':{position:'relative',width:'85%',margin:'20px auto 0',padding:'0 40px',background:'#fff'},
            '.guidecont-info .MuiBox-root':{padding:'40px 15px',},
            '.guidecont-info.type2':{background:'#fff'},
            '.guidecont-info.type2 .MuiBox-root':{background:'none'},
            '.guidecont-info .title':{fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',fontSize:'24px',color:'#000',letterSpacing:'-1px',margin:'0 0 40px 20px'},
            '.guidecont-info h6.title':{fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',
                fontSize:'18px',fontWeight:'500',color:'#1a1a1a',textAlign:'center',margin:'0 0 0.35em 0',lineHeight:'1.75'},
            '.guidecont-info .infoarea':{display:'flex'},
            '.guidecont-info .infoarea .subtxt.type2':{flex:'1',margin:'0 10px',border:'0',background:'#fff',borderRadius:'5px',padding:'20px 60px 20px 20px',wordBreak:'keep-all',color:'#1a1a1a'},
            '.guidecont-info .infoarea .subtxt.type2 span':{display:'block',fontSize:'18px',color:'#1a1a1a',margin:'0 0 10px',paddingTop:'70px'},
            '.guidecont-info .subtxt':{fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',
                fontSize:'18px',color:'#1a1a1a',letterSpacing:'-1px',textAlign:'left'},
            '.guidecont-info .subtxt.type2':{fontSize:'14px',color:'#808080'},
            '.guidecont-info .subtxt.type1':{fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana'},

            '.guidecont-info .infoarea .subtxt.type2 span.info01':{background:'url("http://image.medigate.net/drgate/images/location/info01.png") left top / 60px no-repeat'},
            '.guidecont-info .infoarea .subtxt.type2 span.info02':{background:'url("http://image.medigate.net/drgate/images/location/info02.png") left top / 60px no-repeat'},
            '.guidecont-info .infoarea .subtxt.type2 span.info03':{background:'url("http://image.medigate.net/drgate/images/location/info03.png") left top / 60px no-repeat'},
            '.guidecont-info .infoarea .subtxt.type2 span.info04':{background:'url("http://image.medigate.net/drgate/images/location/info04.png") left top / 60px no-repeat'},
            '.guidecont-info .infoarea .subtxt.type2 span.info05':{background:'url("http://image.medigate.net/drgate/images/location/info05.png") left top / 60px no-repeat'},
            '.guidecont-info .infoarea .subtxt.type2 span.info06':{background:'url("http://image.medigate.net/drgate/images/location/info06.png") left top / 60px no-repeat'},

            '.guidecont-info .infoarea .subtxt.type3':{flex:'1',margin:'0 10px',border:'0',background:'#fff',borderRadius:'5px',padding:'20px',wordBreak:'keep-all',color:'#1a1a1a',fontSize:'14px',color:'#1a1a1a'},
            '.guidecont-info .infoarea .subtxt.type3 span':{display:'block',margin:'0 0 10px'},
            '.guidecont-info .infoarea .subtxt.type3 span.tab':{display:'block',width:'100%',height:'200px',marginBottom:'10px',padding:'0',border:'1px solid rgba(0,0,0,0.1)'},
            '.guidecont-info .infoarea .subtxt.type3 span.title':{fontSize:'24px',margin:'10px 0 5px'},
            '.guidecont-info .infoarea .subtxt.type3 span.subtxt':{fontSize:'13px',color:'#808080',margin:'0 0 5px',paddingTop:'0'},
            '.guidecont-info .infoarea .subtxt.type3 span.tab.bgimg21':{background:'url("http://image.medigate.net/drgate/images/location/guide-img21.jpg") left top / cover no-repeat'},
            '.guidecont-info .infoarea .subtxt.type3 span.tab.bgimg22':{background:'url("http://image.medigate.net/drgate/images/location/guide-img22.jpg") left top / cover no-repeat'},
            '.guidecont-info .infoarea .subtxt.type3 span.tab.bgimg23':{background:'url("http://image.medigate.net/drgate/images/location/guide-img23.jpg") left top / cover no-repeat'},
            '.guidecont-info .infoarea .subtxt.type3 span.tab.bgimg24':{background:'url("http://image.medigate.net/drgate/images/location/guide-img24.jpg") center center / cover no-repeat'},
            '.guidecont-info .infoarea .subtxt.type3 span.tab.bgimg25':{background:'url("http://image.medigate.net/drgate/images/location/guide-img25.jpg") center top / cover no-repeat'},
            '.guidecont-info .infoarea .subtxt.type3 span.tab.bgimg26':{background:'url("http://image.medigate.net/drgate/images/location/guide-img26.jpg") right top / cover no-repeat'},
            '.guidecont-info .infoarea .subtxt.type3 span.tab.bgimg27':{background:'url("http://image.medigate.net/drgate/images/location/guide-img27.jpg") left top / 1160px no-repeat'},
            '.guidecont-info .infoarea .subtxt.type3 span.tab.bgimg28':{background:'url("http://image.medigate.net/drgate/images/location/guide-img28.jpg") left top / cover no-repeat'},
            '.guidecont-info .infoarea .subtxt.type3 span.tab.bgimg29':{background:'url("http://image.medigate.net/drgate/images/location/guide-img29.jpg") right top / cover no-repeat'},
            '.guidecont-info .infoarea .subtxt.type3 span.tab.bgimg30':{background:'url("http://image.medigate.net/drgate/images/location/guide-img30.jpg") center center / 1160px no-repeat'},
            '.guidecont-info .infoarea .subtxt.type3 span.tab.bgimg31':{background:'url("http://image.medigate.net/drgate/images/location/guide-img31.jpg") right center / cover no-repeat'},

            '.guidecont-info.type3':{background:'#fff'},
            '.guidecont-info.type3 .infoarea':{display:'block'},
            '.guidecont-info.type3 .MuiBox-root':{background:'none'},
            '.guidecont-info .infoarea .subtxt.type4':{position:'relative',margin:'20px 20px 60px',border:'0',background:'#fff',borderRadius:'5px'},
            '.guidecont-info .infoarea .subtxt.type4 .txtarea':{margin:'0 0 0 230px',minHeight:'260px',wordBreak:'keep-all',color:'#1a1a1a',fontSize:'16px',color:'#1a1a1a',lineHeight:'1.4'},
            '.guidecont-info .infoarea .subtxt.type4 .txtarea span':{display:'block',margin:'0 0 10px'},
            '.guidecont-info .infoarea .subtxt.type4 .txtarea span.title':{fontSize:'24px',margin:'0',lineHeight:'1.0'},
            '.guidecont-info .infoarea .subtxt.type4 .txtarea span.subtxt':{fontSize:'13px',color:'#808080',margin:'0 0 3px 10px',paddingTop:'0'},
            '.guidecont-info .infoarea .subtxt.type4 .txtarea span.subtxt2':{fontSize:'13px',color:'#808080',margin:'0 0 15px 10px',paddingTop:'0'},
            '.guidecont-info .infoarea .subtxt.type4 span.tab':{position:'absolute',top:'0',left:'0',display:'block',width:'200px',height:'240px',marginBottom:'10px',padding:'0',border:'1px solid rgba(0,0,0,0.1)'},
            '.guidecont-info .infoarea .subtxt.type4 span.tab.bgimg41':{background:'url("http://image.medigate.net/drgate/images/location/guide-img41.jpg") left top / 200px no-repeat'},
            '.guidecont-info .infoarea .subtxt.type4 span.tab.bgimg42':{height:'320px',background:'url("http://image.medigate.net/drgate/images/location/guide-img42.jpg") left top / 200px no-repeat'},
            '.guidecont-info .infoarea .subtxt.type4 span.tab.bgimg43':{height:'360px',background:'url("http://image.medigate.net/drgate/images/location/guide-img43.jpg") left top / 200px no-repeat'},

            //common
            '.MuiTypography-body1':{fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',fontSize:'13px'},
            '.MuiRadio-colorSecondary.Mui-checked':{color:'#26c6da'},
            '.MuiIconButton-colorSecondary':{color:'#26c6da !important'},
            '.MuiButton-containedSecondary':{background:'#26c6da !important',color:'#fff !important'},
            '.MuiButton-outlinedSecondary':{background:'#26c6da !important',color:'#fff !important',border:'0'},
            '.MuiFormLabel-root.Mui-focused':{color:'#26c6da !important'},
            '.btn-type2 .MuiButton-root':{
                fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',
                fontSize:'12px',color:'#fff',textAlign:'center',lineHeight:'24px',letterSpacing:'0',
                background:'rgba(0,0,0,0.7)',height:'24px',borderRadius:'3px',padding:'0'},
            '.btn-type3':{
                fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',
                fontSize:'12px',color:'#fff',textAlign:'center',lineHeight:'28px',letterSpacing:'0',
                background:'rgba(0,0,0,0.7)',height:'30px',borderRadius:'3px',padding:'0 10px',boxShadow:'none'},
            '.btn-type3:hover':{background:'rgba(0,0,0,0.9)'},
            '.btn-type2:hover .MuiButton-root':{background:'rgba(0,0,0,0.9)',transition:'all .1s'},
            '.MuiCheckbox-colorPrimary.Mui-checked':{color:'#26c6da'},
            '.MuiDrawer-paperAnchorBottom':{top:'65px',background:'#f4f5f9'},
            '.MuiDrawer-paperAnchorBottom .MuiBox-root':{height:'calc(100% - 20px)',overflowY:'hidden'},
            '.datainfowrap':{position:'absolute',top:'80px',left:'0',right:'0',bottom:'20px',overflowY:'auto',overflowX:'hidden'},
            '.datainfowrap .MuiGrid-spacing-xs-2':{margin:'0'},
            '.paper-chart':{padding:'40px 0 20px',background:'#fff',margin:'0 0 20px',minHeight:'calc(100% - 20px)'},
            '.paper-chart .titxt .MuiTypography-body1':{
                fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',
                fontSize:'18px',letterSpacing:'-1px',textAlign:'center'},
            '.paper-chart .MuiPaper-elevation1 .notice':{textAlign:'center',paddingTop:'100px',color:'#999',fontSize:"18px"},
            '.combobox.type1':{display:'block',textAlign:'center',marginTop:'10px'},
            '.combobox.type1 .MuiInputBase-root':{
                fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',
                fontSize:'13px',letterSpacing:'-1px',
                border:'1px solid #e6e6e6',borderRadius:'4px',padding:'5px 10px',minWidth:'120px'},
            '.combobox.type1 .MuiInput-underline:before':{borderBottom:'0'},
            '.combobox.type1 .MuiInputBase-input':{textAlign:'left'},
            '.combobox.type2':{display:'block',width:'100%'},
            '.combobox.type2 .MuiInputBase-root':{
                fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',
                fontSize:'13px',letterSpacing:'-1px',
                border:'1px solid #e6e6e6',borderRadius:'4px',padding:'5px 10px',margin:'0 0 10px',display:'block'},
            '.combobox.type2 .MuiInputLabel-formControl':{top:'-10px',left:'10px'},
            '.combobox.type2 .MuiInput-underline:before':{borderBottom:'0'},
            '.combobox.type2 .MuiInputBase-input':{padding:'6px 0',fontSize:'15px'},
            '.combobox.type2 .MuiInputLabel-shrink':{transform:'translate(0, -2px) scale(0.75)'},
            '.MuiInput-underline:after':{display:'none',borderBottom:'2px solid #26c6da'},            
            '.tabcont-2depth .MuiGrid-item':{borderBottom:'1px solid rgba(0,0,0,0.05)'},
            '.tabcont-2depth .MuiPaper-elevation1':{
                fontFamily:'Nanum Square,나눔스퀘어,Noto Sans KR,Dotum,돋움,verdana',
                fontSize:'18px',letterSpacing:'-1px',textAlign:'center',lineHeight:'60px',
                borderRadius:'0',cursor:'pointer'},
            '.tabcont-2depth .MuiPaper-elevation1:hover':{backgroundColor:'rgba(0,0,0,0.05)',transition:'all .1s'},
            '.tabcont-3depth':{textAlign:'center'},
            '.tabcont-3depth .MuiGrid-root':{display:'inline-block',textAlign:'center',flexGrow:'initial',maxWidth:'none',flexBasis:'auto',margin:'20px 2px'},
            '.tabcont-3depth .MuiGrid-root .MuiPaper-elevation1':{
                fontFamily:'Nanum Gothic,나눔고딕,Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',
                fontSize:'13px',color:'rgba(0,0,0,0.5)',textAlign:'center',lineHeight:'30px',letterSpacing:'-1px',
                padding:'0 15px',boxShadow:'none',border:'1px solid rgba(0,0,0,0.1)',background:'none',marginRight:'5px',cursor:'pointer'},
            '.radiobtn .MuiIconButton-root':{padding:'9px 3px 9px 0'},
            '.radiobtn .MuiFormControlLabel-root':{marginLeft:'0'},
            '.radiobtn .MuiTypography-body1':{fontSize:'15px',letterSpacing:'-1px'},
            '.f-reset':{flexGrow:'initial',maxWidth:'none',flexBasis:'auto'},


            '@media (min-width:600px)':{
                '.MuiToolbar-regular':{height:'64px'},
                '.viewcont .sortwrap':{height:'80px'},
                '.appguide-wrap .MuiToolbar-regular':{height:'60px',minHeight:'60px'},
            },
        }
        /* //GLOBAL STYLE SHEET */
    })
);















// export default withStyles(styles)();
// export default withStyles(styles);
// export default withStyles(useStyles)();
export default useStyles;
// export useStyles;