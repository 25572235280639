import React, {useState, useEffect, Fragment} from 'react';

import Fab from '@material-ui/core/Fab';
import useStyles from "../style/common";
import Draggable from "react-draggable";
import DraggableCore from "react-draggable";
import InfoIcon from "@material-ui/icons/Info";
import Box from "@material-ui/core/Box";
import {observer} from "mobx-react";
import {useStore} from "../../stores/StoreProvider";

// function DetailButton(props) {
const DetailButton = observer((props) => {
    const store = useStore().LocationStore;
    const classes = useStyles();
    const { bgColor="#cbcbcb", fontColor="#000" } = props;
    const { parent } = props;
    const { handleAction } = props;


    const fabStyle = () => {{
    }};

    const handleDragStart = () => {
        // //console.log("#######handledragstart", parent.current.offsetTop, parent.current.offsetLeft, parent.current.offsetWidth, parent.current.offsetHeight);
    }

    const handleDrag = () => {

    }

    const handleDragStop = () => {
        // //console.log("@@@@@@handledragstop", parent.current.offsetTop, parent.current.offsetLeft, parent.current.offsetWidth, parent.current.offsetHeight);

    }

    const btnText = () => {
        let btnTxt = "이 지역 통계보기";
        if(store.actionStatus == 1 || store.actionStatus == 3){
            btnTxt= "선택 지역 통계보기";
        } else if(store.actionStatus == 2){
            btnTxt= "2번째 영역을 그리셔야 통계비교가 가능합니다";
        } else if(store.actionStatus == 4){
            btnTxt= "통계를 확인하려면 지도를 좀 더 확대해 주세요";
        } else {

        }
        return btnTxt;
    }

    const btnClass = () => {
        let classStr = "btn_stats";
        if(store.actionStatus == 2 || store.actionStatus == 4){
            classStr+= " disabled";
        }
        return classStr;
    }



    return (
        <Draggable
            allowAnyClick={true}
            // offsetParent = {parent}
            // bounds={boundsTemp}
            // bounds="#mapParent"
            // bounds="#map"
            bounds="parent"
            onStart={handleDragStart}
            onDrag={handleDrag}
            onStop={handleDragStop}
        >
            <Fab
                variant="extended"
                size={"small"}
                className={btnClass()}
                onClick={handleAction}>
                {btnText()}
            </Fab>
        </Draggable>
    );
})

export default DetailButton;