import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { isMobileOnly } from 'react-device-detect';

const Toastr = ({message, p_vertical=isMobileOnly?'center':'center', p_horizontal='center', callback}) => {
    const [state, setState] = React.useState({
        open: true,
        vertical: p_vertical,
        horizontal: p_horizontal,
    });
    
    const { vertical, horizontal, open } = state;
    
    const handleClick = newState => () => {
        setState({ open: true, ...newState });
    };
    
    function handleClose() {
        setState({ ...state, open: false });
        if(callback){
            callback();
        }
    }
    
    return (
        <div>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            key={`${vertical},${horizontal}`}
            open={open}
            onClose={handleClose}
            autoHideDuration={1500}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{message}</span>}
          />
        </div>
    );
}

export default Toastr;
