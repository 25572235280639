import React, { Fragment } from 'react';
import Moment from 'react-moment';
import _ from "lodash";
import CommonFunc from '../../../common/CommonFunction';

function moveWikiPage(type, anchor, keyword, wikiTitle) {
    let wikiUrl = "";
    if(type === "toc"){
        wikiUrl = `https://en.wikipedia.org/wiki/${keyword}#${anchor}`;
        window.open(wikiUrl, '_blank');
    }else if(type === "wiki"){
        wikiUrl = `https://en.wikipedia.org/wiki/${keyword}`;
        window.open(wikiUrl, '_blank');
    }

    CommonFunc.pageview('처방정보', 'WIKI', wikiTitle); //Google Analytics
}

const KCDWikipedia = (props) => {
    //const arrayToc = props.wikiData.toc.filter(item => item.level === 1);
    const wikiTitle = props.wikiData.displaytitle;
    return (
        <div className="cont wikipedia">
            <div className="chaptit">
                <p className="tittxt">
                    <span className="disease" dangerouslySetInnerHTML={{__html: wikiTitle}}></span>
                    <span className="wiki">FROM WIKIPEDIA</span>
                </p>
            </div>
            <div className="post">
                {/*
                    props.wikiData.toc.length > 0 &&
                    <div className="pos_header">
                        <span className="tit_sub">
                            {
                                arrayToc.map((item, index) => {
                                    if(index < 7){
                                        return (
                                            <Fragment>
                                                <span onClick={() => moveWikiPage("toc", item.anchor, props.wikiKeyword, wikiTitle)} dangerouslySetInnerHTML={{__html: item.html}}></span>
                                                <span className="bar"></span>
                                            </Fragment>
                                        )
                                    }
                                })
                            }
                        </span>
                    </div>
                */}
                <div className="pos_cont">
                    {
                        !_.isEmpty(props.wikiData.thumbnail) &&
                        <div className="thumb">
                            <span onClick={() => moveWikiPage("wiki", "", props.wikiKeyword, wikiTitle)}>
                                <div className="thumb_img" style={{background: `url(${props.wikiData.thumbnail}) center / cover no-repeat`}}></div>
                            </span>
                        </div>
                    }
                    <div className="txt">
                        <div className="txt_wrap">
                            <p className="txt_cont" dangerouslySetInnerHTML={{__html: props.wikiData.extract_html}}></p>
                            <div className="txt_etc">
                                {
                                    !_.isEmpty(props.wikiData.timestamp) &&
                                    <div className="date">
                                        최종수정 :&nbsp;
                                        <span>
                                            <Moment locale="ko" format="YYYY. MM. DD">{props.wikiData.timestamp}</Moment>
                                        </span>
                                    </div>
                                }
                                <span className="link" onClick={() => moveWikiPage("wiki", "", props.wikiKeyword, wikiTitle)}>
                                    <div className="linktxt">전체보기</div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KCDWikipedia;