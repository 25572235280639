import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import SubCapacityDayChart from './SubCapacityDayChart';
import SubCapacityDrugCntChart from './SubCapacityDrugCntChart';
import SubCapacityTimeChart from './SubCapacityTimeChart';

import PlaceHolder from '../loading/PlaceHolder';

import { SUB_CAPACITY_STAT_LIST } from '../../../queries/prescript/stat';


class CapacitySubChartFragment extends React.PureComponent {

    render() {
        //console.log("CapacitySubChartFragment props", this.props)
        return (
            <Fragment>
                {
                    <Query query={SUB_CAPACITY_STAT_LIST} variables={{
                        kcd_code_arr: this.props.kcd_code_arr,
                        ctg_code3: this.props.ctg_code3,
                        ingred_code_grp: this.props.ingred_code_grp,
                        dgsbjt_cd_arr: this.props.dgsbjt_cd_arr,
                        dgsbjt_dtl_cd_arr: this.props.dgsbjt_dtl_cd_arr,
                        agg_arr: this.props.agg_arr,
                        sex_tp_cd_arr: this.props.sex_tp_cd_arr,
                        cl_cd_arr: this.props.cl_cd_arr,
                        bed_arr: this.props.bed_arr,
                        sido_cd_arr: this.props.sido_cd_arr,
                        place: this.props.place,
                        atc_code: this.props.atc_code,
                        combi_flag: this.props.combi_flag,
                        method: this.props.method,
                        unit_amt_code: this.props.unit_amt_code.split("_")[1],
                        ingred_code_grp_arr: [],
                        ingred_combi_flag: this.props.ingred_combi_flag,
                        stat_year: this.props.stat_year
                    }}>
                        {({ data, loading, error }) => {
                            if (loading) return null
                            if (error) return <p>{error.message}</p>;
                            //console.log('SUB_CAPACITY_STAT_LIST', data);
                            return (
                                <Fragment>
                                    <div class="titxt">
                                        <span class="subtxt">선택한 조건에서 <span className="deco1">{this.props.ingred_eng} {this.props.unit_amt_name}</span> 처방 패턴</span>
                                        <span class="subtxt_">약물 평균 단가 <span className="deco2">{this.props.unit_amt_value}</span></span>
                                    </div>
                                    <div className="capacity_wrap">
                                        <div className="capacity_chart">
                                            <SubCapacityDayChart
                                                data={data}
                                                unit_amt_code={this.props.unit_amt_code.split("_")[1]}
                                                unit_amt_value={this.props.unit_amt_value}
                                            />
                                        </div>
                                        <div className="capacity_chart">
                                            <SubCapacityDrugCntChart
                                                data={data}
                                                unit_amt_code={this.props.unit_amt_code.split("_")[1]}
                                                unit_amt_value={this.props.unit_amt_value}
                                            />
                                        </div>
                                        <div className="capacity_chart">
                                            <SubCapacityTimeChart
                                                data={data}
                                                unit_amt_code={this.props.unit_amt_code.split("_")[1]}
                                                unit_amt_value={this.props.unit_amt_value}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        }}
                    </Query>
                }
            </Fragment>
        )
    }
}

export default CapacitySubChartFragment;