import React, {Fragment} from 'react';
import { ContextMenu, Item, Separator, ContextMenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

class MoreBtn extends React.PureComponent {
    render() {
        const {comment_idx, commentEditForm, commentRemove, from} = this.props;
        //console.log('MoreBtncall', `${from}_comment_menu_${comment_idx}`)
        return (
            <Fragment>
                <ContextMenuProvider id={`${from}_comment_menu_${comment_idx}`} event="onClick">
                    <div className="more">
                        <i className="material-icons">more_vert</i>
                    </div>
                </ContextMenuProvider>
                <ContextMenu id={`${from}_comment_menu_${comment_idx}`} style={{ zIndex: 999999, marginLeft: "-60px" }}>
                    <Item onClick={()=>commentEditForm()}>
                        <span className="sellist"><i className="material-icons">create</i><span className="selbtn">의견수정</span></span>
                    </Item>
                    <Separator />
                    <Item onClick={()=>commentRemove()}>
                        <span className="sellist"><i className="material-icons">delete_sweep</i><span className="selbtn">의견삭제</span></span>
                    </Item>
                </ContextMenu>
            </Fragment>
        )
    }
}

export default MoreBtn;