import _ from 'lodash';
import $ from 'jquery';
import ReactGA from 'react-ga';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { isMobileOnly } from 'react-device-detect';
import SessionManager from "./SessionManager";
import TagManager from 'react-gtm-module';

//const USER_AUTH_ENDPOINT = 'http://localhost:18080/authenticate';

export default {
	//module.exports = {
	authenticated: function () {
		return !!SessionManager.getToken();
	},
	getImageDomain: function () {
		return "https://image.medigate.net";
	},
	createUserSession: async function (MeDiPkg) {
		let sessionUserInfo = null;
		try {
			const USER_AUTH_ENDPOINT = 'https://apis.medigate.net/authenticate';
			const response = await axios.post(USER_AUTH_ENDPOINT, {MeDiPkg: MeDiPkg});
			// //console.log('createUserSession', response);
			var responseData = response ? response.data : {};
			let user = responseData ? responseData.user : {};
      //console.log('responseData.user', responseData.user);
			if (user && (user.uKind === 'UKD000' || user.uKind === 'UKD001')) {
				SessionManager.saveAuthInfoToStorage(true, responseData);
				sessionUserInfo = user;
			}
		}
		catch (error) {
			console.error(error);
		}
		return sessionUserInfo;
	},
	getSessionUserInfo: async function () {
		let user = this.getUserInfo();	//from storage
		if(!_.isEmpty(user)){
			return user;
		}
		const MeDiPkg = await this.getCookieValue('MeDiPkg');	//from cookie
		if(!_.isEmpty(MeDiPkg) && _.isEmpty(user)){
			user = await this.createUserSession(MeDiPkg);
		}
        return user;
	},
    getRealtimeUserInfo: async function () {
        let user = null;
        const MeDiPkg = await this.getCookieValue('MeDiPkg');	//from cookie
        if(!_.isEmpty(MeDiPkg) && _.isEmpty(user)){
            user = await this.createUserSession(MeDiPkg);
        }
        return user;
    },
	getUserProfile: async function (usrId, token) {
    if(!usrId || !token){
			return null;
		}

		const config = {
			headers: {
				'Content-Type': 'application/json;charset=UTF-8',
				'Authorization': `Bearer ${token}`
			}
		};

		const USER_PROFILE_ENDPOINT = `https://apis.medigate.net/doctor_profile/${usrId}`;
		//console.log('USER_PROFILE_ENDPOINT', USER_PROFILE_ENDPOINT)
		try{
      const response = await axios.get(USER_PROFILE_ENDPOINT, config);
      const user = SessionManager.getConvProfileUser(response.data);
			return user;
		}
		catch(e){
      //console.log('getUserProfile error', e.response);
      if(e && e.response && e.response.data && e.response.data.error === 'TOKEN_EXPIRED'){
        const MeDiPkg = await this.getCookieValue('MeDiPkg');	//from cookie
        if(!_.isEmpty(MeDiPkg)){
          const userSession = await this.createUserSession(MeDiPkg);
          const newToken = await SessionManager.getToken();
          //console.log('session re-created', usrId, newToken);
          return await this.getUserProfile(usrId, newToken);
        }
      }
      return null;
		}
	},
	getToken: function () {
		return SessionManager.getToken();
	},
	getUserInfo: function () {
		return SessionManager.getUserInfo();
	},
	getUserId: function () {
		const user = this.getUserInfo();
		return !user ? null : user.id;
	},
	getUserSno: function () {
		const user = this.getUserInfo();
		return !user ? null : user.sno;
	},
	getUserName: function () {
		const user = this.getUserInfo();
		return !user ? null : user.name;
	},
	getUserType: function () {
		const user = this.getUserInfo();
		return !user ? null : user.userType;
	},
	getUserKind: function () {
		const user = this.getUserInfo();
		return !user ? null : user.userKind;
	},
	getUserEmail: function () {
		const user = this.getUserInfo();
		return !user ? null : user.email;
	},
	getUserSex: function () {
		const user = this.getUserInfo();
		return !user ? null : user.sex;
	},
	getUserPic: function () {
		const user = this.getUserInfo();
		return !user ? null : user.pic;
	},
	getUserNick: function () {
		const user = this.getUserInfo();
		return !user ? null : user.nick;
	},
	getUserProfileStep: function () {
		const user = this.getUserInfo();
		return !user ? null : user.profileStep;
	},
	hasRoleDoctor: function () { //의사, 관리자 포함
		return SessionManager.hasRole('DOCTOR')
	},
	setUserInfo: function (key, value) {
		SessionManager.setUserInfo(key, value)
	},
	initAuthStorage: function () {
		SessionManager.initAuthStorage()
	},
	setCookie: function (cookieName, cookieValue) {
		SessionManager.setCookie(cookieName, cookieValue);
	},
	setCookieDays: function (cookieName, cookieValue, days) {
		SessionManager.setCookieDays(cookieName, cookieValue, days);
	},
	getCookieValue: function (cookieName) {
		return SessionManager.getCookieValue(cookieName);
	},
	saveAuthInfoToStorage: function (rememberme, authInfo) {
		return SessionManager.saveAuthInfoToStorage(rememberme, authInfo);
	},
	signout: function (history) {
		SessionManager.signout(history)
	},
	getCodeName: function (code, codeList) {
		let rtnData = "";
		_.forEach(codeList, function (codeObj) {
			if (codeObj.code === code) {
				rtnData = codeObj.name;
				return false;
			}
		});
		return rtnData;
	},
	isEmpty: function (value) {
		return (value && _.size(_.trim(value)) > 0) ? false : true;
	},
	enter2Br: function (value) {
		return !this.isEmpty(value) ? value.replace(/(?:\r\n|\r|\n)/g, '<br/>') : '';
	},
	replaceAll: function (str, from, to) {
		if (str) {
			return str.replace(new RegExp(from, 'g'), to);
		}
		else {
			return str;
		}
	},
	serializedFormOfJson: function (arr) {
		let obj = {};
		if (arr && arr.length > 0) {
			$.each(arr, function () {
				obj[this.name] = this.value;
			});
		}
		return obj;
	},
	getyyyymmdd() {
		var d = new Date().toISOString().slice(0, 10);
		return d;
	},
	getFileName: function (str) {
		return (str.lastIndexOf("/") > 0) ? str.substring(str.lastIndexOf("/") + 1) : (str.lastIndexOf("\\") > 0) ? str.substring(str.lastIndexOf("\\") + 1) : str;
	},
	getThumbName: function (fileName) {
		if (!fileName) return "";
		if (fileName.lastIndexOf(".") < 0) {
			return fileName + "_thumb";
		}
		else {
			let fileExt = fileName.substring(fileName.lastIndexOf("."));
			return fileName.substring(0, fileName.lastIndexOf(".")) + "_thumb" + fileExt;
		}
	},
	getFilePath: function (str) {
		return (str.lastIndexOf("/") > 0) ? str.substring(0, str.lastIndexOf("/") + 1) : (str.lastIndexOf("\\") > 0) ? str.substring(0, str.lastIndexOf("\\") + 1) : "";
	},
	getThumbnailPath: function (imgPath) {
		return this.getFilePath(imgPath) + this.getThumbName(this.getFileName(imgPath));
	},
	getProfileThumb: function (imgPath = this.getUserPic()) {
		if (imgPath) {
			let thumbPath = this.getThumbnailPath(imgPath)
			return this.getImageDomain() + thumbPath
		}
		else {
			return 'http://image.medigate.net/static/mobile/ver_3/images/icon_profilethumb.gif'
		}
	},
	getFileThumb: function (imgPath) {
		//console.log('getFileThumb', imgPath)
		if (imgPath && imgPath.startsWith('/upload')) {
			let thumbPath = this.getThumbnailPath(imgPath)
			return this.getImageDomain() + thumbPath
		}
		else {
			return imgPath
		}
	},
	getDomain: function (url) {
		var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
		if (match !== null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
			return match[2];
		}
		else {
			return null;
		}
	},
	ip: function (callbackIPAddr) {
		return new Promise((resolve, reject) => {
			//findIP
			(new Promise(r => { var w = window, a = new (w.RTCPeerConnection || w.mozRTCPeerConnection || w.webkitRTCPeerConnection)({ iceServers: [] }), b = () => { }; a.createDataChannel(""); a.createOffer(c => a.setLocalDescription(c, b, b), b); a.onicecandidate = c => { try { c.candidate.candidate.match(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g).forEach(r) } catch (e) { } } }))
				.then(response => {
					//console.log("ip_address : ", response);
					resolve(response);
					callbackIPAddr(response);
				})
				.catch(response => reject(response));
		});
	},
	wrapWindowByMask: function () {
        //화면의 높이와 너비를 구한다.
        var maskHeight = $(document).height();
        var maskWidth = $(window).width();

        //마스크의 높이와 너비를 화면 것으로 만들어 전체 화면을 채운다.
        $('#mask').css({'width':maskWidth,'height':maskHeight});

        //애니메이션 효과
        $('#mask').fadeIn(10);
        $('#mask').fadeTo("slow", 0.8);
	},
	displayLayer: function (id) {
		//console.log('displayLayer', id, $('[data-popup="' + id + '"]').is(':visible'));
		if($('[data-popup="' + id + '"]').is(':visible')){
			//$('#mask, .window').hide();
			$('[data-popup="' + id + '"]').fadeOut(0);
		}
		else{
			//this.wrapWindowByMask();
			$('[data-popup="' + id + '"]').fadeIn(0);
		}
	},
	awsConfig: function () {
		const config = {
			accessKeyId: this.decrypt('U2FsdGVkX19ekbigueqQDFVwQbeiCSLF18eG8Sk4houJcmLtaufHhkMv73Bjx809'),
			secretAccessKey: this.decrypt('U2FsdGVkX1+1W5qhOCkI5EwkGTVBaenxb7jI0lqunN/MXoXb10g+ZsNnqK6QimUpO8G4ofHk8LpAfUM4WT/gBg=='),
			region : this.decrypt('U2FsdGVkX19Q6xTCZNgx4QfBcwqdVRH75DHPHrEGwY4=')
		}
		return config;
	},
	encrypt: function (text) {
		var ciphertext = CryptoJS.AES.encrypt(text, SessionManager.getSecretKey());
		return ciphertext.toString()
	},
	decrypt: function (ciphertext) {
		try {
			if (ciphertext) {
				var bytes = CryptoJS.AES.decrypt(ciphertext.toString(), SessionManager.getSecretKey());
				var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
				//console.log('decryptedData', decryptedData);
				return decryptedData
			}
			else {
				return null
			}
		}
		catch (e) {
			//console.log("decryptJson error: ", e)
		}
	},
	getSnoSuffix: function (sno) {
		if(!sno){
			return '';
		}
		var sno_str = sno + ''
		let width = 7
		let snoLen = sno_str.length
		sno_str = snoLen < width ? new Array(width - snoLen + 1).join('0') + sno_str : sno_str
		return sno_str
	},
	getKcdNameConv: function (kcd_name) {
		if(!kcd_name){
			return '';
		}

		var kcd_arr = kcd_name.split(/[()]/);
		//console.log('arrStr', kcd_arr);
		let kcd_name_conv = '';
		kcd_arr.map((item, index)=>{
			if(index % 2 === 0){
				//normal
				kcd_name_conv += item;
			}
			else{
				//(괄호안)특수 문자 제거
				if(!(item.indexOf('*') !== -1 || item.indexOf('†') !== -1 || item.indexOf('-+') !== -1 || (item.indexOf('-') !== -1 && item.indexOf('+') !== -1))){
					kcd_name_conv += '(' + item + ')';
				}
			}
		})
		return kcd_name_conv;
	},
	sendFeedReactLog: function(dimensions) {
		let qString = 'CATEGORY=' + encodeURI(dimensions.dimension11);
        qString += '&SERVICE_NAME=' + encodeURI(dimensions.dimension1);
        qString += '&WORK_TYPE=' + encodeURI(dimensions.dimension2);
		qString += '&SPECIALTY=' + encodeURI(dimensions.dimension3);
		qString += '&SUBSPECIALTY_IM=' + encodeURI(dimensions.dimension4);
		qString += '&HOSPITAL_TYPE=' + encodeURI(dimensions.dimension5);
		qString += '&USER_ID=' + encodeURI(dimensions.dimension6);
		qString += '&AGE=' + encodeURI(dimensions.dimension7);
		qString += '&SEX=' + encodeURI(dimensions.dimension8);
		qString += '&DEVICE=' + encodeURI(dimensions.dimension9);
		qString += '&CUSTOM_VALUE=' + encodeURI(dimensions.dimension10);

        let url = 'https://logger.medigate.net/drgate/react?' + qString;
        //console.log("qString ", qString, " / url: ", url)
        axios.get(url)
        .catch(error => {
			//console.log("ReactLog error: ", error);
	    });

	},
	getParameterValue: function (paramName, paramSearch) {
		let params = new URLSearchParams(paramSearch);
		let value = params.get(paramName);
		//console.log("value", value);
		return value;
	},
    pivotArray: function (dataArr=[], keyArr=[]) {
        let ret = [];
        dataArr.map(item => {
            let values = [];
            keyArr.map(key => {
                values.push(item[key]);
            })
            ret.push(values);
        })
        //console.log('pivotArray-ret', ret);
        return ret;
    },
	pageview: function (category, title, value) {
	    //console.log('pageview - ', category, title, value)
		document.title = "닥터게이트" + (_.isEmpty(category) ? '' :  (' ' + category));

		if(process.env.REACT_APP_ENV_TYPE === 'production'){
            let user = this.getUserInfo();
            if(user === null){
                user = {};
            }
            const { sno, id, wtp, spc, spcIm, hospitalType, sex, age, userType } = user;
            const snoSuffix = this.getSnoSuffix(sno);
            //console.log('snoSuffix', snoSuffix);

            const dimensions = {
                dimension1: title,
                dimension2: wtp || '',
                dimension3: spc || '',
                dimension4: spcIm || '',
                dimension5: hospitalType || '',
                dimension6: id || '',
                dimension7: age || '',
                dimension8: sex || '',
                dimension9: isMobileOnly ? 'MOBILE' : 'WEB',
                dimension10: value || '',
                dimension11: category || ''
            }
		    //console.log('GA_dimensions', dimensions);
			this.sendFeedReactLog(dimensions); //bigquery react log

            /*
			//GOOGLE ANALYTICS
			ReactGA.initialize('UA-143614795-1', {
				debug: false,
				gaOptions: {
					cookieDomain: 'auto'
				}
			});

			//if(!_.isEmpty(user)){
				ReactGA.set({ userId: snoSuffix });
			//}
			ReactGA.set(dimensions);
			ReactGA.pageview(window.location.pathname);
			*/

            //GOOGLE TAG MANAGER
            const tagManagerArgs = {
                gtmId: 'GTM-KJQ3WG7',
                dataLayer: {
                    uid: snoSuffix,
                    MG_SERVICE_NAME: dimensions.dimension1,
                    MG_WORK_TYPE: dimensions.dimension2,
                    MG_SPECIALTY: dimensions.dimension3,
                    MG_SUBSPECIALTY_IM: dimensions.dimension4,
                    MG_HOSPITAL_TYPE: dimensions.dimension5,
                    MG_USER_ID: dimensions.dimension6,
                    MG_AGE: dimensions.dimension7,
                    MG_SEX: dimensions.dimension8,
                    MG_DEVICE: dimensions.dimension9,
                    MG_VALUE: dimensions.dimension10,
                    MG_CATEGORY: dimensions.dimension11
                }
            }

            if(this.isEmpty(window['dataLayer'])){
                TagManager.initialize(tagManagerArgs);
            }
            else{
                TagManager.dataLayer(tagManagerArgs);
            }
		}
	},
	getDateIntervalMonth: function (interval_month = 0) {
		let today = new Date();
		let month = (today.getMonth() + (interval_month +1))
		let date = today.getFullYear() + '.' + (month < 10 ? ("0" + month) : month);
		return date;
	}
}
