import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

class S_Title extends Component {
    render() {
        const { props } = this;
        return (
            <Typography
                component="h2"
                variant={this.props.variant ? this.props.variant : "h6"}
                align={this.props.align}
                color={this.props.color ? this.props.color : "primary"}
                gutterBottom
                {...props}
            >
                {this.props.children}
            </Typography>
        );
    }
}

S_Title.propTypes = {
    children: PropTypes.node,
};

export default S_Title;
