import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function S_Snackbar({snackbar_open, closeSnackbar, snackbar_message, snackbar_type, snackbar_anchor_origin}) {
    const handleClose = (event, reason) => {
        closeSnackbar();
    };

    return (
        <Snackbar open={snackbar_open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={snackbar_anchor_origin}>
            <Alert onClose={handleClose} severity={snackbar_type}>
                {snackbar_message}
            </Alert>
        </Snackbar>
    );
}
