import React, {useState, useEffect, Fragment} from 'react';
import {useLazyQuery, useQuery} from '@apollo/react-hooks';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import {useStore} from "../../stores/StoreProvider";

import {LEASE_LIST, DONG_INFO} from "../../queries/location";
import {observer} from "mobx-react";
import CommonFunc from "../../common/CommonFunction";

// function LeaseAdContent(props){
const LeaseAdContent = observer ((props) => {
    const store = useStore().LocationStore;
    // //console.log("ks sgg_code", store.locationInfo);
    const {handleCenterMarker} = props;
    const [leaseItem, setLeaseItem] = useState(null);

    const getMgDomain = () => {
        let mgDomain = "www.medigate.net";
        if(process.env.REACT_APP_ENV_TYPE === 'test'){
            mgDomain = "t-" + mgDomain;
        }

        return mgDomain;
    }


    const {
        loading: loadingLeaseList, error: errorLeaseList, data: dataLeaseList
    } = useQuery(LEASE_LIST, {
        variables: {
            sgg_code: store.locationInfo ? store.locationInfo.sgg_code : null
        },
        fetchPolicy: 'network-only',
        onCompleted: (data)=> { store.setLeaseMarkerList(data.getLeaseList) },
    });

    const getLeaseTypeObj = (leaseTypeName) => {
        let leaseTypeCode = null;
        switch (leaseTypeName){
            case '분양':
                leaseTypeCode = 1;
                break;
            case '임대':
                leaseTypeCode = 2;
                break;
            case '매매':
                leaseTypeCode = 3;
                break;
            case '양도':
                leaseTypeCode = 4;
                break;
        }

        return <span className={`leaseType tag-type${leaseTypeCode}`}>{leaseTypeName}</span>;
    }

    useEffect(() => {
        let leaseItemComponent = <ListItem><ListItemText><Typography component="p" variant="body2" color="textPrimary">매물정보가 없습니다.</Typography></ListItemText></ListItem>;
        // //console.log("leaseadcontent dataleaselist", dataLeaseList, store.locationInfo);
        if(!loadingLeaseList && dataLeaseList){
            if(dataLeaseList.getLeaseList.length > 0){
                leaseItemComponent =dataLeaseList.getLeaseList.map((item) => {
                    let rentLayerText = `전층 / ${item.tot_over_layer}`;
                    if(item.rent_layer_type == "O"){
                        rentLayerText = `지상 ${item.rent_layer}층 / ${item.tot_over_layer}`;
                    } else if(item.rent_layer_type == "U"){
                        rentLayerText = `지하 ${item.rent_layer}층 / ${item.tot_over_layer}`;
                    } else {

                    }
                    return(
                        <div className="leasecont">
                            <ListItem>
                                <ListItemText
                                    className={"lstcont lst-ads"}
                                    primary={
                                        <Fragment>
                                            <Typography
                                                component="div"
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                                <p className="type-area">
                                                    {/*<span className={`leaseType tag-type${item.lease_type_code}`}>{item.lease_type}</span>*/}
                                                    {
                                                        item.lease_type.split(",").map((leaseType, i)=>getLeaseTypeObj(leaseType))
                                                    }
                                                </p>
                                                <a href={`http://${getMgDomain()}/lease/${item.lease_idx}`} target={"_blank"} className="btn btn-sm btn-secondary" onClick={()=> { CommonFunc.pageview('입지분석', '임대분양 이동', '' + item.lease_idx + ''); }}>자세히보기</a>
                                            </Typography>

                                            <Typography
                                                component="p"
                                                variant="h6"
                                                color="textPrimary"
                                                onClick={()=> { handleCenterMarker([item.pos_y, item.pos_x], item.title, true); CommonFunc.pageview('입지분석', '매물 클릭');}}
                                            >
                                                {item.title}
                                            </Typography>
                                        </Fragment>
                                    }
                                    secondary={
                                        <Fragment>
                                            <Typography
                                                className="lease-moreinfo"
                                                component="p"
                                                variant="body2"
                                                color="textPrimary"
                                                onClick={()=> { handleCenterMarker([item.pos_y, item.pos_x], item.title, true); CommonFunc.pageview('입지분석', '매물 클릭');}}
                                            >
                                                <span className="titxt">지역</span>
                                                <span className="subtxt">{`${item.sido_name} ${item.sgg_name}`}</span>
                                            </Typography>


                                            <Typography
                                                className="lease-moreinfo"
                                                component="p"
                                                variant="body2"
                                                color="textPrimary"
                                                onClick={()=> { handleCenterMarker([item.pos_y, item.pos_x], item.title, true); CommonFunc.pageview('입지분석', '매물 클릭');}}
                                            >
                                                <span className="titxt">면적</span>
                                                <span className="subtxt spaceline">{`${item.supply_area}㎡ / ${item.private_area}㎡`}</span>
                                                <span className="titxt">층(해당층/총층)</span>
                                                <span className="subtxt">{`${rentLayerText}`}</span>
                                            </Typography>
                                        </Fragment>
                                    }
                                />
                            {/*<ListItemText secondary={`면적 : ${item.supply_area} ${item.sgg_name}`} />*/}
                            {/*<Typography variant="h6">
                                    면적 : {item.supply_area} {item.private_area}
                                </Typography>*/}
                            </ListItem>
                        </div>
                    )
                })
            }
        }

        setLeaseItem(leaseItemComponent);
    }, [dataLeaseList, loadingLeaseList]);

    if (loadingLeaseList) return <p></p>;
    if (errorLeaseList) return <p></p>;

    //console.log("ks LEASE_LIST data", dataLeaseList);


    return (
        <Fragment>
            <Grid container alignItems="center">
                <Grid item xs>
                    <Typography gutterBottom variant="h6">
                        <span className={"tag-title"}>메디게이트 매물정보</span>
                        <span className={"tag-location"}>{store.locationInfo.sgg_name}</span>
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography className="absolute btn-golink">
                        <a href="https://www.medigate.net/lease" className="btn btn-link" target={"_blank"}>임대분양 바로가기</a>
                    </Typography>
                </Grid>
            </Grid>
            <Divider component="li"/>
            <List style={{marginBottom:"30px",paddingBottom:"10px",borderBottom:"1px solid rgba(0,0,0,0.1)"}}>
                {/*<leaseItem />*/}
                {leaseItem}

{/*                {
                    dataLeaseList.length > 0 ?
                    dataLeaseList.getLeaseList.map((item) => {
                        return(
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Fragment>
                                            <Typography
                                                component="p"
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                                {`[${item.lease_type}]`}
                                            </Typography>


                                            <Typography
                                                component="p"
                                                variant="h6"
                                                color="textPrimary"
                                            >
                                                {item.title}
                                            </Typography>
                                        </Fragment>
                                    }
                                    secondary={
                                        <Fragment>
                                            <Typography
                                                component="p"
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                                지역 : {`${item.sido_name} ${item.sgg_name}`}
                                            </Typography>


                                            <Typography
                                                component="p"
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                                면적 : {`${item.supply_area}㎡ / ${item.private_area}㎡ | 층(해당층/총층) : ${item.rent_layer} / ${item.tot_layer}`}
                                            </Typography>
                                        </Fragment>
                                    }
                                />
                                <ListItemText secondary={`면적 : ${item.supply_area} ${item.sgg_name}`} />
                                <Typography variant="h6">
                                    면적 : {item.supply_area} {item.private_area}
                                </Typography>
                            </ListItem>
                        )
                    })
                    :
                    <ListItem>
                        <ListItemText>
                            <Typography
                                component="p"
                                variant="body2"
                                color="textPrimary"
                            >
                                매물정보가 없습니다.
                            </Typography>
                        </ListItemText>
                    </ListItem>
                }*/}
            </List>
        </Fragment>
    )
})

export default LeaseAdContent;