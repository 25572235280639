import React from 'react';
import _ from 'lodash';
import $ from 'jquery';
import SearchFragment from '../prescription/SearchFragment';
import Toastr from '../../../common/Toastr';
import { isMobileOnly } from 'react-device-detect';

class KcdEditLayer extends React.PureComponent {
    state = {
        keyword: null,
        kcdAllFlag: true,
        kcdLayer2DepthFlag: false,
        inLayerkcdList: [],
        kcd_list: this.props.kcd_list,
        kcd_add_msg: false,
    }

    changeKcdLayer2DepthFlag = (flag) => {
        setTimeout(() => {
            this.setState({
                kcdAllFlag: true,
                kcdLayer2DepthFlag: flag,
                inLayerkcdList: this.props.kcd_list,
                kcd_list: this.props.kcd_list
            })
        }, 300)
    }

    changeKcdAllFlag = (e) => {
        const checked = e.target.checked;

        if(checked){
            this.setState({
                kcdAllFlag: true,
                kcd_list: this.state.inLayerkcdList
            });
            $("input:checkbox[name='layer_kcd']").prop("checked", true);
        }else{
            this.setState({
                kcdAllFlag: false,
                kcd_list: []
            });
            $("input:checkbox[name='layer_kcd']").prop("checked", false);
        }
    }

    changeSearchCondition = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;

        if(checked){
            let target_text = $(e.target).next().text();
            target_text = target_text.split(" : ")[1];
            let target_order_cnt = $(e.target).data("ordercnt");
            let target_wikikeyword = $(e.target).data("wikikeyword");

            this.setState({
                kcd_list: [...this.state.kcd_list, {"kcd_code": value, "kcd_kor": target_text, "order_cnt": target_order_cnt, "wikiKeyword": target_wikikeyword}]
            });
        }else{
            this.setState({
                kcd_list: this.state.kcd_list.filter(val => val.kcd_code !== value)
            });
        }
    }

    submitCondition = () => {
        const { kcd_list } = this.state;
        this.props.onChangeKcdSearchCondition(kcd_list);
        this.setState({
            kcdLayer2DepthFlag: false
        })
    }

    onSearched = (keyword, added_kcd_list) => {
        const kcd_list = _.concat(this.state.kcd_list, added_kcd_list);
        this.setState({
            keyword: null, //keyword reset
            kcd_list: kcd_list,
            inLayerkcdList: kcd_list,
            kcd_add_msg: true
        })

    }

    render() {
        // //console.log("ccc states", this.state, this.props)
        if(this.state.kcd_list.length < this.state.inLayerkcdList.length){
            $("#layer_kcd_all").prop("checked", false);
        }else{
            $("#layer_kcd_all").prop("checked", true);
        }

        return (
            <div id="disease-option" className="modal fade mdc-dialog" style={{"-webkitOverflowScrolling": "touch"}}>
                <div className="modal-dialog">
                    <div className="contwrap modal-content">
                        {
                            !isMobileOnly &&
                            <p className="mdl-button close" data-dismiss="modal" onClick={() => this.changeKcdLayer2DepthFlag(false)}>
                                <span className="btn-closeApply"></span>
                            </p>
                        }
                        {
                            !this.state.kcdLayer2DepthFlag &&
                            <div className="tag_list">
                                <div class="modal-body" style={{maxHeight: "calc(100vh - 180px)", overflowY: "auto"}}>
                                    <div className="contwrap">
                                        <h3 className="title">다음 상병이 명시된 처방전의 약물 결과가 나타납니다</h3>
                                        <div className="category">

                                            {
                                                this.props.kcd_list.map((item, index) =>
                                                    <div className="slider__item" key={index}>
                                                        <span className="mdl-chip mdl-chip--deletable">
                                                            <span className="mdl-chip__text">{item.kcd_code} : {item.kcd_kor}</span>
                                                        </span>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <div className="btn_type2 border-top-1px">
                                        <button className="mdc-button mdc-button--outlined cancel close" data-dismiss="modal" onClick={() => this.changeKcdLayer2DepthFlag(false)}>취소</button>
                                        <button className="mdc-button mdc-button--raised select" onClick={()=> {this.changeKcdLayer2DepthFlag(!this.state.kcdLayer2DepthFlag); $('#disease-option .modal-dialog').animate({ scrollTop: 0 }, 'fast');}}>수정하기</button>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (this.state.kcdLayer2DepthFlag && this.state.inLayerkcdList.length > 0) &&
                            <div className="tag-option_list">
                                <div class="modal-body" style={{maxHeight: "calc(100vh - 180px)", overflowY: "auto"}}>
                                    <div className="contwrap">
                                        <h3 className="title">선택한 상병을 해제하거나, + 버튼을 눌러 추가합니다</h3>
                                        <div className="category">
                                            <ul className="checkbox_area">
                                                <li style={{paddingRight:'20px'}}>
                                                    <input type="checkbox" name="layer_kcd_all" id="layer_kcd_all" value="all" className="SPC" onChange={(e)=>this.changeKcdAllFlag(e)} defaultChecked={this.state.kcdAllFlag} />
                                                    <label htmlFor="layer_kcd_all">전체 {this.state.kcdAllFlag ? '선택' : '선택'}</label>
                                                </li>
                                                <div className="option_wrap">
                                                    <button className="mdc-fab btn_add" aria-label="add" id="option-show" onClick={()=> { $('.option_area').show(); $('#option-show').hide(); $('#option-hide').show(); }}>
                                                        <span className="mdc-fab__icon material-icons">add</span>
                                                    </button>
                                                    <button className="mdc-fab btn_clear" aria-label="clear" id="option-hide" onClick={()=> { $('.option_area').hide(); $('#option-show').show(); $('#option-hide').hide(); }}>
                                                        <span className="mdc-fab__icon material-icons">clear</span>
                                                    </button>

                                                    <div className="option_area" style={{display:'none'}}>
                                                        <SearchFragment
                                                            keyword={this.state.keyword}
                                                            filter_kcd_code_arr={_.map(this.state.kcd_list, 'kcd_code')}
                                                            onSearched={this.onSearched}
                                                            searchForm="KCD_ADD"
                                                        />
                                                        {
                                                            this.state.kcd_add_msg &&
                                                            <Toastr message={"선택한 상병이 추가되었습니다."} callback={()=>this.setState({kcd_add_msg: false})}/>
                                                        }
                                                    </div>
                                                </div>
                                            </ul>
                                            <ul className="checkbox_area sub">
                                                {
                                                    this.state.inLayerkcdList.map((item, index) => {
                                                        //console.log('--->', index, item)
                                                        return (
                                                            <li key={index}>
                                                                <input
                                                                    type="checkbox"
                                                                    name="layer_kcd"
                                                                    id={`layer_kcd_${item.kcd_code}`}
                                                                    value={item.kcd_code}
                                                                    data-ordercnt={item.order_cnt}
                                                                    data-wikikeyword={item.wikiKeyword}
                                                                    className="SPC"
                                                                    onChange={(e)=>this.changeSearchCondition(e)}
                                                                    defaultChecked={this.state.kcdAllFlag}
                                                                />
                                                                <label id="layer_kcd" htmlFor={`layer_kcd_${item.kcd_code}`}>{item.kcd_code} : {item.kcd_kor}</label>
                                                            </li>
                                                        )}
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <div className="btn_type2 border-top-1px">
                                        <button className="mdc-button mdc-button--outlined cancel close" data-dismiss="modal" onClick={() => this.changeKcdLayer2DepthFlag(false)}>취소</button>
                                        <button className="mdc-button mdc-button--raised select" data-dismiss="modal" onClick={()=> this.submitCondition()}>확인</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
};

export default KcdEditLayer;