
const drawerWidth = 240;
const salaryStyles = theme => ({
    root: {
        display: 'block',
    },
    leftMenuRoot: {
        width: '220px',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(5),
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('sm')]: {
            marginLeft: drawerWidth,
        },
    },
    appBarShift: {
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarSpacer: theme.mixins.toolbar,
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    content: {
        /*flexGrow: 1,
        height: '100vh',
        overflow: 'auto',*/
    },
    content_m: {
        flexGrow: 1,
        overflow: 'auto',
    },
    hiddenMenuContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeight: {
        height: 240,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        justifyContent: "center",
    },
    notLoginPaper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        backgroundImage: `url(http://image.medigate.net/drgate/images/salary/base.jpg)`,
        backgroundPosition: "center top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: 1000,
        justifyContent: "center",
    },
    paperMargin: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        textAlign: 'center',
        marginBottom: theme.spacing(3),
        color: theme.palette.text.secondary,
    },
    paperMedium: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        borderTop: '1px solid grey'
    },
    fixedMediumHeight: {
        height: 120,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    formControl_width: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    textCenter: {
        display: 'flex',
        alignItems: "center",
        justifyContent: "center"
    },
    payTextCenter: {
        textAlign: "center"
    },
    payTextCenterTest: {
        textAlign: "center"
    },
    MuiTableCellRoot: {textAlign:'center',color:'red'},
    MuiTableCellAlignCenter: {textAlign:'left'},
    pagegraph: {boxShadow:'none'},
    overview: {padding:'0'},
    txtinfo1: {display:'block'},
    txtinfo2: {display:'block'},
    txtinfo3: {display:'block'},
    txtinfo4: {display:'block'}
});

export default salaryStyles;