import React, { Fragment } from 'react';
import { withApollo } from 'react-apollo';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import axios from 'axios';
import $ from 'jquery';
import _ from 'lodash';
import CommonFunc from '../../../common/CommonFunction';
import { COMMENT_CREATE } from '../../../queries/prescript/comment';
import { confirmAlert } from 'react-confirm-alert';
import HtmlConfirm from '../../../common/HtmlConfirm';
import ShowMoreText from 'react-show-more-text';

const AWS = require('aws-sdk');
const s3 = new AWS.S3(CommonFunc.awsConfig());

class Add extends React.PureComponent {

	state = {
		u_type: null,
		content: null,
		img_arr: [],
		more_less : 'LESS'
	}

	getCommentValues = () => {
		const { comment } = this.props;
		const { u_type, content, img_arr } = this.state;

		//set the edited values
		let values = {
			comment_idx: 0,
			ctg_code: comment.ctg_code,
			u_id: comment.u_id,
			u_ip: comment.u_ip,
			kcd_code_arr: _.map(comment.kcd_list, 'kcd_code'),
			u_type: u_type,
			content: content,
			img_arr: img_arr
		}

		//실명
		if(u_type === '1'){
			values = {
				...values,
				u_name: comment.u_name,
				u_pic: comment.u_pic,
				u_spc_name: comment.u_spc_name,
				u_wtp_name: comment.u_wtp_name,
			}
		}
		//필명
		else if(u_type === '2'){
			values = {
				...values,
				u_name: comment.u_nick,
				u_pic: null,
				u_spc_name: null,
				u_wtp_name: null,
			}
		}
		//익명
		else if(u_type === '3'){
			values = {
				...values,
				u_name: null,
				u_pic: null,
				u_spc_name: null,
				u_wtp_name: null,
			}
		}
		return values;
	}

	handleSubmit = (e) => {
	    e.preventDefault();
		this.refs.comment_input_submit.setAttribute("disabled", "disabled");

		if(_.isEmpty(this.state.u_type)){
			this.refs.comment_input_submit.removeAttribute("disabled");
			return confirmAlert({ customUI: ({ onClose }) => <HtmlConfirm title={"작성자 옵션 선택"} message={"실명, 필명 중 선택하세요."} onClose={onClose} />});
		}
		if(_.isEmpty(this.state.content)){
			this.refs.comment_input_submit.removeAttribute("disabled");
			return confirmAlert({ customUI: ({ onClose }) => <HtmlConfirm title={"내용 입력"} message={"의견을 작성하려면 내용을 입력하세요."} onClose={onClose} />});
		}

		const values = this.getCommentValues();

		this.props.client.mutate({
			mutation: COMMENT_CREATE,
			variables: { comment: values }
		})
		.then((res) => {
			this.refs.comment_input_submit.removeAttribute("disabled");
			//console.log('handleSubmit', res.data.createComment);
			this.props.onCommentAdd(res.data.createComment);
		})
		.catch((error) => {
			this.refs.comment_input_submit.removeAttribute("disabled");
			//console.log('error', error.message);
		});
	}

	handleOnChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	addImg = (img) => {
		this.setState({
			img_arr: [...this.state.img_arr, img]
		});
	}

	removeImg = (e, imgUrl) => {
		e.preventDefault();
		let filtered_img_arr = _.filter(this.state.img_arr, function(o) {
			if (o !== imgUrl) return o;
		});
		//console.log('filtered_img_arr', filtered_img_arr);
		this.setState({
			img_arr: filtered_img_arr
		});
	}

	handleFileUpload = (files) => {
		const file = files[0];
		const dirPath = moment().format('YYYY') + '/' + moment().format('MM') + '/';
		const hms = moment().format('hhmmss');
		const fileName = file.name.replace('.', '_' + hms + '.');

        var params = {
			Bucket: 'drgate',
			Key: 'image/' + dirPath + fileName,
			ACL:'public-read',
			Expires: 60,
			ContentType: file.type
		};

		const thisObj = this;
		s3.getSignedUrl('putObject', params, function(err, signedUrl) {
			if (err) {
				//console.log('error', err);
				return err;
			}
			else {
				//console.log('signedUrl', signedUrl);
				var instance = axios.create();
				instance.put(signedUrl, file, {headers: {'Content-Type': file.type}})
				.then(function (result) {
					//console.log('put result', result);
					const img = signedUrl.substring(0, signedUrl.lastIndexOf('?'));
					//console.log('image url : ', img);
					thisObj.addImg(img);
				})
				.catch(function (err) {
					//console.log(err);
				});
			}
		});
	}

	render() {
		const { closeInputForm, comment } = this.props;
		const { u_name, u_pic, u_nick, u_spc_name, u_wtp_name, kcd_list } = comment;

		return (
			<div className="accordion" id="write_review">
				<form onSubmit={(e)=>{e.preventDefault(); return false;}}>
					<div className="rbox_profile">
						<div className="radio-card">
							<input type="radio" name="u_type" value="1" id="producer" onChange={(e)=>this.handleOnChange(e)}/>
							<label for="producer" id="radio-label1">
								{
									!_.isEmpty(u_pic) ?
									<div className="thumbinfo" style={{background: `url(${CommonFunc.getImageDomain()}${u_pic}) center center / cover no-repeat`}}/>
									:
									<div className="thumbinfo none"/>
								}
								<div className="txtinfo">
									<p className="name">{u_name}</p>
									<p className="specialty"><span>{u_spc_name}</span></p>
									<p className="invite"><span>{u_wtp_name}</span></p>
								</div>
							</label>
						</div>
						{ !_.isEmpty(u_nick) ?
						<div className="radio-card type2">
							<input type="radio" name="u_type" value="2" id="consumer2" onChange={(e)=>this.handleOnChange(e)}/>
							<label for="consumer2" id="radio-label3">
								<div className="thumbinfo">{u_nick}</div>
								<div className="txtinfo">
									<p className="name">{u_nick}</p>
								</div>
							</label>
						</div>
                        :
                        <div className="radio-card type2">
                            <label>
                                <div className="txtinfo">
                                    <div className="thumbinfo">필명</div>
                                    <p className="name"><a href="http://www.medigate.net/user/member/editStep01" target="_blank" style={{textDecoration:'underline'}}>설정하기</a></p>
                                </div>
                            </label>
                        </div>
						}
                        {/* 익명제거 - 2020.06 */}
                        {/*
						<div className="radio-card type1">
							<input type="radio" name="u_type" value="3" id="consumer" onChange={(e)=>this.handleOnChange(e)}/>
							<label for="consumer" id="radio-label2">
								<div className="thumbinfo"></div>
								<div className="txtinfo">
									<p className="name">익명</p>
								</div>
							</label>
						</div>
						*/}
					</div>
					<div className="review_textfield">
						<p className="input_area">
							<textarea className="textEntry" name="content" placeholder="의견을 입력합니다." onChange={this.handleOnChange}></textarea>
						</p>
					</div>

					<div className="contwrap b-description_readmore js-description_readmore">
						<p className="extxt">약물의 의견이 다음 질환 검색 때 나타납니다.</p>
						<div style={this.state.more_less === 'LESS' ? {height:'18px', overflowY:'hidden'} : {height:'100%'}}>
							{ 	this.state.more_less === 'LESS' &&
								<div className="more" onClick={()=>this.setState({more_less: 'MORE'})}>MORE (+)</div>
							}
							{
								kcd_list.map((kcd, index) =>
									<span className="tag_code">{kcd.kcd_code}: {kcd.kcd_kor}</span>
								)
							}
							{ 	this.state.more_less === 'MORE' &&
								<div className="less" onClick={()=>this.setState({more_less: 'LESS'})}>LESS (-)</div>
							}
						</div>
						{/*
						<ShowMoreText
							lines={1}
							more='MORE(+)'
							less='LESS(-)'
							anchorclassName='see_more_link_inner'>
							{
								kcd_list.map((kcd, index) =>
									<span className="tag_code">{kcd.kcd_code}: {kcd.kcd_kor}</span>
								)
							}
						</ShowMoreText>
						*/}

					</div>
					<div className="btn_type3">
						<Dropzone
							multiple={false}
							accept="image/jpg,image/jpeg,image/gif,image/png"
							onDrop={ (acceptedFiles) => {
								console.log(acceptedFiles)
								if(acceptedFiles && _.size(acceptedFiles) > 0){
									let fileType = acceptedFiles[0].type;
									if(fileType.indexOf("image") === 0){
										this.handleFileUpload(acceptedFiles);
									}
								}
							}}>
							{({getRootProps, getInputProps}) => (
								<span {...getRootProps()}>
									<input {...getInputProps()} />
									<button className="mdl-button mdl-js-button mdl-button--fab">
										<i className="material-icons more_vert">add</i>
									</button>
								</span>
							)}
						</Dropzone>
						{
							this.state.img_arr.length > 0 &&
							<div className="thumb_wrap">
								<ul>
								{
									this.state.img_arr.map((imgUrl, index) =>
										<li key={index} className="rep"><div className="attach_thumb"><img src={imgUrl} alt="" /><div className="close" onClick={(e)=>this.removeImg(e, imgUrl)}><i className="material-icons md-13">close</i></div></div></li>
									)
								}
								</ul>
							</div>
						}
						<button className="mdc-button mdc-button--outlined cancel close" onClick={()=>closeInputForm()}>취소</button>
						<button className="mdc-button mdc-button--raised select" type="button" onClick={(e)=>this.handleSubmit(e)} ref='comment_input_submit'>등록</button>
					</div>
				</form>
			</div>
		);
	}
}
export default withApollo(Add);
