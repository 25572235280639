import React, { Component, Fragment } from 'react';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SCollapseLeft from "../../salery/S_CollapseLeft";
import { withStyles } from '@material-ui/core/styles';

import useStyles from '../../salery/SalaryStyle';

class LeftMenuBar extends Component {
    state = {
        open: false,
    }
    handleDrawerOpen = () => {
        this.setState({
            open: true
        })
    };
    handleDrawerClose = () => {
        this.setState({
            open: false
        })
    };
    render() {
        const { classes } = this.props;
        const { open } = this.state;

        return (
            <Fragment>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.handleDrawerOpen}
                >
                    <MenuIcon />
                </IconButton>
                <SwipeableDrawer
                    open={open}
                    onClose={this.handleDrawerClose}
                    onOpen={this.handleDrawerOpen}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={this.handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />

                    {/** Left navigation */}
                    <SCollapseLeft
                        closeDrawer={this.handleDrawerClose}
                    />
                </SwipeableDrawer>
            </Fragment>
        );
    }
}

export default withStyles(useStyles)(LeftMenuBar);

