import React, { Component } from 'react';

import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";

class S_NonPieContent extends Component {
    render() {
        const { data={} } = this.props;

        return (
            <Table size="small" className="payoption_table">
                <TableBody>
                    <TableRow>
                        <TableCell align="center" padding="none">
                            <span className="title">퇴직금 별도지급</span>
                            <span className="data">{data.option1}%</span>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" padding="none">
                            <span className="title">본인 연말정산</span>
                            <span className="data">{data.option2}%</span>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" padding="none">
                            <span className="title">퇴사 때 세금환급</span>
                            <span className="data">{data.option3}%</span>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }
}

export default S_NonPieContent;