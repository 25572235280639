import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';

const options = [
    '처방건 기준',
    '약물수 기준'
];

class MobileSelectedPreUseOption extends React.PureComponent {

    state = {
        anchorEl: null,
        selectedIndex: 1
    }

    handleClickListItem = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    }

    handleMenuItemClick = (event, index) => {
        this.setState({
            selectedIndex: index,
            anchorEl: null
        })

        const m_sorter = index === 0 ? "prescript" : "use";
        this.props.sortPrescript(m_sorter);
    }

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    render(){
        return (
            <div style={{width: '100%', maxWidth: 360,}}>
                <div className="header-field menu_schtab">
                    <div className="stv-radio-buttons-wrap">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleClickListItem}
                        >
                            {this.props.order === "prescript" ? "처방" : "약물"}
                        </Button>
                    </div>
                </div>
                <Menu
                    id="lock-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    {
                        options.map((option, index) => {
                            const setIndex = this.props.order === "prescript" ? 0 : 1;
                            return (
                                <MenuItem
                                    key={option}
                                    // selected={index === this.state.selectedIndex}
                                    selected={index === setIndex}
                                    onClick={event => this.handleMenuItemClick(event, index)}
                                >
                                    {option}
                                </MenuItem>
                            )
                        })
                    }
                </Menu>
            </div>
        );
    }
}

export default MobileSelectedPreUseOption;