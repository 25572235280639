import React, {useState, useEffect, Fragment} from 'react';
import {useLazyQuery} from "@apollo/react-hooks";

import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';


import { MY_SEARCH_LOG_LIST, MY_SEARCH_LOG_CNT } from "../../queries/location";
import { useStore } from "../../stores/StoreProvider";
import withCodes from "./withCodes";
import Pagination from "./Pagination";
import CommonFunction from "../../common/CommonFunction";
import {observer} from "mobx-react";


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 1000,
    },
});

// function DetailMyHistoryContents(props) {
const DetailMyHistoryContents = observer ((props) => {
    const classes = useStyles();
    const store = useStore().LocationStore;
    const SPC_CODE = props.spcCode;
    const ORG_CODE = props.orgCode;


    const [startIndex, setStartIndex] = useState(0);
    const [data, setData] = useState([]);
    const [totalCnt, setTotalCnt] = useState({
        count: 0
    });

    const handleStartIndex = (nextIndex) => {
        //console.log("nextIndex", nextIndex);
        setStartIndex(nextIndex);
    }

    const handleReSearch = (rowData) => {
        //console.log("rowData", rowData, rowData.search_condition[0].addr, rowData.spc_code,rowData.hos_type_code);
        // 줌레벨 디폴트로 세팅
        //store.setZoomLevel(3);


        /* 도형 관련 데이터 세팅  ... */
        const {search_condition: figureInfo} = rowData;


        for (let i = 0; i < figureInfo.length; i++){
            let figureData = figureInfo[i].rect_info;
            if(figureInfo[i].shape == "POLYGON"){
                figureData = figureInfo[i].polygon_info;
            } else if(figureInfo[i].shape == "CIRCLE"){
                figureData = figureInfo[i].circle_info;
            }
            let figureDataArr = [figureData, figureInfo[i].shape];
            if(i == 0) {
                store.setIsMultiMap(false);
                store.setDrawHistoryFigure1(figureDataArr);
            } else if(i == 1) {
                store.setIsMultiMap(true);
                store.setDrawHistoryFigure2(figureDataArr);
            }
        }
        /* 도형 관련 데이터 세팅 ////  ... */

        // 주소검색 값 세팅
        store.setSearchAddress(rowData.search_condition[0].addr);
        // 진료과목 값 세팅
        store.setSearchSpcArr(rowData.spc_code);
        // 진료타입 값 세팅
        store.setSearchSpcType(rowData.spc_type);
        // 기관유형 값 세팅
        store.setSearchOrgArr(rowData.hos_type_code);

        //히스토리 로딩 플래그 true
        store.setHistoryLoading(true);

        props.handleOpen(false);

    }

    const [mySearchLogCnt, {
        loading: loadingMySearchLogCnt, error: errorMySearchLogCnt, data: dataMySearchLogCnt
    }] = useLazyQuery(MY_SEARCH_LOG_CNT,
        {
            variables: {
                u_id: store.userInfo.u_id,
            },
            onCompleted(){
                setTotalCnt(dataMySearchLogCnt.getSearchLogCnt)
            },
            fetchPolicy: "network-only"
        }
    );

    const [mySearchLogList, {
        loading: loadingMySearchLogList, error: errorMySearchLogList, data: dataMySearchLogList
    }] = useLazyQuery(MY_SEARCH_LOG_LIST, {
        variables: {
            u_id: store.userInfo.u_id,
            start_index: startIndex * 10,
            row_count: 10
        },
        onCompleted(){
            //console.log("complete", dataMySearchLogList.getSearchLogList, data)
            setData((oldArray) => [...oldArray, ...dataMySearchLogList.getSearchLogList]);
        },
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        if(!loadingMySearchLogCnt){
            if(dataMySearchLogCnt > 0){
                mySearchLogList();
            }
        }
    }, [loadingMySearchLogCnt, dataMySearchLogCnt]);

    useEffect(() => {
        mySearchLogList();
    }, [startIndex]);

    useEffect(() => {
        // //console.log("dataMySearchLogList.getSearchLogList", dataMySearchLogList && dataMySearchLogList.getSearchLogList)
        mySearchLogCnt();
        //console.log("data ddddddd", data)
    }, [data])


    const historyAddrList = (addrData) => {
        let result = [];
        addrData.forEach((item,index)=>{
            let addrComponent = <div>{index+1}지역 : {item.addr}</div>;
            if(addrData.length == 1) {
                addrComponent = <div>{item.addr}</div>;
            }
            result.push(addrComponent);
        });
        return result;
    }

    return (
        <Fragment>
            <Grid container className="datainfowrap">
                <Grid item container direction={"row"} spacing={2}>
                    <Grid item style={{margin:"0 auto",width:"85%",paddingTop:"40px",background:"#Fff"}} className="infowrap table type2">
                        <Grid container className="title">
                            <Typography component="div">조회 이력 보기<span>최근 3개월 이내 조회 데이터만 다시 보실 수 있습니다.</span></Typography>
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow className="title-tr">
                                        <StyledTableCell>No.</StyledTableCell>
                                        <StyledTableCell align="right">조회일시</StyledTableCell>
                                        <StyledTableCell align="right">조회지역</StyledTableCell>
                                        <StyledTableCell align="right">조회조건</StyledTableCell>
                                        <StyledTableCell align="right"><span className="paddingR">다시분석</span></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.length > 0 ?
                                        data.map((row, index) => {
                                            //console.log("row", row)
                                            return (
                                                <StyledTableRow key={row.index}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {(totalCnt.count - index)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {`${row.reg_ymd.substring(0, 4)}.${row.reg_ymd.substring(4, 6)}.${row.reg_ymd.substring(6, 8)}`}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {/*{row.search_condition[0].addr}*/}
                                                        { historyAddrList(row.search_condition).map((item)=>item) }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {
                                                            row.spc_code.length > 0 &&
                                                            <Fragment>전문과 : </Fragment>
                                                        }
                                                        {
                                                            row.spc_code.map((item, i) => {
                                                                return (
                                                                    (i !== 0 ? ',' : '')
                                                                    +
                                                                    (CommonFunction.getCodeName(item, SPC_CODE))
                                                                )
                                                            })
                                                        }
                                                        {
                                                            row.hos_type_code.length > 0 &&
                                                            <Fragment> / 의료기관 : </Fragment>
                                                        }
                                                        {
                                                            row.hos_type_code.map((item, i) => {
                                                                return (
                                                                    (i !== 0 ? ',' : '')
                                                                    +
                                                                    (CommonFunction.getCodeName(item, ORG_CODE))
                                                                )
                                                            })
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => { handleReSearch(row); CommonFunction.pageview('처방정보', '조회이력 클릭'); }}
                                                        >
                                                            분석하기
                                                        </Button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })
                                        :
                                        <StyledTableRow>
                                            <StyledTableCell component="tr">
                                                조회이력이 없습니다
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {
                            ((startIndex + 1) * 10) < totalCnt.count &&
                            <Button
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleStartIndex((startIndex + 1))}
                            >
                                더보기
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
})

export default withCodes(DetailMyHistoryContents);