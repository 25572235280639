/*global kakao*/
import React, {useEffect} from 'react';
import PopperAddress from "./PopperAddress";
import PopperOrganization from "./PopperOrganization";
import PopperSpecialty from "./PopperSpecialty";
import withPopper from "./withPopper";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "@material-ui/core/Button";
// class MapFilter extends React.PureComponent {
import Box from '@material-ui/core/Box';
import useStyles from "../style/common";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {observer} from "mobx-react";
import {useStore} from "../../stores/StoreProvider";
import {useLazyQuery} from "@apollo/react-hooks";
import {DONG_INFO, GEOCODE} from "../../queries/location";
import withCodes from "./withCodes";
import useLocalStorage from "react-use-localstorage";
import _ from 'lodash';

import {useLocation} from "react-router";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const MapFilter = observer( (props) => {
    let query = useQuery();
    const [queryParsed, setQueryParsed] = React.useState(false);
    const store = useStore().LocationStore;


    //actionstatus에 따른 메시지
    const _statusMessageObj = {
        idle: "이 지역 통계보기 버튼을 눌러보세요!",
        firstFigure: "선택하신 지역의 통계를 확인하실 수 있습니다.",
        secondFigure: "다중입지분석을 선택하면 두 지역을 비교하실 수 있습니다.",
        secondFigureReady: "다시 그리기 클릭시 선택영역이 모두 해제됩니다.",
    };
    const [addr, setAddr] = React.useState(store.searchAddress); //input에 표현될 주소

    /*주소 검색에 필요한 state*/
    const addrInputRef = React.useRef();
    const [addrAnchorEl, setAddrAnchorEl] = React.useState(null);
    const [addrOpen, setAddrOpen] = React.useState(false);
    const [addrPlacement, setAddrPlacement] = React.useState();

    /*전문과 필터에 필요한 state*/
    const [spcAnchorEl, setSpcAnchorEl] = React.useState(null);
    const [spcOpen, setSpcOpen] = React.useState(false);
    const [spcPlacement, setSpcPlacement] = React.useState();
    const [spcList, setSpcList] = React.useState([]);


    /*병원 필터에 필요한 state*/
    const [orgAnchorEl, setOrgAnchorEl] = React.useState(null);
    const [orgOpen, setOrgOpen] = React.useState(false);
    const [orgPlacement, setOrgPlacement] = React.useState();
    const [orgListText, setOrgListText] = React.useState();
    const [orgList, setOrgList] = React.useState([]);


    /**
     * Local Storage Variables
     */
    const [lsAddr, setLsAddr] = useLocalStorage('addr', '');
    const [lsSpcArr, setLsSpcArr] = useLocalStorage('spcArr', '');
    const [lsOrgArr, setLsOrgArr] = useLocalStorage('orgArr', '');
    const [lsAddrHist, setLsAddrHist] = useLocalStorage('addrHist', '[]');

    const tooltipMsg = { //툴팁 메시지
        multimap: "반경/다각/사각 중 그리기 영역 옵션을 선택하고 지도 위에서 2개의 영역을 선택해주세요.", //다중입지
    }
    /////////////

    const handleAllPopperClose = () => { //popper 닫기
        setAddrOpen(false);
        setSpcOpen(false);
        setOrgOpen(false);
    }

    const handleSearchClick = newPlacement => event => { //주소검색창 클릭시 발생하는 이벤트
        addrInputRef.current.focus();
        handleAllPopperClose();
        setAddrAnchorEl(event.currentTarget);
        setAddrOpen(true);
        setAddrPlacement(newPlacement);
    };

    const handleSpcClick = newPlacement => event => { //전문과 필터 클릭시 발생하는 이벤트
        handleAllPopperClose();
        setSpcAnchorEl(event.currentTarget);
        setSpcOpen(true);
        setSpcPlacement(newPlacement);
    };


    const handleSpcList = (spcName,spcCode, isAdd) => {//전문과 항목 클릭시 발생하는 이벤트
        let tempSpcList = [...spcList];
        if(spcName == null && spcCode == null && isAdd == null){
            tempSpcList = [];
        } else {
            if(isAdd){ //add 플래그에 따라서 추가하거나 제거함
                tempSpcList.push({code: spcCode, name:spcName});
            } else {
                tempSpcList = tempSpcList.filter(item => item.code !== spcCode);
            }

        }
        store.setSearchSpcArr(tempSpcList);
    };
    const handleOrgList = (orgName,orgCode, isAdd) => {//병원타입 항목 클릭시 발생하는 이벤트
        let tempOrgList = [...orgList];
        if(orgName == null && orgCode == null && isAdd == null){
            tempOrgList = [];
        } else {
            if(isAdd){ //add 플래그에 따라서 추가하거나 제거함
                tempOrgList.push({code: orgCode, name:orgName});
            } else {
                tempOrgList = tempOrgList.filter(item => item.code !== orgCode);
            }
        }
        setOrgList(tempOrgList);
    };

    const handleOrgClick = newPlacement => event => {//병원타입 항목 클릭시 발생하는 이벤트
        handleAllPopperClose();
        setOrgAnchorEl(event.currentTarget);
        setOrgOpen(true);
        setOrgPlacement(newPlacement);
    };

    const toggleAddrPopper = (e) => { //주소 popper 토글
        if(addrOpen){
            e.stopPropagation(); //중요) 닫기버튼 클릭시 이벤트 버블링 방지
            let isPopperContain = document.getElementById("popperAddress").contains(e.target);
            if(!isPopperContain || e.target.name == "closePopper") {
                setAddrOpen(false);
            }
        }
    };
    const toggleSpcPopper = (e) => { //전문과 popper 토글
        if(spcOpen){
            e.stopPropagation(); //중요) 닫기버튼 클릭시 이벤트 버블링 방지
            let isPopperContain = document.getElementById("popperSpecialty").contains(e.target);
            if(!isPopperContain || e.target.name == "closePopper"){
                setSpcOpen(false);
            }
        }
    };
    const toggleOrgPopper = (e) => { //병원타입 popper 토글
        if(orgOpen){
            e.stopPropagation(); //중요) 닫기버튼 클릭시 이벤트 버블링 방지
            let isPopperContain = document.getElementById("popperOrganization").contains(e.target);
            if(!isPopperContain || e.target.name == "closePopper"){
                setOrgOpen(false);
            }
        }
    };

    const spcTextComponent = ()=>{ //전문과 항목 코드에 따른 텍스트 띄워주는 부분
        let spcList = [...store.searchSpcArr];
        let innerText = "진료과목 선택";
        if(spcList.length > 0) {
            let seperator = spcList.length==1 ? "" : ", ";
            innerText = "";
            innerText = spcList.map((item, index) => {
                    let selectedSpc = props.spcCode.find(spc => spc.code == item);
                    let spcString = "";
                    if(selectedSpc != undefined){
                        if(index == (spcList.length-1)) seperator = "";
                        spcString = selectedSpc.name + seperator;
                    }
                    return spcString;
                }
            )
        }

        return <Typography noWrap>{innerText}</Typography>
    }
    const orgTextComponent = ()=>{//병원타입 항목 코드에 따른 텍스트 띄워주는 부분
        let orgList = [...store.searchOrgArr];
        let innerText = "기관유형 선택";
        if(orgList.length > 0) {
            let seperator = orgList.length==1 ? "" : ", ";
            innerText = "";
            innerText = orgList.map((item, index) => {
                    let selectedOrg = props.orgCode.find(org => org.code == item);
                    let orgString = "";
                    if(selectedOrg){
                        if(index == (orgList.length-1)) seperator = "";
                        orgString = selectedOrg.name + seperator;
                    }
                    return orgString;
                }
            )
        }

        return <Typography noWrap>{innerText}</Typography>
    }

    const statusMessage = () => { //actionstatus에 따라 msg 바꿔줌
        let message = _statusMessageObj.idle;

        if(store.actionStatus == 0){

        } else if(store.actionStatus == 1){
            message = _statusMessageObj.firstFigure;
        } else if(store.actionStatus == 2 ){
            message = _statusMessageObj.secondFigure;
        } else if(store.actionStatus == 3  ){
            message = _statusMessageObj.secondFigureReady;
        } else if(store.actionStatus == 4){
            message = _statusMessageObj.secondFigureReady;
        } else {

        }

        return message;
    }

    useEffect(()=>{
    },[]);
    useEffect(() => { //병원타입 항목 텏흐트 관리해줌

        let orgListTextTemp = "";
        let orgArr = orgList.map((item, index)=>{
            return (item.code);
        });

        if(orgList.length == 7) {
            orgListTextTemp = "기관유형 - 전체";

        } else if(orgList.length == 0 ) {
            orgListTextTemp = "기관유형 선택";
        }


        setOrgListText(orgListTextTemp);
        store.setSearchOrgArr(orgArr);

        return () => {
        };
    }, [orgList]);

    useEffect(() => {
        let spcArr = spcList.map((item, index)=>{
            return (item);
        });

        return () => {
        };
    }, [spcList]);


    useEffect(() => { //store의 searchAddress가 변경되면 localstorage에 저장하고, 해당 값으로 geocode 가져옴
        if(store.searchAddress != "" && !geoLoading) {
            getGeocode();

            if (store.searchAddress != lsAddr && store.searchAddress != "" && !store.hasLeaseAddr) {
                setLsAddr(store.searchAddress);
            }
        }
        return () => {
        };
    }, [store.searchAddress]);
    useEffect(() => { //
        //console.log("mapfilter store.searchAddress == store.leaseAddr",store.searchAddress,store.leaseAddr);
        if(store.searchAddress == store.leaseAddr) {
            // addrInputRef.current.value = "";
            setAddr("")
        }
        return () => {
        };
    }, [store.searchAddress, store.leaseAddr, store.hasLeaseAddr, store.hospitalMarkerList]);

    useEffect(()=>{ //query parameter가 있따면 주소로 대입해줌
       if(query.get("address") != null && !queryParsed) {
            store.setSearchAddress(query.get("address"));
            setQueryParsed(true);

        }
    },[query]);

    ///USEEFFECTs - LOCAL STORAGE VARIABLE
    useEffect(()=>{
        if(store.searchAddress != lsAddr && store.searchAddress != ""  && lsAddr != "" && !store.isLogin) {
            store.setSearchAddress(lsAddr);
        }
    },[lsAddr]);

    useEffect(()=>{ //local storage spc 리스트를 가져와서 searchSpcArr과 spcList에 넣어줌
        let lsSpcList = [];
        if(lsSpcArr != null && lsSpcArr.length > 0 && !store.isLogin) {
            if(lsSpcArr[0] != "" && lsSpcArr[0] != undefined) {
                lsSpcList = lsSpcArr.split(",");

                let isSameList = store.searchSpcArr.length === lsSpcList.length && lsSpcList.every((val, i)=> val === store.searchSpcArr[i]);

                if(!isSameList) {
                    store.setSearchSpcArr(lsSpcList);

                } else {

                }
            }
        }

    },[lsSpcArr]);
    useEffect(()=>{ //searchSpcArr 변경시 local storage spcList에 넣어줌
        let spcList = [...store.searchSpcArr];
        if(spcList[0] != "" && spcList[0] != undefined) {
            let spcArrText = spcList.join(",");

            setLsSpcArr(spcArrText);
        }
    },[store.searchSpcArr]);
    useEffect(()=>{ //병원타입 localstorage에서 불러옴
        let lsOrgList = [];
        if(lsOrgArr != null && lsOrgArr.length > 0 && !store.isLogin) {
            if(lsOrgArr[0] != "" && lsOrgArr[0] != undefined) {
                lsOrgList = lsOrgArr.split(",");
                let isSameList = store.searchOrgArr.length === lsOrgList.length && lsOrgList.every((val, i)=> val === store.searchOrgArr[i]);

                if(!isSameList) {
                    store.setSearchOrgArr(lsOrgList);
                } else {
                }
            }
        }
    },[lsOrgArr]);
    useEffect(()=>{ //searchOrgArr 변경시 local storage spcList에 넣어줌
        let orgList = [...store.searchOrgArr];
        let orgArrText = orgList.join(",");

        setLsOrgArr(orgArrText);
    },[store.searchOrgArr]);
    ///USEEFFECTs - LOCAL STORAGE VARIABLE//

    const [getGeocode, {loading:geoLoading, error:geoError, data:geoData}] = useLazyQuery(GEOCODE,
        {
            onCompleted:(geodata)=>{
                    if(geoData.getGeocode == null && addrInputRef.current.value != "") { alert("주소가 올바르지 않습니다."); setAddr("");/*주소 입력창 초기화*/ addrInputRef.current.focus(); }
                    else {
                        store.setCenterPosArr([geoData.getGeocode.pos_y, geoData.getGeocode.pos_x]);
                        // store.setSearchAddress(geoData.getGeocode.addr); //searchaddress 중복처리 제거
                        getLocationInfo({variables: {pos_x: geoData.getGeocode.pos_x, pos_y: geoData.getGeocode.pos_y } });

                        if(addr == "" || addr !== store.searchAddress){
                            //좌표데이터가 바꼈는데 주소표기가 공백이거나
                            // 검색 주소지와 표기 주소지가 다르면
                            // 표기주소지를 해당 주소로 변경 처리해줌
                            setAddr(store.searchAddress);
                        }

                        //Add Search Address History
                        let lsAddrHistJson = JSON.parse(lsAddrHist);
                        lsAddrHistJson.unshift(store.searchAddress);
                        setLsAddrHist(JSON.stringify(_.uniq(lsAddrHistJson)));
                    }
                },
            notifyOnNetworkStatusChange:true,
            fetchPolicy: 'network-only',
            variables: {addr: store.searchAddress }
        }); //GEOCODE (위경도) 조회
    const [getLocationInfo, {loading:locationLoading, error:locationError, data:locationData}] = useLazyQuery(DONG_INFO); //위경도 기반 지역정보 조회 (DONG_INFO)

    const classes = useStyles();

    function handleAddrChange(e) { //주소검색 input change 이벤트
        if(/[~!@#$%^&*()_+|<>?:{}]/.test(e.target.value)) { alert("특수문자는 입력하실 수 없습니다."); setAddr("");/*주소 입력창 초기화*/ addrInputRef.current.focus(); return;} // 특수문자 입력 시 초기화 하고 return;
        else{setAddr(e.target.value);}
    }

    function updateAddr(inputAddr) { //검색할 주소가 변경될때
        if(inputAddr === ""){
            alert('주소지 정보를 입력해 주세요.');
        }
        else{
            if(addr !== inputAddr){ //현재 입력된 주소와 입력할 주소를 비교하여
                setAddr(inputAddr); //다르다면 input 주소를 바꿔주고
            } else { //같다면
                getGeocode(); //geocode를 얻어와서 맵이동만 해줌
            }
            if(inputAddr != store.setSearchAddress){
                store.setSearchAddress(inputAddr);
            }
        }
    }

    function handleAddrKeyPress(e){
        if(e.key === "Enter") { //주소검색 enter 이벤트
            if(!geoLoading){
                updateAddr(e.target.value);
                handleAllPopperClose();
            }
        }
    }

    React.useEffect(()=>{
    },[geoData, geoLoading]);

    React.useEffect(()=>{
        if(!locationLoading && locationData != null){
            if(locationData.getDongInfo != null){
                store.setLocationInfo(locationData.getDongInfo);
            }
        }
    },[locationData, locationLoading]);
    React.useEffect(()=>{
    },[addr]);

    const handleDrawType = (event, newDrawType) => {
        if(newDrawType != store.drawType) { //그리기모드 정책변경으로 동일한 버튼 누를시 아무것도 안하도록 처리
            store.setDrawType(newDrawType);
        }
    };


    const handleRemoveAddrHist = (val) => {
        const lsAddrHistJson = JSON.parse(lsAddrHist);
        if(lsAddrHistJson.length > 0){
            const values = _.remove(lsAddrHistJson, function(item) {
                return item != val;
            });
            setLsAddrHist(JSON.stringify(_.uniq(values)));
        }
    }

    return (
        <Box display="flex" >
            <Grid container direction={"column"}>
                <Grid container item style={{background:"#fff"}}>
                    <Grid item>
                        <ClickAwayListener onClickAway={toggleAddrPopper}>
                            <Box p={2} border={1} borderTop={0} borderLeft={0} borderBottom={0} borderColor="grey.500" width={"20vw"} className="input_adr">

                                {withPopper(PopperAddress)({anchorEl: addrAnchorEl, open: addrOpen, placement: addrPlacement, content:JSON.parse(lsAddrHist), removeAddrHist: handleRemoveAddrHist},()=>{}, toggleAddrPopper)}
                                <Grid container direction={"row"} alignItems="center">
                                    <Grid item style={{flexGrow:1}} onClick={handleSearchClick('bottom-start')}>
                                        <InputBase
                                            placeholder="주소를 입력하세요"
                                            inputProps={{ 'aria-label': '주소를 입력하세요' }}
                                            inputRef = {addrInputRef}
                                            fullWidth={true}
                                            margin={"dense"}
                                            value={addr}
                                            onChange={handleAddrChange}
                                            onKeyPress={handleAddrKeyPress}
                                            className="inputarea"
                                        />
                                    </Grid>
                                    <Grid item>
                                        {!geoLoading &&
                                        <IconButton aria-label="search" color="primary.text" onClick = {()=>{ updateAddr(addr); }}>
                                            <SearchIcon />
                                        </IconButton>
                                        }
                                        {geoLoading &&
                                        <IconButton color="primary.text">
                                            <HourglassEmptyIcon />
                                        </IconButton>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </ClickAwayListener>
                    </Grid>
                    <Grid item xs={1.5}>
                        <ClickAwayListener onClickAway={toggleSpcPopper}>
                            <Box display="flex" pt={2} pl={2} style={{alignContent:"center"}} onClick={handleSpcClick('bottom-start')} className="opt_combo">
                                { withPopper(withCodes(PopperSpecialty))({anchorEl: spcAnchorEl, open: spcOpen, placement: spcPlacement, content: spcList, styles:{width:'45vw', padding:'1vw'} }, handleSpcList, toggleSpcPopper ) }
                                <Paper style={{padding:"0.5vw", minWidth:"15vw", maxWidth:"18vw", cursor:"pointer"}} >
                                    <Grid container direction={"row"} wrap="nowrap">
                                        <Grid item style={{flexGrow:1}} zeroMinWidth>
                                            {spcTextComponent()}
                                        </Grid>
                                        <Grid item style={{flexGrow:0}}>
                                            <ExpandMoreIcon/>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                        </ClickAwayListener>
                    </Grid>
                    <Grid item xs={1.5}>
                        <ClickAwayListener onClickAway={toggleOrgPopper}>
                            <Box display="flex" pt={2} pl={2} style={{alignContent:"center"}} onClick={handleOrgClick('bottom-start')} className="opt_combo">
                                { withPopper(withCodes(PopperOrganization))({anchorEl: orgAnchorEl, open: orgOpen, placement: orgPlacement, content:orgList, styles:{width:'28vw', padding:'1vw'} }, handleOrgList, toggleOrgPopper ) }
                                <Paper style={{padding:"0.5vw", minWidth:"15vw", maxWidth:"18vw", cursor:"pointer"}} >
                                    <Grid container direction={"row"} wrap="nowrap">
                                        <Grid item style={{flexGrow:1}} zeroMinWidth>
                                            {orgTextComponent()}
                                        </Grid>
                                        <Grid item style={{flexGrow:0}}>
                                            <ExpandMoreIcon/>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                        </ClickAwayListener>
                    </Grid>

                    <Grid item xs={1.5}>
                        <Box p={3} className="opt2_switch">
                            <ToggleButtonGroup
                                exclusive
                                onChange={handleDrawType}
                                value={store.drawType}
                                size="small"
                            >
                                <ToggleButton value="CIRCLE">
                                    반경
                                </ToggleButton>
                                <ToggleButton value="RECTANGLE">
                                    사각형
                                </ToggleButton>
                                <ToggleButton value="POLYGON">
                                    다각형
                                </ToggleButton>
                               {/* <ToggleButton value="ROADVIEW">
                                    로드뷰
                                </ToggleButton>*/}
                            </ToggleButtonGroup>
                            <Tooltip title={<React.Fragment><Typography>{tooltipMsg.multimap}</Typography></React.Fragment>} arrow leaveDelay={500} className="multimap">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={store.isMultiMap}
                                            onChange={(e)=> {
                                                store.setIsMultiMap(!store.isMultiMap)
                                            }}
                                            name="multimapCheck"
                                            color="primary"
                                        />
                                    }
                                    label="다중입지분석"
                                />
                            </Tooltip>
                            { store.drawFigure1 != null &&
                            <Button className="btnreset" onClick={ (e)=>{store.setDrawType("clear")} }>
                                다시그리기
                            </Button>
                            }
                        </Box>
                    </Grid>
                    {/*20200521 PopperPopular 있던곳. Header로 옮김.*/}
                </Grid>
                <Grid item className="txtnotice">
                    <Typography>
                        {statusMessage()}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
})

export default MapFilter;
