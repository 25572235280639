import React, { Component } from 'react';
import Chart from "react-google-charts";

class S_PercentAreaChart extends Component {
    render() {
        const { data, min, max } = this.props;
        return (
            <div className="App">
                <Chart
                    chartType="AreaChart"
                    width="100%"
                    height="220px"
                    data={data}
                    options={{
                        isStacked: "percent",
                        legend: { position: 'top' },
                        annotations: {
                            textStyle: {
                                fontName:'verdana',
                                fontSize: 11,
                                bold: true,
                                color: '#000',
                                opacity: 0.8,
                        }},
                        backgroundColor:'none',
                        fontName:'Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana',letterSpacing:'-1px',fontSize:'12px',
                        hAxis: {textStyle: {fontSize: 12}},
                        vAxis: {
                            textStyle: {
                                fontName:'verdana,Arial,Noto Sans KR,Dotum',
                                fontSize: 10,color:'#999'
                            },
                            viewWindow:{min:min, max:max}
                        },
                        chartArea: {width:'93%'},
                        colors: ['#0050ff','#ccc', '#aAb34a'],
                        pointSize: 5
                    }}
                />
            </div>
        )
    }
}

export default S_PercentAreaChart