import React, { Fragment } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

class FilterPlace extends React.PureComponent {
    onChangePlace = (place) => {
        this.props.onChangeFilterPlace(place);
    }

    render() {
        const { filter_combination, filter_place } = this.props;
        let combination_flag = false;
        if(filter_combination === "2combi" || filter_combination === "3combi"){
            combination_flag = true;
        }

        return (
            <div className="header-field menu_schtab">
                <div className="stv-radio-buttons-wrap">
                    <input
                        type="radio"
                        className="stv-radio-button type2"
                        name="place"
                        value="OUT"
                        id="place_out"
                        defaultChecked={filter_place === "OUT"}
                        onChange={() => this.onChangePlace("OUT")}
                    />
                    <label htmlFor="place_out">외래 처방</label>

                    <input
                        type="radio"
                        className="stv-radio-button type1"
                        name="place"
                        value="IN"
                        id="place_in"
                        defaultChecked={filter_place === "IN"}
                        disabled={combination_flag}
                        onChange={() => this.onChangePlace("IN")}
                    />
                    <Tooltip title={combination_flag ? "입원처방 옵션에선 병합요법을 지원하지 않습니다" : ""} placement="bottom-start">
                        <label htmlFor="place_in">입원 처방</label>
                    </Tooltip>
                </div>
            </div>
        )
    }
};

export default FilterPlace;