import React, { Fragment } from 'react';
import _ from "lodash";
import $ from 'jquery';
import ShowMoreText from 'react-show-more-text';
import Moment from 'react-moment';
import linkifyHtml from 'linkifyjs/html';
import Helpful from './Helpful';
import MoreBtn from './MoreBtn';
import ImagePreview from './ImagePreview';
import KCD from './KCD';
import CommonFunc from '../../../common/CommonFunction';

class CommentBody extends React.PureComponent {

    componentDidMount = () => {
        /*
        $( "#container").scroll(function() {
            //console.log('yyyyyyyyyyyyyyyy')
        });
        */
    }

    render() {
        const { user, item, refetch} = this.props;
        const kcd_list_of_comment = (!_.isEmpty(item.kcd_list) ? item.kcd_list.split('#') : []).map(obj => {
            return { kcd_code: obj.split(':')[0], kcd_kor: obj.split(':')[1] };
        });
        const img_arr = !_.isEmpty(item.img_list) ? item.img_list.split('#') : [];
        //console.log('item', item);

		return (
			<div className="info">
                <div className="info_wrap">
                    {
                        user && user.id === item.u_id &&
                        <MoreBtn
                            comment_idx={item.comment_idx}
                            commentRemove={()=>this.props.commentRemove(item.comment_idx, refetch)}
                            commentEditForm={()=>this.props.commentEditForm(item, kcd_list_of_comment, img_arr)}
                            from={this.props.from}
                        />
                    }
                    <div className="info_box aa">
                        {
                            item.u_type === '1' ?
                                <div className="info_author">
                                    { item.u_pic ?
                                        <div className="thumb_author" style={{background: `url(${CommonFunc.getImageDomain()}${item.u_pic}) center center / cover no-repeat`}}/>
                                    :
                                        <div className="thumb_author none"/>
                                    }
                                    <div className="name_author">
                                        <p className="name">{item.u_name}</p>
                                        <p className="specialty"><span>{item.u_spc_name}</span></p>
                                        <p className="invite"><span>{item.u_wtp_name}</span></p>
                                    </div>
                                </div>
                            : item.u_type === '2' ?
                                <div className="info_author type2">
                                    <div className="thumb_author"><span>{item.u_name}</span></div>
                                    <div className="name_author">{item.u_name}</div>
                                </div>
                            : item.u_type === '3' ?
                                <div className="info_author">
                                    <div className="thumb_author"></div>
                                    <div className="name_author">익명</div>
                                </div>
                            : ''
                        }
                        <div className="desc">
                            <ShowMoreText
                                lines={2}
                                more='내용 전체보기'
                                less=''
                                anchorclassName='see_more_link_inner'>
                                <p className="text" dangerouslySetInnerHTML={{ __html: CommonFunc.enter2Br(linkifyHtml(item.content)) }} />
                            </ShowMoreText>
                        </div>
                    </div>
                    {
                        img_arr.length > 0 &&
                        <ImagePreview images={img_arr}/>
                    }

                    <KCD kcd_list_of_comment={kcd_list_of_comment}/>

                    <div className="info_etc">
                        <div className="wrap_checkbox">
                            <Helpful
                                item={item}
                            />
                            <div className="etc date"><Moment date={item.reg_date} format="YYYY.MM.DD" /></div>
                        </div>
                    </div>

                </div>
            </div>
		);
	}
}

export default CommentBody;

