import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import useStyles from './SalaryStyle';

class S_BoxNoContent extends Component {
    render() {
        const { dataType } = this.props;
        // const { classes } = this.props;
        return (
            <div className="nodata">
                {
                    dataType[1] !== "null" &&
                    <Typography component="div" variant="subtitle1" color="primary" class="txtnotice type1">
                        <span>근무처 정보가 없는 사용자의</span>
                        <span>{dataType[0] === "ZON" ? "지역" : "병원타입"}별 통계는</span>
                        <span>노출되지 않습니다.</span>
                    </Typography>
                }
            </div>
        );
    }
}

export default withStyles(useStyles)(S_BoxNoContent);
