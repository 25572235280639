import React, { Fragment } from 'react';
import { isMobileOnly } from 'react-device-detect';
import $ from "jquery";
import SearchFragment from './SearchFragment';
import TitleMenu from '../common/TitleMenu';
import UserProfile from '../layer/UserProfileLayer';
import Tutorial from '../layer/Tutorial';
import CommonFunc from '../../../common/CommonFunction';
import Footer from './Footer';
import LeftMenuBar from './LeftMenuBar';
//import Toastr from '../../common/Toastr';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ServicePolicy from "../../common/ServicePolicy";
import {inject, observer} from "mobx-react";
import {withCookies} from "react-cookie";
import {withRouter} from "react-router";

@inject("salaryStore")
@observer
class Home extends React.PureComponent {

	state = {
		user: null,
		policyAgreed: 'n',
		policySessionAgreed: sessionStorage.getItem("pPolicySession"),
	}

	onSearched = (keyword, kcd_list) => {
		$('html, body').animate({ scrollTop: 0 }, 0);
		const params = {
			'keyword': keyword,
			'kcd_list': kcd_list
		}
		this.props.history.push("/prescription", params);
	}

	changePolicyCookie = () => {
		const { salaryStore } = this.props;
		salaryStore.setPrescriptionServiceAgreeFlag(true)

		this.setState({policySessionAgreed:'y',});
		sessionStorage.setItem("pPolicySession", 'y');
	}
	componentDidMount = () => {
		CommonFunc.pageview('처방정보', '메인'); //Google Analytics
		/*
		//console.log('user', CommonFunc.getUserId(), CommonFunc.getToken())
		const userProfile = await CommonFunc.getUserProfile(CommonFunc.getUserId(), CommonFunc.getToken());
		//console.log('userProfile', userProfile);
		*/
		const { cookies } = this.props;
		// 이용동의 쿠키체크
		// let policyCookie = CommonFunction.getCookieValue("mSpcV");
/*		let policyCookie = cookies.get("mPpcV");
		if(policyCookie == "Y"){
			this.changePolicyCookie();
		}*/

		this.setState({
			policyAgreed:localStorage.getItem('pPolicyChecked'),
		})

	}

	render() {
		const { salaryStore } = this.props;
		const { policyAgreed, policySessionAgreed } = this.state;
		return (
			<Fragment>
				{
					policySessionAgreed == 'y' || policyAgreed == 'y' ?
					<Fragment>
						<div id="header" className="header">
							<LeftMenuBar/>
							<TitleMenu/>
							<UserProfile/>
						</div>
						<SearchFragment
							onSearched={this.onSearched}
							searchForm="HOME"
						/>
						<Tutorial/>
						<Footer/>
					</Fragment>
						:
					<ServicePolicy cookieName={"mPpcV"} handleFunction={this.changePolicyCookie} type={"p"}/>
				}
			</Fragment>
		);
	}
}

export default withCookies(withRouter(Home));

