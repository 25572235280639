import React, {useEffect, useState, useContext} from 'react';
import {MapContext} from "./MapContainer";
import withPopper from "./withPopper";
import MuiSelect from "./MuiSelect";

import CssBaseline from "@material-ui/core/CssBaseline";

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CheckIcon from '@material-ui/icons/Check';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import { spacing } from '@material-ui/system';

import {RadioGroup} from "@material-ui/core";
import {Radio} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {useStore} from "../../stores/StoreProvider";
import _ from "lodash";
import {MG_SPC_CODE_MAP} from "../../common/constants";

function PopperSpecialty(props) {
    const store = useStore().LocationStore;

    //console.log("##popperspc?????",props);
    // const {addrHistory} = content;

    const { content, styles, onAction, close } = props;
    // const { content, styles } = props;
    //console.log("##popperspc?????",content, styles, onAction, typeof onAction);

    const [spcTypeValue, setSpcTypeValue] = useState(store.searchSpcType);

    const maxSelectedSpcCount = 5;
    const [selectedSpcCount, setSelectedSpcCount] = useState(0);
    const [specialtyList, setSpecialtyList]  = useState([]);
    const defaultClicked = false;

    /*
    const [specialtyList, setSpecialtyList]  = useState([
        {code:"00", values : {clicked: false, name: "일반의"}},
        {code:"23", values : {clicked: false, name: "가정의학과"}},
        {code:"20", values : {clicked: false, name: "결핵과"}},
        {code:"01", values : {clicked: false, name: "내과"}},
        {code:"09", values : {clicked: false, name: "마취통증의학과"}},
        {code:"17", values : {clicked: false, name: "방사선종양학과"}},
        {code:"18", values : {clicked: false, name: "병리과"}},
        {code:"15", values : {clicked: false, name: "비뇨의학과"}},
        {code:"10", values : {clicked: false, name: "산부인과"}},
        {code:"08", values : {clicked: false, name: "성형외과"}},
        {code:"11", values : {clicked: false, name: "소아청소년과"}},
        {code:"02", values : {clicked: false, name: "신경과"}},
        {code:"06", values : {clicked: false, name: "신경외과"}},
        {code:"24", values : {clicked: false, name: "응급의학과"}},
        {code:"12", values : {clicked: false, name: "안과"}},
        {code:"16", values : {clicked: false, name: "영상의학과"}},
        {code:"26", values : {clicked: false, name: "예방의학과"}},
        {code:"04", values : {clicked: false, name: "외과"}},
        {code:"13", values : {clicked: false, name: "이비인후과"}},
        {code:"19", values : {clicked: false, name: "진단검사의학과"}},
        {code:"03", values : {clicked: false, name: "정신건강의학과"}},
        {code:"05", values : {clicked: false, name: "정형외과"}},
        {code:"21", values : {clicked: false, name: "재활의학과"}},
        {code:"25", values : {clicked: false, name: "직업환경의학과"}},
        {code:"14", values : {clicked: false, name: "피부과"}},
        {code:"22", values : {clicked: false, name: "핵의학과"}},
        {code:"07", values : {clicked: false, name: "흉부외과"}},
    ]);*/

    const initialList = () => {
        let list = [
            {code:"00", values : {clicked: defaultClicked, name: "일반의"}},
            {code:"23", values : {clicked: defaultClicked, name: "가정의학과"}},
            {code:"20", values : {clicked: defaultClicked, name: "결핵과"}},
            {code:"01", values : {clicked: defaultClicked, name: "내과"}},
            {code:"09", values : {clicked: defaultClicked, name: "마취통증의학과"}},
            {code:"17", values : {clicked: defaultClicked, name: "방사선종양학과"}},
            {code:"18", values : {clicked: defaultClicked, name: "병리과"}},
            {code:"15", values : {clicked: defaultClicked, name: "비뇨의학과"}},
            {code:"10", values : {clicked: defaultClicked, name: "산부인과"}},
            {code:"08", values : {clicked: defaultClicked, name: "성형외과"}},
            {code:"11", values : {clicked: defaultClicked, name: "소아청소년과"}},
            {code:"02", values : {clicked: defaultClicked, name: "신경과"}},
            {code:"06", values : {clicked: defaultClicked, name: "신경외과"}},
            {code:"24", values : {clicked: defaultClicked, name: "응급의학과"}},
            {code:"12", values : {clicked: defaultClicked, name: "안과"}},
            {code:"16", values : {clicked: defaultClicked, name: "영상의학과"}},
            {code:"26", values : {clicked: defaultClicked, name: "예방의학과"}},
            {code:"04", values : {clicked: defaultClicked, name: "외과"}},
            {code:"13", values : {clicked: defaultClicked, name: "이비인후과"}},
            {code:"19", values : {clicked: defaultClicked, name: "진단검사의학과"}},
            {code:"03", values : {clicked: defaultClicked, name: "정신건강의학과"}},
            {code:"05", values : {clicked: defaultClicked, name: "정형외과"}},
            {code:"21", values : {clicked: defaultClicked, name: "재활의학과"}},
            {code:"25", values : {clicked: defaultClicked, name: "직업환경의학과"}},
            {code:"14", values : {clicked: defaultClicked, name: "피부과"}},
            {code:"22", values : {clicked: defaultClicked, name: "핵의학과"}},
            {code:"07", values : {clicked: defaultClicked, name: "흉부외과"}},
        ];

        list = props.spcCode;

        if(store.searchSpcArr.length > 0){ //searchSpcArr이 존재할때
            list.map( (item, index)=>{
                let result = item;

                result = store.searchSpcArr.map((clickedSpc, index)=>{ //searchSpcArr과 비교하여 같은 코드라면 체크해줌
                    let cResult = result;
                    if(result.code == clickedSpc) {
                        cResult = result.clicked = !defaultClicked;
                    }
                    return cResult
                });

                return result;
            });
        }
/*

        if(content != null){ //content : 기존 맵필터에 적용되어있는 리스트
            list.map( (item, index)=>{
                let result = item;

                result = content.map((contentItem, contentIndex)=>{ //content와 비교하여 같은 코드라면 체크해줌
                    let cResult = result;
                    if(result.code == contentItem.code) {
                        cResult = result.clicked = !defaultClicked;
                    }
                    return cResult
                });

                return result;
            });
        }
*/

    return list;
};
// const [specialtyList, setSpecialtyList]  = useState([]);
    /*
    const specialtyList  = [
        {clicked: false, name:"일반의", value:""},
        {clicked: false, name:"가정의학과", value:""},
        {clicked: false, name:"결핵과", value:""},
        {clicked: false, name:"내과", value:""},
        {clicked: false, name:"마취통증의학과", value:""},
        {clicked: false, name:"방사선종양학과", value:""},
        {clicked: false, name:"병리과", value:""},
        {clicked: false, name:"비뇨의학과", value:""},
        {clicked: false, name:"산부인과", value:""},
        {clicked: false, name:"성형외과", value:""},
        {clicked: false, name:"소아청소년과", value:""},
        {clicked: false, name:"신경과", value:""},
        {clicked: false, name:"신경외과", value:""},
        {clicked: false, name:"응급의학과", value:""},
        {clicked: false, name:"안과", value:""},
        {clicked: false, name:"영상의학과", value:""},
        {clicked: false, name:"예방의학과", value:""},
        {clicked: false, name:"외과", value:""},
        {clicked: false, name:"이비인후과", value:""},
        {clicked: false, name:"진단검사의학과", value:""},
        {clicked: false, name:"정신건강의학과", value:""},
        {clicked: false, name:"정형외과", value:""},
        {clicked: false, name:"재활의학과", value:""},
        {clicked: false, name:"직업환경의학과", value:""},
        {clicked: false, name:"피부과", value:""},
        {clicked: false, name:"핵의학과", value:""},
        {clicked: false, name:"흉부외과", value:""},
    ];
*/

    const [chipList, setChipList] = React.useState(null);
/*
    const getChipList = specialtyList.map((item, index)=>{
        let outline = (item.clicked) ? "outlined" : "default";
        return (
            <Grid item>
                <Chip label={item.name} onClick={handleClick} style={{width:"8vw"}} variant={outline}/>
                {/!*<Typography display={"inline"}></Typography>*!/}
            </Grid>
        )
    });
    */
    useEffect(()=>{
        setSpecialtyList([...initialList()]);
    },[]);

    useEffect(()=>{
        store.setSearchSpcType(spcTypeValue);
    },[spcTypeValue]);

    useEffect(()=>{
        //console.log("specialtyList, selectedSpcCount useEffect", specialtyList);
        if(specialtyList.length > 0){
            //console.log("!@!@@specialtyList to chipList??? useEffect", specialtyList);

            let selectedSpcCnt = 0;
            specialtyList.map((item, index)=>{
                if(item.clicked){
                    selectedSpcCnt++
                }
            });

            setSelectedSpcCount(selectedSpcCnt);
        }

        if(selectedSpcCount > maxSelectedSpcCount) {
            //console.log('selectedSpcCount is larger 5', selectedSpcCount);
        }
        // return () => {//console.log("###specialtylist effect cleanup", specialtyList);}
    },[specialtyList, selectedSpcCount]);

    useEffect(()=>{
        //console.log("selectedSpcCnt useEffect", selectedSpcCount);
    },[selectedSpcCount]);

    const handleClick = (code) => {
        //전문과 칩 클릭시 이벤트
        // console.info('popperspecialty You clicked the Chip.',  code, [...store.searchSpcArr]);
        let spcListArray = [...specialtyList];
        let storeSpcListArray = [...store.searchSpcArr];
        //console.info('popperspecialty You clicked the Chip.',  code, spcListArray);

        //todo: 초기화 버튼 작동
        /*
        if(초기화버튼일시){
            초기화동작
        } else {}
        */


        let findIndex = storeSpcListArray.findIndex(spcItem => spcItem == code);
        //console.info('popperspecialty CLICKED INDEX.',  findIndex);
        if(findIndex > -1) {
            storeSpcListArray.splice(findIndex, 1);
          //console.info('popperspecialty CLICKED INDEX checked.',  spcListArray);
            // store.setSearchSpcArr([...storeSpcListArray]);

        } else {
            if(storeSpcListArray.length == 5) {
                //과목 선택 제한 갯수 5개 이하만 가능
                alert("전문과는 5개까지 선택 가능합니다.");
            } else {
                storeSpcListArray.push(code);
                //console.info('popperspecialty CLICKED INDEX not checked. push item', storeSpcListArray, spcListArray);
                // store.setSearchSpcArr([...storeSpcListArray, code]);
            }
        }

        store.setSearchSpcArr([...storeSpcListArray]);

        // store.setSearchSpcArr([...store.searchSpcArr, code]);
        // store.setSearchSpcArr([...store.searchSpcArr]);


    };
/*
    const handleClick = (e,code, key) => {
        //전문과 칩 클릭시 이벤트
        // console.info('You clicked the Chip.', e, code, key);
        let tempChipList = specialtyList;
        if(e == null && code == null && key == null){
            tempChipList = tempChipList.map( (item, index) => {
                item.clicked = false;
                return item;
            });
            //console.log("spc handleclick 초기화" , tempChipList);
            onAction(); //parameter 없이 보내면 초기화

        } else {

            let clickState = tempChipList[key]["clicked"];

            // console.info('You clicked the Chip33333333333333333333333333333.',clickState,  tempChipList[key].values["clicked"], tempChipList,typeof specialtyList,typeof tempChipList);
            if(selectedSpcCount > (maxSelectedSpcCount-1) && !clickState) {
                //과목 선택 제한 갯수 5개 이하만 가능 and 항목 선택 해제시는 체크 안함(clicked true에서 false 갈때는 체크하면안됨)
                alert("전문과는 5개까지 선택 가능합니다.");
            } else {
                onAction(tempChipList[key].name, tempChipList[key].code, !clickState);

                tempChipList[key].clicked = !clickState;

            }
        }
        setSpecialtyList([...tempChipList]); //tempChipList로 set하면 안됨. array object라서 그런듯.
    };*/
    const handleListLoad = (code) => {
        //전문과 리스트 불러올시 이벤트
        // console.info('You clicked the Chip.', e, code, key);
        let tempChipList = specialtyList;

        // onAction(tempChipList[key].name, tempChipList[key].code, !clickState);

        // tempChipList[key].clicked = !clickState;

        setSpecialtyList([...tempChipList]); //tempChipList로 set하면 안됨. array object라서 그런듯.
    };

    useEffect(() => {
        //외부에서 변경시 적용시켜주는 로직. localstorage 로직 추가필요

        //console.log("popperspecialty useeffect store.searchSpcArr ????????????????", store.searchSpcArr, store.searchSpcArr.length);
        let newSpcArr = [...store.searchSpcArr];
        // let newSpcArr = store.searchSpcArr;
        let oldSpcArr = [...specialtyList];
        // if(store.searchSpcArr != lsAddr && store.searchSpcArr.length > 0) {
        //console.log("popperspecialty store.searchSpcArr", newSpcArr, oldSpcArr);
        if (newSpcArr !== oldSpcArr && newSpcArr.length > 0 && oldSpcArr.length > 0) {
            //console.log("popperspecialty store.searchSpcArr IN IF", newSpcArr, oldSpcArr);
            oldSpcArr.map((item, index) => {
                // //console.log("popperspecialty spclist map", item, index);
            });
            // handleClick()
            // setLsAddr(store.searchAddress)
        }

        return () => {
        };
    }, [store.searchSpcArr, specialtyList]);

    const handleInit = () => {
        //setSpcTypeValue('SUBJECT');
        store.setSearchSpcType('SUBJECT');
        if(store.userInfo && store.userInfo.u_spc_code){
            const spcCode = [_.filter(MG_SPC_CODE_MAP, {mg_code: store.userInfo.u_spc_code})[0].code];
            store.setSearchSpcArr(spcCode);
        }
        else{
            store.setSearchSpcArr([]);
        }
    }
    //console.log('spcTypeValue', spcTypeValue)

    return(
        <Paper style={styles} id={"popperSpecialty"} className="popperwrap">
            <Grid container direction="column">
{/*                <Grid item>
                    <Typography paragraph>진료과/전문의 선택</Typography>
                </Grid>*/}
                <Grid container item spacing={2} xs={12} className="conttit">
                    <Grid item xs={11}>
                        <Typography paragraph>진료과/전문의 선택</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="delete" name="closePopper" onClick={(e) => { e.target.name='closePopper'; close(e)}} size={"small"}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid item container direction={"row"} className="radiobtn type1">
                    <Grid item>
                        <FormControl component="div">
                            <RadioGroup row name={"spcType"} value={spcTypeValue} onChange={ (e)=>{ setSpcTypeValue(e.target.value) } }>
                                <FormControlLabel control={<Radio/> } value={"SUBJECT"} label="진료과"/>
                                <FormControlLabel control={<Radio/> } value={"SPECIALTY"} label="전문의"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="popper-opt2">
                {

                    specialtyList.length > 0 &&
                    specialtyList.map((item, index)=>{
                        let returnObj = null;

                        /*if(!(spcTypeValue !== "SPECIALTY" && item.code === "00")){ //진료과&&일반의가 아닐때만 chip 렌더링*/
                            let outline = (!item.clicked) ? "outlined" : "default";
                            returnObj = (<Grid item>
                                            <Chip key={"spcChip"+index} name="spcChip" label={item.name} onClick={(e)=>handleClick(item.code)} style={{width: "8vw"}} variant={outline}/>
                                        </Grid>);
                        /*}*/

                        return returnObj;
                        /*
                        if(spcTypeValue !== "SPECIALTY" && item.code === "00"){
                            return null;
                        }
                        let outline = (item.values.clicked) ? "outlined" : "default";
                        return (
                            <Grid item>
                                <Chip key={index} name="spcChip" label={item.values.name} onClick={(e)=>handleClick(e,item.code, index)} style={{width: "8vw"}} variant={outline}/>
                            </Grid>
                        )
                        */
                    })

                }
                {
                    // chipList
                }
                </Grid>
                <Grid item style={{flexGrow:0}} alignItems={"center"} alignContent={"center"} justify="flex-end" className="btn-type2">
                    <Button onClick={(e)=>handleInit()} >초기화</Button>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PopperSpecialty;
// export default withPopper(PopperAddress);
