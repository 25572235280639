import { observable, action } from 'mobx';
import CommonFunc from "../../common/CommonFunction";

export default class SalaryStore {
    @observable userProfile;        // 사용자정보
    @observable isLogin;            // 로그인여부
    @observable myPay;              // net 급여정보
    @observable myWorkTime;         // 주간근무시간 정보
    @observable mySalaryResult;     // myPay 와 myWorkTime 조건으로 가져온 데이터 /payinfo 와 /worktime에서 사용
    @observable filterOverviewTerm; // /payinfo 와 /worktime 메뉴 이동시 들고가는 조건값
    @observable loginLayerShow;     // LoginLayer 컨트롤 변수
    @observable salaryLogRegDate;   // 최근 salary log 등록 시간
    @observable salaryServiceAgreeFlag;   // 이용확인
    @observable ieBlockerFlag;      // IE Block 변수
    @observable prescriptionServiceAgreeFlag;   // 처방정보이용확인

    // 변수 초기화
    constructor() {
        this.userProfile = {};
        this.isLogin = CommonFunc.getUserId() ? true : false;
        this.myPay = 0;
        this.myWorkTime = 0;
        this.mySalaryResult = [];
        this.filterOverviewTerm = "";
        this.loginLayerShow = false;
        this.salaryLogRegDate = null;
        this.salaryServiceAgreeFlag = false;
        // this.ieBlockerFlag = isIE ? false : true;
        this.ieBlockerFlag = true;
    }

    // 사용자정보 세팅
    @action
    setUserProfile = (userInfo) => {
        this.userProfile = userInfo;
    }

    @action
    setIsLogin = (param) => {
        this.isLogin = param;
    }

    // 급여정보와 주간근무시간 입력시
    @action
    setMyPayInfo = (pay, worktime) => {
        this.myPay = pay;
        this.myWorkTime = worktime;
    }

    // 급여정보와 주간근무시간 입력후 결과 데이터
    @action
    setMySalaryResult = (result, filter) => {
        this.mySalaryResult = result;
        this.filterOverviewTerm = filter;
    }

    // 로그인 레이어 컨트롤
    @action
    setLoginLayerShow = (param) => {
        this.loginLayerShow = !param;
    }

    // 최근 salary log 등록 시간
    @action
    setSalaryLogRegDate = (param) => {
        this.salaryLogRegDate = param;
    }

    @action
    setSalaryServiceAgreeFlag = (param) => {
        this.salaryServiceAgreeFlag = param;
    }

    @action
    setPrescriptionServiceAgreeFlag = (param) => {//처방정보 이용동의
        this.prescriptionServiceAgreeFlag = param;
    }

    @action
    setIeBlockerFlag = (param) => {
        this.ieBlockerFlag = param;
    }
}

// const salaryUserInfo = new SalaryStore();