import React, {Component} from 'react';
import { withCookies } from 'react-cookie';

import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import SalaryGlobalStyle from '../salery/SalaryGlobalStyle';
import SalaryGlobalCss from "../salery/SalaryGlobalStyle";

class IeBlockNotice extends Component {
    serviceOkay = () => {
        const { cookies } = this.props;
        cookies.set("mIecV", "Y");
        this.props.changeIeBlockerCookieActive();
    }

    render() {
        return(
            <div className="blockwrap">
                <SalaryGlobalCss />
                <Paper elevation={0} variant="outlined" square className="wraparea">
                    <Grid container spacing={2} className="blockarea">
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={12}>
                                <Grid item xs className="blockarea-cont">
                                    <Typography gutterBottom variant="subtitle1" className="subtitle">
                                        닥터게이트는 현재 사용하시는 인터넷 익스플로러 브라우저를<br />완벽하게 지원하지 않습니다.
                                    </Typography>
                                    <Grid item xs className="linkcont">
                                        <Typography variant="body2" className="title">
                                            다른 브라우저를 사용해주세요.​
                                        </Typography>
                                        <Typography variant="body2" className="linkarea">
                                            <Link href="https://www.google.com/intl/ko/chrome/" target="_blank" rel="noopener" className="imageicon link_chrome">
                                                크롬<br />다운로드
                                            </Link>
                                            <Link href="https://www.microsoft.com/ko-kr/edge" target="_blank" rel="noopener" className="imageicon link_edge">
                                                EDGE<br />다운로드
                                            </Link>
                                            <Link href="https://whale.naver.com/ko/download?=main" target="_blank" rel="noopener" className="imageicon link_whale">
                                                네이버웨일<br />다운로드
                                            </Link>
                                            <Link href="https://www.mozilla.org/ko/firefox/new/" target="_blank" rel="noopener" className="imageicon link_firefox">
                                                파이어폭스<br />다운로드
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    <Button className="btnarea" onClick={this.serviceOkay}>기능 제약이나 오류 가능성을 감안하고, ​인터넷 익스플로러로 접속합니다.​</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        )
    }
}

export default withCookies(IeBlockNotice);
