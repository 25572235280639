import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import FilterPlace from '../filter/FilterPlace';
import FilterATCCategory from '../filter/FilterATCCategory';
import FilterCombination from '../filter/FilterCombination';
import FilterMethod from '../filter/FilterMethod';
import FilterStatYear from '../filter/FilterStatYear';

class MobileFilterOption extends React.PureComponent {
    state = {
        expanded_status: 0,
        expanded: false,
        open: false,
    }

    handleTooltipClose = () => {
        this.setState({
            open: false
        })
        // setOpen(false);
    }

    handleTooltipOpen = () => {
        this.setState({
            open: true
        })
        // setOpen(true);
    }
    
    render(){
        const { prescriptionList } = this.props;
        let expanded = false;
        if(this.state.expanded_status === 0){
            expanded = this.props.filter_place_change || !_.isEmpty(this.props.filter_category) || !_.isEmpty(this.props.filter_combination) || !_.isEmpty(this.props.filter_method);
        }
        else{
            expanded = this.state.expanded;
        }

        return (
            <Fragment>
                <div className="mobile-filter-wrap type2">
                    <ExpansionPanel expanded={expanded} onChange={()=>this.setState({expanded: !expanded, expanded_status: 1})}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                        >
                            <div style={{flexBasis: '100%'}}>
                                <Typography style={{fontSize: "11px"}}>필터</Typography>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{alignItems: 'center'}}>
                            <ClickAwayListener onClickAway={this.handleTooltipClose}>
                                <div class="schstatus_wrap">
                                    {
                                        prescriptionList[0] && prescriptionList[0].f_tot_prescript >= 500 &&
                                        /*<Tooltip
                                            PopperProps={{
                                            disablePortal: true,
                                            }}
                                            onClose={this.handleTooltipClose}
                                            open={this.state.open}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={`처방전 샘플수가 충분합니다 (${ this.props.filter_stat_year }년 표본데이터)`}
                                            placement="bottom"
                                        >*/
                                            <p className="schstatus type3" onClick={this.handleTooltipOpen}>샘플수 양호({
                                                <FilterStatYear
                                                    filter_stat_year={this.props.filter_stat_year}
                                                    filter_stat_year_selectedOption={this.props.filter_stat_year_selectedOption}
                                                    onChangeFilterStatYear={this.props.onChangeFilterStatYear}
                                                />
                                                }년)
                                            </p>
                                        /*</Tooltip>*/
                                    }
                                    {
                                        (prescriptionList[0] &&
                                        prescriptionList[0].f_tot_prescript >= 100 &&
                                        prescriptionList[0].f_tot_prescript < 500) &&
                                        /*<Tooltip title="처방전 샘플수가 다소 부족해, 추가 상세검색을 추천하지 않습니다" placement="right">*/
                                            <p className="schstatus type2">샘플수 주의({
                                                <FilterStatYear
                                                    filter_stat_year={this.props.filter_stat_year}
                                                    filter_stat_year_selectedOption={this.props.filter_stat_year_selectedOption}
                                                    onChangeFilterStatYear={this.props.onChangeFilterStatYear}
                                                />
                                                }년)
                                            </p>
                                        /*</Tooltip>*/
                                    }
                                    {
                                        prescriptionList[0] && prescriptionList[0].f_tot_prescript < 100 &&
                                        /*<Tooltip title="처방전 샘플수가 부족해, 결과 해석에 주의가 필요합니다" placement="right">*/
                                            <p className="schstatus type1">샘플수 부족({
                                                <FilterStatYear
                                                    filter_stat_year={this.props.filter_stat_year}
                                                    filter_stat_year_selectedOption={this.props.filter_stat_year_selectedOption}
                                                    onChangeFilterStatYear={this.props.onChangeFilterStatYear}
                                                    />
                                                }년)
                                            </p>
                                        /*</Tooltip>*/
                                    }
                                </div>
                            </ClickAwayListener>
                            <div className="schoption type1">
                                <FilterPlace
                                    filter_combination={this.props.filter_combination}
                                    filter_place={this.props.filter_place}
                                    onChangeFilterPlace={this.props.onChangeFilterPlace}
                                />
                            </div>
                            <div className="schoption type2">
                                <FilterATCCategory
                                    kcd_code_arr={this.props.kcd_code_arr}
                                    ctg_code3={this.props.ctg_code3}
                                    dgsbjt_cd_arr={this.props.dgsbjt_cd_arr}
                                    dgsbjt_dtl_cd_arr={this.props.dgsbjt_dtl_cd_arr}
                                    agg_arr={this.props.agg_arr}
                                    sex_tp_cd_arr={this.props.sex_tp_cd_arr}
                                    cl_cd_arr={this.props.cl_cd_arr}
                                    bed_arr={this.props.bed_arr}
                                    sido_cd_arr={this.props.sido_cd_arr}
                                    filter_place={this.props.filter_place}
                                    filter_category={this.props.filter_category}
                                    filter_combination={this.props.filter_combination}
                                    filter_stat_year={this.props.filter_stat_year}
                                    filter_method={this.props.filter_method}
                                    filter_category_selectedOption={this.props.filter_category_selectedOption}
                                    onChangeFilterCategory={this.props.onChangeFilterCategory}
                                />
                            </div>
                            <div className="schoption type3">
                                <FilterCombination
                                    filter_combination={this.props.filter_combination}
                                    filter_place={this.props.filter_place}
                                    filter_combination_selectedOption={this.props.filter_combination_selectedOption}
                                    onChangeFilterCombination={this.props.onChangeFilterCombination}
                                />
                            </div>
                            <div className="schoption type4">
                                <FilterMethod
                                    kcd_code_arr={this.props.kcd_code_arr}
                                    ctg_code3={this.props.ctg_code3}
                                    dgsbjt_cd_arr={this.props.dgsbjt_cd_arr}
                                    dgsbjt_dtl_cd_arr={this.props.dgsbjt_dtl_cd_arr}
                                    agg_arr={this.props.agg_arr}
                                    sex_tp_cd_arr={this.props.sex_tp_cd_arr}
                                    cl_cd_arr={this.props.cl_cd_arr}
                                    bed_arr={this.props.bed_arr}
                                    sido_cd_arr={this.props.sido_cd_arr}
                                    filter_place={this.props.filter_place}
                                    filter_category={this.props.filter_category}
                                    filter_combination={this.props.filter_combination}
                                    filter_method={this.props.filter_method}
                                    filter_stat_year={this.props.filter_stat_year}
                                    filter_method_selectedOption={this.props.filter_method_selectedOption}
                                    onChangeFilterMethod={this.props.onChangeFilterMethod}
                                />
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            </Fragment>
        );
    }
}

export default MobileFilterOption;