import React, { Component } from 'react';
import Chart from "react-google-charts";


const pieOptions = {
    slices: [
        {color:"#6a51cd"},
        {color:"#a6c5ef"},
        {color:"#69c9d6"}
    ],
    legend: {
        position: "bottom",
        alignment: "center",
        textStyle: {
            color: "233238",
            fontSize: 13
        }
    },
    tooltip: {
        showColorCode: true,
        trigger: "selection",
        ignoreBounds: false,
        textStyle:{fontSize:10}
    },
    chartArea: {
        left: 0,
        top: 10,
        width: "100%",
        height: "80%"
    },
    fontName: "Roboto",
    backgroundColor:'none',
    fontSize:15,
};

class S_PieChart extends Component {
    render() {
        const { data } = this.props;

        return (
            <div className="App">
                <Chart
                    width={'100%'}
                    height={'220px'}
                    chartType="PieChart"
                    //loader={<div>Loading Chart</div>}
                    data={data}
                    options={pieOptions}
                />
            </div>
        )
    }
}

export default S_PieChart