import React, { Fragment} from 'react';
import { Switch, Route } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import {isIE} from 'react-device-detect';
import { Provider } from 'mobx-react'; // MobX 에서 사용하는 Provider

import Home from './components/prescript/prescription/Home';
import Intro from './components/Intro';
import Prescription from './components/prescript/prescription/Prescription';
import SearchRouter from './components/prescript/prescription/SearchRouter';
// 입지분석
import Location from './components/location/Location';
// 연봉 인덱스
import Salery from './components/salery/S_OutsideFrame';

// mobx store
import SalaryStore from "./stores/salary/SalaryStore";
import {StoreProvider} from "./stores/StoreProvider";

import IeBlockNotice from "./components/common/IeBlockNotice";
import MetaTags from 'react-meta-tags';
import Header from "./common/Header";
import { Helmet } from 'react-helmet';
import ReactHelmet from "./common/ReactHelmet";

class App extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			ieBlockerFlag: true
		}
	}

	changeIeBlockerCookieActive = (param) => {
		this.setState({
			ieBlockerFlag: true
		});
	}

	changeIeBlockerCookie = (param) => {
		this.setState({
			ieBlockerFlag: false
		});
	}

	componentDidMount = () => {
		const { cookies } = this.props;

		if(isIE){
			let ieBlockCookie = cookies.get("mIecV");
			//console.log("ieBlockCookie", ieBlockCookie, salaryStore.ieBlockerFlag)
			if(this.state.ieBlockerFlag && ieBlockCookie !== "Y"){
				// let ieBlockCookie = CommonFunc.getCookieValue("mIecV");
				this.changeIeBlockerCookie();
			}
		}else{
			this.setState({
				ieBlockerFlag: true
			})
		}
	}

	render() {
		return (
            <Fragment>
                {/* Header (OG TAG Setting) */}
                {/*
                <ReactHelmet
                    title="닥터게이트"
                    description="의료 빅데이터 기반 정보제공 서비스"
                    favicon="//image.medigate.net/drgate/images/logo_drgate.png"
                />
                */}
                {/*
                <Switch>
                    <Route exact path="/" >
                        <Header title="닥터게이트" description="의료 빅데이터 기반 정보제공 서비스" image="//image.medigate.net/drgate/images/logo_drgate.png"/>
                    </Route>
                    <Route exact path="/prescription" >
                        <Header title="닥터게이트 : 처방정보" description="임상 데이터 기반 약물 처방정보 서비스" image="//image.medigate.net/drgate/images/logo_drgate.png"/>
                    </Route>
                    <Route exact path="/search" >
                        <Header title="닥터게이트 : 처방정보" description="임상 데이터 기반 약물 처방정보 서비스" image="//image.medigate.net/drgate/images/logo_drgate.png"/>
                    </Route>
                    <Route exact path="/location" >
                        <Header title="닥터게이트 : 입지분석" description="지도기반 최적 개원입지 어드바이스" image="//image.medigate.net/drgate/images/logo_drgate.png"/>
                    </Route>
                    <Route exact path="/salary" >
                        <Header title="닥터게이트 : 연봉인덱스" description="대한민국 의사 GOSS,NET 급여정보 서비스" image="//image.medigate.net/drgate/images/logo_drgate.png"/>
                    </Route>
                </Switch>
                */}
				{
					!this.state.ieBlockerFlag ?
					<IeBlockNotice
						changeIeBlockerCookieActive={this.changeIeBlockerCookieActive}
					/>
					:
					<Switch>
						<Route exact path="/">
                            <Provider salaryStore={new SalaryStore()}>
                                <Intro />
							</Provider>
						</Route>
						{/*<Route exact path="/home" component={ Home } />*/}
						<Route exact path="/prescription" component={ Prescription }/>
						{/*<Route exact path="/search" component={ Home } />*/}
						<Route exact path="/search">
                            <Provider salaryStore={new SalaryStore()}>
                                <Home/>
							</Provider>
						</Route>
						<Route exact path="/location" >
                            <StoreProvider>
                                <Location />
							</StoreProvider>
						</Route>
						<Route path="/salary">
							<Provider salaryStore={new SalaryStore()}>
								<Salery />
							</Provider>
						</Route>
						<Route exact path="/search/:keyword" component={ SearchRouter } />
					</Switch>
				}
			</Fragment>
		);
	}
}

export default withCookies(App);
