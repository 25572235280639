import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import $ from 'jquery';
import 'bootstrap';
import _ from 'lodash';
import { PRODUCT_LIST } from '../../../queries/prescript/prescription';
import ProductList from './ProductList';
import BeatLoading_prescriptList from '../loading/BeatLoading_prescriptList';
import CommonFunc from '../../../common/CommonFunction';
import {isMobileOnly} from "react-device-detect";
import Tooltip from "@material-ui/core/Tooltip";

function selectProduct(value, state, props) {
    let url = "https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetail?itemSeq=" + value.pmt_code;
    window.open(url, '_blank');
}

class IngredGmNewList extends React.PureComponent {

    state = {
        item: {},
        userProfile: {},
        pdt_placeholder: "해당 제품을 확인하세요(갱신:2019.07)",
        load_pagination: false
    }

    componentWillMount = async () => {
        if(!_.isEmpty(CommonFunc.getUserId())){
            const userProfile = await CommonFunc.getUserProfile(CommonFunc.getUserId(), CommonFunc.getToken());
            this.setState({
                userProfile: userProfile || {}
            })
        }
    }

    displayIngredDetails = (item) => {
        //console.log("item displayIngredDetails", item)
        this.setState({
            item: item
        }, () => {
            $('#druginfo_layer').modal('show');
        });

        CommonFunc.pageview('처방정보', '성분상세', item.ingred_eng); //Google Analytics
    }

    onMenuOpen = () => {
        this.setState({
            pdt_placeholder: "검색하려면 제품명을 입력하세요"
        })
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        const propsChanged = (oldProps.page_num !== newProps.page_num);
        if(propsChanged) {
            this.setState({
                load_pagination: false
            })
        }
    }

    render() {
        //console.log("state", this.state)
        //console.log("prescriptionList.length", prescriptionList.length, this.state.liked_item);
        //console.log('item-------------------------', prescriptionList);

        const {
            data, ctg_code3
        } = this.props;

        const {
            item,
            userProfile = {}
        } = this.state;

        const { getIngredGmNewList = [] } = data;
        const ingredGmNewList = _.take(getIngredGmNewList, this.props.page_num * this.props.row_count);
        const ingredGmNewCount = ingredGmNewList ? ingredGmNewList.length : 0;
        const page_num = Math.ceil(ingredGmNewCount / this.props.row_count);
        //console.log("data ", data)
        //console.log("ingredGmNewCount ", ingredGmNewCount)
        return (
            (ingredGmNewList && ingredGmNewList.length > 0) &&
            <div className="wrap_druglist">
                {
                    <div className="title-text">
                        새로운 약물
                    </div>
                }
                {
                    <Fragment>
                        <ul className="druglist">
                            <li>
                                <div className="contlist title">
                                    <div className="txtinfo td01">&nbsp;</div>
                                    <Tooltip title="복용 방법으로 내복/주사/외용/기타가 있습니다">
                                        <div className="txtinfo td02">투여</div>
                                    </Tooltip>
                                    <div className="txtinfo td03">성분명</div>
                                    <div className="txtinfo td04">해당 제품</div>
                                </div>
                            </li>
                            {
                                ingredGmNewList.map((item, index) => {
                                    return (
                                        <li key={index} id={`item_${index+1}`}>
                                            <div className="contlist">
                                                <div className="txtinfo td01"><span>{index + 1}</span></div>
                                                <div className="txtinfo td06">
                                                    {
                                                        item.method === "A" &&
                                                        <span className="type1">{item.method_kor}</span>
                                                    }
                                                    {
                                                        item.method === "B" &&
                                                        <span className="type2">{item.method_kor}</span>
                                                    }
                                                    {
                                                        item.method === "C" &&
                                                        <span className="type3">{item.method_kor}</span>
                                                    }
                                                    {
                                                        item.method === "D" &&
                                                        <span className="type4">{item.method_kor}</span>
                                                    }
                                                </div>
                                                <div className="txtinfo td03">
                                                    <p className="titxt">{item.ingred_eng}</p>
                                                    <p className="subtxt">{item.atc_name}</p>
                                                </div>
                                                <div className="txtinfo td04">
                                                    {
                                                        <Query query={PRODUCT_LIST} variables={{
                                                            ingred_code_grp: item.ingred_code_grp,
                                                            method: item.method,
                                                            ingred_combi_flag: item.combi_flag,
                                                            u_id: userProfile.u_id,
                                                            u_spc_code: userProfile.u_spc_code,
                                                            u_wtp_code: userProfile.u_wtp_code,
                                                            u_hospital_group_code: userProfile.u_hospital_group_code,
                                                            u_birthday: userProfile.u_birthday,
                                                            u_sex: userProfile.u_sex,
                                                            u_zip_zone: userProfile.u_zip_zone
                                                        }}>
                                                            {({ data, loading, error }) => {
                                                                if (loading) return <p></p>
                                                                if (error) return <p>{error.message}</p>;
                                                                // //console.log('PRODUCT_LIST', data);
                                                                const insurance_count = _.filter(data.getPdtList, ["insurance", "급여"]).length;

                                                                return (
                                                                    <ProductList
                                                                        data={data}
                                                                        insurance_count={insurance_count}
                                                                        from_type="list"
                                                                    />
                                                                )
                                                            }}
                                                        </Query>
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Fragment>
                }

                {
                    ingredGmNewList &&
                    ingredGmNewList.length > 0 &&
                    ingredGmNewList[0].tot_cnt > ingredGmNewCount &&
                    <div className="more_area" onClick={() => { this.setState({load_pagination: true}); this.props.onLoadMore(page_num + 1)} }>
                        <div style={{display:'inline-block'}}>
                            {
                                this.state.load_pagination &&
                                <BeatLoading_prescriptList/>
                            }
                        </div>
                        <div className="btn_more">
                            결과약물 더보기
                            <span>({page_num * this.props.row_count} / {ingredGmNewList[0].tot_cnt})</span>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default IngredGmNewList;