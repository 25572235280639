import React, { Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
import ReactPopupLayer from "react-popup-layer"

import CapacitySubChartFragment from './CapacitySubChartFragment';

import "react-popup-layer/assets/index.css"

class CapacityChart extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            chartOptions: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false
                },
                title: {
                    text: "용량별 처방"
                },
                subtitle: {
                    text: "챠트 조각을 눌러, 처방 패턴을 확인하세요",
                    style : {
                        color : '#FF6666'
                    }
                },
                tooltip: {
                    pointFormat: '<b>{point.percentage:.1f}%</b>'
                },
                legend: {
                    align: 'right',
                    layout: 'vertical',
                    verticalAlign: 'middle',
                    maxHeight: 90
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true,
                        animation: {
                            duration: 0
                        }
                    },
                    series: {
                        point:{
                            events:{
                                click: this.openModal.bind(this),
                                legendItemClick: this.openLegendModal.bind(this)
                            }
                        }
                    }
                },
                series: [{
                    type: 'pie',
                    name: "",
                    data: this.props.data.getAmtStat,
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal'
                            },
                            yAxis: {
                                labels: {
                                    align: 'left'
                                }
                            },
                            credits: {
                                enabled: false
                            }
                        }
                    }]
                }
            },
            unit_amt_code: "",
            unit_amt_name: "",
            unit_amt_value: ""
        }
    }

    openModal = (e) => {
        //console.log("openModal", e, e.point.code, e.point.avg_unit_cost);
        this.setState({
            visible: true,
            unit_amt_code: e.point.code,
            unit_amt_name: e.point.name,
            unit_amt_value: e.point.avg_unit_cost
        })
    }

    openLegendModal = (e) => {
        //console.log("openLegendModal", e);
        //console.log("openModal", e, e.target.code, e.target.avg_unit_cost);
        this.setState({
            visible: true,
            unit_amt_code:e.target.code,
            unit_amt_name: e.target.name,
            unit_amt_value: e.target.avg_unit_cost
        })
        return false;
    }

    cancelModal = () => {
        this.setState({
            visible: false,
            unit_amt_code: "",
            unit_amt_name: "",
            unit_amt_value: ""
        })
    }

    render() {
        const { visible } = this.state
        //console.log("visible", visible, this.props)
        return (
            <Fragment>
                <ReactPopupLayer
                    title=""
                    footer={false}
                    visible={visible}
                    onCancel={this.cancelModal}
                    className="my-modal"
                >
                    {
                        visible &&
                        <CapacitySubChartFragment
                            kcd_code_arr={this.props.kcd_code_arr}
                            ctg_code3={this.props.ctg_code3}
                            ingred_code_grp={this.props.ingred_code_grp}
                            ingred_combi_flag={this.props.ingred_combi_flag}
                            ingred_eng={this.props.ingred_eng}
                            dgsbjt_cd_arr={this.props.dgsbjt_cd_arr}
                            dgsbjt_dtl_cd_arr={this.props.dgsbjt_dtl_cd_arr}
                            agg_arr={this.props.agg_arr}
                            sex_tp_cd_arr={this.props.sex_tp_cd_arr}
                            cl_cd_arr={this.props.cl_cd_arr}
                            bed_arr={this.props.bed_arr}
                            sido_cd_arr={this.props.sido_cd_arr}
                            place={this.props.place}
                            atc_code={this.props.atc_code}
                            combi_flag={this.props.combi_flag}
                            method={this.props.method}
                            unit_amt_code={this.state.unit_amt_code}
                            unit_amt_name={this.state.unit_amt_name}
                            unit_amt_value={this.state.unit_amt_value}
                            stat_year={this.props.stat_year}
                        />
                    }
                </ReactPopupLayer>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.chartOptions}
                />
            </Fragment>
        )
    }
}

export default CapacityChart;