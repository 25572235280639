import React, {Fragment} from 'react';

class KCDDetailMoreLess extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            more_less: props.more_less
        };
    }

    changeMoreLess = (nextType) => {
        this.setState({
            more_less: nextType
        })
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            more_less: "LESS"
        })
    }

    render() {
        const { kcd_list_of_comment } = this.props;

        return (
            <div className="info_box">
            {
                kcd_list_of_comment.length > 0 &&
                <div className="tag">
                    <div style={this.state.more_less === 'LESS' ? {height:'22px', overflowY:'hidden'} : {height:'100%'}}>
                    { 	this.state.more_less === 'LESS' &&
                        <div className="more" onClick={() => this.changeMoreLess('MORE')}>MORE (+)</div>
                    }
                    {
                        kcd_list_of_comment.map((kcd, index) =>
                            <div key={index} className="tag_code">{kcd.kcd_code}: {kcd.kcd_kor}</div>
                        )
                    }
                    { 	this.state.more_less === 'MORE' &&
                        <div className="less" onClick={() => this.changeMoreLess('LESS')}>LESS (-)</div>
                    }
                    </div>
                </div>
            }
            </div>
        );
    }
}

export default KCDDetailMoreLess;