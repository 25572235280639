import React, { Fragment } from 'react';
import axios from "axios";
import _ from "lodash";
import search from 'youtube-search';
import News from './News';
import Muzzima from './Muzzima';
import Youtube from './Youtube';
import CommonFunc from '../../../common/CommonFunction';

const content_by_collection = 100;

class ContentFragment extends React.PureComponent {

    state = {
        content_news: [],
        content_muzzima: [],
        content_youtube: []
    }

    searchNews = async (keyword, content_by_collection) => {
        const url = `https://search.medigate.net/search.jsp?query=${keyword}&totaldisplay=${content_by_collection}&page=1&collection=news_gisa,news_dansin&sort=news_gisa@PUBLISHDATE/DESC;news_dansin@PUBLISHDATE/DESC`;
        //console.log('searchNews-url : ', url)
        axios.get(url)
        .then(response => {
            const totalCount = response && response.data ? response.data.TotalCount : 0;
            let idList = [];
            if(totalCount > 0){
                const newsGisaList = response.data.Data.filter(item => item.CollName === `news_gisa`)[0].TotalCount > 0 ? response.data.Data.filter(item => item.CollName === `news_gisa`)[0].Result : [];
                const newsDansainList = response.data.Data.filter(item => item.CollName === `news_dansin`)[0].TotalCount > 0 ? response.data.Data.filter(item => item.CollName === `news_dansin`)[0].Result : [];
                idList = _.map(_.sortBy([...newsGisaList, ...newsDansainList], 'PUBLICID').reverse(), 'PUBLICID');
            }

            //console.log('searchNews idList', idList);

            if(idList.length > 0){
                if(idList.length > content_by_collection){
                    idList = _.take(idList, content_by_collection); //50개 까지 추출
                }

                const config = {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                    }
                };
                axios.post('http://m.medigatenews.com/search/news_by_publiccd', {'idList': idList}, config)
                .then(res => {
                    //console.log('searchNews-->', res.data);
                    this.setState({
                        content_news: res.data
                    })
                }).catch(error => {
                    //console.log("error data", error.data);
                });
            }
        }).catch(error => {
            //console.log("error data", error);
        });
    }

    searchMuzzima = (keyword, content_by_collection) => {
        const url = `https://search.medigate.net/search.jsp?query=${keyword}&collection=board_muzzima&colldisplay=${content_by_collection}&page=1&sort=board_muzzima@DATE/DESC`;
        //console.log('searchMuzzima-url : ', url)
        axios.get(url)
        .then(response => {
            const totalCount = response && response.data ? response.data.TotalCount : 0;
            let idList = [];
            if(totalCount > 0){
                idList = _.map(response.data.Data[0].Result, 'CONT_ID');
            }
            //console.log('searchMuzzima idList', keyword, idList);

            if(idList.length > 0){
                const config = {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': `Bearer ${CommonFunc.getToken()}`
                    }
                };
                axios.post('https://apis.medigate.net/search/muzzima', { 'idList': idList }, config)
                .then(res => {
                    //console.log('searchMuzzima-->', res.data);
                    this.setState({
                        content_muzzima: res.data
                    })
                }).catch(error => {
                    //console.log("error data", error);
                });
            }
        }).catch(error => {
            //console.log("error data", error);
        });
    }

    searchYoutube = (keyword, content_by_collection) => {
        keyword = keyword.replace(new RegExp('\\|', 'g'), ' OR '); //OR(|) 연산을 공백으로 치환
        //console.log('keyword', keyword);
        var opts = {
            maxResults: 10,
            order: 'relevance',
            key: 'AIzaSyBzb64x8rYNnsoA5u7kUQ75brrLWE8hKA4'      //Medigate Pwersionalization
            //key: 'AIzaSyC8O5O2I-9XxM9-bvPi6eCQxex3taivPkA'    //Medigate Mate
        };

        const thisObj = this;
        search(keyword, opts, function(err, results) {
            if(err) return //console.log('YOUTUBE ERROR', err.message);

            //console.log('searchYoutube-->', results);
            thisObj.setState({
                content_youtube: results
            })
        });
    }

    searchConent = (ingred_kor, ingred_eng, user) => {

        const keyword = this.getKeyword(ingred_kor, ingred_eng);
        //console.log('keyword -->', keyword, ' (ingred_kor : ', ingred_kor, 'ingred_eng : ', ingred_eng + ')');
        if(keyword){
            this.searchNews(keyword, content_by_collection);
            if(user){
                this.searchMuzzima(keyword, content_by_collection);
            }
            this.searchYoutube(keyword, content_by_collection);
        }
    }

    getKeyword = (ingred_kor, ingred_eng) => {
        let keyword = '';
        if(!_.isEmpty(ingred_kor)){
            const SPLIT_CAHR_KOR = ',';
            if(ingred_kor.indexOf(SPLIT_CAHR_KOR !== -1)){
                const tmpKeyword = ingred_kor.split(SPLIT_CAHR_KOR);
                let word = '';
                tmpKeyword.map((item, index) => {
                    if(index > 0){
                        word += '|';
                    }
                    let tmpWord = item.trim().split(' ')[0];
                    if(tmpWord.indexOf('(') !== -1){
                        tmpWord = tmpWord.substring(0, tmpWord.indexOf('('));
                    }
                    word += tmpWord;
                })
                keyword += word;
            }
            else{
                keyword += ingred_kor.split(' ')[0];
            }
        }
        if(!_.isEmpty(ingred_eng)){
            if(!_.isEmpty(keyword)){
                keyword += '|';
            }
            const SPLIT_CAHR_ENG = '&';
            if(ingred_eng.indexOf(SPLIT_CAHR_ENG !== -1)){
                const tmpKeyword = ingred_eng.split(SPLIT_CAHR_ENG);
                let word = '';
                tmpKeyword.map((item, index) => {
                    if(index > 0){
                        word += '|';
                    }
                    let tmpWord = item.trim().split(' ')[0];
                    if(tmpWord.indexOf('(') !== -1){
                        tmpWord = tmpWord.substring(0, tmpWord.indexOf('('));
                    }
                    word += tmpWord;
                })
                keyword += word;
            }
            else{
                keyword += ingred_eng.split(' ')[0];
            }
        }
        // //console.log('keyword', keyword)
        return keyword;
    }

    componentDidMount = () => {
        const { ingred_kor, ingred_eng, user } = this.props;
        this.searchConent(ingred_kor, ingred_eng, user);
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        const propsChanged = (oldProps.ingred_kor !== newProps.ingred_kor || oldProps.ingred_eng !== newProps.ingred_eng || oldProps.user !== newProps.user);
        //console.log('componentWillReceiveProps changed', propsChanged);
        if(propsChanged) {
            const { ingred_kor, ingred_eng, user } = newProps;
            this.searchConent(ingred_kor, ingred_eng, user);
        }
    }

    render(){
        const {user, ingred_kor, ingred_eng} = this.props;
        //console.log('ContentFragment props', ingred_kor, ingred_eng);
        //console.log('ContentFragment content_muzzima', this.state.content_muzzima);
        //console.log('ContentFragment content_youtube', this.state.content_youtube);

        return (
            <Fragment>
                {/* 메디게이트 뉴스 */}
                { this.state.content_news.length > 0 &&
                <News
                    ingred_name={ ingred_eng }
                    content={this.state.content_news}
                />
                }

                {/* 무찌마 */}
                <Muzzima
                    user={ user }
                    ingred_name={ ingred_eng }
                    content={this.state.content_muzzima}
                />

                {/* 유튜브 */}
                { this.state.content_youtube.length > 0 &&
                <Youtube
                    ingred_name={ ingred_eng }
                    content={this.state.content_youtube}
                />
                }
            </Fragment>
        )
    }
}

export default ContentFragment;
