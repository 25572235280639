import React, {useEffect, useState, useCallback, useMemo} from 'react';
import {useLazyQuery, useMutation} from '@apollo/react-hooks';

import {
    BIZAREA_LIST,
    DONG_INFO,
    DONG_LIST,
    HOSPITAL_CLUSTER_EMD,
    HOSPITAL_CLUSTER_SGG,
    HOSPITAL_LIST,
    MY_SEARCH_LOG_INSERT
} from '../../queries/location';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import _ from "lodash";

import "./kakaomap.css";
import useStyles from "../style/common";
import DetailButton from "./DetailButton";
import DetailLayout from "./DetailLayout";
/* ksseo */
import LeaseAdContainer from "./LeaseAdContainer";

import {useStore} from "../../stores/StoreProvider";
import {observer} from "mobx-react";
import {throttle} from "throttle-debounce";

import circleToPolygon from "circle-to-polygon";
import {isChrome} from 'react-device-detect';
import CustomOverlays from "./CustomOverlays";

import useKeyPress from "../common/useKeyPress";
import CommonFunc from "../../common/CommonFunction";

import {useLocation, useHistory} from "react-router-dom";

const Kakaomap = observer ((props) => {
    const store = useStore().LocationStore;
    const {convertHosList} = props;

    /* STATIC VARIABLES */
    const _normalMarkerObj = {
        url:'http://image.medigate.net/drgate/images/location/marker2.png',
        width:26,
        height:33,
    };
    const _centerMarkerObj = {
        url:'http://image.medigate.net/drgate/images/location/marker1.png',
        width:26,
        height:33,
    };
    const _leaseAddrCenterMarkerObj = {
        url:'http://image.medigate.net/drgate/images/location/marker3.png',
        width:26,
        height:33,
    };
    const _rvCursorUrl = "http://image.medigate.net/drgate/images/location/marker4.png";// 로드뷰 커서 url
    const _rvMarkerUrl = "http://image.medigate.net/drgate/images/location/marker4.png";// 로드뷰 마커 url
    const _roadviewMarkerObj = {
        url:_rvMarkerUrl,
        width:32,
        height:32,
    };

    const _maxSpcCount = 5;
    const _errMsg_zoom = "의료기관 통계를 보려면 지도를 더 확대해 주세요."
    const _errMsg_spc = "진료과목을 선택해 주세요. (최대 "+_maxSpcCount+"개의 진료과 선택가능)"
    const _errMsg_map = "지도 중심 지역을 선택해 주세요. 읍면동 단위까지 선택 하셔야 합니다."
    const _defaultAddr = '서울시';
    const defaultCenterPos = [37.566431, 126.978042];///맵 기본 좌표 - 서울시청
    const _mapMaxZoomLevel = 8; //맵 최대 줌 레벨 제한
    /* STATIC VARIABLES 끝 */


    // const locInfo = null;
    // const locInfo = useLocation();

    //key event hook variables
    const escPress = useKeyPress('Escape');
    //key event hook variables end

/* useLazyQuery  항목들 설정 */
    const [getHosData, {loading:hosLoading, error:hosError, data:hosData}] =
        useLazyQuery(HOSPITAL_LIST, {notifyOnNetworkStatusChange:true, fetchPolicy: 'network-only',}
        ); //GEOCODE (위경도) 조회

    const [getNearDongData, {loading:nearDongLoading, error:nearDongError, data:nearDongData}] =
        useLazyQuery(DONG_LIST,
            {
                onCompleted: (data)=> { store.setNearDongList(data.getDongList); },
                notifyOnNetworkStatusChange:true,
                fetchPolicy: 'network-only',
            }
        ); //인접동 가져오기

    const [getLocationInfo, {loading:locationLoading, error:locationError, data:locationData}] =
        useLazyQuery(DONG_INFO,
            {
                onCompleted: (data)=>{ store.setLocationInfo(data.getDongInfo) },
            }); //위경도 기반 지역정보 조회 (DONG_INFO)

    /*
    * drawFigure에 대한 정보들*/
    const [getNearDongDataFigure1, {loading:nearDongFigure1Loading, error:nearDongFigure1Error, data:nearDongFigure1Data}] =
        useLazyQuery(DONG_LIST,
            {
                onCompleted: (data)=> {
                    store.setFigureNearDongList(data.getDongList);

                },
                notifyOnNetworkStatusChange:true,
                fetchPolicy: 'network-only',
            }
        ); //인접동 가져오기

    const [getNearDongDataFigure2, {loading:nearDongFigure2Loading, error:nearDongFigure2Error, data:nearDongFigure2Data}] =
        useLazyQuery(DONG_LIST,
            {
                onCompleted: (data)=> { store.setFigureNearDongList2(data.getDongList); },
                notifyOnNetworkStatusChange:true,
                fetchPolicy: 'network-only',
            }
        ); //인접동 가져오기

    const [getLocationInfoFigure1, {loading:locationFigure1Loading, error:locationFigure1Error, data:locationFigure1Data}] =
        useLazyQuery(DONG_INFO,
            {
                onCompleted: (data)=>{
                    if(data.getDongInfo != null) {
                        store.setfigureLocationInfo(data.getDongInfo);
                    }
                },
                notifyOnNetworkStatusChange:true,
                fetchPolicy: 'network-only',
            }); //위경도 기반 지역정보 조회 (DONG_INFO)

    const [getLocationInfoFigure2, {loading:locationFigure2Loading, error:locationFigure2Error, data:locationFigure2Data}] =
        useLazyQuery(DONG_INFO,
            {
                onCompleted: (data)=>{
                    if(data.getDongInfo != null) {
                        store.setfigureLocationInfo2(data.getDongInfo);
                    }
                },
                notifyOnNetworkStatusChange:true,
                fetchPolicy: 'network-only',
            }); //위경도 기반 지역정보 조회 (DONG_INFO)

    const [getBizareaData, {loading:endpointBizareaLoading, error:endpointBizareaError, data:endpointBizarea}] = useLazyQuery(BIZAREA_LIST);


    const [mapOption, setMapOption] = useState(null);

    /*
    * /////////////////drawFigure에 대한 정보들 끝!!*/

    // ksseo 클러스터 마커 시군구
    const [getHospitalClusterSgg, {loading: hospitalClusterSggLoading, error: hospitalClusterSggError, data: hospitalClusterSggData}] =
        useLazyQuery(HOSPITAL_CLUSTER_SGG,{
            onCompleted: (data) => {
                setMarkerList(data.getHospitalClusterListBySgg)
                store.setActionStatus(4);
            },
            fetchPolicy: 'network-only'
        });

    // ksseo 클러스터 마커 읍면동
    const [getHospitalClusterEmd, {loading: hospitalClusterEmdLoading, error: hospitalClusterEmdError, data: hospitalClusterEmdData}] =
        useLazyQuery(HOSPITAL_CLUSTER_EMD, {
            onCompleted: (data)=>{
                setMarkerList(data.getHospitalClusterListByEmd);
                store.setActionStatus(4);
            },
            fetchPolicy: 'network-only'
        });
/* useLazyQuery  항목들 설정 끝 */


    useEffect(() => {
        if(!hospitalClusterSggLoading){ //시군구 클러스터 데이터 마커 적용
            if(hospitalClusterSggData){
                setMarkerList(hospitalClusterSggData.getHospitalClusterListBySgg)
                store.setActionStatus(4);
            }
        }
    }, [hospitalClusterSggData, hospitalClusterSggLoading]);

    useEffect(() => {
        if(!hospitalClusterEmdLoading){ //읍면동 클러스터 데이터 마커 적용
            if(hospitalClusterEmdData){
                setMarkerList(hospitalClusterEmdData.getHospitalClusterListByEmd)
            }
        }
    }, [hospitalClusterEmdData, hospitalClusterEmdLoading]);

    const [addSearchLog, { loading: logInsertLoading, error:logError }] = useMutation(MY_SEARCH_LOG_INSERT); //사용자 통계보기 insert하는 쿼리 객체 선언

    /* */
    const mapRef = React.useRef();
    const roadviewRef = React.useRef(); //로드뷰 컨테이너
    const classes = useStyles();
    /* */

    /* ------------------------------------------------------------------------------------------------------------------------------------------------ */
    /* ------------------------------------------------------------------------------------------------------------------------------------------------ */
    /* ------------------------------------------------------------------------------------------------------------------------------------------------ */
    /* ------------------------------------------------------------------------STATE 선언부 시작 */
    const [isInitialized, setIsInitialized] = useState(false);
    const [autoDetail, setAutoDetail] = useState(true);

    //맵 코어
    const [map, setMap] = useState(null);
    const getMap = useCallback( () => {
        return map
    }, [map]);
    const [kakao, setKakao] = useState(null);
    const [loaded, setLoaded] = useState(false);
    //로드뷰 https://apis.map.kakao.com/web/sample/basicRoadview/ // 로드뷰 관련 210805
    const [roadviewClient, setRoadviewClient] = useState(null); //로드뷰 helper 객체// 로드뷰 관련 210805
    const [roadviewObj, setRoadviewObj] = useState(null); //로드뷰 객체// 로드뷰 관련 210805
    const [rvListener, setRvListener] = useState(false); //지도에 로드뷰 클릭 리스너가 등록되있는가// 로드뷰 관련 210805
    const [leaseAddr, setLeaseAddr] = useState(""); //임대분양에서 넘어왔을때 달고 오는 주소값

    //맵 기타
    const [placeSvc, setPlaceSvc] = useState(null);
    const [clearButton, setClearButton] = useState(null);
    const [clearingFigure, setClearingFigure] = useState(false);

    //상권
    const [bizareaList1, setBizareaList1] = React.useState([]);
    const [bizareaList2, setBizareaList2] = React.useState([]);

    //읍면동 경계
    const [emdareaList1, setEmdareaList1] = React.useState([]);
    const [emdareaList2, setEmdareaList2] = React.useState([]);

    //그리기 관련
    const [drawingManager, setDrawingManager] = useState(null);
    const [lastDrawType, setLastDrawType] = useState('');
    const [isFigureDrawable, setIsFigureDrawable] = useState(false);
    const [drawMultiMap, setDrawMultiMap] = useState(false);
    const [isDrawSuccessed, setIsDrawSuccessed] = useState(false);
    const [isDrawFailed, setIsDrawFailed] = useState(false);

    const [localDrawFigure1, setLocalDrawFigure1] = useState(null); //기존 그려졌던 도형정보
    const [localDrawFigure2, setLocalDrawFigure2] = useState(null); //

    // 로드뷰 관련 210805
    const [roadviewOn, setRoadviewOn] = useState(false); //로드뷰 on,off
    const [roadviewDisplay, setRoadviewDisplay] = useState(false); //로드뷰 지역 선택후 디스플레이 되는중


    /*
     거리재기 관련 변수들
     */
    let drawingFlag = false; // 선이 그려지고 있는 상태를 가지고 있을 변수입니다
    let moveLine; // 선이 그려지고 있을때 마우스 움직임에 따라 그려질 선 객체 입니다
    let clickLine; // 마우스로 클릭한 좌표로 그려질 선 객체입니다
    let distanceOverlay; // 선의 거리정보를 표시할 커스텀오버레이 입니다
    let dots = {}; // 선이 그려지고 있을때 클릭할 때마다 클릭 지점과 거리를 표시하는 커스텀 오버레이 배열입니다.
    const [drawingFlagState,setDrawingFlagState] = useState(false); // 선이 그려지고 있는 상태를 가지고 있을 변수입니다
    const [moveLineState,setMoveLineState] = useState(null); // 선이 그려지고 있을때 마우스 움직임에 따라 그려질 선 객체 입니다
    const [clickLineState,setClickLineState] = useState([]); // 마우스로 클릭한 좌표로 그려질 선 객체입니다
    const [distanceOverlayState,setDistanceOverlayState] = useState(null); // 선의 거리정보를 표시할 커스텀오버레이 입니다
    const [dotsState, setDotsState] = useState([]); // 선이 그려지고 있을때 클릭할 때마다 클릭 지점과 거리를 표시하는 커스텀 오버레이 배열입니다.
    /*
     거리재기 관련 변수들 끝
     */

    /*
    graphql query list
    * */

    const [isDetailOpen, setIsDetailOpen] = useState(false);

    /* 초빙정보 리스트 레이어 변수 */
    const [infowindow, setInfowindow] = useState(null);

    /* ksseo zoomLevel 변수 */
    const [zoomLevel, setZoomLevel] = useState(3);

    /* 카카오맵 마커 리스트 */
    const [centerMarker, setCenterMarker] = useState(null); //중앙마커정보 (우측 병원정보눌렀을때)
    const [centerMarkerIw, setCenterMarkerIw] = useState(null); //중앙마커 infowindow (우측 병원정보눌렀을때)
    const [markerList, setMarkerList] = useState([]); //기본 마커 리스트
    const [drawedMarkerList, setDrawedMarkerList] = useState([]); //그려져있는 마커 리스트
    const [drawedOverlayList, setDrawedOverlayList] = useState([]); //그려져있는 마커의 infowindow
    const [rvMarker, setRvMarker] = useState(null); //로드뷰 마커
    const [rvMarkerPos, setRvMarkerPos] = useState(null); //로드뷰 마커위치
    const [addrCenterMarker, setAddrCenterMarker] = useState(null); // 임대에서 주소눌러서 왔을때 나오는 마커
    const [addrCenterMarkerList, setAddrCenterMarkerList] = useState([]); // 임대에서 주소눌러서 왔을때 나오는 마커

    /* 임대분양 매물정보 infowindow*/
    const [leaseOverlayList, setLeaseOverlayList] = useState([]); //임대분양 infowindow


    /* Backend 통신 로딩 Flag*/
    const [loadingFlag, setLoadingFlag] = useState(false);
    /* 조회이력 도형 불러오기 완료 플래그*/
    const [historyFigure1Flag, setHistoryFigure1Flag] = useState(false);
    const [historyFigure2Flag, setHistoryFigure2Flag] = useState(false);


    /* ////////////////////////////////////////////////////////.*/
    /* ////////////////////////////////////////////////////////.*/
    /* ///////////////////도형 유틸리티 함수들/////////////////.*/
    /* ////////////////////////////////////////////////////////.*/
    /* ////////////////////////////////////////////////////////.*/
    /*
    polygon(rectangle) 센터 구하기 함수들. state인 points를 꼭지점 배열로 세팅하면 figureCenterObj로 연산해줌.
    * 참고 URL : https://stackoverflow.com/questions/16282330/find-centerpoint-of-polygon-in-javascript
    * */

    const [figureCenterObj, setFigureCenterObj] = useState(null);
    const [points, setPoints] = useState([]);
    const [length, setLength] = useState(0);

    useEffect(() => {
        if(points != null && points.length > 0) {
            setLength(points.length);
        }
    }, [points]); //폴리곤이나 사각형의 포인트가 지정이 된다면 length를 지정해주고

    useEffect(() => {//length 가 지정이 되면 centroid 함수를 실행해서 중심값을 세팅한다.
        if(length > 0) {  centroid();}
    }, [length]);

    useEffect(() => {//length 가 지정이 되면 centroid 함수를 실행해서 중심값을 세팅한다.
        if(figureCenterObj != null) {
            let param = {pos_x: ""+figureCenterObj.pos_x, pos_y: ""+figureCenterObj.pos_y};
            if(param.pos_x != 0 && param.pos_y != 0) {
                if (store.historyLoading) {
                    if (clearButton.getMap() == null) {
                        clearButton.setMap(map);
                    }
                    clearButton.setPosition(new kakao.maps.LatLng(param.pos_y, param.pos_x));
                }
            }
        }
    }, [figureCenterObj,store.drawHistoryFigure1,store.drawHistoryFigure2]);

    useEffect(() => {//도형의 중앙값 지정시 해당하는 locationinfo 가져옴
        if(figureCenterObj != null) {
            let param = {pos_x: ""+figureCenterObj.pos_x, pos_y: ""+figureCenterObj.pos_y};
            if(store.figureLocationInfo != null){
                getLocationInfoFigure2({variables: param})
            } else if(store.figureLocationInfo == null){
                getLocationInfoFigure1({variables: param})
            }
        }
    }, [figureCenterObj]);

    const polygonArea = () =>{
        let area = 0,
            i,
            j,
            point1,
            point2;

        for (i = 0, j = length - 1; i < length; j=i,i++) {
            point1 = points[i];
            point2 = points[j];
            area += getLatLngFromPos(point1, "lng") * getLatLngFromPos(point2, "lat"); //포인트1경도 , 포인트2위도
            area -= getLatLngFromPos(point1, "lat") * getLatLngFromPos(point2, "lng"); //포인트1위도, 포인트2경도
        }
        area /= 2;

        return area;
    }

    const centroid = () => {
        let x = 0,
            y = 0,
            i,
            j,
            f,
            point1,
            point2;

        for (i = 0, j = length - 1; i < length; j=i,i++) {
            point1 = points[i];
            point2 = points[j];
            f = getLatLngFromPos(point1, "lng") * getLatLngFromPos(point2, "lat") - getLatLngFromPos(point2, "lng") * getLatLngFromPos(point1, "lat"); //포1경도,포2위도,포2경도,포1위도
            x += (getLatLngFromPos(point1, "lng") + getLatLngFromPos(point2, "lng")) * f; //포1경도,포2경도
            y += (getLatLngFromPos(point1, "lat") + getLatLngFromPos(point2, "lat")) * f; //포1위도,포2위도ㅜ
        }

        f = polygonArea() * 6;
        setLength(0);
        return setFigureCenterObj({pos_x: x / f, pos_y: y / f});
    }
    /* //////////////polygon(rectangle) 센터 구하기 함수들 끝.*/
    /* ////////////////////////////////////////////////////////.*/
    /* ////////////////////////////////////////////////////////.*/
    /* ////////////////////////////////////////////////////////.*/
    /* ////////////////////////////////////////////////////////.*/




    /* --------------------------------------------------------------------- */
    /* --------------------------------------------------------------------- */
    /* ------------------------------주요 EFFECTS--------------------------- */
    /* --------------------------------------------------------------------- */
    /* --------------------------------------------------------------------- */
    /* --------------------------------------------------------------------- */
    useEffect(()=>{
        setLoadingFlag(locationLoading || nearDongLoading || hosLoading);
    },[locationLoading, nearDongLoading, hosLoading]);

    const history = useHistory()
    const locInfo = useLocation();
    useEffect(() => {
        // setKakao(importMapScripts());
        const queryParams = new URLSearchParams(locInfo.search)
        //new URLSearchParams(locInfo.search).get("leaseaddr")

        if (queryParams.has('leaseaddr')) {
            //alert('임대에서 넘어옴.')
            queryParams.delete('leaseaddr')
            history.replace({
                search: queryParams.toString(),
            })
            // console.log(">??????<", locInfo, queryParams.get("leaseaddr"), new URLSearchParams(locInfo.search).get("leaseaddr"))
            setLeaseAddr(new URLSearchParams(locInfo.search).get("leaseaddr"))
            store.setLeaseAddr(new URLSearchParams(locInfo.search).get("leaseaddr"));
            // store.setHasLeaseAddr(true);
        }

        importMapScripts(locInfo);

        return () => {
            setKakao(null);
        }
    }, []);

    useEffect(()=>{
        if(markerList != null && map != null && drawingManager != null) {
            clearDrawedMarker();
            clearInfowindow();

            let dMarkerList = [];
            let dOverlayList = [];
            if (markerList.length > 0) {
                //마커 생성하는 루프
                for (let i = 0; i < markerList.length; i++) {
                    let clusterMarker = false;
                    if(markerList[i].hos_name == null || markerList[i].hos_name == "") {
                        clusterMarker=true;
                    }
                    let {hos_name: title, pos_x: lng, pos_y: lat} = markerList[i]; //마커리스트 항목에서 필요한 요소들 추림
                    let markerImg = new kakao.maps.MarkerImage(_normalMarkerObj.url, new kakao.maps.Size(_normalMarkerObj.width,_normalMarkerObj.height));
                    if(clusterMarker) {
                        title = "<span class='title'>" + markerList[i].region_name +"</span><span class='num'>"+ markerList[i].hospital_count + "개</span>"; //클러스터 마커일시 타이틀이 다름
                    }

                    if(!clusterMarker) {
                        let marker = new kakao.maps.Marker({
                            map: getMap(), //마커달 카카오맵
                            position: new kakao.maps.LatLng(lat, lng),//마커 위경도
                            image: markerImg,
                            //title: title, //마커이름
                            code: markerList[i].hos_code
                        });

                        let infowindow = new kakao.maps.InfoWindow({
                            content: '<div class="label hospital"><span class="center">'+title+'</span></div>',
                            disableAutoPan: true,
                        });

                        kakao.maps.event.addListener(marker, 'mouseover', ()=> {
                            infowindow.open(map, marker)
                        });
                        kakao.maps.event.addListener(marker, 'mouseout', ()=> {
                            infowindow.close()
                        });
                        dMarkerList.push(marker);
                    }
                    else{
                        const overlay = new kakao.maps.CustomOverlay({
                            position: new kakao.maps.LatLng(lat, lng),
                            content: '<div class="overlay cluster"><span class="textinfo">'+title+'</span></div>',
                        });
                        dOverlayList.push(overlay);
                    }
                }
            }
            setDrawedMarkerList(dMarkerList);
            setDrawedOverlayList(dOverlayList);
        }

        return () => {
            if(zoomLevel > 4){
                if(markerList.length > 0){

                }
            }
        };
    },[markerList]);

    useEffect(()=>{
        displayClusterInfowindow(drawedOverlayList);
    }, [drawedOverlayList]);

    const displayClusterInfowindow = (drawedOverlayList) => {
        drawedOverlayList.map(overlay => {
            overlay.setMap(map);
        })
    }

    useEffect(()=>{
        return () => {

        }
    },[autoDetail]); //200602

    useEffect(()=>{ //한개그렸을때 맵 띄워주기 위한 effect //200525
        if(drawingManager !== null && !locationFigure1Loading && locationFigure1Data != null && !store.historyLoading) {
            if (store.drawFigure1 != null) {
                if(!store.isMultiMap) {
                    drawingManager.cancel();
                    if(autoDetail) {
                        handleDetailButton(autoDetail); //도형1 그려졌을때 통계보기 띄우기.
                    }
                }
            }
        }
    }, [drawingManager, locationFigure1Data]);//200612 도형1 새 데이터가 로드되기전에 통계보기가 되면 오류남. 따라서 다른 deps를 제거함.

    useEffect(()=>{ //두개그렸을때 맵 띄워주기 위한 effect //200527
        if(drawingManager !== null&& !locationFigure2Loading && locationFigure2Data != null && !store.historyLoading) {
            if (store.drawFigure2 != null) {
                drawingManager.cancel();
                if(autoDetail) {
                    handleDetailButton(autoDetail); //도형2 그려졌을때 통계보기 띄우기.
                }
            }
        }
    }, [drawingManager, locationFigure2Data]) //200611 도형2 새 데이터가 로드되기전에 통계보기가 되면 오류남. 따라서 다른 deps를 제거함.
    // }, [store.hospitalMarkerList2, store.drawFigure2, locationFigure2Data,locationFigure2Loading]) //원본

    useEffect(()=>{
        return ()=>{
            if(isDetailOpen){ //isDetailOpen이 true인 상태에서 cleanup될 때 (통계보기 닫을 때)
                if(store.historyLoading){
                    store.setHistoryLoading(false);
                }
                if(store.drawFigure2 != null){
                    let figure2Center = new kakao.maps.LatLng(figureCenterObj.pos_y,figureCenterObj.pos_x); //도형2 위치
                    // map.setCenter(clearBtnCenter);
                    map.setCenter(figure2Center);
                }

            }
        }
    },[isDetailOpen]);

    useEffect(() => {
        if(kakao != null && mapOption !== null && !loaded){ //loaded가 있어야 한번만 init 시킴
        //if(kakao != null && mapOption !== null){
            initMap({
                center: new kakao.maps.LatLng(mapOption.lat, mapOption.lng),
                level: zoomLevel
            });
        }
    }, [kakao, mapOption]);

    useEffect(() => {
        if(store.isLogin){
            if(!_.isEmpty(store.locationInfo)) {
                setMapOption({
                    lat: store.centerPosArr[0],
                    lng: store.centerPosArr[1]
                })
            } else {
                setMapOption({
                    lat: defaultCenterPos[0],
                    lng : defaultCenterPos[1]
                })
            }
        }
        else if(!store.isLogin){
            setMapOption({
                lat: defaultCenterPos[0],
                lng : defaultCenterPos[1]
            })
        }
    }, [store.isLogin, store.locationInfo]);

    useEffect(()=>{
        if(loaded){
            initDrawingManager();
            initClearButton();
            //delayedCenterData();
            // setCenterData();
        }
    }, [loaded]);

    useEffect(() => {
        if(drawingManager != null) {
            if (store.drawType != null) {
                deleteClickLine();
                deleteDistnce();
                deleteCircleDot();

                if("clear" == store.drawType) { //clear all figure
                    //setClearingFigure(true); //clearingFigure effect에서 처리
                    memoizedClearFigure();
                    clearBizarea(1); //상권1 제거
                    clearBizarea(2);//상권2 제거
                    setAutoDetail(true); //자동 통계보기 flag on
                } else if("clear_first" == store.drawType) { //clear first figure //현재 미사용
                    clearFigure(1);
                    clearBizarea(1);
                } else if("clear_second" == store.drawType) { //clear second figure //현재 미사용
                    clearFigure(2);
                    clearBizarea(2);
                } else if("distance" == store.drawType){ //거리재기 기능 //현재 미구현
                    drawingManager.cancel();
                } else { //일반적인 도형 그리기일 경우
                    if(store.drawTyle === lastDrawType) {
                        selectOverlay(store.drawType);
                    }
                }
            } else { //drawtype이 공백이거나 undefined가 지정되었을 때, 최근 drawType으로 지정
                store.setDrawType(lastDrawType);
            }
        }

        return () => {
            if(store.drawType != "clear" && store.drawType != "distance" && store.drawType != "") {
                //일반적인 도형그리기 상태에서 cleanup 되면 lastDrawType을 저장한다
                setLastDrawType(store.drawType);
                // setIsFigureDrawable(true);
            } else {
                setIsFigureDrawable(false);
            }
        };
    }, [store.drawType, lastDrawType]);

    useEffect(()=>{ //actionStatus 제어하는 부분
        if(store.drawFigure2 != null){ //도형이 두개가 그려져 있다면 무조건 status는 3임.
            store.setActionStatus(3);
        }else {
            if (store.isMultiMap) {
                if (store.drawFigure1 != null) { //다중 체크 && 도형1 유효
                    store.setActionStatus(2);
                } else { //다중 체크됐지만 도형 1 없음
                    store.setActionStatus(0);
                }
            } else {
                if (store.drawFigure1 != null) { //다중 체크 풀림 && 도형1 유효
                    store.setActionStatus(1);
                } else { //다중x 도형 1 x
                    store.setActionStatus(0);
                }
            }
        }
        return ()=>{
        }
    }, [store.drawFigure1,store.drawFigure2, store.isMultiMap]);

    useEffect(() => {//kakaomap api의 drawingManager 세팅부분.
        // console.log('drawing event OUTER IF ', Math.random(), drawingManager);
        if(drawingManager != null){
            // console.log('drawing event listening ', Math.random());
            /*
            drawingManager에 이벤트 리스너들을 추가한다.
            */
            drawingManager.addListener('drawstart', function(data){ //도형 그리기 시작 리스너
                let valid = false;
                if((store.locationInfo == null || store.searchAddress == "")) {
                    alert(_errMsg_map);
                    drawingManager.cancel();
                    setIsFigureDrawable(false);
                } else {
                    valid = true;
                }

                if(!(store.searchSpcArr.length > 0)){
                    alert(_errMsg_spc);
                    drawingManager.cancel();
                    setIsFigureDrawable(false);
                    // drawingManager.cancel();
                    // selectOverlay(store.drawType);
                } else {
                    valid = true;
                }

                if(valid){
                }

            });

            drawingManager.addListener('draw', function(data){ //그리는 중 리스너.
                // //console.log("drawmanager draw event", data);
            });

            drawingManager.addListener('drawend', function(data){//도형 그리기 완료 리스너.
                return memoizedDrawEndEvent(data);

            });

            drawingManager.addListener('cancel', function(e){ //도형그리기 취소시
            });

            drawingManager.addListener('remove', function(e){ //도형 삭제시
                if(store.drawFigure1 == null){
                } else if(store.drawFigure1 != null){
                }
            });
        }

        return () => {
            if(drawingManager != null){
                // console.log('clean up - [drawingManager] ');
                setDrawingManager(null);
            } else {

                // alert(2)
            }
        }
    },[drawingManager]);


    const memoizedDrawEndEvent = useCallback((data) => {
        //도형 면적 제한 수치
        let _circleLimit = {min: 30, max: 2000};//원 최소/최대 [반경] 단위:미터
        let _rectangleLimit = {min: 100, max: 4000}; //사각형 최소/최대 [면적] 단위:제곱미터
        let _polygonLimit = {min: 100, max: 4000}; //다각형 최소/최대 [면적] 단위:제곱미터
        //도형 면적 제한 수치 끝

        let validDraw = true; //규격 체크 여부

        let currentFigure = "drawFigure1"; //현재 그려지고있는 도형1,2를 구분해준다.
        if(store.isMultiMap && store.drawFigure1 != null){
            currentFigure = "drawFigure2";
        }

        //도형 종류에 따라 다른 로직으로 validate 처리
        if(data.overlayType == "rectangle") { //사각형 일 경우
            let drawedBounds = data.target.getBounds();
            //rectPath : 사각형 points 구하기. 남서쪽부터 시계방향. @@@@@@@@@
            let rectPath = [
                {lng: drawedBounds.getSouthWest().getLng(), lat:drawedBounds.getSouthWest().getLat()},
                {lng: drawedBounds.getSouthWest().getLng(), lat:drawedBounds.getNorthEast().getLat()},
                {lng: drawedBounds.getNorthEast().getLng(), lat:drawedBounds.getNorthEast().getLat()},
                {lng: drawedBounds.getNorthEast().getLng(), lat:drawedBounds.getSouthWest().getLat()},
            ]
            setPoints(rectPath);

            let drawedMeasure = measure(drawedBounds.getSouthWest().getLat(), drawedBounds.getSouthWest().getLng(),drawedBounds.getNorthEast().getLat(), drawedBounds.getNorthEast().getLng());//사각형 bounds lat lng로 계산된 면적
            if(drawedMeasure < _rectangleLimit.min) {
                alert("그리기 영역의 최소 면적은 "+ _rectangleLimit.min +"m\u00b2 이상이어야 합니다.");
                validDraw = false;
            }
            if(drawedMeasure > _rectangleLimit.max) {
                alert("그리기 영역은 "+ _rectangleLimit.max +"m\u00b2를 초과할 수 없습니다. ");
                validDraw = false;
            }
        } else if(data.overlayType == "polygon") { //다각형 일 경우
            let polygonBounds = [];
            data.target.getPath().map((coord, index)=>{
                polygonBounds.push(coord.toLatLng()) //Ga: lng 경도 x축 /// Ha: lat 위도 y축
            });
            setPoints(polygonBounds);

            // let drawedArea = data.target.getArea();
            let drawedArea = data.target.getArea() ;
            drawedArea = drawedArea/100/3.5;
            if(drawedArea < _polygonLimit.min) {
                alert("그리기 영역의 최소 면적은 "+ _polygonLimit.min +"m\u00b2 이상이어야 합니다.");
                validDraw = false;
            }
            if(drawedArea > _polygonLimit.max) {
                alert("그리기 영역은 "+ _polygonLimit.max +"m\u00b2를 초과할 수 없습니다. ");
                validDraw = false;
            }
        } else { //원일경우
            let drawedCenter = data.target.getPosition();
            // let centerArr = [drawedCenter.Ia, drawedCenter.Ja]; //unused
            setFigureCenterObj({pos_x: getLatLngFromPos(drawedCenter, "lng"), pos_y: getLatLngFromPos(drawedCenter, "lat")});  //circle은 중앙 좌표가 바로 나옴. 경도,위도순
            let drawedRadius = data.target.getRadius();

            if(drawedRadius < _circleLimit.min) {
                alert("그리기 영역의 최소 폭은 "+ _circleLimit.min +"M 이상이어야 합니다.");
                validDraw = false;
            }
            if(drawedRadius > _circleLimit.max) {
                alert("그리기 영역은 "+ _circleLimit.max +"M를 초과할 수 없습니다. ");
                validDraw = false;
            }
        }
        /////도형 validate 끝

        let drawedFigure = data.target; //현재 그려진 도형을 잡아둔다.
        let figureType = data.overlayType; //도형종류

        let figureArr = [drawedFigure, figureType]; //store.drawFigure 형식


        if(validDraw) { //valid
            setMarkerList([])
            if("drawFigure1" === currentFigure){
                store.setHospitalMarkerList([]);
                store.setDrawFigure1(figureArr);

                if(store.isMultiMap) {
                    // drawingManager.cancel();
                    // store.setActionStatus(2);
                }
            }else if("drawFigure2" === currentFigure){
                store.setHospitalMarkerList2([]);
                store.setDrawFigure2(figureArr);
            }

            //도형 그리기가 성공적으로 완료됐을시 마지막 클릭 위치에 다시그리기 버튼 배치
            let posFromData; //다시그리기 버튼 위경도 데이터
            if(data.overlayType == "polygon"){ //polygon은 data에 coord가 없으므로 다른 로직을 이용함
                //http://apis.map.kakao.com/web/documentation/#MapProjection_coordsFromPoint
                //위치 좌표(pixel)에 해당하는 지도 좌표를 반환한다.
                posFromData = map.getProjection().coordsFromPoint(new kakao.maps.Point(data.point.x, data.point.y));
            } else {
                posFromData = data.coords.toLatLng();
            }
            const clearBtnPos = posFromData;

            clearButton.setMap(map);
            clearButton.setPosition(clearBtnPos);
            // drawingManager.cancel();
            if(!store.isMultiMap) {
                setIsDrawSuccessed(true);
            } else {
                setDrawMultiMap(true);
                if(store.drawFigure2 === null) {
                    setIsFigureDrawable(true);
                }
            }
        } else { //도형 invalid
            drawingManager.remove(data.target); //map상의 도형을 제거하고,
            //정보 다시 불러옴
            if("drawFigure1" === currentFigure){
                //그려진 도형이 없다면, 중앙 기반 인접동 가져옴.
                let boundStrObj = {polygon: getFigureBoundString()};
                getNearDongData({variables: boundStrObj});
            }else if("drawFigure2" === currentFigure){
                let boundStrObj = {polygon : getFigureBoundString(figureArr[0],figureArr[1])};
                getNearDongDataFigure1({variables: boundStrObj});
            }

            setZoomLevel(map.getLevel())

            if(map.getLevel() < 5) { //현재 지도 확대 레벨이 1~4일때만 마커 재로드
                displayHospitals(); //drawingManager의 drawend 이벤트
            }


            setIsDrawFailed(true);
        }
    }, [clearButton, store.isMultiMap,store.drawFigure1,store.drawFigure2, drawingManager, getFigureBoundString, getLatLngFromPos, getNearDongData, getNearDongDataFigure1, kakao, lastDrawType, map, store])


    /*
    * ////////읍면동(인접동) / 상권지역은 팀장님이 해주셨습니다.
    * ////////주석이 틀릴 수 있습니다.작업 시 확인필요
    *
    * */
    const getEmdPolygonInfo = (emdListData) => { //인접동 오브젝트 생성
        let emdList = [];
        emdListData.map(item =>{
            const polygonArr = item.polygon.trim().split(",");
            const centroidOfArea = getPolygonCentroid(polygonArr);

            let polygonPath = [];
            polygonArr.map((polygon, index)=>{
                if(index === (polygonArr.length -1)){
                    return false;
                }
                const pos = polygon.trim().split(" ");
                polygonPath.push(new kakao.maps.LatLng(pos[1], pos[0]));
            })

            const polygon = new kakao.maps.Polygon({
                path: polygonPath, // 선을 구성하는 좌표배열 입니다
                strokeWeight: 2, // 선의 두께입니다
                strokeColor: '#FF8000', // 선의 색깔입니다
                strokeOpacity: 0.7, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                strokeStyle: 'longdash', // 선의 스타일입니다
                fillOpacity: 0.05, // 채우기 불투명도 입니다
                //zIndex: 1
            });

            const content = '<div class="emd_name">' + item.emd_name + '</div>',
                position = new kakao.maps.LatLng(centroidOfArea.y, centroidOfArea.x),
                removeable = false;

            const overlay = new kakao.maps.CustomOverlay({
                map: getMap(),
                // map: map,
                position: position,
                content: content,
                yAnchor: 1,
                //zIndex: 1
            });

            const data = {
                polygon: polygon,
                overlay: overlay
            }
            emdList.push(data); //읍면동 데이터 관리하기 위해서 따로 넣어둠
        })
        return emdList;
    }

    const showEmdArea = (emdList) => { //읍면동 오브젝트 보여주기
        if(emdList.length > 0 && map){
            emdList.map(emd => {
                emd.polygon.setMap(map);
                emd.overlay.setMap(map);
            })
        }
    }

    const removeEmdArea = (emdList) => { //읍면동 오브젝트 제거
        if(emdList.length > 0 && map){
            emdList.map(emd => {
                emd.polygon.setMap(null);
                emd.overlay.setMap(null);
            })
        }
    }

    const displayBizarea = (figureNo) => { //도형에 해당하는 상권지역 데이터 가져오기
        if (map != null) {
            const targetObj = store['drawFigure'+figureNo][0];
            const figureType = store['drawFigure'+figureNo][1];

            let refObj = (targetObj != null) ? targetObj : map; // bound를 가져올 기준 object. 기본값은 map
            if(figureType == "rectangle") {
                const bound = refObj.getBounds();
                getBizareaData({variables: {
                        shape: "RECT",
                        rect_info: {
                            min_x: bound.getSouthWest().getLng().toString(),
                            min_y: bound.getSouthWest().getLat().toString(),
                            max_x: bound.getNorthEast().getLng().toString(),
                            max_y: bound.getNorthEast().getLat().toString(),
                        },
                        circle_info: null
                    }});
            }
            else if(figureType == "circle") {
                const centerArr = [getLatLngFromPos(refObj.getPosition(), "lng"), getLatLngFromPos(refObj.getPosition(), "lat")]; //Ha: lat, Ga: lng 경도,위도 순
                const drawedRadius = refObj.getRadius();
                getBizareaData({variables: {
                        shape: "CIRCLE",
                        circle_info: {
                            center_x: centerArr[0].toString(),
                            center_y: centerArr[1].toString(),
                            radius: parseInt(drawedRadius)
                        },
                        rect_info: null
                    }});
            }
        }
    }

    useEffect(()=>{ //상권지역 그려주는 부분
        if(!endpointBizareaLoading && endpointBizarea){
            const bizareaDataList = endpointBizarea.getBizareaList;
            const bizareaList = [];
            bizareaDataList.map(item=>{
                const polygonArr = item.polygon.trim().split(",");
                let polygonPath = [];
                polygonArr.map((polygon, index)=>{
                    if(index === (polygonArr.length -1)){
                        return false;
                    }
                    const pos = polygon.trim().split(" ");
                    polygonPath.push(new kakao.maps.LatLng(pos[1], pos[0]));
                })
                let polygon = new kakao.maps.Polygon({
                    path: polygonPath, // 선을 구성하는 좌표배열 입니다
                    strokeWeight: 2, // 선의 두께입니다
                    strokeColor: '#39DE2A', // 선의 색깔입니다
                    strokeOpacity: 0.6, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                    strokeStyle: 'longdash', // 선의 스타일입니다
                    fillColor: '#A2FF99', // 채우기 색깔입니다
                    fillOpacity: 0.4, // 채우기 불투명도 입니다
                    zIndex: 1
                });
                const data = {
                    area_name: item.area_name,
                    polygon: polygon
                }
                bizareaList.push(data);
            })

            if(store.drawFigure1 && !store.drawFigure2){
                setBizareaList1(bizareaList);
            }
            else if(store.drawFigure1 && store.drawFigure2){
                setBizareaList2(bizareaList);
            }
        }
    }, [endpointBizareaLoading, endpointBizarea]);

    const showBizarea = (bizareaList) => { //상권지역 보여주기
        if(bizareaList.length > 0 && map){
            bizareaList.map(bizarea => {
                bizarea.polygon.setMap(map);

                let overlay = new kakao.maps.CustomOverlay({
                    xAnchor: 0,
                    yAnchor: 0,
                    //zIndex: 1
                }); //상권명 표시용

                const positionBumper = 0.0002;
                kakao.maps.event.addListener(bizarea.polygon, 'mouseover', function(mouseEvent) {
                    overlay.setContent('<div class="area" style="background:#008080;color:#FFFFFF;padding:5px;border-radius:5px;"><span>[상권지역]</span> ' + bizarea.area_name + '</div>');
                    overlay.setPosition(new kakao.maps.LatLng(mouseEvent.latLng.getLat() + positionBumper, mouseEvent.latLng.getLng() + positionBumper));
                    overlay.setMap(map);
                });

                kakao.maps.event.addListener(bizarea.polygon, 'mousemove', function(mouseEvent) {
                    overlay.setPosition(new kakao.maps.LatLng(mouseEvent.latLng.getLat() + positionBumper, mouseEvent.latLng.getLng() + positionBumper));
                });

                kakao.maps.event.addListener(bizarea.polygon, 'mouseout', function() {
                    overlay.setMap(null);
                });
            })
        }
    }

    const removeBizarea = (bizareaList) => { //상권지역 제거 // 현재 미사용
        if(bizareaList.length > 0 && map){
            bizareaList.map(bizarea => {
                bizarea.polygon.setMap(null);
            })
        }
    }

    /*
    * 상권지역 1/2 표현
    * */
    useEffect(()=>{
        if(bizareaList1.length > 0) {
            showBizarea(bizareaList1);
        }
        return () => {
            clearBizarea(1)
        }
    }, [bizareaList1]);

    useEffect(()=>{
        if(bizareaList2.length > 0) {
            showBizarea(bizareaList2);
        }
        return () => {
            clearBizarea(2)
        }
    }, [bizareaList2]);

    /*
    * //상권지역 1/2 표현
    * */



    /*
    * 도형 그려졌을 시 관련 데이터 가져오는 부분
    * 인접동 / actionStatus 세팅
    * */
    useEffect(()=>{
        if(store.drawFigure1 != null){ //도형1그릴시 도형 범위 세팅해줌
            setMarkerList([]);
            ////도형에 따른 인접동 가져오기
            let boundStrObj = {polygon : getFigureBoundString(store.drawFigure1[0],store.drawFigure1[1])};
            getNearDongDataFigure1({variables: boundStrObj});

            //상권 정보
            displayBizarea(1);
            if(store.isMultiMap) {
                store.setActionStatus(2);
            } else {
                store.setActionStatus(1);
            }
            hideCenterMarker();

            displayHospitals();
        }

        return () => {
            setLocalDrawFigure1(store.drawFigure1);
            setAutoDetail(true); //200602
        }
    }, [store.drawFigure1]);

    const getPolygonCentroid = (points) => {
        let centroid = {x: 0, y: 0};
        for(var i = 0; i < points.length; i++) {
            let point = points[i].split(" ");
            centroid.x += parseFloat(point[0], 10);
            centroid.y += parseFloat(point[1], 10);
        }
        centroid.x /= points.length;
        centroid.y /= points.length;
        return centroid;
    }


    /**
     * 인접동 데이터 유무로 표현해주는 부분
     */
    useEffect(()=>{
        if(map && nearDongFigure1Data){
            const emdareaList = getEmdPolygonInfo(nearDongFigure1Data.getDongList);
            setEmdareaList1(emdareaList);
        }
    }, [nearDongFigure1Data]);

    useEffect(()=>{
        if(map && nearDongFigure2Data){
            const emdareaList = getEmdPolygonInfo(nearDongFigure2Data.getDongList);
            setEmdareaList2(emdareaList);
        }
    }, [nearDongFigure2Data]);

    useEffect(()=>{
        if(map && emdareaList1){
            showEmdArea(emdareaList1);
        }
    }, [emdareaList1]);

    useEffect(()=>{
        if(map && emdareaList2) {
            showEmdArea(emdareaList2);
        }
    }, [emdareaList2]);

    useEffect(()=>{
        if(store.drawFigure2 != null){ //도형2그릴시 도형 범위 세팅해줌
            if(!historyFigure1Flag) {
                setMarkerList([]);
            }
            displayHospitals() //store.drawFigure2 useeffect

            ////도형에 따른 인접동 가져오기
            let boundStrObj = {polygon : getFigureBoundString(store.drawFigure2[0],store.drawFigure2[1])};
            getNearDongDataFigure2({variables: boundStrObj});

            //상권 정보
            displayBizarea(2);

            // drawingManager.cancel();
            hideCenterMarker();
        }

        return () => {
            setLocalDrawFigure2(store.drawFigure2);
            setAutoDetail(true); //200602

        }
    }, [store.drawFigure2]);

    useEffect(()=>{
        if(store.nearDongList != null){ //도형그릴시 도형 범위 세팅해줌
        }
    }, [store.nearDongList]);

    /*
    도형 관련 데이터 세팅 끝
     */


    /*
    * 임대분양 데이터 표현하는 부분 (lease)
    * */
    useEffect(()=>{
        if(kakao != null && map != null) {
            /*211003 요청으로 제거*/

            if(1==2) {
                clearLeaseOverlay(); //  leaseOverlay 리셋

                let overlayList = [];
                store.leaseMarkerList.map(item => {
                    if (item.pos_x && item.pos_y) {
                        let contentElm = document.createElement("div");
                        contentElm.innerHTML = '<span class="textinfo"><a href="http://' + getMgDomain() + '/lease/' + item.lease_idx + '" target="_blank">' + item.title + '</a></span></span>';
                        contentElm.className = 'overlay lease';
                        contentElm.addEventListener("mouseup", () => {
                            CommonFunc.pageview('입지분석', '임대분양 이동', '' + item.lease_idx + '')
                        }, false);
                        //const content = '<div class="overlay lease"><span class="textinfo"><a href="http://www.medigate.net/cbiz/lease/detail?leaseIdx=' + item.lease_idx + '" target="_blank">' + item.title + '</a></span></div>';
                        const overlay = new kakao.maps.CustomOverlay({
                            position: new kakao.maps.LatLng(item.pos_y, item.pos_x),
                            //position: position,
                            content: contentElm,
                            yAnchor: 1
                        });
                        overlayList.push(overlay);
                    }
                })
                setLeaseOverlayList(overlayList);
            }
        }

        return () => {
        }
    }, [store.leaseMarkerList]);

    useEffect(()=>{
        displayLeaseOverlay(leaseOverlayList);
    }, [leaseOverlayList]);

    const displayLeaseOverlay = (leaseOverlayList) => {
        leaseOverlayList.map(overlay => {
            overlay.setMap(map);
        })
    }


    /*
    * 임대분양 데이터 표현하는 부분 (lease) 끝
    * */



    /*
    * kakaomap 오브젝트에 대한 effect
    * 옵션 설정, 리스너 설정
    * */
    useEffect(()=>{
        if(map != null){
            //rvMarker.setPosition(map.getCenter());

            setPlaceSvc(new kakao.maps.services.Places(map));
            map.setMaxLevel(_mapMaxZoomLevel); //최대레벨제한


            //center_changed / bounds_changed 차이??
            // kakao.maps.event.addListener(map, 'center_changed', function () { //맵 중심값 변경 listener
            kakao.maps.event.addListener(map, 'bounds_changed', function () { //맵 중심값 변경 listener
                let latlng = map.getCenter();
                delayedSetCenterPos([latlng.getLat(), latlng.getLng()]);
            });

            /*거리재기를 위한 이벤트 리스너*/
            /*kakao.maps.event.addListener(map, 'click', function(mouseEvent) {
                let clickPosition = mouseEvent.latLng;

                if(store.drawType === "distance"){
                    if (!drawingFlag) {
                        // 상태를 true로, 선이 그리고있는 상태로 변경합니다
                        drawingFlag = true;
                        // 지도 위에 선이 표시되고 있다면 지도에서 제거합니다
                        deleteClickLine();
                        // 지도 위에 커스텀오버레이가 표시되고 있다면 지도에서 제거합니다
                        deleteDistnce();
                        // 지도 위에 선을 그리기 위해 클릭한 지점과 해당 지점의 거리정보가 표시되고 있다면 지도에서 제거합니다
                        deleteCircleDot();

                        // 클릭한 위치를 기준으로 선을 생성하고 지도위에 표시합니다
                        clickLine = new kakao.maps.Polyline({
                            // map: map, // 선을 표시할 지도입니다 200602
                            path: [clickPosition], // 선을 구성하는 좌표 배열입니다 클릭한 위치를 넣어줍니다
                            strokeWeight: 3, // 선의 두께입니다
                            strokeColor: '#db4040', // 선의 색깔입니다
                            strokeOpacity: 1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
                            strokeStyle: 'solid' // 선의 스타일입니다
                        });

                        // 선이 그려지고 있을 때 마우스 움직임에 따라 선이 그려질 위치를 표시할 선을 생성합니다
                        moveLine = new kakao.maps.Polyline({
                            strokeWeight: 3, // 선의 두께입니다
                            strokeColor: '#db4040', // 선의 색깔입니다
                            strokeOpacity: 0.5, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
                            strokeStyle: 'solid' // 선의 스타일입니다
                        });

                        // 클릭한 지점에 대한 정보를 지도에 표시합니다
                        displayCircleDot(clickPosition, 0);


                        setClickLineState([clickLine]);
                    } else { // 선이 그려지고 있는 상태이면

                        // 그려지고 있는 선의 좌표 배열을 얻어옵니다
                        var path = clickLine.getPath();

                        // 좌표 배열에 클릭한 위치를 추가합니다
                        path.push(clickPosition);

                        // 다시 선에 좌표 배열을 설정하여 클릭 위치까지 선을 그리도록 설정합니다
                        clickLine.setPath(path);
                        // setClickLineState(oldArr=>[...oldArr, path]);
                        // setClickLineState(oldArr=>[oldArr, path]);
                        setClickLineState([clickLine, path]);
                        // appendClickLineState(path);


                        var distance = Math.round(clickLine.getLength());
                        displayCircleDot(clickPosition, distance);
                    }
                }
            });*/

            kakao.maps.event.addListener(map, 'mousemove', function(mouseEvent) {
                if(store.drawType === "distance") {
                    // 지도 마우스무브 이벤트가 발생했는데 선을 그리고있는 상태이면
                    if (drawingFlag) {

                        // 마우스 커서의 현재 위치를 얻어옵니다
                        var mousePosition = mouseEvent.latLng;

                        // 마우스 클릭으로 그려진 선의 좌표 배열을 얻어옵니다
                        var path = clickLine.getPath();

                        // 마우스 클릭으로 그려진 마지막 좌표와 마우스 커서 위치의 좌표로 선을 표시합니다
                        var movepath = [path[path.length - 1], mousePosition];
                        moveLine.setPath(movepath);
                        moveLine.setMap(map);

                        var distance = Math.round(clickLine.getLength() + moveLine.getLength()), // 선의 총 거리를 계산합니다
                            content = '<div class="dotOverlay distanceInfo">총거리 <span class="number">' + distance + '</span>m</div>'; // 커스텀오버레이에 추가될 내용입니다

                        // 거리정보를 지도에 표시합니다
                        showDistance(content, mousePosition);
                    }
                }
            });

            kakao.maps.event.addListener(map, 'rightclick', function(mouseEvent) {
                if(store.drawType === "distance") {
                    // 지도 오른쪽 클릭 이벤트가 발생했는데 선을 그리고있는 상태이면
                    if (drawingFlag) {

                        // 마우스무브로 그려진 선은 지도에서 제거합니다
                        moveLine.setMap(null);
                        moveLine = null;

                        // 마우스 클릭으로 그린 선의 좌표 배열을 얻어옵니다
                        var path = clickLine.getPath();

                        // 선을 구성하는 좌표의 개수가 2개 이상이면
                        if (path.length > 1) {

                            // 마지막 클릭 지점에 대한 거리 정보 커스텀 오버레이를 지웁니다
                            if (dots[dots.length-1].distance) {
                                dots[dots.length-1].distance.setMap(null);
                                dots[dots.length-1].distance = null;
                            }

                            var distance = Math.round(clickLine.getLength()), // 선의 총 거리를 계산합니다
                                content = getTimeHTML(distance); // 커스텀오버레이에 추가될 내용입니다

                            // 그려진 선의 거리정보를 지도에 표시합니다
                            showDistance(content, path[path.length-1]);

                        } else {

                            // 선을 구성하는 좌표의 개수가 1개 이하이면
                            // 지도에 표시되고 있는 선과 정보들을 지도에서 제거합니다.
                            deleteClickLine();
                            deleteCircleDot();
                            deleteDistnce();

                        }

                        // 상태를 false로, 그리지 않고 있는 상태로 변경합니다
                        drawingFlag = false;

                        selectOverlay(lastDrawType)
                    }
                }
            });

            // ksseo zommLevel에 따른 마커 클러스터
            kakao.maps.event.addListener(map, 'zoom_changed', function() {
                let level = map.getLevel();
                setZoomLevel(level);
                store.setZoomLevel(level);

                if(map != null){
                    if(store.drawFigure1 == null){
                        setMarkerList([]);

                        if(level > 4 && level < 7){
                            // 시군구
                            /*getHospitalClusterSgg({
                                variables: getParam()
                            });*/
                        }else if(level > 6 && level < 10){
                            // 읍면동
                            /*getHospitalClusterEmd({
                                variables: getParam()
                            });*/
                        } else {
                            store.setActionStatus(0);
                        }

                    } else {
                        // displayHospitals(); //map event listener zoomChanged event
                    }
                }
            })
            setFigureBound();


            /*
            * GPS 체크하는 부분
            * //chrome 이외의 브라우저에서는 위치가 이상하게 잡혀서 일시 비활성화 해둠
            * */
            if('geolocation' in navigator) {
                /* 위치정보 사용 가능 */

                if(!store.isLogin ) {
                    //크롬을 사용하는 비회원일시 gps기반 위치정보 가져와서 세팅해줌
                    //타 브라우저는 GPS 기반 위치 인식이 이상함.
                    if(isChrome) {
                        navigator.geolocation.getCurrentPosition((position) => {
                                // store.setCenterPosArr([position.coords.latitude, position.coords.longitude]);
                                let geocoder = new kakao.maps.services.Geocoder();

                                geocoder.coord2RegionCode(position.coords.longitude, position.coords.latitude,
                                    (result, status) => {
                                        if (result.length > 0) {
                                            store.setSearchAddress(result[0].address_name);
                                            //setGpsSetted(true);
                                        }
                                    }); //lng, lat, callbackFunction(result, status)
                            },
                            function(error) {
                                if (error.code == error.PERMISSION_DENIED){
                                    store.setSearchAddress(_defaultAddr);
                                }
                            });
                    } else {
                        store.setSearchAddress(_defaultAddr);
                    }
                }
            } else {
                /* 위치정보 사용 불가능 */
                store.setSearchAddress(_defaultAddr);
            }
            /* 임대분양 상세에서 넘어오는 주소 있을 시 처리 */
            // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@22222222222222222222 useQuery();",locInfo, new URLSearchParams(locInfo.search).get("leaseaddr"), (new URLSearchParams(locInfo.search).get("leaseaddr") != null));
            let addrFromLease = getLeaseAddr();
            if(addrFromLease != null && addrFromLease != ""){
                //store.setSearchAddress(addrFromLease);
                // console.log("333useQuery();",addrFromLease );
            }


            setCenterData();
        }
        return ()=>{
            if(map != null){
                /*
                map cleanup 동작
                안해주면 다른페이지 갔다 와서 초기화 됐을 때 이상동작 함.
                * */
                store.setIsMultiMap(false);
                store.setDrawFigure1(null);
                store.setDrawFigure2(null);
                store.setDrawType(null);
                store.setHospitalMarkerList([]);
                store.setHospitalMarkerList2([]);
                store.setFigureNearDongList([]);
                store.setFigureNearDongList2([]);
                store.setNearDongList(null);
                store.setfigureLocationInfo(null);
                store.setfigureLocationInfo2(null);
                store.setFigureBound(null);
                store.setFigureBound2(null)
                store.setFigureBoundArr([]);
                store.setKakao(null);
            }
        }
    }, [map]);

    /* react router query parameter 가져오기 관련
    * 참고 url : https://reactrouter.com/web/example/query-parameters
    * */
    // A custom hook that builds on useLocation to parse
    // the query string for you.

    /*function getLeaseAddr(){
        //return new URLSearchParams(locInfo.search).get("leaseaddr");
        // return "testLeaseAddr";
        return leaseAddr;
    }*/
    const getLeaseAddr = useCallback(()=>(
        leaseAddr
    ), [leaseAddr])

    /*
    * 거리재기 관련 함수들 //현재 미구현
    * */
    // 클릭으로 그려진 선을 지도에서 제거하는 함수입니다
    function deleteClickLine() {
        if (clickLine) {
            clickLine.setMap(null);
            clickLine = null;
        }
        clearClickLineState();
    }
    function clearClickLineState() {
        clickLineState.map((item, i)=>{
            if(i == 0) {
                item.setPath([]);
                item.setMap(null);
            }
        });
        setClickLineState([]);
        // setClickLineState(null);
    }

// 마우스 드래그로 그려지고 있는 선의 총거리 정보를 표시하거
// 마우스 오른쪽 클릭으로 선 그리가 종료됐을 때 선의 정보를 표시하는 커스텀 오버레이를 생성하고 지도에 표시하는 함수입니다
    function showDistance(content, position) {

        if (distanceOverlay) { // 커스텀오버레이가 생성된 상태이면
            // 커스텀 오버레이의 위치와 표시할 내용을 설정합니다
            distanceOverlay.setPosition(position);
            distanceOverlay.setContent(content);

        } else { // 커스텀 오버레이가 생성되지 않은 상태이면

            // 커스텀 오버레이를 생성하고 지도에 표시합니다
            distanceOverlay = new kakao.maps.CustomOverlay({
                map: getMap(), // 커스텀오버레이를 표시할 지도입니다
                // map: map, // 커스텀오버레이를 표시할 지도입니다
                content: content,  // 커스텀오버레이에 표시할 내용입니다
                position: position, // 커스텀오버레이를 표시할 위치입니다.
                xAnchor: 0,
                yAnchor: 0,
                zIndex: 3
            });
        }
    }

// 그려지고 있는 선의 총거리 정보와
// 선 그리가 종료됐을 때 선의 정보를 표시하는 커스텀 오버레이를 삭제하는 함수입니다
    function deleteDistnce () {
        if (distanceOverlay) {
            distanceOverlay.setMap(null);
            distanceOverlay = null;
        }
    }

// 선이 그려지고 있는 상태일 때 지도를 클릭하면 호출하여
// 클릭 지점에 대한 정보 (동그라미와 클릭 지점까지의 총거리)를 표출하는 함수입니다
    function displayCircleDot(position, distance) {

        // 클릭 지점을 표시할 빨간 동그라미 커스텀오버레이를 생성합니다
        var circleOverlay = new kakao.maps.CustomOverlay({
            content: '<span class="dot"></span>',
            position: position,
            zIndex: 1
        });

        // 지도에 표시합니다
        // circleOverlay.setMap(map);

        if (distance > 0) {
            // 클릭한 지점까지의 그려진 선의 총 거리를 표시할 커스텀 오버레이를 생성합니다
            var distanceOverlay = new kakao.maps.CustomOverlay({
                content: '<div class="dotOverlay">거리 <span class="number">' + distance + '</span>m</div>',
                position: position,
                yAnchor: 1,
                zIndex: 2
            });

            // 지도에 표시합니다
            // distanceOverlay.setMap(map); //200602 state effect에서 처리
        }

        // 배열에 추가합니다
        dots.push({circle:circleOverlay, distance: distanceOverlay});
        setDotsState(oldArr=>[...oldArr, {circle:circleOverlay, distance: distanceOverlay}])
    }

// 클릭 지점에 대한 정보 (동그라미와 클릭 지점까지의 총거리)를 지도에서 모두 제거하는 함수입니다
    function deleteCircleDot() {
        var i;

        for ( i = 0; i < dots.length; i++ ){
            if (dots[i].circle) {
                dots[i].circle.setMap(null);
            }

            if (dots[i].distance) {
                dots[i].distance.setMap(null);
            }
        }

        dots = [];
        //setDotsState([]);
        clearCircleDotState();
    }
    function clearCircleDotState(){
        dotsState.map((item, i)=>{
            item.circle.setMap(null);
            if(item.distance != null) {
                item.distance.setMap(null);
            }
        });
        setDotsState([]);
    }
    function appendClickLineState(path){
        clickLineState.setPath(path);
    }

// 마우스 우클릭 하여 선 그리기가 종료됐을 때 호출하여
// 그려진 선의 총거리 정보와 거리에 대한 도보, 자전거 시간을 계산하여
// HTML Content를 만들어 리턴하는 함수입니다
    function getTimeHTML(distance) {

        // 도보의 시속은 평균 4km/h 이고 도보의 분속은 67m/min입니다
        var walkkTime = distance / 67 | 0;
        var walkHour = '', walkMin = '';

        // 계산한 도보 시간이 60분 보다 크면 시간으로 표시합니다
        if (walkkTime > 60) {
            walkHour = '<span class="number">' + Math.floor(walkkTime / 60) + '</span>시간 '
        }
        walkMin = '<span class="number">' + walkkTime % 60 + '</span>분'

        // 자전거의 평균 시속은 16km/h 이고 이것을 기준으로 자전거의 분속은 267m/min입니다
        var bycicleTime = distance / 227 | 0;
        var bycicleHour = '', bycicleMin = '';

        // 계산한 자전거 시간이 60분 보다 크면 시간으로 표출합니다
        if (bycicleTime > 60) {
            bycicleHour = '<span class="number">' + Math.floor(bycicleTime / 60) + '</span>시간 '
        }
        bycicleMin = '<span class="number">' + bycicleTime % 60 + '</span>분'

        // 거리와 도보 시간, 자전거 시간을 가지고 HTML Content를 만들어 리턴합니다
        var content = '<ul class="dotOverlay distanceInfo">';
        content += '    <li>';
        content += '        <span class="label">총거리</span><span class="number">' + distance + '</span>m';
        content += '    </li>';
        content += '    <li>';
        content += '        <span class="label">도보</span>' + walkHour + walkMin;
        content += '    </li>';
        content += '    <li>';
        content += '        <span class="label">자전거</span>' + bycicleHour + bycicleMin;
        content += '    </li>';
        content += '</ul>'

        return content;
    }
    /*//
    거리재기 관련 함수들 끝
    //////////////////
    * */

    /* 카카오 위치 api에서 @위도, @경도 각각 추출하는 함수*/
    //!!위도 0~90 latitude
    //!!경도 0~180 longitude

    //인자로 카카오api 위치객체와 latlngStr (lat,lng,latitude,longitude 모도가능) 받음
    function getLatLngFromPos(posObj, latlngStr){
        //latlngStr - lat,lng,latitude,longitude 모도가능
        let result = null;

        if(latlngStr == "lat" || latlngStr == "latitude") {
            result = getLatitudeFromPosition(posObj);
        }if(latlngStr == "lng" || latlngStr == "longitude") {
            result = getLongitudeFromPosition(posObj);
        }

        return result;
    }

    function getLatitudeFromPosition(posObj){
        let key = Object.keys(posObj)[1]//카카오 getPosition api의 두번째 항목 - 위도
        return posObj[key];//위도 0~90
    }
    function getLongitudeFromPosition(posObj){
        let key = Object.keys(posObj)[0]//카카오 getPosition api의 첫번째 항목 - 경도
        return posObj[key];//경도 0~180
    }
    /* 끝 :: 카카오 위치 api에서 위, 경도 각각 추출하는 함수*/


    //centerPosArr(맵 중심좌표) 가 바뀌면 해당 데이터 가져옴옴
   useEffect(
        () => {
            delayedCenterData();
        },
        [store.centerPosArr]
    );
   useEffect(
        () => {
            // delayedCenterData();
        },
        [store.centerPosArr, store.locationInfo]
    );


   /*이하 임대분양에서오는 주소연동*/
    const getMgDomain = () => { //메디게이트 도메인 가져오기
        let mgDomain = "www.medigate.net";
        if(process.env.REACT_APP_ENV_TYPE === 'test'){
            mgDomain = "t-" + mgDomain;
        }

        return mgDomain;
    }
   useEffect(()=>{
       if(leaseAddr != null && leaseAddr != "" && map != null){
           store.setHasLeaseAddr(true);

           displayHospitals();
       }
   }, [leaseAddr,map]);

   useEffect(()=>{
       if(store.hasLeaseAddr && map != null){
       // if(store.hasLeaseAddr && map != null && isInitialized){
           store.setLeaseAddr(leaseAddr);
           store.setSearchAddress(leaseAddr);
           displayHospitals();

       }
   }, [store.hasLeaseAddr ,map]);
    useEffect(() => { //로딩후 화면 중앙에 마커 그려줌, 로직문제로 2칸짜리 배열에 넣어줌
        let defaultCheck = (store.centerPosArr[0] != defaultCenterPos[0] && store.centerPosArr[1] != defaultCenterPos[1]);
        // console.log("임대주소마커 초기",addrCenterMarker,defaultCheck);
        /*211003 요청으로 제거*/
        let forceDisable = 1==2;
        if(store.searchAddress == store.leaseAddr && defaultCheck && addrCenterMarkerList[1] == null && isInitialized && store.hasLeaseAddr && forceDisable) {
        // if(store.searchAddress == store.leaseAddr && addrCenterMarker == null && !defaultCheck) {
            let currentCenterMarkerArr =addrCenterMarkerList;
            let dummyMarkerIsExist = currentCenterMarkerArr[0] == null; //배열의 첫번쨰 아이템ㅂ은 더미 마커인데, 존재하는지 체크함
            let addrCenterMarkerImg = new kakao.maps.MarkerImage(_leaseAddrCenterMarkerObj.url, new kakao.maps.Size(_leaseAddrCenterMarkerObj.width, _leaseAddrCenterMarkerObj.height));

            let targetMap = dummyMarkerIsExist ? null : map; //더미가 없다면 더미 세팅을 위해 맵을 null로 해주고 아니면 제대로 세팅 해줌

            let addrCenterMarker = new kakao.maps.Marker({
                map: targetMap, //마커달 카카오맵
                position: new kakao.maps.LatLng(store.centerPosArr[0], store.centerPosArr[1]),//마커 위경도
                // position: map.getCenter(),//마커 위경도
                image: addrCenterMarkerImg,
                zIndex: 9,
            });
            // setAddrCenterMarker(addrCenterMarker);

            currentCenterMarkerArr.push(addrCenterMarker);
            setAddrCenterMarkerList(currentCenterMarkerArr);
            // console.log("임대주소마커",addrCenterMarker);

 /*           if(!dummyMarkerIsExist){ //더미가 없다면, 실제 마커가 찍힌것이므로 병원출력함
                displayHospitals();
            }*/
        }
        return () => {
        };
    }, [store.searchAddress, store.leaseAddr, store.hasLeaseAddr, store.hospitalMarkerList,isInitialized]);
    useEffect(() => { //
        if(addrCenterMarkerList.length > 1) {
            addrCenterMarkerList[0].setMap(null);
            addrCenterMarkerList[0] = null;
            displayHospitals();
        }
        return () => {
        };
    }, [addrCenterMarkerList]);
    /* @@@@@@@@@@@@@@@@@@@@@@@@@ */
/*
//210916 검색마커
useEffect(
        () => {
            // delayedCenterData();
            let addrFromLease = getLeaseAddr();
            if(addrFromLease != null && addrFromLease != ""){
                console.log("[store.searchAddress]", addrFromLease, store.searchAddress);
                if(addrCenterMarker != null){
                    addrCenterMarker.setMap(null);
                    setAddrCenterMarker(null);
                }
                if(addrFromLease == store.searchAddress){
                    console.log("임대에서 주소ㅓ검색");


                    let addrCenterMarkerImg = new kakao.maps.MarkerImage(_addrCenterMarkerObj.url, new kakao.maps.Size(_addrCenterMarkerObj.width, _addrCenterMarkerObj.height));

                    let addrCenterMarker = new kakao.maps.Marker({
                        map: map, //마커달 카카오맵
                        position: map.getCenter(),//마커 위경도
                        image: addrCenterMarkerImg,
                        zIndex: 3,
                    });
                    setAddrCenterMarker(addrCenterMarker);
                    console.log("임대주소마커",addrCenterMarker);
                }
            }
        },
        [store.searchAddress]
    );*/



   /*
   맵 이벤트 리스너에서 중앙좌표 변경시 호출해줌
   * */
    //delay function call
    // const delayedSetCenterPos = debounce(250,false, (arg) => {
    const delayedSetCenterPos = throttle(500,false, (arg) => {
        /////200515 도형조건
        if((store.drawHistoryFigure1 == null && store.drawFigure1 != null) || (store.drawHistoryFigure2 == null && store.drawFigure2 != null)){

        } else {
        }
            store.setCenterPosArr(arg);
    },false);




    /*
    * 병의원 데이터 불러오기
    * */
    // const delayedHosData = debounce(250,true, () => {
    const delayedHosData = throttle(500,false, () => {
        if(!loadingFlag) {
            let isExistFigure = ((store.drawFigure1 != null) || (store.drawFigure2 != null));

            if(isInitialized) {//초기화 여부 체크 후 마커 리스트 불러오도록
                getMarkerListByZoom(zoomLevel, isExistFigure);
            }
        }
    })

    /*
    * 중심좌표 변경됨에 따라 데이터 가져오는 함수
    * */
    // const delayedCenterData = debounce(250, true, () => {
    /*const delayedCenterData = throttle(250, false, () => {
        if(map != null) {
            //map이 유효할때 center 관련 장소 정보 가져옴
            let coordObj = {pos_x:""+map.getCenter().getLng(), pos_y:""+map.getCenter().getLat()};
            // if(isInitialized) {
                getLocationInfo({variables: coordObj});
            // }
            if(store.drawFigure1 == null && map.getLevel() <= 4) {
                //그려진 도형이 없다면, 중앙 기반 인접동 가져옴.
                let boundStrObj = {polygon: getFigureBoundString()};
                getNearDongData({variables: boundStrObj});
            }
        }
    }, false)*/

    const setCenterData = useCallback(() => {
        if(getMap() != null) {
            //map이 유효할때 center 관련 장소 정보 가져옴
            let coordObj = {pos_x:""+map.getCenter().getLng(), pos_y:""+map.getCenter().getLat()};
            // if(isInitialized) {
            getLocationInfo({variables: coordObj});
            // }
            if(store.drawFigure1 == null && map.getLevel() <= 4) {
                //그려진 도형이 없다면, 중앙 기반 인접동 가져옴.
                let boundStrObj = {polygon: getFigureBoundString()};
                getNearDongData({variables: boundStrObj});
            }
        }}, [getFigureBoundString, getLocationInfo, getNearDongData, map, store.drawFigure1]);

    const delayedCenterData = useCallback(()=>{
        return  throttle(250, false, ()=> {
            setCenterData();
        }, false)
    }, [getFigureBoundString, getLocationInfo, getNearDongData, map, store.drawFigure1]);





    /*
    * 병의원 정보 표현해주기
    * */
    const displayHospitals = () => {
        if (map != null) {
            let defaultCheck = (store.centerPosArr[0] != defaultCenterPos[0] && store.centerPosArr[1] != defaultCenterPos[1]);
            if (defaultCheck) { //맵 첫번째 로딩시
/*                let mapMoveFlag = (!clearingFigure && (store.drawFigure1 == null && store.drawFigure2 == null));
                if(mapMoveFlag) {*/
                let moveLatLon = new kakao.maps.LatLng(store.centerPosArr[0], store.centerPosArr[1]);

                if(clearingFigure) {
                    if(store.drawFigure1 != null || store.drawFigure2 != null){
                        map.setCenter(moveLatLon);
                    }
                } else {
                    map.setCenter(moveLatLon);
                }
            }

            if(store.drawFigure1 != null && store.drawFigure2 == null){ //도형이 1만 있을 경우
                setFigureBound(store.drawFigure1[0], store.drawFigure1[1]);//drawFigure format : [도형obj, "도형타입"]
            } else if(store.drawFigure1 != null && store.drawFigure2 != null){ //도형이 두개 다 있을 경우
                setFigureBound(store.drawFigure2[0], store.drawFigure2[1], true);//drawFigure format : [도형obj, "도형타입"]
            } else { //도형이 없는 경우
                setFigureBound();
            }

            if (store.searchSpcArr.length > 0) { //전문과 선택이 되어있을 경우 병의원 정보 가져옴
                if(!loadingFlag) {
                    delayedHosData();
                }
            } else if (store.searchSpcArr.length == 0) { //전문과 선택이 한개도 되어있지 않다면 마커를 모두 제거함
                if(!loadingFlag) {
                    setMarkerList([]);
                }
            }
        }
    }



    /*
    * 마커리스트를 줌 레벨에 따라 가져옴
    * */
    const getMarkerListByZoom = (zoom, forceHos) => { //
        let isExistFigure = ((store.drawFigure1 != null ) || (store.drawFigure2 != null));
        let existFigureBound = (store.figureBound != null && store.figureBound != '');
        let isRoadview = roadviewOn;
        if(existFigureBound) {
            if(!isRoadview) { //로드뷰가 아닐때만 정보 가져옴 210806
                if(((zoom > 0 && zoom <= 4) && store.actionStatus != 4) || forceHos){ // 줌 레벨이 1~4거나, 도형이 있을 경우는 일반 병의원 가져옴
                    let isSecondFigureExist = (store.drawFigure2 != null);
                    getHosData({variables: getParam(isSecondFigureExist)});
                } else if (zoom >= 5 && zoom <= 6 && !isExistFigure && !forceHos) { // 줌 레벨이 5~6이라면 읍면동 클러스터 가져옴
                    getHospitalClusterEmd({variables: getParam()});
                } else if (zoom >= 7 && zoom <= 8 && !isExistFigure && !forceHos) {  // 줌 렙레이 7~8이라면 시군구 클러스터 가져옴
                    getHospitalClusterSgg({variables: getParam()});
                }
            }
        }

        if(!isInitialized){
            setIsInitialized(true); //초기 로딩 체크 테스트.
        }
    }

    useEffect(() => {
        if(markerList.length < 1) {
            // getMarkerListByZoom(zoomLevel);
        }
        if(isInitialized){

        }
    }, [isInitialized]);
    useEffect(() => {
        if(map!=null) {
            // if((store.drawHistoryFigure1 == null && store.drawFigure1 != null) || (store.drawHistoryFigure2 == null && store.drawFigure2 != null)) {
            if((store.drawHistoryFigure1 == null && store.drawFigure1 == null) && (store.drawHistoryFigure2 == null && store.drawFigure2 == null)) {
                displayHospitals();// useeffect
            }
        }
    // }, [store.locationInfo]);
    // }, [store.centerPosArr]);
    }, [store.centerPosArr,store.locationInfo]);



    /*
    * DB에서 가져온 병의원 데이터로 kakaomap marker 만들어주는 effect
    * */
    useEffect(()=>{
        ///hosData 호출시 반응하는 useEffect
        if(!hosLoading && hosData!= null){
            if(hosData.getHospitalList != null){
                const convertedHosList = convertHosList(hosData.getHospitalList); //병의원 위경도 중첩 제거 (mod by hsjung)
                if((store.drawFigure1 != null && store.drawFigure2 == null)){
                    store.setHospitalMarkerList(convertedHosList);
                    if(store.drawHistoryFigure1 != null) {
                        setHistoryFigure1Flag(true);
                    }
                } else if ( store.drawFigure2 != null){ //200604조건변경
                    store.setHospitalMarkerList2(convertedHosList);
                    if(store.drawHistoryFigure2 != null) {
                        setHistoryFigure2Flag(true);
                    }
                }

                if(store.drawFigure1 == null && store.drawFigure2 == null){
                    store.setHospitalMarkerList(convertedHosList);
                }
                setMarkerList(convertedHosList);
            }
        }
        if(hosError){
            console.error("GET HOSPITAL DATA ERROR!!!!!!!!", hosError);
        }
    },[hosData]);


    /*
    * 도형이 그려졌을때 그 도형에 해당하는 마커만 그려줌
    * 2번째 도형이 그려졌을때는 1번,2번 합쳐서 표현
    * */
    useEffect(()=>{
        if(store.drawFigure1 != null || store.drawFigure2 != null) {
            if (store.hospitalMarkerList.length > 0 && store.hospitalMarkerList2.length > 0) {
                setMarkerList([...store.hospitalMarkerList, ...store.hospitalMarkerList2]);
            } else if (store.hospitalMarkerList.length > 0 && !store.hospitalMarkerList2.length > 0) {
                setMarkerList([...store.hospitalMarkerList]);
            }
        }
    }, [store.hospitalMarkerList, store.hospitalMarkerList2, store.drawFigure1, store.drawFigure2]);


    /**
     *
     *spc코드 재설정시 마커 바로 불러오기
     */
    useEffect(() => {
        hideCenterMarker();
        if(store.searchSpcArr.length > 0) {
            getMarkerListByZoom(zoomLevel);
            selectOverlay(store.drawType);
        } else if(store.searchSpcArr.length == 0) { //전문과가 없다면 마커를 지워줌
            // 클러스터 데이터 노출위해서 줌레벨로 분기
            setMarkerList([]);
            if(drawingManager != null) {
                drawingManager.cancel();
            }
        }

        return () => {
        };
    }, [store.searchSpcArr, store.searchSpcType]); //200526 진료과/전문의도 dep 추가

    /**
     *
     * org코드 재설정시 마커 바로 불러오기
     */
    useEffect(() => {
        hideCenterMarker();
        if(store.searchSpcArr.length > 0) {
            getMarkerListByZoom(zoomLevel);
        }
        return () => {
            if(markerList.length > 0 ){ //200601 테스트
                setMarkerList([]);
            }
        };
    }, [store.searchOrgArr]);


    /*
    * 줌레벨 effect //현재 미사용으로 보입니다.
    * */
    useEffect(() => { //on zoom level changed (hsjung)
        if(map != null && zoomLevel <= 4){
            if(store.drawFigure1 != null) {
                // displayHospitals();
            }
        }
        return () => {
            //clearInfowindow();
        };
    }, [zoomLevel]);


    /**
     * 조회이력 불러오기 시 처리하는 부분
     *
     */
    useEffect(()=>{
        if(store.drawHistoryFigure1 != null){
            // 조회이력 불러올때 도형1 2 순차적으로 불러오기 필요함.
            if(!historyFigure1Flag && !historyFigure2Flag) { //불러오기 첫 과정
                clearFigure();
                setFigureFromHistory([...store.drawHistoryFigure1], 0); //1번 이력의 도형값 넣어줌
            } else if(historyFigure1Flag && !historyFigure2Flag) { //도형 1이 그려졌고
                if(store.drawHistoryFigure2 != null){// 도형2도 처리해야한다면
                    setFigureFromHistory([...store.drawHistoryFigure2],2); //2번 이력의 도형값 넣어줌
                }
            }
        }
    }, [store.drawHistoryFigure1, store.drawHistoryFigure2, historyFigure1Flag, historyFigure2Flag]);
    useEffect(()=>{ //1번이력 도형처리
        if(historyFigure1Flag){
            if(store.drawHistoryFigure2 == null) { //1번이력 처리가 완료되고 2번이력 도형이 없다면 프로세스 완료 처리
                store.setHistoryLoading(false);

                store.setDrawHistoryFigure1(null);
                setHistoryFigure1Flag(false);

                map.setCenter( clearButton.getPosition() );
            }
        }
    },[historyFigure1Flag]);
    useEffect(()=>{
        if(historyFigure2Flag){ //2번 이력 도형이 완료되면 프로세스 완료 처리
            store.setHistoryLoading(false);
            store.setDrawHistoryFigure1(null);
            store.setDrawHistoryFigure2(null);
            setHistoryFigure1Flag(false);
            setHistoryFigure2Flag(false);
            map.setCenter( clearButton.getPosition() );
        }
    },[historyFigure2Flag]);
    /*
    * 이력의 도형 그리기 로직 끝
    * */



    /*
    * 다시그리기 버튼 effect
    * */
    useEffect(()=>{
        if(clearButton != null) {
        }
        return () => {
            if(clearButton != null) {
                if(clearButton.getMap() != null) {
                    //다시그리기 cleanup 될때 맵에서 보여지고 있던 상태라면, 맵에서 안보이게 처리.
                    clearButton.setMap(null);
                }
            }
        }
    },[clearButton]);


    /*
    * 우측 정보 클릭시 나타나는 액션에 관련한 effect
    * */
    useEffect(()=>{ //중앙좌표 정보 세팅해주는 부분
        hideCenterMarker(); //중앙 좌표는 하나만 나와야 하므로, 변경될 때 항상 초기화 해줌

        if(store.centerMarkerInfo != null) { //클릭된 병의원 정보가 있다면
            const info = store.centerMarkerInfo;//클릭된 정보 가져옴
            if(!info.isLease) { //임대분양이 아닐때만 마커를 그려야 하므로 infowindow와 마커 정보 세팅

                const content = '<div class="label hospital_one"><span class="center">' + info.title + '</span></div>';
                let centerIw = new kakao.maps.InfoWindow({
                    // map: map,
                    content: content,
                    position: new kakao.maps.LatLng(info.lat, info.lng),//마커 위경도
                    zIndex: 4,
                });

                let centerMarkerImg = new kakao.maps.MarkerImage(_centerMarkerObj.url, new kakao.maps.Size(_centerMarkerObj.width, _centerMarkerObj.height));

                let centerMarker = new kakao.maps.Marker({
                    map: getMap(), //마커달 카카오맵
                    // map: map, //마커달 카카오맵
                    position: new kakao.maps.LatLng(info.lat, info.lng),//마커 위경도
                    image: centerMarkerImg,
                    zIndex: 3,
                });
                setCenterMarker(centerMarker);
                setCenterMarkerIw(centerIw);
            }

            map.setCenter(new kakao.maps.LatLng(info.lat, info.lng));
        } else {
            setCenterMarker(null);
            setCenterMarkerIw(null);
        }
    }, [store.centerMarkerInfo]);

    useEffect(()=>{
        if(centerMarker != null){
            if(centerMarkerIw != null) {//중앙좌표 정보가 세팅됐다면, 맵에 보여줌.
                centerMarkerIw.open(getMap(), centerMarker);
            }
        }
        return () => {
        }
    }, [centerMarker, centerMarkerIw]);

    /*
   * 우측 정보 클릭시 나타나는 액션에 관련한 effect
   * 끝
   * */

    useEffect(()=>{ //actionstatus 변경에 관련한 effect
    }, [store.actionStatus]);


    /**
     *그리기 초기화용 이펙트
     * drawType == "clear"
     */
    useEffect(()=>{
        if(clearingFigure){ //
            //clearFigure(); //
            store.setDrawType(lastDrawType);
            // store.setDrawType('circle');
        }
        return ()=>{
        }
    },[clearingFigure, lastDrawType]);

    /* //EFFECTS 끝 */

    /* ---------------------------------------------------------------------------------------------  */
    /* -------------------------------------FUNCTIONS-----------------------------------------------  */
    /* ---------------------------------------------------------------------------------------------  */
    function hideCenterMarker(){ //중앙마커 숨기는 함수
        if(centerMarker != null) {
            centerMarker.setMap(null)
        }
        if(centerMarkerIw != null) {
            centerMarkerIw.close();
        }

    }
    function clearDrawedMarker(){ //그려져있는 마커 숨기는 함수
        for (let i = 0; i < drawedMarkerList.length; i++) {
            drawedMarkerList[i].setMap(null);
        }
        setDrawedMarkerList([]); //마커들 null 처리 후 리스트도 초기화
    }

    function clearBizarea(areaNo){ //상권지역 숨기는 함수. areaNo(도형 번호)에 따라 다르게 처리
        if(areaNo == 1){
            if(bizareaList1.length > 0) { //
                for (let i = 0; i < bizareaList1.length; i++) {
                    bizareaList1[i].polygon.setMap(null)
                }
                setBizareaList1([]);
            }
        } else if (areaNo == 2){
            if(bizareaList2.length > 0) { //
                for (let i = 0; i < bizareaList2.length; i++) {
                    bizareaList2[i].polygon.setMap(null)
                }
                setBizareaList2([]);
            }
        }
    }

    function clearInfowindow(){ //infowindow날리는 함수
        for (let i = 0; i < drawedOverlayList.length; i++) {
            drawedOverlayList[i].setMap(null);
        }
        setDrawedOverlayList([]);
    }

    function clearLeaseOverlay(){ //임대 infowindow 날리는 함수
        for (let i = 0; i < leaseOverlayList.length; i++) {
            leaseOverlayList[i].setMap(null);
        }
        setLeaseOverlayList([]);
    }

    function setFigureFromHistory(figureDataArr, figureNo){ //이력 불러오기 시 각 도형 데이터를 store에 저장해주는 함수
        let figureType = figureDataArr[1];
        let figureData = figureDataArr[0];
        let boundList = new kakao.maps.LatLngBounds(); //도형 범위. 기본값은 도형이 없을때인 맵 bound로 세팅

        if(figureType === "POLYGON"){
            let polygonPath = [];
            figureData.map((data, index)=>{
                boundList.extend(new kakao.maps.LatLng(data.pos_y, data.pos_x));
                polygonPath.push(new kakao.maps.LatLng(data.pos_y, data.pos_x).toCoords()); //latlng로 보내면 에러발생.
            });
            drawingManager.put(kakao.maps.drawing.OverlayType.POLYGON, polygonPath);

            let polygonBounds = [];
            polygonPath.map((coord, index)=>{
                polygonBounds.push(coord.toLatLng()) //Ga: lng 경도 x축 /// Ha: lat 위도 y축
            });
            setPoints(polygonBounds);

            let overlayData = drawingManager.getOverlays();
            if(figureNo != 2) { //1번째 도형이라면
                store.setDrawFigure1([overlayData.polygon[0], "polygon"]);
            } else { //2번째 도형이라면
                if(overlayData.polygon.length > 1){ //도형이 2개 이상일때는
                    store.setDrawFigure2([overlayData.polygon[1], "polygon"]); //2번째 도형정보로 설정
                } else { //아니라면
                    store.setDrawFigure2([overlayData.polygon[0], "polygon"]); //1번째 도형정보로 설정
                }
            }
        } else if(figureType === "CIRCLE"){
            let center = new kakao.maps.LatLng(figureData.center_y, figureData.center_x);
            map.setCenter(center);

            setFigureCenterObj({pos_x: figureData.center_x, pos_y: figureData.center_y});  //circle은 중앙 좌표가 바로 나옴.
            drawingManager.put(kakao.maps.drawing.OverlayType.CIRCLE, center, figureData.radius);

            let overlayData = drawingManager.getOverlays();
            if(figureNo != 2) { //1번째 도형이라면
                store.setDrawFigure1([overlayData.circle[0], "circle"]);
            } else { //2번째 도형이라면
                if(overlayData.circle.length > 1){ //도형이 2개 이상일때는
                    store.setDrawFigure2([overlayData.circle[1], "circle"]); //2번째 도형정보로 설정
                } else { //아니라면
                    store.setDrawFigure2([overlayData.circle[0], "circle"]); //1번째 도형정보로 설정
                }
            }

        } else { //사각형이거나 도형이 없는 경우.
            const {min_x, min_y, max_x, max_y} = figureData;
            boundList.extend(new kakao.maps.LatLng(min_y,max_x ));
            boundList.extend(new kakao.maps.LatLng(max_y, min_x));
            if(figureType != null){
                let drawedBounds = boundList;
                if(figureType != "RECTANGLE"){
                    drawedBounds = figureData.getBounds();
                }
                //rectPath : 사각형 points 구하기. 남서쪽부터 시계방향.
                let rectPath = [
                    {Ga: drawedBounds.getSouthWest().getLng(), Ha:drawedBounds.getSouthWest().getLat()},
                    {Ga: drawedBounds.getSouthWest().getLng(), Ha:drawedBounds.getNorthEast().getLat()},
                    {Ga: drawedBounds.getNorthEast().getLng(), Ha:drawedBounds.getNorthEast().getLat()},
                    {Ga: drawedBounds.getNorthEast().getLng(), Ha:drawedBounds.getSouthWest().getLat()},
                ]
                setPoints(rectPath);

                drawingManager.put(kakao.maps.drawing.OverlayType.RECTANGLE, boundList);
                let overlayData = drawingManager.getOverlays();
                if(figureNo != 2) { //1번째 도형이라면
                    store.setDrawFigure1([overlayData.rectangle[0], "rectangle"]);
                } else { //2번째 도형이라면
                    if(overlayData.rectangle.length > 1){ //도형이 2개 이상일때는
                        store.setDrawFigure2([overlayData.rectangle[1], "rectangle"]); //2번째 도형정보로 설정
                    } else { //아니라면
                        store.setDrawFigure2([overlayData.rectangle[0], "rectangle"]); //1번째 도형정보로 설정
                    }
                }
            }
        }

    }


    const memoizedClearFigure = useCallback((drawFigureType) => { //도형 다시그리기 함수
        // resetDrawingManager();
        store.setDrawType(lastDrawType);

        //drawFigureType은 도형 번호이며, 로직은 작동하지만 사용되진 않는 상태.
        drawingManager.cancel();
        let overlayData = drawingManager.getOverlays();
        if(overlayData.circle.length >= 1){
            if(drawFigureType == 1) {
                drawingManager.remove(overlayData.circle[0]);
            } else if(drawFigureType == 2) {
                drawingManager.remove(overlayData.circle[1]);
            } else {
                let max = overlayData.circle.length;
                for (let i = 0; i < max; i++) {
                    drawingManager.remove(overlayData.circle[0]);
                }
            }
        }
        if(overlayData.rectangle.length >= 1){
            if(drawFigureType == 1) {
                drawingManager.remove(overlayData.rectangle[0]);
            } else if(drawFigureType == 2) {
                drawingManager.remove(overlayData.rectangle[1]);
            } else {
                let max = overlayData.rectangle.length;
                for (let i = 0; i < max; i++) {
                    drawingManager.remove(overlayData.rectangle[0]);
                }
            }
        }
        if(overlayData.polygon.length >= 1){
            if(drawFigureType == 1) {
                drawingManager.remove(overlayData.polygon[0]);
            } else if(drawFigureType == 2) {
                drawingManager.remove(overlayData.polygon[1]);
            } else {
                let max = overlayData.polygon.length;
                for (let i = 0; i < max; i++) {
                    drawingManager.remove(overlayData.polygon[0]);
                }
            }
        }



        //도형에 관련된 store 정보 초기화
        if(drawFigureType == 1) {
            store.setDrawFigure1(null)
            store.setfigureLocationInfo(null)
        } else if(drawFigureType == 2) {
            store.setDrawFigure2(null)
            store.setfigureLocationInfo2(null)
        } else {
            store.setDrawFigure1(null)
            store.setDrawFigure2(null)
            store.setfigureLocationInfo(null)
            store.setfigureLocationInfo2(null)
            store.setHospitalMarkerList([]);
            store.setHospitalMarkerList2([]);
        }

        //지도위의 인접동 polygon 제거
        if(emdareaList1 && emdareaList1.length > 0 && drawFigureType != 2){
            removeEmdArea(emdareaList1);
        }
        if(emdareaList2 && emdareaList2.length > 0 && drawFigureType != 1){
            removeEmdArea(emdareaList2);
        }

        displayHospitals();

        //다시그리기 버튼 숨기기
        if(clearButton != null) {
            clearButton.setMap(null);
        }


        //다시그리기 로직이 끝나면 flag 변경해줌
        // setClearingFigure(false);
    }, [drawingManager,lastDrawType, emdareaList1, emdareaList2]);
    function clearFigure(drawFigureType){ //도형 다시그리기 함수
        //drawFigureType은 도형 번호이며, 로직은 작동하지만 사용되진 않는 상태.
        drawingManager.cancel();
        let overlayData = drawingManager.getOverlays();
        if(overlayData.circle.length >= 1){
            if(drawFigureType == 1) {
                drawingManager.remove(overlayData.circle[0]);
            } else if(drawFigureType == 2) {
                drawingManager.remove(overlayData.circle[1]);
            } else {
                let max = overlayData.circle.length;
                for (let i = 0; i < max; i++) {
                    drawingManager.remove(overlayData.circle[0]);
                }
            }
        }
        if(overlayData.rectangle.length >= 1){
            if(drawFigureType == 1) {
                drawingManager.remove(overlayData.rectangle[0]);
            } else if(drawFigureType == 2) {
                drawingManager.remove(overlayData.rectangle[1]);
            } else {
                let max = overlayData.rectangle.length;
                for (let i = 0; i < max; i++) {
                    drawingManager.remove(overlayData.rectangle[0]);
                }
            }
        }
        if(overlayData.polygon.length >= 1){
            if(drawFigureType == 1) {
                drawingManager.remove(overlayData.polygon[0]);
            } else if(drawFigureType == 2) {
                drawingManager.remove(overlayData.polygon[1]);
            } else {
                let max = overlayData.polygon.length;
                for (let i = 0; i < max; i++) {
                    drawingManager.remove(overlayData.polygon[0]);
                }
            }
        }



        //도형에 관련된 store 정보 초기화
        if(drawFigureType == 1) {
            store.setDrawFigure1(null)
            store.setfigureLocationInfo(null)
        } else if(drawFigureType == 2) {
            store.setDrawFigure2(null)
            store.setfigureLocationInfo2(null)
        } else {
            store.setDrawFigure1([])
            store.setDrawFigure2([])
            store.setfigureLocationInfo(null)
            store.setfigureLocationInfo2(null)
            store.setHospitalMarkerList([]);
            store.setHospitalMarkerList2([]);
        }

        //지도위의 인접동 polygon 제거
        if(emdareaList1 && emdareaList1.length > 0 && drawFigureType != 2){
            removeEmdArea(emdareaList1);
        }
        if(emdareaList2 && emdareaList2.length > 0 && drawFigureType != 1){
            removeEmdArea(emdareaList2);
        }

        displayHospitals();

        //다시그리기 버튼 숨기기
        if(clearButton != null) {
            clearButton.setMap(null);
        }


        //다시그리기 로직이 끝나면 flag 변경해줌
        setClearingFigure(false);
    }

    function toggleDetailOpen(){ //통계보기 창 open/close 토글
        setIsDetailOpen(!isDetailOpen);
    }

    //kakaomap api 처음 불러오는 부분
    function importMapScripts (recentLocInfo) {
        // console.log("import map");
        let kakaoApiKey = "e37b06703532b52d8d9be490f17a9b2e";
        const script = document.createElement('script');
        script.async = true;
        script.src = "//dapi.kakao.com/v2/maps/sdk.js?appkey=" + kakaoApiKey + "&autoload=false&libraries=services,drawing,clusterer";
        document.head.appendChild(script);
        script.onload = () => {
            window.kakao.maps.load(() => {
                setKakao(window.kakao);
            });
        };
    }

    function initMap(options){ //kakaomap api 불러와지고 map element에 세팅하는 함수
        let el = document.getElementById('map');
        setMap(new kakao.maps.Map(el, options));
        setInfowindow(new kakao.maps.InfoWindow())

        /*로드뷰관련 초기화*/
        setRoadviewClient(new kakao.maps.RoadviewClient()); //좌표로부터 로드뷰 파노ID를 가져올 로드뷰 helper객체)
        //console.log("new kakao.maps.Roadview(roadviewRef)", new kakao.maps.Roadview(roadviewRef));
        // console.log("new kakao.maps.Roadview(roadviewRef)", roadviewRef);
        setRoadviewObj(new kakao.maps.Roadview(roadviewRef.current));
        // initRoadviewMarker();
        //로드뷰 초기화 끝


        setLoaded(true);
        store.setKakao(kakao);

        CommonFunc.pageview('입지분석', '메인'); //Google Analytics
    }

    function initClearButton(){ //다시그리기 버튼 static하게 그려주는 부분
        let clearBtnElm = document.createElement("div");
        clearBtnElm.innerHTML = '<span class="left"></span><span class="center">다시그리기</span><span class="right"></span>';
        clearBtnElm.className = 'label reset';

        //버튼에 직접 함수를 실행하면 cors 에러가 발생되기 때문에 아래와 같이 arrow function을 사용하여 작성함
        clearBtnElm.addEventListener("mouseup", ()=>{store.setDrawType("clear")}, false);

        setClearButton(
            new kakao.maps.CustomOverlay({
                // map: map,
                position: new kakao.maps.LatLng(37.566431, 126.978042),
                content: clearBtnElm,
                clickable: true,
                zIndex: 123,
            })//다시그리기버튼 세팅. 기본위치 서울시청*/
        );
    }


    function initDrawingManager(){ //map element가 세팅되면 drawingManager 초기화 하는 부분
        //http://apis.map.kakao.com/web/documentation/#drawing_DrawingManager
        let options = {
            map: getMap(), // drawing manager로 그리기 요소를 그릴 map 객체
            // map: map, // drawing manager로 그리기 요소를 그릴 map 객체
            drawingMode: [ // drawing manager로 제공할 그리기 요소 모드
                kakao.maps.drawing.OverlayType.POLYLINE,
                kakao.maps.drawing.OverlayType.RECTANGLE,
                kakao.maps.drawing.OverlayType.CIRCLE,
                kakao.maps.drawing.OverlayType.POLYGON
            ],
            guideTooltip: ['draw', 'drag'], // 사용자에게 제공할 그리기 가이드 툴팁
            polylineOptions: {
                // draggable: true, // 그린 후 드래그가 가능하며 guideTooltip옵션에 'drag'가 포함된 경우 툴팁이 표시된다
                removable: false, // 그린 후 삭제 할 수 있도록 x 버튼이 표시된다
                // editable: true, // 그린 후 수정할 수 있도록 작은 사각형이 표시되며 guideTooltip옵션에 'edit'가 포함된 경우 툴팁이 표시된다
                strokeColor: '#c88e1a', // 선 색
                fillColor: '#c88e1a', // 선 색
                hintStrokeStyle: 'dash', // 그리중 마우스를 따라다니는 보조선의 선 스타일
                hintStrokeOpacity: 0.5  // 그리중 마우스를 따라다니는 보조선의 투명도
            },
            rectangleOptions: {
                // draggable: true,
                removable: false,
                // editable: true,
                strokeColor: '#21a82d', // 외곽선 색
                fillColor: '#21a82d', // 채우기 색
                fillOpacity: 0.5 // 채우기색 투명도
            },
            circleOptions: {
                // draggable: true,
                removable: false,
                // editable: true,
                strokeColor: '#39f',
                fillColor: '#3399ff',
                fillOpacity: 0.5
            },
            polygonOptions: {
                // draggable: true,
                removable: false,
                // editable: true,
                strokeColor: '#c88e1a',
                fillColor: '#c88e1a',
                fillOpacity: 0.5,
                hintStrokeStyle: 'dash',
                hintStrokeOpacity: 0.5
            }
        };
        if(drawingManager === null) {
            setDrawingManager(new kakao.maps.drawing.DrawingManager(options));
        }
    }
    const resetDrawingManager = useCallback(() => {
        if(drawingManager !== null) {
            setDrawingManager(null);
            initDrawingManager();
        } else {
            alert('이미  ㅇrawingmanager Null임')
        }

    }, [drawingManager])

    /**
     *도형 버튼 클릭시 그리기 모드 세팅하는 함수들
     */
    /*
    function selectOverlay(type, isCancel){
        if(drawingManager != null){
            if(isCancel){
                drawingManager.cancel();
            }
            // setIsFigureDrawable(true);
            if(store.isMultiMap && store.drawFigure1 != null && store.drawFigure2 == null){ //다중그리기에 2번 아직 안그렸을때
                setDrawMultiMap(true);

            } else if(!store.isMultiMap && store.drawFigure1 == null) { //다중그리기 아니고 1번 아직 안그렸을때
                setIsFigureDrawable(true);
            } else {
                setIsFigureDrawable(false);

            }
            const isSingleDrawable = !store.isMultiMap && store.drawFigure1 == null;
            const isMultiDrawable = store.isMultiMap && store.drawFigure1 != null && store.drawFigure2 == null;
            // setIsFigureDrawable(isSingleDrawable || isMultiDrawable);
        }
    }
     */
    const selectOverlay = useCallback((type, isCancel) => {
        if(drawingManager != null){
            if(isCancel){
                drawingManager.cancel();
            }
            // setIsFigureDrawable(true);
            if(store.isMultiMap && store.drawFigure1 != null && store.drawFigure2 == null){ //다중그리기에 2번 아직 안그렸을때
                setDrawMultiMap(true);

            } else if(!store.isMultiMap && store.drawFigure1 == null) { //다중그리기 아니고 1번 아직 안그렸을때
                setIsFigureDrawable(true);
            } else if(!store.isMultiMap && store.drawFigure1 != null){
                setIsFigureDrawable(false);

            }
            const isSingleDrawable = !store.isMultiMap && store.drawFigure1 == null;
            const isMultiDrawable = store.isMultiMap && store.drawFigure1 != null && store.drawFigure2 == null;
            // setIsFigureDrawable(isSingleDrawable || isMultiDrawable);
        }
    }, [store.isMultiMap , store.drawFigure1, store.drawFigure2])
    useEffect(()=>{
        if(drawingManager != null) {
            if("CIRCLE" == store.drawType || "POLYGON" == store.drawType || "RECTANGLE" == store.drawType) { //도형 그리기 모드라면
                if (isFigureDrawable) {
                    drawingManager.select(kakao.maps.drawing.OverlayType[store.drawType]);
                }

                if(isDrawFailed){
                    drawingManager.select(kakao.maps.drawing.OverlayType[store.drawType]);
                }

                //drawingmanager select 이후엔 flag들 다 정리해줌
                setIsDrawSuccessed(false);
                setIsDrawFailed(false);
                setDrawMultiMap(false);
            }
            // console.log('useEffect - [isFigureDrawable,drawMultiMap,isDrawFailed, isDrawSuccessed]', [isFigureDrawable,drawMultiMap,isDrawFailed, isDrawSuccessed]);
        }
        return () => {
            // console.log('clean up - [isFigureDrawable,drawMultiMap,isDrawFailed, isDrawSuccessed]', [isFigureDrawable,drawMultiMap,isDrawFailed, isDrawSuccessed]);
            // alert(3)
            if(isFigureDrawable || isDrawSuccessed) {
                setIsFigureDrawable(false);
                // setIsDrawSuccessed(false);
            }
        }
    },[isFigureDrawable,drawMultiMap,isDrawFailed, isDrawSuccessed]); //도형 그리기 모드로 들어갈 경우 세팅되는 state들
    useEffect(()=>{
        if(drawingManager != null) { //그리기 모드 종류에 따른 이벤트
            drawingManager.cancel();
            if("CIRCLE" == store.drawType || "POLYGON" == store.drawType || "RECTANGLE" == store.drawType) {
                setRoadviewOn(false);
                //drawingManager.cancel();
                if(store.isMultiMap) {
                    if(store.drawFigure2 == null) {
                        drawingManager.select(kakao.maps.drawing.OverlayType[store.drawType]);
                    }
                } else {
                    if(store.drawFigure1 == null) {
                        drawingManager.select(kakao.maps.drawing.OverlayType[store.drawType]);
                    }
                }
            } else if("ROADVIEW" == store.drawType) {
                // console.log("LOAD ROADVIEW", roadviewOn);
                //drawingManager.cancel();
                setRoadviewOn(true);

                //
            }
        }
    },[store.drawType, store.isMultiMap]);
    useEffect(()=>{ //다중그리기 모드 토글시 발생하는 이벤트
        if(drawingManager != null) {
            if("CIRCLE" == store.drawType || "POLYGON" == store.drawType || "RECTANGLE" == store.drawType) {
                if(store.isMultiMap) {
                    drawingManager.cancel();
                    if(store.drawFigure2 == null) {
                        drawingManager.select(kakao.maps.drawing.OverlayType[store.drawType]);
                    }
                } else {
                    if(store.drawFigure1 != null) {
                        drawingManager.cancel();
                    }
                }
            }
        }
    },[store.isMultiMap]);
    /**
     *도형 버튼 클릭시 그리기 모드 세팅하는 함수들 끝
     */
    /*
    * 로드뷰 기능추가 210805
    * */
    /*로드뷰 useEffect 시작*/
/*    const memoizedRoadviewCb = useCallback(
        (pos) => {
            console.log("roadview memoized callback", roadviewOn, pos);
        },
        [],
    );
    const memoizedRoadviewMemo = useMemo(
        () => roadviewOn,
        [roadviewOn],
    );*/
    const memoizedRoadviewMarker = useMemo(
        () => {
            //console.log("메모이즈드 메모",rvMarker, rvMarkerPos);
            let marker = null;
            if(rvMarker != null && rvMarkerPos != null) {
                marker = rvMarker.setPosition(rvMarkerPos);
            } else {
                //rvMarker.setMap(null);
            }
            return marker;
        },
        [rvMarker,rvMarkerPos],
        // [map, rvMarkerPos],
    );

    function initRoadviewMarker(pos) { //로드뷰마커 초기화

    // const initRoadviewMarker = (pos) => { //로드뷰마커 초기화
/*        if(rvMarker != null) {
            rvMarker.setMap(null);
            setRvMarker(null);
        }*/

        // console.log("로드뷰마커 init", memoizedRoadviewMarker, rvMarker);
        let rvMarkerTemp = null;
        let rvMarkerImg = new kakao.maps.MarkerImage(
            'https://t1.daumcdn.net/localimg/localimages/07/2018/pc/roadview_minimap_wk_2018.png',
            new kakao.maps.Size(26, 46),
            {
                // 스프라이트 이미지를 사용합니다.
                // 스프라이트 이미지 전체의 크기를 지정하고
                spriteSize: new kakao.maps.Size(1666, 168),
                // 사용하고 싶은 영역의 좌상단 좌표를 입력합니다.
                // background-position으로 지정하는 값이며 부호는 반대입니다.
                spriteOrigin: new kakao.maps.Point(705, 114),
                offset: new kakao.maps.Point(13, 46)
            }
        );
        rvMarkerImg = new kakao.maps.MarkerImage(_roadviewMarkerObj.url, new kakao.maps.Size(_roadviewMarkerObj.width,_roadviewMarkerObj.height));

        if(rvMarker == null) {
            rvMarkerTemp = new kakao.maps.Marker({
                image: rvMarkerImg
            });
        }
        if(map != null){
            rvMarkerTemp.setMap(map);
        }
        if(pos != null){
            // rvMarker.setMap(null);
            // setRvMarker(null);
            rvMarkerTemp.setPosition(pos);
        }

        setRvMarker(rvMarkerTemp);

        // setRvMarkerPos(null);
        // return rvMarkerTemp
    }

    const clearForRoadview = () => { //로드뷰 기능 클릭시 초기화
        clearFigure();
        clearBizarea(1); //상권1 제거
        clearBizarea(2);//상권2 제거
        //setAutoDetail(true); //자동 통계보기 flag on

        //로드뷰 마커 제외한 다른 마커숨김
        clearDrawedMarker();
    }

    const loadRoadview =(position) => { //실제 로드뷰 불러오는 함수
        // console.log("loadRoadview init" );
        roadviewClient.getNearestPanoId(position, 50, function(panoId) {
            //console.log("loadRoadview getpanoId" , panoId, rvMarker, memoizedRoadviewMarker);
            setRvMarkerPos(position);

            // moveRoadviewMarker(position); //클릭한고승로 로드뷰마커이동

            if (panoId === null) { //클릭된 좌표 기준 근처 로드뷰 정보가 없을시
                setRoadviewDisplay(false)
                map.relayout();
            }else {//로드뷰 정보가 있을 시
                roadviewObj.setPanoId(panoId, position); //panoId와 중심좌표를 통해 로드뷰 실행
                roadviewObj.relayout();
                setRoadviewDisplay(true);
            }
        });
    }

    const roadviewHandler = (mouseEvent) => {
        let clickPosition = mouseEvent.latLng;
        //console.log("map clicked", clickPosition,roadviewOn, store.drawType, memoizedRoadviewMemo);
        // console.log("map clicked", roadviewOn, store.drawType, memoizedRoadviewMemo);
        // console.log("map clicked", roadviewOn, store.drawType, memoizedRoadviewMemo);

        // memoizedRoadviewMemo();
        // memoizedRoadviewCb(clickPosition);
        // if(store.drawType === "ROADVIEW"){
        let isRoadviewMode = store.drawType === "ROADVIEW";
        if(roadviewOn && isRoadviewMode){
            loadRoadview(clickPosition)
        }
    }
    const toggleRoadviewListener = (isAdd) => {
        /*로드맵을 위한 이벤트 리스너*/
        // console.log("roadview listener init", isAdd);
        if(isAdd){
            // console.log("roadview listener add rvListener", rvListener);
            if(!rvListener) { //리스너 한번만 추가되도록 설정
                kakao.maps.event.addListener(map, 'click', roadviewHandler);
                setRvListener(true);
            }
        } else {
            // console.log("roadview listener remove");
            kakao.maps.event.removeListener(map, 'click', roadviewHandler);
        }

    }

    useEffect(()=>{ //로드뷰 토글 시 발생
        if(map != null) {
            //kakao.maps.event.removeListener(map, 'click', roadviewHandler);

            if (roadviewOn) {
                map.setCursor('url(' + _rvCursorUrl + ') 16 30, default');//210908 커서변경요청적용
                clearForRoadview()//로드뷰 모드로 들어오면 초기화시킴

                toggleRoadviewListener(true);
                // 지도 위에 로드뷰 도로 오버레이를 추가합니다
                map.addOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);

                // 지도 위에 마커를 표시합니다
                // marker.setMap(map);

                // 마커의 위치를 지도 중심으로 설정합니다
                // marker.setPosition(map.getCenter());

                // 로드뷰의 위치를 지도 중심으로 설정합니다
                // toggleRoadview(map.getCenter());
                // console.log("rvMarker??",rvMarker);
                if(rvMarker == null) {
                    initRoadviewMarker();
                }
            } else {
                map.setCursor('crosshair'); //210908 커서변경요청적용
                toggleRoadviewListener(false);
                setRoadviewDisplay(false);

                // 지도 위의 로드뷰 도로 오버레이를 제거합니다
                map.removeOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);
                // 지도 위의 마커를 제거합니다
                if(rvMarker != null) {
                    rvMarker.setMap(null);
                    setRvMarker(null);
                    setRvMarkerPos(null);
                }
                // marker.setMap(null);

                //로드뷰 모드 끄면 다시 마커 보여줌ㄴ
                getMarkerListByZoom(zoomLevel);

            }
        }
        return () => {
            // 지도 위의 로드뷰 도로 오버레이를 제거합니다
            // map.removeOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);
            // 지도 위의 마커를 제거합니다
            // marker.setMap(null);
        }
    },[roadviewOn, map]);
    useEffect(()=>{ //로드뷰 토글 시 발생
        if(map != null) {
            if(rvMarker != null) {
                /*로드뷰 마커 설정*/
                //rvMarker.setMap(map);
            } else {
                //initRoadviewMarker();
            }
        }
        return () => {
        }
    },[rvMarker, map]);
    /*로드뷰 useEffect 끝*/

    /* api에 필요한 parameter를 만들어줌.*/
    function getParam(isSecondFigure){
        let spcArr = [...store.searchSpcArr];
        let orgArr = [...store.searchOrgArr];
        let figureBound = store.figureBound;

        if(isSecondFigure){
            figureBound = store.figureBound2;
        }
        let param = {
            spc_code_arr: spcArr,
            hos_type_code_arr: orgArr,
            spc_type: store.searchSpcType,
            polygon: figureBound,
            zoomLevel: zoomLevel,
        }

        return param;
    }
    /*log insert용 parameter 만들어줌.*/
    function getLogParam(figureNo){
        let targetData = store.locationInfo;
        let targetFigure = null;
        if(figureNo == 1){
            targetData = store.figureLocationInfo;
            targetFigure = store.drawFigure1;
            // console.log("getlogparam@@@@@@@@@@@@ ", figureNo, targetData, targetFigure);
        } else if(figureNo == 2){
            targetData = store.figureLocationInfo2;
            targetFigure = store.drawFigure2;
        }
        let param =  {
            sido_code: targetData.sido_code,
            sgg_code: targetData.sgg_code,
            emd_code: targetData.emd_code,
            addr: targetData.addr,

        }
        if(targetFigure == null) {
            let swLatLng = map.getBounds().getSouthWest();
            let neLatLng = map.getBounds().getNorthEast();

            param.rect_info = {
                min_y: "" + swLatLng.getLat(),
                max_x: "" + neLatLng.getLng(),
                max_y: "" + neLatLng.getLat(),
                min_x: "" + swLatLng.getLng(),
            };
            param.circle_info = null;
            param.polygon_info = null;
            param.shape = null; //도형을 그리지 않은 기본 상태일시.
        } else {
            param.shape = targetFigure[1].toUpperCase();
            if (targetFigure[1] == "circle") {
                param.circle_info = {
                    center_x: "" + targetFigure[0].getPosition().getLng(),
                    center_y: "" + targetFigure[0].getPosition().getLat(),
                    radius: parseInt(targetFigure[0].getRadius()),
                }
            } else if (targetFigure[1] == "rectangle") {
                param.rect_info = {
                    min_y: "" + targetFigure[0].getBounds().getSouthWest().getLat(),
                    max_x: "" + targetFigure[0].getBounds().getNorthEast().getLng(),
                    max_y: "" + targetFigure[0].getBounds().getNorthEast().getLat(),
                    min_x: "" + targetFigure[0].getBounds().getSouthWest().getLng(),
                };
            } else {
                let polygonPath = [];
                targetFigure[0].getPath().map((data, index) => {
                    polygonPath.push({pos_x: "" + data.toLatLng().Ia, pos_y: "" + data.toLatLng().Ja});
                });

                param.polygon_info = polygonPath;
            }
        }
        return param;
    }

    /**
     *도형 바운드 세팅하기.
     */
    function setFigureBound(targetObj, figureType, isSecondFigure) {
        //좌상, 좌하(남서), 우하, 우상(북동)
        let refObj = (targetObj != null) ? targetObj : map; // bound를 가져올 기준 object. 기본값은 map
        let defaultFigureBound = [];
        let defaultFigureBoundString = "";

        if(figureType == "rectangle" || !targetObj) {
            let bound = refObj.getBounds();
            let swLatLng = bound.getSouthWest();
            let neLatLng = bound.getNorthEast();
            let x1 = swLatLng.getLat();
            let y1 = swLatLng.getLng();
            let x2 = neLatLng.getLat();
            let y2 = neLatLng.getLng();
            // [x1, y2], [x1, y1], [x2,y1], [x2,y2]
            defaultFigureBound = [[y2+" "+x1], [y1+" "+ x1+""], [y1+" "+x2+""], [y2+" "+x2+""]];

            defaultFigureBound.push(defaultFigureBound[0]);
        } else if(figureType == "polygon") {
            refObj.getPath().map((coord, index)=>{
                // defaultFigureBound.push([coord.toLatLng().Ia + " " + coord.toLatLng().Ja]);
                defaultFigureBound.push([getLatLngFromPos(coord.toLatLng(), "lng") + " " + getLatLngFromPos(coord.toLatLng(), "lat")]); //경도, 위도 순
            });

            defaultFigureBound.push(defaultFigureBound[0]);
        } else if(figureType == "circle") {
            let centerArr = [getLatLngFromPos(refObj.getPosition(), "lng"), getLatLngFromPos(refObj.getPosition(), "lat")]; //Ha: lat, Ga: lng 경도,위도순
            let drawedRadius = refObj.getRadius();

            // defaultFigureBound = circleToPolygon(centerArr,drawedRadius,32)
            let c2pObj = circleToPolygon(centerArr,drawedRadius,32);
            defaultFigureBound = c2pObj.coordinates[0];
        }

        defaultFigureBound.map((item, index) => {
            let seperator = (index < defaultFigureBound.length - 1 ? "," : "");
            let coordString = item[0];
            if(figureType == "circle"){ //200604 조건변경
                let historyAndCircle = (historyFigure1Flag && store.drawHistoryFigure2 != null);
                if(!historyFigure1Flag || historyAndCircle) {
                    coordString = item[0] + " " + item[1];
                }
            }
            defaultFigureBoundString = defaultFigureBoundString.concat(coordString + seperator);
        });

        //console.log("defaultFigureBound",defaultFigureBound);
        store.setFigureBoundArr(defaultFigureBound);
        if(!isSecondFigure) {
            store.setFigureBound(defaultFigureBoundString);
        }else  {
            store.setFigureBound2(defaultFigureBoundString);
        }
    }

    /**
     *도형 바운드 정보 받아오기.
     */
    function getFigureBoundString(targetObj, figureType) {
        let refObj = (targetObj != null) ? targetObj : map; // bound를 가져올 기준 object. 기본값은 map
        let defaultFigureBound = [];
        let defaultFigureBoundString = "";
        let latitude = null;//위도
        let longitude = null;//경도
        // console.log("refobj??", refObj, typeof refObj.getPosition);
        if(refObj != null){ //refobject가 null이 아닐때 위/경도 설정해줌
            if(typeof refObj.getPosition == "function") {
                // let key1 = Object.keys(refObj.getPosition())[0]//카카오 getPosition api의 첫번째 항목 - 경도
                // let key2 = Object.keys(refObj.getPosition())[1]//카카오 getPosition api의 두번째 항목 - 위도

                latitude = getLatLngFromPos(refObj.getPosition(), "lat");//위도 0~90
                longitude = getLatLngFromPos(refObj.getPosition(), "lng");//경도 0~180
            }
        }

        if(figureType == "rectangle" || !targetObj) {
            let bound = refObj.getBounds();
            let swLatLng = bound.getSouthWest();
            let neLatLng = bound.getNorthEast();
            let x1 = swLatLng.getLat();
            let y1 = swLatLng.getLng();
            let x2 = neLatLng.getLat();
            let y2 = neLatLng.getLng();
            defaultFigureBound = [[y2+" "+x1], [y1+" "+ x1+""], [y1+" "+x2+""], [y2+" "+x2+""]];

            defaultFigureBound.push(defaultFigureBound[0]);
        } else if(figureType == "polygon") {
            refObj.getPath().map((coord, index)=>{
                // console.log("polygon centerArr", Object.keys(coord.toLatLng())[0], Object.keys(coord.toLatLng())[1])
                // console.log("polygon centerArr", [getLatLngFromPos(coord.toLatLng(), "lng") + " " + getLatLngFromPos(coord.toLatLng(), "lat")])
                defaultFigureBound.push([getLatLngFromPos(coord.toLatLng(), "lng") + " " + getLatLngFromPos(coord.toLatLng(), "lat")]); //경도, 위도 순
            });
            defaultFigureBound.push(defaultFigureBound[0]);
        } else if(figureType == "circle") {
            let centerArr = [longitude, latitude];//경도 - 위도 순으로 들어감
            // console.log("centerARR??", centerArr);
            // let centerArr = [refObj.getPosition().Ia, refObj.getPosition().Ja];//경도 - 위도 순으로 들어감
            //Ha,Ja: lat-위도, Ga,Ia: lng-경도
            // centerArr = [refObj.getPosition().Ja, refObj.getPosition().Ia]; //Ha,Ja: lat-위도, Ga,Ia: lng-경도
            let drawedRadius = refObj.getRadius();
            //--let c2pObj = circleToPolygon(centerArr,drawedRadius,32);
/*
            console.log("centerArr", centerArr,refObj,refObj.getPosition())
            console.log("centerArr2", Object.keys(refObj.getPosition())[0], Object.keys(refObj.getPosition())[1])
            console.log("centerArr2-1", refObj.getPosition()[Object.keys(refObj.getPosition())[0]], refObj.getPosition()[Object.keys(refObj.getPosition())[1]])
            */
            let c2pObj = circleToPolygon(centerArr,drawedRadius,16);
            defaultFigureBound = c2pObj.coordinates[0];
        }

        defaultFigureBound.map((item, index) => {
            let seperator = (index < defaultFigureBound.length - 1 ? "," : "");
            let coordString = item[0];
            if(figureType == "circle"){
                coordString = item[0] + " " + item[1];
            }
            defaultFigureBoundString = defaultFigureBoundString.concat(coordString + seperator);
        });
        return defaultFigureBoundString;
    }

    /**
     *검색지역 통계보기 버튼 클릭 시 Validation
     */
    function handleDetailButton(isAuto){
        let searchValueValid = true;
        if((store.locationInfo == null || store.searchAddress == "")&&searchValueValid) {
            alert(_errMsg_map);
            searchValueValid = false;
        }
        if(!(store.searchSpcArr.length > 0) && searchValueValid){
            alert(_errMsg_spc);
            searchValueValid = false;
        }

        if((map.getLevel() > 4)&&searchValueValid){
            if(store.drawFigure1 == null) {
                alert(_errMsg_zoom);
                searchValueValid = false;
            }
        }

        if(store.actionStatus == 4){
            searchValueValid = false;
        }

        if(searchValueValid) {
            if(isAuto){
                setAutoDetail(false);
            }
            if(store.isMultiMap && store.drawFigure1 == null && store.drawFigure2 == null){
                store.setIsMultiMap(false);
            }
            // setIsDetailOpen(!isDetailOpen)
            setIsDetailOpen(true);
            //검색조건 이력 저장, 회원일시는 로그에 회원정보 추가로 기입됨.
            let searchLogParam = {
                u_id: "",
                u_spc_code: "",
                u_wtp_code: "",
                u_loc_code: "",
            };

            let searchConditionParamArr = []
            if(store.drawFigure1 != null) {
                searchConditionParamArr.push(getLogParam(1));
            }
            if(store.drawFigure2 != null) {
                searchConditionParamArr.push(getLogParam(2));
            }

            if(store.drawFigure1 == null && store.drawFigure2 == null){
                setFigureBound();
                searchConditionParamArr.push(getLogParam());
                // searchParam = getLogParam();
            }

            searchLogParam.search_condition_arr= searchConditionParamArr;

            searchLogParam.spc_code_arr= store.searchSpcArr;
            searchLogParam.spc_type= store.searchSpcType;
            searchLogParam.hos_type_code_arr= store.searchOrgArr;

            if(store.isLogin) { //로그인 상태일시 회원정보 추가 기입 로직
                searchLogParam.u_id= store.userInfo.u_id;
                searchLogParam.u_spc_code= store.userInfo.u_spc_code;
                searchLogParam.u_wtp_code= store.userInfo.u_wtp_code;
                searchLogParam.u_loc_code= (store.userInfo.u_loc_code==null)?"":store.userInfo.u_loc_code;
            }
            addSearchLog({variables: searchLogParam});
            CommonFunc.pageview('입지분석', '통계보기');
        }
    }


    /*사각형의 latlng로 면적 구하는 함수*/
    function measure(lat1, lon1, lat2, lon2){  // generally used geo measurement function
        var R = 6378.137; // Radius of earth in KM
        var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
        var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon/2) * Math.sin(dLon/2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c;
        return d * 1000; // meters
    }
    /* //FUNCTIONS */


    /*
    * 거리재기 관련 effect들. 현재 미구현
    * */
    useEffect(()=>{
        return()=>{
            if(drawingFlagState){
                store.setDrawType(lastDrawType);
            }
        }
    },[drawingFlagState])
    useEffect(()=>{
        return()=>{

        }
    },[moveLineState])
    useEffect(()=>{
        if(clickLineState.length > 0){
            clickLineState[0].setMap(map);
        }
        if(clickLineState.length > 1){
            let newPath = clickLineState[1];
            clickLineState[0].setPath(newPath);
        }
        return()=>{

        }
    },[clickLineState])
    useEffect(()=>{
        return()=>{

        }
    },[distanceOverlayState])
    useEffect(()=>{
        if(dotsState.length > 0) {
            dotsState.map((item)=>{
                item.circle.setMap(map);
                if(item.distance != null) {
                    item.distance.setMap(map);
                }
            });
        } else {

        }
        return()=>{

        }
    },[dotsState])

    /*
    * 거리재기 관련 effect들. 현재 미구현
    * 끝
    * */

    //key press check effect
    //esc key event
    useEffect(()=>{
        if(escPress) {
            if (store.drawType === "distance") {//거리재기 그리는중일시 취소
                clearCircleDotState();
                clearClickLineState();
            } else if (store.drawType === "CIRCLE" || store.drawType === "POLYGON" || store.drawType === "RECTANGLE") {//도형 그리는중일시 취소
                if (drawingManager != null) {
                    store.setDrawType()
                    // selectOverlay(store.drawType);
                    // drawingManager.cancel();
                    // setIsFigureDrawable(true);
                }
            }
        }
    },[escPress]);



    /*
    * 아래부터 return
    * */
    return (<Box id={"mapParent"}>
        <Backdrop className={classes.backdrop} open={loadingFlag} >
            {/*로딩 표현을 위한 backdrop.*/}
            {/*<Backdrop className={classes.backdrop} open={open} onClick={handleClose}>*/}
            <CircularProgress color="inherit" />
        </Backdrop>
        { map != null &&
        <CustomOverlays kakao={kakao} map={map} />
        }
        <Box
            id="map"
            ref={mapRef}
            style={{
                position: "relative",
                overflow: "hidden",
                height:"100%"}}
        >
            {
                (store.locationInfo && store.locationInfo.sgg_code) &&
                <LeaseAdContainer/>
            }
            <DetailButton fontColor={"#ffffff"} bgColor={"#2172ff"} parent = {mapRef} handleAction={ handleDetailButton }/>
            <Box
                id={"roadviewWrapper"}
                style={{
                    position: "relative",
                    left:0,
                    right:0,
                    top:55,
                    bottom:0,
                    // background:"gray",
                    zIndex:123456,
                    // visibility:"hidden",
                    visibility:roadviewDisplay?"":"hidden",
                    width:"100%",
                    height:"100%"}}
            >
                <Box
                    id="roadviewContainer" ref={roadviewRef}
                    style={{
                        position: "relative",
                        // background:"gray",
                        // zIndex:123456,
                        width:"100%",
                        height:"100%"}}
                ><
                /Box>
                <Box
                    className="roadviewCloseBtn"
                    style={{
                        position: "absolute",
                        left:20,
                        right:0,
                        top:35,
                        bottom:0,
                        background:"green",
                        width:"100px",
                        height:"50px",
                        zIndex:123457,}}
                >
                    <span id="btnRoadviewClose" className="btn" onClick={()=>setRoadviewDisplay(false)}>[로드뷰닫기]</span>
                </Box>
            </Box>
        </Box>


        <DetailLayout open={isDetailOpen} handleOpen={toggleDetailOpen}/>
        {/*</div>*/}
    </Box>)
})

export default Kakaomap;
