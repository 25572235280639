import React, { Fragment } from 'react';
import axios from 'axios';

import KCDWikipedia from '../content/KCDWikipedia';
import WikiPlaceHolder from '../loading/WikiPlaceHolder';

class KcdContentFragment extends React.PureComponent {
    state = {
        displaytitle: "",
        thumbnail: "",
        extract_html: "",
        timestamp: "",
        content_urls: "",
        toc: [],
        wikiLoadings: true
    }

    componentDidMount = () => {
        //console.log("this.props.wikiKeyword ", this.props.wikiKeyword);
        const wikiApiUrl = `https://en.wikipedia.org/api/rest_v1/page/summary/${this.props.wikiKeyword}`;
        //const wikiApiTocUrl = `https://en.wikipedia.org/api/rest_v1/page/metadata/${this.props.wikiKeyword}`;

        axios.get(wikiApiUrl)
        .then((wikiApiRes) => {
            //console.log('wikiApiUrl_result', wikiApiRes.data);
            this.setState({
                displaytitle: wikiApiRes.data.displaytitle || '',
                thumbnail: wikiApiRes.data.thumbnail ? wikiApiRes.data.thumbnail.source : '',
                extract_html: wikiApiRes.data.extract_html || '',
                timestamp: wikiApiRes.data.timestamp || '',
                content_urls: wikiApiRes.data.content_urls.desktop.page || '',
                //toc: wikiApiTocRes.data.toc.entries || '',
                toc: '' || '',
                wikiLoadings: false
            });
            /*
            axios.get(wikiApiTocUrl)
            .then((wikiApiTocRes) => {
                //console.log('wikiApiTocUrl_result', wikiApiTocRes.data);

                this.setState({
                    displaytitle: wikiApiRes.data.displaytitle || '',
                    thumbnail: wikiApiRes.data.thumbnail ? wikiApiRes.data.thumbnail.source : '',
                    extract_html: wikiApiRes.data.extract_html || '',
                    timestamp: wikiApiRes.data.timestamp || '',
                    content_urls: wikiApiRes.data.content_urls.desktop.page || '',
                    //toc: wikiApiTocRes.data.toc.entries || '',
                    toc: '' || '',
                    wikiLoadings: false
                });

            })
            .catch((error) => {
                //console.log('error', error.message);
            });
            */
        })
        .catch((error) => {
            //console.log('error', error.message);
        });
    }

    render() {
        //console.log("WikipediaFragment props", this.props);
        // //console.log("WikipediaFragment state", this.state);

        return (
            <Fragment>
                {/*!_.isEmpty(this.state.displaytitle) && !_.isEmpty(this.state.extract_html) &&*/}
                {
                    <div className="api">
                        {
                            this.state.wikiLoadings ?
                            <WikiPlaceHolder/>
                            :
                            <KCDWikipedia
                                wikiKeyword={this.props.wikiKeyword}
                                wikiData={this.state}
                            />
                        }
                    </div>
                }
            </Fragment>
        )
    }
}

export default KcdContentFragment;