import React, {useEffect, useState, useContext} from 'react';
import {observer, useObserver} from "mobx-react";
import {useLazyQuery} from "@apollo/react-hooks";
import {STAT_CLINIC_BY_SPC} from "../../../queries/location";
import {Chart} from "react-google-charts";
import CommonFunc from '../../../common/CommonFunction';
import {useStore} from "../../../stores/StoreProvider";
import _ from 'lodash';
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "../../style/common";
import Typography from "@material-ui/core/Typography";
import {SIDO_CODE, SPC_CODE, SBJ_CODE, CHART } from '../../../common/constants'

const StatHosSpc = observer((props) => {
    const store = useStore().LocationStore;
    const classes = useStyles();

    const [data, setData] = React.useState(null);
    const [getData, {loading:dataLoading, error:dataError, data:endpointData}] = useLazyQuery(STAT_CLINIC_BY_SPC);

    useEffect(() => {
        getData({ variables: { hos_code_arr: props.hosCodeArr, spc_code_arr: store.searchSpcArr }});
    }, [props.hosCodeArr, store.searchSpcArr]);

    useEffect(() => {
        //console.log('dataLoading', dataLoading, endpointData)
        if(!dataLoading && endpointData){
            //console.log('endpointData', endpointData);
            const title = [['', '전문과목 의원', { role: 'annotation' }, '진료과목 의원', { role: 'annotation' }]];
            //const values = CommonFunc.pivotArray(endpointData.getStatHospital, ['label', 'value']);
            let values = [];
            endpointData.getStatClinicBySpc.map((item, index) => {
                values.push([item.label, item.value_specialty, item.value_specialty, item.value_subject, item.value_subject]);
            })
            const chartData = title.concat(values);
            setData(chartData);
        }
    },[endpointData, dataLoading]);

    return(
        <>
            { dataLoading &&
                <Backdrop className={classes.backdrop} open={dataLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            {
                data &&
                <Paper className="paper-chart">
                    <Paper className="titxt">
                        <Typography>전문과별 & 진료과목별 의원수</Typography>
                    </Paper>
                    <Chart
                        chartType='ColumnChart'
                        width='100%'
                        height='200px'
                        data={data}
                        options={{
                            bar: {groupWidth: CHART.barWidth * 2},
                            legend: { position: 'top' },
                        }}
                        loader={<div>Loading Chart</div>}
                    />
                </Paper>
            }
        </>
    );
})

export default StatHosSpc;