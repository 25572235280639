import React from 'react';
import { css } from '@emotion/core';
// First way to import
import { BeatLoader } from 'react-spinners';

// const override = css`
//     width: 100%;
//     height: 464px;
//     margin: 0 auto;
//     textAlign: center;
//     lineHeight: 464px;
// `;

const override = css`
    display: inline-block;
    border-color: red;
`;

class BeatLoading_prescriptList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    render() {
        return (
            <div className='sweet-loading'>
                <BeatLoader
                    css={override}
                    sizeUnit={"px"}
                    size={5}
                    margin="3px"
                    color={'#1E88E5'}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

export default BeatLoading_prescriptList;