import React, {Component, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import { withApollo } from 'react-apollo';
import { isMobileOnly } from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

import {CONV_SPC_CODE_ENG, HLINK_CONSULTANT_MOBILE, HLINK_CONSULTANT_TEL} from "./UserConstants"
import CommonFunction from "../../common/CommonFunction";
import useStyles from './SalaryStyle';
import {SERVICE_AD_LOG} from "../../queries/salary/salary";

@inject("salaryStore")
@observer
class S_HlinkADBox extends Component {
    hlinkAdLog = (item) => {
        const salaryStore = this.props.salaryStore;
        let pathName = window.location.pathname;
        if(pathName === "/salary"){
            pathName = "/salary/payinfo";
        }

        let variables = {
            u_id: salaryStore.userProfile.u_id,
            svc_code: item.svc_code,
            svc_cont_idx: "" + item.svc_cont_idx,
            path_name: pathName
        }

        this.props.client.mutate({
            mutation: SERVICE_AD_LOG,
            variables: variables
        })
        .then((res) => {
            // //console.log('SERVICE_AD_LOG result ', res, res.data.createAdLog.result);
            if(res.data.createAdLog.result){
                let url = `http://www.medigate.net/hlink/job/detail?idx=${item.svc_cont_idx}`;
                window.open(url, '_blank')
            }
        })
        .catch((error) => {
            console.log('error', error.message);
        });
    }

    render() {
        const {
            fixedHeightPaper,
            data=[],
        } = this.props;

        const salaryStore = this.props.salaryStore;

        let spcToCode = CommonFunction.getCodeName(salaryStore.userProfile.u_spc_code, CONV_SPC_CODE_ENG);
        let spcImage = `${CommonFunction.getImageDomain()}/drgate/images/hlink/${spcToCode}.jpg`;

        return (
            <Fragment>
                {
                    data.map((item) => {
                        return(
                            <Grid item xs={12}>
                                <Paper className={fixedHeightPaper}>
                                {
                                    item.svc_cont_idx === 0 ?
                                    <Fragment>
                                        <Typography component="div">
                                            <Typography component="p">관련 공고가 없습니다.</Typography>
                                        </Typography>
                                    </Fragment>
                                        :
                                    <Fragment>
                                        <div className="hr_infoimg" style={{background:`url(${spcImage}) center center / cover no-repeat`}}></div>
                                        <div className="hlink_logo"></div>
                                        <Typography component="div" className="hr_infotxt">
                                            {
                                                !isMobileOnly ?
                                                <Link
                                                    component="span"
                                                    style={{cursor: "pointer"}}
                                                    onClick={() => { this.hlinkAdLog(item); CommonFunction.pageview('연봉인덱스', 'HLINK광고 클릭', item.svc_cont_idx); }}
                                                >

                                                        <Typography component="p" className="txtinfo1">
                                                            <span className="title">{item.title}</span>
                                                        </Typography>
                                                    <div className="txtarea">
                                                        <Typography component="p" className="txtinfo2">
                                                            <span className="txttit">초빙과목</span>
                                                            <span className="txtsub">{item.spc_name}</span>
                                                        </Typography>
                                                        <Typography component="p" className="txtinfo2">
                                                            <span className="txttit">급여</span>
                                                            <span className="txtsub">{item.pay}</span>
                                                        </Typography>
                                                        <Typography component="p" className="txtinfo2">
                                                            <span className="txttit">채용마감일</span>
                                                            <span className="txtsub">{item.end_date}</span>
                                                        </Typography>
                                                        <Typography component="p" className="txtinfo2">
                                                            <span className="txttit">담당 컨설턴트</span>
                                                            <span className="txtsub">
                                                                <span className="txtsub2" style={{paddingRight:'5px'}}>
                                                                    {CommonFunction.getCodeName(item.mgr_number, HLINK_CONSULTANT_TEL)}
                                                                </span>
                                                                /<span className="txtsub2" style={{paddingLeft:'5px'}}>
                                                                    {CommonFunction.getCodeName(item.mgr_number, HLINK_CONSULTANT_MOBILE)}
                                                                </span>
                                                            </span>
                                                        </Typography>
                                                    </div>
                                                </Link>
                                                :
                                                <Fragment>
                                                    <Link
                                                        component="span"
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => { this.hlinkAdLog(item); CommonFunction.pageview('연봉인텍스', 'HLINK광고 클릭', item.svc_cont_idx); }}
                                                    >
                                                        <div className="txtarea">
                                                            <Typography component="p" className="txtinfo1">
                                                                <span className="title">{item.title}</span>
                                                            </Typography>
                                                        </div>
                                                    </Link>
                                                    <div className="txtarea">
                                                        <Typography component="p" className="txtinfo2">
                                                            <span className="txttit">초빙과목</span>
                                                            <span className="txtsub">{item.spc_name}</span>
                                                        </Typography>
                                                        <Typography component="p" className="txtinfo2">
                                                            <span className="txttit">급여</span>
                                                            <span className="txtsub">{item.pay}</span>
                                                        </Typography>
                                                        <Typography component="p" className="txtinfo2">
                                                            <span className="txttit">채용마감일</span>
                                                            <span className="txtsub">{item.end_date}</span>
                                                        </Typography>
                                                        <Typography component="p" className="txtinfo2">
                                                            <span className="txttit">담당 컨설턴트</span>
                                                            <span className="txtsub">
                                                                <span className="txtsub2" style={{paddingRight:'5px'}}>
                                                                    <a href={`tel:${CommonFunction.getCodeName(item.mgr_number, HLINK_CONSULTANT_TEL)}`}>
                                                                        {CommonFunction.getCodeName(item.mgr_number, HLINK_CONSULTANT_TEL)}
                                                                    </a>
                                                                </span>
                                                                /<span className="txtsub2" style={{paddingLeft:'5px'}}>
                                                                    <a href={`tel:${CommonFunction.getCodeName(item.mgr_number, HLINK_CONSULTANT_MOBILE)}`}>
                                                                        {CommonFunction.getCodeName(item.mgr_number, HLINK_CONSULTANT_MOBILE)}
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </Typography>
                                                    </div>
                                                </Fragment>
                                            }
                                        </Typography>
                                    </Fragment>
                                }
                                </Paper>
                            </Grid>
                        )
                    })
                }
            </Fragment>
        );
    }
}

export default withApollo(withStyles(useStyles)(S_HlinkADBox));
