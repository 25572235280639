import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import useStyles from './SalaryStyle';

class S_BoxNoLongContent extends Component {
    render() {
        const { dataType} = this.props;
        // const { classes } = this.props;

        return (
            <Typography component="div" variant="subtitle1" color="primary" class="txtnotice type2">
                근무처 정보가 없는 사용자의 {dataType}별 통계는 노출되지 않습니다.
            </Typography>
        );
    }
}

export default withStyles(useStyles)(S_BoxNoLongContent);
