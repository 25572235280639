import React from 'react'
import LocationStore from "./LocationStore";
// import { createLocationStore, TLocationStore } from "./LocationStore";
import { useLocalStore } from 'mobx-react';

const storeContext = React.createContext(null);

export const StoreProvider = ({children}) => {
    // const store = useLocalStore(LocationStore);
    const store = useLocalStore(() => ({
        LocationStore
    }) );
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export const useStore = () => {
    const store = React.useContext(storeContext);
    if(!store){
        throw new Error('useStore must be used within a StoreProvider. useStore는 StoreProvider 내에서 사용되어야 합니다.');
    }

    return store;
}
