import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import "./kakaomap.css";
import {useStore} from "../../stores/StoreProvider";

const CustomOverlays = observer((props) => {
    const kakao = props.kakao;
    const map = props.map;
    const store = useStore().LocationStore;


    useEffect(() => {
        var placeOverlay = new kakao.maps.CustomOverlay({zIndex:1}),
            contentNode = document.createElement('div'), // 커스텀 오버레이의 컨텐츠 엘리먼트 입니다
            markers = [], // 마커를 담을 배열입니다
            currCategory = '', // 현재 선택된 카테고리를 가지고 있을 변수입니다
            lastPlace = {}; // 현재 선택된 마커의 정보

        // 엘리먼트에 이벤트 핸들러를 등록하는 함수입니다
        function addEventHandle(target, type, callback) {
            if (target.addEventListener) {
                target.addEventListener(type, callback);
            } else {
                target.attachEvent('on' + type, callback);
            }
        }

        // 카테고리 검색을 요청하는 함수입니다
        function searchPlaces() {
            if (!currCategory) {
                return;
            }

            // 커스텀 오버레이를 숨깁니다
            placeOverlay.setMap(null);

            // 지도에 표시되고 있는 마커를 제거합니다
            removeMarker();

            ps.categorySearch(currCategory, placesSearchCB, {useMapBounds:true});
        }

        // 장소검색이 완료됐을 때 호출되는 콜백함수 입니다
        function placesSearchCB(data, status, pagination) {
            if (status === kakao.maps.services.Status.OK) {
                // 정상적으로 검색이 완료됐으면 지도에 마커를 표출합니다
                displayPlaces(data);
            } else if (status === kakao.maps.services.Status.ZERO_RESULT) {
                // 검색결과가 없는경우 해야할 처리가 있다면 이곳에 작성해 주세요

            } else if (status === kakao.maps.services.Status.ERROR) {
                // 에러로 인해 검색결과가 나오지 않은 경우 해야할 처리가 있다면 이곳에 작성해 주세요

            }
        }

        // 지도에 마커를 표출하는 함수입니다
        function displayPlaces(places) {
            // 몇번째 카테고리가 선택되어 있는지 얻어옵니다
            // 이 순서는 스프라이트 이미지에서의 위치를 계산하는데 사용됩니다
            var order = document.getElementById(currCategory).getAttribute('data-order');
            for ( var i=0; i<places.length; i++ ) {
                // 마커를 생성하고 지도에 표시합니다
                var marker = addMarker(new kakao.maps.LatLng(places[i].y, places[i].x), order);

                // 마커와 검색결과 항목을 클릭 했을 때
                // 장소정보를 표출하도록 클릭 이벤트를 등록합니다
                (function(marker, place) {
                    kakao.maps.event.addListener(marker, 'click', function() {
                        if(place['display'] === 'Y'){
                            closePlaceInfo(place);
                        }
                        else{
                            displayPlaceInfo(place);
                        }
                    });
                })(marker, places[i]);
            }
        }

        // 마커를 생성하고 지도 위에 마커를 표시하는 함수입니다
        function addMarker(position, order) {
            var imageSrc = 'http://image.medigate.net/drgate/images/location/places_category_2.png', // 마커 이미지 url, 스프라이트 이미지를 씁니다
                imageSize = new kakao.maps.Size(22, 22),  // 마커 이미지의 크기
                imgOptions =  {
                    spriteSize : new kakao.maps.Size(22, 160), // 스프라이트 이미지의 크기
                    spriteOrigin : new kakao.maps.Point(0, (order*22)), // 스프라이트 이미지 중 사용할 영역의 좌상단 좌표
                    offset: new kakao.maps.Point(11, 28) // 마커 좌표에 일치시킬 이미지 내에서의 좌표
                },
                markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imgOptions),
                marker = new kakao.maps.Marker({
                    position: position, // 마커의 위치
                    image: markerImage
                });

            marker.setMap(map); // 지도 위에 마커를 표출합니다
            markers.push(marker);  // 배열에 생성된 마커를 추가합니다

            return marker;
        }

        // 지도 위에 표시되고 있는 마커를 모두 제거합니다
        function removeMarker() {
            for ( var i = 0; i < markers.length; i++ ) {
                markers[i].setMap(null);
            }
            markers = [];
        }

        // 클릭한 마커에 대한 장소 상세정보를 커스텀 오버레이로 표시하는 함수입니다
        function displayPlaceInfo (place) {
            if(lastPlace){
                lastPlace['display'] = 'N';
            }
            place['display'] = 'Y';
            lastPlace = place;

            var content = '<div class="placeinfo"><a class="title" href="' + place.place_url + '" target="_blank" title="' + place.place_name + '">' + place.place_name + '</a>';
            if (place.road_address_name) {
                content += '    <span title="' + place.road_address_name + '">' + place.road_address_name + '</span><span class="jibun" title="' + place.address_name + '">(지번 : ' + place.address_name + ')</span>';
            }
            else {
                content += '    <span title="' + place.address_name + '">' + place.address_name + '</span>';
            }
            content += '    <span class="tel">' + place.phone + '</span></div><div class="after"></div>';

            contentNode.innerHTML = content;
            placeOverlay.setPosition(new kakao.maps.LatLng(place.y, place.x));
            placeOverlay.setMap(map);
        }

        function closePlaceInfo(place){
            place['display'] = 'N';
            //lastPlace['display'] = 'N';
            placeOverlay.setMap(null);
        }

        // 각 카테고리에 클릭 이벤트를 등록합니다
        function addCategoryClickEvent() {
            var category = document.getElementById('category'),
                children = category ? category.children : [];

            for (var i=0; i<children.length; i++) {
                children[i].onclick = onClickCategory;
            }
        }

        // 카테고리를 클릭했을 때 호출되는 함수입니다
        function onClickCategory() {
            var id = this.id,
                className = this.className;

            placeOverlay.setMap(null);

            if (className === 'on') {
                currCategory = '';
                changeCategoryClass();
                removeMarker();
            } else {
                currCategory = id;
                changeCategoryClass(this);
                searchPlaces();
            }
        }

        // 클릭된 카테고리에만 클릭된 스타일을 적용하는 함수입니다
        function changeCategoryClass(el) {
            var category = document.getElementById('category'),
                children = category ? category.children : [],
                i;

            for ( i=0; i<children.length; i++ ) {
                children[i].className = '';
            }

            if (el) {
                el.className = 'on';
            }
        }

        // 장소 검색 객체를 생성합니다
        var ps = new kakao.maps.services.Places(map);
        // 지도에 idle 이벤트를 등록합니다
        kakao.maps.event.addListener(map, 'idle', searchPlaces);

        // 커스텀 오버레이의 컨텐츠 노드에 css class를 추가합니다
        contentNode.className = 'placeinfo_wrap';

        // 커스텀 오버레이의 컨텐츠 노드에 mousedown, touchstart 이벤트가 발생했을때
        // 지도 객체에 이벤트가 전달되지 않도록 이벤트 핸들러로 kakao.maps.event.preventMap 메소드를 등록합니다
        addEventHandle(contentNode, 'mousedown', kakao.maps.event.preventMap);
        addEventHandle(contentNode, 'touchstart', kakao.maps.event.preventMap);

        // 커스텀 오버레이 컨텐츠를 설정합니다
        placeOverlay.setContent(contentNode);

        // 각 카테고리에 클릭 이벤트를 등록합니다
        addCategoryClickEvent();
/*
        kakao.maps.event.addListener(map, 'click', (mouseEvent)=> {getDistanceFunc(mouseEvent)})
        kakao.maps.event.addListener(map, 'mousemove', (mouseEvent)=> {distanceMouseMove(mouseEvent)})
        kakao.maps.event.addListener(map, 'rightclick', (mouseEvent)=> {distanceDrawEnd(mouseEvent)})*/
    },[]);

    const setMapType = (maptype) => {
        if(map){
            var roadmapControl = document.getElementById('btnRoadmap');
            var skyviewControl = document.getElementById('btnSkyview');
            if (maptype === 'roadmap') {
                map.setMapTypeId(kakao.maps.MapTypeId.ROADMAP);
                roadmapControl.className = 'selected_btn';
                skyviewControl.className = 'btn';
            } else {
                map.setMapTypeId(kakao.maps.MapTypeId.HYBRID);
                skyviewControl.className = 'selected_btn';
                roadmapControl.className = 'btn';
            }
        }
    }

    const zoomIn = () => {
        if(map){
            map.setLevel(map.getLevel() - 1);
        }
    }

    const zoomOut = () => {
        if(map){
            map.setLevel(map.getLevel() + 1);
        }
    }
    const getRoadview = () => {
        if(map){
            alert("roadview")
        }
    }


    /*거리재기 기능 관련 필요 함수 / 변수들. */

    const [distanceMode, setDistanceMode] = React.useState(false); //거리재기 true/false
    const getDistance = () => {
        if(map){
            //alert("getDistance")
            setDistanceMode(!distanceMode);
            // distanceMode = !distanceMode
        }
    }

    React.useEffect(()=>{
        //console.log("customoverlay distanceMode ",distanceMode);
        if(distanceMode){
            store.setDrawType("distance");
        } else {
            store.setDrawType("CIRCLE");
        }
    },[distanceMode])
    React.useEffect(()=>{
    },[])

    const roadviewBtnAction = () => {
        let isRoadview = (store.drawType === "ROADVIEW");

        if(isRoadview){//로드뷰라면 기존꺼로
            store.setDrawType("CIRCLE");
        } else { //로드뷰가 아니면 로드뷰로
            store.setDrawType("ROADVIEW");
        }
    }

    /*
        TO-BE (hsjung) 디자인 시
        1) https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/places_category.png 이미지 확장
        2) kakaomap.css 카테고리 background-position 추가
     */

    return(
        <>
            <div className="custom_typecontrol radius_border">
                <span id="btnRoadmap" className="selected_btn" onClick={()=>setMapType('roadmap')}>지도</span>
                <span id="btnSkyview" className="btn" onClick={()=>setMapType('skyview')}>스카이뷰</span>
            </div>
            <div className="custom_zoomcontrol radius_border">
                <span className="btn1" onClick={()=>zoomIn()}></span>
                <span className="btn2" onClick={()=>zoomOut()}></span>
            </div>
            <div className="custom_roadviewcontrol radius_border">
                <span id="btnRoadview" className={(store.drawType === 'ROADVIEW')?'selected_btn':'btn'} onClick={()=>roadviewBtnAction()} style={{position: 'absolute', bottom: '60px', right: '500px', width: '35px', height: '63px', overflow: 'hidden', zIndex: '2'}}>로드뷰</span>
                {/*<span id="btnRoadview" className={(store.drawType === 'ROADVIEW')?'selected_btn':'btn'} onClick={()=>roadviewBtnAction()}>로드뷰</span>*/}
            </div>
            {/*sample 로드뷰버튼*/}
{/*            <div className="custom_typecontrol radius_border">
                <span id="btnRoadview" className={(store.drawType === 'ROADVIEW')?'selected_btn':'btn'} onClick={()=>roadviewBtnAction()}>로드뷰ㅇ</span>
            </div>*/}
            {/*sample 로드뷰버튼 끝*/}

            {/*
            <div className={`custom_etccontrol radius_border ${distanceMode ? 'active' : ''}`}>
                <span className={`distance`} onClick={()=>getDistance()}>거리재기{distanceMode.toString()}</span>
            </div>
            */}
            <ul id="category">
                <li id="PM9" data-order="0">
                    <span className="category_bg pharmacy"></span>
                    약국
                </li>
                <li id="PO3" data-order="1">
                    <span className="category_bg public"></span>
                    공공기관
                </li>
                <li id="BK9" data-order="2">
                    <span className="category_bg bank"></span>
                    은행
                </li>
                <li id="MT1" data-order="3">
                    <span className="category_bg mart"></span>
                    마트
                </li>
                <li id="PS3" data-order="4">
                    <span className="category_bg edu"></span>
                    보육기관
                </li>
                <li id="SC4" data-order="5">
                    <span className="category_bg school"></span>
                    학교
                </li>
                <li id="OL7" data-order="6">
                    <span className="category_bg oil"></span>
                    주유소
                </li>
            </ul>
        </>
    );
})

export default CustomOverlays;