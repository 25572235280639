import gql from 'graphql-tag';

//////////////
/* 급여정보 */
//////////////

export const PAYINFO_OVERVIEW = gql`
	query select($spc_code: String, $hop_loc_code: String, $org_type: String, $period_type: String) {
		getAvgPay(spc_code: $spc_code, hop_loc_code: $hop_loc_code, org_type: $org_type, period_type: $period_type) {
			type
			avg_net_pay
			avg_gross_pay
			median_net_pay
			median_gross_pay
			avg_net_growth
			avg_gross_growth
			median_net_growth
			median_gross_growth
		}
	}
`;

export const PAYINFO_COMPARE_PAY = gql`
	query select($spc_code: String, $hop_loc_code: String, $chart_menu: String, $pay_type: String, $period_type: String, $org_type: String) {
		getChartData(spc_code: $spc_code, hop_loc_code: $hop_loc_code, chart_menu: $chart_menu, pay_type: $pay_type, period_type: $period_type, org_type: $org_type) {
			x
			y
            annotationY: y
            
		}
	}
`;

export const PAYINFO_COMPARE_MULTILINE_PAY = gql`
	query select($pay_type: String) {
		getServiceTypeChartData(pay_type: $pay_type) {
			x
			g_free: G_FREE
			g_ad: G_AD
			smart: SMART
			premium: PREMIUM
			foreigner: FOREIGNER
			nooclinic: NOOCLINIC
			etc: ETC
		}
	}
`;

export const PAYINFO_NET_DISTRIBUTION = gql`
    query select($spc_code: String, $hop_loc_code: String, $org_type: String) {
        getNetSpreadChartData(spc_code: $spc_code, hop_loc_code: $hop_loc_code, org_type: $org_type) {
            x
            y
            annotationY: y
        }
    }
`;

export const PAYINFO_TERM_PERCENTILE = gql`
    query select($spc_code: String, $hop_loc_code: String, $org_type: String, $period_type: String, $u_id: String, $salary: String, $time: String) {
        getPercentileData(spc_code: $spc_code, hop_loc_code: $hop_loc_code, org_type: $org_type, period_type: $period_type, u_id: $u_id, salary:$salary, time: $time) {
            type
            pay_percent
            pay_percentile_rank
            work_percentile_rank
        }
    }
`;

//////////////
/* 근무시간 */
//////////////

export const WORKTIME_OVERVIEW = gql`
	query select($unit_type: String, $spc_code: String, $hop_loc_code: String, $org_type: String, $period_type: String) {
		getAvgHour(unit_type: $unit_type, spc_code: $spc_code, hop_loc_code: $hop_loc_code, org_type: $org_type, period_type: $period_type) {
			type
			avg_weekly_hour
			avg_net_hour
			avg_gross_hour
			avg_weekly_hour_growth
			avg_net_hour_growth
			avg_gross_hour_growth
		}
	}
`;

//////////////
/* 근무환경 */
//////////////
export const CONDITION_PIE = gql`
    query select($spc_code: String, $hop_loc_code: String, $org_type: String, $period_type: String) {
        getWorkCondPieChartData(spc_code: $spc_code, hop_loc_code: $hop_loc_code, org_type: $org_type, period_type: $period_type) {
            cnt_nego_flag_y
            cnt_nego_flag_n
            per_nego_flag_y
            per_nego_flag_n
            cnt_incentive_flag_y
            cnt_incentive_flag_n
            per_incentive_flag_y
            per_incentive_flag_n
            cnt_labor_law_flag_y
            cnt_labor_law_flag_n
            per_labor_law_flag_y
            per_labor_law_flag_n
            cnt_house_flag_y
            cnt_house_flag_n
            per_house_flag_y
            per_house_flag_n
            cnt_meal_flag_y
            cnt_meal_flag_n
            per_meal_flag_y
            per_meal_flag_n
            cnt_insu_flag_y
            cnt_insu_flag_n
            per_insu_flag_y
            per_insu_flag_n
            cnt_s_work_flag_y
            cnt_s_work_flag_n
            per_s_work_flag_y
            per_s_work_flag_n
            cnt_h_work_flag_y
            cnt_h_work_flag_n
            per_h_work_flag_y
            per_h_work_flag_n
            cnt_red_work_1
            cnt_red_work_2
            cnt_red_work_3
            per_red_work_1
            per_red_work_2
            per_red_work_3
            cnt_night_work_flag_y
            cnt_night_work_flag_n
            per_night_work_flag_y
            per_night_work_flag_n
            cnt_weekend_work_flag_y
            cnt_weekend_work_flag_n
            per_weekend_work_flag_y
            per_weekend_work_flag_n
            cnt_night_pay_flag_y
            cnt_night_pay_flag_n
            per_night_pay_flag_y
            per_night_pay_flag_n
            cnt_weekend_pay_flag_y
            cnt_weekend_pay_flag_n
            per_weekend_pay_flag_y
            per_weekend_pay_flag_n
            pay_option1
            pay_option2
            pay_option3
        }
    }
`;

export const CONDITION_STACKED = gql`
    query select($spc_code: String, $hop_loc_code: String, $org_type: String, $period_type: String, $cond_type: String) {
        getWorkCondStackedBarChartData(spc_code: $spc_code, hop_loc_code: $hop_loc_code, org_type: $org_type, period_type: $period_type, cond_type: $cond_type) {
            x
            cnt_flag_1
            annotation_1: per_flag_1
            cnt_flag_2
            cnt_flag_3
            per_flag_1
            per_flag_2
            per_flag_3
        }
    }
`;

export const CONDITION_MONTH_CHART = gql`
    query select($spc_code: String, $hop_loc_code: String, $org_type: String, $cond_type: String) {
        getWorkCondMonthlyChartData(spc_code: $spc_code, hop_loc_code: $hop_loc_code, org_type: $org_type, cond_type: $cond_type) {
            x
            cnt_flag_1
            annotation_1: per_flag_1
            cnt_flag_2
            cnt_flag_3
            per_flag_1
            per_flag_2
            per_flag_3
        }
    }
`;

//////////////
/* 초빙수요 */
//////////////

export const DEMAND_MEDI = gql`
    query select($spc_code: String, $hop_loc_code: String, $org_type: String, $period_type: String) {
        getMediDemandData(spc_code: $spc_code, hop_loc_code: $hop_loc_code, org_type: $org_type, period_type: $period_type) {
            total_cnt
            loc_cnt
            org_cnt
            spc_cnt
            total_cnt_growth
            loc_cnt_growth
            org_cnt_growth
            spc_cnt_growth
        }
    }
`;

export const DEMAND_MEDI_TREND = gql`
    query select {
        getAllMediDemandTrendChartData {
            x
            y
            annotationY: y
        }
    }
`;

export const DEMAND_KOSIS = gql`
    query select($spc_name: String, $hop_loc_name: String, $org_name: String) {
        getKosisDemandData(spc_name: $spc_name, hop_loc_name: $hop_loc_name, org_name: $org_name) {
            total_cnt
            loc_cnt
            org_cnt
            spc_cnt
            total_cnt_growth
            loc_cnt_growth
            org_cnt_growth
            spc_cnt_growth
        }
    }
`;

export const DEMAND_KOSIS_TREND = gql`
    query select($prof_type2: String, $greater_than_year: String) {
        getAllKosisDemandTrendChartData(prof_type2: $prof_type2, greater_than_year: $greater_than_year) {
            x
            y
            annotationY: y
        }
    }
`;

export const DEMAND_MEDI_COMPARE = gql`
    query select($spc_code: String, $hop_loc_code: String, $org_type: String, $period_type: String) {
        getMediDemandCompareChartData(spc_code: $spc_code, hop_loc_code: $hop_loc_code, org_type: $org_type, period_type: $period_type) {
            x
            y
            annotationY: y
        }
    }
`;

export const DEMAND_MEDI_TREND_COMPARE = gql`
    query select($spc_code: String, $hop_loc_code: String, $org_type: String) {
        getMediDemandTrendChartData(spc_code: $spc_code, hop_loc_code: $hop_loc_code, org_type: $org_type) {
            x
            y
            annotationY: y
        }
    }
`;

export const DEMAND_KOSIS_COMPARE = gql`
    query select {
        getKosisDemandCompareChartData{
            x
            y
            annotationY: y
        }
    }
`;

export const DEMAND_KOSIS_TREND_COMPARE = gql`
    query select($spc_name: String, $hop_loc_name: String, $org_name: String, $greater_than_year: String) {
        getKosisDemandTrendChartData(spc_name: $spc_name, hop_loc_name: $hop_loc_name, org_name: $org_name, greater_than_year: $greater_than_year) {
            x
            y
            annotationY: y
        }
    }
`;

/////////////////////////////////
/* 초빙광고 (only 급여정보) */
/////////////////////////////////

export const RECJOB_AD = gql`
    query select($spc_code: String) {
        getRecjobAd(spc_code: $spc_code) {
            svc_cont_idx
            svc_code
            org_name
            spc_name
            zon
            pay_type
            pay
            work_hour
            s_work_hour
            h_work_hour
            feed_id
        }
    }
`;

///////////////
/* HLINK광고 */
///////////////

export const HLINK_AD = gql`
    query select($spc_code: String, $limit_cnt: Float) {
        getHlinkAd(spc_code: $spc_code, limit_cnt: $limit_cnt) {
            svc_cont_idx
            svc_code
            title
            spc_name
            pay
            end_date
            mgr_number
        }
    }
`;

////////////////
/* 내급여정보 */
///////////////

export const SALARY_INSERT_LOG_ONE = gql`
    query select($u_id: String) {
        getSalaryInsertLogByOne(u_id: $u_id) {
            u_id
            salary_val
            time_val
            org_type
            hop_loc_code
            reg_date
            mod_flag
        }
    }
`;

//==============================//
// MUTATE
//==============================//

export const PAYINFO_SALARY_RESULT = gql`
    mutation insert($spc_code: String, $hop_loc_code: String, $org_type: String, $period_type: String, $u_id: String, $salary: String, $time: String) {
        createSalaryInsertLog(spc_code: $spc_code, hop_loc_code: $hop_loc_code, org_type: $org_type, period_type: $period_type, u_id: $u_id, salary:$salary, time: $time) {
            type
            pay_percent
            pay_percentile_rank
            work_percentile_rank
        }
    }
`;

export const SERVICE_AD_LOG = gql`
    mutation insert($u_id: String, $svc_code: String, $svc_cont_idx: String, $path_name: String) {
        createAdLog(u_id: $u_id, svc_code: $svc_code, svc_cont_idx: $svc_cont_idx, path_name: $path_name){
            result
        }
    }
`;