import React, {useEffect, useState, useContext} from 'react';
import {MapContext} from "./MapContainer";
import withPopper from "./withPopper";
import MuiSelect from "./MuiSelect";

import CssBaseline from "@material-ui/core/CssBaseline";

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CheckIcon from '@material-ui/icons/Check';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import { spacing } from '@material-ui/system';
import CloseIcon from "@material-ui/icons/Close";
import {useStore} from "../../stores/StoreProvider";
function PopperOrganization(props) {

    const { content, styles, onAction, close } = props;
    //console.log("popper org content??", content);

    const [orgTypeValue, setOrgTypeValue] = useState("");

    const [organizationList, setOrganizationList]  = useState([]);

    const defaultClicked = false;

    const store = useStore().LocationStore;

    const initialList = () => {
        let list = [
            {code:"01", values: {clicked: defaultClicked, name:"상급종합병원"}},
            {code:"11", values: {clicked: defaultClicked, name:"종합병원"},},
            {code:"05", values: {clicked: defaultClicked, name:"전문병원"},},
            {code:"21", values: {clicked: defaultClicked, name:"병원"},},
            {code:"28", values: {clicked: defaultClicked, name:"요양병원"},},
            {code:"31", values: {clicked: defaultClicked, name:"의원"},},
            {code:"71", values: {clicked: defaultClicked, name:"보건소"},},
        ];

        list = props.orgCode;

        if(store.searchOrgArr.length > 0){ //searchSpcArr이 존재할때
            list.map( (item, index)=>{
                let result = item;

                result = store.searchOrgArr.map((clickedOrg, index)=>{ //searchSpcArr과 비교하여 같은 코드라면 체크해줌
                    let cResult = result;
                    if(result.code == clickedOrg) {
                        cResult = result.clicked = !defaultClicked;
                    }
                    return cResult
                });

                return result;
            });
        }

        return list;
    };

    useEffect(()=>{
        setOrganizationList([...initialList()]);
    },[]);

    const handleClick = (e,code, key) => {
        //전문과 칩 클릭시 이벤트
        // console.info('You clicked the Chip.', e, code, key);
        let tempChipList = organizationList;

        if(e == null && code == null && key == null){
            tempChipList = tempChipList.map( (item, index) => {
                item.clicked = false;
                return item;
            });
            //console.log("org handleclick 초기화" , tempChipList);
            onAction(); //parameter 없이 보내면 초기화

        } else {
            let clickState = tempChipList[key].clicked;

            // console.info('You clicked the Chip33333333333333333333333333333.',clickState,  tempChipList[key].values["clicked"], tempChipList,typeof organizationList,typeof tempChipList);
            onAction(tempChipList[key].name, tempChipList[key].code, !clickState);

            tempChipList[key].clicked = !clickState;
            setOrganizationList([...tempChipList]); //tempChipList로 set하면 안됨. array object라서 그런듯.
        }
    };

    useEffect(() => {
        //외부에서 변경시 적용시켜주는 로직. localstorage 로직 추가필요

        let newOrgArr = [...store.searchOrgArr];
        let oldOrgArr = [...organizationList];

        if (newOrgArr !== oldOrgArr && newOrgArr.length > 0 && oldOrgArr.length > 0) {
            oldOrgArr.map((item, index) => {
                // //console.log("popperspecialty spclist map", item, index);
            });
            // handleClick()
            // setLsAddr(store.searchAddress)
        }

        return () => {
        };
    }, [store.searchOrgArr, organizationList]);

    return(
        <Paper style={styles} id={"popperOrganization"} className="popperwrap">
            <Grid container direction="column">
{/*                <Grid item>
                    <Typography paragraph>기관유형 선택</Typography>
                    <Divider />
                </Grid>*/}
                <Grid container item spacing={2} xs={12} className="conttit">
                    <Grid item xs={10}>
                        <Typography paragraph>기관유형 선택</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton aria-label="delete" name="closePopper" onClick={(e) => { e.target.name='closePopper'; close(e)}} size={"small"}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item container spacing={1} className="popper-opt2">
                {
                    organizationList.map((item, index)=>{
                        let outline = (!item.clicked) ? "outlined" : "default";
                        return (
                            <Grid item>
                                <Chip key={"orgChip"+index} name="orgChip" label={item.name} onClick={(e)=>handleClick(e,item.code, index)} style={{width: "8vw"}} variant={outline}/>
                            </Grid>
                        )
                    })
                }
                </Grid>
                <Grid item className="btn-type2">
                    <Button onClick={(e)=>handleClick()} >초기화</Button>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PopperOrganization;
// export default withPopper(PopperAddress);