import React, { Fragment } from 'react';
import { Query, withApollo } from 'react-apollo';
import _ from 'lodash';
import { isMobileOnly } from 'react-device-detect';
import CommonFunc from '../../../common/CommonFunction';
import MyCommentFragment from '../comment/MyCommentFragment';
import { MY_COMMENT_CNT } from '../../../queries/prescript/comment';
import { MY_FAVORITE_LIST } from '../../../queries/prescript/prescription';
import Tab from '@material/react-tab';
import FavoriteIngred from './FavoriteIngred';
import Tooltip from '@material-ui/core/Tooltip';

class MyLayer extends React.PureComponent {

    state = {
        user: null,
        reload_flag: 0,
        favorite_order: 'date', //'ingred'(성분명순), 'date'(등록순)
        favorite_tot_cnt: 0,
        favorite_edit_mode: null,
        comment_tot_cnt: 0,
        keyword: '',
        tab: 'favorite', //default
    }

    updateMyCommentCnt = (comment_tot_cnt) => {
        this.setState({
            comment_tot_cnt: comment_tot_cnt
        })
    }

    getTotalCommentCnt = (user) => {
        const thisObj = this;
        this.props.client.query({
			query: MY_COMMENT_CNT,
			variables: { u_id: user.id, reload_flag: this.state.reload_flag }
		})
		.then((res) => {
            thisObj.updateMyCommentCnt(res.data.getMyCommentCnt.count);
		})
		.catch((error) => {
            //console.log('error', error.message);
		});
    }

    initData = () => {
        //console.log('initData initData')
        this.setState({
            tab: 'favorite',
            favorite_edit_mode: null,
            reload_flag: this.state.reload_flag + 1
        }, () => {
            this.getTotalCommentCnt(this.state.user); //댓글 개수 조회
        });
    }

    onUpdateIngredEditMode = (mode) => {
        //console.log('onUpdateIngredEditMode', mode);

        //상병코드 삭제 시
        if(mode === 'REMOVE_COMPLETED'){
            mode = null;

            //data update
            this.setState({
                reload_flag: this.state.reload_flag + 1,
                favorite_edit_mode: mode
            });
        }
        else{
            this.setState({
                favorite_edit_mode: mode
            });
        }
    }

    componentDidMount = async () => {
        const user = await CommonFunc.getSessionUserInfo();
		if(user){
			this.setState({
                user: user
            });
		}
		else{
			//console.log('로그인 되지 않은 유저~!!');
		}
	}

    render() {
        return (
            <Fragment>
                <div id="favorite" className="modal mdc-dialog" style={{"-webkitOverflowScrolling": "touch"}}>
                    <div className="modal-dialog">
						<div className="contwrap modal-content">
                            {/*<!-- tab -->*/}
                            <div className="mdc-tab-bar" role="tablist">
                                <div className="mdc-tab-scroller">
                                    <div className="mdc-tab-scroller__scroll-area">
                                        <div className="mdc-tab-scroller__scroll-content">
                                            <Tab active={this.state.tab === 'favorite'} onClick={() => this.setState({tab:'favorite'})}>
                                                <span className="mdc-tab__content">
                                                    <span className="mdc-tab__text-label">저장한 약물(<span>{this.state.favorite_tot_cnt}</span>)</span>
                                                </span>
                                            </Tab>
                                            <Tab active={this.state.tab === 'comment'} onClick={() => this.setState({tab:'comment'})}>
                                                <span className="mdc-tab__content">
                                                    <span className="mdc-tab__text-label">작성한 평가(<span>{this.state.comment_tot_cnt}</span>)</span>
                                                </span>
                                            </Tab>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<!-- //tab -->*/}
                            <button type="button" className="mdl-button close" data-dismiss="modal">close</button>

                            {/*<!-- //저장한 약물 -->*/}
                            <div class="modal-body" style={{maxHeight: "calc(100vh - 180px)", overflowY: "auto"}}>
                                <div className="druginfo favorite" style={{display:this.state.tab === 'favorite'? 'block':'none'}}>
                                    {
                                        this.state.favorite_tot_cnt > 0 &&
                                            <Fragment>
                                                <div className="dsearch">
                                                    <label>
                                                        <i className="material-icons">search</i>
                                                    </label>
                                                    <input type="text" value={this.state.keyword} onChange={(e)=> this.setState({keyword: e.target.value})} className="react-autosuggest__input" placeholder="질환이나 약물명을 입력" />
                                                </div>
                                                <div className="sort_option">
                                                    <div className="optionselect">
                                                        <div className="option_list">
                                                            <div className={this.state.favorite_order === 'date' ? 'type_item on' : 'type_item'}>
                                                                <div className="select" onClick={()=>this.setState({favorite_order: 'date'})}>
                                                                    <span className="sort_label">등록순</span>
                                                                </div>
                                                            </div>
                                                            <div className={this.state.favorite_order === 'ingred' ? 'type_item on' : 'type_item'}>
                                                                <div className="select" onClick={()=>this.setState({favorite_order: 'ingred'})}>
                                                                    <span className="sort_label">성분명순</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modify">
                                                            {
                                                                _.isEmpty(this.state.favorite_edit_mode) &&
                                                                <button type="button" className="mdl-button" onClick={()=> this.setState({favorite_edit_mode: 'EDIT', keyword: ''})}>
                                                                    <i className="material-icons">create</i>수정
                                                                </button>
                                                            }
                                                            {
                                                                !_.isEmpty(this.state.favorite_edit_mode) &&
                                                                <Fragment>
                                                                    <button type="button" className="mdl-button" onClick={()=> this.setState({favorite_edit_mode: 'REMOVE'})}>
                                                                        <i className="material-icons">delete</i>삭제
                                                                    </button>
                                                                    <button type="button" className="mdl-button" onClick={()=> this.setState({favorite_edit_mode: null})}>
                                                                        <i className="material-icons">cancel</i>취소
                                                                    </button>
                                                                </Fragment>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        }
                                        <div className="info">
                                            <ul>
                                            {
                                                this.state.user && this.state.reload_flag > 0 &&
                                                <Query query={MY_FAVORITE_LIST} variables={{ u_id: this.state.user.id, order: this.state.favorite_order, reload_flag: this.state.reload_flag }} fetchPolicy="cache-and-network">
                                                    {({ data, loading, error, refetch, fetchMore }) => {
                                                        if (loading) return <p></p>
                                                        if (error) return <p>{error.message}</p>;
                                                        //console.log('MY_FAVORITE_LIST', this.state.reload_flag, data);

                                                        if(data && data.getMypageLB && data.getMypageLB.length > 0){
                                                            this.setState({
                                                                favorite_tot_cnt: data.getMypageLB.length
                                                            })
                                                        }

                                                        return (
                                                            <FavoriteIngred
                                                                data={data}
                                                                keyword={this.state.keyword}
                                                                favorite_edit_mode={this.state.favorite_edit_mode}
                                                                client={this.props.client}
                                                                refetch={refetch}
                                                                onUpdateIngredEditMode={this.onUpdateIngredEditMode}
                                                                //current_ctg_code={this.state.ctg_code3}
                                                                //force_rerender_category={this.state.force_rerender_category}
                                                                //onSearchPrescriptList={this.onSearchPrescriptList}
                                                            />
                                                        )
                                                    }}
                                                </Query>
                                                }
                                            </ul>
                                        </div>
                                        {
                                            this.state.favorite_tot_cnt === 0 &&
                                            <div style={{textAlign: 'center', paddingTop: '20px'}}>저장한 약물이 없습니다.</div>
                                        }
                                </div>
                            </div>

                                {/*<!-- 내 평가 -->*/}
                            <div class="modal-body" style={{maxHeight: "calc(100vh - 180px)", overflowY: "auto"}}>
                                <div className="comment" style={{display:this.state.tab === 'comment'? 'block':'none'}}>
                                    {
                                        this.state.user && this.state.reload_flag > 0 &&
                                            this.state.comment_tot_cnt > 0 ?
                                                <MyCommentFragment
                                                    user={ this.state.user }
                                                    reload_flag={ this.state.reload_flag }
                                                    updateMyCommentCnt={ this.updateMyCommentCnt }
                                                />
                                                :
                                                <div style={{textAlign: 'center', paddingTop: '40px'}}>작성한 평가가 없습니다.</div>
                                    }
                                </div>
                            </div>
                            {/*<!-- //내 평가 -->*/}
                        </div>
                        {
                            isMobileOnly &&
                            <div class="modal-footer">
                                <div className="btn_type2">
                                    <button type="button" className="mdc-button mdc-button--outlined cancel close" data-dismiss="modal">취소</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {
                    !isMobileOnly ?
                    <Tooltip title="마이 페이지">
                        <button className="dialog-button" data-toggle="modal" data-target="#favorite" onClick={()=> { this.initData(); CommonFunc.pageview('처방정보', '마이페이지'); }}>
                            <div className="info_profile">
                                <p className="profile_thumb">
                                    <i className="material-icons">star_border</i>
                                </p>
                            </div>
                        </button>
                    </Tooltip>
                    :
                    <button className="dialog-button" data-toggle="modal" data-target="#favorite" onClick={()=> { this.initData(); CommonFunc.pageview('처방정보', '마이페이지'); }}>
                        <div className="info_profile">
                            <p className="profile_thumb">
                                <i className="material-icons">star_border</i>
                            </p>
                        </div>
                    </button>
                }
            </Fragment>
        )
    }
}

export default withApollo(MyLayer);