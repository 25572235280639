import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import useStyles from './SalaryStyle';

const termCondition = [
    {text: '최근 1년', value: 'year'},
    {text: '최근 1개월', value: 'month'},
];
const payCondition_two = [
    {text: '세후', value: 'net'},
    {text: '세전', value: 'gross'},
];
const payCondition_type = [
    {text: '평균', value: 'net'},
    {text: '중앙', value: 'median'},
];
const payCondition_four = [
    {text: '월급 평균(NET)', value: 'net'},
    {text: '연봉 평균(GROSS)', value: 'gross'},
    {text: '월급 중앙(NET)', value: 'net_median'},
    {text: '연봉 중앙(GROSS)', value: 'gross_median'},
];
const payTrendCondition_two = [
    {text: '월급 평균(NET)', value: 'net'},
    {text: '연봉 평균(GROSS)', value: 'gross'}
];
const optionCondition = [
    {text: '술기에 따른 인상여부', value: 'nego'},
    {text: '인센티브', value: 'incentive'},
    {text: '연차를 근로기준법에 준함', value: 'labor_law'},
    {text: '주택제공', value: 'house'},
    {text: '식사비 별도제공', value: 'meal'},
    {text: '배상책임보험 병원가입', value: 'insu'},
    {text: '토요일 휴무', value: 's_work'},
    {text: '일요일 휴무', value: 'h_work'},
    {text: '공휴일 휴무', value: 'red_work'},
    {text: '평일 야간당직', value: 'night_work'},
    {text: '주말 당직', value: 'weekend_work'},
    {text: '평일야간 당직비 별도지급', value: 'night_pay'},
    {text: '주말 당직비 별도지급', value: 'weekend_pay'},
];
const optionDemand = [
    {text: '전체', value: '소계'},
    {text: '전문의', value: '전문의'},
    {text: '일반의', value: '일반의'},
    {text: '인턴', value: '인턴'},
    {text: '레지던트', value: '레지던트'},
];

class S_FormSelectBox extends Component {
    render() {
        const { classes, id, labelId, selectedValue, empty, defaultValue, type, optionsData, width } = this.props;

        return (
            <FormControl className={width ? classes.formControl_width : classes.formControl}>
                <Select
                    labelId={labelId}
                    id={id}
                    value={selectedValue}
                    displayEmpty={empty}
                    onChange={(e) => this.props.change(e, id)}

                >
                    {
                        defaultValue &&
                        <MenuItem value="">{defaultValue}</MenuItem>
                    }

                    {
                        type === "term" &&
                        termCondition.map((item, index) => {
                            return(
                                <MenuItem value={item.value}>{item.text}</MenuItem>
                            )
                        })
                    }
                    {
                        type === "pay_two" &&
                        payCondition_two.map((item, index) => {
                            return(
                                <MenuItem value={item.value}>{item.text}</MenuItem>
                            )
                        })
                    }
                    {
                        type === "pay_four" &&
                        payCondition_four.map((item, index) => {
                            return(
                                <MenuItem value={item.value}>{item.text}</MenuItem>
                            )
                        })
                    }
                    {
                        type === "payTrend_two" &&
                        payTrendCondition_two.map((item, index) => {
                            return(
                                <MenuItem value={item.value}>{item.text}</MenuItem>
                            )
                        })
                    }
                    {
                        type === "pay_type" &&
                        payCondition_type.map((item, index) => {
                            return(
                                <MenuItem value={item.value}>{item.text}</MenuItem>
                            )
                        })
                    }
                    {
                        type === "type_condition" &&
                        optionCondition.map((item, index) => {
                            return(
                                <MenuItem value={item.value}>{item.text}</MenuItem>
                            )
                        })
                    }
                    {
                        type === "demand" &&
                        optionDemand.map((item, index) => {
                            return(
                                <MenuItem value={item.value}>{item.text}</MenuItem>
                            )
                        })
                    }
                    {
                        type === "custom" &&
                        optionsData.map((item, index) => {
                            return(
                                <MenuItem value={item.code}>
                                    {item.name}
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        )
    }
}

export default withStyles(useStyles)(S_FormSelectBox);