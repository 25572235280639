import React, { Fragment } from 'react';
import { withApollo } from 'react-apollo';
import _ from 'lodash';
import ToggleIcon from 'material-ui-toggle-icon';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { confirmAlert } from 'react-confirm-alert';
import HtmlConfirm from '../../../common/HtmlConfirm';

import { LIKE_BOOKMARK, UNLIKE_BOOKMARK } from '../../../queries/prescript/prescription';



class FavoriteFragment extends React.PureComponent {
    constructor(props){
        super(props);

        this.state = {
            like_flag: props.item.like_flag,
            total_like_cnt: props.item.total_like_cnt
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.item !== this.props.item) {
            const { like_flag, total_like_cnt } = nextProps.item;
			this.setState({
                like_flag: like_flag,
                total_like_cnt: total_like_cnt
            })
        }
	}

    changeFavoriteFlag = (afterFavoriteFlag, user) => {
        //console.log("user", user, this.props.filter_combination)
        //console.log("vari",
        //     user.id,
        //     this.props.item.ingred_code_grp,
        //     this.props.item.combi_flag,
        //     this.props.item.method,
        //     this.props.kcd_code_arr,
        //     this.props.ctg_code_grp,
        //     this.props.ctg_code3
        // );

        if(!_.isEmpty(user)){
            let total_like_cnt = this.state.total_like_cnt + (afterFavoriteFlag === "Y" ? 1 : -1);
            this.setState({
                like_flag: afterFavoriteFlag,
                total_like_cnt: total_like_cnt
            })

            const new_item = {
                ...this.props.item,
                like_flag: afterFavoriteFlag,
                total_like_cnt: total_like_cnt
            }

            //console.log('afterFavoriteFlag', afterFavoriteFlag);

            if(afterFavoriteFlag === "Y"){
                this.props.client.mutate({
                    mutation: LIKE_BOOKMARK,
                    variables: {
                        u_id: user.id,
                        ingred_code_grp: this.props.item.ingred_code_grp,
                        ingred_combi_flag: this.props.item.combi_flag,
                        method: this.props.item.method,
                        kcd_code_arr: this.props.kcd_code_arr,
                        ctg_code_grp: this.props.ctg_code_grp,
                        ctg_code3: this.props.ctg_code3
                    }
                })
                .then((res) => {
                    this.props.onLike(new_item);
                    //console.log('SUCCESS LIKE', res);
                })
                .catch((error) => {
                    //console.log('error', error.message);
                });
            }else{
                let values = [{
                    u_id: user.id,
                    ingred_code_grp: this.props.item.ingred_code_grp,
                    ingred_combi_flag: this.props.item.combi_flag,
                    method: this.props.item.method
                }]

                //console.log('afterFavoriteFlag values', values);

                this.props.client.mutate({
                    mutation: UNLIKE_BOOKMARK,
                    variables: {
                        likeBookmark: values
                    }
                })
                .then((res) => {
                    this.props.onLike(new_item);
                    //console.log('SUCCESS UNLIKE', res);
                })
                .catch((error) => {
                    //console.log('error', error.message);
                });
            }

            //this.props.changeBookmark(afterFavoriteFlag, total_like_cnt);
        }else{
            confirmAlert({ customUI: ({ onClose }) => <HtmlConfirm message={"로그인 후 이용하실 수 있습니다"} onClose={onClose} />});
            return false;
        }
	}

    render() {
		return (
			<Fragment>
                <IconButton
                    onClick={() => this.changeFavoriteFlag(this.state.like_flag === "Y"? "N" : "Y", this.props.user)}
                >
                    <ToggleIcon
                        on={this.state.like_flag === "Y"}
                        onIcon={<FavoriteIcon />}
                        offIcon={<FavoriteBorderIcon />}
                    />
                </IconButton>
                <span>{this.state.total_like_cnt}</span>
			</Fragment>
		);
	}
}

export default withApollo(FavoriteFragment);

