import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Skeleton from "@material-ui/lab/Skeleton";

import SBarChart from './S_BarChart';

class S_LongBarGraph extends Component {
    render() {
        const { data=[], fixedHeightPaper, loopCount, legendName, loading, type, unit="" } = this.props;
        let chartArr = [];
        let spcArr = [];
        let locArr = [];
        let orgArr = [];

        if(type === 1){
            data.forEach((item, i) => {
                item.forEach((innerItem) => {
                    let tmpArray = _.toArray(innerItem)
                    if(i === 0){
                        spcArr.push(_.dropRight(tmpArray));
                    }else if(i === 1){
                        locArr.push(_.dropRight(tmpArray));
                    }else if(i === 2){
                        orgArr.push(_.dropRight(tmpArray));
                    }
                });
            });

            spcArr.unshift(["", `전문과별${unit}`, { role: "annotation" }]);
            locArr.unshift(["", `지역별${unit}`, { role: "annotation" }]);
            orgArr.unshift(["", `병원타입별${unit}`, { role: "annotation" }]);

            //console.log("spcArr", spcArr);
            //console.log("locArr", locArr);
            //console.log("orgArr", orgArr);
        }else{
            data.forEach((item) => {
                let tmpArray = _.toArray(item)
                chartArr.push(_.dropRight(tmpArray));
            });
            chartArr.unshift(legendName);

            //console.log("chartArr", chartArr);
        }


        return (
            <Fragment>
                {
                    Array.from(new Array(loopCount)).map((item, index) => (
                        <Grid item xs={12}>
                            <div className="ico_scroll"></div>
                            <Paper className={fixedHeightPaper}>
                                {
                                    !data.length > 0 || loading ?
                                    <Fragment>
                                        <Skeleton variant="rect" width="100%" height={240} />
                                    </Fragment>
                                    :
                                        loopCount !== 1 ?
                                            index === 0 ?
                                            <SBarChart
                                                data={spcArr}
                                                fill="#1e88e5"
                                                name="전문과별"
                                                custom={true}
                                            />
                                            :
                                            index === 1 ?
                                            <SBarChart
                                                data={locArr}
                                                fill="#43a047"
                                                name="지역별"
                                            />
                                            :
                                            <SBarChart
                                                data={orgArr}
                                                name="병원타입별"
                                                fill="#3f51b5"
                                            />
                                        :
                                        <SBarChart
                                            data={chartArr}
                                            name={legendName}
                                            fill="#434343"
                                            custom={true}
                                            format="#'%'"
                                        />
                                }
                            </Paper>
                        </Grid>
                    ))
                }
            </Fragment>
        )
    }
}

export default S_LongBarGraph;