import React, {useState, useEffect, Fragment} from 'react';

import LeaseAdContent from './LeaseAdContent';
import HospitalContent from './HospitalContent';
import {useStore} from "../../stores/StoreProvider";

function LeaseAdBox(props){
    const store = useStore().LocationStore;
    const setCenterMarker = (posArr, markerTitle, isLease) => {
        //posArr[0] : y, posArr[1] : x
        store.setCenterPosArr([posArr[0], posArr[1]]);
        store.setCenterMarker({lat:posArr[0], lng:posArr[1], title: markerTitle, isLease: isLease});
    }
    return (
        <Fragment>
            {/* 동네 매물정보 */}
            <LeaseAdContent handleCenterMarker={setCenterMarker}/>

            {/* 의료기관정보 */}
            <HospitalContent handleCenterMarker={setCenterMarker}/>
        </Fragment>
    )
}

export default LeaseAdBox;