import React, {useState, useEffect, Fragment} from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import LeaseAdBox from './LeaseAdBox';

function LeaseAdContainer(props) {
    return (
        <Paper
            className="loc-cont"
            component="div"
            elevation={3}
            variant="outlined"
        >
            <LeaseAdBox />
        </Paper>
    )
}

export default LeaseAdContainer;