import React, {Fragment} from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Category = ({data, force_rerender_category, current_ctg_code, onSearchPrescriptList}) => {
    // const classes = useStyles();
    const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    let seq = 0;

    return (
        <div id="wrap_sidemenu_v2">
        {/*
        <div className="wrap_sidemenu">
            <div id="sidemenu">
                <div className="sidelst">
        */}
                    {
                        (data.getCtg2List && data.getCtg2List.length > 0) &&
                        data.getCtg2List.map((item, index) =>
                        <ExpansionPanel key={index} expanded={expanded === `panel${(index + 1)}`} onChange={handleChange(`panel${(index + 1)}`)}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${(index + 1)}bh-content`}
                                id={`panel${(index + 1)}bh-header`}
                            >
                                <Typography>{item.ctg_name}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{display:'block'}}>
                            {
                                data.getCtg3List.map((item1, index1) => {
                                    if(item1.ctg_code_grp === item.ctg_code_grp){
                                        //카테고리 re-render 시 호출됨
                                        if(current_ctg_code === null && seq === 0){
                                            //console.log('forceInit ctg_code =========>', item1.ctg_code);
                                            onSearchPrescriptList(item1.ctg_code_grp, item1.ctg_code, item1.ctg_name, item.ctg_name);
                                        }
                                        seq++;
                                        return (
                                            <Typography
                                                key={index1}
                                                className={item1.ctg_code === current_ctg_code ? 'on': ''}
                                                onClick={()=>onSearchPrescriptList(item1.ctg_code_grp, item1.ctg_code, item1.ctg_name, item.ctg_name)}
                                            >
                                                {item1.ctg_name}
                                            </Typography>
                                        )
                                    }
                                })
                            }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        )
                    }
        {/*
                </div>
            </div>
        </div>
        */}
        </div>
    );
}

export default Category;