import React, {Component, Fragment} from 'react';

import Typography from "@material-ui/core/Typography";
import CommonFunction from "../../common/CommonFunction";
import { HTP_CODE, LOC_CODE, SPC_CODE } from "./UserConstants";
import SBoxNoContent from "./S_BoxNoContent";

class S_BoxWorktimeSubContent extends Component {
    render() {
        const { data, spc_code, hop_loc_code, org_type } = this.props;
        const dataType = data.type.split(",");

        return (
            <Fragment>
                {
                    (dataType[1] && dataType[1] === "null") ?
                        <SBoxNoContent
                            dataType={dataType}
                        />
                        :
                        <Typography component="span" variant="subtitle1" style={{marginBottom:"0"}}>
                            선생님의 시간당 급여는
                            <Typography component="p" variant="subtitle1" style={{margin:"0 0 15px",borderBottom:"1px dashed rgba(0,0,0,0.1)",paddingBottom:"10px"}}>
                                <p className="txtinfo">
                                    <Typography component="span" variant="subtitle1" color="Sprimary">
                                        {
                                            data.type === "ALL" &&
                                            <Fragment>전체</Fragment>
                                        }
                                        {
                                            data.type === "SPC" &&
                                            CommonFunction.getCodeName(spc_code, SPC_CODE)
                                        }
                                        {
                                            data.type === "ZON" &&
                                            CommonFunction.getCodeName(hop_loc_code, LOC_CODE)
                                        }
                                        {
                                            data.type === "HTP" &&
                                            CommonFunction.getCodeName(org_type, HTP_CODE)
                                        }
                                    </Typography> 기준
                                </p>
                                <p className="txtinfo">평균의
                                    <Typography component="span" variant="subtitle1" color="secondary"> {data.pay_percentile_rank}%</Typography> 해당하고,
                                </p>
                            </Typography>
                            근무시간은
                            <p className="txtinfo">
                                <Typography component="span" variant="subtitle1" color="secondary">{data.work_percentile_rank}퍼센타일</Typography>에 해당합니다.
                            </p>
                        </Typography>
                }
            </Fragment>
        )
    }
}

export default S_BoxWorktimeSubContent;