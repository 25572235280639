import React, {Fragment, useState} from 'react';
import LocationMain from './LocationMain';
import LocationMainIframe from './LocationMainIframe';
import ServicePolicy from "../common/ServicePolicy";
import SServicePolicy from "../common/ServicePolicy";
import {useStore} from "../../stores/StoreProvider";
import {observer} from "mobx-react";
import {withCookies} from "react-cookie";
import useLocalStorage from "react-use-localstorage";
import { isMobileOnly } from 'react-device-detect';
import LocationMobileNotice from "./LocationMobileNotice";

// class Location extends React.PureComponent {
const Location = observer((props) => {
    const store = useStore().LocationStore;
    const {cookies} = props;
    const [lPolicyChecked, setLpolicyChecked] = useLocalStorage('lPolicyChecked', 'n');
    const [lPolicySession, setLpolicySession] = useState(sessionStorage.getItem("lPolicySession"));

    React.useEffect(()=>{
        if(cookies != null){
            if(cookies.get("mLpcV") == "Y"){
                store.setLocationServiceAgreeFlag(true);
            }
        }
    }, [cookies]);

    const setPolicyAgree = (bool) => {
        store.setLocationServiceAgreeFlag(true);
        setLpolicySession("y");
        sessionStorage.setItem("lPolicySession", 'y')
    }

    const locationComponent = () => {
        window.location.href = `https://location.medigate.net`;
        /*
        const fromMedigateIframe = window.location.search.indexOf('medigate_iframe') !== -1;

        if(isMobileOnly){
            return <LocationMobileNotice />
        }
        else if(fromMedigateIframe) {
            return <LocationMainIframe />
        }
        else{
            return <LocationMain />
        }*/
    }
    return (
        <>
            {/*{!store.locationServiceAgreeFlag ?*/}
            {/*{lPolicyChecked == "y" || lPolicySession == "y" ?*/}
            {locationComponent()}
            {/*:*/}
            {/*<ServicePolicy cookieName={"mLpcV"} handleFunction={setPolicyAgree} type={"l"}/>*/}
            {/*}*/}
        </>
    );
});

export default withCookies(Location);
