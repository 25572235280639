export const DEMO_KCD_CODE = [
    {code: 'A020', label: '살모넬라 장염' },
    {code: 'A021', label: '살모넬라 패혈증' },
    {code: 'A022', label: '국소성 살모넬라 감염' }
]

/*
export const SPC_CODE = [
    {code: 'SPC101', label: '가정의학과' },
    {code: 'SPC102', label: '결핵과' },
    {code: 'SPC103', label: '내과' },
    {code: 'SPC104', label: '마취통증의학과' }
];
*/
export const WTP_CODE = [
    {code: 'WTP001', label: '기타' },
    {code: 'WTP003', label: '인턴' },
    {code: 'WTP004', label: '레지던트' },
    {code: 'WTP005', label: '교직' }
];

//전문과목 코드
export const SPC_CODE = [
    {code: '23', label: '가정의학과'},
    {code: '20', label: '결핵과'},
    {code: '01', label: '내과'},
    {code: '09', label: '마취통증의학과'},
    {code: '17', label: '방사선종양학과'},
    {code: '18', label: '병리과'},
    {code: '15', label: '비뇨의학과'},
    {code: '10', label: '산부인과'},
    {code: '08', label: '성형외과'},
    {code: '11', label: '소아청소년과'},
    {code: '02', label: '신경과'},
    {code: '06', label: '신경외과'},
    {code: '24', label: '응급의학과'},
    {code: '12', label: '안과'},
    {code: '16', label: '영상의학과'},
    {code: '26', label: '예방의학과'},
    {code: '04', label: '외과'},
    {code: '13', label: '이비인후과'},
    {code: '19', label: '진단검사의학과'},
    {code: '03', label: '정신건강의학과'},
    {code: '05', label: '정형외과'},
    {code: '21', label: '재활의학과'},
    {code: '25', label: '직업환경의학과'},
    {code: '14', label: '피부과'},
    {code: '22', label: '핵의학과'},
    {code: '07', label: '흉부외과'},
    {code: '00', label: '일반의'},
];

//진료과목 코드
export const SBJ_CODE = [
        {code: '00', label: '일반의'}
    ].concat(SPC_CODE);

export const MG_SPC_CODE_MAP = [
    { mg_code: 'SPC116', code: '00', label: '일반의'},
    { mg_code: 'SPC101', code: '23', label: '가정의학과'},
    { mg_code: 'SPC102', code: '20', label: '결핵과'},
    { mg_code: 'SPC103', code: '01', label: '내과'},
    { mg_code: 'SPC104', code: '09', label: '마취통증의학과'},
    { mg_code: 'SPC123', code: '17', label: '방사선종양학과'},
    { mg_code: 'SPC130', code: '18', label: '병리과'},
    { mg_code: 'SPC105', code: '15', label: '비뇨의학과'},
    { mg_code: 'SPC106', code: '10', label: '산부인과'},
    { mg_code: 'SPC108', code: '08', label: '성형외과'},
    { mg_code: 'SPC109', code: '11', label: '소아청소년과'},
    { mg_code: 'SPC110', code: '02', label: '신경과'},
    { mg_code: 'SPC111', code: '06', label: '신경외과'},
    { mg_code: 'SPC113', code: '12', label: '안과'},
    { mg_code: 'SPC122', code: '16', label: '영상의학과'},
    { mg_code: 'SPC129', code: '26', label: '예방의학과'},
    { mg_code: 'SPC117', code: '04', label: '외과'},
    { mg_code: 'SPC114', code: '24', label: '응급의학과'},
    { mg_code: 'SPC115', code: '13', label: '이비인후과'},
    { mg_code: 'SPC119', code: '21', label: '재활의학과'},
    { mg_code: 'SPC120', code: '03', label: '정신건강의학과'},
    { mg_code: 'SPC121', code: '05', label: '정형외과'},
    { mg_code: 'SPC107', code: '25', label: '직업환경의학과'},
    { mg_code: 'SPC118', code: '19', label: '진단검사의학과'},
    { mg_code: 'SPC124', code: '14', label: '피부과'},
    { mg_code: 'SPC125', code: '22', label: '핵의학과'},
    { mg_code: 'SPC126', code: '07', label: '흉부외과'}
];

//시군구 코드
export const SIDO_CODE = [
    {code: '11', label: '서울특별시'},
    {code: '21', label: '부산광역시'},
    {code: '22', label: '대구광역시'},
    {code: '23', label: '인천광역시'},
    {code: '24', label: '광주광역시'},
    {code: '25', label: '대전광역시'},
    {code: '26', label: '울산광역시'},
    {code: '29', label: '세종특별자치시'},
    {code: '31', label: '경기도'},
    {code: '32', label: '강원도'},
    {code: '33', label: '충청북도'},
    {code: '34', label: '충청남도'},
    {code: '35', label: '전라북도'},
    {code: '36', label: '전라남도'},
    {code: '37', label: '경상북도'},
    {code: '38', label: '경상남도'},
    {code: '39', label: '제주특별자치도'}
];

export const CHART = {
    barWidth: 20
}
