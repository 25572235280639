import React from 'react';

class Combi2_PlaceHolder extends React.Component {
    render() {
        return (
            <div className="loading">
                <ul className="druglist">
                    <li className="bk">
                        <div className="contlist">
                            <div className="txtinfo td00">
                                <div className="css-2b097c-container"></div>
                            </div>
                            <div className="txtinfo td01">
                                <div className="css-2b097c-container"></div>
                            </div>
                            <div className="txtinfo td02">
                                <div className="css-2b097c-container" ></div>
                            </div>
                            <div className="txtinfo td06">
                                <div className="css-2b097c-container"></div>
                            </div>
                            <div className="txtinfo td03">
                                <div className="dialog-button">
                                    <div className="titxt">
                                        <div className="css-2b097c-container"></div>
                                    </div>
                                    <div className="subtxt">
                                        <div className="css-2b097c-container"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="txtinfo td04">
                                <div className="css-2b097c-container"></div>
                            </div>
                        </div>
                    </li>
                    <li className="bk">
                        <div className="contlist">
                            <div className="txtinfo td00">
                                <div className="css-2b097c-container"></div>
                            </div>
                            <div className="txtinfo td01">
                                <div className="css-2b097c-container"></div>
                            </div>
                            <div className="txtinfo td02">
                                <div className="css-2b097c-container" ></div>
                            </div>
                            <div className="txtinfo td06">
                                <div className="css-2b097c-container"></div>
                            </div>
                            <div className="txtinfo td03">
                                <div className="dialog-button">
                                    <div className="titxt">
                                        <div className="css-2b097c-container"></div>
                                    </div>
                                    <div className="subtxt">
                                        <div className="css-2b097c-container"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="txtinfo td04">
                                <div className="css-2b097c-container"></div>
                            </div>
                        </div>
                    </li>
                    <li className="bk">
                        <div className="contlist">
                            <div className="txtinfo td00">
                                <div className="css-2b097c-container"></div>
                            </div>
                            <div className="txtinfo td01">
                                <div className="css-2b097c-container"></div>
                            </div>
                            <div className="txtinfo td02">
                                <div className="css-2b097c-container" ></div>
                            </div>
                            <div className="txtinfo td06">
                                <div className="css-2b097c-container"></div>
                            </div>
                            <div className="txtinfo td03">
                                <div className="dialog-button">
                                    <div className="titxt">
                                        <div className="css-2b097c-container"></div>
                                    </div>
                                    <div className="subtxt">
                                        <div className="css-2b097c-container"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="txtinfo td04">
                                <div className="css-2b097c-container"></div>
                            </div>
                        </div>
                    </li>
                    <li className="bk">
                        <div className="contlist">
                            <div className="txtinfo td00">
                                <div className="css-2b097c-container"></div>
                            </div>
                            <div className="txtinfo td01">
                                <div className="css-2b097c-container"></div>
                            </div>
                            <div className="txtinfo td02">
                                <div className="css-2b097c-container" ></div>
                            </div>
                            <div className="txtinfo td06">
                                <div className="css-2b097c-container"></div>
                            </div>
                            <div className="txtinfo td03">
                                <div className="dialog-button">
                                    <div className="titxt">
                                        <div className="css-2b097c-container"></div>
                                    </div>
                                    <div className="subtxt">
                                        <div className="css-2b097c-container"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="txtinfo td04">
                                <div className="css-2b097c-container"></div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
}

export default Combi2_PlaceHolder;