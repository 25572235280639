import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import useStyles from "../../style/common";
import {useStore} from "../../../stores/StoreProvider";
import {useLazyQuery} from "@apollo/react-hooks";
import {STAT_SGIS_SUMMARY} from "../../../queries/location";
import _ from 'lodash';
import {Chart} from "react-google-charts";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from 'highcharts/highcharts-more';
import {SIDO_CODE, SPC_CODE, SBJ_CODE, CHART } from '../../../common/constants'
import CommonFunc from "../../../common/CommonFunction";

const BIZ_CTG1_TYPES = [
    {ctg1_code:'1', ctg1_name: '생활업종'},
    {ctg1_code:'2', ctg1_name: '주요편의시설'},
]

const BIZ_TYPES = [
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'10', ctg2_name: '생활서비스', ctg3_code:'1001', ctg3_name: '인테리어'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'10', ctg2_name: '생활서비스', ctg3_code:'1002', ctg3_name: '목욕탕'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'10', ctg2_name: '생활서비스', ctg3_code:'1003', ctg3_name: '교습학원'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'10', ctg2_name: '생활서비스', ctg3_code:'1004', ctg3_name: '어학원'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'10', ctg2_name: '생활서비스', ctg3_code:'1005', ctg3_name: '예체능학원'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'10', ctg2_name: '생활서비스', ctg3_code:'1006', ctg3_name: '부동산중개업'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'10', ctg2_name: '생활서비스', ctg3_code:'1007', ctg3_name: '이발소'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'10', ctg2_name: '생활서비스', ctg3_code:'1008', ctg3_name: '미용실'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'10', ctg2_name: '생활서비스', ctg3_code:'1009', ctg3_name: '세탁소'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'10', ctg2_name: '생활서비스', ctg3_code:'1010', ctg3_name: 'PC방'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'10', ctg2_name: '생활서비스', ctg3_code:'1011', ctg3_name: '노래방'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'20', ctg2_name: '도소매', ctg3_code:'2001', ctg3_name: '문구점'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'20', ctg2_name: '도소매', ctg3_code:'2002', ctg3_name: '서점'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'20', ctg2_name: '도소매', ctg3_code:'2003', ctg3_name: '편의점'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'20', ctg2_name: '도소매', ctg3_code:'2004', ctg3_name: '식료품점'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'20', ctg2_name: '도소매', ctg3_code:'2005', ctg3_name: '휴대폰점'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'20', ctg2_name: '도소매', ctg3_code:'2006', ctg3_name: '의류'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'20', ctg2_name: '도소매', ctg3_code:'2007', ctg3_name: '화장품/방향제'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'20', ctg2_name: '도소매', ctg3_code:'2008', ctg3_name: '철물점'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'20', ctg2_name: '도소매', ctg3_code:'2009', ctg3_name: '주유소'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'20', ctg2_name: '도소매', ctg3_code:'2010', ctg3_name: '꽃집'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'20', ctg2_name: '도소매', ctg3_code:'2011', ctg3_name: '슈퍼마켓'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'40', ctg2_name: '숙박', ctg3_code:'4001', ctg3_name: '호텔'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'40', ctg2_name: '숙박', ctg3_code:'4002', ctg3_name: '여관'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'40', ctg2_name: '숙박', ctg3_code:'4003', ctg3_name: '팬션'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'50', ctg2_name: '음식점', ctg3_code:'5001', ctg3_name: '한식'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'50', ctg2_name: '음식점', ctg3_code:'5002', ctg3_name: '중식'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'50', ctg2_name: '음식점', ctg3_code:'5003', ctg3_name: '일식'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'50', ctg2_name: '음식점', ctg3_code:'5004', ctg3_name: '분식'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'50', ctg2_name: '음식점', ctg3_code:'5005', ctg3_name: '서양식'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'50', ctg2_name: '음식점', ctg3_code:'5006', ctg3_name: '제과점'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'50', ctg2_name: '음식점', ctg3_code:'5007', ctg3_name: '패스트푸드'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'50', ctg2_name: '음식점', ctg3_code:'5008', ctg3_name: '치킨'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'50', ctg2_name: '음식점', ctg3_code:'5009', ctg3_name: '호프 및 간이주점'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'50', ctg2_name: '음식점', ctg3_code:'5010', ctg3_name: '카페'},
    {ctg1_code:'1', ctg1_name: '생활업종', ctg2_code:'50', ctg2_name: '음식점', ctg3_code:'5011', ctg3_name: '기타 외국식'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'60', ctg2_name: '공공', ctg3_code:'6001', ctg3_name: '우체국'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'60', ctg2_name: '공공', ctg3_code:'6002', ctg3_name: '행정기관'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'60', ctg2_name: '공공', ctg3_code:'6003', ctg3_name: '경찰/지구대'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'60', ctg2_name: '공공', ctg3_code:'6004', ctg3_name: '소방서'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'70', ctg2_name: '교육', ctg3_code:'7001', ctg3_name: '초등학교'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'70', ctg2_name: '교육', ctg3_code:'7002', ctg3_name: '중학교'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'70', ctg2_name: '교육', ctg3_code:'7003', ctg3_name: '고등학교'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'70', ctg2_name: '교육', ctg3_code:'7004', ctg3_name: '전문대학'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'70', ctg2_name: '교육', ctg3_code:'7005', ctg3_name: '대학교'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'70', ctg2_name: '교육', ctg3_code:'7006', ctg3_name: '대학원'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'70', ctg2_name: '교육', ctg3_code:'7007', ctg3_name: '어린이보육업'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'80', ctg2_name: '기업', ctg3_code:'8001', ctg3_name: '제조/화학'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'80', ctg2_name: '기업', ctg3_code:'8002', ctg3_name: '서비스'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'80', ctg2_name: '기업', ctg3_code:'8003', ctg3_name: '통신/IT'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'80', ctg2_name: '기업', ctg3_code:'8004', ctg3_name: '건설'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'80', ctg2_name: '기업', ctg3_code:'8005', ctg3_name: '판매/유통'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'80', ctg2_name: '기업', ctg3_code:'8006', ctg3_name: '기타금융업'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'80', ctg2_name: '기업', ctg3_code:'8007', ctg3_name: '기타의료업'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'80', ctg2_name: '기업', ctg3_code:'8008', ctg3_name: '문화/체육'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'90', ctg2_name: '편의/문화', ctg3_code:'9001', ctg3_name: '백화점/중대형마트'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'90', ctg2_name: '편의/문화', ctg3_code:'9002', ctg3_name: '은행'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'90', ctg2_name: '편의/문화', ctg3_code:'9003', ctg3_name: '병원'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'90', ctg2_name: '편의/문화', ctg3_code:'9004', ctg3_name: '극장/영화관'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'90', ctg2_name: '편의/문화', ctg3_code:'9005', ctg3_name: '도서관/박물관'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'30', ctg2_name: '교통', ctg3_code:'3001', ctg3_name: '지하철역'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'30', ctg2_name: '교통', ctg3_code:'3002', ctg3_name: '터미널'},
    {ctg1_code:'2', ctg1_name: '주요편의시설', ctg2_code:'99', ctg2_name: '기타', ctg3_code:'9900', ctg3_name: '기타'},
]

const StatSgis = (props) => {
    const store = useStore().LocationStore;
    const classes = useStyles();

    const defaultCtg1Code = '1';
    const [data, setData] = React.useState(null);
    const [year, setYear] = React.useState(null);
    const [emdCodeArr, setEmdCodeArr] = React.useState(_.map(props.nearDongList, 'emd_code'));
    const [emdCode, setEmdCode] = React.useState(null); //읍면동 필터링

    const [getData, {loading:dataLoading, error:dataError, data:endpointData}] = useLazyQuery(STAT_SGIS_SUMMARY);

    const [bizCtg1Code, setBizCtg1Code] = React.useState(null); //category 1 depth code
    const [bizCtg2Code, setBizCtg2Code] = React.useState(null); //category 2 depth code
    const [bizCtg2List, setBizCtg2List] = React.useState(null);
    const [bizChartData, setBizChartData] = React.useState(null);
    const [bizChartTrendData, setBizChartTrendData] = React.useState(null);

    const filterEmd = (selEmdCode) => {
        //console.log('selEmdCode', selEmdCode)
        setEmdCode(selEmdCode);
    }

    const handleBizCtg1 = (ctg1Code) => {
        //console.log('ctg1Code', ctg1Code);
        setBizCtg1Code(ctg1Code);
    }
    const handleBizCtg2 = (ctg2Code) => {
        //console.log('ctg2Code', ctg2Code);
        setBizCtg2Code(ctg2Code);
    }

    useEffect(() => {
        CommonFunc.pageview('입지분석', '통계-인구상권');
    }, []);

    useEffect(() => {
        if(emdCode){
            setEmdCodeArr([emdCode]);
        }
    }, [emdCode]);

    useEffect(() => {
        //console.log('emd_code_arr', emdCodeArr);
        setBizCtg1Code(null);
        setBizCtg2Code(null);
        getData({ variables: { emd_code_arr: emdCodeArr} } );
        if(emdCodeArr.length === 1){
            //console.log('emdCodeArr', emdCodeArr)
            setEmdCode(emdCodeArr[0]);
        }
    }, [emdCodeArr]);

    useEffect(() => {
        //console.log('dataLoading', dataLoading, endpointData);
        if(!dataLoading && endpointData){
            const sgisSummaryData = endpointData.getStatSgisSummary;
            //console.log('sgisSummaryData', sgisSummaryData)
            setData(sgisSummaryData);
            setBizCtg1Code(defaultCtg1Code);
        }
    }, [endpointData]);

    useEffect(() => {
        //console.log('bizCtg1Code_changed ->', bizCtg1Code);
        if(bizCtg1Code){
            var ctgCodes = _.uniqBy(
                _.filter(BIZ_TYPES, {ctg1_code: bizCtg1Code}).map(item => { return {ctg2_code: item.ctg2_code, ctg2_name: item.ctg2_name} })
                , 'ctg2_code'
            );
            setBizCtg2List(ctgCodes);
            setBizCtg2Code(ctgCodes[0].ctg2_code);
        }
        return () => {
            //console.log('prev-bizCtg1Code', bizCtg1Code);
        };
    }, [bizCtg1Code]);

    //업종별 사업체수
    useEffect(() => {
        //console.log('bizCtg2Code_changed ->', bizCtg2Code);
        if(data && bizCtg2Code){
            handleBizChartData(data);
        }
        else{
            setBizChartData(null);
            setBizChartTrendData(null);
        }
    }, [bizCtg2Code]);

    const handleBizChartData = (sgisSummaryData) => {
        console.log('handleBizChartData', sgisSummaryData);
        if(!sgisSummaryData){
            setBizChartData(null);
            setBizChartTrendData(null);
            return;
        }

        const years = _.map(_.map(sgisSummaryData, 'biz_theme_increase')[0], 'year');
        //console.log('years', years);
        const year = _.max(years);
        //console.log('year', year);
        setYear(year);

        const bizThemeIncreaseList = _.map(sgisSummaryData, 'biz_theme_increase');
        //console.log('bizThemeIncreaseList', bizThemeIncreaseList);

        let themeList = []; // 해당 년도의 테마 리스트 추출
        bizThemeIncreaseList.map(item => {
            const dataOfyear = _.filter(item, {year: year});
            if(dataOfyear && dataOfyear.length > 0){
                themeList.push(dataOfyear[0].theme_list);
            }
        })
        //console.log('themeList', themeList.length);

        //---------------------- 업종별 사업체수 (최근년도) ----------------------//
        let bizChartData = [];
        bizChartData.push(['업종', '업체수', {role: 'annotation'}]);
        const ctg3CodeArr = _.map(_.filter(BIZ_TYPES, {ctg2_code: bizCtg2Code}), 'ctg3_code'); //ctg3 조회

        ctg3CodeArr.map(item=>{
            //let themeName = null;
            const themeNameArr = _.filter(BIZ_TYPES, {ctg3_code: item});
            let themeName = null;
            if(themeNameArr.length > 0){
                themeName = themeNameArr[0].ctg3_name;
            }
            let themeValue = 0;
            themeList.map(theme => {
                const values = _.filter(theme, {theme_cd: item});
                if(values && values.length > 0){
                    //themeName = values[0].theme_nm;
                    themeValue += values[0].corp_cnt;
                }
            })
            bizChartData.push([themeName, themeValue, themeValue]);
        })
        //console.log('bizChartData', bizChartData);
        setBizChartData(bizChartData);

        //---------------------- 사업체 증감 추이 ----------------------//
        let bizChartTrendData = [];
        let bizChartTrendTitle = ['년도'];
        ctg3CodeArr.map(item=>{
            bizChartTrendTitle.push(_.filter(BIZ_TYPES, {ctg3_code: item})[0].ctg3_name);
            bizChartTrendTitle.push({ role: 'annotation' });  //annotation
        })
        //console.log('bizChartTrendTitle', bizChartTrendTitle);
        bizChartTrendData.push(bizChartTrendTitle);

        years.map(y=>{
            let bizChartTrendValues = [];
            bizChartTrendValues.push(y);

            let themeList = []; // 해당 년도의 테마 리스트 추출
            bizThemeIncreaseList.map(item => {
                const dataOfyear = _.filter(item, {year: y});
                if(dataOfyear && dataOfyear.length > 0){
                    themeList.push(dataOfyear[0].theme_list);
                }
                //console.log('dataOfyear', year, dataOfyear);
            })
            //console.log('themeList', themeList.length);

            ctg3CodeArr.map(item=>{
                let themeValue = 0;
                themeList.map(theme => {
                    const values = _.filter(theme, {theme_cd: item});
                    //console.log(values);
                    if(values && values.length > 0){
                        //themeName = values[0].theme_nm;
                        themeValue += values[0].corp_cnt;
                        //console.log('themeName', themeName, values[0].corp_cnt);
                    }
                })
                bizChartTrendValues.push(themeValue); //values
                bizChartTrendValues.push(themeValue); //annotation
            })
            //console.log('bizChartTrendValues', bizChartTrendValues);
            bizChartTrendData.push(bizChartTrendValues);
        })
        //console.log('bizChartTrendData', bizChartTrendData);
        setBizChartTrendData(bizChartTrendData);
    }

    const age_pp = data ? _.map(data, 'age_pp') : null;
    const house_pp = data ? _.map(data, 'house_pp') : null;

    let regionTotalChartData = null;
    if(data && emdCode){
        /*console.log('data', data)
        console.log('zzz', [
            ['거주형태', '인구수'],
            ['아파트'].concat(_.sum(_.map(house_pp, 'apart_cnt'))),
            ['오피스텔'].concat(_.sum(_.map(house_pp, 'officetel_cnt'))),
            ['연립/다세대'].concat(_.sum(_.map(house_pp, 'row_house_cnt'))),
            ['기숙사 및 사회시설'].concat(_.sum(_.map(house_pp, 'dom_soc_fac_cnt'))),
            ['단독주택'].concat(_.sum(_.map(house_pp, 'detach_house_cnt'))),
            ['기타'].concat(_.sum(_.map(house_pp, 'etc_cnt'))),
        ])*/

        const regionTotals = _.filter(data, {emd_code: emdCode});
        if(regionTotals && regionTotals.length > 0){
            const regionTotal = regionTotals[0].region_total;
            regionTotalChartData = regionTotal.map(item => {
                return {
                    name: item.adm_nm,
                    data: [ item.apart_per, item.resid_ppltn_per, item.job_ppltn_per, item.one_person_family_per, item.sixty_five_more_ppltn_per, item.twenty_ppltn_per ],
                    pointPlacement: 'on'
                }
            })
            //console.log('regionTotalChartData', regionTotalChartData);
        }


    }

    let sgisYear = null;
    if(data && data.length > 0){
      sgisYear = data[0].sgis_year;
    }
    //console.log('sgisYear', sgisYear);

    //const bizChartHeight = bizChartData ? `${bizChartData.length * (CHART.barWidth + 10)}px` : '0px';
    //const bizChartHeight = '700px';
    //console.log('bizChartHeight', bizChartHeight);

    return (
        <>
            { dataLoading &&
                <Backdrop className={classes.backdrop} open={dataLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            { (!dataLoading && !data) &&
                <Grid container direction={"column"} className="sgisInfo">
                    <Grid item className="sort-tab">
                        <Paper>SGIS에서 제공되는 인구/상권정보 데이터가 없습니다.</Paper>
                    </Grid>
                </Grid>
            }
            { data &&
                <Grid container direction={"column"} className="sgisInfo">
                    <Grid item className="sort-tab">
                        <Paper>
                            {
                                props.nearDongList.length > 1 &&
                                <Button variant="contained" color={emdCode === null ? 'secondary' : 'primary'} onClick={() => { setEmdCodeArr(_.map(props.nearDongList, 'emd_code')); setEmdCode(null);}}>전체</Button>
                            }
                            {
                                props.nearDongList.map(item =>
                                    <Button variant="contained" color={emdCode === item.emd_code ? 'secondary' : 'primary'} onClick={() => filterEmd(item.emd_code)}>{item.emd_name}</Button>
                                )
                            }
                        </Paper>
                    </Grid>
                    <Grid container xs={12} className="info-total">
                        <Grid item xs={3}>
                            <Paper>
                                <Typography>총 인구수</Typography>
                                {_.sum(_.map(data, 'tot_ppltn')).toLocaleString()}
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper>
                                <Typography>총 가구수</Typography>
                                {_.sum(_.map(data, 'tot_family')).toLocaleString()}
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper>
                                <Typography>인구밀도(명/km<sup>2</sup>)</Typography>
                                {_.round((_.sum(_.map(data, 'ppltn_dnsty'))/data.length), 0).toLocaleString()}
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper>
                                <Typography>총 사업체수</Typography>
                                {_.sum(_.map(data, 'corp_cnt')) === 0 ? 'N/A' : _.sum(_.map(data, 'corp_cnt')).toLocaleString()}
                            </Paper>
                        </Grid>
                    </Grid>
                    {
                      sgisYear &&
                        <Paper className="titxt" /*style={{background: 'none'}}*/>
                          <Typography>인구, 가구 ({sgisYear}년 기준)</Typography>
                        </Paper>
                    }
                    <Grid container xs={12}>
                        <Grid item xs={9} style={{paddingRight:"10px"}}>
                            <Paper className="paper-chart">
                                <Paper className="titxt">
                                    <Typography>연령대별 인구수</Typography>
                                </Paper>
                                <Paper>
                                <Chart
                                    chartType='BarChart'
                                    width='100%'
                                    height='400px'
                                    data={
                                        [
                                            ['연령대', '인구수', {role: 'annotation'}],
                                            ['10대 미만'].concat(_.sum(_.map(age_pp, 'teenage_less_than_cnt'))).concat(_.sum(_.map(age_pp, 'teenage_less_than_cnt'))),
                                            ['10대'].concat(_.sum(_.map(age_pp, 'teenage_cnt'))).concat(_.sum(_.map(age_pp, 'teenage_cnt'))),
                                            ['20대'].concat(_.sum(_.map(age_pp, 'twenty_cnt'))).concat(_.sum(_.map(age_pp, 'twenty_cnt'))),
                                            ['30대'].concat(_.sum(_.map(age_pp, 'thirty_cnt'))).concat(_.sum(_.map(age_pp, 'thirty_cnt'))),
                                            ['40대'].concat(_.sum(_.map(age_pp, 'forty_cnt'))).concat(_.sum(_.map(age_pp, 'forty_cnt'))),
                                            ['50대'].concat(_.sum(_.map(age_pp, 'fifty_cnt'))).concat(_.sum(_.map(age_pp, 'fifty_cnt'))),
                                            ['60대'].concat(_.sum(_.map(age_pp, 'sixty_cnt'))).concat(_.sum(_.map(age_pp, 'sixty_cnt'))),
                                            ['70대 이상'].concat(_.sum(_.map(age_pp, 'seventy_more_than_cnt'))).concat(_.sum(_.map(age_pp, 'seventy_more_than_cnt'))),
                                        ]
                                    }
                                    options={{
                                        //title: '연령대별 인구수',
                                        bar: {groupWidth: CHART.barWidth},
                                        legend: { position: 'none' }
                                    }}
                                    loader={<div>Loading Chart</div>}
                                />
                            </Paper>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} style={{paddingLeft:"10px"}}>
                            <Paper className="paper-chart">
                                <Paper className="titxt">
                                    <Typography>성별 인구수</Typography>
                                </Paper>
                                <Paper>
                                <Chart
                                    chartType='PieChart'
                                    width='100%'
                                    data={
                                        [
                                            ['성별', '인구수'],
                                            ['남'].concat(_.sum(_.map(data, 'sex_m'))),
                                            ['여'].concat(_.sum(_.map(data, 'sex_f'))),
                                        ]
                                    }
                                    options={{
                                        //title: '성별 인구수',
                                        pieHole: 0.4,
                                        legend: {
                                            position: "bottom",
                                            alignment: "center",
                                        },
                                        tooltip: {
                                            textStyle : {fontSize:12},
                                            showColorCode: true
                                        }
                                    }}
                                    legend_toggle
                                />
                            </Paper>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={4} style={{paddingRight:"10px"}}>
                            <Paper className="paper-chart">
                                <Paper className="titxt">
                                    <Typography>지역 거주인구 특성</Typography>
                                </Paper>
                                <Paper>
                                {regionTotalChartData ?
                                    <HighchartsReact
                                        highcharts={HighchartsMore(Highcharts)}
                                        options={{
                                            chart: {
                                                polar: true,
                                                height: 250,
                                            },
                                            title:{
                                                text: null
                                            },
                                            credits: {
                                                enabled: false
                                            },
                                            xAxis: {
                                                categories: ['아파트 비율', '거주인구 비율', '직장인구 비율', '1인가구 비율', '65세이상인구 비율', '20대인구 비율'],
                                                tickmarkPlacement: 'on',
                                                lineWidth: 0
                                            },
                                            yAxis: {
                                                gridLineInterpolation: 'polygon',
                                                lineWidth: 0
                                            },
                                            plotOptions: {
                                                series: {
                                                    animation: false
                                                }
                                            },
                                            series: regionTotalChartData,
                                        }}
                                    />
                                    :
                                    <Typography className="notice">{ emdCode ? '' : '상단의 개별 행정구역(읍면동)을 선택해 주세요.' }</Typography>
                                }
                                </Paper>
                            </Paper>
                        </Grid>
                        <Grid item xs={8} style={{paddingLeft:"10px"}}>
                            <Paper className="paper-chart">
                                <Paper className="titxt">
                                    <Typography>거주유형별 가구수</Typography>
                                </Paper>
                                <Paper>
                                <Chart
                                    chartType='PieChart'
                                    width='100%'
                                    height='250px'
                                    data={
                                        [
                                            ['거주형태', '인구수'],
                                            ['아파트'].concat(_.sum(_.map(house_pp, 'apart_cnt'))),
                                            ['오피스텔'].concat(_.sum(_.map(house_pp, 'officetel_cnt'))),
                                            ['연립/다세대'].concat(_.sum(_.map(house_pp, 'row_house_cnt'))),
                                            ['기숙사 및 사회시설'].concat(_.sum(_.map(house_pp, 'dom_soc_fac_cnt'))),
                                            ['단독주택'].concat(_.sum(_.map(house_pp, 'detach_house_cnt'))),
                                            ['기타'].concat(_.sum(_.map(house_pp, 'etc_cnt'))),
                                        ]
                                    }
                                    options={{
                                        //title: '거주유형별 가구수',
                                        pieHole: 0.4,
                                        legend: {
                                            position: "right",
                                            alignment: "center",
                                        },
                                        tooltip: {
                                            textStyle : {fontSize:12},
                                            showColorCode: true
                                        }
                                    }}
                                    legend_toggle
                                />
                                </Paper>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="tabcont-2depth">
                        {
                            BIZ_CTG1_TYPES.map((item, index) =>
                                <Grid item xs={6} key={index} onClick={()=>handleBizCtg1(item.ctg1_code)}>
                                    <Paper className={(item.ctg1_code==bizCtg1Code?'MuiButton-containedSecondary':'')}>{item.ctg1_name}</Paper>
                                </Grid>
                            )
                        }
                    </Grid>
                    <Paper className="tabcont-3depth">
                        <Grid>
                            {
                                bizCtg2List &&
                                bizCtg2List.map((item, index) =>
                                    <Grid key={index} xs={4/bizCtg2List.length} onClick={()=>handleBizCtg2(item.ctg2_code)}>
                                        <Paper className={(item.ctg2_code==bizCtg2Code?'MuiButton-containedSecondary':'')}>{item.ctg2_name}</Paper>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Paper>
                    <Grid item className="paper-chart">
                        <Paper className="titxt">
                            <Typography>종별 사업체수 ({year}년 기준)</Typography>
                        </Paper>
                        <Paper>
                            { bizChartData &&
                                <Chart
                                    chartType='BarChart'
                                    width='100%'
                                    height='400px'
                                    data={
                                        bizChartData
                                    }
                                    options={{
                                        //title: '업종별 사업체수 (' + {year} + '년 기준)',
                                        bar: {groupWidth: CHART.barWidth},
                                        legend: { position: 'none' },
                                        tooltip: {
                                            //textStyle : {fontSize:12},
                                            //showColorCode: true
                                        }
                                    }}
                                    loader={<div>Loading Chart</div>}
                                />
                            }
                        </Paper>
                    </Grid>
                    <Grid item className="paper-chart">
                        <Paper className="titxt">
                            <Typography>사업체 증가 추이 ({year}년 기준)</Typography>
                        </Paper>
                        <Paper>
                            { bizChartTrendData &&
                            <Chart
                                chartType='LineChart'
                                width='100%'
                                height='400px'
                                data={
                                    bizChartTrendData
                                }
                                options={{
                                    //title: '사업체 증가 추이',
                                    legend: {
                                        position: "top",
                                        alignment: "center",
                                    },
                                }}
                                loader={<div>Loading Chart</div>}
                            />
                            }
                        </Paper>
                    </Grid>
                </Grid>
        }
        </>
    )
};

export default StatSgis;
