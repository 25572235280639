import React from 'react';

const ZeroResult_Sample1 = () => {
    return (
        <div className="not_found_wrap">
            <div className="not_found">
                <div className="icon" style={{background: `url(http://image.medigate.net/test/190522/images/icon_nofound2.png) center / cover no-repeat`}}></div>
				<p className="txt">아쉽게도,<br />현재 검색 조건으로 처방된 샘플이 없습니다.</p>
            </div>
        </div>
    )
}

export default ZeroResult_Sample1;