import React, {useState, useEffect, Fragment} from 'react';
import { withApollo } from 'react-apollo';
import {inject, observer} from "mobx-react";
import { isMobileOnly } from 'react-device-detect';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';
import axios from 'axios';
import $ from 'jquery';

import Avatar from '@material-ui/core/Avatar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import CommonFunc from '../../common/CommonFunction';
import HtmlConfirm from '../../common/HtmlConfirm';
import { useStore } from "../../stores/StoreProvider";
import SessionManager from "../../common/SessionManager";

function UserProfileLayer(props){
    const [userSession, setUserSession] = useState(null);
    const [fetchedUserData, setFetchedUserData] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rememberme, setRememberme] = useState(true);
    const [message, setMessage] = useState(null);
    const [signinBtnLabel, setSigninBtnLabel] = useState("로그인");
    const [loginLayerFlag, setLoginLayerFlag] = useState(false);

    useEffect( ()=>{
        setLoginLayerFlag(props.loginLayerShow);
    }, [props.loginLayerShow])

    useEffect( ()=>{
        if(loginLayerFlag !== props.loginLayerShow){
            callbackLayerStatus(loginLayerFlag);
        }
    }, [loginLayerFlag])

    useEffect( ()=>{
        async function fetchMySession() {
            const sUserInfo = await CommonFunc.getSessionUserInfo();
            setUserSession(sUserInfo);
        }
        fetchMySession();
    }, [])


    useEffect(() => {
        if(userSession){
            getUserProfile(userSession.id, CommonFunc.getToken());
        }
        else{
            setFetchedUserData(true);
        }
    }, [userSession])

    const callbackLayerStatus = (status) => {
        if(props.changeLoginLayerShow){
            props.changeLoginLayerShow(status);
        }
    }

    const clickLogin = () => {
        //--store.setLoginLayerShow(store.loginLayerShow);
        setLoginLayerFlag(!loginLayerFlag);
    }

    const getUserProfile = async (u_id, token) => {
        const userProfile = await CommonFunc.getUserProfile(u_id, token);
        setFetchedUserData(true);
        setUserProfile(userProfile);
    }

    const signin = (e) => {
        e.preventDefault();

        if(_.isEmpty(username)){
            setMessage("아이디를 입력하세요.");
            return;
        }
        if(_.isEmpty(password)){
            setMessage("비밀번호를 입력하세요.");
            return;
        }

        setSigninBtnLabel("로그인 중...");

        const endpoint = `https://apis.medigate.net/signin?username=${username}&password=${password}`;

        const thisProps = props;
        axios.post(endpoint, {headers: {'Content-Type': 'application/json;charset=UTF-8'}})
        .then((response) => {
            const responseData = response.data;
            console.log('responseData.user', responseData.user);
            const userInfo = responseData.user;
            console.log('userInfo', userInfo);

            var isAllowedRoleForDrgate = userInfo.uKind === 'UKD000' || userInfo.uKind === 'UKD001';
            if(isAllowedRoleForDrgate){
                CommonFunc.saveAuthInfoToStorage(rememberme, responseData);
                CommonFunc.pageview('공통', '로그인'); //Google Analytics
                window.location.reload();
            }
            else{
                //로그인 권한 없음
                setMessage("메디게이트 의사 선생님만 로그인 가능합니다.");
                setSigninBtnLabel("로그인");
            }
        })
        .catch(error => {
            setMessage("아이디 또는 비밀번호가 일치하지 않습니다.");
            setSigninBtnLabel("로그인");
        });
    }

    const handleOnChange = (e) => {
        if(e.target.name == "username"){
            setUsername(e.target.value);
        }
        if(e.target.name == "password"){
            setPassword(e.target.value);
        }
    }

    const profileImageNoti = () => {
        confirmAlert({ customUI: ({ onClose }) => <HtmlConfirm title={"이미지 수정 불가"} message={"현재 이미지 등록/수정은 지원하지 않습니다.<br/>꼭 필요한 경우 메디게이트 모바일을 이용해<br/>프로필 이미지를 등록/수정하세요."} onClose={onClose} />});
        return false;
    }

    const handleClickAway = () => {
        //--store.setLoginLayerShow(true)
        callbackLayerStatus(true);
        setLoginLayerFlag(!loginLayerFlag);
    };

    return (
        <div id="header" className="profile" style={{position:"relative",top:"5px",right:"0",bottom:"auto",left:"auto",borderBottom:"0",padding:"0",background:"none"}}>
            <div className="header-field menu_userinfo"
            style={{position:"relative",top:"auto",right:"auto",bottom:"auto",left:"auto"}}>
                <div className="dialog-button" onClick={clickLogin}>
                    <div className="info_profile">
                        {/*<Avatar alt="Cindy Baker" src="//image.medigate.net/static/mobile/ver_3/images/icon_profilethumb.gif" />*/}
                        { userProfile && !_.isEmpty(userProfile.u_pic) ?
                            <Avatar alt="Cindy Baker1" src={`${CommonFunc.getImageDomain()}${userProfile.u_pic}`} />
                            :
                            <Avatar alt="Cindy Baker2" src="//image.medigate.net/static/mobile/ver_3/images/icon_profilethumb.gif" />
                        }
                    </div>
                </div>
            </div>
            {
                (fetchedUserData && loginLayerFlag) &&
                <div>
                    { userProfile ?
                        <div className="header-field menu_userinfo">
                            <div id="popup_userinfo" className="modal fade mdc-dialog" style={{display: "block"}}>
                                <ClickAwayListener onClickAway={handleClickAway}>
                                    <div className="modal-dialog" style={{position: "inherit"}}>
                                        <div className="contwrap modal-content">
                                            <p className="mdl-button close" data-dismiss="modal" onClick={clickLogin}>
                                                <span className="btn-closeApply"></span>
                                            </p>
                                            <div className="profilearea">
                                                <div className="info_img">
                                                    { !_.isEmpty(userProfile.u_pic) ?
                                                        <p className="profile_thumb" onClick={profileImageNoti} style={{backgroundImage: `url(${CommonFunc.getImageDomain()}${userProfile.u_pic})`, cursor: "pointer" }}></p>
                                                        :
                                                        <p className="profile_thumb" onClick={profileImageNoti} style={{backgroundImage: `url(//image.medigate.net/static/mobile/ver_3/images/icon_profilethumb.gif)`, cursor: "pointer" }}></p>
                                                    }
                                                </div>
                                                <div className="info_txt" style={{color: "#1a1a1a"}}>
                                                    <p className="tittxt">{userProfile.u_name}</p>
                                                    <p className="subtxt mail">{userProfile.u_email}</p>
                                                </div>
                                            </div>
                                            <div className="contarea">
                                                <div className="info_txt specialty" style={{color: "#1a1a1a"}}>
                                                    <span className="line_right">{userProfile.u_profile_spc_name}</span>
                                                    <span>{userProfile.u_wtp_name}</span>
                                                </div>
                                                <div className="info_txt location">
                                                    <span className="loc01">{userProfile.u_office}</span>
                                                    <span className="loc02">{userProfile.u_office_addr}</span>
                                                </div>
                                            </div>
                                            <div className="btn_type3 border-top-1px">
                                                <button className="mdc-button mdc-button--raised select left"
                                                        onClick={() => {
                                                            let url = `https://www.medigate.net/user/edit/basic`;
                                                            window.open(url, '_blank');
                                                        }}
                                                >정보수정</button>
                                                <button className="mdc-button mdc-button--outlined cancel right" onClick={()=>CommonFunc.signout()}>로그아웃</button>
                                            </div>
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            </div>
                            <div className="dialog-button userinfo">
                                <div className="info_profile">
                                    { !_.isEmpty(userProfile.u_pic) ?
                                        <Avatar alt="Cindy Baker1" src={`${CommonFunc.getImageDomain()}${userProfile.u_pic}`} />
                                        :
                                        <Avatar alt="Cindy Baker2" src="//image.medigate.net/static/mobile/ver_3/images/icon_profilethumb.gif" />
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="header-field menu_userinfo">
                            <div id="popup_login" className="modal mdc-dialog" style={{display: "block"}}>
                                <ClickAwayListener onClickAway={handleClickAway}>
                                    <div className="modal-dialog">
                                        <div className="contwrap modal-content">
                                            <p className="mdl-button close" data-dismiss="modal" onClick={clickLogin}>
                                                <span className="btn-closeApply"></span>
                                            </p>
                                            <form onSubmit={(e)=>signin(e)}>
                                                <div className="profilearea login">
                                                    <p className="input_row txt" style={{color: "#1a1a1a"}}>
                                                        메디게이트 의사회원만<br/>로그인할 수 있습니다.
                                                    </p>
                                                    <div className="input_row">
                                                        <input
                                                            type="text"
                                                            name="username"
                                                            value={username}
                                                            placeholder="메디게이트 아이디"
                                                            onChange={handleOnChange}
                                                            className="form-control" />
                                                    </div>
                                                    <div className="input_row">
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            value={password}
                                                            placeholder="메디게이트 비밀번호"
                                                            onChange={handleOnChange}
                                                            className="form-control" />
                                                    </div>
                                                    <button type="submit" className="mdc-button mdc-button--raised select left">{signinBtnLabel}</button>
                                                    {
                                                        message &&
                                                        <div className="error"><p className="error_txt"  dangerouslySetInnerHTML={{__html: message}}/></div>
                                                    }
                                                    <div className="gridcontainer signin_options">
                                                        <div className="input_row padding-10px">
                                                            <div className="checkbox">
                                                                <label className="label_txt" style={{color: "#1a1a1a"}}>
                                                                <span className={rememberme?'ckbox on':'ckbox'}>
                                                                    <input
                                                                        type="checkbox"
                                                                        name="rememberme"
                                                                        defaultChecked={rememberme}
                                                                        onChange={() => setRememberme(!rememberme)}
                                                                    />
                                                                </span>
                                                                    로그인 상태 유지
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="input_row pc padding-10px border-top-1px">
                                                            <a className="forgotid" style={{color: "#1a1a1a", fontSize: "12px"}} href="https://www.medigate.net/user/member/findForm?findType=U_ID" target="_blank">아이디 찾기</a>
                                                            <a className="forgotpassword" style={{color: "#1a1a1a", fontSize: "12px"}} href="https://www.medigate.net/user/member/findForm?findType=U_PASSWD" target="_blank">비밀번호 찾기</a>
                                                            <a className="join" style={{fontSize: "12px"}} href="https://www.medigate.net/user/member/addStep01" target="_blank">의사회원 가입</a>
                                                        </div>
                                                        <div className="input_row mobile padding-10px border-top-1px">
                                                            <p className="forgot">아이디, 비밀번호 찾기는 PC에서 이용할 수 있습니다.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            </div>
                            <div className="dialog-button">
                                <div className="info_profile">
                                    <Avatar alt="Cindy Baker" src="//image.medigate.net/static/mobile/ver_3/images/icon_profilethumb.gif" />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default withApollo(UserProfileLayer);
